import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';
import { Button, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { action, observable, makeObservable } from 'mobx';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import KeyValueModel from 'models/KeyValueModel';
import { NUMBER_OF_DECIMALS_PROCESSING_PRICE } from 'util/constants';
import InputWithNullCheck from 'components/InputWithNullCheck';
import InputNumber from 'components/InputNumber';
import { getTranslation, inputNumberStep, processDecimals } from 'util/helpers';
import { instance as notification } from 'util/notification';
import { I18N } from '../../../../assets/i18n/i18n';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class ProcessingListRoute extends React.Component<IProps> {
  public content: KeyValueModel = new KeyValueModel();
  public showEdit: boolean = false;

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      showEdit: observable,
      clearContent: action,
      updateContent: action,
      toggleEdit: action,
      toggleEditAndClearContent: action,
    });
  }

  public componentDidMount() {
    this.props.adminStore.getProcessingItems();
  }

  public clearContent = () => {
    this.content = new KeyValueModel();
  };

  public updateContent = (item: KeyValueModel) => {
    this.content = new KeyValueModel().update(item);
  };

  public toggleEdit = () => {
    this.showEdit = !this.showEdit;
  };

  public toggleEditAndClearContent = () => {
    this.toggleEdit();
    if (!this.showEdit) {
      this.clearContent();
    }
  };

  public render() {
    const {
      adminStore: { processing },
      translateService: { t },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">{t.GLOBAL_LABEL_PROCESSING}</h2>
            <table className="custom-table mb-3">
              <thead>
                <tr>
                  <th className="ps-3">{t.GLOBAL_LABEL_NAME}</th>
                  <th className="ps-3">{t.GLOBAL_LABEL_UNIT}</th>
                  <th className="ps-3 padding-right-4rem text-end">{t.GLOBAL_LABEL_PRICE}</th>
                </tr>
              </thead>
              {processing.length > 0
                ? processing.map((item: KeyValueModel) => (
                    <tbody key={item.name}>
                      <tr
                        data-test={`processing-row-${item.name}`}
                        className="pointer"
                        onClick={() => this._onRowClick(item)}
                      >
                        <td data-test="row-name" className="ps-3">
                          {this._createNameLabel(item.name)}
                        </td>
                        <td data-test="row-units">{this._createUnitLabel(item.units)}</td>
                        <td data-test="row-price" className="padding-right-4rem text-end">
                          {processDecimals(item.value, NUMBER_OF_DECIMALS_PROCESSING_PRICE)}
                        </td>
                      </tr>
                    </tbody>
                  ))
                : null}
            </table>
          </Col>
        </Row>

        {this.showEdit && (
          <div className="sidebar" data-test="processing-sidebar">
            <div className={classnames(['sidebar-heading', 'with-close-button'])}>
              <h5 className="mb-0">{t.ADMIN_COMMONDETAIL_HEADER(t.GLOBAL_LABEL_PROCESSING)}</h5>
              <span className="btn-close" onClick={this.toggleEditAndClearContent} data-test="close-sidebar">
                &times;
              </span>
            </div>
            <Container fluid>
              <Form onSubmit={this._submitForm}>
                <FormGroup row>
                  <Label for="name" sm={4}>
                    {t.GLOBAL_LABEL_NAME}
                  </Label>
                  <Col sm={8}>
                    <InputWithNullCheck
                      value={this._createNameLabel(this.content.name) as string}
                      name="name"
                      type="text"
                      data-test="name-input"
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="name" sm={4}>
                    {t.GLOBAL_LABEL_UNIT}
                  </Label>
                  <Col sm={8}>
                    <InputWithNullCheck
                      value={this._createUnitLabel(this.content.units) as string}
                      name="unit"
                      type="text"
                      data-test="unit-input"
                      disabled
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="name" sm={4}>
                    {t.GLOBAL_LABEL_PRICE}
                  </Label>
                  <Col sm={8}>
                    <InputNumber
                      value={this.content.value}
                      name="price"
                      type="number"
                      data-test="price-input"
                      decimalPlaces={NUMBER_OF_DECIMALS_PROCESSING_PRICE}
                      step={inputNumberStep(NUMBER_OF_DECIMALS_PROCESSING_PRICE)}
                      onChangeValue={(number: number) => this.content.changeValue(number)}
                    />
                  </Col>
                </FormGroup>
                <div className="clearfix">
                  <Button type="submit" className="pull-right" color="success" data-test="save-btn">
                    {t.GLOBAL_LABEL_SAVE}
                  </Button>
                </div>
              </Form>
            </Container>
          </div>
        )}
      </Container>
    );
  }

  private _submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      translateService: { t },
      adminStore: { putProcessingItem, getProcessingItems },
    } = this.props;

    putProcessingItem(this.content).then(() => {
      notification.success(t.ADMIN_COMMONDETAIL_UPDATE_MSG(t.GLOBAL_LABEL_PROCESSING));
      this.toggleEditAndClearContent();
      getProcessingItems();
    });
  };

  private _onRowClick = (item: KeyValueModel) => {
    this.toggleEdit();
    if (this.showEdit) {
      this.updateContent(item);
    } else {
      this.clearContent();
    }
  };

  private _createNameLabel = (key: string) => {
    const {
      translateService: { t },
    } = this.props;
    return getTranslation(`SORTINGREPORT_PROCESSING_TIME_${key}` as keyof I18N, t);
  };

  private _createUnitLabel = (key: string) => {
    const {
      translateService: { t },
    } = this.props;
    return getTranslation(`ADMIN_PROCESSING_UNIT_${key}` as keyof I18N, t);
  };
}

export default inject('adminStore', 'translateService')(observer(ProcessingListRoute));
