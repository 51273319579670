import * as React from 'react';
import * as classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { chunk } from 'lodash';
import { Col, Collapse, FormGroup, Label, Row } from 'reactstrap';

import CommonStore from 'stores/CommonStore';
import MaterialsModel from 'models/MaterialsModel';
import MaterialModel from 'models/MaterialModel';
import TranslateService from 'services/TranslateService';
import { inputNumberStep } from 'util/helpers';
import ViewStore from 'stores/ViewStore';
import InputNumber from 'components/InputNumber';
import { action, computed, observable, makeObservable } from 'mobx';
import * as FontAwesome from 'react-fontawesome';
import InputWithNullCheck from 'components/InputWithNullCheck';
import {
  NUMBER_OF_DECIMALS_MATERIAL,
  NUMBER_OF_DECIMALS_MATERIAL_FR,
  NUMBER_OF_DECIMALS_MATERIAL_UK,
} from 'util/constants';
import { CountryCode } from 'util/enums';

interface IProps {
  isReadOnly: boolean;
  materials: MaterialsModel[];
  nonDefaultMaterial: MaterialModel[];
  commonStore?: CommonStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  headerTitle?: string;
}

class MaterialsComponent extends React.Component<IProps> {
  private _isOpen: boolean = false;

  constructor(props: IProps) {
    super(props);

    makeObservable<MaterialsComponent, '_isOpen' | '_shouldBeOpen' | '_toggleIsOpen'>(this, {
      _isOpen: observable,
      _shouldBeOpen: computed,
      _toggleIsOpen: action,
    });
  }

  private get _shouldBeOpen(): boolean {
    return this._isOpen || !this._isCollapse;
  }

  public render() {
    const {
      isReadOnly,
      commonStore,
      nonDefaultMaterial,
      translateService: { t },
      headerTitle,
    } = this.props;
    if (!commonStore.materials) {
      return null;
    }

    // CREATE ARRAY CHUNKS BY TO BE ABLE TO RENDER INSIDE <FormGroup>
    const sections = chunk(this.props.materials, 2);

    return (
      <React.Fragment>
        <h5 data-test="materials-header" className="materials-header" onClick={this._toggleIsOpen}>
          {headerTitle || t.ADVISEDGOOD_SORTINGREPORT_MATERIAL}
          {this._isCollapse && (
            <FontAwesome data-test="arrow" className="margin-left-20" name={this._isOpen ? 'angle-up' : 'angle-down'} />
          )}
        </h5>
        <Row className="bg-white pb-1 pt-1  border-top border-bottom">
          <Collapse isOpen={this._shouldBeOpen} data-test="content">
            <Col className="pt-2 pb-2">
              {sections.map((sectionItem: MaterialsModel[], sectionItemIndex: number) => (
                <FormGroup
                  key={`ms-${sectionItemIndex}`}
                  row
                  className={classnames({ 'mb-0': sections.length === sectionItemIndex + 1 })}
                >
                  {sectionItem.map((item: MaterialsModel, index: number) => (
                    <Col xs={6} key={`mi-${sectionItemIndex}-${index}`}>
                      <Row>
                        <Label
                          xs={4}
                          className={classnames(
                            { 'text-end': index === 1 },
                            { 'p-0': !item.material || !item.material.isDefault }
                          )}
                        >
                          {item.material && item.material.isDefault ? (
                            item.material.code
                          ) : (
                            <InputWithNullCheck
                              disabled={isReadOnly}
                              type="select"
                              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                item.setMaterial(nonDefaultMaterial, e.currentTarget.value)
                              }
                              value={item.material && item.material.id}
                            >
                              <option value="" disabled>
                                {t.GLOBAL_LABEL_SELECT}
                              </option>
                              {nonDefaultMaterial &&
                                nonDefaultMaterial.map((material: MaterialModel) => (
                                  <option key={material.id} value={material.id}>
                                    {material.code}
                                  </option>
                                ))}
                            </InputWithNullCheck>
                          )}
                        </Label>
                        {this._renderInput(item, isReadOnly)}
                      </Row>
                    </Col>
                  ))}
                </FormGroup>
              ))}
            </Col>
          </Collapse>
        </Row>
      </React.Fragment>
    );
  }

  private _toggleIsOpen = () => {
    this._isOpen = !this._isOpen;
  };

  private get _isCollapse(): boolean {
    const {
      viewStore: { isDE, isIT, isDE_D365 },
    } = this.props;
    return isDE || isIT || isDE_D365;
  }

  private _renderInput = (item: MaterialsModel, isReadOnly: boolean) => {
    const {
      viewStore: { countryCode },
    } = this.props;

    let numberOfDecimals: number;
    switch (countryCode) {
      case CountryCode.UK:
        numberOfDecimals = NUMBER_OF_DECIMALS_MATERIAL_UK;
        break;
      case CountryCode.FR:
        numberOfDecimals = NUMBER_OF_DECIMALS_MATERIAL_FR;
        break;
      default:
        numberOfDecimals = NUMBER_OF_DECIMALS_MATERIAL;
    }

    return (
      <Col xs={8}>
        <InputNumber
          data-test="material-input"
          disabled={isReadOnly}
          decimalPlaces={numberOfDecimals}
          onChangeValue={(value: number) => item.setWeight(value)}
          step={inputNumberStep(numberOfDecimals)}
          value={item.weight}
        />
      </Col>
    );
  };
}

export default inject('commonStore', 'translateService', 'viewStore')(observer(MaterialsComponent));
