import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Sí';
lang.GLOBAL_LABEL_YES_SMALL = 'sí';
lang.GLOBAL_LABEL_NO = 'No';
lang.GLOBAL_LABEL_NO_SMALL = 'no';
lang.GLOBAL_LABEL_SUBMIT = 'Enviar';
lang.GLOBAL_LABEL_RESULT = 'Resultado';
lang.GLOBAL_LABEL_ERROR = 'Error';
lang.GLOBAL_LABEL_REFRESH = 'Actualizar';
lang.GLOBAL_LABEL_ADD_NEW = 'Añadir nuevo';
lang.GLOBAL_LABEL_NAME = 'Nombre';
lang.GLOBAL_LABEL_CODE = 'Código';
lang.GLOBAL_LABEL_UNIQUE_ID = 'Id. único';
lang.GLOBAL_LABEL_LOCATION = 'Ubicación';
lang.GLOBAL_LABEL_ONLINE = 'En línea';
lang.GLOBAL_LABEL_IS_DEFAULT = 'Predeterminado';
lang.GLOBAL_LABEL_MODEL = 'Modelo';
lang.GLOBAL_LABEL_IP = 'IP';
lang.GLOBAL_LABEL_SAVE = 'Guardar';
lang.GLOBAL_LABEL_DELETE = 'Eliminar';
lang.GLOBAL_LABEL_CANCEL = 'Cancelar';
lang.GLOBAL_LABEL_UPLOAD = 'Cargar';
lang.GLOBAL_LABEL_FILE = 'Archivo';
lang.GLOBAL_LABEL_PORT = 'Puerto';
lang.GLOBAL_LABEL_FIRST_NAME = 'Nombre';
lang.GLOBAL_LABEL_LAST_NAME = 'Apellido';
lang.GLOBAL_LABEL_USERNAME = 'Nombre de usuario';
lang.GLOBAL_LABEL_PASSWORD = 'Contraseña';
lang.GLOBAL_LABEL_EMAIL = 'Correo electrónico';
lang.GLOBAL_LABEL_ROLE = 'Función';
lang.GLOBAL_LABEL_STATUS = 'Estado civil';
lang.GLOBAL_LABEL_REMARKS = 'Observaciones';
lang.GLOBAL_LABEL_SELECT = 'Seleccionar';
lang.GLOBAL_LABEL_GRN = 'Recibiendo número';
lang.GLOBAL_LABEL_IN_PROGRESS = 'En curso';
lang.GLOBAL_LABEL_COMPLETED = 'Completado';
lang.GLOBAL_LABEL_REGISTER = 'Registro';
lang.GLOBAL_LABEL_OPERATION = 'Operación';
lang.GLOBAL_LABEL_SUPPLIER = 'Proveedor';
lang.GLOBAL_LABEL_SUPPLIER_CITY = 'Ciudad del proveedor';
lang.GLOBAL_LABEL_DRIVER = 'Conductor';
lang.GLOBAL_LABEL_LICENCE_PLACE = 'Matrícula';
lang.GLOBAL_LABEL_TO_CLAIM = 'Pa reclamar';
lang.GLOBAL_LABEL_CREATED = 'Creado';
lang.GLOBAL_LABEL_ACTIONS = 'Acciones';
lang.GLOBAL_LABEL_CLAIM = 'Reclamación';
lang.GLOBAL_LABEL_DESCRIPTION = 'Descripción';
lang.GLOBAL_LABEL_ADD = 'Añadir';
lang.GLOBAL_LABEL_NOT_CLAIMED = 'No solicitado';
lang.GLOBAL_LABEL_DELIVERIES = 'Entregas';
lang.GLOBAL_LABEL_SAVE_ATTACHMENTS = 'Guardar archivos adjuntos';
lang.GLOBAL_LABEL_UPLOAD_ATTACHMENTS = 'Cargar archivos adjuntos';
lang.GLOBAL_LABEL_ATTACHMENTS = 'Archivos adjuntos';
lang.GLOBAL_LABEL_ATTACHMENT = 'Adjunto archivo';
lang.GLOBAL_LABEL_ADVISED_GOODS = 'Productos aconsejados';
lang.GLOBAL_LABEL_SYNC = 'Sincronizar';
lang.GLOBAL_LABEL_CLOSE = 'Cerrar';
lang.GLOBAL_LABEL_VERIFY = 'Verificar';
lang.GLOBAL_LABEL_ALERT = 'EL PRODUCTO ACONSEJADO ESTÁ MARCADO';
lang.GLOBAL_LABEL_ALERT_RECEIVED_GOOD = '!!! BIEN RECIBIDO ES MARCADO !!!';
lang.GLOBAL_LABEL_CLAIM_BY = 'Solicitar por';
lang.GLOBAL_LABEL_CLASSIFIED_BY = 'Clasificado por';
lang.GLOBAL_LABEL_VERIFIED_BY = 'Verificado por';
lang.GLOBAL_LABEL_ROLLEDBACK_BY = 'Revertido por';
lang.GLOBAL_LABEL_FLAGGED_BY = 'Marcado por';
lang.GLOBAL_LABEL_UNFLAGGED_BY = 'Sin marcar por';
lang.GLOBAL_LABEL_TRANSFERS = 'Transferencias';
lang.GLOBAL_LABEL_TRANSFERED = 'Transferido';
lang.GLOBAL_LABEL_WEIGHT = 'Peso';
lang.GLOBAL_LABEL_CREATE = 'Crear';
lang.GLOBAL_LABEL_CHARACTERS_REMAINING = (number) => `Caracteres restantes: ${number}`;
lang.GLOBAL_LABEL_HIDE = 'Ocultar';
lang.GLOBAL_LABEL_UNHIDE = 'Mostrar';
lang.GLOBAL_LABEL_SHOW = 'Mostrar';
lang.GLOBAL_LABEL_LANGUAGE = (language) => `Idioma: ${language}`;
lang.GLOBAL_LABEL_FILE_DOWNLOADED_MSG = 'Archivo descargado';
lang.GLOBAL_LABEL_DECIMALS = 'Decimales';
lang.GLOBAL_LABEL_GO_BACK = 'Volver atrás';
lang.GLOBAL_LABEL_VERSION = (version) => `Versión - ${version}`;
lang.GLOBAL_LABEL_CONFIRM = 'Confirmar';
lang.GLOBAL_LABEL_FIELD = 'campo';
lang.GLOBAL_LABEL_FIELDS = 'campos';
lang.GLOBAL_LABEL_NONE = 'Ninguno';
lang.GLOBAL_LABEL_ALL_TENANTS = 'Todos los arrendatarios';
lang.GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR = 'No se ha definido el nombre del campo de entrada';
lang.GLOBAL_LABEL_UNCLAIM = 'No solicitar';
lang.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE = 'Fecha de vencimiento del certificado';
lang.GLOBAL_LABEL_CERT_AUT_RIF_DATE = 'Fecha de vencimiento del certificado';
lang.GLOBAL_LABEL_CERT_715_13 = 'Cert. 715/13';
lang.GLOBAL_LABEL_TYPE = 'Tipo';
lang.GLOBAL_LABEL_REVISION_REQUEST = 'Solicitud de revisión';
lang.GLOBAL_LABEL_EXPIRED = 'Vencido';
lang.GLOBAL_LABEL_NO_CERTIFICATE = 'Sin certificado';
lang.GLOBAL_LABEL_RESPONSIBLE = 'Responsable';
lang.GLOBAL_LABEL_DEPARTMENT = 'Departamento';
lang.GLOBAL_LABEL_EXPORT = 'Exportar';
lang.GLOBAL_LABEL_REEXPORT = 'Volver a exportar';
lang.GLOBAL_LABEL_FLAGGED = 'Marcado';
lang.GLOBAL_LABEL_EXPORTED = 'Exportado';
lang.GLOBAL_LABEL_SYNCED = 'Sincronizado';
lang.GLOBAL_LABEL_PRINT = 'Imprimir';
lang.GLOBAL_LABEL_FLAG = 'MARCAR';
lang.GLOBAL_LABEL_UN_FLAG = 'NO MARCAR';
lang.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER = '.Sin numero de autorizacion';
lang.GLOBAL_LABEL_AUTHORIZATION_EXPIRED = '.Autorización expirada';
lang.GLOBAL_LABEL_NO_EXPIRATION_DATE = '.Sin fecha de vencimiento';
lang.GLOBAL_LABEL_AUTH_NUMBER = '.Auth. número';
lang.GLOBAL_LABEL_EXP_DATE = '.Exp. fecha';
lang.GLOBAL_LABEL_IS_NOT_VALID = (name) => `.${name} No es válido`;
lang.GLOBAL_LABEL_INVALID_FIELDS = (name) => `.Campos inválidos: ${name}`;
lang.GLOBAL_LABEL_USER_ROLES = '.Roles del usuario';
lang.GLOBAL_LABEL_DEFAULT_ROLE = '.Rol predeterminado';
lang.GLOBAL_LABEL_DEFAULT_TENANT = '.Inquilino predeterminado';
lang.GLOBAL_LABEL_NEW_VERSION_RELEASED = '.Se lanzó una nueva versión.';
lang.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION = '.Para aplicar cambios, actualice la página. ¡Todos los cambios no guardados se perderán!';
lang.GLOBAL_LABEL_TENANTS_AVAILABLE = '.Inquilinos disponibles';
lang.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE = (roleName) => `.Inquilinos disponibles para ${roleName}`;
lang.GLOBAL_LABEL_IMPORT = '.Importar';
lang.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE = (date) => `.Su contraseña caduca el ${date}. Cambie la contraseña lo antes posible para evitar el bloqueo de la cuenta.`;
lang.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE = 'Tu contraseña ha expirado. Por favor configura uno nuevo';
lang.GLOBAL_LABEL_PROCESSING = 'Procesando';
lang.GLOBAL_LABEL_PRICE = 'Precio';
lang.GLOBAL_LABEL_UNIT = 'Unidad';
lang.GLOBAL_LABEL_BREAKDOWN = 'Descompostura';
lang.GLOBAL_LABEL_PASSWORD_EXP_DATE = 'Contraseña exp. fecha';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA = 'Italia';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO = 'Estero';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_DITTA = 'Ditta';
lang.GLOBAL_LABEL_SUPPLIERS_DETAILS = 'Detalles de proveedores';
lang.GLOBAL_LABEL_FORM = 'Formulario';
lang.GLOBAL_LABEL_QUALITY = 'Calidad';
lang.GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS = 'Actualizar variantes de producto';
lang.GLOBAL_LABEL_WEIGHT_UNITS = 'Unidades de peso';
lang.GLOBAL_LABEL_EMPLOYEE_ID = 'ID de empleado';
lang.GLOBAL_LABEL_LEGAL_ENTITY = 'Entidad legal';
lang.GLOBAL_LABEL_PRODUCT_VARIANTS = 'Variantes de producto';
lang.GLOBAL_LABEL_REFRESH_COMMON = (name) => `Actualizar ${name}`;
lang.GLOBAL_LABEL_TOTAL = 'Total';
lang.GLOBAL_LABEL_SHAREPOINT = 'Sharepoint';
lang.GLOBAL_LABEL_PARTIAL_SIGN_OFF = '.Cierre parcial';
lang.GLOBAL_LABEL_TITLE = 'Título';
lang.GLOBAL_LABEL_CLIENT_ID = 'Identificación del cliente';
lang.GLOBAL_LABEL_CLIENT_SECRET = 'Cliente Sercet';
lang.GLOBAL_LABEL_CONNECTION_ADDRESS = 'Dirección de conexión';
lang.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT = 'Habilitar la deducción de saldo por defecto';
lang.GLOBAL_LABEL_DETAILED_WEIGHING = 'Pesaje detallado';
lang.GLOBAL_LABEL_UPDATE = 'Actualizar';
lang.GLOBAL_FILTERKEY_DATE_ASC = 'Fecha ASC';
lang.GLOBAL_FILTERKEY_DATE_DESC = 'Fecha DESC';
lang.GLOBAL_FILTERKEY_STATUS_ASC = 'Estado ASC';
lang.GLOBAL_FILTERKEY_STATUS_DESC = 'Estado DESC';
lang.GLOBAL_FILTERKEY_ALL = 'Todo';
lang.GLOBAL_FILTERKEY_ONLY_MY = 'Solo mi';
lang.GLOBAL_FILTERKEY_ONLY_UNCLAIMED = 'Solo lo no solicitado';
lang.GLOBAL_FILTERKEY_ONLY_UNSYNCED = 'Solo lo no sincronizado';
lang.GLOBAL_FILTERKEY_IN_PROGRESS = 'En curso';
lang.GLOBAL_FILTERKEY_IN_LAB = 'En el laboratorio';
lang.GLOBAL_FILTERKEY_READY_FOR_TIER_2 = 'Listo para la dirección de las operaciones del depósito';
lang.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF = 'Listo para aprobar';
lang.GLOBAL_FILTERKEY_SIGNED_OFF = 'Aprobado';
lang.GLOBAL_FILTERKEY_COMPLETE = 'Completar';
lang.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF = 'Parcialmente firmado';
lang.GLOBAL_RADIOBUTTON_DRY = 'Seco';
lang.GLOBAL_RADIOBUTTON_RAIN = 'Lluvia';
lang.GLOBAL_RADIOBUTTON_SNOW = 'Nieve';
lang.GLOBAL_RADIOBUTTON_DUMP = 'Vertedero';
lang.GLOBAL_RADIOBUTTON_VAN = 'Furgoneta';
lang.GLOBAL_RADIOBUTTON_FLATBED = 'Base plana';
lang.GLOBAL_RADIOBUTTON_ROLL_OFF_BOX = '.Caja roll-off';
lang.GLOBAL_RADIOBUTTON_RAILCAR = 'Automotor';
lang.GLOBAL_RADIOBUTTON_OTHER = 'Otro';
lang.GLOBAL_USERROLE_SUPERADMIN = 'Superadministrador';
lang.GLOBAL_USERROLE_ADMIN = 'Administrador';
lang.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR = 'Báscula';
lang.GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE = '.Báscula externas';
lang.GLOBAL_USERROLE_TIER3 = 'Nivel 3';
lang.GLOBAL_USERROLE_TIER2 = 'Nivel 2';
lang.GLOBAL_USERROLE_TIER1 = 'Nivel 1';
lang.GLOBAL_USERROLE_MANAGER = 'Gestor';
lang.GLOBAL_USERROLE_LAB = 'Laboratorio';
lang.GLOBAL_USERROLE_TRADER = 'Comerciante';
lang.GLOBAL_USERROLE_TRADER_MOBILE = 'Com.';
lang.GLOBAL_USERROLE_BACKOFFICE = '.Back Office';
lang.GLOBAL_USERROLE_DATAANALYZER = 'Analizador de datos';
lang.GLOBAL_LOGIN_HEADER = 'Inicio de sesión';
lang.GLOBAL_LOGIN_TITLE = 'Inicie sesión en su cuenta';
lang.GLOBAL_LOGOUT_SUCCESS_MSG = 'Ha cerrado sesión';
lang.GLOBAL_DATERANGEPICKER_START_DATE_PLH = 'Fecha de inicio';
lang.GLOBAL_DATERANGEPICKER_END_DATE_PLH = 'Fecha de finalización';
lang.GLOBAL_RECOVERPASSWORD_HEADER = 'Establecer<br />contraseña';
lang.GLOBAL_RECOVERPASSWORD_UPDATE_MSG = 'Se ha actualizado la contraseña';
lang.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN = 'Establecer contraseña';
lang.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD = 'Contraseña actual';
lang.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD = 'Contraseña nueva';
lang.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD = 'Volver a introducir la contraseña nueva';
lang.GLOBAL_FILE_SELECT_ERROR = '.Error durante la selección de archivo';
lang.GLOBAL_SERVERERROR_DEFAULT = 'Se ha producido un error...';
lang.GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR = 'Error en el servicio externo...';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD = 'T2 no puede desmarcar el producto aconsejado.';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD = '.T2 no puede desmarcar recibido bien.';
lang.GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK = 'T2 no puede revertir el estado del producto aconsejado.';
lang.GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD = 'Trader no puede desmarcar el producto aconsejado.';
lang.GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS = '.El administrador no puede revertir el estado actual aconsejado como bueno.';
lang.GLOBAL_SERVERERROR_T1_CANNOT_CLAIM = 'T1 no puede solicitar el producto aconsejado';
lang.GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD = 'T1 no puede cargar el producto aconsejado';
lang.GLOBAL_SERVERERROR_T1_CANNOT_VERIFY = 'T1 no puede verificar el producto aconsejado';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED = 'No se puede verificar el producto aconsejado porque la entrega aún no está sincronizada';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_ADVISED_GOOD_NOT_UNLOADED = '.No se puede completar el producto aconsejado porque la entrega aún no está descargado';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF = 'El producto aconsejado marcado no puede estar listo para la aprobación';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF = '.El bien aconsejado no está listo para firmar';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED = '.Todos los artículos tienen que estar codificados en stock antes de que se pueda firmar el bien aconsejado.';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT = '.No puede cerrar la sesión como buena recomendada si ya la ha verificado.';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT = '.El bien aconsejado no tiene el peso de tara introducido';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT = '.Cerrar sesión en el buen nivel aconsejado está deshabilitado para el inquilino';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF = '.No se puede actualizar, se recomienda si la entrega ya ha finalizado';
lang.GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED = '.El comentario no se puede actualizar si se descarga AG';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT = '.El aconsejado buena peso de tara no está completo, por lo tanto no puede verificarse el producto aconsejado';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT = '.El entrega peso de tara no está completo, por lo tanto no puede verificarse el producto aconsejado';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING = 'Tienen que completarse todos los códigos de las existencias antes de que se pueda verificar el producto aconsejado';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB = 'Aún hay algunos artículos en el laboratorio';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY = (fullname) => `El producto aconsejado ya fue solicitado por ${fullname}`;
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF = 'El retroceso no se puede realizar una vez que se apruebe la entrega';
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF = '.No se puede realizar la reversión una vez que se haya aprobado el aviso';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST = '.La entrega con muestras de laboratorio no se puede eliminar';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_LAB_SAMPLES_EXIST = 'El producto aconsejado con muestras de laboratorio no se puede eliminar';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_LAST_ADVISED_GOOD_IN_DELIVERY = '.La entrega incluye sólo 1 producto aconsejado. Por favor, elimina la entrega en su lugar.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE = '.La plantilla no admite más de 11 productos recibidos dentro de un bien recomendado.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG = '.Máximo permitido 10 bienes recibidos dentro de un bien aconsejado.';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG = '.Máximo permitido 19 bienes recibidos dentro de un bien aconsejado.';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE = 'Tiempo de espera mientras se obtiene la imagen de la cámara';
lang.GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED = 'Calibre la cámara antes de usarla';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT = 'Tiempo de espera mientras se obtiene el resultado del ocr';
lang.GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND = 'La función del administrador no existe';
lang.GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND = '.El rol AppRegistration no existe';
lang.GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED = '.Solo se permite editar cuentas del tipo AppRegistration';
lang.GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR = '.Client Secret requiere al menos 20 caracteres, una letra mayúscula, un dígito, un carácter especial';
lang.GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS = '.El ID de cliente ya existe';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE = '.El título de registro de la aplicación no es válido';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION = '.La descripción del registro de la aplicación no es válida';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20 = '.Client Secret requiere al menos 20 caracteres';
lang.GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS = 'No se puede eliminar el arrendatario porque algunos usuarios pertenecen a él';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES = '.No se puede establecer la secuencia para el inquilino porque ya se han creado algunas entregas';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES = '.No se puede establecer la entidad legal para el inquilino porque ya se han creado algunas entregas';
lang.GLOBAL_SERVERERROR_CANNOT_CHANGE_TENANT_WORKFLOW = '.El flujo de trabajo no se puede cambiar';
lang.GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT = '.Este sitio no se puede usar porque ya lo está usando otro inquilino';
lang.GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY = '.Proporcione el sitio';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY = '.Proporcione una carpeta de SharePoint para cargar archivos adjuntos';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST = '.La carpeta de SharePoint seleccionada no existe';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT = '.Carpeta de SharePoint para la carga de archivos adjuntos no seleccionada para el inquilino. Comuníquese con su SuperAdmin';
lang.GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE = '.La aplicación dOps no puede cargar archivos en el sitio de SharePoint seleccionado. Comuníquese con su SuperAdmin';
lang.GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT = '.Error de servicio externo: no se pudieron actualizar los metadatos de los archivos adjuntos en SharePoint Online';
lang.GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT = '.Error de servicio externo: no se puede iniciar sesión en SharePoint Online';
lang.GLOBAL_SERVERERROR_SHAREPOINT_FOLDER_NOT_FOUND = '.Error de servicio externo: no se encontró la carpeta de SharePoint para la carga de archivos adjuntos. Por favor contacte al SuperAdmin';
lang.GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED = 'La entrega no puede actualizarse después de que se haya sincronizado';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER = '.El número de nota de entrega es obligatorio para la sincronización de entrega';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED = 'No se pueden eliminar los productos aconsejados que ya se han solicitado';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT = '.El peso de tara es obligatorio para la sincronización de entrega';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD = 'El producto subaconsejado debe tener al menos un artículo de producto recibido';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS = 'El producto aconsejado debe contener al menos un artículo en el desglose de los productos aconsejados';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD = 'La entrega debe contener al menos un artículo de producto aconsejado';
lang.GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED = 'No se puede guardar el peso de tara hasta que se solicite el producto aconsejado';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED = 'Todos los artículos tienen que estar en las existencias antes de que se pueda aprobar la entrega';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED = 'Todos los artículos del laboratorio tienen que estar completos antes de que se pueda aprobar la entrega';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '.Hay algunos productos aconsejados que no tienen completo el peso de tara';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT = '.Hay algunos bienes recibidos que no tienen el peso bruto llenado';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE = '.Hay algunos productos recomendados que no tienen el código EWC completado';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '.La entrega debe sincronizarse antes de cerrar sesión';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '.No se puede cerrar la sesión como buena, la entrega debe estar sincronizada';
lang.CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF = '.Todos los productos aconsejados deben estar listos para la firma antes de que se pueda firmar la entrega';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '.No se puede completar la entrega hasta que no se proporcionen todos los pesos de tara';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF = 'No se puede completar la entrega que no se haya aprobado.';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'La entrega no puede volver a sincronizarse porque aún no está sincronizado en la báscula';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF = 'La ordenación no puede volver a sincronizarse porque aún no está aprobada';
lang.GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS = 'Ya existe una composición con el mismo código';
lang.GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY = (grn) => `La composición se está usando en la entrega ${grn}`;
lang.GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED = 'No se pueden editar los productos aconsejados sin solicitarlo primero';
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD = 'No se puede añadir el análisis del laboratorio para los artículos del informe de ordenación completado';
lang.GLOBAL_SERVERERROR_STOCK_CODE_INVALID = (stockCode) => `El código de las existencias ${stockCode} proporcionado no es válido`;
lang.GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND = (userRole) => `.No se encontró el ID de rol de usuario ${userRole}`;
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD = 'No se puede añadir el análisis del laboratorio para los artículos del informe de ordenación no solicitado';
lang.GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR = 'Se ha producido una excepción al generar la imagen del código QR';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT = 'El peso del producto recibido no puede ser NULO al transferirse';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED = 'Peso máximo para transferir el peso total del producto recibido';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT = 'El peso del producto aconsejado debe establecerse antes de la transferencia';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED = 'El peso máximo de la transferencia no puede ser mayor que el peso del producto aconsejado';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT = 'No se puede transferir el producto recibido con peso negativo';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD = 'No se puede transferir el producto recibido total';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD = 'No se puede transferir el producto aconsejado total';
lang.GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD = 'No se puede transferir el producto recibido si solo hay uno en los productos aconsejados';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN = 'No se puede asignar la función de superadministrador al usuario';
lang.GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR = '.La contraseña requiere al menos 10 caracteres, una letra mayúscula, un dígito, un carácter especial';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN = 'No puede eliminar a otros administradores';
lang.GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR = 'Error no documentado';
lang.GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL = 'Error al enviar el correo electrónico';
lang.GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST = 'Error al procesar la solicitud';
lang.GLOBAL_SERVERERROR_FILE_NOT_FOUND = 'Archivo no encontrado';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND = 'Producto aconsejado no encontrado';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND = 'Archivo adjunto del producto aconsejado no encontrado';
lang.GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND = 'Contaminación no encontrada';
lang.GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND = 'Producto subaconsejado no encontrado';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND = 'Producto recibido no encontrado';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79 = '.Producto recibido descripción no puede tener más de 79 caracteres';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_FOUND = 'Cámara no encontrada';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE = 'Cámara no activa';
lang.GLOBAL_SERVERERROR_TENANT_NOT_FOUND = 'Arrendatario no encontrado';
lang.GLOBAL_SERVERERROR_ADMIN_NOT_FOUND = 'Administrador no encontrado';
lang.GLOBAL_SERVERERROR_WEIGHBRIDGE_USER_NOT_FOUND = 'Usuario de báscula no encontrado';
lang.GLOBAL_SERVERERROR_TRADER_USER_NOT_FOUND = 'Usuario comerciante no encontrado';
lang.GLOBAL_SERVERERROR_USER_NOT_FOUND = 'Usuario no encontrado';
lang.GLOBAL_SERVERERROR_LOCATION_NOT_FOUND = 'Ubicación no encontrada';
lang.GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS = 'Ya existe la ubicación';
lang.GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND = 'Entrega no encontrada';
lang.GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND = 'Archivo adjunto de la entrega no encontrado';
lang.GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND = 'Proveedor no encontrado';
lang.GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND = 'Tipo de proveedor no encontrado';
lang.GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND = 'Transportista no encontrado';
lang.GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND = 'Composición no encontrada';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND = 'Tipo de entrada de laboratorio no encontrado';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW = 'Tipo de entrada de laboratorio no encontrada para el flujo de trabajo';
lang.GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND = 'Entrada de laboratorio no encontrada';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED = 'Estado de laboratorio incompatible';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW = 'Estado de laboratorio no encontrado para el flujo de trabajo';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND = 'Aleación no encontrada';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS = '.El artículo en existencia ya existe';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5 = '.La longitud del código del artículo de stock debe ser de 5 dígitos';
lang.GLOBAL_SERVERERROR_PRINTER_NOT_FOUND = 'Impresora no encontrada';
lang.GLOBAL_SERVERERROR_SCALE_NOT_FOUND = 'Balanza no encontrada';
lang.GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND = 'Modelo de impresión no encontrado';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND = '.Formulario de producto no encontrado';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM = '.El formulario del producto proporcionado no está relacionado con el artículo de stock proporcionado';
lang.GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND = '.Calidad del producto no encontrada';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND = '.Variante de producto no encontrada';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF = '.Las variantes del producto son necesarias para la firma';
lang.GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS = 'Ya existe el nombre de usuario';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF = 'No lo puede eliminar usted mismo';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD = 'Nombre de usuario o contraseña no válido';
lang.GLOBAL_SERVERERROR_CANNOT_LOGIN_TENANT_IS_DELETED = 'No se puede iniciar sesión en el Arrendatario porque ha sido eliminado';
lang.GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE = '.Elija una contraseña diferente. Este ya se ha usado antes';
lang.GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED = '.El secreto de cliente actual ha sido usuario durante 2 años y expiró. Póngase en contacto con el superadministrador';
lang.GLOBAL_SERVERERROR_INVALID_EMAIL = 'Proporcione un correo electrónico válido';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME = 'Proporcione un nombre de usuario';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD = 'Proporcione una contraseña';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10 = 'La longitud mínima de la contraseña es de 10 caracteres';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_ID = '.Identificación de cliente no válida';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET = '.Secreto de cliente no válido';
lang.GLOBAL_SERVERERROR_INVALID_FIRSTNAME = 'Proporcione un nombre';
lang.GLOBAL_SERVERERROR_INVALID_LASTNAME = 'Proporcione un apellido';
lang.GLOBAL_SERVERERROR_INVALID_USER_ROLES = 'Proporcione las funciones de usuario';
lang.GLOBAL_SERVERERROR_INVALID_USER_TENANTS = '.Proporcione inquilinos de usuario';
lang.GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID = '.La identificación del empleado externo debe tener al menos 6 símbolos';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED = '.El campo de identificación de empleado externo es obligatorio';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND = '.Empleado externo con Id proporcionado no encontrado';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT = (tenantName) => `.La identificación del empleado seleccionado no es elegible para trabajar en el inquilino: ${tenantName}`;
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE = '.Proporcione solo un rol predeterminado para el usuario';
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT = '.Proporcione solo un inquilino predeterminado para el usuario';
lang.GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES = '.El rol activo del usuario debe coincidir con uno de los roles de usuario existentes';
lang.GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT = '.No tienes permiso para acceder al inquilino seleccionado';
lang.GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN = '.Solo el superadministrador puede crear o editar usuarios con la función "Administrador".';
lang.GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY = '.El usuario no puede tener roles Trader y BackOffice simultáneamente';
lang.GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES = '.El usuario con rol DataAnalyzer no puede tener múltiples roles';
lang.GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN = '.Se pueden asignar varios inquilinos solo si el usuario tiene el rol de Trader, BackOffice o Admin';
lang.GLOBAL_SERVERERROR_INVALID_NAME = 'Proporcione un nombre';
lang.GLOBAL_SERVERERROR_ACCESS_DENIED = 'Se ha denegado el acceso';
lang.GLOBAL_SERVERERROR_SESSION_NOT_FOUND = 'Sesión no encontrada';
lang.GLOBAL_SERVERERROR_SESSION_EXPIRED = 'Sesión vencida';
lang.GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE = '.Vuelve a intentarlo en 1 minuto';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING = 'Proporcione un nombre a la aleación';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD = 'No puede aprobar la entrega si ya se ha verificado uno de los productos aconsejados';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS = 'Ya existe el proveedor con el código proporcionado';
lang.GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING = 'Proporcione un nombre al proveedor';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING = 'Proporcione un código al proveedor';
lang.GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS = 'Proporcione el estado marcado';
lang.GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION = 'Proporcione una descripción';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255 = '.Producto recibido descripción no puede tener más de 255 caracteres';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION = 'Proporcione información de la composición';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID = 'Proporcione el Id. de la composición';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT = 'Proporcione el peso de la composición';
lang.GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID = 'Proporcione el Id. del producto subaconsejado';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1 = 'Proporcione un valor para el p1';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2 = 'Proporcione un valor para el p2';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3 = 'Proporcione un valor para el p3';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4 = 'Proporcione un valor para el p4';
lang.GLOBAL_SERVERERROR_PROVIDE_CONTRAST = 'Proporcione un contraste';
lang.GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE = 'Proporcione un tipo de pantalla';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME = 'Proporcione un nombre a la cámara';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE = 'Proporcione un lugar a la cámara';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID = 'Proporcione un Id. único a la cámara';
lang.GLOBAL_SERVERERROR_PROVIDE_SUPPLIER = 'Proporcione un proveedor';
lang.GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER = 'Proporcione un transportista';
lang.GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT = 'Proporcione un departamento';
lang.GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30 = 'Las observaciones pueden tener una longitud máx. de 30 caracteres';
lang.GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER = 'Proporcione la matrícula del vehículo';
lang.GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM = 'Proporcione una aleación';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION = 'Proporcione una descripción aconsejada';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30 = 'La descripción del producto aconsejado no puede tener más de 30 caracteres';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50 = 'La descripción del producto aconsejado no puede tener más de 50 caracteres';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100 = 'La descripción del producto aconsejado no puede tener más de 100 caracteres';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMMENT_MAX_LENGTH_100 = 'El comentario no puede tener más de 100 caracteres';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNLOADED_ATTACHMENTS_MISSING_ERROR = 'Por favor agregue archivos adjuntos';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMPLETED_ATTACHMENTS_MISSING_ERROR = 'Por favor agregue archivos adjuntos';
lang.GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER = 'Proporcione el número de ticket de la báscula';
lang.GLOBAL_SERVERERROR_PROVIDE_LOCATION = 'Proporcione una ubicación';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE = 'Proporcione el código de la composición';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME = 'Proporcione el nombre de la composición';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME = 'Proporcione el nombre de la impresora';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP = 'Proporcione la IP de la impresora';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL = 'Proporcione el modelo de la impresora';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS = '.Proporcione la dirección de conexión de la impresora';
lang.GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255 = '.La descripción puede tener un máximo de 255 caracteres';
lang.GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255 = '.La dirección puede tener un máximo de 255 caracteres';
lang.GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50 = '.El nombre puede tener un máximo de 255 caracteres';
lang.GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND = '.Tipo de documento no encontrado';
lang.GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME = '.Proporcione el nombre del informe de Lasernet';
lang.GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255 = '.El nombre del informe de Lasernet puede tener un máximo de 255 caracteres';
lang.GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES = '.Proporcione el número predeterminado de copias';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID = '.Proporcione la identificación de la impresora';
lang.GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS = '.La impresora con el nombre proporcionado ya existe';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND = '.Configuración de la impresora no encontrada';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS = '.La configuración de la impresora con los parámetros proporcionados ya existe';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES = '.Indique el número de copias';
lang.GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER = 'Proporcione el peso que se va a transferir';
lang.GLOBAL_SERVERERROR_PROVIDE_STATUS = 'Proporcione el estado nuevo';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME = 'Proporcione el nombre de la balanza';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_IP = 'Proporcione la IP de la balanza';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT = 'Proporcione el puerto de la balanza';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE = '.Por favor proporcione el tipo de entrega';
lang.GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION = '.Por favor proporcione las condiciones climáticas';
lang.GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE = '.Por favor proporcione el tipo de transacción';
lang.GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE = '.Proporcione el precio de procesamiento';
lang.GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND = '.Precio de procesamiento no encontrado';
lang.GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH = 'La resolución de la imagen es demasiado alta';
lang.GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED = '.Solo se permiten archivos e imágenes PDF como archivos adjuntos';
lang.GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY = 'El GRN no puede estar vacío';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION = (transfers) => `No se pueden eliminar los productos relacionados con las posiciones de traslado: ${transfers}`;
lang.GLOBAL_SERVERERROR_UNSUPPORTED_ACTION = 'Acción incompatible';
lang.GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE = '.No hay inquilinos D365 disponibles en este servidor';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS = 'No se puede crear la entrada del laboratorio porque ya existe otra entrada del laboratorio del mismo tipo';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT = 'El peso rebajado del producto aconsejado no puede estar vacío';
lang.GLOBAL_SERVERERROR_MACHINE_NOT_FOUND = 'Máquina no encontrada';
lang.GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND = 'Lote objetivo no encontrado';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING = (rgIdentifier) => `Proporcione el peso para: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING = (rgIdentifier) => `Proporcione la máquina en: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING = (rgIdentifier) => `Proporcione el WI realizado en: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING = (rgIdentifier) => `Proporcione la densidad de volúmen en: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING = (rgIdentifier) => `Proporcione la descripción del material en: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING = (rgIdentifier) => `Proporcione la calidad encontrada en: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING = (rgIdentifier) => `Proporcione el tipo principal en: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING = (rgIdentifier) => `Proporcione el lote objetivo en: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING = 'Proporcione el nombre del arrendatario';
lang.GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING = 'Proporcione el código del arrendatario';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING = 'Proporcione el código de ubicación del arrendatario';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS = 'El código de ubicación del arrendatario puede tener una longitud máx. de 10 caracteres';
lang.GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING = 'Proporcione el prefijo GRN del arrendatario';
lang.GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING = 'Proporcione el valor GRN actual para el arrendatario';
lang.GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING = 'Proporcione el flujo de trabajo del arrendatario';
lang.GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING = 'Proporcione la zona horaria del arrendatario';
lang.GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING = 'Proporcione el código de región del arrendatario';
lang.GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS = 'El código de región del arrendatario puede tener una longitud máx. de 10 caracteres';
lang.GLOBAL_SERVERERROR_TENANT_COMMENT_MAX_CHARS = '.El comentario del arrendatario puede tener una longitud máx. de 100 caracteres.';
lang.GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING = '.Proporcione la entidad legal del inquilino';
lang.GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT = 'Solo el propietario del archivo adjunto o el administrador puede eliminar el archivo adjunto';
lang.GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING = 'Falta la configuración del parámetro de integración. Póngase en contacto con el administrador.';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED = 'El producto aconsejado puede aparecer sin solicitar solo si en el estado aparece SOLICITADO';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN = 'El producto aconsejado puede aparecer sin solicitar solo por la persona que lo solicite o por el administrador';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS = 'El producto aconsejado puede aparecer sin solicitar solo si no se han creado las entradas del laboratorio';
lang.GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS = 'Ya existe el embalaje';
lang.GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND = 'Embalaje no encontrado';
lang.GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT = 'La suma de los campos de contaminación de "tamaños" debe ser del 100 %';
lang.GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND = 'Tiempo de procesamiento no encontrado';
lang.GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING = (rgIdentifier) => `Proporcione la contaminación en: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_INVALID_CODE = 'Proporcione el código';
lang.GLOBAL_SERVERERROR_PROVIDE_ORIGIN = 'Proporcione el origen de la entrega';
lang.GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND = 'Origen no encontrado';
lang.GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35 = '.La longitud del origen debe ser de 35 letras';
lang.GLOBAL_SERVERERROR_EWC_NOT_FOUND = 'EWC no encontrada';
lang.GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS = '.EWC ya existe';
lang.GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS = '.GRN ya existe';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF = 'La entrega no puede exportarse porque aún no está aprobada';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED = '.Utilice un filtro para reducir el número de entregas que se exportarán. La lista de entrega seleccionada es demasiado larga. ';
lang.GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND = 'Medición no encontrada';
lang.GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND = '.Análisis de espectro no encontrado.';
lang.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS = (joinedlabelslist) => `.Proporcione el: ${joinedlabelslist}`;
lang.GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH = (allowedLength) => `.La longitud del código de usuario debe ser de ${allowedLength} letras`;
lang.GLOBAL_SERVERERROR_USER_WITH_THIS_CODE_ALREADY_EXISTS = '.El usuario con este código ya existe en el arrendatario';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE = '.Proporcione la fecha de creación de la entrega';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER = '.Proporcione un buen número de línea recomendado';
lang.GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE = '.Proporcione el código de comerciante';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT = '.Indique el peso neto recomendado';
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE = (grn) => `.La entrega ${grn} ya se completó y no se puede actualizar`;
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE = (grn) => `.La entrega ${grn} ya se ha cerrado y no se puede actualizar`;
lang.GLOBAL_SERVERERROR_FILE_IS_EMPTY = '.El archivo está vacío';
lang.GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED = '.No se pudo importar el contrato ';
lang.GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE = '.El número de columnas no corresponde a la plantilla';
lang.GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED = '.El orden de las columnas no corresponde a la plantilla';
lang.GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED = '.No se pudieron cargar las ubicaciones del patio, se proporcionó un nombre de columna incorrecto';
lang.GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED = '.No se pudieron cargar los proveedores, se proporcionaron nombres de columna incorrectos';
lang.GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED = '.No se pudieron cargar los transportistas por carretera, se proporcionaron nombres de columna incorrectos';
lang.GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED = '.No se pudieron cargar los artículos en existencia, se proporcionaron nombres de columna incorrectos';
lang.GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY  = 'Proporcione unidades de peso';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY = '.Proporcione entidad legal';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_NOT_FOUND = '.Entidad jurídica proporcionada no encontrada';
lang.GLOBAL_SERVERERROR_EXTERNAL_API_NAME_NOT_FOUND = '.Nombre de API externo proporcionado no encontrado';
lang.GLOBAL_COPY_TITLE = (itemname) => `.Haga clic para copiar ${itemname} al portapapeles`;
lang.GLOBAL_COPY_SUCCESS_MESSAGE = (name) => `.${name} se copió al portapapeles`;
lang.HEADER_QRCODE_HEADER = 'Escanee el código QR';
lang.HEADER_QRCODE_ERROR_MSG = 'Se ha producido un error al abrir el lector del código QR';
lang.HEADER_LABEL_LOGOUT = 'Cierre de sesión';
lang.HEADER_LABEL_CHANGE_PASSWORD = 'Cambiar contraseña';
lang.HEADER_LABEL_SWITCH_ROLE = '.Cambiar de rol';
lang.HEADER_LABEL_SWITCH_TENANT = '.Cambiar inquilino';
lang.HEADER_MODAL_LOGOUT = '¿Quiere cerrar sesión? Se perderán todos los cambios que no haya guardado.';
lang.HEADER_MODAL_SWITCH_ROLE = '.¿Te gustaría cambiar de rol? Todos los cambios no guardados se perderán.';
lang.HEADER_MODAL_SWITCH_TENANT = '.¿Le gustaría cambiar de inquilino? Se perderán todos los cambios no guardados.';
lang.ADMIN_SECTIONENTITY_USER = 'Usuario';
lang.ADMIN_SECTIONENTITY_STOCK_ITEM = 'Aleación';
lang.ADMIN_SECTIONENTITY_SUPPLIER = 'Proveedor';
lang.ADMIN_SECTIONENTITY_ROAD_HAULIER = '.Portador';
lang.ADMIN_SECTIONENTITY_YARD_LOCATION = '.Ubicación del depósito';
lang.ADMIN_SECTIONENTITY_PRINTER = '.Impresora';
lang.ADMIN_SECTIONENTITY_SCALE = '.Balanza';
lang.ADMIN_SECTIONENTITY_MATERIAL = 'Composición';
lang.ADMIN_SECTIONENTITY_MACHINE = 'Máquina';
lang.ADMIN_SECTIONENTITY_TARGET_BATCH = 'Lote objetivo';
lang.ADMIN_SECTIONENTITY_PACKAGE = 'Paquete';
lang.ADMIN_SECTIONENTITY_EWC = 'Número EWC';
lang.ADMIN_SECTIONENTITY_ORIGIN = 'Origen';
lang.ADMIN_ACTIVITYLOG_TITLE = 'Registro de actividad';
lang.ADMIN_ACTIVITYLOG_ACTION = 'Acción';
lang.ADMIN_ACTIVITYLOG_CREATEDAT = 'Creado a las';
lang.ADMIN_ACTIVITYLOG_USER = 'Usuario';
lang.ADMIN_ACTIVITYLOG_MESSAGE = 'Mensaje';
lang.ADMIN_MATERIALS_CREATE_SUCCESS_MSG = 'Se ha creado una composición nueva';
lang.ADMIN_MATERIALS_TITLE = 'Composición';
lang.ADMIN_MATERIALS_CREATE_NEW = 'Crear composición nueva';
lang.ADMIN_MATERIALDETAIL_UPDATE_MSG = 'Se ha actualizado la composición';
lang.ADMIN_MATERIALDETAIL_DELETE_MSG = 'Se ha eliminado la composición';
lang.ADMIN_MATERIALDETAIL_HEADER = 'Detalles de la composición';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER = 'Eliminar confirmación de la composición';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT = (name) => `¿Quiere eliminar la composición <strong>${name}</strong>?`;
lang.ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG = 'Se ha creado una impresora nueva';
lang.ADMIN_PRINTERLIST_HEADER = 'Impresoras';
lang.ADMIN_PRINTERLIST_ADD_BTN = 'Crear impresora';
lang.ADMIN_PRINTERLIST_NAME = 'Nombre de la impresora';
lang.ADMIN_PRINTERLIST_CREATE_NEW = 'Crear impresora nueva';
lang.ADMIN_PRINTERDETAIL_UPDATE_MSG = 'Se ha actualizado la impresora';
lang.ADMIN_PRINTERDETAIL_DELETE_MSG = 'Se ha eliminado la impresora';
lang.ADMIN_PRINTERDETAIL_HEADER = 'Detalles de la ubicación de la impresora';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER = 'Eliminar confirmación de la impresora';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT = (name) => `¿Quiere eliminar la impresora <strong>${name}</strong>?`;
lang.ADMIN_ROADHAULIERLIST_HEADER = 'Transportistas';
lang.ADMIN_ROADHAULIERLIST_UPLOAD_BTN = 'Cargar transportistas';
lang.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER = '.Numero de autorización';
lang.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE = '.Fecha de caducidad';
lang.ADMIN_COMMONLIST_CREATE_HEADER = (name) => `.Crear nueva ubicación del ${name}`;
lang.ADMIN_COMMONLIST_UPLOAD_HEADER = (name) => `.Cargar ${name}`;
lang.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG = (name) => `.Se ha creado una ${name} nueva`;
lang.ADMIN_COMMONDETAIL_UPDATE_MSG = (name) => `.Se ha actualizado el ${name}`;
lang.ADMIN_COMMONDETAIL_HIDE_MSG = (name) => `.Se ha ocultado el ${name}`;
lang.ADMIN_COMMONDETAIL_UNHIDE_MSG = (name) => `.Se ha mostrado el ${name}`;
lang.ADMIN_COMMONDETAIL_HEADER = (name) => `.Detalles del ${name}`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER = (name) => `.Ocultar confirmación del ${name}`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.¿Quiere ocultar <strong>${name}</strong>?`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER = (name) => `.Mostrar confirmación del ${name}`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.¿Quiere mostrar <strong>${name}</strong>?`;
lang.ADMIN_COMMONDETAIL_ERROR_MSG = (name) => `.No se ha encontrado el ${name}`;
lang.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG = '¡La lista se ha cargado correctamente!';
lang.ADMIN_ADMINLISTUPLOAD_ERROR_MSG = 'Se ha producido un error durante la carga de la lista.';
lang.ADMIN_ADMINLISTUPLOAD_EXAMPLE = 'Revise el archivo Excel de ejemplo:';
lang.ADMIN_SCALELIST_CREATE_SUCCESS_MSG = 'Se ha creado una balanza nueva';
lang.ADMIN_SCALELIST_HEADER = 'Balanzas';
lang.ADMIN_SCALELIST_ADD_BTN = 'Crear balanza';
lang.ADMIN_SCALELIST_NAME = 'Nombre de la balanza';
lang.ADMIN_SCALELIST_CREATE_NEW = 'Crear balanza nueva';
lang.ADMIN_SCALEDETAIL_UPDATE_MSG = 'Se ha actualizado la balanza';
lang.ADMIN_SCALEDETAIL_DELETE_MSG = 'Se ha eliminado la balanza';
lang.ADMIN_SCALEDETAIL_HEADER = 'Detalles de la balanza';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_HEADER = 'Eliminar confirmación de la balanza';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_TEXT = (name) => `¿Quiere eliminar la balanza <strong>${name}</strong>?`;
lang.ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR = 'No se ha definido el nombre del campo de entrada';
lang.ADMIN_STOCKEITEMLIST_HEADER = 'Aleaciones';
lang.ADMIN_STOCKEITEMLIST_UPLOAD_BTN = 'Cargar aleaciones';
lang.ADMIN_SUPPLIERLIST_HEADER = 'Proveedores';
lang.ADMIN_SUPPLIERLIST_UPLOAD_BTN = 'Cargar proveedores';
lang.ADMIN_SUPPLIERDETAIL_UPDATE_MSG = 'Se ha actualizado el proveedor';
lang.ADMIN_SUPPLIERDETAIL_HIDE_MSG = 'Se ha ocultado el proveedor';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_MSG = 'Se ha mostrado el proveedor';
lang.ADMIN_SUPPLIERDETAIL_HEADER = 'Detalles del proveedor';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER = 'Ocultar confirmación del proveedor';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `¿Quiere ocultar el proveedor <strong>${name}</strong>?`;
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Mostrar confirmación del proveedor';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `¿Quiere mostrar el proveedor <strong>${name}</strong>?`;
lang.ADMIN_SUPPLIERDETAIL_ERROR_MSG = 'No se ha encontrado el proveedor';
lang.ADMIN_MACHINEDETAIL_UPDATE_MSG = 'Se ha actualizado la máquina';
lang.ADMIN_MACHINEDETAIL_HIDE_MSG = 'Se ha ocultado la máquina';
lang.ADMIN_MACHINEDETAIL_UNHIDE_MSG = 'Se ha mostrado la máquina';
lang.ADMIN_MACHINEDETAIL_HEADER = 'Detalles de la máquina';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER = 'Ocultar confirmación de la máquina';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `¿Quiere ocultar la máquina <strong>${name}</strong>?`;
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Mostrar confirmación de la máquina';
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `¿Quiere mostrar la máquina <strong>${name}</strong>?`;
lang.ADMIN_MACHINEDETAIL_ERROR_MSG = 'No se ha encontrado la máquina';
lang.ADMIN_PACKAGING_DETAIL_UPDATE_MSG = 'Se ha actualizado el embalaje';
lang.ADMIN_PACKAGING_DETAIL_HIDE_MSG = 'Se ha ocultado el embalaje';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_MSG = 'Se ha mostrado el embalaje';
lang.ADMIN_PACKAGING_DETAIL_HEADER = 'Detalles del embalaje';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER = 'Ocultar confirmación del embalaje';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT = (name) => `¿Quiere ocultar el embalaje <strong>${name}</strong>?`;
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER = 'Mostrar confirmación del embalaje';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `¿Quiere mostrar el embalaje <strong>${name}</strong>?`;
lang.ADMIN_PACKAGING_DETAIL_ERROR_MSG = 'No se ha encontrado el embalaje';
lang.ADMIN_TARGETBATCHDETAIL_UPDATE_MSG = 'Se ha actualizado el lote objetivo';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_MSG = 'Se ha ocultado el lote objetivo';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG = 'Se ha mostrado el lote objetivo';
lang.ADMIN_TARGETBATCHDETAIL_HEADER = 'Detalles del lote objetivo';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER = 'Ocultar confirmación del lote objetivo';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `¿Quiere ocultar el lote objetivo <strong>${name}</strong>?`;
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Mostrar confirmación del lote objetivo';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `¿Quiere mostrar el lote objetivo <strong>${name}</strong>?`;
lang.ADMIN_TARGETBATCHDETAIL_ERROR_MSG = 'No se ha encontrado el lote objetivo';
lang.ADMIN_USERLIST_CREATE_SUCCESS_MSG = 'Se ha creado un usuario nuevo';
lang.ADMIN_USERLIST_HEADER = 'Gestión de usuarios';
lang.ADMIN_USERLIST_ADD_BTN = 'Crear usuario nuevo';
lang.ADMIN_USERDETAIL_UPDATE_MSG = 'Se ha actualizado el usuario';
lang.ADMIN_USERDETAIL_DELETE_MSG = 'Se ha eliminado el usuario';
lang.ADMIN_USERDETAIL_DELETE_BTN = 'Eliminar usuario';
lang.ADMIN_USERDETAIL_HEADER = 'Detalles del usuario';
lang.ADMIN_USERDETAIL_CONFIRMATION_HEADER = 'Eliminar confirmación del usuario';
lang.ADMIN_USERDETAIL_CONFIRMATION_TEXT = (name) => `¿Quiere eliminar el usuario <strong>${name}</strong>?`;
lang.ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG = 'Se ha creado la ubicación del depósito nuevo';
lang.ADMIN_YARDLOCATIONLIST_HEADER = 'Ubicaciones del depósito';
lang.ADMIN_YARDLOCATIONLIST_ADD_BTN = 'Crear ubicación del depósito';
lang.ADMIN_YARDLOCATIONLIST_UPLOAD_BTN = 'Cargar ubicación del depósito';
lang.ADMIN_YARDLOCATIONLIST_NAME = 'Nombre de la ubicación del depósito';
lang.ADMIN_YARDLOCATIONLIST_CREATE_NEW = 'Crear nueva ubicación del depósito';
lang.ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG = 'Se ha actualizado la ubicación del depósito';
lang.ADMIN_YARDLOCATIONDETAIL_DELETE_MSG = 'Se ha eliminado la ubicación del depósito';
lang.ADMIN_YARDLOCATIONDETAIL_HEADER = 'Detalles de la ubicación del depósito';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER = 'Eliminar ubicación';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT = (name) => `¿Quiere eliminar la ubicación <strong>${name}</strong>?`;
lang.ADMIN_YARDLOCATIONDETAIL_NAME = 'Ubicación del depósito';
lang.ADMIN_MACHINE_LIST_HEADER = 'Máquinas';
lang.ADMIN_MACHINE_LIST_UPLOAD_BTN = 'Cargar máquinas';
lang.ADMIN_TARGET_BATCH_LIST_HEADER = 'Lotes objetivo';
lang.ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN = 'Cargar lotes objetivo';
lang.ADMIN_PACKAGING_ADD_BTN = 'Añadir embalaje';
lang.ADMIN_PACKAGING_UPLOAD_BTN = 'Cargar embalaje';
lang.ADMIN_PACKAGING_CREATE_NEW_PACKAGING = 'Crear embalaje nuevo';
lang.ADMIN_PACKAGING_CREATE_SUCCESS_MSG = 'Se ha creado el embalaje nuevo';
lang.ADMIN_EWCLIST_HEADER = 'EWC';
lang.ADMIN_EWCLIST_ADD_BTN = '.Crear EWC';
lang.ADMIN_EWCLIST_UPLOAD_BTN = '.Subir EWC';
lang.ADMIN_EWCLIST_CODE = '.Código';
lang.ADMIN_EWCLIST_CREATE_NEW = '.Crear nuevo EWC';
lang.ADMIN_EWCLIST_CREATE_SUCCESS_MSG = '.Nuevo código EWC fue agregado';
lang.ADMIN_EWCDETAIL_UPDATE_MSG = '.Se actualizó el código EWC';
lang.ADMIN_EWCDETAIL_HEADER = '.Detalle del EWC';
lang.ADMIN_EWCDETAIL_NAME = '.Código';
lang.ADMIN_EWCDETAIL_HIDE_MSG = '.EWC estaba oculto';
lang.ADMIN_EWCDETAIL_UNHIDE_MSG = '.EWC fue descubierto';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER = '.Ocultar confirmación de EWC';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.¿Desea ocultar el código EWC <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER = '.Mostrar confirmación EWC';
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.¿Desea mostrar el código EWC <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_ERROR_MSG = '.CEE no encontró';
lang.ADMIN_ORIGINLIST_HEADER = '.Procedencia';
lang.ADMIN_ORIGINLIST_ADD_BTN = '.Crear código de origen';
lang.ADMIN_ORIGINLIST_UPLOAD_BTN = '.Origen de carga';
lang.ADMIN_ORIGINLIST_CODE = '.Código';
lang.ADMIN_ORIGINLIST_CREATE_NEW = '.Crear nuevo origen';
lang.ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG = '.Nuevo código de origen fue agregado';
lang.ADMIN_ORIGINDETAIL_UPDATE_MSG = '.Código de origen actualizado';
lang.ADMIN_ORIGINDETAIL_HEADER = '.Detalle de origen';
lang.ADMIN_ORIGINDETAIL_NAME = '.Código';
lang.ADMIN_ORIGINDETAIL_HIDE_MSG = '.El origen estaba oculto';
lang.ADMIN_ORIGINDETAIL_UNHIDE_MSG = '.El origen fue ocultado';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER = '.Ocultar confirmación de origen';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `.¿Te gustaría ocultar el código de origen <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER = '.Mostrar confirmación de origen';
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `.¿Te gustaría mostrar el código de origen <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_ERROR_MSG = '.Origen no encontrado';
lang.ADMIN_CONTRACTS_TITLE = '.Contratos';
lang.ADMIN_CONTRACTS_FILE_IMPORT = '.Importación de archivos';
lang.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC = '.Activar sincronización SFTP';
lang.ADMIN_CONTRACTS_FILE_IMPORTED_MSG = '.Archivo importado';
lang.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG = '.Importación SFTP activada';
lang.ADMIN_PROCESSING_UNIT_MINUTES = '£/hora';
lang.ADMIN_PROCESSING_UNIT_TONS = '£/tonelada';
lang.ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE = 'Actualizar la confirmación de variantes de producto';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT = 'El proceso de actualización puede tardar unos minutos. Quieres proceder?';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE = 'Variantes de producto actualizadas correctamente';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE = 'Actualizar variantes de producto aún en curso. Actualizar la página después de 2 minutos';
lang.ADMIN_REFRESH_CONFIRMATION_TITLE = (name) => `Actualizar la confirmación de ${name}`;
lang.ADMIN_REFRESH_SUCCESS_MESSAGE = (name) => `${name} actualizado correctamente`;
lang.ADMIN_REFRESH_CONFIRMATION_TEXT = 'El proceso de actualización puede tardar unos minutos. Quieres proceder?';
lang.ADMIN_REFRESH_IN_PROGRESS_MESSAGE = (name) => `Actualizar ${name} aún en curso. Actualizar la página después de 2 minutos`;
lang.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE = 'Empleados actualizados con éxito';
lang.ADMIN_LASERNET_REPORT_NAME = 'Nombre del informe de Laserner';
lang.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES = 'Número predeterminado de copias';
lang.ADMIN_LASERNET_DOCUMENT_TYPE = 'Tipo de Documento';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL = 'Detalle del tipo de documento';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_ID = 'Identificación del tipo de documento';
lang.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG = 'Proporcione el nombre del informe de Lasernet';
lang.ADMIN_LASERNET_CONFIGURATIONS_HEADER = 'Configuraciones de impresora';
lang.ADMIN_LASERNET_CONFIGURATION_DETAILS = 'Detalles de configuración';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER = 'Eliminar confirmación';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT = '¿Le gustaría eliminar la configuración?';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE = 'La confirmación se eliminó correctamente.';
lang.ADMIN_LASERNET_PRINTER_ID = 'Identificación de la impresora';
lang.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG = 'Se ha creado el arrendatario nuevo';
lang.SUPERADMIN_TENANTLIST_HEADER = 'Arrendatarios';
lang.SUPERADMIN_TENANTLIST_ADD_BTN = 'Añadir nuevo';
lang.SUPERADMIN_TENANTLIST_GRN = 'Recibiendo prefijo del número';
lang.SUPERADMIN_TENANTLIST_CREATE_NEW = 'Crear arrendatario nuevo';
lang.SUPERADMIN_TENANTDETAIL_UPDATE_MSG = 'Se ha actualizado el arrendatario';
lang.SUPERADMIN_TENANTDETAIL_DELETE_MSG = 'Se ha eliminado el arrendatario';
lang.SUPERADMIN_TENANTDETAIL_HEADER = 'Detalles del arrendatario';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER = 'Eliminar confirmación del arrendatario';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT = (name) => `¿Quiere eliminar el arrendatario <strong>${name}</strong>?`;
lang.SUPERADMIN_TENANTDETAIL_SITE = 'Sitio';
lang.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS = 'Comprobar si faltan archivos adjuntos';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL = 'Aconsejado Buen nivel';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL = 'En el nivel de entrega';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION = 'Requerir clasificación';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION = 'Requerir contaminación';
lang.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT = 'Confirme la descripción de sobrescritura en el informe de clasificación';
lang.SUPERADMIN_TENANTDETAIL_DISPLAY_TARE_WEIGHT_FOR_RECEIVED_GOOD = 'Mostrar el peso de tara del bien recibido';
lang.SUPERADMIN_TENANTDETAIL_IS_STOCK_CODE_REQUIRED_FOR_VERIFY = '¿Se requiere el código de stock para verificar?';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_HEADER = 'Ocultar confirmación del inquilino';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Mostrar confirmación del inquilino';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `¿Le gustaría ocultar el inquilino <strong>${name}</strong>?`;
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `¿Le gustaría mostrar el inquilino <strong>${name}</strong>?`;
lang.SUPERADMIN_TENANTDETAIL_HIDE_SUCCESS_MESSAGE = 'El inquilino se ocultó con éxito';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_SUCCESS_MESSAGE = 'El inquilino se mostró correctamente';
lang.SUPERADMIN_TENANTSECTIONS_GENERAL_SECTION = 'General';
lang.SUPERADMIN_TENANTSECTIONS_CONFIGURATION_SECTION = 'Configuración';
lang.SUPERADMIN_TENANTSECTIONS_AG_CONFIGURATION_SECTION = 'Aconsejado Bueno ';
lang.SUPERADMIN_TENANTSECTIONS_DELIVERY_CONFIGURATION_SECTION = 'Entrega';
lang.SUPERADMIN_TENANTSECTIONS_REQUIRED_OPTIONS_SECTION = 'Opciones requeridas';
lang.SUPERADMIN_TENANTSECTIONS_INTEGRATION_SECTION = 'Integración';
lang.SUPERADMIN_TENANTADMIN_UPDATE_MSG = 'Arrendatario actualizado';
lang.SUPERADMIN_TENANTADMIN_ADD_MSG = 'Administrador añadido';
lang.SUPERADMIN_TENANTADMIN_DELETE_MSG = 'Administrador eliminado';
lang.SUPERADMIN_TENANTADMIN_TENANT = 'Arrendatario';
lang.SUPERADMIN_TENANTADMIN_LOCATION_CODE = 'Código de la ubicación';
lang.SUPERADMIN_TENANTADMIN_REGION_CODE = 'Código de la región';
lang.SUPERADMIN_TENANTADMIN_GRN_VALUE = 'Recibiendo valor del número';
lang.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE = 'Valor actual de secuencia de laboratorio';
lang.SUPERADMIN_TENANTADMIN_WORKFLOW = 'Flujo de trabajo';
lang.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW = 'Seleccionar el flujo de trabajo';
lang.SUPERADMIN_TENANTADMIN_TIMEZONE = 'Zona horaria';
lang.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE = 'Base de la API de integración';
lang.SUPERADMIN_TENANTADMIN_ADMINS = 'Administradores';
lang.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN = 'Añadir administrador';
lang.SUPERADMIN_TENANTADMIN_NO_ADMINS = 'Sin administradores';
lang.SUPERADMIN_TENANTADMIN_HEADER = 'Añadir usuario administrador';
lang.SUPERADMIN_TENANTADMINLINE_HEADER = 'Usuario administrador';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE = '.Ocultar administrador';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE = (name) => `.¿Te gustaría esconderte <strong>${name}</strong>?`;
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE = '.Mostrar administrador';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE = (name) => `.¿Te gustaría mostrar <strong>${name}</strong>?`;
lang.SUPERADMIN_SHAREPOINT_CHOOSE_SITE = '.Elija el sitio de Sharepoint';
lang.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH = '.Ruta de la carpeta de imágenes';
lang.SUPERADMIN_SHAREPOINT_SITES = '.Sitios de Sharepoint';
lang.SUPERADMIN_APP_USERS_TITLE = '.Registros de aplicaciones';
lang.SUPERADMIN_APP_USERS_ADD_MSG = '.Registro de aplicaciones agregado';
lang.SUPERADMIN_APP_USERS_ADD_TITLE = '.Agregar registro de aplicación';
lang.SUPERADMIN_APP_USERS_UPDATE_MSG = '.Registro de la aplicación actualizado';
lang.SUPERADMIN_APP_USERS_RANDOM_SECRET = 'Generar secreto aleatorio';
lang.SUPERADMIN_APP_USERS_COPY_TEXT = 'Copie este valor porque no podrá recuperar la clave después de guardar y cerrar este usuario de la aplicación.';
lang.SUPERADMIN_CONNECTIONS_TITLE = 'Conexiones';
lang.SUPERADMIN_CONNECTIONS_UPDATE_SUCCESS_MSG = 'El ID de cliente y el secreto se actualizaron correctamente';
lang.SUPERADMIN_CONNECTIONS_D365_DESCRIPTION = 'Datos maestros de D365 y conexión API de YardLink';
lang.SUPERADMIN_CONNECTIONS_SHAREPOINT_DESCRIPTION = 'Conexión API de SharePoint en línea';
lang.MENU_ADMIN_TITLE = 'Administración';
lang.MENU_ADMIN_USER_MANAGEMENT = 'Gestión de usuarios';
lang.MENU_ADMIN_ACTIVITY_LOG = 'Registro de actividad';
lang.MENU_ADMIN_STOCK_ITEMS = 'Aleaciones';
lang.MENU_ADMIN_SUPPLIERS = 'Proveedores';
lang.MENU_ADMIN_ROAD_HAULIERS = 'Transportistas';
lang.MENU_ADMIN_YARD_LOCATION = 'Ubicación del depósito';
lang.MENU_ADMIN_PRINTERS = 'Impresoras';
lang.MENU_ADMIN_LASERNET_PRINTERS = 'Impresoras láser';
lang.MENU_ADMIN_DOCUMENT_TYPES = 'Tipos de documentos';
lang.MENU_ADMIN_CONFIGURATIONS = 'Configuraciones';
lang.MENU_ADMIN_SCALES = 'Balanzas';
lang.MENU_ADMIN_MATERIALS = 'Composiciones';
lang.MENU_ADMIN_APPLICATION = 'Aplicación';
lang.MENU_ADMIN_DELIVERY_LIST = 'Lista de entregas';
lang.MENU_ADMIN_MACHINES = 'Máquinas';
lang.MENU_ADMIN_TARGET_BATCHES = 'Lotes objetivo';
lang.MENU_ADMIN_PACKAGING = 'Embalaje';
lang.MENU_ADMIN_ORIGINS = 'Procedencia';
lang.MENU_ADMIN_EWCS = 'EWC';
lang.MENU_SUPERADMIN_TENANTS = 'Arrendatarios';
lang.LABORATORY_ADVISEGOODS_UPDATE_MSG = 'Análisis del laboratorio: actualizados';
lang.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG = 'Estado de los análisis del laboratorio: actualizados';
lang.LABORATORY_ADVISEGOODS_DELIVERIES = 'Entregas';
lang.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION = 'Aconsejado Descripción';
lang.LABORATORY_ADVISEGOODS_TENANT = 'Arrendatario';
lang.LABORATORY_ADVISEGOODS_SORTING_REPORT = 'Informe de ordenación';
lang.LABORATORY_ADVISEGOODS_LAB_STATUSES = 'Estados del laboratorio';
lang.LABORATORY_ADVISEGOODS_LAB_LIST = 'Lista de laboratorios';
lang.LABORATORY_ANALISYS_HEADER = 'Análisis del laboratorio';
lang.LABORATORY_ANALISYS_MEASUREMENTS = 'Medidas';
lang.LABORATORY_ANALISYS_UPDATE_STATUS_MSG = 'Estado de los análisis del laboratorio: actualizados';
lang.LABORATORY_ANALISYS_WET_WEIGHT = 'Peso húmedo';
lang.LABORATORY_ANALISYS_DRY_WEIGHT = 'Peso seco';
lang.LABORATORY_ANALISYS_INGOTE_WEIGHT = 'Peso del botón';
lang.LABORATORY_ANALISYS_YIELD = 'Producción';
lang.LABORATORY_ANALISYS_SPECTRO_ANALYSIS = '.Espectroanálisis';
lang.LABORATORY_PRINTER_SUCCESS_MSG = 'La página se ha enviado a la impresora';
lang.LABORATORY_PRINTER_WARN_MSG = 'Impresora utilizada incompatible';
lang.LABORATORY_PRINTER_ERROR_MSG = 'Se ha producido un error al imprimir';
lang.LABORATORY_PRINTER_LABEL = 'Etiqueta';
lang.LABORATORY_PRINTER_NO_PRINTERS = 'Sin impresoras';
lang.LABORATORY_SCALE_CONNECTION_ERROR_MSG = 'No se ha podido establecer la conexión con la báscula';
lang.LABORATORY_SCALE_SCALE_ERROR_MSG = 'Báscula: se ha producido un error';
lang.LABORATORY_SCALE_FAKE_RESULT = 'Mirar el resultado';
lang.DELIVERIES_LABORATORY_FILTER_BY = 'Filtrar por:';
lang.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG = 'Se ha recibido una entrega nueva';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG = (name) => `Se ha actualizado la entrega ${name}`;
lang.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG = 'Se ha solicitado el producto aconsejado';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED = 'La entrega se ha vuelto a sincronizar';
lang.DELIVERIES_FILTER_SORT_BY = (name) => `Ordenar por ${name}`;
lang.DELIVERIES_FILTER_GRN_SEARCH = 'Recibiendo búsqueda de número';
lang.DELIVERIES_FILTER_ADVISED_GOOD_MSG = 'Se ha solicitado el producto aconsejado';
lang.DELIVERIES_FILTER_DELIVERY_RESYNCED = 'La entrega se ha vuelto a sincronizar';
lang.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG = 'Entrega aprobada';
lang.DELIVERIES_DELIVERYLIST_COMPLETED_MSG = 'Entrega completada';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF = 'Aprobar';
lang.DELIVERIES_DELIVERYLIST_RESYNC_WB = 'Volver a sincronizar la báscula';
lang.DELIVERIES_DELIVERYLIST_RESYNC_SR = 'Volver a sincronizar Sr';
lang.DELIVERIES_DELIVERYLIST_RESP = 'Resp.';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER = '¿Confirmar entrega?';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT = (grn) => `¿Quiere confirmar <strong>${grn}</strong>?`;
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER = '¿Marcar como completo?';
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT = (grn) => `¿Quiere completar <strong>${grn}</strong>?`;
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER = '¿Exportar entrega?';
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT = (grn) => `¿Quiere exportar <strong>${grn}</strong>?`;
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER = '.Confirmación de discrepancia de peso';
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT = '.Clasificación El peso total no coincide con el peso recibido. ¿Le gustaría cerrar la sesión?';
lang.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG = 'Se ha producido un error, redirigiendo a la lista de entregas';
lang.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG = 'Se ha creado la entrega';
lang.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG = 'Se ha producido un error al cargar el archivo adjunto, vuelva a cargarlo';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER = 'Número del contrato';
lang.DELIVERIES_NEWDELIVERYDETAILS_GRN = 'Recibiendo número';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW = 'Crear entrega nueva';
lang.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER = 'Número de albarán';
lang.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER = 'Matrícula del vehículo';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER = 'Número de contenedor';
lang.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER = 'Transportista';
lang.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS = 'Sin archivos adjuntos';
lang.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE = 'Persona responsable';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY = 'Creado por';
lang.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY = 'Aprobado por';
lang.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY = 'Sincronizado por';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY = 'Completado por';
lang.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM = 'Aleación';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION = 'Aconsejado Descripción';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT = 'Aconsejado Peso';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT = '.Adv. Peso bruto)';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT = '.Adv. Peso (neto)';
lang.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION = 'Ubicación del depósito';
lang.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT = '.Peso bruto';
lang.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT = '.Peso de tara';
lang.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET = 'N.º de ticket de la báscula';
lang.DELIVERIES_NEWDELIVERYDETAILS_NET = 'Neto';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER = '¿Guardar cambios?';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT = '¿Quiere <strong>guardar los cambios</strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER = '¿Marcar como completo?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT = '¿Quiere marcar la entrega como <strong>completa</strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = '¿Guardar sin archivos adjuntos?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = '¿Quiere guardar la entrega <strong>sin archivos adjuntos </strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER = '¿Guardar con el certificado vencido?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT = 'El certificado de proveedores está vencido o no está disponible. ¿Quieres guardar la entrega de todos modos?';
lang.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN = 'Origen';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER = '.¿Guardar con autorización caducada?';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT = '.La autorización para RoadHaulier falta o ha expirado. ¿Quieres guardar la entrega de todos modos?';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE = '.Tipo de transacción';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE = '.Trato pagable';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER = '.Transferir';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS = '.Devolución de rechazos';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID = '.Vacío';
lang.DELIVERIES_DELIVERYDETAILSLINE_HEADER = 'Detalles de mercancías aconsejadas';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION = 'Descripción aconsejada';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT = 'Peso aconsejado';
lang.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE = '.Línea de pedido Seq.';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX = '.Número de línea';
lang.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG = 'Se ha actualizado la entrega';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG = '¡Se han actualizado los archivos adjuntos correctamente!';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG = 'Se ha producido un error al cargar el archivo adjunto, vuelva a cargarlo';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG = 'Archivo adjunto eliminado';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION = 'Eliminar confirmación de adjunto';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION = '¿Le gustaría eliminar este archivo adjunto?';
lang.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG = (deliveryGrn) => `Se ha solicitado el producto aconsejado en la entrega <strong>${deliveryGrn}<strong/>`;
lang.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG = (codes) => `.Los códigos de acciones [${codes}] no coinciden con ninguno de los códigos de acciones de dOps. Por favor contacte a su administrador`;
lang.DELIVERIES_DELIVERYEDIT_DELETE = '¿Eliminar entrega?';
lang.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION = '¿Quieres eliminar Delivery? No será posible restaurarlo en el futuro.';
lang.DELIVERIES_DELIVERYEDIT_DELETED = (name) => `Se eliminó la entrega ${name}`;
lang.ADVISEDGOOD_ROUTE_EWC_NUMBER = 'Número EWC';
lang.ADVISEDGOOD_ROUTE_DRIVER = 'Conductor';
lang.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG = 'Producto aconsejado actualizado';
lang.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED = (entity) => `.Pesos actualizados en: ${entity}`;
lang.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG = 'Se ha MARCADO el producto aconsejado';
lang.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG = 'Se ha DESMARCADO el producto aconsejado';
lang.ADVISEDGOOD_ROUTE_COMPLETED_MSG = 'Se ha completado el producto aconsejado';
lang.ADVISEDGOOD_ROUTE_VERIFIED_MSG = 'Se ha verificado el producto aconsejado';
lang.ADVISEDGOOD_ROUTE_LAB_MSG = 'Se han creado correctamente los análisis del laboratorio';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG = 'El producto aconsejado se ha guardado antes de crear la transferencia';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG = 'El producto aconsejado debe guardarse antes de crear los análisis del laboratorio nuevos';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG = 'El producto aconsejado debe guardarse antes de imprimirlo';
lang.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK = 'Retroceso al estado';
lang.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION = 'Registro del vehículo';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT = 'Peso bruto';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT = '.Peso de tara';
lang.ADVISEDGOOD_ROUTE_NET_WEIGHT = 'Peso neto';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED = '.Peso bruto obligatorio';
lang.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT = 'Peso recibido';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED = '.Peso de tara obligatorio';
lang.ADVISEDGOOD_ROUTE_SORTING_REPORT = 'Informe de ordenación';
lang.ADVISEDGOOD_ROUTE_BALANCED = 'Ponderado';
lang.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG = 'Archivo ZIP descargado';
lang.ADVISEDGOOD_ROUTE_PICTURES = 'Imágenes';
lang.ADVISEDGOOD_ROUTE_NO_PICTURE = 'Sin imagen';
lang.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES = 'Cargando imágenes...';
lang.ADVISEDGOOD_ROUTE_COMMENT = 'Comentario';
lang.ADVISEDGOOD_ROUTE_NO_COMMENT = 'Sin comentario';
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER = (hasFlag) => `Confirmación ${hasFlag}`;
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `¿Quiere <strong class=${className}>${hasFlag}</strong> este producto aconsejado?`;
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER = '¿Guardar cambios?';
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT = '¿Quiere <strong>guardar los cambios</strong>?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER = '¿Marcar como completo?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT = '¿Quiere marcar este producto aconsejado como <strong>completo</strong>?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER = 'Marcar como verificado';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT = '¿Quiere marcar este producto aconsejado como <strong>verificado</strong>?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT = '.El total de clasificación es mayor que el peso recibido de la báscula para camiones. Para verificar, confirme que el peso de clasificación se haya verificado en una báscula de plataforma.';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER = '¿Retroceder al estado solicitado?';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT = '¿Quiere retroceder el estado a <strong>solicitado</strong>?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER = '¿Volver al estado descargado?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT = '¿Le gustaría revertir el estado a <strong>descargado</strong>?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_ERROR = 'Por favor agregue archivos adjuntos';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_WARNING = '¿Está seguro de que desea descargar el producto recomendado sin archivos adjuntos?';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS = 'Total de la ordenación (bruto)';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET = 'Total de la ordenación (neto)';
lang.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG = 'Se ha actualizado el peso bruto';
lang.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG = 'Se ha actualizado el peso recibido';
lang.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG = 'Se ha actualizado el número EWC';
lang.ADVISEDGOOD_ROUTE_DELIVERY_TYPE = 'Tipo de entrega';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER = 'Confirmar ubicación en carga';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT = 'Seleccionar la ubicación del depósito para la descarga';
lang.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION = 'Descargar ubicación';
lang.ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR = 'El producto aconsejado no puede verificarse porque el total de ordenación es mayor al peso recibido';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER = 'No solicitar producto aconsejado';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT = '¿No quiere solicitar el producto aconsejado?';
lang.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG = 'No se ha solicitado el producto aconsejado';
lang.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE = '.La cantidad de bienes recibidos no puede ser superior a 10.';
lang.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT = '.El camión recibió peso';
lang.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL = '.Total de clasificación de camiones (bruta)';
lang.ADVISEDGOOD_ROUTE_PACKAGING = '.embalaje';
lang.ADVISEDGOOD_ROUTE_NO_PACKAGING = '.Sin embalaje';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG = 'Cerrar sesión AG';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER = '¿Cerrar AG?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT = '¿Le gustaría cerrar la sesión de AG?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE = 'AG se firmó con éxito';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = '¿Guardar sin archivos adjuntos?';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = '¿Le gustaría guardar las buenas recomendaciones <strong>sin archivos adjuntos</strong>?';
lang.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT = '¿Desea sobrescribir la descripción del artículo?';
lang.ADVISEDGOOD_ROUTE_DELETE = 'Eliminar ¿Bueno recomendado?';
lang.ADVISEDGOOD_ROUTE_DELETE_CONFIRMATION = '¿Le gustaría eliminar el producto recomendado? No será posible restaurarlo en el futuro.';
lang.ADVISEDGOOD_ROUTE_DELETED = (name) => `Aconsejado Bueno ${name} fue eliminado`;
lang.ADVISEDGOOD_SORTINGREPORT_HEADER = 'Detalles del informe de ordenación';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG = 'El producto recibido no puede transferirse porque el peso de transferencia es mayor que el peso actual';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG = 'Se ha transferido el producto recibido';
lang.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE = 'Código de existencias';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER = 'Transferir';
lang.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS = 'Análisis del laboratorio';
lang.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS = 'No se solicitan análisis del laboratorio';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL = 'Composición';
lang.ADVISEDGOOD_SORTINGREPORT_WI_DONE = 'WI realizado';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER = 'Crear nuevo análisis de laboratorio';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT = '¿Quieres crear un nuevo análisis de laboratorio?';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER = 'Eliminar análisis del laboratorio';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT = (labAnalysisName) => `¿Quiere eliminar la solicitud <strong>${labAnalysisName}</strong>?`;
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE = 'Máquina';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH = 'Lote objetivo';
lang.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY = 'Densidad aparente';
lang.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY = 'Calidad encontrada';
lang.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE = 'Tipo principal';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION = 'Descripción del material';
lang.ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG = (emptyRequiredField) => `Tiene que rellenar <strong>${emptyRequiredField}</strong> antes de guardar`;
lang.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG = 'El producto aconsejado debe guardarse antes de añadir la imagen';
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE = 'Cambiar ubicación';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION = 'Ubicación objetivo';
lang.ADVISEDGOOD_LABANALYSIS_LAB_REMARKS = 'Observaciones del laboratorio';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER = 'Eliminar confirmación de la imagen';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT = '¿Quiere eliminar esta imagen?';
lang.ADVISEDGOOD_DEDUCTION_FREE_WATER = 'Agua libre';
lang.ADVISEDGOOD_DEDUCTION_FREE_OIL = 'Aceite libre';
lang.ADVISEDGOOD_DEDUCTION_FREE_EMULSION = 'Emulsión libre';
lang.ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE = 'Nieve/hielo libre';
lang.ADVISEDGOOD_DEDUCTION_NO_DEDUCTION = 'Sin deducción';
lang.ADVISEDGOOD_DEDUCTION_DEDUCTION = 'Deducción';
lang.ADVISEDGOOD_DEDUCTION_MODAL_HEADER = 'Confirmar producto aconsejado descargado';
lang.ADVISEDGOOD_UNLOAD_TITLE = 'Descargado';
lang.ADVISEDGOOD_UNLOAD_UPDATE_MSG = 'Descarga del producto aconsejado completo';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE = '.Confirmación de peso negativo';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT = '.El peso del material es negativo. Por favor confirmar';
lang.RECEIVED_GOOD_FLAG_ERROR_MSG = 'Recibido bueno fue señalado';
lang.RECEIVED_GOOD_FLAG_SUCCESS_MSG = 'Recibido bueno fue UN-BANDERA';
lang.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `¿Le gustaría <strong class=${className}> ${hasFlag} </strong> esto recibió bien?`;
lang.RECEIVED_GOOD_CONTAMINATION_TITLE = 'Contaminación';
lang.RECEIVED_GOOD_CONTAMINATION_OTHER = 'Otro';
lang.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION = 'Sin contaminación';
lang.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH = 'Archivo adjunto no metálico';
lang.RECEIVED_GOOD_CONTAMINATION_WATER = 'Agua (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FREE_OIL = 'Aceite gratis (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIRT = 'Suciedad (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_WOOD = 'Madera (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PLASTIC = 'Plástico (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL = 'Lana de vidrio (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG = 'Desechos ardientes (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BITUMEN = 'Betún (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_OIL_CREASE = 'Archivo adjunto Aceite/grasa (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CARDBOARD = 'Cartón (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH = 'Archivos adjuntos metálicos';
lang.RECEIVED_GOOD_CONTAMINATION_IRON = 'Hierro (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MN_STEEL = 'Acero Mn (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL = 'CrFe 10 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL = 'CrFe 13 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_301 = '301 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL = 'CrFe 17 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_SHREDDER = 'Trituradora (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT = 'Mat. incinerador (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS = 'Diferentes metales';
lang.RECEIVED_GOOD_CONTAMINATION_CU = 'Cu (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_AL = 'Al (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MS = 'Ms (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PB = 'Pb (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TI = 'Ti (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER = 'Refrigerador StSt/Cu (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING = 'Mat. para trituración (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS = 'Virutas sólidas';
lang.RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR = 'Virutas de CrFe (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR = 'Vir. aleadas al mínimo (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS = 'Virutas de StSt (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_304_TURNINGS = 'Virutas de 304 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_316_TURNINGS = 'Virutas de 316 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE = 'No tiene el tamaño del horno';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR = 'Para cizalla móvil (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR = 'Para cizalla fija (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING = 'Para corte con soplete (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_PRESS = 'Para prensa (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY = 'No son necesarios agregados (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES = 'Formado de cuerpos huecos (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS = 'Formas del material';
lang.RECEIVED_GOOD_CONTAMINATION_PACKETS = 'Paquetes (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BRIQUETTES = 'Briquetas (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INGOTS = 'Lingotes (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL = 'Material fino (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_RUNNINGS = 'Marchas (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND = 'Tara (entrante)';
lang.RECEIVED_GOOD_CONTAMINATION_TOTAL = 'Total (pz)';
lang.RECEIVED_GOOD_CONTAMINATION_DRUMS = 'Bidones (pz)';
lang.RECEIVED_GOOD_CONTAMINATION_BOXES = 'Cajas (pz)';
lang.RECEIVED_GOOD_CONTAMINATION_GRID_BOXES = 'Cajas de rejillas (pz)';
lang.RECEIVED_GOOD_CONTAMINATION_BIGBAGS = 'Bolsas grandes (pz)';
lang.RECEIVED_GOOD_CONTAMINATION_PALLETS = 'Palés (pz)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND = 'Tara (saliente)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION = 'Composición de las virutas';
lang.RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT = 'Contenido magnético';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING = 'Viruta metálica';
lang.RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING = 'Molienda';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES = 'Tamaños';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_0_15 = 'Tamaño de 0-15 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_16_35 = 'Tamaño de 16-35 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_36_60 = 'Tamaño de 36-60 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_61_80 = 'Tamaño de 61-80 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_81_120 = 'Tamaño de 81-120 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_121_150 = 'Tamaño de 121-150 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_151_999 = 'Tamaño de 150-999 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING = 'La suma no es del 100 %';
lang.RECEIVED_GOOD_CONTAMINATION_PACKAGING = 'Embalaje';
lang.SORTINGREPORT_CLASSIFICATION_TITLE = 'Clasificación';
lang.SORTINGREPORT_CLASSIFICATION_VERY_LIGHT = 'muy ligero';
lang.SORTINGREPORT_CLASSIFICATION_LIGHT = 'ligero';
lang.SORTINGREPORT_CLASSIFICATION_MIDDLE = 'medio';
lang.SORTINGREPORT_CLASSIFICATION_HEAVY = 'pesado';
lang.SORTINGREPORT_CLASSIFICATION_VERY_HEAVY = 'muy pesado';
lang.SORTINGREPORT_CLASSIFICATION_FE_SDS = 'Fe-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CR_SDS = 'Cr-SDS mezclado';
lang.SORTINGREPORT_CLASSIFICATION_V13F_SDS = 'V13F-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS = '1.4510-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS = '1.4512-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS = '17er Cr-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS = '1.4016-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS = '1.4509-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_304_SDS = '304-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_SDS = 'CrNi-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS = 'CrNiMo-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FE_TUR = 'Fe-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR = 'Cr-/CrNi-TUR mezclado';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_TUR = 'CrNi-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR = 'CrNiMo-TUR';
lang.SORTINGREPORT_CLASSIFICATION_OTHER = 'Otro';
lang.SORTINGREPORT_CLASSIFICATION_SHREDDER = 'Trituradora';
lang.SORTINGREPORT_CLASSIFICATION_SOLIDS = 'Sólidos';
lang.SORTINGREPORT_CLASSIFICATION_TURNINGS = 'Virutas';
lang.SORTINGREPORT_CLASSIFICATION_PACKAGE = 'Paquete';
lang.SORTINGREPORT_CLASSIFICATION_BRIKETTS = 'Briquetas';
lang.SORTINGREPORT_CLASSIFICATION_INGOTS = 'Lingotes';
lang.SORTINGREPORT_CLASSIFICATION_FINES = 'Finos';
lang.SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION = 'Incineración de residuos';
lang.SORTINGREPORT_CLASSIFICATION_RUNNINGS = 'Marchas';
lang.SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP = 'Desechos de la demolición';
lang.SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP = 'Desechos de la producción';
lang.SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP = 'Desechos de la recolección';
lang.SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP = 'Desechos del cizallamiento';
lang.SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS = 'Virutas con sólidos';
lang.SORTINGREPORT_PROCESSING_TIME_TITLE = 'Tiempo de procesamiento';
lang.SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS = 'HORAS DE CORTE flexión/aplastamiento';
lang.SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS = 'HORAS DE LA EXCAVADORA';
lang.SORTINGREPORT_PROCESSING_TIME_CART_HOURS = 'HORAS DE LA CARRETILLA';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS = 'HORAS DEL OPERADOR';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES = '.Minutos';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TONS = '.Toneladas';
lang.SORTINGREPORT_PROCESSING_TITLE = '.Procesando';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR = '.Operadora';
lang.SORTINGREPORT_PROCESSING_TIME_FORKLIFT = '.Máquina elevadora';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE = '.Grua';
lang.SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING = '.Corte de antorcha';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA = '.Plasma';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR = '.Cizalla de grúa';
lang.SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR = '.Cizalla de guillotina';
lang.SORTINGREPORT_PROCESSING_TIME_DENSIFIER = '.Densificador';
lang.SORTINGREPORT_PROCESSING_TIME_BALER = '.Embaladora';
lang.SORTINGREPORT_MEASUREMENT_TITLE = 'Medición';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_WET = 'Muestra húmeda (g)';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY = 'Muestra seca (g)';
lang.SORTINGREPORT_MEASUREMENT_HUMIDITY = 'Humedad (%)';
lang.SORTINGREPORT_MEASUREMENT_FOR_MELTING = 'Para derretir (g)';
lang.SORTINGREPORT_MEASUREMENT_INGOT = 'Lingote (g)';
lang.SORTINGREPORT_MEASUREMENT_YIELD = 'Rendimiento (%)';
lang.SORTINGREPORT_MEASUREMENT_CONTAINER = 'Envase';
lang.SORTINGREPORT_LASERNET_COPIES = 'copias';
lang.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE = (numberOfCopies) => `${numberOfCopies} etiqueta(s) están en cola y se imprimirán durante 1 minuto`;
lang.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE = 'Error de configuración de la impresora. Póngase en contacto con su administrador';
lang.STATUS_LABINPUT_PRE_SAMPLE = 'Muestra previa';
lang.STATUS_LABINPUT_SAMPLE = 'Muestra';
lang.STATUS_LABINPUT_RE_SAMPLE = 'Doble muestra';
lang.STATUS_LABINPUT_SORTATION = 'Ordenación';
lang.STATUS_LABINPUT_NITON_ANALYSIS = 'Análisis Niton';
lang.STATUS_LABSTATUSES_IN_LAB = 'En el laboratorio';
lang.STATUS_LABSTATUSES_DRYING = 'Secado';
lang.STATUS_LABSTATUSES_MELTING = 'Fusión';
lang.STATUS_LABSTATUSES_MELTED = 'Fundido';
lang.STATUS_LABSTATUSES_COMPLETE = 'Completar';
lang.STATUS_LABSTATUSES_REQUESTED = 'Solicitado';
lang.STATUS_LABSTATUSES_SAMPLED = 'Incluido en la muestra';
lang.STATUS_LABSTATUSES_IN_PROGRESS = 'En curso';
lang.STATUS_DELIVERYSTATUSES_IN_WORK = 'En curso';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2 = '.Listo para la gestión del sitio';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF = 'Listo para aprobación';
lang.STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF = 'Parcialmente firmado';
lang.STATUS_DELIVERYSTATUSES_SIGNED_OFF = 'Aprobado';
lang.STATUS_DELIVERYSTATUSES_COMPLETE = 'Completar';
lang.STATUS_ADVISEDGOOD_WAITING = 'Esperando';
lang.STATUS_ADVISEDGOOD_CLAIMED = 'Ordenando';
lang.STATUS_ADVISEDGOOD_UNLOADED = 'Descargado';
lang.STATUS_ADVISEDGOOD_IN_LAB = 'En el laboratorio';
lang.STATUS_ADVISEDGOOD_READY_FOR_TIER_2 = '.Listo para la gestión del sitio';
lang.STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF = 'Listo para aprobación';
lang.STATUS_ADVISEDGOOD_SIGNED_OFF = 'Aprobado';
lang.STATUS_ADVISEDGOOD_COMPLETE = 'Completar';
lang.DASHBOARD_TITLES_DASHBOARD = 'Panel';
lang.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS = 'Entregas en curso';
lang.DASHBOARD_TITLES_DELIVERY_BY_STATUS = 'Entrega por estado';
lang.DASHBOARD_TITLES_AVERAGE_SORTING_TIME = 'Tiempo medio de clasificación';
lang.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME = 'Tiempo medio de respuesta';
lang.DASHBOARD_TITLES_SORTING_TIME_TREND = 'Hora de clasificación de las últimas entregas';
lang.DASHBOARD_TITLES_FLAGGED_ITEMS = 'Artículos marcados';
lang.DASHBOARD_TITLES_TREES_SAVED = 'Árboles salvados';
lang.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL = 'Tiempo de clasificación (horas)';
lang.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL = 'Tiempo de respuesta (horas)';
lang.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP = 'Tiempo de clasificación';
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTHS = (condition) => `En progreso ${condition} meses`;
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTH = (condition) => `En progreso ${condition} mes`;
lang.DASHBOARD_TITLES_IN_PROGRESS_WEEK = (condition) => `En curso ${condition} semana`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS = (condition) => `Elementos marcados ${condition} meses`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH = (condition) => `Elementos marcados ${condition} mes`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK = (condition) => `Elementos marcados ${condition} semana`;
lang.USA_CONFIGURATION_CONFIGURATION = 'Configuración';
lang.USA_CONFIGURATION_SHOW_CONTRACT_NUMBER = 'Mostrar número de contrato';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN = 'Mostrar desglose del producto aconsejado';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE = 'Mostrar tara del producto aconsejado';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION = 'Mostrar ubicación del producto aconsejado';
lang.USA_CONFIGURATION_SHOW_DELIVERY_GROSS = 'Mostrar peso neto/bruto/tara de la entrega';
lang.USA_CONFIGURATION_SHOW_DELIVERY_TYPE = 'Mostrar tipo de entrega';
lang.USA_CONFIGURATION_SHOW_RADIATION_FILE = 'Carga del archivo de radiación';
lang.USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS = 'Mostrar condiciones meteorológicas';
lang.USA_CONFIGURATION_STOCK_CODE = 'Formato del código de existencias';
lang.USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE = 'Tolerancia de la báscula';
lang.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR = 'Error al cargar el archivo de radiación, vuelva a intentarlo';
lang.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG = 'Archivo de radiación obligatorio';
lang.USA_CONFIGURATION_WEATHER_CONDITIONS = 'Condiciones meteorológicas';
lang.USA_CONFIGURATION_DELIVERY_TYPE = 'Tipo de entrega';
lang.USA_CONFIGURATION_GROSS = 'Bruto';
lang.USA_CONFIGURATION_TARE = 'Tara';
lang.USA_CONFIGURATION_NET = 'Neto';
lang.USA_CONFIGURATION_RADIATION_FILE = 'Archivo de radiación';
lang.USA_CONFIGURATION_NO_RADIATION_FILE = 'Sin archivo de radiación';
lang.USA_CONFIGURATION_ADV_TARE = 'Tara aconsejada';
lang.PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED = '.Nueva entrega creada';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF = '.Entrega lista para cerrar sesión';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF = '.Entrega firmada';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER = '.Entrega completada por el comerciante';

export default lang;
