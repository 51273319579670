import { action, computed, observable, makeObservable } from 'mobx';

import UpdateModel from 'models/UpdateModel';
import * as moment from 'moment-timezone';
import { cloneObj } from 'util/helpers';

export default class CertificateModel extends UpdateModel<CertificateModel> {
  public expirationDate?: moment.Moment = null;

  constructor() {
    super();

    makeObservable(this, {
      expirationDate: observable,
      isCertificateExpired: computed,
      hasNotCertificate: computed,
      formattedExpirationDate: computed,
      update: action,
      changeExpirationDate: action,
    });
  }

  public get isCertificateExpired(): boolean {
    return this.expirationDate?.isBefore() && !moment().isSame(this.expirationDate, 'day');
  }

  public get hasNotCertificate(): boolean {
    return !this.expirationDate;
  }

  public get formattedExpirationDate(): string {
    return this.expirationDate && this.expirationDate.toISOString();
  }

  public update(obj: CertificateModel) {
    const newCertificateModel = cloneObj(obj);

    if (newCertificateModel && newCertificateModel.expirationDate) {
      newCertificateModel.expirationDate = moment(newCertificateModel.expirationDate);
    }

    this.updater.update(this, newCertificateModel, CertificateModel);
    return this;
  }

  public changeExpirationDate = (newDate: moment.Moment): void => {
    this.expirationDate = newDate;
  };
}
