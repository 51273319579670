import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import TranslateService from 'services/TranslateService';
import ConnectionModel from 'models/ConnectionModel';

interface IProps {
  content: ConnectionModel;
  saveAction: (model: ConnectionModel) => void;
  translateService?: TranslateService;
}

class ConnectionForm extends React.Component<IProps> {

  public render() {
    const {
      translateService: { t },
      content,
    } = this.props;

    return content ? (
      <Form onSubmit={this._submitForm}>
        <FormGroup row>
          <Label for="clientId" sm={4}>
            {t.GLOBAL_LABEL_CLIENT_ID}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              value={content.clientId}
              name="clientId"
              id="clientId"
              maxLength={50}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setClientId(e.target.value)}
              type="text"
              data-test="client-id-input"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="clientSecret" sm={4}>
            {t.GLOBAL_LABEL_CLIENT_SECRET}
          </Label>
          <Col sm={8}>
            <Input
              value={content.clientSecret}
              name="clientSecret"
              id="clientSecret"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setClientSecret(e.target.value)}
              type="text"
              data-test="client-secret-input"
            />
          </Col>
        </FormGroup>
        <div className="clearfix">
          <Button type="submit" className="pull-right" color="success" data-test="update-btn" disabled={!content.hasClientIdAndSecret}>
            {t.GLOBAL_LABEL_UPDATE}
          </Button>
        </div>
      </Form>
    ) : null;
  }

  private _submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { saveAction, content } = this.props;
    saveAction(content);
  };
}

export default inject('translateService')(observer(ConnectionForm));
