import * as React from 'react';
import { inject, observer } from 'mobx-react';
import AppUserModel from 'models/AppUserModel';
import { Button, Col, Form, FormGroup, Label } from 'reactstrap';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import TranslateService from 'services/TranslateService';
import RandomPasswordGeneratorButton from 'components/RandomPasswordGeneratorButton';
import CopyToClipboardButton from 'components/CopyToClipboardButton';

interface IAppUserFormProps {
  content: AppUserModel;
  saveAction: (model: AppUserModel) => void;
  translateService?: TranslateService;
  toggleHideConfirmationModal?: () => void;
  toggleUnhideConfirmationModal?: () => void;
}

class AppUserForm extends React.Component<IAppUserFormProps> {
  public generateRandomPassword = (password: string) => {
    this.props.content.setPassword(password);
  };

  public render() {
    const {
      translateService: { t },
      content,
    } = this.props;

    return content ? (
      <Form onSubmit={this._submitForm}>
        <FormGroup row>
          <Label for="title" sm={4}>
            {t.GLOBAL_LABEL_TITLE}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              value={content.firstname}
              name="title"
              id="title"
              maxLength={50}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setFirstname(e.target.value)}
              type="text"
              data-test="title-input"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="description" sm={4}>
            {t.GLOBAL_LABEL_DESCRIPTION}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              value={content.lastname}
              name="description"
              id="description"
              maxLength={50}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setLastname(e.target.value)}
              type="text"
              data-test="description-input"
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="clientId" sm={4}>
            {t.GLOBAL_LABEL_CLIENT_ID}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              value={content.username}
              name="clientId"
              id="clientId"
              maxLength={50}
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setUsername(e.target.value)}
              type="text"
              data-test="client-id-input"
            />
          </Col>
        </FormGroup>

        <FormGroup row className="margin-bottom-3rem">
          <Col sm={4} className="flex-nowrap-space-between align-items-center">
            <Label for="clientSecret">{t.GLOBAL_LABEL_CLIENT_SECRET}</Label>
            <RandomPasswordGeneratorButton
              callback={this.generateRandomPassword}
              passwordLength={20}
              placement="top"
              tooltipText={t.SUPERADMIN_APP_USERS_RANDOM_SECRET}
            />
          </Col>

          <Col sm={8}>
            <InputWithLimitOfCharacters
              value={content.password}
              name="clientSecret"
              id="clientSecret"
              maxLength={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setPassword(e.target.value)}
              type="text"
              data-test="client-secret-input"
            />
            <CopyToClipboardButton
              buttonClassName="absolute-right-25"
              text={content.password}
              tooltipText={t.GLOBAL_COPY_TITLE(t.GLOBAL_LABEL_CLIENT_SECRET.toLowerCase())}
              messageOnCopied={t.GLOBAL_COPY_SUCCESS_MESSAGE(t.GLOBAL_LABEL_CLIENT_SECRET)}
            />
            {content.isNew && (
              <span className="absolute-bottom--40 padding-right-10" data-test="copy-notice">
                {t.SUPERADMIN_APP_USERS_COPY_TEXT}
              </span>
            )}
          </Col>
        </FormGroup>
        <div className="clearfix">
          {this._renderHideUnhideButton}
          <Button type="submit" className="pull-right" color="success" data-test="save-btn">
            {t.GLOBAL_LABEL_SAVE}
          </Button>
        </div>
      </Form>
    ) : null;
  }

  private _submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { saveAction, content } = this.props;
    saveAction(content);
  };

  private get _renderHideUnhideButton() {
    const {
      toggleHideConfirmationModal,
      toggleUnhideConfirmationModal,
      translateService: { t },
    } = this.props;

    if (!toggleHideConfirmationModal || !toggleUnhideConfirmationModal) {
      return null;
    }
    return this.props.content.active ? (
      <Button
        data-test="hide-button"
        type="button"
        color="danger"
        onClick={toggleHideConfirmationModal}
        className="pull-left"
      >
        {t.GLOBAL_LABEL_HIDE}
      </Button>
    ) : (
      <Button
        data-test="unhide-button"
        type="button"
        color="success"
        onClick={toggleUnhideConfirmationModal}
        className="pull-left"
      >
        {t.GLOBAL_LABEL_UNHIDE}
      </Button>
    );
  }
}

export default inject('translateService')(observer(AppUserForm));
