import { logger } from 'util/logger';

export const randomPasswordGenerator = (passwordLength: number): string => {
  const requiredSymbols = getKey.reduce((res: string[], callback: () => string) => [...res, callback()], []);
  if (passwordLength < requiredSymbols.length) {
    logger.error(`Password should be longer than ${requiredSymbols.length} symbols`);
    return null;
  }
  const otherSymbolsLength = passwordLength - requiredSymbols.length;
  const otherSymbols = [];
  while (otherSymbols.length !== otherSymbolsLength) {
    const symbol = getKey[Math.floor(Math.random() * getKey.length)]();
    otherSymbols.push(symbol);
  }

  return shuffleString([...requiredSymbols, ...otherSymbols]);
};

export const keys = {
  upperCase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  lowerCase: 'abcdefghijklmnopqrstuvwxyz',
  number: '0123456789',
  symbol: '!@#$%^&*()_+~\\`|}{[]:;?><,./-=',
};

const getKey: (() => string)[] = [
  function upperCase() {
    return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
  },
  function lowerCase() {
    return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
  },
  function number() {
    return keys.number[Math.floor(Math.random() * keys.number.length)];
  },
  function symbol() {
    return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
  },
];

const shuffleString = (array: string[]): string => {
  return [...array].sort(() => 0.5 - Math.random()).join('');
};
