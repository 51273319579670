import { action, computed, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import IdCodeNameModel from 'models/IdCodeNameModel';

export default class EmployeeModel extends UpdateModel<EmployeeModel> {
  public id: string = '';
  public externalId: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public legalEntity?: string = '';

  constructor() {
    super();

    makeObservable(this, {
      id: observable,
      externalId: observable,
      firstName: observable,
      lastName: observable,
      legalEntity: observable,
      convertedIdCodeNameModel: computed,
      fullName: computed,
      update: action,
    });
  }

  public get convertedIdCodeNameModel(): IdCodeNameModel {
    return new IdCodeNameModel().update({ id: this.id, name: this.fullName, code: this.externalId } as IdCodeNameModel);
  }

  public get fullName(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  public update(obj: EmployeeModel) {
    this.updater.update(this, obj, EmployeeModel);
    return this;
  }
}
