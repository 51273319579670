import { action, observable, observe, computed, makeObservable } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';
import UpdateModel from 'models/UpdateModel';
import { ISetHasChanged } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';

export default class DeliverySubAdvisedGoodModel extends UpdateModel<DeliverySubAdvisedGoodModel>
  implements ISetHasChanged {
  constructor() {
    super();
    makeObservable(this, {
      advisedDescription: observable,
      advisedWeight: observable,
      id: observable,
      shouldAddNewLine: computed,
      update: action,
      setAdvisedDescription: action,
      setAdvisedWeight: action,
      constructSaveObj: action,
    });
    observe(this, this.onChange);
  }

  public advisedDescription?: string = '';
  public advisedWeight?: number = null;
  public id?: string = null;

  public get shouldAddNewLine() {
    return !!this.advisedDescription || !!this.advisedWeight;
  }

  public onChange = (change: IObjectDidChange) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  public update = (obj: DeliverySubAdvisedGoodModel) => {
    this.updater.update(this, obj, DeliverySubAdvisedGoodModel);

    return this;
  };

  public setAdvisedDescription(val: string) {
    this.advisedDescription = val;
  }
  public setAdvisedWeight(val: number) {
    this.advisedWeight = val;
  }

  // CONSTRUT OBJ FOR PUT/POST DELIVERY
  public constructSaveObj() {
    return {
      advisedDescription: this.advisedDescription,
      advisedWeight: this.advisedWeight,
      id: this.id,
    };
  }
}
