import * as React from 'react';
import { observer } from 'mobx-react';
import Autocomplete, { IAutoCompleteItem, IAutocompleteProps } from 'domain/Autocomplete';
import { PromiseWrapper } from 'util/helpers';
import Loading from 'domain/layout/Loading';

interface IAsyncAutocompleteProps extends Omit<IAutocompleteProps, 'items'> {
  items: PromiseWrapper<IAutoCompleteItem[]>;
}

const AsyncAutocompleteComponent: React.FC<IAsyncAutocompleteProps> = ({ items, ...rest }) => {
  return (
    <React.Suspense fallback={<Loading data-test="loading" />}>
      <Autocomplete {...rest} items={items.read()} />
    </React.Suspense>
  );
};

export const AsyncAutocomplete = observer(AsyncAutocompleteComponent);
