import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, Label } from 'reactstrap';
import TranslateService from 'services/TranslateService';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import ScaleModel from 'models/ScaleModel';

interface IScaleDetailComponentProps {
  content: ScaleModel;
  translateService?: TranslateService;
}

const ScaleDetailComponent: React.FunctionComponent<IScaleDetailComponentProps> = ({
  content,
  translateService: { t },
}) => {
  const changeIp = (e: React.ChangeEvent<HTMLInputElement>) => {
    content.changeIp(e.target.value);
  };
  const changePort = (e: React.ChangeEvent<HTMLInputElement>) => {
    content.changePort(e.target.value);
  };

  return (
    content && (
      <React.Fragment>
        <FormGroup row className="remaining-padding">
          <Label for="ip" sm={4}>
            {t.GLOBAL_LABEL_IP}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="ip"
              value={content.ip}
              name="ip"
              id="ip"
              maxLength={50}
              required
              onChange={changeIp}
              type="text"
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="port" sm={4}>
            {t.GLOBAL_LABEL_PORT}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="port"
              value={content.port}
              name="port"
              id="port"
              maxLength={50}
              required
              onChange={changePort}
              type="number"
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    )
  );
};

export default inject('translateService')(observer(ScaleDetailComponent));
