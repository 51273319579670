import { action, observable, makeObservable } from 'mobx';
import IdNameActiveModel from 'models/IdNameActiveModel';
import { ICode, IConstructSaveObj } from 'models/ModelInterfaces';

export default class MaterialModel extends IdNameActiveModel implements ICode, IConstructSaveObj<MaterialModel> {
  public code: string = '';
  public isDefault: boolean = false;
  public position: number = null;

  constructor() {
    super();

    makeObservable(this, {
      code: observable,
      isDefault: observable,
      position: observable,
      changeCode: action,
      setIsDefault: action,
    });
  }

  public get constructSaveObj(): MaterialModel {
    return {
      name: this.name,
      code: this.code,
      active: this.active,
      isDefault: this.isDefault,
    } as MaterialModel;
  }

  public update(obj: MaterialModel) {
    this.updater.update(this, super.update(obj), MaterialModel);
    return this;
  }

  public changeCode(val: string) {
    this.code = val;
  }

  public setIsDefault(val: boolean) {
    this.isDefault = val;
  }
}
