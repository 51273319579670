import { action, observable, makeObservable } from 'mobx';

export default class LayoutStore {
  public menuOpen: boolean = false;
  public isLoading: boolean = false;

  constructor() {
    makeObservable(this, {
      menuOpen: observable,
      isLoading: observable,
      setIsLoading: action,
      toggleMenu: action,
    });
  }

  public setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  public toggleMenu(forceOpen: boolean | null = null) {
    this.menuOpen = forceOpen !== null ? forceOpen : !this.menuOpen;
  }
}
