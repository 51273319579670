import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import ScaleModel from 'models/ScaleModel';
import TranslateService from 'services/TranslateService';
import env from 'env';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';
import { logger } from 'util/logger';
import CommonStore, { ICommonData } from 'stores/CommonStore';

interface IState {
  scales: ScaleModel[];
  isOpen: boolean;
}

interface IProps {
  onValue: (value: number) => void;
  commonStore?: CommonStore;
  translateService?: TranslateService;
}

class ScaleComponent extends React.Component<IProps, IState> {
  public state = {
    scales: [],
    isOpen: false,
  } as IState;

  public render() {
    const {
      translateService: { t },
    } = this.props;

    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this._showScales}>
        <DropdownToggle tag="span" className="pointer" data-test="scale-drop-down-toggle">
          <FontAwesome name="balance-scale" />
        </DropdownToggle>
        <DropdownMenu data-test="scale-drop-down-menu">
          {this.state.scales.map((scale: ScaleModel) => {
            return (
              <DropdownItem onClick={() => this._getScaleData(scale)} key={scale.id}>
                {scale.name}
              </DropdownItem>
            );
          })}
          {!env.isProduction && (
            <DropdownItem onClick={() => this.props.onValue(Math.floor(Math.random() * 100) + 1)}>
              {t.LABORATORY_SCALE_FAKE_RESULT}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }

  private _showScales = async () => {
    const isShown = !this.state.isOpen;
    let common: ICommonData;
    if (isShown) {
      common = await this.props.commonStore.getCommon();
    }

    this.setState({
      isOpen: isShown,
      scales: common ? common.scales : [],
    });
  };

  private _getScaleData = async (scale: ScaleModel) => {
    const {
      translateService: { t },
    } = this.props;

    window.scaleDataCallback = (weight: number, unit: string, stable: boolean, rawData: string) => {
      if (weight !== null) {
        this.props.onValue(weight);
      } else {
        logger.error('Scale error', 'weight is null');
        notification.error(t.LABORATORY_SCALE_CONNECTION_ERROR_MSG);
      }
    };

    // GET SCALE DATA
    if (typeof JSInterface !== 'undefined' && JSInterface && JSInterface.readScale) {
      try {
        JSInterface.readScale(scale.ip, scale.port, 'scaleDataCallback');
      } catch (err) {
        logger.error('Scale error', err);
        notification.error(t.LABORATORY_SCALE_SCALE_ERROR_MSG);
      }
    }
  };
}

export default inject('commonStore', 'translateService')(observer(ScaleComponent));
