import * as React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { throttle } from 'lodash';
import Loading from 'domain/layout/Loading';

interface IInfiniteScrollDefaultProps {
  loader?: JSX.Element;
  isLoadingNeeded?: boolean;
}

export interface IInfiniteScrollProps extends IInfiniteScrollDefaultProps {
  loadMore: () => Promise<void>;
  hasMore: boolean;
  increasePageAmount: () => void;
  children: React.ReactChildren | JSX.Element[];
  shouldScrollToTop?: boolean;
  setShouldScrollToTop?: (value: boolean) => void;
  nodeForScrolling?: Element;
  initialLoading?: boolean;
}

class InfiniteScroll extends React.Component<IInfiniteScrollProps> {
  private _isLoading = false;

  public static defaultProps: IInfiniteScrollDefaultProps = {
    loader: <Loading />,
    isLoadingNeeded: false,
  };

  constructor(props: IInfiniteScrollProps) {
    super(props);

    makeObservable<InfiniteScroll, '_isLoading' | '_changeIsLoading'>(this, {
      _isLoading: observable,
      _changeIsLoading: action,
    });
  }

  public componentDidMount() {
    if (this.props.initialLoading) {
      this._loadItems();
    }
    const node = this.props.nodeForScrolling || window;
    node.addEventListener('scroll', this.handleOnScroll);
  }

  public componentDidUpdate(prevProps: IInfiniteScrollProps) {
    if (this.props.nodeForScrolling !== prevProps.nodeForScrolling) {
      window.removeEventListener('scroll', this.handleOnScroll);
      this.props.nodeForScrolling.addEventListener('scroll', this.handleOnScroll);
    }

    if (this.props.shouldScrollToTop) {
      this.props.nodeForScrolling.scrollTo(0, 0);
      this.props.setShouldScrollToTop && this.props.setShouldScrollToTop(false);
    }
  }

  public componentWillUnmount() {
    const node = this.props.nodeForScrolling || window;

    node.removeEventListener('scroll', this.handleOnScroll);
  }

  public handleOnScroll = throttle(() => {
    const { hasMore, nodeForScrolling } = this.props;
    if (this._isLoading || !hasMore) {
      return;
    }

    const scrollTop = nodeForScrolling ? nodeForScrolling.scrollTop : document.documentElement.scrollTop;
    const scrollHeight = nodeForScrolling ? nodeForScrolling.scrollHeight : document.body.offsetHeight;
    // Checks that the page has scrolled to the bottom
    if (window.innerHeight + scrollTop >= scrollHeight - 200) {
      this.props.increasePageAmount();
      this._loadItems();
    }
  }, 16);

  public render() {
    return (
      <React.Fragment>
        {this.props.children}
        {this.props.isLoadingNeeded && this._isLoading && this.props.loader}
      </React.Fragment>
    );
  }

  private _loadItems = () => {
    this._changeIsLoading(true);
    this.props.loadMore().then(() => {
      this._changeIsLoading(false);
    });
  };

  private _changeIsLoading = (newValue: boolean) => (this._isLoading = newValue);
}

export default observer(InfiniteScroll);
