import { TenantSectionModel } from './TenantSectionModel';
import { action, computed, makeObservable, observable } from 'mobx';
import TenantModel from './TenantModel';

interface ITenantSectionsModelOptions {
  sections: TenantSectionModel[];
}

export class TenantSectionsModel {
  public constructor(options: ITenantSectionsModelOptions) {
    Object.assign(this, options);
    makeObservable(this, {
      sections: observable,
      setSections: action,
      firstActiveSection: computed,
      isFirstSectionActive: computed,
      isLastSectionActive: computed,
    })
  }

  public sections: TenantSectionModel[] = [];

  public setActiveSection = (activeSection: number): void => {
    this.sections.forEach((section: TenantSectionModel, index: number) => {
      section.changeActiveStatus(index === activeSection);
    })
  }

  public goToTheFirstFailedSection = (tenant: TenantModel) => {
    const invalidSections = this.sections.filter((section: TenantSectionModel) => {
      return section.isInvalidSection(tenant);
    });

    if (invalidSections.length) {
      this.setActiveSection(invalidSections[0].index);
    }
  }

  public setSections = (sections: TenantSectionModel[]): void => {
    this.sections = sections;
  }

  public get firstActiveSection() {
    return this.sections.filter(s => s.active)?.[0];
  }

  public get isFirstSectionActive() {
    return this.sections[0]?.active;
  }

  public get isLastSectionActive() {
    return this.sections[this.sections.length - 1]?.active;
  }
}
