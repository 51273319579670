import RootService from 'services/RootService';
import FileModel from 'models/FileModel';

export default abstract class UploadAndDownloadAttachmentsAbstractStore {
  public constructor(protected readonly rootService: RootService) {}

  public abstract urlSection: string;

  public uploadAttachments = (id: string, attachments: FileList): Promise<FileModel[]> | undefined => {
    if (attachments && attachments.length !== 0) {
      const upload = new FormData();
      for (let i = 0; i < attachments.length; i++) {
        upload.append('files', attachments.item(i));
      }

      return this.uploadAttachmentsRequest(id, upload);
    }
  };

  public uploadAttachment = (id: string, attachment: File): Promise<FileModel[]> | undefined => {
    if (attachment) {
      const upload = new FormData();
      upload.append('files', attachment);

      return this.uploadAttachmentsRequest(id, upload);
    }
  };

  public uploadAttachmentsRequest = (id: string, upload: FormData) => {
    return this.rootService.ajaxService.post(`${this.urlSection}/${id}/upload`, upload, {
      skipCounting: true,
    });
  };

  public downloadAttachments = (id: string) => {
    return this.rootService.ajaxService.download(`${this.urlSection}/${id}/download/attachments`);
  };
}
