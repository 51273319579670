import * as React from 'react';
import { inject, observer } from 'mobx-react';

import TranslateService from 'services/TranslateService';
import ModalConfirmation from 'domain/ModalConfirmation';

interface IProps {
  toggleConfirmation: () => void;
  itemName: string;
  isOpen: boolean;
  callbackYes: () => void;
  translateService?: TranslateService;
}

const RefreshConfirmation: React.FC<IProps> = (props) => {
  const {
    translateService: { t },
    itemName,
    isOpen,
    toggleConfirmation,
    callbackYes,
  } = props;

  return (
    <ModalConfirmation
      data-test="refresh-confirmation"
      buttonYesColor="success"
      buttonNo={t.GLOBAL_LABEL_NO}
      buttonYes={t.GLOBAL_LABEL_YES}
      callbackYes={callbackYes}
      heading={t.ADMIN_REFRESH_CONFIRMATION_TITLE(itemName)}
      ico="check"
      isOpen={isOpen}
      text={t.ADMIN_REFRESH_CONFIRMATION_TEXT}
      toggleModal={toggleConfirmation}
    />
  );
};

export default inject('translateService')(observer(RefreshConfirmation));
