import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'Oui';
lang.GLOBAL_LABEL_YES_SMALL = 'Oui';
lang.GLOBAL_LABEL_NO = 'Non';
lang.GLOBAL_LABEL_NO_SMALL = 'non';
lang.GLOBAL_LABEL_SUBMIT = 'Soumettre';
lang.GLOBAL_LABEL_RESULT = 'Résultat';
lang.GLOBAL_LABEL_ERROR = 'Erreur';
lang.GLOBAL_LABEL_REFRESH = 'Rafraîchir';
lang.GLOBAL_LABEL_ADD_NEW = 'Ajouter nouveau';
lang.GLOBAL_LABEL_NAME = 'Nom';
lang.GLOBAL_LABEL_CODE = 'Code';
lang.GLOBAL_LABEL_UNIQUE_ID = 'Identifiant unique';
lang.GLOBAL_LABEL_LOCATION = 'Emplacement';
lang.GLOBAL_LABEL_ONLINE = 'en ligne';
lang.GLOBAL_LABEL_IS_DEFAULT = 'est par défaut';
lang.GLOBAL_LABEL_MODEL = 'Modèle';
lang.GLOBAL_LABEL_IP = 'IP';
lang.GLOBAL_LABEL_SAVE = 'Enregistrer';
lang.GLOBAL_LABEL_DELETE = 'Effacer';
lang.GLOBAL_LABEL_CANCEL = 'Annuler';
lang.GLOBAL_LABEL_UPLOAD = 'Télécharger';
lang.GLOBAL_LABEL_FILE = 'Fichier';
lang.GLOBAL_LABEL_PORT = 'Port';
lang.GLOBAL_LABEL_FIRST_NAME = 'Prénom';
lang.GLOBAL_LABEL_LAST_NAME = 'Nom';
lang.GLOBAL_LABEL_USERNAME = 'Nom d\'utilisateur';
lang.GLOBAL_LABEL_PASSWORD = 'Mot de passe';
lang.GLOBAL_LABEL_EMAIL = 'Email';
lang.GLOBAL_LABEL_ROLE = 'Rôle';
lang.GLOBAL_LABEL_STATUS = 'Statut';
lang.GLOBAL_LABEL_REMARKS = 'Remarques';
lang.GLOBAL_LABEL_SELECT = 'Sélectionner';
lang.GLOBAL_LABEL_GRN = 'BE';
lang.GLOBAL_LABEL_IN_PROGRESS = 'En cours';
lang.GLOBAL_LABEL_COMPLETED = 'Clôturé';
lang.GLOBAL_LABEL_REGISTER = 'Enregistrer';
lang.GLOBAL_LABEL_OPERATION = 'Chantier';
lang.GLOBAL_LABEL_SUPPLIER = 'Fournisseur';
lang.GLOBAL_LABEL_SUPPLIER_CITY = 'ville fournisseur';
lang.GLOBAL_LABEL_DRIVER = 'Chauffeur';
lang.GLOBAL_LABEL_LICENCE_PLACE = 'Immatriculation';
lang.GLOBAL_LABEL_TO_CLAIM = 'De réclamer';
lang.GLOBAL_LABEL_CREATED = 'Créé';
lang.GLOBAL_LABEL_ACTIONS = 'Actions';
lang.GLOBAL_LABEL_CLAIM = 'Prendre en charge';
lang.GLOBAL_LABEL_DESCRIPTION = 'Description';
lang.GLOBAL_LABEL_ADD = 'Ajouter';
lang.GLOBAL_LABEL_NOT_CLAIMED = 'Non pris en charge';
lang.GLOBAL_LABEL_DELIVERIES = 'Livraisons';
lang.GLOBAL_LABEL_SAVE_ATTACHMENTS = 'Enregistrer pièces jointes';
lang.GLOBAL_LABEL_UPLOAD_ATTACHMENTS = 'Télécharger pièces jointes';
lang.GLOBAL_LABEL_ATTACHMENTS = 'Pièces jointes';
lang.GLOBAL_LABEL_ATTACHMENT = 'Pièce jointe';
lang.GLOBAL_LABEL_ADVISED_GOODS = 'Marchandises annoncées';
lang.GLOBAL_LABEL_SYNC = 'Synchronisation';
lang.GLOBAL_LABEL_CLOSE = 'Fermer';
lang.GLOBAL_LABEL_VERIFY = 'Vérifier';
lang.GLOBAL_LABEL_ALERT = 'LOT ANNONCE BLOQUE !';
lang.GLOBAL_LABEL_ALERT_RECEIVED_GOOD = 'LOT RECU BLOQUE !';
lang.GLOBAL_LABEL_CLAIM_BY = 'Pris en charge par';
lang.GLOBAL_LABEL_CLASSIFIED_BY = 'Trié par';
lang.GLOBAL_LABEL_VERIFIED_BY = 'Vérifié par';
lang.GLOBAL_LABEL_ROLLEDBACK_BY = 'Annulé par';
lang.GLOBAL_LABEL_FLAGGED_BY = 'Signalé par';
lang.GLOBAL_LABEL_UNFLAGGED_BY = 'Non signalé par';
lang.GLOBAL_LABEL_TRANSFERS = 'transferts';
lang.GLOBAL_LABEL_TRANSFERED = 'Transféré';
lang.GLOBAL_LABEL_WEIGHT = 'Poids';
lang.GLOBAL_LABEL_CREATE = 'Créer';
lang.GLOBAL_LABEL_CHARACTERS_REMAINING = (number) => `Caractères restants: ${number}`;
lang.GLOBAL_LABEL_HIDE = 'Masquer';
lang.GLOBAL_LABEL_UNHIDE = 'Afficher';
lang.GLOBAL_LABEL_SHOW = 'Afficher';
lang.GLOBAL_LABEL_LANGUAGE = (language) => `Langue: ${language}`;
lang.GLOBAL_LABEL_FILE_DOWNLOADED_MSG = 'fichier téléchargé';
lang.GLOBAL_LABEL_DECIMALS = 'décimales';
lang.GLOBAL_LABEL_GO_BACK = 'Retour';
lang.GLOBAL_LABEL_VERSION = (version) => `Version - ${version}`;
lang.GLOBAL_LABEL_CONFIRM = 'Confirmer';
lang.GLOBAL_LABEL_FIELD = 'champ';
lang.GLOBAL_LABEL_FIELDS = 'Champs';
lang.GLOBAL_LABEL_NONE = 'Aucun';
lang.GLOBAL_LABEL_ALL_TENANTS = 'Tous les chantiers';
lang.GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR = 'Le nom du champ de saisie n\'est pas défini';
lang.GLOBAL_LABEL_UNCLAIM = 'Annuler la prise en charge';
lang.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE = 'Date d\'expiration du certificat';
lang.GLOBAL_LABEL_CERT_AUT_RIF_DATE = 'Cert. aut. rif.';
lang.GLOBAL_LABEL_CERT_715_13 = 'Cert. 715/13';
lang.GLOBAL_LABEL_TYPE = 'Type';
lang.GLOBAL_LABEL_REVISION_REQUEST = 'Demande de révision';
lang.GLOBAL_LABEL_EXPIRED = 'Expiré';
lang.GLOBAL_LABEL_NO_CERTIFICATE = 'Aucun certificat';
lang.GLOBAL_LABEL_RESPONSIBLE = 'Responsable';
lang.GLOBAL_LABEL_DEPARTMENT = 'Département';
lang.GLOBAL_LABEL_EXPORT = 'Exporter';
lang.GLOBAL_LABEL_REEXPORT = 'Réexporter';
lang.GLOBAL_LABEL_FLAGGED = 'Bloqué';
lang.GLOBAL_LABEL_EXPORTED = 'Exporté';
lang.GLOBAL_LABEL_SYNCED = 'Synchronisé';
lang.GLOBAL_LABEL_PRINT = 'Impression';
lang.GLOBAL_LABEL_FLAG = 'BLOQUER';
lang.GLOBAL_LABEL_UN_FLAG = 'LIBERER';
lang.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER = 'Pas de numéro d\'autorisation';
lang.GLOBAL_LABEL_AUTHORIZATION_EXPIRED = 'Autorisation expirée';
lang.GLOBAL_LABEL_NO_EXPIRATION_DATE = 'Pas de date d\'expiration';
lang.GLOBAL_LABEL_AUTH_NUMBER = 'Numéro d\'autorisation';
lang.GLOBAL_LABEL_EXP_DATE = 'Date d\'expiration';
lang.GLOBAL_LABEL_IS_NOT_VALID = (name) => `${name} n\'est pas valide`;
lang.GLOBAL_LABEL_INVALID_FIELDS = (name) => `Champs non valides: ${name}`;
lang.GLOBAL_LABEL_USER_ROLES = 'Rôles utilisateur';
lang.GLOBAL_LABEL_DEFAULT_ROLE = 'Rôle par défaut';
lang.GLOBAL_LABEL_DEFAULT_TENANT = 'Chantier par défaut';
lang.GLOBAL_LABEL_NEW_VERSION_RELEASED = 'Une nouvelle version est sortie';
lang.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION = 'Pour appliquer les modifications - actualisez la page. Toutes les modifications non enregistrées seront perdues!';
lang.GLOBAL_LABEL_TENANTS_AVAILABLE = 'Chantiers disponibles';
lang.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE = (roleName) => `Chantiers disponibles pour ${roleName}`;
lang.GLOBAL_LABEL_IMPORT = 'Importer';
lang.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE = (date) => `Votre mot de passe expire le ${date}. Merci de le modifier au plus tôt pour éviter le blocage de votre compte`;
lang.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE = 'Votre mot de passe a expiré. Veuillez en définir un nouveau';
lang.GLOBAL_LABEL_PROCESSING = 'En traitement';
lang.GLOBAL_LABEL_PRICE = 'Prix';
lang.GLOBAL_LABEL_UNIT = 'Unité';
lang.GLOBAL_LABEL_BREAKDOWN = 'Panne';
lang.GLOBAL_LABEL_PASSWORD_EXP_DATE = 'Date d\'expiration du mot de passe';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA = 'Italie';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO = 'Etranger';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_DITTA = 'Entreprise';
lang.GLOBAL_LABEL_SUPPLIERS_DETAILS = 'Détails des fournisseurs';
lang.GLOBAL_LABEL_FORM = 'Forme';
lang.GLOBAL_LABEL_QUALITY = 'Qualité';
lang.GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS = 'Actualiser les variantes de produit';
lang.GLOBAL_LABEL_WEIGHT_UNITS = 'Unités de poids';
lang.GLOBAL_LABEL_EMPLOYEE_ID = 'ID de l\'employé';
lang.GLOBAL_LABEL_LEGAL_ENTITY = 'Entité légale';
lang.GLOBAL_LABEL_PRODUCT_VARIANTS = 'Variantes de produits';
lang.GLOBAL_LABEL_REFRESH_COMMON = (name) => `Rafraîchir ${name}`;
lang.GLOBAL_LABEL_TOTAL = 'Le total';
lang.GLOBAL_LABEL_SHAREPOINT = 'Point de partage';
lang.GLOBAL_LABEL_PARTIAL_SIGN_OFF = '.Déconnexion partielle';
lang.GLOBAL_LABEL_TITLE = 'Titre';
lang.GLOBAL_LABEL_CLIENT_ID = 'Identité du client';
lang.GLOBAL_LABEL_CLIENT_SECRET = 'Service client';
lang.GLOBAL_LABEL_CONNECTION_ADDRESS = 'Adresse de connexion';
lang.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT = 'Activer la déduction du solde par défaut';
lang.GLOBAL_LABEL_DETAILED_WEIGHING = 'Pesée détaillée';
lang.GLOBAL_LABEL_UPDATE = 'Mise à jour';
lang.GLOBAL_FILTERKEY_DATE_ASC = 'Date ASC';
lang.GLOBAL_FILTERKEY_DATE_DESC = 'Date DESC';
lang.GLOBAL_FILTERKEY_STATUS_ASC = 'statut ASC';
lang.GLOBAL_FILTERKEY_STATUS_DESC = 'statut DESC';
lang.GLOBAL_FILTERKEY_ALL = 'Tous';
lang.GLOBAL_FILTERKEY_ONLY_MY = 'Seulement les miens';
lang.GLOBAL_FILTERKEY_ONLY_UNCLAIMED = 'Seulement les lots non pris en charge';
lang.GLOBAL_FILTERKEY_ONLY_UNSYNCED = 'Seulement les lots non synchronisés';
lang.GLOBAL_FILTERKEY_IN_PROGRESS = 'En cours';
lang.GLOBAL_FILTERKEY_IN_LAB = 'Au labo';
lang.GLOBAL_FILTERKEY_READY_FOR_TIER_2 = 'Prêt pour le tri';
lang.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF = 'Prêt pour validation';
lang.GLOBAL_FILTERKEY_SIGNED_OFF = 'Validé';
lang.GLOBAL_FILTERKEY_COMPLETE = 'Clôturé';
lang.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF = 'Partiellement signé';
lang.GLOBAL_RADIOBUTTON_DRY = 'Sec';
lang.GLOBAL_RADIOBUTTON_RAIN = 'Pluie';
lang.GLOBAL_RADIOBUTTON_SNOW = 'Neige';
lang.GLOBAL_RADIOBUTTON_DUMP = 'Camion benne';
lang.GLOBAL_RADIOBUTTON_VAN = 'Fourgon';
lang.GLOBAL_RADIOBUTTON_FLATBED = 'Tautliner';
lang.GLOBAL_RADIOBUTTON_ROLL_OFF_BOX = 'Ampliroll';
lang.GLOBAL_RADIOBUTTON_RAILCAR = 'Wagon';
lang.GLOBAL_RADIOBUTTON_OTHER = 'Autre';
lang.GLOBAL_USERROLE_SUPERADMIN = 'Super admin';
lang.GLOBAL_USERROLE_ADMIN = 'Admin';
lang.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR = 'Pont-bascule';
lang.GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE = 'Pont-bascule externe';
lang.GLOBAL_USERROLE_TIER3 = 'Niveau 3';
lang.GLOBAL_USERROLE_TIER2 = 'Niveau 2';
lang.GLOBAL_USERROLE_TIER1 = 'Niveau 1';
lang.GLOBAL_USERROLE_MANAGER = 'Manager';
lang.GLOBAL_USERROLE_LAB = 'Laboratoire';
lang.GLOBAL_USERROLE_TRADER = 'Commercial';
lang.GLOBAL_USERROLE_TRADER_MOBILE = 'Com.';
lang.GLOBAL_USERROLE_BACKOFFICE = 'Back Office';
lang.GLOBAL_USERROLE_DATAANALYZER = 'Analyseur de données';
lang.GLOBAL_LOGIN_HEADER = 'Identification';
lang.GLOBAL_LOGIN_TITLE = 'Connectez-vous à votre compte';
lang.GLOBAL_LOGOUT_SUCCESS_MSG = 'Vous avez été déconnecté';
lang.GLOBAL_DATERANGEPICKER_START_DATE_PLH = 'Date de début';
lang.GLOBAL_DATERANGEPICKER_END_DATE_PLH = 'Date de fin';
lang.GLOBAL_RECOVERPASSWORD_HEADER = 'Définir <br /> le mot de passe';
lang.GLOBAL_RECOVERPASSWORD_UPDATE_MSG = 'Le mot de passe a été mis à jour';
lang.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN = 'Définir le mot de passe';
lang.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD = 'Mot de passe actuel';
lang.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD = 'nouveau mot de passe';
lang.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD = 'Resaisissez le nouveau mot de passe';
lang.GLOBAL_FILE_SELECT_ERROR = 'Erreur lors de la sélection du fichier';
lang.GLOBAL_SERVERERROR_DEFAULT = 'Quelque chose s\'est mal passé ...';
lang.GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR = 'Échec service externe ...';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD = 'T2 ne peut pas libérer un lot bloqué';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD = 'T2 ne peut pas libérer un lot reçu bloqué';
lang.GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK = 'T2 ne peut pas réinitialiser le statut d\'un lot';
lang.GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD = 'Trader ne peut pas libérer un lot bloqué';
lang.GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS = 'L\'administrateur ne peut pas réinitialiser un lot annoncé avec le statut actuel';
lang.GLOBAL_SERVERERROR_T1_CANNOT_CLAIM = 'T1 ne peut pas prendre en charge un lot';
lang.GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD = 'T1 ne peut pas décharger un lot';
lang.GLOBAL_SERVERERROR_T1_CANNOT_VERIFY = 'T1 ne peut pas vérifier un lot';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED = 'Impossible de vérifier le lot parce que la livraison n\'est pas encore synchronisée';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_ADVISED_GOOD_NOT_UNLOADED = '.Impossible de complète le lot parce que la livraison n\'est pas encore déchargée';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF = 'Lot bloqué ! Ne peut pas être validé';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF = '.Le bien conseillé n\'est pas prêt pour la signature';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED = '.Tous les articles doivent être codés en stock avant que le bien avisé puisse être approuvé';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT = '.Vous ne pouvez pas vous déconnecter conseillé si vous l\'avez déjà vérifié';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT = '.Le bien conseillé n\'a pas de poids à vide renseigné';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT = '.La déconnexion sur le bon niveau conseillé est désactivée pour le locataire';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF = 'Impossible de mettre à jour le lot si la livraison a été validée';
lang.GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED = '.Le commentaire ne peut pas être mis à jour si AG est déchargé';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT = 'Impossible de vérifier le lot, la tare annoncée est manquante';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT = 'Impossible de vérifier le lot, la tare est manquante';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING = 'Tous les codes stock doivent être remplis avant que la marchandise puisse être vérifiée';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB = 'Il y a encore des analyses en attente au laboratoire';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY = (fullname) => `Le lot est déjà pris en charge par ${fullname}`;
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF = 'La réinitialisation ne peut pas être faite une fois la réception validée';
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF = '.La restauration ne peut pas être effectuée une fois que le bien avisé est signé';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST = 'Une livraison avec des analyses labo ne peut être effacée';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_LAB_SAMPLES_EXIST = 'Le lot contenant les échantillons de laboratoire ne peut pas être supprimé';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_LAST_ADVISED_GOOD_IN_DELIVERY = '.La livraison comprend 1 seul bien conseillé. Veuillez plutôt supprimer la livraison';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE = 'Le modèle ne prend pas en charge plus de 11 marchandises triées pour un lot';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG = 'Maximum 10 marchandises triées pour un lot';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG = 'Maximum 19 marchandises triées pour un lot';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE = 'Délai d\'attente dépassé lors du transfert de l\'image de la caméra';
lang.GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED = 'Merci de calibrer la caméra avant utilisation';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT = 'Délai d\'attente dépassé lors du transfert de résultats OCR';
lang.GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND = 'Le rôle Admin n\'existe pas';
lang.GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND = '.Le rôle AppRegistration n\'existe pas';
lang.GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED = '.Seule la modification des comptes de type AppRegistration est autorisée';
lang.GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR = '.Le secret client nécessite au moins 20 caractères, une lettre majuscule, un chiffre, un caractère spécial';
lang.GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS = '.L\'ID client existe déjà';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE = '.Le titre d\'enregistrement de l\'application n\'est pas valide';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION = '.La description de l\'enregistrement de l\'application n\'est pas valide';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20 = '.Le secret client nécessite au moins 20 caractères';
lang.GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS = 'Impossible de supprimer le chantier parce que certains utilisateurs lui sont affectés';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES = '.Impossible de définir la séquence pour le locataire car il a déjà créé des livraisons';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES = '.Impossible de définir l\'entité légale pour le locataire car il a déjà créé des livraisons';
lang.GLOBAL_SERVERERROR_CANNOT_CHANGE_TENANT_WORKFLOW = '.Le flux de travail ne peut pas être modifié';
lang.GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT = '.Ce site ne peut pas être utilisé car il est déjà utilisé par un autre locataire';
lang.GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY = '.Veuillez fournir le site';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY = '.Veuillez fournir un dossier SharePoint pour le téléchargement des pièces jointes';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST = '.Le dossier SharePoint sélectionné n\'existe pas';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT = '.Le dossier SharePoint pour le téléchargement des pièces jointes n\'est pas sélectionné pour le locataire. Veuillez contacter votre SuperAdmin';
lang.GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE = '.L\'application dOps n\'est pas autorisée à télécharger des fichiers sur le site SharePoint sélectionné. Veuillez contacter votre SuperAdmin';
lang.GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT = '.Erreur de service externe : échec de la mise à jour des métadonnées des pièces jointes sur SharePoint Online';
lang.GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT = '.Erreur de service externe : impossible de se connecter à SharePoint Online';
lang.GLOBAL_SERVERERROR_SHAREPOINT_FOLDER_NOT_FOUND = '.Erreur de service externe : dossier SharePoint pour le téléchargement des pièces jointes introuvable. Veuillez contacter le superadministrateur';
lang.GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED = 'La livraison ne peut pas être mise à jour après synchronisation';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER = 'Le numéro du bon de livraison est obligatoire pour la synchronisation';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED = 'Impossible de supprimer les marchandises déjà prises en charge';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT = 'La tare est obligatoire pour la synchronisation de livraison';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD = 'Un sous-lot annoncé doit avoir au moins une qualité reçue';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS = 'Une marchandise annoncée doit contenir au moins un article dans le détail de livraison';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD = 'La livraison doit contenir au moins une marchandise annoncée';
lang.GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED = 'Impossible d\'enregistrer la tare tant que le lot n\'est pas pris en charge';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED = 'Tous les articles doivent être codés en stock pour valider la réception';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED = 'Toutes les analyses labo doivent être disponibles pour valider la réception';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = 'Il y a des lots annoncés pour lesquels la tare n\'est pas renseignée';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT = 'Il y a des lots reçus pour lesquels le poids brut n\'est pas renseigné';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE = 'Certaines marchandises annoncées n\'ont pas de code déchet EWC rempli';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'La livraison doit être synchronisée avant validation';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '.Impossible de se déconnecter conseillé bon, la livraison doit être synchronisée';
lang.CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF = '.Toutes les marchandises conseillées doivent être prêtes à être approuvées avant que la livraison puisse être approuvée';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = 'Impossible de clôturer la livraison tant que tous les poids de tare ne sont pas renseignés';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF = 'Impossible de clôturer la livraison si elle n\'est pas validée';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'La livraison ne peut pas être re-synchronisée, car elle n\'est pas encore synchronisée à la bascule';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF = 'Le tri ne peut pas être re-synchronisé, car il n\'a pas encore été validé';
lang.GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS = 'Une marchandise avec le même code existe déjà';
lang.GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY = (grn) => `Le produit est utilisé par la livraison ${grn}`;
lang.GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED = 'Impossible de modifier les marchandises annoncées avant de les avoir pris en charge';
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD = 'Impossible d\'ajouter des analyses labo pour les éléments dont le tri est terminé';
lang.GLOBAL_SERVERERROR_STOCK_CODE_INVALID = (stockCode) => `Le code stock fourni ${stockCode} est invalide`;
lang.GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND = (userRole) => `ID de rôle utilisateur ${userRole} introuvable`;
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD = 'Impossible d\'ajouter des analyses labo pour les lots triés non pris en charge';
lang.GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR = 'Une exception est survenue lors de la génération du QR code';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT = 'Le poids d\'une marchandise ne peut pas être NUL lors du transfert';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED = 'Le poids maximum à transférer est le poids total des marchandises reçues';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT = 'Le poids du lot doit être saisi avant le transfert';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED = 'Le poids maximum à transférer ne peut être supérieur au poids du lot';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT = 'Impossible de transférer un marchandise reçue avec un poids négatif';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD = 'Impossible de transférer la marchandise reçue au complet';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD = 'Impossible de transférer la totalité du lot';
lang.GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD = 'Impossible de transférer le lot si un seul poste est annoncé';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN = 'Impossible d\'attribuer le rôle de super admin à l\'utilisateur';
lang.GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR = 'Le mot de passe doit avoir au moins 10 caractères, une majuscule, un chiffre, un caractère spécial';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN = 'Vous ne pouvez pas supprimer d\'autres admins';
lang.GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR = 'erreur non documentée';
lang.GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL = 'Erreur lors de l\'envoi de l\'e-mail';
lang.GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST = 'Erreur lors du traitement de la requête';
lang.GLOBAL_SERVERERROR_FILE_NOT_FOUND = 'Fichier non trouvé';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND = 'Marchandise annoncée non trouvée';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND = 'Pièce jointe à la marchandise non trouvée';
lang.GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND = 'Contamination non trouvée';
lang.GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND = 'Sous-lot annoncé non trouvé';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND = 'Marchandise reçue non trouvée';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79 = 'La description de la marchandise triée ne doit pas dépasser 79 caractères';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_FOUND = 'Appareil photo introuvable';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE = 'Appareil photo inactif';
lang.GLOBAL_SERVERERROR_TENANT_NOT_FOUND = 'Chantier introuvable';
lang.GLOBAL_SERVERERROR_ADMIN_NOT_FOUND = 'Admin non trouvé';
lang.GLOBAL_SERVERERROR_WEIGHBRIDGE_USER_NOT_FOUND = 'Utilisateur de pont-bascule non trouvé';
lang.GLOBAL_SERVERERROR_TRADER_USER_NOT_FOUND = 'Utilisateur commerçant non trouvé';
lang.GLOBAL_SERVERERROR_USER_NOT_FOUND = 'Utilisateur non trouvé';
lang.GLOBAL_SERVERERROR_LOCATION_NOT_FOUND = 'Emplacement non trouvé';
lang.GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS = 'Emplacement déjà existant';
lang.GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND = 'Livraison non trouvée';
lang.GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND = 'Pièce jointe à la livraison non trouvée';
lang.GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND = 'Fournisseur non trouvé';
lang.GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND = 'Type de fournisseur introuvable';
lang.GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND = 'Transporteur non trouvé';
lang.GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND = 'Marchandise non trouvée';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND = 'Type d\'entrée labo non trouvé';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW = 'Type d\'entrée labo non trouvé pour flux de travail';
lang.GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND = 'Entrée labo non trouvée';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED = 'Statut labo non pris en charge';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW = 'Statut labo non trouvé pour flux de travail';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND = 'Code stock non trouvé';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS = 'Le code stock existe déjà';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5 = 'La longueur du code stock doit être de 5 chiffres';
lang.GLOBAL_SERVERERROR_PRINTER_NOT_FOUND = 'Imprimante non trouvée';
lang.GLOBAL_SERVERERROR_SCALE_NOT_FOUND = 'Pont-bascule non trouvé';
lang.GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND = 'Modèle d\'impression non trouvé';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND = 'Forme de produit introuvable';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM = 'La forme de produit n\'est pas disponible pour le code stock choisi';
lang.GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND = 'Qualité du produit introuvable';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND = 'Variante de produit introuvable';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF = '.Des variantes de produit sont requises pour la validation';
lang.GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS = 'Ce nom d\'utilisateur existe déjà';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF = 'Vous ne pouvez pas vous supprimer vous-même';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD = 'Nom d\'utilisateur ou mot de passe invalide';
lang.GLOBAL_SERVERERROR_CANNOT_LOGIN_TENANT_IS_DELETED = '.Ne peut pas se connecter au locataire car il a été supprimé';
lang.GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE = 'Veuillez choisir un mot de passe différent. Celui-ci a déjà été utilisé auparavant';
lang.GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED = '.Le secret client actuel est utilisé depuis 2 ans maintenant et a expiré. Veuillez contacter le super-administrateur';
lang.GLOBAL_SERVERERROR_INVALID_EMAIL = 'Merci de saisir une adresse email valide';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME = 'Merci de saisir un nom d\'utilisateur';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD = 'Merci de saisir un mot de passe';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10 = 'La longueur du mot de passe est de 10 caractères minimum';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_ID = '.Identifiant client invalide';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET = '.Code secret client invalide';
lang.GLOBAL_SERVERERROR_INVALID_FIRSTNAME = 'Merci de saisir un prénom';
lang.GLOBAL_SERVERERROR_INVALID_LASTNAME = 'Merci de saisir un nom';
lang.GLOBAL_SERVERERROR_INVALID_USER_ROLES = 'Merci de saisir les rôles de l\'utilisateur';
lang.GLOBAL_SERVERERROR_INVALID_USER_TENANTS = 'Merci de saisir les chantiers de l\'utilisateur';
lang.GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID = '.L\'identifiant de l\'employé externe doit comporter au moins 6 symboles';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED = 'Le champ d\'identification d\'un employé externe est obligatoire';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND = '.Employé externe avec l\'identifiant fourni introuvable';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT = (tenantName) => `.L\'identifiant de l\'employé sélectionné n\'est pas éligible pour travailler sur le locataire : ${tenantName}`;
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE = 'Veuillez fournir un seul rôle par défaut pour l\'utilisateur';
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT = 'Veuillez fournir un seul chantier par défaut pour l\'utilisateur';
lang.GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES = 'Le rôle actif de l\'utilisateur doit correspondre à l\'un des rôles d\'utilisateur existants';
lang.GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT = 'Vous n\'êtes pas autorisé à accéder au chantier sélectionné';
lang.GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN = 'Seul un Super Admin peut créer ou modifier un utilisateur avec le rôle "Admin"';
lang.GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY = 'L\'utilisateur ne peut pas avoir simultanément les rôles Commercial et BackOffice';
lang.GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES = '.L\'utilisateur avec le rôle DataAnalyzer ne peut pas avoir plusieurs rôles';
lang.GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN = 'On ne peut attribuer plusieurs chantiers que si l\'utilisateur a le rôle Commercial, BackOffice ou Admin';
lang.GLOBAL_SERVERERROR_INVALID_NAME = 'Merci de saisir un nom';
lang.GLOBAL_SERVERERROR_ACCESS_DENIED = 'Accès refusé';
lang.GLOBAL_SERVERERROR_SESSION_NOT_FOUND = 'Session non trouvée';
lang.GLOBAL_SERVERERROR_SESSION_EXPIRED = 'La session a expiré';
lang.GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE = 'Veuillez réessayer dans 1 minute';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING = 'Merci de saisir le nom de l\'article de stock';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD = 'Vous ne pouvez pas valider la livraison si vous avez déjà vérifié l\'un des produits annoncés';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS = 'Un fournisseur existe déjà avec le code indiqué';
lang.GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING = 'Merci de saisir le nom du fournisseur';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING = 'Merci de saisir le code fournisseur';
lang.GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS = 'Merci de saisir la raison du blocage';
lang.GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION = 'Merci de saisir une description';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255 = 'La description d\'un lot trié ne peut pas dépasser 255 caractères';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION = 'Merci de saisir des informations sur la marchandise';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID = 'Merci de saisir le code de la marchandise';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT = 'Merci de saisir le poids de la marchandise';
lang.GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID = 'Merci de saisir le code du sous-lot annoncé';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1 = 'Merci de saisir une valeur pour p1';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2 = 'Merci de saisir une valeur pour p2';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3 = 'Merci de saisir une valeur pour p3';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4 = 'Merci de saisir une valeur pour p4';
lang.GLOBAL_SERVERERROR_PROVIDE_CONTRAST = 'Merci de saisir une valeur de contraste';
lang.GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE = 'Merci de saisir le type d\'affichage';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME = 'Merci de saisir le nom de la caméra';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE = 'Merci de saisir l\'emplacement de la caméra';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID = 'Merci de saisir l\'ID de la caméra';
lang.GLOBAL_SERVERERROR_PROVIDE_SUPPLIER = 'Merci de saisir le fournisseur';
lang.GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER = 'Merci de saisir le transporteur';
lang.GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT = 'Merci de saisir le secteur';
lang.GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30 = 'Les remarques ne peuvent pas dépasser 30 caractères';
lang.GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER = 'Merci de saisir le numéro d\'immatriculation du véhicule';
lang.GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM = 'Merci de saisir le code stock';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION = 'Merci de saisir une description annoncée';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30 = 'La description de la marchandise annoncée ne peut dépasser 30 caractères';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50 = 'La description de la marchandise annoncée ne peut dépasser 50 caractères';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100 = 'La description de la marchandise annoncée ne peut dépasser 100 caractères';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMMENT_MAX_LENGTH_100 = 'Le commentaire ne peut pas dépasser 100 caractères';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNLOADED_ATTACHMENTS_MISSING_ERROR = 'Veuillez ajouter des pièces jointes';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMPLETED_ATTACHMENTS_MISSING_ERROR = 'Veuillez ajouter des pièces jointes';
lang.GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER = 'Merci de saisir un numéro de pesée du pont-bascule';
lang.GLOBAL_SERVERERROR_PROVIDE_LOCATION = 'Merci de saisir un emplacement';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE = 'Merci de saisir le code de la marchandise';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME = 'Merci de saisir le nom de la marchandise';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME = 'Merci de saisir le nom de l\'imprimante';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP = 'Merci de saisir l\'adresse IP de l\'imprimante';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL = 'Merci de saisir le modèle d\'imprimante';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS = '.Veuillez fournir l\'adresse de connexion de l\'imprimante';
lang.GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255 = '.La description peut contenir jusqu\'à 255 caractères';
lang.GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255 = '.L\'adresse peut contenir jusqu\'à 255 caractères';
lang.GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50 = '.Le nom peut contenir jusqu\'à 255 caractères';
lang.GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND = '.Type de document introuvable';
lang.GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME = '.Veuillez fournir le nom du rapport Lasernet';
lang.GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255 = '.Le nom du rapport Lasernet peut contenir jusqu\'à 255 caractères';
lang.GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES = '.Veuillez indiquer le nombre de copies par défaut';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID = '.Veuillez fournir l\'ID de l\'imprimante';
lang.GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS = '.L\'imprimante avec le nom fourni existe déjà';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND = '.Configuration de l\'imprimante introuvable';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS = '.La configuration de l\'imprimante avec les paramètres fournis existe déjà';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES = '.Veuillez indiquer le nombre d\'exemplaires';
lang.GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER = 'Merci de saisir le poids à transférer';
lang.GLOBAL_SERVERERROR_PROVIDE_STATUS = 'Merci de saisir le nouveau statut';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME = 'Merci de saisir le nom de la bascule';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_IP = 'Merci de saisir l\'adresse IP de la bascule';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT = 'Merci de saisir le nom du port de la bascule';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE = 'Merci de saisir le type de livraison';
lang.GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION = 'Merci de saisir les conditions météorologiques';
lang.GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE = 'Merci de saisir le type de transaction';
lang.GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE = 'Merci de saisir le prix de traitement';
lang.GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND = 'Prix de traitement introuvable';
lang.GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH = 'La résolution d\'image est trop élevée';
lang.GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED = 'Seuls les fichiers PDF et les images sont autorisés en pièces jointes';
lang.GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY = 'BE ne peut pas être vide';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION = (transfers) => `Impossible de supprimer les marchandises liées à des codes de transfert : ${transfers}`;
lang.GLOBAL_SERVERERROR_UNSUPPORTED_ACTION = 'Action non prise en charge';
lang.GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE = '.Aucun locataire D365 n\'est disponible sur ce serveur';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS = 'Impossible de créer l\'entrée labo, car une autre entrée labo de même type existe déjà';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT = 'Le poids déduit du lot ne peut pas être vide';
lang.GLOBAL_SERVERERROR_MACHINE_NOT_FOUND = 'Machine introuvable';
lang.GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND = 'lot cible introuvable';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING = (rgIdentifier) => `Merci de saisir le poids pour: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING = (rgIdentifier) => `Merci de saisir la machine dans : ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING = (rgIdentifier) => `Merci de saisir WI fait dans : ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING = (rgIdentifier) => `Merci de saisir la densité apparente dans: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING = (rgIdentifier) => `Merci de saisir la description de la marchandises dans: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING = (rgIdentifier) => `Merci de saisir la qualité trouvée dans: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING = (rgIdentifier) => `Merci de saisir le type principal dans: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING = (rgIdentifier) => `Merci de saisir le lot cible dans: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING = 'Merci de saisir le nom du chantier';
lang.GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING = 'Merci de saisir le code du chantier';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING = 'Merci de saisir le code lieu du chantier';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS = 'Le code lieu du chantier ne peut dépasser 10 caractères';
lang.GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING = 'Merci de saisir le préfixe du BE du chantier';
lang.GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING = 'Merci de saisir la valeur actuelle du BE pour le chantier';
lang.GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING = 'Merci de saisir le flux de travail du chantier';
lang.GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING = 'Merci de saisir le fuseau horaire du chantier';
lang.GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING = 'Merci de saisir le code région du chantier';
lang.GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS = 'Le code région du chantier ne peut pas dépasser 10 caractères';
lang.GLOBAL_SERVERERROR_TENANT_COMMENT_MAX_CHARS = '.Le commentaire du chantier ne peut pas dépasser 100 caractères';
lang.GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING = '.Veuillez indiquer l\'entité juridique du locataire';
lang.GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT = 'Seul le propriétaire de la pièce jointe ou l\'administrateur peut supprimer la pièce jointe';
lang.GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING = 'La configuration du noeud d\'intégration est manquante. Veuillez contacter votre administrateur.';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED = 'La prise en charge d\'un lot peut être annulée seulement si son statut est PRIS EN CHARGE';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN = 'La prise en charge d\'un lot ne peut être annulée que par la personne qui l\'a prise en charge ou par l\'administrateur';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS = 'La prise en charge d\'un lot ne peut être annulée que si des données labo n\'ont pas été saisies';
lang.GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS = 'L\'emballage existe déjà';
lang.GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND = 'Emballage introuvable';
lang.GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT = 'La somme des valeurs des champs Contamination doit être de 100%';
lang.GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND = 'Temps de traitement non trouvé';
lang.GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING = (rgIdentifier) => `Merci de saisir la contamination dans: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_INVALID_CODE = 'Merci de saisir le code';
lang.GLOBAL_SERVERERROR_PROVIDE_ORIGIN = 'Merci de saisir le lieu de chargement de la livraison';
lang.GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND = 'Lieu de chargement introuvable';
lang.GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35 = 'La longueur maximum du lieu de chargement doit être de 35 lettres';
lang.GLOBAL_SERVERERROR_EWC_NOT_FOUND = 'Code déchet EWC introuvable';
lang.GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS = '.Code déchet EWC existe déjà';
lang.GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS = '.GRN existe déjà';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF = 'La réception ne peut pas être exportée car elle n\'est pas encore validée';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED = 'Merci d\'utiliser un filtre pour réduire le nombre de livraisons à exporter. La liste de livraison sélectionnée est trop longue.';
lang.GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND = 'Mesure non trouvée';
lang.GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND = 'Analyse spectro introuvable';
lang.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS = (joinedlabelslist) => `Veuillez fournir: ${joinedlabelslist}`;
lang.GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH = (allowedLength) => `La longueur du code utilisateur doit être de ${allowedLength} lettres`;
lang.GLOBAL_SERVERERROR_USER_WITH_THIS_CODE_ALREADY_EXISTS = '.L\'utilisateur avec ce code existe déjà sur le locataire';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE = 'Veuillez indiquer la date de création de la livraison';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER = 'Veuillez indiquer le numéro de ligne du lot annoncé';
lang.GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE = 'Veuillez saisir le code du commercial';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT = 'Veuillez saisir le poids net annoncé';
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE = (grn) => `La livraison ${grn} est déjà clôturée et ne peut pas être mise à jour`;
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE = (grn) => `La Livraison ${grn} est déjà validée et ne peut pas être mise à jour`;
lang.GLOBAL_SERVERERROR_FILE_IS_EMPTY = 'Le fichier est vide';
lang.GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED = 'Échec de l\'importation du contrat';
lang.GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE = 'Le nombre de colonnes ne correspond pas au modèle';
lang.GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED = 'L\'ordre des colonnes ne correspond pas au modèle';
lang.GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED = '.Échec du téléchargement des emplacements de la cour, nom de colonne incorrect fourni';
lang.GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED = '.Échec du téléchargement des fournisseurs, nom(s) de colonne incorrect(s) fourni(s)';
lang.GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED = '.Échec du téléchargement des transporteurs routiers, nom(s) de colonne incorrect(s) fourni(s)';
lang.GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED = '.Échec du téléchargement des articles en stock, nom(s) de colonne incorrect(s) fourni(s)';
lang.GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY  = 'Veuillez fournir des unités de poids';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY = '.Veuillez indiquer l\'entité juridique';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_NOT_FOUND = '.Entité légale fournie introuvable';
lang.GLOBAL_SERVERERROR_EXTERNAL_API_NAME_NOT_FOUND = '.Nom d\'API externe fourni introuvable';
lang.GLOBAL_COPY_TITLE = (itemname) => `Cliquez pour copier ${itemname} dans le presse-papiers`;
lang.GLOBAL_COPY_SUCCESS_MESSAGE = (name) => `${name} a été copié dans le presse-papiers`;
lang.HEADER_QRCODE_HEADER = 'Scannez le QR code';
lang.HEADER_QRCODE_ERROR_MSG = 'Quelque chose s\'est mal passé en ouvrant le lecteur de QR code';
lang.HEADER_LABEL_LOGOUT = 'Se déconnecter';
lang.HEADER_LABEL_CHANGE_PASSWORD = 'Changer le mot de passe';
lang.HEADER_LABEL_SWITCH_ROLE = 'Changer de rôle';
lang.HEADER_LABEL_SWITCH_TENANT = 'Changer de chantier';
lang.HEADER_MODAL_LOGOUT = 'Voulez-vous vous déconnecter ? Toutes les modifications non enregistrées seront perdues.';
lang.HEADER_MODAL_SWITCH_ROLE = 'Voulez-vous changer de rôle? Toutes les modifications non enregistrées seront perdues.';
lang.HEADER_MODAL_SWITCH_TENANT = 'Souhaitez-vous changer de chantier? Toutes les modifications non enregistrées seront perdues.';
lang.ADMIN_SECTIONENTITY_USER = 'Utilisateur';
lang.ADMIN_SECTIONENTITY_STOCK_ITEM = 'Code stock';
lang.ADMIN_SECTIONENTITY_SUPPLIER = 'Fournisseur';
lang.ADMIN_SECTIONENTITY_ROAD_HAULIER = 'Transporteur';
lang.ADMIN_SECTIONENTITY_YARD_LOCATION = 'Secteur';
lang.ADMIN_SECTIONENTITY_PRINTER = 'Imprimante';
lang.ADMIN_SECTIONENTITY_SCALE = 'Bascule';
lang.ADMIN_SECTIONENTITY_MATERIAL = 'Composition';
lang.ADMIN_SECTIONENTITY_MACHINE = 'Machine';
lang.ADMIN_SECTIONENTITY_TARGET_BATCH = 'Lot cible';
lang.ADMIN_SECTIONENTITY_PACKAGE = 'Paquet';
lang.ADMIN_SECTIONENTITY_EWC = 'Code déchet EWC';
lang.ADMIN_SECTIONENTITY_ORIGIN = 'Lieu de chargement';
lang.ADMIN_ACTIVITYLOG_TITLE = 'Journal d\'activité';
lang.ADMIN_ACTIVITYLOG_ACTION = 'Action';
lang.ADMIN_ACTIVITYLOG_CREATEDAT = 'Créé à';
lang.ADMIN_ACTIVITYLOG_USER = 'Utilisateur';
lang.ADMIN_ACTIVITYLOG_MESSAGE = 'Message';
lang.ADMIN_MATERIALS_CREATE_SUCCESS_MSG = 'Un nouvel élément a été créé';
lang.ADMIN_MATERIALS_TITLE = 'Composition';
lang.ADMIN_MATERIALS_CREATE_NEW = 'Créer un nouvel élément';
lang.ADMIN_MATERIALDETAIL_UPDATE_MSG = 'Les éléments ont été mis à jour';
lang.ADMIN_MATERIALDETAIL_DELETE_MSG = 'Les éléments ont été effacées';
lang.ADMIN_MATERIALDETAIL_HEADER = 'Détail de l\'élément';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER = 'Confirmation de la suppression de l\'élément';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT = (name) => `Voulez-vous supprimer l\'élément <strong> ${name} </ strong>?`;
lang.ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG = 'La nouvelle imprimante a été créée';
lang.ADMIN_PRINTERLIST_HEADER = 'Imprimantes';
lang.ADMIN_PRINTERLIST_ADD_BTN = 'Créer l\'imprimante';
lang.ADMIN_PRINTERLIST_NAME = 'Nom de l\'imprimante';
lang.ADMIN_PRINTERLIST_CREATE_NEW = 'Créer une nouvelle imprimante';
lang.ADMIN_PRINTERDETAIL_UPDATE_MSG = 'L\'imprimante a été mise à jour';
lang.ADMIN_PRINTERDETAIL_DELETE_MSG = 'L\'imprimante a été supprimée';
lang.ADMIN_PRINTERDETAIL_HEADER = 'Détail de l\'emplacement de l\'imprimante';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER = 'Confirmation de la suppression de l\'imprimante';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT = (name) => `Voulez-vous supprimer l\'imprimante <strong> ${name} </ strong>?`;
lang.ADMIN_ROADHAULIERLIST_HEADER = 'Transporteurs';
lang.ADMIN_ROADHAULIERLIST_UPLOAD_BTN = 'Télécharger les transporteurs';
lang.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER = 'Numéro d\'autorisation';
lang.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE = 'Date d\'expiration';
lang.ADMIN_COMMONLIST_CREATE_HEADER = (name) => `Créer un nouveau  ${name}`;
lang.ADMIN_COMMONLIST_UPLOAD_HEADER = (name) => `Télécharger ${name}`;
lang.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG = (name) => `Un nouveau ${name} a été créé`;
lang.ADMIN_COMMONDETAIL_UPDATE_MSG = (name) => `${name} a été mis à jour`;
lang.ADMIN_COMMONDETAIL_HIDE_MSG = (name) => `${name} a été masqué`;
lang.ADMIN_COMMONDETAIL_UNHIDE_MSG = (name) => `${name} a été affiché`;
lang.ADMIN_COMMONDETAIL_HEADER = (name) => `Détail ${name}`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER = (name) => `Masquer la confirmation de ${name}`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous masquer <strong> ${name} </ strong>?`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER = (name) => `Afficher la confirmation de ${name}`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous afficher <strong> ${name} </ strong>?`;
lang.ADMIN_COMMONDETAIL_ERROR_MSG = (name) => `${name} non trouvé`;
lang.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG = 'Liste téléchargée avec succès !';
lang.ADMIN_ADMINLISTUPLOAD_ERROR_MSG = 'Quelque chose s\'est mal passé lors du téléchargement de la liste.';
lang.ADMIN_ADMINLISTUPLOAD_EXAMPLE = '<strong> Remarque : </strong> Jetez un coup d\'oeil dans le fichier excel exemple: <a href="/assets/example_upload_excel_file.xlsx" download> Example.xlsx </a>';
lang.ADMIN_SCALELIST_CREATE_SUCCESS_MSG = 'La nouvelle bascule a été créée';
lang.ADMIN_SCALELIST_HEADER = 'Bascule';
lang.ADMIN_SCALELIST_ADD_BTN = 'Créer la bascule';
lang.ADMIN_SCALELIST_NAME = 'Nom de la bascule';
lang.ADMIN_SCALELIST_CREATE_NEW = 'Créer une nouvelle bascule';
lang.ADMIN_SCALEDETAIL_UPDATE_MSG = 'La bascule a été mise à jour';
lang.ADMIN_SCALEDETAIL_DELETE_MSG = 'La bascule a été supprimée';
lang.ADMIN_SCALEDETAIL_HEADER = 'Détail de la bascule';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_HEADER = 'Confirmation de la suppression de la bascule';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_TEXT = (name) => `Voulez-vous supprimer la bascule <strong> ${name} </ strong>?`;
lang.ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR = 'Le nom du champ de saisie n\'est pas défini';
lang.ADMIN_STOCKEITEMLIST_HEADER = 'Articles de stock';
lang.ADMIN_STOCKEITEMLIST_UPLOAD_BTN = 'Télécharger les articles de stock';
lang.ADMIN_SUPPLIERLIST_HEADER = 'Fournisseurs';
lang.ADMIN_SUPPLIERLIST_UPLOAD_BTN = 'Télécharger les fournisseurs';
lang.ADMIN_SUPPLIERDETAIL_UPDATE_MSG = 'Le fournisseur a été mis à jour';
lang.ADMIN_SUPPLIERDETAIL_HIDE_MSG = 'Le fournisseur a été masqué';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_MSG = 'Le fournisseur a été affiché';
lang.ADMIN_SUPPLIERDETAIL_HEADER = 'Détail du fournisseur';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER = 'Confirmation du masquage du fournisseur';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous masquer le fournisseur <strong> ${name} </ strong>?`;
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Confirmation de l\'affichage du fournisseur';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous afficher le fournisseur <strong> ${name} </ strong>?`;
lang.ADMIN_SUPPLIERDETAIL_ERROR_MSG = 'Fournisseur non trouvé';
lang.ADMIN_MACHINEDETAIL_UPDATE_MSG = 'La machine a été mise à jour';
lang.ADMIN_MACHINEDETAIL_HIDE_MSG = 'La machine a été masquée';
lang.ADMIN_MACHINEDETAIL_UNHIDE_MSG = 'La machine a été affichée';
lang.ADMIN_MACHINEDETAIL_HEADER = 'détail de la machine';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER = 'Confirmation du masquage de la machine';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous masquer la machine <strong> ${name} </ strong>?`;
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Confirmation de l\'affichage de la machine';
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous afficher la machine <strong> ${name} </ strong>?`;
lang.ADMIN_MACHINEDETAIL_ERROR_MSG = 'Machine non trouvée';
lang.ADMIN_PACKAGING_DETAIL_UPDATE_MSG = 'L\'emballage a été mis à jour';
lang.ADMIN_PACKAGING_DETAIL_HIDE_MSG = 'L\'emballage a été caché';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_MSG = 'L\'emballage a été affiché';
lang.ADMIN_PACKAGING_DETAIL_HEADER = 'Détail de l\'emballage';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER = 'Confirmation du masquage de l\'emballage';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous masquer l\'emballage <strong>${name}</strong>?`;
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER = 'Confirmation de l\'affichage de l\'emballage';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Souhaitez-vous afficher l\'emballage <strong>${name}</strong>?`;
lang.ADMIN_PACKAGING_DETAIL_ERROR_MSG = 'Emballage non trouvé';
lang.ADMIN_TARGETBATCHDETAIL_UPDATE_MSG = 'Le lot cible a été mis à jour';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_MSG = 'Le lot cible a été masqué';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG = 'Le lot cible a été affiché';
lang.ADMIN_TARGETBATCHDETAIL_HEADER = 'Détail du lot cible';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER = 'Confirmation du masquage du lot cible';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous masquer le lot cible <strong> ${name} </ strong>?`;
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Confirmation de l\'affichage du lot cible';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous afficher le lot cible <strong> ${name} </ strong>?`;
lang.ADMIN_TARGETBATCHDETAIL_ERROR_MSG = 'Lot cible non trouvé';
lang.ADMIN_USERLIST_CREATE_SUCCESS_MSG = 'Le nouvel utilisateur a été créé';
lang.ADMIN_USERLIST_HEADER = 'Gestion des utilisateurs';
lang.ADMIN_USERLIST_ADD_BTN = 'Créer un nouvel utilisateur';
lang.ADMIN_USERDETAIL_UPDATE_MSG = 'L\'utilisateur a été mis à jour';
lang.ADMIN_USERDETAIL_DELETE_MSG = 'L\'utilisateur a été supprimé';
lang.ADMIN_USERDETAIL_DELETE_BTN = 'Supprimer l\'utilisateur';
lang.ADMIN_USERDETAIL_HEADER = 'détail de l\'utilisateur';
lang.ADMIN_USERDETAIL_CONFIRMATION_HEADER = 'Confirmation de la suppression de l\'utilisateur';
lang.ADMIN_USERDETAIL_CONFIRMATION_TEXT = (name) => `Voulez-vous supprimer l\'utilisateur <strong> ${name} </ strong>?`;
lang.ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG = 'Le nouveau secteur a été créé';
lang.ADMIN_YARDLOCATIONLIST_HEADER = 'Secteurs';
lang.ADMIN_YARDLOCATIONLIST_ADD_BTN = 'Créer un secteur';
lang.ADMIN_YARDLOCATIONLIST_UPLOAD_BTN = 'Télécharger un secteur';
lang.ADMIN_YARDLOCATIONLIST_NAME = 'Nom du secteur';
lang.ADMIN_YARDLOCATIONLIST_CREATE_NEW = 'Créer un nouveau secteur';
lang.ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG = 'Le secteur a été mis à jour';
lang.ADMIN_YARDLOCATIONDETAIL_DELETE_MSG = 'Le secteur a été supprimé';
lang.ADMIN_YARDLOCATIONDETAIL_HEADER = 'Détail du secteur';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER = 'Supprimer le secteur';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT = (name) => `Voulez-vous supprimer le secteur <strong> ${name} </ strong>?`;
lang.ADMIN_YARDLOCATIONDETAIL_NAME = 'Secteur';
lang.ADMIN_MACHINE_LIST_HEADER = 'Machines';
lang.ADMIN_MACHINE_LIST_UPLOAD_BTN = 'Téléchargement des machines';
lang.ADMIN_TARGET_BATCH_LIST_HEADER = 'Lots cibles';
lang.ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN = 'Télécharger les lots cibles';
lang.ADMIN_PACKAGING_ADD_BTN = 'Ajouter un emballage';
lang.ADMIN_PACKAGING_UPLOAD_BTN = 'Télécharger l\'emballage';
lang.ADMIN_PACKAGING_CREATE_NEW_PACKAGING = 'Créer un nouvel emballage';
lang.ADMIN_PACKAGING_CREATE_SUCCESS_MSG = 'Un nouvel emballage a été créé';
lang.ADMIN_EWCLIST_HEADER = 'Codes déchets EWC';
lang.ADMIN_EWCLIST_ADD_BTN = 'Créer un code déchet EWC';
lang.ADMIN_EWCLIST_UPLOAD_BTN = 'Importer un code déchet EWC';
lang.ADMIN_EWCLIST_CODE = 'Code';
lang.ADMIN_EWCLIST_CREATE_NEW = 'Créer un nouveau code déchet EWC';
lang.ADMIN_EWCLIST_CREATE_SUCCESS_MSG = 'Un nouveau code déchet EWC a été ajouté';
lang.ADMIN_EWCDETAIL_UPDATE_MSG = 'Le code déchet EWC a été mis à jour';
lang.ADMIN_EWCDETAIL_HEADER = 'Détail du code déchet EWC';
lang.ADMIN_EWCDETAIL_NAME = 'Code';
lang.ADMIN_EWCDETAIL_HIDE_MSG = 'Le code déchet EWC a été masqué';
lang.ADMIN_EWCDETAIL_UNHIDE_MSG = 'Le code déchet EWC a été affiché';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER = 'Confirmation du masquage du code déchet EWC';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Souhaitez-vous masquer le code déchet EWC <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Confirmation de l\'affichage du code déchet EWC';
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Souhaitez-vous afficher le code déchet EWC <strong> ${name} </strong>?`;
lang.ADMIN_EWCDETAIL_ERROR_MSG = 'Le code déchet EWC n\'a pas trouvé';
lang.ADMIN_ORIGINLIST_HEADER = 'Lieux de chargement';
lang.ADMIN_ORIGINLIST_ADD_BTN = 'Créer un code de lieu de chargement';
lang.ADMIN_ORIGINLIST_UPLOAD_BTN = 'Importer un lieu de chargement';
lang.ADMIN_ORIGINLIST_CODE = 'Code';
lang.ADMIN_ORIGINLIST_CREATE_NEW = 'Créer un nouveau lieu de chargement';
lang.ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG = 'Un nouveau code de lieu de chargement a été ajouté';
lang.ADMIN_ORIGINDETAIL_UPDATE_MSG = 'Le code du lieu de chargement a été mis à jour';
lang.ADMIN_ORIGINDETAIL_HEADER = 'Détail du lieu de chargement';
lang.ADMIN_ORIGINDETAIL_NAME = 'Code';
lang.ADMIN_ORIGINDETAIL_HIDE_MSG = 'Le lieu de chargement a été masqué';
lang.ADMIN_ORIGINDETAIL_UNHIDE_MSG = 'Le lieu de chargement a été affiché';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER = 'Confirmation du masquage du lieu de chargement';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Voulez-vous masquer le code du lieu de chargement <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Confirmation de l\'affichage du lieu de chargement';
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Souhaitez-vous afficher le code du lieu de chargement <strong> ${name} </strong>?`;
lang.ADMIN_ORIGINDETAIL_ERROR_MSG = 'Le lieu de chargement n\'a pas trouvé';
lang.ADMIN_CONTRACTS_TITLE = 'Contrats';
lang.ADMIN_CONTRACTS_FILE_IMPORT = 'Importation de fichiers';
lang.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC = 'Déclencher la synchronisation SFTP';
lang.ADMIN_CONTRACTS_FILE_IMPORTED_MSG = 'Fichier importé';
lang.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG = 'Importation SFTP déclenchée';
lang.ADMIN_PROCESSING_UNIT_MINUTES = '€/heure';
lang.ADMIN_PROCESSING_UNIT_TONS = '€/tonne';
lang.ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE = 'Confirmation d\'actualisation des variantes de produit';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT = 'Le processus d\'actualisation peut prendre jusqu\'à quelques minutes. Voulez-vous poursuivre?';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE = 'Les variantes de produit ont été actualisées avec succès';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE = 'Actualisation des variantes de produit toujours en cours. Recharger la page après 2min';
lang.ADMIN_REFRESH_CONFIRMATION_TITLE = (name) => `Actualiser ${name} Confirmation`;
lang.ADMIN_REFRESH_SUCCESS_MESSAGE = (name) => `${name} a été actualisé avec succès`;
lang.ADMIN_REFRESH_CONFIRMATION_TEXT = 'Le processus d\'actualisation peut prendre jusqu\'à quelques minutes. Voulez-vous poursuivre?';
lang.ADMIN_REFRESH_IN_PROGRESS_MESSAGE = (name) => `Actualisation de ${name} toujours en cours. Mettre à jour la page après 2 min`;
lang.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE = 'Les employés ont été rafraîchis avec succès';
lang.ADMIN_LASERNET_REPORT_NAME = 'Nom du rapport Laserner';
lang.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES = 'Nombre de copies par défaut';
lang.ADMIN_LASERNET_DOCUMENT_TYPE = 'Tipo de Documento';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL = 'Détail du type de document';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_ID = 'ID du type de document';
lang.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG = 'Veuillez fournir le nom du rapport Lasernet';
lang.ADMIN_LASERNET_CONFIGURATIONS_HEADER = 'Configurations d\'imprimante';
lang.ADMIN_LASERNET_CONFIGURATION_DETAILS = 'Détails de configuration';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER = 'Confirmation de suppression';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT = 'Souhaitez-vous supprimer la configuration ?';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE = 'La confirmation a été supprimée avec succès';
lang.ADMIN_LASERNET_PRINTER_ID = 'Identifiant de l\'imprimante';
lang.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG = 'Un nouveau chantier a été créé';
lang.SUPERADMIN_TENANTLIST_HEADER = 'Chantiers';
lang.SUPERADMIN_TENANTLIST_ADD_BTN = 'Ajouter un nouveau';
lang.SUPERADMIN_TENANTLIST_GRN = 'Préfixe du BE';
lang.SUPERADMIN_TENANTLIST_CREATE_NEW = 'Créer un nouveau chantier';
lang.SUPERADMIN_TENANTDETAIL_UPDATE_MSG = 'Le chantier a été mis à jour';
lang.SUPERADMIN_TENANTDETAIL_DELETE_MSG = 'Le chantier a été supprimé';
lang.SUPERADMIN_TENANTDETAIL_HEADER = 'Détail du chantier';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER = 'Confirmation de la suppression du chantier';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT = (name) => `Voulez-vous supprimer le chantier <strong> ${name} </ strong>? ??`;
lang.SUPERADMIN_TENANTDETAIL_SITE = 'Placer';
lang.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS = 'Vérifier les pièces jointes manquantes';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL = 'Au niveau Bon conseillé';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL = 'Au niveau de la livraison';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION = 'Exiger un classement';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION = 'Nécessite une contamination';
lang.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT = 'Confirmer l\'écrasement de la description dans le rapport de tri';
lang.SUPERADMIN_TENANTDETAIL_DISPLAY_TARE_WEIGHT_FOR_RECEIVED_GOOD = 'Afficher la tare du produit reçu';
lang.SUPERADMIN_TENANTDETAIL_IS_STOCK_CODE_REQUIRED_FOR_VERIFY = 'Le code de stock est-il requis pour la vérification';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_HEADER = 'Masquer la confirmation du locataire';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_HEADER = 'Afficher la confirmation du locataire';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `Souhaitez-vous masquer le locataire <strong>${name}</strong> ?`;
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `Souhaitez-vous afficher le locataire <strong>${name}</strong> ?`;
lang.SUPERADMIN_TENANTDETAIL_HIDE_SUCCESS_MESSAGE = 'Le locataire a été masqué avec succès';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_SUCCESS_MESSAGE = 'Le locataire a été masqué avec succès';
lang.SUPERADMIN_TENANTSECTIONS_GENERAL_SECTION = 'Général';
lang.SUPERADMIN_TENANTSECTIONS_CONFIGURATION_SECTION = 'Aufbau';
lang.SUPERADMIN_TENANTSECTIONS_AG_CONFIGURATION_SECTION = 'Utiliser des filtres intestinaux ';
lang.SUPERADMIN_TENANTSECTIONS_DELIVERY_CONFIGURATION_SECTION = 'Lieferung';
lang.SUPERADMIN_TENANTSECTIONS_REQUIRED_OPTIONS_SECTION = 'Options ergonomiques';
lang.SUPERADMIN_TENANTSECTIONS_INTEGRATION_SECTION = 'L\'intégration';
lang.SUPERADMIN_TENANTADMIN_UPDATE_MSG = 'Le chantier a été mis à jour';
lang.SUPERADMIN_TENANTADMIN_ADD_MSG = 'Administrateur ajouté';
lang.SUPERADMIN_TENANTADMIN_DELETE_MSG = 'Administrateur supprimé';
lang.SUPERADMIN_TENANTADMIN_TENANT = 'Chantier';
lang.SUPERADMIN_TENANTADMIN_LOCATION_CODE = 'Code lieu';
lang.SUPERADMIN_TENANTADMIN_REGION_CODE = 'Code région';
lang.SUPERADMIN_TENANTADMIN_GRN_VALUE = 'Valeur actuelle du BE';
lang.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE = 'Valeur de séquence Lab actuelle';
lang.SUPERADMIN_TENANTADMIN_WORKFLOW = 'flux de travail';
lang.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW = 'Choisissez le flux de travail';
lang.SUPERADMIN_TENANTADMIN_TIMEZONE = 'Fuseau horaire';
lang.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE = 'Base d\'intégration API';
lang.SUPERADMIN_TENANTADMIN_ADMINS = 'Administrateurs';
lang.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN = 'Ajouter un administrateur';
lang.SUPERADMIN_TENANTADMIN_NO_ADMINS = 'Utilisateurs non administrateurs';
lang.SUPERADMIN_TENANTADMIN_HEADER = 'Ajouter un administrateur';
lang.SUPERADMIN_TENANTADMINLINE_HEADER = 'Administrateur';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE = 'Masquer l\'administrateur';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE = (name) => `Voulez-vous vous masquer <strong>${name}</strong>?`;
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE = 'Afficher l\'administrateur';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE = (name) => `Voulez-vous afficher <strong>${name}</strong>?`;
lang.SUPERADMIN_SHAREPOINT_CHOOSE_SITE = '.Choisissez le site Sharepoint';
lang.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH = '.Chemin du dossier d\'images';
lang.SUPERADMIN_SHAREPOINT_SITES = '.Sites Sharepoint';
lang.SUPERADMIN_APP_USERS_TITLE = '.Inscriptions à l\'application';
lang.SUPERADMIN_APP_USERS_ADD_MSG = '.Enregistrement de l\'application ajouté';
lang.SUPERADMIN_APP_USERS_ADD_TITLE = '.Ajouter un enregistrement d\'application';
lang.SUPERADMIN_APP_USERS_UPDATE_MSG = '.Enregistrement de l\'application mis à jour';
lang.SUPERADMIN_APP_USERS_RANDOM_SECRET = 'Générer un secret aléatoire';
lang.SUPERADMIN_APP_USERS_COPY_TEXT = 'Copiez cette valeur car vous ne pourrez pas récupérer la clé après avoir enregistré et fermé cet utilisateur d\'application.';
lang.SUPERADMIN_CONNECTIONS_TITLE = 'Connexions';
lang.SUPERADMIN_CONNECTIONS_UPDATE_SUCCESS_MSG = 'L\'ID client et le secret ont été mis à jour avec succès';
lang.SUPERADMIN_CONNECTIONS_D365_DESCRIPTION = 'Connexion aux données principales du D365 et à l\'API YardLink';
lang.SUPERADMIN_CONNECTIONS_SHAREPOINT_DESCRIPTION = 'Connexion à l\'API SharePoint en ligne';
lang.MENU_ADMIN_TITLE = 'Administration';
lang.MENU_ADMIN_USER_MANAGEMENT = 'Gestion des utilisateurs';
lang.MENU_ADMIN_ACTIVITY_LOG = 'Journal d\'activité';
lang.MENU_ADMIN_STOCK_ITEMS = 'Codes articles';
lang.MENU_ADMIN_SUPPLIERS = 'Fournisseurs';
lang.MENU_ADMIN_ROAD_HAULIERS = 'Transporteurs';
lang.MENU_ADMIN_YARD_LOCATION = 'Secteur';
lang.MENU_ADMIN_PRINTERS = 'Imprimantes';
lang.MENU_ADMIN_LASERNET_PRINTERS = 'Imprimantes Lasernet';
lang.MENU_ADMIN_DOCUMENT_TYPES = 'Types de documents';
lang.MENU_ADMIN_CONFIGURATIONS = 'Configurations';
lang.MENU_ADMIN_SCALES = 'Bascules';
lang.MENU_ADMIN_MATERIALS = 'Composition';
lang.MENU_ADMIN_APPLICATION = 'Application';
lang.MENU_ADMIN_DELIVERY_LIST = 'Liste de livraison';
lang.MENU_ADMIN_MACHINES = 'Machines';
lang.MENU_ADMIN_TARGET_BATCHES = 'Lots cibles';
lang.MENU_ADMIN_PACKAGING = 'Emballage';
lang.MENU_ADMIN_ORIGINS = 'Provenances';
lang.MENU_ADMIN_EWCS = 'Codes Déchets EWC';
lang.MENU_SUPERADMIN_TENANTS = 'Chantiers';
lang.LABORATORY_ADVISEGOODS_UPDATE_MSG = 'Analyse labo - mis à jour';
lang.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG = 'Statut des analyses labo - mis à jour';
lang.LABORATORY_ADVISEGOODS_DELIVERIES = 'Livraisons';
lang.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION = 'Description annoncée';
lang.LABORATORY_ADVISEGOODS_TENANT = 'Chantier';
lang.LABORATORY_ADVISEGOODS_SORTING_REPORT = 'rapport de tri';
lang.LABORATORY_ADVISEGOODS_LAB_STATUSES = 'Statuts labo';
lang.LABORATORY_ADVISEGOODS_LAB_LIST = 'Liste labo';
lang.LABORATORY_ANALISYS_HEADER = 'Analyses labo';
lang.LABORATORY_ANALISYS_MEASUREMENTS = 'Mesures';
lang.LABORATORY_ANALISYS_UPDATE_STATUS_MSG = 'Statut de l\'analyse labo - mis à jour';
lang.LABORATORY_ANALISYS_WET_WEIGHT = 'Poids humide';
lang.LABORATORY_ANALISYS_DRY_WEIGHT = 'Poids sec';
lang.LABORATORY_ANALISYS_INGOTE_WEIGHT = 'Poids Lingot';
lang.LABORATORY_ANALISYS_YIELD = 'rendement';
lang.LABORATORY_ANALISYS_SPECTRO_ANALYSIS = 'Analyse spectro';
lang.LABORATORY_PRINTER_SUCCESS_MSG = 'La page a été envoyée à l\'imprimante';
lang.LABORATORY_PRINTER_WARN_MSG = 'L\'imprimante utilisée n\'est pas supportée';
lang.LABORATORY_PRINTER_ERROR_MSG = 'Une erreur est survenue lors de l\'impression';
lang.LABORATORY_PRINTER_LABEL = 'Étiquette';
lang.LABORATORY_PRINTER_NO_PRINTERS = 'Aucune imprimante';
lang.LABORATORY_SCALE_CONNECTION_ERROR_MSG = 'La connexion avec la bascule n\'a pas pu être établie';
lang.LABORATORY_SCALE_SCALE_ERROR_MSG = 'Bascule - une erreur est survenue';
lang.LABORATORY_SCALE_FAKE_RESULT = 'Résultat';
lang.DELIVERIES_LABORATORY_FILTER_BY = 'Filtrer par :';
lang.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG = 'Une nouvelle livraison a été reçue';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG = (name) => `La livraison ${name} a été mise à jour`;
lang.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG = 'Le lot a été pris en charge';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED = 'La livraison a été resynchronisée';
lang.DELIVERIES_FILTER_SORT_BY = (name) => `Trier par ${name}`;
lang.DELIVERIES_FILTER_GRN_SEARCH = 'Recherche de BE';
lang.DELIVERIES_FILTER_ADVISED_GOOD_MSG = 'Le lot a été pris en charge';
lang.DELIVERIES_FILTER_DELIVERY_RESYNCED = 'La livraison a été resynchronisée';
lang.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG = 'La livraison a été validée';
lang.DELIVERIES_DELIVERYLIST_COMPLETED_MSG = 'La livraison est clôturée';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF = 'Valider';
lang.DELIVERIES_DELIVERYLIST_RESYNC_WB = 'resynchronisation pont-bascule';
lang.DELIVERIES_DELIVERYLIST_RESYNC_SR = 'resynchronisation Sr';
lang.DELIVERIES_DELIVERYLIST_RESP = 'Responsable';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER = 'Valider la livraison?';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT = (grn) => `Voulez-vous valider <strong> ${grn} </ strong>?`;
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER = 'Marquer comme clôturé ?';
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT = (grn) => `Voulez-vous clôturer <strong> ${grn} </ strong>?`;
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER = 'Exporter la livraison ?';
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT = (grn) => `Souhaitez-vous exporter <strong> ${grn} </ strong>?`;
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER = 'Confirmation de l\'écart de poids';
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT = 'Le poids total trié ne correspond pas au poids reçu. Souhaitez-vous valider?';
lang.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG = 'Une erreur est survenue, retour à la liste des livraisons';
lang.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG = 'La livraison a été créée';
lang.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG = 'Le téléchargement de la pièce jointe a échoué, merci de télécharger à nouveau';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER = 'Numéro d\'achat';
lang.DELIVERIES_NEWDELIVERYDETAILS_GRN = 'Numéro BE';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW = 'Créer une nouvelle livraison';
lang.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER = 'Numéro de bon de livraison';
lang.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER = 'Numéro d\'immatriculation du véhicule';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER = 'Numéro du conteneur';
lang.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER = 'Transporteur';
lang.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS = 'Pas de pièces jointes';
lang.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE = 'Responsable';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY = 'Créé par';
lang.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY = 'Validé par';
lang.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY = 'Synchronisé par';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY = 'Clôturé par';
lang.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM = 'Code stock';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION = 'Description annoncée';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT = 'Poids annoncé';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT = 'Poids annoncé (brut)';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT = 'Poids annoncé (net)';
lang.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION = 'Secteur';
lang.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT = 'Poids brut';
lang.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT = 'Poids de tare';
lang.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET = 'N° de ticket de pont bascule';
lang.DELIVERIES_NEWDELIVERYDETAILS_NET = 'Net';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER = 'Sauvegarder les modifications?';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT = 'Voulez-vous <strong> Enregistrer les modifications </strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER = 'Marquer comme clôturé ?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT = 'Voulez-vous clôturer la livraison <strong> </strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'Enregistrer sans pièces jointes?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'Voulez-vous enregistrer la livraison <strong> sans pièces jointes </strong>?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER = 'Enregistrer malgré l\'expiration du certificat ?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT = 'Le certificat du fournisseur est expiré ou manquant. Voulez-vous quand même enregistrer la livraison?';
lang.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN = 'Lieu de chargement';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER = 'Enregistrer avec une autorisation expirée?';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT = 'L\'autorisation du transporteur est manquante ou a expiré. Voulez-vous quand même enregistrer la livraison?';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE = 'Type de transaction';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE = 'Montant à payer';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER = 'Transfert';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS = 'Retour des lots refusés';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID = 'Vide';
lang.DELIVERIES_DELIVERYDETAILSLINE_HEADER = 'Marchandises annoncées - détail';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION = 'Description annoncée';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT = 'Poids annoncé';
lang.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE = 'Ligne d\'achat';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX = 'Numéro de ligne';
lang.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG = 'La livraison a été mise à jour';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG = 'La mise à jour des pièces jointes a réussi !';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG = 'Le téléchargement des pièces jointes a échoué, merci de télécharger à nouveau';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG = 'La pièce jointe a été supprimée';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION = 'Confirmation de la suppression de la pièce jointe';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION = 'Souhaitez-vous supprimer cette pièce jointe?';
lang.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG = (deliveryGrn) => `La marchandise de la livraison <strong>${deliveryGrn}<strong /> a été prise en charge`;
lang.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG = (codes) => `Les codes stock [${codes}] ne correspondent à aucun des codes stock de dOps. Veuillez contacter votre administrateur`;
lang.DELIVERIES_DELIVERYEDIT_DELETE = 'Supprimer la livraison?';
lang.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION = 'Souhaitez-vous supprimer la livraison? Il ne sera pas possible de la restaurer à l\'avenir.';
lang.DELIVERIES_DELIVERYEDIT_DELETED = (name) => `La livraison ${name} a été supprimée`;
lang.ADVISEDGOOD_ROUTE_EWC_NUMBER = 'Code déchet EWC';
lang.ADVISEDGOOD_ROUTE_DRIVER = 'Chauffeur';
lang.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG = 'La marchandise annoncée a été mise à jour';
lang.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED = (entity) => `Poids mis à jour dans: ${entity}`;
lang.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG = 'La marchandise a été BLOQUEE';
lang.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG = 'La marchandise a été DEBLOQUEE';
lang.ADVISEDGOOD_ROUTE_COMPLETED_MSG = 'La marchandise annoncée a été clôturée';
lang.ADVISEDGOOD_ROUTE_VERIFIED_MSG = 'La marchandise annoncée a été vérifiée';
lang.ADVISEDGOOD_ROUTE_LAB_MSG = 'L\'analyse labo a été créée avec succès';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG = 'La marchandise annoncée doit être enregistrée avant de créer le transfert';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG = 'La marchandise annoncée doit être enregistrée avant de créer une nouvelle analyse labo';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG = 'La marchandise annoncée doit être enregistrée avant l\'impression';
lang.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK = 'Statut de la restauration';
lang.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION = 'Immatriculation du véhicule';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT = 'Poids brut';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT = 'Tare';
lang.ADVISEDGOOD_ROUTE_NET_WEIGHT = 'Poids net';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED = 'Poids brut requis';
lang.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT = 'Poids reçu';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED = 'Poids de tare requis';
lang.ADVISEDGOOD_ROUTE_SORTING_REPORT = 'Rapport de tri';
lang.ADVISEDGOOD_ROUTE_BALANCED = 'Équilibré';
lang.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG = 'Fichier zip téléchargé';
lang.ADVISEDGOOD_ROUTE_PICTURES = 'Images';
lang.ADVISEDGOOD_ROUTE_NO_PICTURE = 'Pas d\'image';
lang.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES = 'Téléchargement des photos en cours …';
lang.ADVISEDGOOD_ROUTE_COMMENT = 'Commentaire';
lang.ADVISEDGOOD_ROUTE_NO_COMMENT = 'Sans commentaire';
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER = (hasFlag) => `${hasFlag} confirmation`;
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `Voulez-vous <strong class = ${className}> ${hasFlag} </strong> ce lot?`;
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER = 'Sauvegarder les modifications?';
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT = 'Voulez-vous <strong> enregistrer les modifications </strong>?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER = 'Marquer comme clôturé ?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT = 'Voulez-vous marquer cette marchandise annoncée <strong>comme clôturée </strong>?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER = 'Marquer comme vérifié';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT = 'Voulez-vous marquer cette marchandise annoncée <strong> comme vérifiée </strong>?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT = 'Le poids total trié est plus grand que le poids de la bascule d\'entrée. Pour vérifier, veuillez confirmer que le poids trié a été vérifié sur une balance interne.';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER = 'Retaurer au statut PRIS EN CHARGE ?';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT = 'Voulez-vous restaurer au statut <strong> PRIS EN CHARGE </strong>?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER = 'Revenir à l\'état déchargé ?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT = 'Souhaitez-vous revenir à l\'état <strong>déchargé</strong> ?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_ERROR = 'Veuillez ajouter des pièces jointes';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_WARNING = 'Etes-vous sûr de vouloir décharger le bon conseillé sans pièces jointes';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS = 'Poids total trié (brut)';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET = 'Poids total trié (net)';
lang.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG = 'Le poids brut a été mis à jour';
lang.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG = 'Le poids reçu a été mis à jour';
lang.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG = 'Le code déchet EWC a été mis à jour';
lang.ADVISEDGOOD_ROUTE_DELIVERY_TYPE = 'Type de livraison';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER = 'Confirmer le lieu de déchargement';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT = 'Sélectionnez le secteur pour le déchargement';
lang.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION = 'Lieu de déchargement';
lang.ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR = 'Le lot ne peut pas être vérifié car le poids total trié est supérieur au poids reçu';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER = 'Annuler la prise en charge du lot';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT = 'Souhaitez-vous annuler la prise en charge du lot ?';
lang.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG = 'La prise en charge du lot a été annulée';
lang.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE = 'Le nombre des marchandises triées ne peut pas dépasser 10';
lang.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT = 'Poids reçu du camion (Brut)';
lang.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL = 'Total trié du camion (brut)';
lang.ADVISEDGOOD_ROUTE_PACKAGING = 'Emballage';
lang.ADVISEDGOOD_ROUTE_NO_PACKAGING = 'Aucun emballage';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG = 'Fermer la session AG';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER = 'Fermer AG ?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT = 'Souhaitez-vous signer AG ?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE = 'AG a été signé avec succès';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'Enregistrer sans pièces jointes ?';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'Souhaitez-vous enregistrer le bien conseillé <strong>sans pièces jointes</strong> ?';
lang.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT = 'Souhaitez-vous remplacer la description de l\'article ?';
lang.ADVISEDGOOD_ROUTE_DELETE = 'Supprimer conseillé Bon ?';
lang.ADVISEDGOOD_ROUTE_DELETE_CONFIRMATION = 'Souhaitez-vous supprimer Advised Good ? Il ne sera pas possible de le restaurer à l\'avenir.';
lang.ADVISEDGOOD_ROUTE_DELETED = (name) => `Conseillé Bon ${name} a été supprimé`;
lang.ADVISEDGOOD_SORTINGREPORT_HEADER = 'Détail du rapport de tri';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG = 'La marchandise annoncée ne peut pas être transférée, car le poids de transfert est plus grand que le poids actuel';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG = 'La marchandise annoncée a été transférée';
lang.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE = 'Code stock';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER = 'Transférer';
lang.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS = 'Analyse labo';
lang.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS = 'Aucune analyse labo demandée';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL = 'Composition';
lang.ADVISEDGOOD_SORTINGREPORT_WI_DONE = 'WI fait';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER = 'Créer une nouvelle analyse labo';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT = 'Voulez-vous créer une nouvelle analyse labo?';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER = 'Supprimer l\'analyse labo';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT = (labAnalysisName) => `Voulez-vous supprimer la demande d\'analyse <strong>${labAnalysisName}</strong>?`;
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE = 'Machine';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH = 'Lot cible';
lang.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY = 'Masse volumique apparente';
lang.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY = 'Qualité reconnue';
lang.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE = 'Forme principale';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION = 'Description de la marchandise';
lang.ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG = (emptyRequiredField) => `Vous devez remplir <strong> ${emptyRequiredField} </ strong> avant d\'enregistrer`;
lang.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG = 'La marchandise annoncée doit être enregistrée avant d\'ajouter une image';
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE = 'Changer de lieu';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION = 'Emplacement cible';
lang.ADVISEDGOOD_LABANALYSIS_LAB_REMARKS = 'Remarques labo';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER = 'Confirmation de la suppression de l\'image';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT = 'Voulez-vous supprimer cette photo?';
lang.ADVISEDGOOD_DEDUCTION_FREE_WATER = 'Eau libre';
lang.ADVISEDGOOD_DEDUCTION_FREE_OIL = 'Huile libre';
lang.ADVISEDGOOD_DEDUCTION_FREE_EMULSION = 'Emulsion libre';
lang.ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE = 'Neige / glace libre';
lang.ADVISEDGOOD_DEDUCTION_NO_DEDUCTION = 'Aucune déduction';
lang.ADVISEDGOOD_DEDUCTION_DEDUCTION = 'Déduction';
lang.ADVISEDGOOD_DEDUCTION_MODAL_HEADER = 'Confirmer le déchargement de la marchandise';
lang.ADVISEDGOOD_UNLOAD_TITLE = 'Déchargé';
lang.ADVISEDGOOD_UNLOAD_UPDATE_MSG = 'Déchargement de la marchandise complet';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE = 'Confirmation de poids négatif';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT = 'Le poids du lot est négatif. Veuillez confirmer';
lang.RECEIVED_GOOD_FLAG_ERROR_MSG = 'Le lot trié a été BLOQUE';
lang.RECEIVED_GOOD_FLAG_SUCCESS_MSG = 'Le lot trié a été DEBLOQUE';
lang.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `Souhaitez-vous <strong class=${className}> ${hasFlag} </strong> ce lot trié?`;
lang.RECEIVED_GOOD_CONTAMINATION_TITLE = 'Contamination';
lang.RECEIVED_GOOD_CONTAMINATION_OTHER = 'Autre';
lang.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION = 'Aucune Contamination';
lang.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH = 'Attachement non-métallique';
lang.RECEIVED_GOOD_CONTAMINATION_WATER = 'Eau (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FREE_OIL = 'Huile libre (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIRT = 'Poussière (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_WOOD = 'Bois (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PLASTIC = 'Plastique (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL = 'Laine de verre (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG = 'Crasses de découpe (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BITUMEN = 'Goudron (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_OIL_CREASE = 'Huile / graisse attachée (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CARDBOARD = 'Carton (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH = 'Attachement métallique';
lang.RECEIVED_GOOD_CONTAMINATION_IRON = 'Fer (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MN_STEEL = 'Acier au Mn (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL = 'Cr10 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL = 'F13 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_301 = '301 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL = 'F17 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_SHREDDER = 'Broyé (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT = 'Incinéré (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS = 'Autres Métaux';
lang.RECEIVED_GOOD_CONTAMINATION_CU = 'Cu (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_AL = 'Al (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MS = 'Mn (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PB = 'Pb (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TI = 'Ti (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER = 'Echangeur inox au Cu (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING = 'Métaux mêlés (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS = 'Tournures dans les chutes';
lang.RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR = 'Tournures Cr (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR = 'Tournures acier (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS = 'Tournures inox (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_304_TURNINGS = 'Tournures 304 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_316_TURNINGS = 'Tournures 316 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE = 'Marchandise hors dimension';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR = 'Pour cisaille mobile (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR = 'Pour cisaille stationnaire (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING = 'Pour découpe chalumeau (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_PRESS = 'Pour presse (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY = 'Aucune densification nécessaire (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES = 'Contient des corps creux (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS = 'Forme de la marchandise';
lang.RECEIVED_GOOD_CONTAMINATION_PACKETS = 'Paquets (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BRIQUETTES = 'Briquettes (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INGOTS = 'Lingots (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL = 'Matière fine (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_RUNNINGS = 'Coulures (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND = 'Tare (entrée)';
lang.RECEIVED_GOOD_CONTAMINATION_TOTAL = 'Total (nb)';
lang.RECEIVED_GOOD_CONTAMINATION_DRUMS = 'Fûts (nb)';
lang.RECEIVED_GOOD_CONTAMINATION_BOXES = 'Caisses fer (nb)';
lang.RECEIVED_GOOD_CONTAMINATION_GRID_BOXES = 'Bacs grillagés (nb)';
lang.RECEIVED_GOOD_CONTAMINATION_BIGBAGS = 'BigBags (nb)';
lang.RECEIVED_GOOD_CONTAMINATION_PALLETS = 'Palettes (nb)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND = 'Tare (sortie)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION = 'Composition des tournures';
lang.RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT = '% magnétique';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING = 'Tournures';
lang.RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING = 'Meulures';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES = 'Tailles';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_0_15 = 'Taille 0-15 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_16_35 = 'Taille 16-35 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_36_60 = 'Taille 36-60 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_61_80 = 'Taille 61-80 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_81_120 = 'Taille 81-120 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_121_150 = 'Taille 121-150 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_151_999 = 'Taille 150-999 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING = 'La somme n\'est pas égale à 100%';
lang.RECEIVED_GOOD_CONTAMINATION_PACKAGING = 'Emballage';
lang.SORTINGREPORT_CLASSIFICATION_TITLE = 'Classification';
lang.SORTINGREPORT_CLASSIFICATION_VERY_LIGHT = 'très léger';
lang.SORTINGREPORT_CLASSIFICATION_LIGHT = 'Léger';
lang.SORTINGREPORT_CLASSIFICATION_MIDDLE = 'Densité moyenne';
lang.SORTINGREPORT_CLASSIFICATION_HEAVY = 'lourd';
lang.SORTINGREPORT_CLASSIFICATION_VERY_HEAVY = 'très lourd';
lang.SORTINGREPORT_CLASSIFICATION_FE_SDS = 'Chutes Fe';
lang.SORTINGREPORT_CLASSIFICATION_CR_SDS = 'Chutes Acier au Cr';
lang.SORTINGREPORT_CLASSIFICATION_V13F_SDS = 'Chutes F13';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS = 'Chutes 1.4510';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS = 'Chutes 1.4512';
lang.SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS = 'Chutes f17';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS = 'Chutes 1.4016';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS = 'Chutes 1.4509';
lang.SORTINGREPORT_CLASSIFICATION_FQ_304_SDS = 'Chutes 304';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_SDS = 'Chutes CrNi';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS = 'Chutes CrNiMo';
lang.SORTINGREPORT_CLASSIFICATION_FE_TUR = 'Tournures Acier';
lang.SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR = 'Tournures mêlées Cr / NiCr';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_TUR = 'Tournures NiCr';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR = 'Tournures NiCrMo';
lang.SORTINGREPORT_CLASSIFICATION_OTHER = 'Autre';
lang.SORTINGREPORT_CLASSIFICATION_SHREDDER = 'Broyé';
lang.SORTINGREPORT_CLASSIFICATION_SOLIDS = 'Chutes';
lang.SORTINGREPORT_CLASSIFICATION_TURNINGS = 'Tournures';
lang.SORTINGREPORT_CLASSIFICATION_PACKAGE = 'Paquets';
lang.SORTINGREPORT_CLASSIFICATION_BRIKETTS = 'Briquettes';
lang.SORTINGREPORT_CLASSIFICATION_INGOTS = 'lingots';
lang.SORTINGREPORT_CLASSIFICATION_FINES = 'Fines';
lang.SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION = 'Incinéré';
lang.SORTINGREPORT_CLASSIFICATION_RUNNINGS = 'Coulures';
lang.SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP = 'Chutes de démolition';
lang.SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP = 'Chutes de production';
lang.SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP = 'Chutes de récupération';
lang.SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP = 'Chutes cisaillées';
lang.SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS = 'Tournures avec des chutes';
lang.SORTINGREPORT_PROCESSING_TIME_TITLE = 'Temps de traitement';
lang.SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS = 'TEMPS DE DECOUPE pliage / écrasement';
lang.SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS = 'TEMPS DE GRUE';
lang.SORTINGREPORT_PROCESSING_TIME_CART_HOURS = 'TEMPS DE CHARIOT';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS = 'TEMPS DE TRI OPERATEUR / TRI NITON';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES = 'Minutes';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TONS = 'Tonnes';
lang.SORTINGREPORT_PROCESSING_TITLE = 'En cours';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR = 'Opérateur';
lang.SORTINGREPORT_PROCESSING_TIME_FORKLIFT = 'Chariot élévateur';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE = 'Grue';
lang.SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING = 'Découpe chalumeau';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA = 'Plasma';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR = 'Cisaille de grue';
lang.SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR = 'Cisaille guillotine';
lang.SORTINGREPORT_PROCESSING_TIME_DENSIFIER = 'Densificateur';
lang.SORTINGREPORT_PROCESSING_TIME_BALER = 'Presse';
lang.SORTINGREPORT_MEASUREMENT_TITLE = 'Mesures';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_WET = 'Échantillon humide (g)';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY = 'Échantillon sec (g)';
lang.SORTINGREPORT_MEASUREMENT_HUMIDITY = 'Humidité (%)';
lang.SORTINGREPORT_MEASUREMENT_FOR_MELTING = 'Poids à fondre (g)';
lang.SORTINGREPORT_MEASUREMENT_INGOT = 'Lingot (g)';
lang.SORTINGREPORT_MEASUREMENT_YIELD = 'Rendement (%)';
lang.SORTINGREPORT_MEASUREMENT_CONTAINER = 'Récipient';
lang.SORTINGREPORT_LASERNET_COPIES = 'Copies';
lang.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE = (numberOfCopies) => `${numberOfCopies} étiquette(s) sont en file d\'attente et seront imprimées pendant 1 minute`;
lang.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE = 'Erreur de configuration de l\'imprimante. Veuillez contacter votre administrateur';
lang.STATUS_LABINPUT_PRE_SAMPLE = 'Pré-échantillonnage';
lang.STATUS_LABINPUT_SAMPLE = 'Échantillonnage';
lang.STATUS_LABINPUT_RE_SAMPLE = 'Ré-échantillonnage';
lang.STATUS_LABINPUT_SORTATION = 'Tri';
lang.STATUS_LABINPUT_NITON_ANALYSIS = 'Analyse Niton';
lang.STATUS_LABSTATUSES_IN_LAB = 'Au labo';
lang.STATUS_LABSTATUSES_DRYING = 'Séchage';
lang.STATUS_LABSTATUSES_MELTING = 'Fusion';
lang.STATUS_LABSTATUSES_MELTED = 'Fondu';
lang.STATUS_LABSTATUSES_COMPLETE = 'Complété';
lang.STATUS_LABSTATUSES_REQUESTED = 'demandé';
lang.STATUS_LABSTATUSES_SAMPLED = 'Echantillonné';
lang.STATUS_LABSTATUSES_IN_PROGRESS = 'En cours';
lang.STATUS_DELIVERYSTATUSES_IN_WORK = 'En cours';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2 = 'Prêt pour le tri';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF = 'Prêt pour la validation';
lang.STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF = 'Partiellement signé';
lang.STATUS_DELIVERYSTATUSES_SIGNED_OFF = 'Validé';
lang.STATUS_DELIVERYSTATUSES_COMPLETE = 'Clôturé';
lang.STATUS_ADVISEDGOOD_WAITING = 'En attente';
lang.STATUS_ADVISEDGOOD_CLAIMED = 'Pris en charge';
lang.STATUS_ADVISEDGOOD_UNLOADED = 'Déchargé';
lang.STATUS_ADVISEDGOOD_IN_LAB = 'Au labo';
lang.STATUS_ADVISEDGOOD_READY_FOR_TIER_2 = 'Prêt pour le tri';
lang.STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF = 'Prêt pour la validation';
lang.STATUS_ADVISEDGOOD_SIGNED_OFF = 'Validé';
lang.STATUS_ADVISEDGOOD_COMPLETE = 'Clôturé';
lang.DASHBOARD_TITLES_DASHBOARD = 'Tableau de bord';
lang.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS = 'Livraisons en cours';
lang.DASHBOARD_TITLES_DELIVERY_BY_STATUS = 'Livraison par Statut';
lang.DASHBOARD_TITLES_AVERAGE_SORTING_TIME = 'Temps de tri moyen';
lang.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME = 'Délai d\'exécution moyen';
lang.DASHBOARD_TITLES_SORTING_TIME_TREND = 'Temps de tri des dernières livraisons';
lang.DASHBOARD_TITLES_FLAGGED_ITEMS = 'Livraisons bloquées';
lang.DASHBOARD_TITLES_TREES_SAVED = 'arbres sauvés';
lang.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL = 'Temps de tri (heures)';
lang.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL = 'Délai d\'exécution (heures)';
lang.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP = 'Temps de tri';
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTHS = (condition) => `En cours ${condition} mois`;
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTH = (condition) => `En cours ${condition} mois`;
lang.DASHBOARD_TITLES_IN_PROGRESS_WEEK = (condition) => `En cours ${condition} semaine`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS = (condition) => `Articles signalés ${condition} mois`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH = (condition) => `Articles signalés ${condition} mois`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK = (condition) => `Articles signalés ${condition} semaine`;
lang.USA_CONFIGURATION_CONFIGURATION = 'Configuration';
lang.USA_CONFIGURATION_SHOW_CONTRACT_NUMBER = 'Afficher le numéro d\'achat';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN = 'Afficher le détail de la livraison';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE = 'Afficher la tare de la marchandise annoncée';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION = 'Afficher le secteur de la marchandise annoncée';
lang.USA_CONFIGURATION_SHOW_DELIVERY_GROSS = 'Afficher les poids brut/tare/net de la livraison';
lang.USA_CONFIGURATION_SHOW_DELIVERY_TYPE = 'Afficher le type de livraison';
lang.USA_CONFIGURATION_SHOW_RADIATION_FILE = 'Télécharger le fichier de contrôle de la radioactivité';
lang.USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS = 'Afficher les conditions météorologiques';
lang.USA_CONFIGURATION_STOCK_CODE = 'Format du code stock';
lang.USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE = 'Tolérance du pont bascule';
lang.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR = 'Le téléchargement du fichier de contrôle de la radioactivité a échoué, merci de télécharger à nouveau';
lang.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG = 'Le fichier de contrôle de la radioactivité est obligatoire';
lang.USA_CONFIGURATION_WEATHER_CONDITIONS = 'Conditions météorologiques';
lang.USA_CONFIGURATION_DELIVERY_TYPE = 'Type de livraison';
lang.USA_CONFIGURATION_GROSS = 'Brut';
lang.USA_CONFIGURATION_TARE = 'Tare';
lang.USA_CONFIGURATION_NET = 'Net';
lang.USA_CONFIGURATION_RADIATION_FILE = 'Fichier de contrôle de la radioactivité';
lang.USA_CONFIGURATION_NO_RADIATION_FILE = 'Aucun fichier de contrôle de la radioactivité';
lang.USA_CONFIGURATION_ADV_TARE = 'Tare annoncée';
lang.PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED = 'Nouvelle livraison créée';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF = 'Livraison prête à valider';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF = 'Livraison validée';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER = 'Livraison clôturée par le commercial';

export default lang;
