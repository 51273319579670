import { action, computed, observable, makeObservable } from 'mobx';
import UserConfigurationModel from 'models/UserConfigurationModel';
import UpdateModel from 'models/UpdateModel';
import { cloneObj } from 'util/helpers';
import { CountryCode } from 'util/enums';

export default class WorkflowModel extends UpdateModel<WorkflowModel> {
  public id: string = null;
  public configuration: UserConfigurationModel = new UserConfigurationModel();
  public code: CountryCode = CountryCode.INIT;

  constructor() {
    super();

    makeObservable(this, {
      configuration: observable,
      code: observable,
      isUK: computed,
      isUS: computed,
      isDE: computed,
      isDE_D365: computed,
      isIT: computed,
      isFR: computed,
      update: action,
    });
  }

  get isUK(): boolean {
    return this.code === CountryCode.UK;
  }

  get isUS(): boolean {
    return this.code === CountryCode.US;
  }

  get isDE(): boolean {
    return this.code === CountryCode.DE;
  }

  get isDE_D365(): boolean {
    return this.code === CountryCode.DE_D365;
  }

  get isIT(): boolean {
    return this.code === CountryCode.IT;
  }

  get isFR(): boolean {
    return this.code === CountryCode.FR;
  }

  public update = (obj: WorkflowModel): WorkflowModel => {
    const newWorkflow = cloneObj(obj);

    if (newWorkflow) {
      newWorkflow.configuration = newWorkflow.configuration
        ? new UserConfigurationModel().update(newWorkflow.configuration)
        : new UserConfigurationModel();
    }

    this.updater.update(this, newWorkflow, WorkflowModel);
    return this;
  };
}
