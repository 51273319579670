import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import ScaleDetailComponent from 'pod/admin/scales/ScaleDetailComponent';
import ScaleModel from 'models/ScaleModel';
import TranslateService from 'services/TranslateService';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import AdminStore from 'pod/admin/AdminStore';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class ScaleDetailRoute extends React.Component<IProps> {
  public content: ScaleModel = null;

  public updateContent = (newScale: ScaleModel) => {
    this.content = new ScaleModel().update(newScale);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      updateContent: action,
    });
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { scaleList, scaleApi },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.SCALE}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_SCALE}
        itemsList={scaleList}
        api={scaleApi}
        updateItem={this.updateContent}
      >
        <ScaleDetailComponent content={this.content} />
      </CommonAdminRoute>
    );
  }
}

export default inject('adminStore', 'translateService')(observer(ScaleDetailRoute));
