import { action, computed, observable, makeObservable } from 'mobx';

import CertificateModel from 'models/CertificateModel';
import { ICode, IConstructSaveObj, IName } from 'models/ModelInterfaces';

export default class RoadHaulierModel extends CertificateModel
  implements IName, ICode, IConstructSaveObj<RoadHaulierModel> {
  public code: string = '';
  public name: string = '';
  public id: string = null;
  public authorizationNumber?: string = '';
  public active: boolean = false;

  constructor() {
    super();

    makeObservable(this, {
      code: observable,
      name: observable,
      id: observable,
      authorizationNumber: observable,
      active: observable,
      constructSaveObj: computed,
      changeAuthorizationNumber: action,
      changeActiveStatus: action,
      changeName: action,
      changeCode: action,
    });
  }

  public get constructSaveObj(): RoadHaulierModel {
    return {
      name: this.name,
      code: this.code,
      active: this.active,
      expirationDate: this.expirationDate,
      authorizationNumber: this.authorizationNumber,
    } as RoadHaulierModel;
  }

  public update = (obj: RoadHaulierModel) => {
    super.update(obj);
    return this;
  };

  public changeAuthorizationNumber = (newNumber: string): void => {
    this.authorizationNumber = newNumber;
  };

  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);

  public changeName = (newName: string) => (this.name = newName);

  public changeCode = (newCode: string) => (this.code = newCode);
}
