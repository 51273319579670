import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import { action, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import { Collapse, FormGroup, Label, Input } from 'reactstrap';
import KeyValueModel from 'models/KeyValueModel';
import { getTranslation, inputNumberStep } from 'util/helpers';
import SessionStore from 'stores/SessionStore';
import { I18N } from '../../../assets/i18n/i18n';
import InputNumber from 'components/InputNumber';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import { ContaminationSection } from 'util/enums';
import { TOTAL } from 'util/constants';
import ViewStore from 'stores/ViewStore';
import ReceivedGoodsStore from 'pod/receivedGoods/ReceivedGoodsStore';
import IdNameModel from 'models/IdNameModel';
import InputWithNullCheck from 'components/InputWithNullCheck';
import SortingReportDetailSection from 'pod/receivedGoods/SortingReportDetailSection';

interface IContaminationProps {
  receivedGood: ReceivedGoodsModel;
  isReadOnly: boolean;
  isShownOnlyFieldsWithValue: boolean;
  addContaminationPackagingSectionItems: (items: KeyValueModel[]) => void;
  isDepartmentHSS: boolean;
  translateService?: TranslateService;
  sessionStore?: SessionStore;
  viewStore?: ViewStore;
  receivedGoodsStore?: ReceivedGoodsStore;
  validate?: boolean;
}

interface IContaminationData {
  key: string;
  keyValueArray: KeyValueModel[];
  section: ContaminationSection;
}

class Contamination extends React.Component<IContaminationProps> {
  private _visibleContaminationSections: ContaminationSection[] = [];

  constructor(props: IContaminationProps) {
    super(props);

    makeObservable<Contamination, '_visibleContaminationSections' | '_toggleContaminationSection'>(this, {
      _visibleContaminationSections: observable,
      _toggleContaminationSection: action,
    });
  }

  public componentDidUpdate(): void {
    const {
      viewStore: { isDE, isDE_D365 },
    } = this.props;
    if (isDE || isDE_D365) {
      this._updatePackagingSection();
    }
  }

  public render() {
    const {
      receivedGood,
      isReadOnly,
      translateService: { t },
      validate,
      viewStore: { countryCode },
    } = this.props;

    return (
      <SortingReportDetailSection
        title={this._renderContaminationHeader}
        hasFieldWithError={
          validate && !isReadOnly && !receivedGood.validateOnlyExistingFields(['contamination'], countryCode)
        }
      >
        {this._contaminationData.map((item, index) => {
          const isSectionActive = this._isContaminationSectionActive(item.section);
          return (
            <React.Fragment key={index}>
              <h6
                onClick={this._toggleContaminationSection}
                data-section={item.section}
                className="padding-left-30 pointer"
              >
                {item.key}
                <span data-test="number-of-items" className="margin-left-1rem">
                  {this._getContaminationGroupValue(item.keyValueArray, item.section)}
                </span>
                {this._hasSectionWarning(item.keyValueArray, item.section) && (
                  <span data-test="warning" className="color-warning margin-left-1rem">
                    <FontAwesome name="exclamation-triangle" />
                    {this._sectionWarningMessage(item.section)}
                  </span>
                )}
                <FontAwesome className="margin-left-20" name={isSectionActive ? 'angle-up' : 'angle-down'} />
              </h6>
              <Collapse isOpen={isSectionActive}>
                <FormGroup row className="contamination-section">
                  {this._renderKeyValueArrayModel(item.keyValueArray, item.section)}
                </FormGroup>
              </Collapse>
            </React.Fragment>
          );
        })}
        <h6 className="padding-left-30">
          <Label className="margin-right-10 font-1rem pointer" for="no-contamination">
            {t.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION}
          </Label>
          <Input
            id="no-contamination"
            className="ms-1 margin-top-0-2rem pointer"
            data-test="no-contamination"
            disabled={isReadOnly}
            type="checkbox"
            checked={receivedGood.noContamination}
            onChange={this._setNoContamination}
          />
        </h6>
      </SortingReportDetailSection>
    );
  }

  private _toggleContaminationSection = (e: React.MouseEvent<HTMLHeadingElement>) => {
    const sectionName = e.currentTarget.dataset.section as ContaminationSection;
    if (this._visibleContaminationSections.includes(sectionName)) {
      this._visibleContaminationSections = this._visibleContaminationSections.filter(
        (section) => section !== sectionName
      );
    } else {
      this._visibleContaminationSections.push(sectionName);
    }
  };

  private _setNoContamination = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.receivedGood.setNoContamination(e.target.checked);
  };

  private _isContaminationSectionActive = (sectionName: ContaminationSection) => {
    return this._visibleContaminationSections.includes(sectionName);
  };

  private _renderKeyValueArrayModel(array: KeyValueModel[], sectionName: ContaminationSection) {
    return array.map((item: KeyValueModel, index: number) => {
      const key = `${sectionName}.${index}`;

      return (
        <div data-test="contamination-section-field" className="contamination-section-field" key={key}>
          {this._renderLabel(item, key, sectionName)}
          {this._renderInput(item, key)}
        </div>
      );
    });
  }

  private get _contaminationData(): IContaminationData[] {
    const {
      receivedGood: { contamination },
      translateService: { t },
      isShownOnlyFieldsWithValue,
      viewStore: { isDE, isDE_D365 },
      isDepartmentHSS,
    } = this.props;

    const contaminationData = [
      {
        key: t.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH,
        keyValueArray: contamination.nonMetallicAttach,
        section: ContaminationSection.nonMetallicAttach,
      },
      {
        key: t.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH,
        keyValueArray: contamination.metallicAttach,
        section: ContaminationSection.metallicAttach,
      },
      {
        key: t.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS,
        keyValueArray: contamination.differentMetals,
        section: ContaminationSection.differentMetals,
      },
      {
        key: t.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS,
        keyValueArray: contamination.turningsInSolids,
        section: ContaminationSection.turningsInSolids,
      },
      {
        key: t.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE,
        keyValueArray: contamination.notInFurnaceSize,
        section: ContaminationSection.notInFurnaceSize,
      },
      {
        key: t.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS,
        keyValueArray: contamination.materialForms,
        section: ContaminationSection.materialForms,
      },
      {
        key: t.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION,
        keyValueArray: contamination.turningsComposition,
        section: ContaminationSection.turningsComposition,
      },
    ];

    if (isDE || isDE_D365) {
      contaminationData.push(
        {
          key: t.RECEIVED_GOOD_CONTAMINATION_PACKAGING,
          keyValueArray: contamination.packaging,
          section: ContaminationSection.packaging,
        },
        {
          key: t.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND,
          keyValueArray: contamination.tareInbound,
          section: ContaminationSection.tareInbound,
        },
        {
          key: t.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND,
          keyValueArray: contamination.tareOutbound,
          section: ContaminationSection.tareOutbound,
        }
      );
      if (isDepartmentHSS) {
        contaminationData.push({
          key: t.RECEIVED_GOOD_CONTAMINATION_SIZES,
          keyValueArray: contamination.sizes,
          section: ContaminationSection.sizes,
        });
      }
    }

    return isShownOnlyFieldsWithValue
      ? contaminationData.map((item) => {
          item.keyValueArray = item.keyValueArray.filter((arr) => arr.value);
          return item;
        })
      : contaminationData;
  }

  private get _calculateTotalNumber(): number {
    return this._contaminationData.reduce(
      (res, section) => res + this._calculateSectionValue(section.keyValueArray, section.section),
      0
    );
  }

  // TODO: remove or return back after feedback regarding contamination total sum
  // private get _calculateTotalSum(): number {
  //   return this._contaminationData.reduce((res, section) => {
  //     if (
  //       section.section === ContaminationSection.turningsComposition ||
  //       section.section === ContaminationSection.packaging ||
  //       section.section === ContaminationSection.sizes
  //     ) {
  //       return res;
  //     }
  //
  //     if (section.section === ContaminationSection.tareOutbound) {
  //       res -= this._calculateSectionSumOfFields(section.keyValueArray, section.section);
  //     } else {
  //       res += this._calculateSectionSumOfFields(section.keyValueArray, section.section);
  //     }
  //
  //     return res;
  //   }, 0);
  // }

  private get _renderContaminationHeader(): string {
    const {
      translateService: { t },
    } = this.props;
    // TODO: remove or return back after feedback regarding contamination total sum
    // return `${t.RECEIVED_GOOD_CONTAMINATION_TITLE} (${this._calculateTotalNumber}) ${this._calculateTotalSum}`;
    return `${t.RECEIVED_GOOD_CONTAMINATION_TITLE} (${this._calculateTotalNumber})`;
  }

  private _hasSectionWarning(array: KeyValueModel[], sectionName: ContaminationSection) {
    const { isDepartmentHSS } = this.props;
    const sectionSum = this._calculateSectionSumOfFields(array, sectionName);
    return sectionName === ContaminationSection.sizes && isDepartmentHSS && sectionSum && sectionSum !== 100;
  }

  private _sectionWarningMessage(sectionName: ContaminationSection) {
    const {
      translateService: { t },
    } = this.props;
    return sectionName === ContaminationSection.sizes ? t.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING : '';
  }

  private _renderLabel(item: KeyValueModel, key: string, sectionName: ContaminationSection) {
    return <Label className="margin-right-10" for={key}>{`${this._createLabel(item.name, sectionName)}`}</Label>;
  }

  private _renderInput(item: KeyValueModel, key: string): React.ReactNode | undefined {
    const {
      receivedGood,
      sessionStore: {
        tenant: { workflow },
      },
      viewStore: { numberOfDecimals },
    } = this.props;
    switch (item.inputType.get(workflow.code)) {
      case 'number':
        return (
          <InputNumber
            key={`${key}.value`}
            data-test={`${key}.value`}
            disabled={this.props.isReadOnly}
            step={inputNumberStep(numberOfDecimals)}
            className="width-100"
            id={key}
            value={item.value}
            decimalPlaces={numberOfDecimals}
            onChangeValue={(number: number) => this._setValue(receivedGood, item, number)}
          />
        );

      case 'checkbox':
        return (
          <InputWithNullCheck
            key={`${key}.value`}
            data-test={`${key}.value`}
            disabled={this.props.isReadOnly}
            id={key}
            type="checkbox"
            checked={Boolean(item.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this._setValue(receivedGood, item, Number(e.target.checked))
            }
          />
        );
    }
  }

  private _createLabel = (key: string, sectionName: ContaminationSection) => {
    const {
      translateService: { t },
    } = this.props;
    return sectionName === ContaminationSection.packaging
      ? key
      : getTranslation(`RECEIVED_GOOD_CONTAMINATION_${key}` as keyof I18N, t);
  };

  private _calculateSectionValue = (keyValueArray: KeyValueModel[], sectionName: ContaminationSection): number => {
    switch (sectionName) {
      case ContaminationSection.tareInbound:
      case ContaminationSection.tareOutbound:
        return keyValueArray.reduce((res, item) => {
          if (item.name === TOTAL) {
            return res;
          }
          res += item.value;
          return res;
        }, 0);

      default:
        return this._getKeyValueArrayItemNumber(keyValueArray);
    }
  };

  private _calculateSectionSumOfFields = (
    keyValueArray: KeyValueModel[],
    sectionName: ContaminationSection
  ): number => {
    switch (sectionName) {
      case ContaminationSection.turningsComposition:
      case ContaminationSection.packaging:
        return null;

      case ContaminationSection.tareInbound:
      case ContaminationSection.tareOutbound:
        const total = keyValueArray.find((item) => item.name === TOTAL);
        return total && total.value ? total.value : 0;
      default:
        return this._getKeyValueArrayItemSum(keyValueArray);
    }
  };

  // TODO: remove or return back after feedback regarding contamination total sum
  // private _getMeasurements(sectionSum: number | string, sectionName: ContaminationSection) {
  //   return sectionName === ContaminationSection.sizes && sectionSum ? '%' : '';
  // }

  private _getKeyValueArrayItemNumber = (keyValueArray: KeyValueModel[]): number => {
    return keyValueArray.filter((item) => item.value).length;
  };

  private _getKeyValueArrayItemSum = (keyValueArray: KeyValueModel[]): number => {
    return keyValueArray.reduce((res, item) => res + item.value, 0);
  };

  private _getContaminationGroupValue = (keyValueArray: KeyValueModel[], sectionName: ContaminationSection): string => {
    const sectionValue = this._calculateSectionValue(keyValueArray, sectionName);
    // TODO: remove or return back after feedback regarding contamination total sum
    // const sectionSum = this._calculateSectionSumOfFields(keyValueArray, sectionName) || '';
    // const sectionMeasurements = this._getMeasurements(sectionSum, sectionName);
    // return `(${sectionValue}) ${sectionSum}${sectionMeasurements}`;
    return `(${sectionValue})`;
  };

  private _updatePackagingSection() {
    const {
      receivedGood: { contamination, addContaminationPackagingSectionItems },
      receivedGoodsStore: { packaging },
    } = this.props;

    const newPackagingItems = packaging
      .filter((item) => !contamination.packaging.some((i: KeyValueModel) => i.name === item.name))
      .map((item: IdNameModel) => new KeyValueModel().update({ name: item.name } as KeyValueModel));

    if (newPackagingItems) {
      addContaminationPackagingSectionItems(newPackagingItems);
    }
  }

  private _setValue(receivedGood: ReceivedGoodsModel, item: KeyValueModel, value: number) {
    item.changeValue(value);
    receivedGood.setNoContamination(false);
  }
}

export default inject('translateService', 'sessionStore', 'viewStore', 'receivedGoodsStore')(observer(Contamination));
