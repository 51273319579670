import * as React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Button } from 'reactstrap';
import { instance as notification } from 'util/notification';
import TranslateService from 'services/TranslateService';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import DeliveryListAdvisedGoodModel from 'models/DeliveryListAdvisedGoodModel';
import ModalConfirmation from 'domain/ModalConfirmation';
import { AdvisedGoodStatus, DeliveryStatus } from 'util/enums';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';

interface IAdvisedGoodSignOffButtonProps {
  ag: DeliveryListAdvisedGoodModel | AdvisedGoodsModel;
  setDeliveryStatus?: (newStatus: DeliveryStatus) => void;
  translateService?: TranslateService;
  advisedGoodsStore?: AdvisedGoodsStore;
  validate?: () => boolean;
}

class AdvisedGoodSignOffButton extends React.Component<IAdvisedGoodSignOffButtonProps> {
  private _confirmationModalState = false;

  private _toggleConfirmationModalState = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    this._confirmationModalState = !this._confirmationModalState;
  };

  private _signOffAG = () => {
    const {
      ag,
      translateService: { t },
      advisedGoodsStore: { postSignOffAdvisedGood },
      setDeliveryStatus,
      validate,
    } = this.props;

    if (validate && !validate()) {
      return;
    }

    postSignOffAdvisedGood(ag.id).then(({ deliveryStatus }) => {
      notification.success(t.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE);
      ag.setStatus(AdvisedGoodStatus.SIGNED_OFF);

      if (setDeliveryStatus && deliveryStatus) {
        setDeliveryStatus(deliveryStatus);
      }
    });
  };

  constructor(props: IAdvisedGoodSignOffButtonProps) {
    super(props);

    makeObservable<AdvisedGoodSignOffButton, '_confirmationModalState' | '_toggleConfirmationModalState'>(this, {
      _confirmationModalState: observable,
      _toggleConfirmationModalState: action,
    });
  }

  public render() {
    const {
      ag,
      translateService: { t },
      advisedGoodsStore: { hasAgSignOff },
    } = this.props;

    return hasAgSignOff(ag) ? (
      <React.Fragment>
        <Button
          data-test="sign-off-ag-btn"
          size="sm"
          className="sign-off-ag-btn"
          onClick={this._toggleConfirmationModalState}
        >
          {t.ADVISEDGOOD_ROUTE_SIGN_OFF_AG}
        </Button>
        <ModalConfirmation
          data-test="confirmation-sign-off-ag"
          buttonYesColor="success"
          callbackYes={this._signOffAG}
          heading={t.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER}
          ico="power-off"
          isOpen={this._confirmationModalState}
          text={t.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT}
          toggleModal={this._toggleConfirmationModalState}
        />
      </React.Fragment>
    ) : null;
  }
}

export default inject('translateService', 'advisedGoodsStore')(observer(AdvisedGoodSignOffButton));
