import RootService from 'services/RootService';
import reactNative from 'util/react-native';
import { ReactNativeActionType } from 'util/enums';
import { logger } from 'util/logger';
import storage from 'util/storage';
import { DEVICE_TOKEN } from 'util/constants';

class DeviceTokenStore {
  public constructor(private readonly rootService: RootService) {
    window.saveDeviceToken = (token: string) => {
      this._saveDeviseToken(token);
    };
  }

  public sendUserIsLoggedInStatus = () => {
    reactNative.send({ command: ReactNativeActionType.IS_LOGGED_IN });
  };

  public sendUserIsLogoutStatus = () => {
    storage.remove(DEVICE_TOKEN);
    reactNative.send({ command: ReactNativeActionType.LOGOUT });
  };

  private _saveDeviseToken = async (token: string) => {
    try {
      const tokensArray = await this._getAllUserDeviceTokens();
      if (!tokensArray.includes(token)) {
        await this._saveDeviceTokenRequest(token);
        storage.set(DEVICE_TOKEN, token);
      }
    } catch (e) {
      logger.error(e);
    }
  };

  private _getAllUserDeviceTokens = (): Promise<string[]> => this.rootService.ajaxService.get('firebase/device-tokens');

  private _saveDeviceTokenRequest = (token: string) =>
    this.rootService.ajaxService.post('firebase/device-tokens', { deviceToken: token });
}

export default DeviceTokenStore;
