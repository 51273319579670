import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';
import LasernetPrinterConfigurationModel from 'models/LasernetPrinterConfigurationModel';
import Autocomplete from 'domain/Autocomplete';
import LasernetPrinterModel from 'models/LasernetPrinterModel';
import IdNameModel from 'models/IdNameModel';

interface IPrinterDetailConfigurationComponentProps {
  content: LasernetPrinterConfigurationModel;
  lasernetPrinterStore?: LasernetPrinterStore;
  translateService?: TranslateService;
}

const LasernetPrinterConfigurationDetailComponent: React.FunctionComponent<IPrinterDetailConfigurationComponentProps> = ({
  content,
  lasernetPrinterStore,
  translateService: { t },
}) => {
  const {
    userListForAutocomplete,
    printerListOnlyActive,
    documentTypesListForAutocomplete,
    getPrinterById,
    getDocumentTypeById,
    getUserById,
  } = lasernetPrinterStore;

  const changePrinterId = (item: LasernetPrinterModel) => {
    content.setPrinterId(item.id);
  };
  const changeUserId = (item: IdNameModel) => {
    content.setUserId(item.id);
  };
  const changeDocumentTypeId = (item: IdNameModel) => {
    content.setDocumentTypeId(item.id);
  };

  return (
    content && (
      <React.Fragment>
        <FormGroup row>
          <Label for="userId" sm={4}>
            {t.ADMIN_SECTIONENTITY_USER}
          </Label>
          <Col sm={8}>
            <Autocomplete
              id="userId"
              data-test="user-id"
              placeholder={t.GLOBAL_LABEL_SELECT}
              items={userListForAutocomplete}
              onSelect={changeUserId}
              selectedValue={getUserById(content.userId)?.id}
              selectedName={getUserById(content.userId)?.name}
              displayCodeAndName
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="documentTypeId" sm={4}>
            {t.ADMIN_LASERNET_DOCUMENT_TYPE}
          </Label>
          <Col sm={8}>
            <Autocomplete
              id="documentTypeId"
              data-test="document-type-id"
              placeholder={t.GLOBAL_LABEL_SELECT}
              items={documentTypesListForAutocomplete}
              onSelect={changeDocumentTypeId}
              selectedValue={getDocumentTypeById(content.documentTypeId)?.id}
              selectedName={getDocumentTypeById(content.documentTypeId)?.name}
              displayCodeAndName
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="printerId" sm={4}>
            {t.ADMIN_PRINTERLIST_NAME}
          </Label>
          <Col sm={8}>
            <Autocomplete
              id="printerId"
              data-test="printer-id"
              placeholder={t.GLOBAL_LABEL_SELECT}
              items={printerListOnlyActive}
              onSelect={changePrinterId}
              selectedValue={getPrinterById(content.printerId)?.id}
              selectedName={getPrinterById(content.printerId)?.name}
              displayCodeAndName
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    )
  );
};

export default inject(
  'translateService',
  'lasernetPrinterStore'
)(observer(LasernetPrinterConfigurationDetailComponent));
