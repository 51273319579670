import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';

interface IBellProps extends IBellDefaultProps {
  amountUnreadMessages: number;
}

interface IBellDefaultProps {
  containerClassName?: string;
  amountClassName?: string;
  notificationSoundPath?: string;
}

export default class Bell extends React.Component<IBellProps> {
  public static defaultProps: IBellDefaultProps = {
    containerClassName: 'font-22',
    amountClassName: 'font-10',
    notificationSoundPath: '../../assets/audio/bell.mp3',
  };

  private _audio: HTMLAudioElement = new Audio(this.props.notificationSoundPath);

  public componentDidUpdate(prevProps: IBellProps): void {
    if (this.props.amountUnreadMessages > prevProps.amountUnreadMessages) {
      this._audio.play();
    }
  }

  public render() {
    const { amountUnreadMessages, amountClassName, containerClassName } = this.props;

    return amountUnreadMessages ? (
      <div className={`bell-container ${containerClassName}`}>
        <div>
          <FontAwesome name="bell" />
          <div className={`bell-amount ${amountClassName}`}>{amountUnreadMessages}</div>
        </div>
      </div>
    ) : null;
  }
}
