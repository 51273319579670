import * as React from 'react';
import { action, makeObservable, observable } from 'mobx';
import TenantModel, { ITenantModelValidationKeys } from 'models/TenantModel';
import { IActive } from 'models/ModelInterfaces';

interface ITenantSectionModelOptions {
  name: string;
  component: React.FC;
  index: number;
  active: boolean;
  validationRules: Array<keyof Partial<ITenantModelValidationKeys>>;
}

export class TenantSectionModel implements IActive {
  public constructor(options: ITenantSectionModelOptions) {
    Object.assign(this, options);
    makeObservable(this, {
      active: observable,
      changeActiveStatus: action,
    })
  }
  public name: string = '';
  public component: React.FC = null;
  public active: boolean = false;
  public index: number = null;
  public validationRules: Array<keyof ITenantModelValidationKeys> = [];

  public changeActiveStatus(active: boolean) {
    this.active = active;
  }

  public isInvalidSection = (tenant: TenantModel) => {
    return tenant.needToCheck && this.validationRules.some(rule => tenant.isInvalidFieldByKey(rule));
  }
}
