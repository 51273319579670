import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import TranslateService from 'services/TranslateService';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import { AdminRootUrl } from 'util/enums';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';
import LasernetPrinterModel from 'models/LasernetPrinterModel';
import LasernetPrinterDetailComponent from 'pod/admin/lasernetPrinters/printers/LasernerPrinterDetailComponent';

interface IProps {
  lasernetPrinterStore?: LasernetPrinterStore;
  translateService?: TranslateService;
}

class LasernetPrinterDetailRoute extends React.Component<IProps> {
  public content: LasernetPrinterModel = null;

  public updateContent = (newPrinter: LasernetPrinterModel) => {
    this.content = new LasernetPrinterModel().update(newPrinter);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      updateContent: action,
    });
  }

  public render() {
    const {
      translateService: { t },
      lasernetPrinterStore: { printerApi, printers },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.LASERNET_PRINTERS}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_PRINTER}
        itemsList={printers}
        api={printerApi}
        updateItem={this.updateContent}
      >
        <LasernetPrinterDetailComponent content={this.content} />
      </CommonAdminRoute>
    );
  }
}

export default inject('lasernetPrinterStore', 'translateService')(observer(LasernetPrinterDetailRoute));
