import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { getTranslation, inputNumberStep, processDecimals } from 'util/helpers';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import Autocomplete from 'domain/Autocomplete';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import SessionStore from 'stores/SessionStore';
import SortingReportDetailComponent from 'pod/receivedGoods/SortingReportDetailComponent';
import StockItemModel from 'models/StockItemModel';
import TranslateService from 'services/TranslateService';
import InputNumber from 'components/InputNumber';
import { AdvisedGoodStatus, Position } from 'util/enums';
import ViewStore from 'stores/ViewStore';
import { ITransferData } from 'models/TransferModel';
import CommonStore from 'stores/CommonStore';
import {
  BORDER_DANGER,
  SORTING_REPORT_DESCRIPTION_MAX_LENGTH_DEFAULT,
  SORTING_REPORT_DESCRIPTION_MAX_LENGTH_UK,
  TEXT_DANGER,
} from 'util/constants';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import ModalConfirmation from 'domain/ModalConfirmation';
import { instance as notification } from 'util/notification';
import ReceivedGoodsStore from 'pod/receivedGoods/ReceivedGoodsStore';
import { I18N } from '../../../assets/i18n/i18n';
import StockItem from 'components/StockItemComponent';

interface IProps {
  advisedGood: AdvisedGoodsModel;
  index: number;
  isReadOnly: boolean;
  isRemarksReadOnly: boolean;
  item: ReceivedGoodsModel;
  key: string;
  labAnalysisCreate: (receivedGood: ReceivedGoodsModel, labStatusSelected: string) => void;
  preOpenModal: (itemId: string) => boolean;
  transferDeliveredGood: (deliveredGood: ReceivedGoodsModel, transfer: ITransferData) => Promise<void>;
  updateReceivedGoodGrossWeight: (item: ReceivedGoodsModel, newVal: number, toFixed?: boolean) => void;
  isTareNetFieldsShown: boolean;
  isStockItemShown: boolean;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  commonStore?: CommonStore;
  receivedGoodsStore?: ReceivedGoodsStore;
  isNeedToValidateRGs?: boolean;
}

interface IState {
  isModalOpen: boolean;
  negativeGrossWeightConfirmationModal: boolean;
  flagConfirmationModal: boolean;
}

class SortingReportLineComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      negativeGrossWeightConfirmationModal: false,
      flagConfirmationModal: false,
    };
  }

  public componentDidMount() {
    if (this.props.preOpenModal(this.props.item.id)) {
      this._toggleModal(true);
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      preOpenModal,
      item: { id },
    } = this.props;
    if (prevProps.item.id !== id && preOpenModal(id)) {
      this._toggleModal(true);
    }
  }

  public get descriptionMaxLength() {
    const {
      viewStore: { isUK },
    } = this.props;
    return isUK ? SORTING_REPORT_DESCRIPTION_MAX_LENGTH_UK : SORTING_REPORT_DESCRIPTION_MAX_LENGTH_DEFAULT;
  }

  public render() {
    const { isModalOpen, flagConfirmationModal } = this.state;
    const {
      item,
      isReadOnly,
      viewStore: { numberOfDecimals, isDE_D365, isFR, hasOverwriteRgDescriptionDialogEnabled },
      isTareNetFieldsShown,
      isStockItemShown,
      translateService: { t },
      isRemarksReadOnly,
      isNeedToValidateRGs,
      sessionStore: { isTier1 },
    } = this.props;

    if (isReadOnly && !item.description && !item.grossWeight && !item.stockCode) {
      return null;
    }

    return (
      <tbody>
        <tr className="pointer">
          {isStockItemShown && (
            <td className="ps-3 srl-stock-code-padding" data-test="stock-item-field">
              {this._isStockItemReadOnly ? (
                item.stockCode
              ) : (
                <StockItem
                  isAutocomplete={isDE_D365 || isFR}
                  isDisabled={this._isStockItemDisabled}
                  isInvalid={this._isInvalidStockItem}
                  item={item}
                  isAutoChangeDescription={!(isDE_D365 && isTier1)}
                  overwriteRgDescriptionDialogEnabled={hasOverwriteRgDescriptionDialogEnabled}
                />
              )}
            </td>
          )}

          {this._renderDescription()}

          <td className="srl-weight-align-padding" data-test="srl-weight-field">
            {isReadOnly ? (
              processDecimals(item.grossWeight, numberOfDecimals)
            ) : (
              <InputNumber
                data-test="srl-weight"
                disabled={this._isRGWeightReadOnly}
                decimalPlaces={numberOfDecimals}
                onBlur={this._onBlurGrossWeightHandler}
                onChangeValue={this._updateGrossWeight}
                step={inputNumberStep(numberOfDecimals)}
                type="number"
                value={item.grossWeight}
                className={classnames({ [BORDER_DANGER]: !this._isValidGrossWeight() })}
                allowNegativeValue={this._isAllowNegativeGrossWeight}
              />
            )}
          </td>
          {isTareNetFieldsShown && (
            <React.Fragment>
              <td className="srl-weight-align-padding" data-test="tare-weight-field">
                {isReadOnly ? (
                  processDecimals(item.tareWeight, numberOfDecimals)
                ) : (
                  <InputNumber
                    data-test="tare-weight"
                    disabled={isReadOnly}
                    decimalPlaces={numberOfDecimals}
                    onBlur={this._onBlurTareWeightHandler}
                    onChangeValue={this._updateTareWeight}
                    step={inputNumberStep(numberOfDecimals)}
                    type="number"
                    value={item.tareWeight}
                  />
                )}
              </td>
              <td className="srl-weight-align-padding" data-test="net-weight-field">
                {isReadOnly ? (
                  processDecimals(item.netWeight, numberOfDecimals)
                ) : (
                  <InputNumber
                    data-test="net-weight"
                    disabled={true}
                    decimalPlaces={numberOfDecimals}
                    type="number"
                    value={item.netWeight}
                  />
                )}
              </td>
            </React.Fragment>
          )}
          <td className="text-end pe-3 srl-info-button">
            <Button
              data-test="info-btn"
              color={this._infoButtonColor}
              onClick={() => this._toggleModal(true)}
              className={classnames(['width-38', { [BORDER_DANGER]: !this._isButtonInfoValid() }])}
            >
              <FontAwesome name="info" />
            </Button>

            {/* MODAL - SORTING REPORT DETAIL MODAL */}
            {isModalOpen && (
              <Modal
                data-test="modal"
                id="sorting-report-modal"
                isOpen={isModalOpen}
                toggle={() => this._toggleModal()}
                backdrop="static"
              >
                <ModalHeader data-test="modal-header" toggle={() => this._toggleModal()}>
                  {this._showFlagButton && (
                    <Button
                      data-test="rg-flag-btn"
                      color={item.flagged ? 'danger' : 'success'}
                      onClick={this._toggleFlagConfirmationModal}
                      className="margin-right-1rem"
                    >
                      <FontAwesome name="flag" className="me-0" />
                    </Button>
                  )}
                  {this._sortingReportModalTitle}
                </ModalHeader>
                <ModalBody className="ps-0 pe-0 pt-0">
                  {item.flagged && (
                    <Alert color="danger" className="text-center margin-top-1rem" data-test="rg-flagged-alert">
                      <strong>{t.GLOBAL_LABEL_ALERT_RECEIVED_GOOD}</strong>
                    </Alert>
                  )}
                  <SortingReportDetailComponent
                    advisedGood={this.props.advisedGood}
                    item={item}
                    isReadOnly={isReadOnly}
                    isStockCodeReadOnly={this._isStockItemDisabled}
                    isStockCodeInvalid={this._isInvalidStockItem}
                    isRGWeightReadOnly={this._isRGWeightReadOnly}
                    isRemarksReadOnly={isRemarksReadOnly}
                    labAnalysisCreate={this.props.labAnalysisCreate}
                    transferDeliveredGood={this.props.transferDeliveredGood}
                    descriptionMaxLength={this.descriptionMaxLength}
                    isNeedToValidateRGs={isNeedToValidateRGs}
                    allowNegativeGrossWeight={this._isAllowNegativeGrossWeight}
                    onBlurGrossWeightHandler={this._onBlurGrossWeightHandler}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button data-test="save-btn" color="success" onClick={this._saveSortingReport}>
                    {t.GLOBAL_LABEL_SAVE}
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {/* MODAL CONFIRMATION FOR SAVE NEGATIVE GROSS WEIGHT */}
            <ModalConfirmation
              data-test="negative-gross-weight-confirm"
              buttonYesColor="success"
              buttonNo={t.GLOBAL_LABEL_NO}
              buttonYes={t.GLOBAL_LABEL_YES}
              callbackNo={this._cancelSaveNegativeGrossWeight}
              callbackYes={() => null}
              heading={t.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE}
              ico="check"
              isOpen={this.state.negativeGrossWeightConfirmationModal}
              body={this._negativeGrossWeightConfirmationBody}
              toggleModal={this._toggleNegativeGrossWeightConfirmationModal}
            />

            {/* MODAL CONFIRMATION FOR FLAGGING */}
            <ModalConfirmation
              data-test="flag-received-good-modal"
              buttonYesColor={item.flagged ? 'success' : 'danger'}
              callbackYes={this._flagReceivedGood}
              heading={t.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER(this._hasFlag)}
              ico="flag"
              isOpen={flagConfirmationModal}
              text={t.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT(item.flagged ? 'text-success' : TEXT_DANGER, this._hasFlag)}
              toggleModal={this._toggleFlagConfirmationModal}
            />
          </td>
        </tr>
      </tbody>
    );
  }

  private _renderDescription = () => {
    const {
      item,
      viewStore: { isUS },
      isReadOnly,
      isStockItemShown,
      commonStore: { stockItems },
      translateService: { t },
    } = this.props;

    const field = isUS ? (
      <InputWithLimitOfCharacters
        data-test="sorting-report-description-us"
        disabled={isReadOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => item.setDescription(e.target.value)}
        required
        type="text"
        maxLength={this.descriptionMaxLength}
        value={item.description}
        className={classnames({ [BORDER_DANGER]: !isReadOnly && !item.validators.description })}
      />
    ) : (
      <Autocomplete
        data-test="sorting-report-description"
        inputClassName={classnames({ [BORDER_DANGER]: !isReadOnly && !item.validators.description })}
        disabled={isReadOnly}
        placeholder={t.GLOBAL_LABEL_SELECT}
        items={stockItems}
        onChange={(str: string) => item.setDescription(str)}
        onSelect={this._onDescriptionSelect}
        selectedValue={item.description}
        maxLength={this.descriptionMaxLength}
        charactersRemainingPosition={Position.top}
      />
    );

    return (
      <td className={classnames({ 'ps-3': !isStockItemShown }, 'srl-description-content', 'srl-description-padding')}>
        {isReadOnly ? <span>{item.description}</span> : field}
      </td>
    );
  };

  private _onDescriptionSelect = (selectedItem: StockItemModel) => {
    const {
      item,
      viewStore: { isFR },
    } = this.props;
    item.setDescription(selectedItem.name);
    if (isFR) {
      item.setStockCode(selectedItem.code);
    }
  };

  private get _sortingReportModalTitle(): string {
    const {
      viewStore: { isDE, isIT, isFR, isDE_D365 },
      item,
      translateService: { t },
    } = this.props;
    return (
      t.ADVISEDGOOD_SORTINGREPORT_HEADER +
      ((isDE || isDE_D365 || isIT || isFR) && item.rgTitle ? `: ${item.rgTitle}` : '')
    );
  }

  private get _isStockItemReadOnly(): boolean {
    const {
      isReadOnly,
      advisedGood,
      viewStore: { isTier1FromDE, isTier1FromIT, isTier1FromFR, isTier1FromDE_D365 },
    } = this.props;
    const yardManagerAGStatuses = [
      AdvisedGoodStatus.CLAIMED,
      AdvisedGoodStatus.UNLOADED,
      AdvisedGoodStatus.READY_FOR_TIER_2,
      AdvisedGoodStatus.READY_FOR_SIGN_OFF,
    ];

    return (
      isReadOnly &&
      !(
        yardManagerAGStatuses.includes(advisedGood.status) &&
        (isTier1FromDE || isTier1FromIT || isTier1FromFR || isTier1FromDE_D365)
      )
    );
  }

  private get _isRGWeightReadOnly(): boolean {
    const { isReadOnly } = this.props;
    return (
      isReadOnly ||
      this._isOnlySubAdvisedGoodWithTrueBalanceAndSecondWeight ||
      this._isITAndNoAGSecondWeight ||
      this._isFRAndNoAGSecondWeight
    );
  }

  private get _isOnlySubAdvisedGoodWithTrueBalanceAndSecondWeight(): boolean {
    const { advisedGood, index } = this.props;
    return (
      index === 0 &&
      this.props.advisedGood.balance &&
      (!!advisedGood.secondWeight || (advisedGood.hasOnlyOneDeliveryAGForUS && !!advisedGood.deliveryReceivedWeight))
    );
  }

  private get _isITAndNoAGSecondWeight(): boolean {
    const {
      viewStore: { isTier2FromIT, isTier3FromIT },
      advisedGood,
    } = this.props;
    return (isTier2FromIT || isTier3FromIT) && !advisedGood.secondWeight;
  }

  private get _isFRAndNoAGSecondWeight(): boolean {
    const {
      viewStore: { isTier2FromFR, isTier3FromFR },
      advisedGood,
    } = this.props;
    return (isTier2FromFR || isTier3FromFR) && typeof advisedGood.secondWeight !== 'number';
  }

  private get _isAllowNegativeGrossWeight(): boolean {
    const {
      viewStore: { isUS },
      sessionStore: { isTier1, isAdmin },
    } = this.props;
    return isUS && (isTier1 || isAdmin);
  }

  private _onBlurGrossWeightHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      return;
    }

    if (this._isAllowNegativeGrossWeight && Number(value) < 0) {
      this._toggleNegativeGrossWeightConfirmationModal();
    }
    this._updateGrossWeight(Number(value));
  };

  private _updateGrossWeight = (newValue: number) => {
    this.props.updateReceivedGoodGrossWeight(this.props.item, newValue);
  };

  private _onBlurTareWeightHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      return;
    }

    this._updateTareWeight(Number(value));
  };

  private _updateTareWeight = (newValue: number) => {
    this.props.item.setTareWeight(newValue);
  };

  private _toggleModal = (isModalOpen: boolean = false) => {
    this.setState({
      isModalOpen,
    });
  };

  private _saveSortingReport = () => {
    this._toggleModal();
  };

  private _isValidGrossWeight = (): boolean => {
    const { item, isNeedToValidateRGs } = this.props;
    return !isNeedToValidateRGs || this._isRGWeightReadOnly || item.validators.grossWeight;
  };

  private _isButtonInfoValid = (): boolean => {
    const {
      item,
      viewStore: { countryCode, isIT, isFR },
      advisedGood: { status },
      isReadOnly,
      isNeedToValidateRGs,
    } = this.props;
    const needValidate = Boolean(status === AdvisedGoodStatus.READY_FOR_SIGN_OFF && item.stockCode);
    if (!needValidate && (!isNeedToValidateRGs || !item.isFilledOut || isReadOnly)) {
      return true;
    }
    if ((isIT || isFR) && status === AdvisedGoodStatus.CLAIMED) {
      return item.validators.contamination; // on unload validate only contamination for IT
    }
    return item.isValid(countryCode);
  };

  private get _isStockItemDisabled() {
    const {
      advisedGood,
      viewStore: { isTier2FromIT, isTier2FromFR, isTier3FromFR },
    } = this.props;
    return (
      this._isStockItemReadOnly ||
      ((isTier2FromIT || isTier2FromFR) && advisedGood.status === AdvisedGoodStatus.CLAIMED) ||
      (isTier3FromFR && ![AdvisedGoodStatus.CLAIMED, AdvisedGoodStatus.UNLOADED].includes(advisedGood.status))
    );
  }

  private get _isInvalidStockItem() {
    const {
      item,
      viewStore: { stockCodeFormat, isDE_D365 },
      advisedGood: { status },
    } = this.props;

    return (
      (!!item.stockCode && !item.isValidStockCodeFormat(stockCodeFormat) && !this._isStockItemDisabled) ||
      (isDE_D365 && item.id && !item.validators.stockItemCode && status === AdvisedGoodStatus.UNLOADED)
    );
  }

  // TOGGLE MODAL CONFIRMATION FOR SAVE NEGATIVE GROSS WEIGHT - TIER 1
  private _toggleNegativeGrossWeightConfirmationModal = () => {
    this.setState({ negativeGrossWeightConfirmationModal: !this.state.negativeGrossWeightConfirmationModal });
  };

  private _cancelSaveNegativeGrossWeight = () => {
    this._updateGrossWeight(null);
    this._toggleNegativeGrossWeightConfirmationModal();
  };

  private get _negativeGrossWeightConfirmationBody() {
    const {
      translateService: { t },
    } = this.props;
    return (
      <div className={classnames(`${TEXT_DANGER} flex`)}>
        <FontAwesome name="exclamation-triangle" className={classnames('font-17 me-3')} />
        <span>{t.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT}</span>
      </div>
    );
  }

  private _flagReceivedGood = () => {
    const {
      translateService: { t },
      receivedGoodsStore: { postFlagReceivedGood },
      item,
    } = this.props;
    return postFlagReceivedGood(item.id, !item.flagged).then(() => {
      item.setFlagged(!item.flagged);
      item.setWasFlagged(true);
      if (item.flagged) {
        notification.error(t.RECEIVED_GOOD_FLAG_ERROR_MSG);
      } else {
        notification.success(t.RECEIVED_GOOD_FLAG_SUCCESS_MSG);
      }
    });
  };

  private get _hasFlag(): string {
    const {
      translateService: { t },
      item,
    } = this.props;

    return getTranslation(`GLOBAL_LABEL_${item.flagged ? 'UN_FLAG' : 'FLAG'}` as keyof I18N, t) as string;
  }

  // ON FLAG CLICK - SHOW FLAG CONFIRMATION WINDOW
  private _toggleFlagConfirmationModal = () => {
    this.setState({
      flagConfirmationModal: !this.state.flagConfirmationModal,
    });
  };

  private get _infoButtonColor() {
    const { item } = this.props;
    if (item.flagged) {
      return 'danger';
    }
    if (item.wasFlagged) {
      return 'secondary';
    }
    return item.hasItems ? 'success' : 'false';
  }

  private get _showFlagButton() {
    const {
      sessionStore: { isTier3 },
      viewStore: {
        isTier2FromDE,
        isTier2FromIT,
        isTier1FromDE,
        isTier1FromIT,
        isTier2FromFR,
        isTier1FromFR,
        isTier1FromDE_D365,
        isTier2FromDE_D365,
      },
      isReadOnly,
      item,
    } = this.props;
    return !(
      ((isTier3 || isTier2FromDE || isTier2FromIT || isTier2FromFR || isTier2FromDE_D365) && item.flagged) ||
      (isReadOnly && !(isTier1FromDE || isTier1FromIT || isTier1FromFR || isTier1FromDE_D365))
    );
  }
}

export default inject(
  'sessionStore',
  'translateService',
  'viewStore',
  'commonStore',
  'receivedGoodsStore'
)(observer(SortingReportLineComponent));
