import { action, observable, computed, makeObservable } from 'mobx';

export default class VersionStore {
  private _isNeedUpdateVersion: boolean = false;

  constructor() {
    makeObservable<VersionStore, '_isNeedUpdateVersion'>(this, {
      _isNeedUpdateVersion: observable,
      needUpdateVersion: computed,
      setIsNeedUpdateVersion: action,
    });
  }

  public get needUpdateVersion(): boolean {
    return this._isNeedUpdateVersion;
  }

  public setIsNeedUpdateVersion = (val: boolean) => {
    this._isNeedUpdateVersion = val;
  };
}
