import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { inject, observer } from 'mobx-react';

import AuthRoute from 'system/AuthRoute';

import Loading from 'domain/layout/Loading';

import AuthenticatedIndexRoute from 'pod/authenticated/AuthenticatedIndexRoute';
import UnauthenticatedIndexRoute from 'pod/unathenticated/UnauthenticatedIndexRoute';
import LayoutStore from 'stores/LayoutStore';
import AjaxService from 'services/AjaxService';
import { action, autorun, observable, makeObservable } from 'mobx';
import { locale } from 'moment';
import TranslateService from 'services/TranslateService';
import VersionStore from 'stores/VersionStore';
import ModalConfirmation from 'domain/ModalConfirmation';
import { reloadPage } from 'util/helpers';

interface IRoutesProps {
  ajaxService?: AjaxService;
  layoutStore?: LayoutStore;
  translateService?: TranslateService;
  versionStore?: VersionStore;
}

class Routes extends React.Component<IRoutesProps> {
  public constructor(props: IRoutesProps) {
    super(props);

    makeObservable<Routes, '_showVersionModal' | '_setShowVersionModal'>(this, {
      _showVersionModal: observable,
      _setShowVersionModal: action,
    });

    autorun(() => {
      locale(this.props.translateService.language);
    });

    autorun(() => {
      if (this.props.versionStore.needUpdateVersion) {
        this.props.versionStore.setIsNeedUpdateVersion(false);
        this._setShowVersionModal(true);
      }
    });
  }

  private _showVersionModal: boolean = false;

  public render() {
    const {
      translateService: { t },
    } = this.props;
    return (
      <div className="full-height position-relative">
        {(this.props.ajaxService.hasPendingRequests || this.props.layoutStore.isLoading) && <Loading />}
        <Switch>
          <Route path="/auth" component={UnauthenticatedIndexRoute} />
          <AuthRoute path="/" component={AuthenticatedIndexRoute} />
        </Switch>
        <ModalConfirmation
          data-test="reload-page-modal"
          buttonYesColor="success"
          callbackYes={reloadPage}
          buttonYes={t.GLOBAL_LABEL_REFRESH}
          buttonNo={t.GLOBAL_LABEL_CANCEL}
          heading={t.GLOBAL_LABEL_NEW_VERSION_RELEASED}
          isOpen={this._showVersionModal}
          text={t.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION}
          toggleModal={this._toggleShowVersionModal}
        />
      </div>
    );
  }

  private _toggleShowVersionModal = () => {
    this._setShowVersionModal(!this._showVersionModal);
  };

  private _setShowVersionModal = (val: boolean) => {
    this._showVersionModal = val;
  };
}

export default inject('layoutStore', 'ajaxService', 'translateService', 'versionStore')(observer(Routes));
