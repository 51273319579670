import * as React from 'react';
import { inject, observer } from 'mobx-react';

import AdminStore from 'pod/admin/AdminStore';
import ProductVariants from 'pod/admin/commonComponents/ProductVariants';
import TranslateService from 'services/TranslateService';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class FormListRoute extends React.Component<IProps> {
  public componentDidMount() {
    this.props.adminStore.getFormItems();
  }

  public render() {
    const {
      adminStore: { formListActiveFirst, getFormItems },
      translateService: { t },
    } = this.props;

    return <ProductVariants items={formListActiveFirst} title={t.GLOBAL_LABEL_FORM} onRefreshCallback={getFormItems} />;
  }
}

export default inject('adminStore', 'translateService')(observer(FormListRoute));
