import { action, observable, makeObservable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import { ICode } from 'models/ModelInterfaces';

export default class IdCodeNameModel extends IdNameModel implements ICode {
  code: string = '';

  public changeCode = (newCode: string) => {
    this.code = newCode;
  };

  constructor() {
    super();

    makeObservable(this, {
      code: observable,
      changeCode: action,
    });
  }
}
