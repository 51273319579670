type TNewInstance<T, K> = new (params: K) => T;

export interface ISetHasChanged {
  setHasChanged?: (value: boolean) => void;
}

export interface ISetHasChangedAndClearIsDirty extends ISetHasChanged {
  clearIsDirty?: () => void;
}

type TObjectStateMethods = ISetHasChanged & ISetHasChangedAndClearIsDirty;

export default class ObjectUpdater<T, K = undefined> {
  public update(
    instanceToUpdate: T & TObjectStateMethods,
    newProps: Partial<T>,
    constructor: TNewInstance<T, K>,
    constructorParams?: K
  ): T {
    // to update model to initial state pass null instead newProps
    if (!newProps) {
      newProps = new constructor(constructorParams);
    }

    Object.keys(newProps).forEach((prop) => {
      instanceToUpdate[prop as keyof T] = newProps[prop as keyof Partial<T>];
    });

    if (instanceToUpdate.setHasChanged) {
      instanceToUpdate.setHasChanged(false);
    }

    if (instanceToUpdate.clearIsDirty) {
      instanceToUpdate.clearIsDirty();
    }

    return instanceToUpdate;
  }
}
