import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { IActive } from 'models/ModelInterfaces';

export default class IdActiveModel extends UpdateModel<IdActiveModel> implements IActive {
  public id: string = '';

  public active: boolean = false;

  constructor() {
    super();

    makeObservable(this, {
      active: observable,
      update: action,
      changeActiveStatus: action,
    });
  }

  public update(obj: IdActiveModel) {
    this.updater.update(this, obj, IdActiveModel);
    return this;
  }

  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);
}
