import { action, observable, makeObservable } from 'mobx';
import IdNameModel from 'models/IdNameModel';

export default class UserRoleModel extends IdNameModel {
  public isDefault: boolean = false;

  constructor() {
    super();

    makeObservable(this, {
      isDefault: observable,
      changeIsDefault: action,
    });
  }

  public update(obj: UserRoleModel) {
    this.updater.update(this, obj, UserRoleModel);
    return this;
  }

  public changeIsDefault = (val: boolean) => (this.isDefault = val);
}
