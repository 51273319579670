import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

export default class UserConfigurationModel extends UpdateModel<UserConfigurationModel> {
  public id: string = null;
  public numberOfDecimals: number = 0;
  public stockCodeFormat: string = '';
  public weighbridgeTolerance: number = 0;
  public dateFormat: string = '';

  public update = (obj: UserConfigurationModel) => {
    this.updater.update(this, obj, UserConfigurationModel);
    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      numberOfDecimals: observable,
      stockCodeFormat: observable,
      weighbridgeTolerance: observable,
      dateFormat: observable,
      update: action,
      setStockCodeFormat: action,
      setWeighbridgeTolerance: action,
      setNumberOfDecimals: action,
      setDateFormat: action,
    });
  }

  public setStockCodeFormat(val: string) {
    this.stockCodeFormat = val;
  }

  public setWeighbridgeTolerance(val: string) {
    this.weighbridgeTolerance = parseInt(val, 10);
  }

  public setNumberOfDecimals(val: string) {
    this.numberOfDecimals = parseInt(val, 10);
  }

  public setDateFormat(val: string) {
    this.dateFormat = val;
  }
}
