import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdvisedGoodLabRoute from 'pod/advisedGoodLab/AdvisedGoodLabRoute';

export default class AdvisedGoodLabIndexRoute extends React.PureComponent {
  public render() {
    return (
      <Switch>
        <Route path="/advised-good-lab/:id/:deliveredGoodId?" component={AdvisedGoodLabRoute} />
      </Switch>
    );
  }
}
