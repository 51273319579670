import * as React from 'react';
import { toJS } from 'mobx';
import * as H from 'history';
import { CardDeck, Container, Row, Col } from 'reactstrap';
import DashboardCard from 'pod/dashboard/DashboardCard';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import DashboardStore from 'pod/dashboard/DashboardStore';
import { Bar, Doughnut } from 'react-chartjs-2';
import Loading from 'domain/layout/Loading';
import SessionStore from 'stores/SessionStore';
import DashboardFiltersStore from 'stores/DashboardFilterStore';
import TenantPickerComponent from 'components/TenantPickerComponent';
import BackButton from 'components/BackButton';

interface IDashboardRouteProps {
  translateService?: TranslateService;
  dashboardStore?: DashboardStore;
  sessionStore?: SessionStore;
  history?: H.History;
  dashboardFilterStore?: DashboardFiltersStore;
}

class DashboardRoute extends React.Component<IDashboardRouteProps> {
  public componentDidMount() {
    const { dashboardStore, dashboardFilterStore } = this.props;

    dashboardStore.getDashboardData(dashboardFilterStore.tenantFilterStore.tenantId);
  }

  public render() {
    const {
      dashboardStore: {
        formattedDeliveryByStatus,
        totalDeliveriesInProgress,
        formattedDeliveriesSortingTime,
        isLoading,
        formattedAverageSortingLeadTimePerMonth,
        totalFlaggedAdvisedGoods,
        doughnutLegendOptions,
        isDataExisted,
        lastDeliveriesLeadTimeOptions,
        averageSortingTimePerMonthOptions,
        deliveriesInProgressMoreThan3Months,
        deliveriesInProgressMoreThan1Month,
        deliveriesInProgressLessThan1Month,
        deliveriesInProgressLessThan1Week,
        advisedGoodsFlaggedMoreThan3Months,
        advisedGoodsFlaggedMoreThan1Month,
        advisedGoodsFlaggedLessThan1Month,
        advisedGoodsFlaggedLessThan1Week,
        formattedAverageTurnAroundTimePerMonth,
        averageSortingTurnaroundTimePerMonthOptions,
      },
      dashboardFilterStore: { tenantFilterStore },
      translateService: { t },
      sessionStore: { isTier1, isManager },
    } = this.props;

    return isLoading ? (
      <Loading />
    ) : (
      <React.Fragment>
        {(isTier1 || isManager) && (
          <BackButton onClick={this._goBack} data-test="back-btn">
            {t.GLOBAL_LABEL_DELIVERIES}
          </BackButton>
        )}
        <Container fluid>
          <Row>
            {isManager && (
              <Col xs="12">
                <div className="btn-container margin-bottom-0_5rem">
                  <TenantPickerComponent changeTenant={this.changeTenant} tenantFilterStore={tenantFilterStore} />
                </div>
              </Col>
            )}
            {isDataExisted && (
              <Col xs="12" className="ps-0 pe-0">
                <CardDeck className="dashboard">
                  <DashboardCard title={t.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS}>
                    <div className="dashboard-card-total">
                      <div className="dashboard-card-total-row">
                        <div>{`${
                          t.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS
                        }, ${t.GLOBAL_LABEL_TOTAL?.toLowerCase()}`}</div>
                        <div className="total-number">{totalDeliveriesInProgress}</div>
                      </div>
                      <div className="dashboard-card-total-row">
                        <div>{t.DASHBOARD_TITLES_IN_PROGRESS_MONTHS('>3')}</div>
                        <div>{deliveriesInProgressMoreThan3Months}</div>
                      </div>
                      <div className="dashboard-card-total-row">
                        <div>{t.DASHBOARD_TITLES_IN_PROGRESS_MONTH('>1')}</div>
                        <div>{deliveriesInProgressMoreThan1Month}</div>
                      </div>
                      <div className="dashboard-card-total-row">
                        <div>{t.DASHBOARD_TITLES_IN_PROGRESS_MONTH('<1')}</div>
                        <div>{deliveriesInProgressLessThan1Month}</div>
                      </div>
                      <div className="dashboard-card-total-row">
                        <div>{t.DASHBOARD_TITLES_IN_PROGRESS_WEEK('<1')}</div>
                        <div>{deliveriesInProgressLessThan1Week}</div>
                      </div>
                    </div>
                  </DashboardCard>

                  <DashboardCard title={t.DASHBOARD_TITLES_DELIVERY_BY_STATUS}>
                    <div>
                      <Doughnut
                        data={toJS(formattedDeliveryByStatus)}
                        legend={doughnutLegendOptions}
                        options={{
                          cutoutPercentage: 70,
                        }}
                      />
                    </div>
                  </DashboardCard>

                  <DashboardCard title={t.DASHBOARD_TITLES_FLAGGED_ITEMS}>
                    <div className="dashboard-card-total">
                      <div className="dashboard-card-total-row">
                        <div>{`${t.DASHBOARD_TITLES_FLAGGED_ITEMS}, ${t.GLOBAL_LABEL_TOTAL?.toLowerCase()}`}</div>
                        <div className="total-number color-red">{totalFlaggedAdvisedGoods}</div>
                      </div>
                      <div className="dashboard-card-total-row">
                        <div>{t.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS('>3')}</div>
                        <div>{advisedGoodsFlaggedMoreThan3Months}</div>
                      </div>
                      <div className="dashboard-card-total-row">
                        <div>{t.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH('>1')}</div>
                        <div>{advisedGoodsFlaggedMoreThan1Month}</div>
                      </div>
                      <div className="dashboard-card-total-row">
                        <div>{t.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH('<1')}</div>
                        <div>{advisedGoodsFlaggedLessThan1Month}</div>
                      </div>
                      <div className="dashboard-card-total-row">
                        <div>{t.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK('<1')}</div>
                        <div>{advisedGoodsFlaggedLessThan1Week}</div>
                      </div>
                    </div>
                  </DashboardCard>

                  <DashboardCard title={t.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME}>
                    <Bar
                      data={toJS(formattedAverageTurnAroundTimePerMonth)}
                      legend={doughnutLegendOptions}
                      options={averageSortingTurnaroundTimePerMonthOptions}
                    />
                  </DashboardCard>

                  <DashboardCard title={t.DASHBOARD_TITLES_AVERAGE_SORTING_TIME}>
                    <Bar
                      data={toJS(formattedAverageSortingLeadTimePerMonth)}
                      legend={doughnutLegendOptions}
                      options={averageSortingTimePerMonthOptions}
                    />
                  </DashboardCard>

                  <DashboardCard title={t.DASHBOARD_TITLES_SORTING_TIME_TREND}>
                    <Bar
                      data={toJS(formattedDeliveriesSortingTime)}
                      legend={doughnutLegendOptions}
                      options={lastDeliveriesLeadTimeOptions}
                    />
                  </DashboardCard>
                </CardDeck>
              </Col>
            )}
          </Row>
        </Container>
      </React.Fragment>
    );
  }

  public changeTenant = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      dashboardFilterStore: { tenantFilterStore },
    } = this.props;

    tenantFilterStore.changeTenant(e.target.value);
  };

  private _goBack = () => {
    this.props.history.goBack();
  };
}

export default inject(
  'translateService',
  'dashboardStore',
  'sessionStore',
  'dashboardFilterStore'
)(observer(DashboardRoute));
