import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import IdNameActiveModel from 'models/IdNameActiveModel';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class YardLocationDetailRoute extends React.Component<IProps> {
  public content: IdNameActiveModel = null;

  public updateContent = (newYardLocation: IdNameActiveModel) => {
    this.content = new IdNameActiveModel().update(newYardLocation);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      updateContent: action,
    });
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { yardLocationList, yardLocationApi },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.YARD_LOCATION}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_YARD_LOCATION}
        itemsList={yardLocationList}
        api={yardLocationApi}
        updateItem={this.updateContent}
      />
    );
  }
}

export default inject('adminStore', 'translateService')(observer(YardLocationDetailRoute));
