import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import ReceivedGoodsLabModel from 'models/ReceivedGoodsLabModel';
import TenantModel from 'models/TenantModel';
import { cloneObj } from 'util/helpers';
import MaterialModel from 'models/MaterialModel';

export default class AdvisedGoodLabModel extends UpdateModel<AdvisedGoodLabModel> {
  public advisedDescription: string = '';
  public flagged: boolean = false;
  public grn: string = '';
  public agTitle: string = '';
  public id: string = '';
  public receivedGoods: ReceivedGoodsLabModel[] = [];
  public supplier: string = '';
  public tenant: TenantModel = null;

  public update = (
    obj: AdvisedGoodLabModel,
    defaultMaterials: MaterialModel[],
    nonDefaultMaterials: MaterialModel[]
  ) => {
    const newAdvisedGoodLabModel = cloneObj(obj);
    if (newAdvisedGoodLabModel) {
      newAdvisedGoodLabModel.receivedGoods = newAdvisedGoodLabModel.receivedGoods.map((rg: ReceivedGoodsLabModel) =>
        new ReceivedGoodsLabModel().update(rg, defaultMaterials, nonDefaultMaterials)
      );
    }

    this.updater.update(this, newAdvisedGoodLabModel, AdvisedGoodLabModel);
    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      advisedDescription: observable,
      flagged: observable,
      grn: observable,
      agTitle: observable,
      id: observable,
      receivedGoods: observable,
      supplier: observable,
      tenant: observable,
      update: action,
    });
  }
}
