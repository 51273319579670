import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';
import { action, observable, makeObservable } from 'mobx';

import AdminStore from 'pod/admin/AdminStore';
import SupplierModel from 'models/SupplierModel';
import TranslateService from 'services/TranslateService';
import NavlinkCustom from 'domain/NavlinkCustom';
import ViewStore from 'stores/ViewStore';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import SupplierDetailRoute from 'pod/admin/suppliers/SupplierDetailRoute';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import SupplierDetailComponent from 'pod/admin/suppliers/SupplierDetailComponent';
import DateFormattingService from 'services/DateFormattingService';
import { getTranslation } from 'util/helpers';
import { I18N } from '../../../../assets/i18n/i18n';
import { Button } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import RefreshConfirmation from 'pod/admin/commonComponents/RefreshConfirmation';
import { instance as notification } from 'util/notification';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  dateFormattingService?: DateFormattingService;
}

class SupplierListRoute extends React.Component<IProps> {
  public content: SupplierModel = new SupplierModel();
  public showConfirmation: boolean = false;

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      showConfirmation: observable,
      clearModel: action,
      toggleConfirmation: action,
    });
  }

  public componentDidMount(): void {
    const {
      adminStore: { getSupplierTypes },
    } = this.props;
    getSupplierTypes();
  }

  public clearModel = () => {
    this.content = new SupplierModel();
  };

  public toggleConfirmation = () => {
    this.showConfirmation = !this.showConfirmation;
  };

  public get supplierList() {
    const {
      translateService: { t },
      viewStore: { isIT },
      adminStore: { supplierListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="ps-3">{t.GLOBAL_LABEL_NAME}</th>
            {isIT && (
              <React.Fragment>
                <th data-test="supplier-type-label">{t.GLOBAL_LABEL_TYPE}</th>
                <th data-test="revision-request-label">{t.GLOBAL_LABEL_REVISION_REQUEST}</th>
                <th data-test="cert-aut-label">{t.GLOBAL_LABEL_CERT_AUT_RIF_DATE}</th>
                <th data-test="cert-71513-label">{t.GLOBAL_LABEL_CERT_715_13}</th>
                <th data-test="cert-exp-label">{t.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE}</th>
              </React.Fragment>
            )}
            <th className="pe-3">{t.GLOBAL_LABEL_CODE}</th>
          </tr>
        </thead>
        {supplierListActiveFirst.map((supplier) => this._renderItem(supplier))}
      </table>
    );
  }

  public get supplierForm() {
    return <SupplierDetailComponent content={this.content} />;
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { supplierApi },
      viewStore: { isDE_D365 },
    } = this.props;

    return (
      <React.Fragment>
        <CommonAdminListRoute
          api={supplierApi}
          rootPath={AdminRootPath.SUPPLIERS}
          itemsTable={this.supplierList}
          itemCreateForm={this.supplierForm}
          componentRoute={SupplierDetailRoute}
          clearModel={this.clearModel}
          content={this.content}
          sectionNameTranslation={t.ADMIN_SECTIONENTITY_SUPPLIER}
          exampleFileNames={ExampleFileNames.SUPPLIERS}
          header={t.MENU_ADMIN_SUPPLIERS}
          hasUploadBtn={!isDE_D365}
          hasCreateBtn={!isDE_D365}
          buttons={
            isDE_D365 ? (
              <Button
                data-test="trigger-button"
                className="float-end"
                color="success"
                onClick={this.toggleConfirmation}
              >
                <FontAwesome name="refresh" className="me-2" />
                {t.GLOBAL_LABEL_REFRESH_COMMON(t.MENU_ADMIN_SUPPLIERS)}
              </Button>
            ) : null
          }
        />
        {isDE_D365 && (
          <RefreshConfirmation
            callbackYes={this._onRefresh}
            itemName={t.MENU_ADMIN_SUPPLIERS}
            isOpen={this.showConfirmation}
            toggleConfirmation={this.toggleConfirmation}
          />
        )}
      </React.Fragment>
    );
  }

  private _renderItemBody = (supplier: SupplierModel) => {
    const {
      viewStore: { isIT },
      dateFormattingService: { formatDate },
      translateService: { t },
    } = this.props;
    return (
      <React.Fragment>
        <td className="ps-3">{supplier.name}</td>
        {isIT && (
          <React.Fragment>
            <td data-test="supplier-type-data">
              {!!supplier.type
                ? getTranslation(`GLOBAL_LABEL_SUPPLIER_TYPE_${supplier.type.toUpperCase()}` as keyof I18N, t)
                : ''}
            </td>
            <td data-test="revision-request-data">{formatDate(supplier.formattedRevisionRequest)}</td>
            <td data-test="cert-aut-data">{formatDate(supplier.formattedCertAutRifDate)}</td>
            <td data-test="cert-71513-data">{formatDate(supplier.formattedCert71513Date)}</td>
            <td data-test="cert-exp-data">{formatDate(supplier.formattedExpirationDate)}</td>
          </React.Fragment>
        )}
        <td className="pe-3">{supplier.code}</td>
      </React.Fragment>
    );
  };

  private _renderItem = (supplier: SupplierModel) => {
    const {
      viewStore: { isDE_D365 },
    } = this.props;

    return (
      <tbody key={supplier.id} data-test="suppliers">
        {isDE_D365 ? (
          <tr key={supplier.id} className={classnames([{ 'bg-secondary': !supplier.active }])}>
            {this._renderItemBody(supplier)}
          </tr>
        ) : (
          <NavlinkCustom
            to={`/admin/suppliers/${supplier.id}`}
            tagName="tr"
            className={classnames(['pointer', { 'bg-secondary': !supplier.active }])}
            key={supplier.id}
          >
            {this._renderItemBody(supplier)}
          </NavlinkCustom>
        )}
      </tbody>
    );
  };

  private _onRefresh = async () => {
    const {
      translateService: { t },
      adminStore: { supplierApi },
    } = this.props;
    try {
      await this.props.adminStore.refreshSuppliers();
      notification.success(t.ADMIN_REFRESH_SUCCESS_MESSAGE(t.MENU_ADMIN_SUPPLIERS));
    } catch (e) {
      notification.success(t.ADMIN_REFRESH_IN_PROGRESS_MESSAGE(t.MENU_ADMIN_SUPPLIERS));
    } finally {
      supplierApi.getAllItems();
    }
  };
}

export default inject(
  'adminStore',
  'viewStore',
  'translateService',
  'dateFormattingService'
)(observer(SupplierListRoute));
