import { action, observable, makeObservable } from 'mobx';

import IdNameActiveModel from 'models/IdNameActiveModel';
import { IConstructSaveObj } from 'models/ModelInterfaces';

export default class ScaleModel extends IdNameActiveModel implements IConstructSaveObj<ScaleModel> {
  public port: string = '';
  public ip: string = '';

  constructor() {
    super();

    makeObservable(this, {
      port: observable,
      ip: observable,
      changePort: action,
      changeIp: action,
    });
  }

  public get constructSaveObj(): ScaleModel {
    return {
      name: this.name,
      port: this.port,
      ip: this.ip,
      active: this.active,
    } as ScaleModel;
  }

  public update = (obj: ScaleModel) => {
    this.updater.update(this, super.update(obj), ScaleModel);
    return this;
  };

  public changePort = (newPort: string) => (this.port = newPort);

  public changeIp = (newIp: string) => (this.ip = newIp);
}
