import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';
import ModalConfirmation from 'domain/ModalConfirmation';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import { Button } from 'reactstrap';
import TenantModel from 'models/TenantModel';
import UserModel from 'models/UserModel';
import TranslateService from 'services/TranslateService';
import * as H from 'history';
import UserTenantModel from 'models/UserTenantModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';
import TenantDetail from 'pod/superAdmin/tenants/TenantDetail';

interface IQuery {
  id: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  history: H.History;
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}

interface IState {
  content: TenantModel;
  showDeleteConfirmationModal: boolean;
  showHideConfirmationModal: boolean;
  showUnhideConfirmationModal: boolean;
  workflowTenants: UserTenantModel[];
}

class TenantDetailRoute extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      content: null,
      showDeleteConfirmationModal: false,
      showHideConfirmationModal: false,
      showUnhideConfirmationModal: false,
      workflowTenants: [],
    };
  }

  public componentDidMount() {
    const {
      superAdminStore,
      match: {
        params: { id: tenantId },
      },
    } = this.props;

    superAdminStore.getWorkflows();

    this._setContent(tenantId);
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      match: {
        params: { id: newTenantId },
      },
    } = this.props;
    const {
      match: {
        params: { id: tenantId },
      },
    } = prevProps;

    if (tenantId !== newTenantId) {
      this._setContent(newTenantId);
    }
  }

  public saveAdmin = (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.putAdmin(user).then(() => {
      notification.success(this.props.translateService.t.SUPERADMIN_TENANTADMIN_UPDATE_MSG);
    });
  };

  public postAdmin = (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.postAdmin(user).then((res) => {
      notification.success(this.props.translateService.t.SUPERADMIN_TENANTADMIN_ADD_MSG);
      this.state.content.pushAdmin(res);
    });
  };

  public hideTenant = () => {
    this.props.superAdminStore.hideTenantById(this.state.content?.id)
      .then(() => {
        notification.success(this.props.translateService.t.SUPERADMIN_TENANTDETAIL_HIDE_SUCCESS_MESSAGE);
        this.props.history.push('/super-admin/tenants');
      })
      .catch(error => {
        notification.error(error)
      });
  };

  public unhideTenant = () => {
    this.props.superAdminStore.unhideTenantById(this.state.content?.id)
      .then(() => {
        notification.success(this.props.translateService.t.SUPERADMIN_TENANTDETAIL_UNHIDE_SUCCESS_MESSAGE);
        this.props.history.push('/super-admin/tenants');
      })
      .catch(error => notification.error(error));
  };

  public hideAdmin = (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.hideAdminById(this.state.content.admins, user.id)
      .then(() => {
        notification.success(this.props.translateService.t.ADMIN_COMMONDETAIL_HIDE_MSG(user.fullName));
      })
  };

  public unhideAdmin = (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.unhideAdminById(this.state.content.admins, user.id)
      .then(() => {
        notification.success(this.props.translateService.t.ADMIN_COMMONDETAIL_UNHIDE_MSG(user.fullName));
      })
  };

  public render() {
    const { content, showDeleteConfirmationModal, showHideConfirmationModal, showUnhideConfirmationModal } = this.state;
    const {
      superAdminStore: { workflowList },
      translateService: { t },
      history
    } = this.props;

    if (!content) {
      return <div />;
    }

    return (
      <div className="position-relative">
        <TenantDetail
          history={history}
          tenant={content}
        />

        <span className="tenant-hide-nav">
          { this._hasHideButton && <Button
            data-test="hide-btn"
            color="danger"
            onClick={this._toggleHideConfirmationModal}
          >
            {t.GLOBAL_LABEL_HIDE}
          </Button>
          }
          { this._hasUnhideButton && <Button
            data-test="unhide-btn"
            color="primary"
            onClick={this._toggleUnhideConfirmationModal}
          >
            {t.GLOBAL_LABEL_UNHIDE}
          </Button>
          }
        </span>

        <Button
          data-test="delete-btn"
          color="danger"
          className="tenant-delete-btn"
          onClick={this._toggleDeleteConfirmationModal}
        >
          {t.GLOBAL_LABEL_DELETE}
        </Button>

        {content && showDeleteConfirmationModal && (
          <ModalConfirmation
            isOpen={showDeleteConfirmationModal}
            toggleModal={this._toggleDeleteConfirmationModal}
            heading={t.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER}
            text={t.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT(content.name)}
            callbackYes={this._deleteTenant}
          />
        )}

        {content && showHideConfirmationModal && (
          <ModalConfirmation
            isOpen={showHideConfirmationModal}
            toggleModal={this._toggleHideConfirmationModal}
            heading={t.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_HEADER}
            text={t.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_TEXT(content.name)}
            callbackYes={this.hideTenant}
          />
        )}

        {content && showUnhideConfirmationModal && (
          <ModalConfirmation
            isOpen={showUnhideConfirmationModal}
            toggleModal={this._toggleUnhideConfirmationModal}
            heading={t.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_HEADER}
            text={t.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_TEXT(content.name)}
            callbackYes={this.unhideTenant}
          />
        )}
      </div>
    );
  }

  private get _hasHideButton() {
    return this.state.content?.active;
  }

  private get _hasUnhideButton() {
    return this.state.content && !this.state.content.active;
  }

  private _setContent = async (tenantId: string) => {
    const { superAdminStore } = this.props;
    const content = await superAdminStore.getTenantById(tenantId);
    const workflowTenants = await superAdminStore.getTenantsForTheSameWorkflow(content);
    this.setState({ content, workflowTenants });
  };

  private _toggleDeleteConfirmationModal = () => {
    this.setState({
      showDeleteConfirmationModal: !this.state.showDeleteConfirmationModal,
    });
  };

  private _toggleHideConfirmationModal = () => {
    this.setState({
      showHideConfirmationModal: !this.state.showHideConfirmationModal,
    });
  };

  private _toggleUnhideConfirmationModal = () => {
    this.setState({
      showUnhideConfirmationModal: !this.state.showUnhideConfirmationModal,
    });
  };

  private _deleteTenant = () => {
    const {
      superAdminStore,
      translateService: { t },
    } = this.props;
    const { content } = this.state;
    superAdminStore.deleteTenantById(content.id).then(() => {
      notification.success(t.SUPERADMIN_TENANTDETAIL_DELETE_MSG);
      this.props.history.push('/super-admin/tenants');
    });
  };
}

export default inject('superAdminStore', 'translateService')(observer(TenantDetailRoute));
