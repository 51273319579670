import { action, computed, observable, makeObservable } from 'mobx';

import IdNameActiveModel from 'models/IdNameActiveModel';
import { IConstructSaveObj } from 'models/ModelInterfaces';

export default class LasernetPrinterModel extends IdNameActiveModel implements IConstructSaveObj<LasernetPrinterModel> {
  public address: string = '';
  public description?: string = '';

  constructor() {
    super();

    makeObservable(this, {
      address: observable,
      description: observable,
      changeDescription: action,
      changeAddress: action,
    });
  }

  public get constructSaveObj(): LasernetPrinterModel {
    return {
      name: this.name,
      description: this.description,
      address: this.address,
      active: this.active,
    } as LasernetPrinterModel;
  }

  public update = (obj: LasernetPrinterModel) => {
    this.updater.update(this, super.update(obj), LasernetPrinterModel);
    return this;
  };

  public changeDescription = (newDescription: string) => (this.description = newDescription);
  public changeAddress = (newAddress: string) => (this.address = newAddress);
}
