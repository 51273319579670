import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Col, Row } from 'reactstrap';

import LabAnalysisModel from 'models/LabAnalysisModel';
import LabStatusBoxComponent from 'pod/advisedGoodLab/LabStatusBoxComponent';
import LabStatusModel from 'models/LabStatusModel';
import TranslateService from 'services/TranslateService';
import {
  getTranslation,
  processDecimals,
  transformEveryFirstLetterToUpperCase,
  transformKeyToText,
} from 'util/helpers';
import { I18N } from '../../../assets/i18n/i18n';
import ViewStore from 'stores/ViewStore';
import * as FontAwesome from 'react-fontawesome';
import AdvisedGoodLabStore from 'pod/advisedGoodLab/AdvisedGoodLabStore';
import ModalConfirmation from 'domain/ModalConfirmation';
import { action, observable, makeObservable } from 'mobx';
import MaterialsComponent from 'components/MaterialsComponent';
import CommonStore from 'stores/CommonStore';
import DateFormattingService from 'services/DateFormattingService';
import { NUMBER_OF_DECIMALS_MEASUREMENT } from 'util/constants';
import PicturesComponent from 'components/PicturesComponent';

interface IProps {
  labInput: LabAnalysisModel;
  receivedGoodId: string;
  labStatuses: LabStatusModel[];
  deleteLabInput: (labInputId: string) => void;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  advisedGoodLabStore?: AdvisedGoodLabStore;
  commonStore?: CommonStore;
  dateFormattingService?: DateFormattingService;
}

class AdvisedGoodLabAnalysisComponent extends React.Component<IProps> {
  private _isDeleteLabAnalysisOpen: boolean = false;

  constructor(props: IProps) {
    super(props);

    makeObservable<AdvisedGoodLabAnalysisComponent, '_isDeleteLabAnalysisOpen' | '_toggleDeleteLabAnalysis'>(this, {
      _isDeleteLabAnalysisOpen: observable,
      _toggleDeleteLabAnalysis: action,
    });
  }

  public render() {
    const {
      labInput,
      translateService: { t },
    } = this.props;

    return (
      <div>
        {!this._isAnyFromDE_IT_FR && (
          <h5 className="mt-4 clearfix">
            {t.GLOBAL_LABEL_STATUS}
            <div className="float-end">
              {getTranslation(`STATUS_LABINPUT_${labInput.labInputType.name.toUpperCase()}` as keyof I18N, t)}
            </div>
          </h5>
        )}
        <Row className="bg-white border-top border-bottom">
          <Col xs={12}>{this._renderLabAnalyses}</Col>
        </Row>

        {labInput.ingotWeight && <h5 className="mt-4">{t.LABORATORY_ANALISYS_MEASUREMENTS}</h5>}
        {labInput.ingotWeight && this._renderMeasurement}

        {this._renderPicture}

        {this._renderSpectroAnalysis}

        {labInput.remarks && <h5 className="mt-4">{t.GLOBAL_LABEL_REMARKS}</h5>}
        {labInput.remarks && (
          <Row className="bg-white border-top border-bottom pt-3 pb-3">
            <Col xs={12}>
              <p className="font-16">{labInput.remarks}</p>
            </Col>
          </Row>
        )}

        <ModalConfirmation
          isOpen={this._isDeleteLabAnalysisOpen}
          heading={t.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER}
          toggleModal={this._toggleDeleteLabAnalysis}
          callbackYes={this._deleteLabAnalyses}
          text={t.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT(
            transformEveryFirstLetterToUpperCase(transformKeyToText(labInput.labInputType.name))
          )}
        />
      </div>
    );
  }

  private _toggleDeleteLabAnalysis = () => (this._isDeleteLabAnalysisOpen = !this._isDeleteLabAnalysisOpen);

  private get _renderLabAnalyses() {
    const {
      viewStore: { isIT, isFR, timezone },
      labStatuses,
      labInput,
      translateService: { t },
      dateFormattingService: { formatDateTime },
    } = this.props;
    return this._isAnyFromDE_IT_FR ? (
      <div data-test="lab-input-germany" className="pt-2 pb-2 flex-nowrap-space-between">
        <div className="pt-2 pb-2">
          <span>{getTranslation(`STATUS_LABINPUT_${labInput.labInputType.name.toUpperCase()}` as keyof I18N, t)}</span>
          {(isIT || isFR) && (
            <span data-test="lab-input-code" className="margin-left-1rem">
              {labInput.code}
            </span>
          )}
          <strong className="margin-left-1rem">{labInput.tracking[0].createdBy.fullName}</strong>
          <span className="margin-left-1rem">{formatDateTime(labInput.tracking[0].createdAt, timezone)}</span>
        </div>
        {this._hasDeleteLabAnalysisAbility && (
          <Button
            data-test="delete-btn"
            color="false"
            size="sm"
            className="border-none"
            onClick={this._toggleDeleteLabAnalysis}
          >
            <FontAwesome name="close" />
          </Button>
        )}
      </div>
    ) : (
      <div data-test="lab-input" className="lab-status-component clearfix">
        {labStatuses &&
          labStatuses.map((labStatus) => (
            <LabStatusBoxComponent key={labStatus.id} labInputTracking={labInput.tracking} labStatus={labStatus} />
          ))}
      </div>
    );
  }

  private get _hasDeleteLabAnalysisAbility(): boolean {
    const {
      viewStore: { isTier2FromDE, isTier2FromDE_D365 },
    } = this.props;
    return isTier2FromDE || isTier2FromDE_D365;
  }

  private _deleteLabAnalyses = () => {
    const {
      labInput: { id },
      advisedGoodLabStore,
      deleteLabInput,
    } = this.props;
    return advisedGoodLabStore.deleteLabAnalysis(id).then(() => {
      deleteLabInput(id);
    });
  };

  private get _renderSpectroAnalysis() {
    const {
      labInput,
      viewStore: { isUS },
      commonStore: { nonDefaultMaterial },
      translateService: { t },
    } = this.props;
    const show = isUS && labInput.spectroAnalysis.hasItems;
    return show ? (
      <MaterialsComponent
        headerTitle={t.LABORATORY_ANALISYS_SPECTRO_ANALYSIS}
        isReadOnly={true} // always read only
        materials={labInput.spectroAnalysis.items}
        nonDefaultMaterial={nonDefaultMaterial}
        data-test={'lab-spectro-analysis'}
      />
    ) : null;
  }

  private get _renderMeasurement() {
    const {
      viewStore: { isUS },
      labInput,
      translateService: { t },
    } = this.props;
    return (
      <Row className="bg-white border-top border-bottom pt-3 pb-3">
        <Col xs={12}>
          <table data-test="lab-measurements" className="full-width">
            <tbody>
              {isUS ? (
                <React.Fragment>
                  <tr>
                    <td className="width-100 font-light">{t.SORTINGREPORT_MEASUREMENT_SAMPLE_WET}:</td>
                    <td data-test="wet-weight-us" className="font-16">
                      {labInput.wetWeight}
                    </td>
                    <td className="text-end pe-3 font-light">{t.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY}:</td>
                    <td data-test="dry-weight-us" className="font-16">
                      {labInput.dryWeight}
                    </td>
                    <td className="text-end pe-3 font-light">{t.SORTINGREPORT_MEASUREMENT_HUMIDITY}:</td>
                    <td data-test="humidity-us" className="width-120 font-16">
                      {processDecimals(labInput.humidity, NUMBER_OF_DECIMALS_MEASUREMENT)}
                    </td>
                  </tr>
                  <tr>
                    <td className="width-100 font-light">{t.SORTINGREPORT_MEASUREMENT_FOR_MELTING}:</td>
                    <td data-test="melting-weight-us" className="font-16">
                      {labInput.forMeltingWeight}
                    </td>
                    <td className="text-end pe-3 font-light">{t.SORTINGREPORT_MEASUREMENT_INGOT}:</td>
                    <td data-test="ingot-weight-us" className="font-16">
                      {labInput.ingotWeight}
                    </td>
                    <td className="text-end pe-3 font-light">{t.SORTINGREPORT_MEASUREMENT_YIELD}:</td>
                    <td data-test="yield-us" className="font-16">
                      {processDecimals(labInput.yieldUS, NUMBER_OF_DECIMALS_MEASUREMENT)}
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <tr>
                    <td className="width-100 font-light">{t.LABORATORY_ANALISYS_WET_WEIGHT}:</td>
                    <td data-test="wet-weight" className="font-16">
                      {labInput.wetWeight}
                    </td>
                    <td className="text-end pe-3 font-light">{t.LABORATORY_ANALISYS_DRY_WEIGHT}:</td>
                    <td data-test="dry-weight" className="width-120 font-16">
                      {labInput.dryWeight}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-light">{t.LABORATORY_ANALISYS_INGOTE_WEIGHT}:</td>
                    <td data-test="ingot-weight" className="font-16">
                      {labInput.ingotWeight}
                    </td>
                    <td className="text-end pe-3 font-light">{t.LABORATORY_ANALISYS_YIELD}:</td>
                    <td data-test="yield" className="font-16">
                      {labInput.yield}
                    </td>
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  }

  private get _renderPicture() {
    const {
      labInput,
      advisedGoodLabStore,
      translateService: { t },
    } = this.props;
    return labInput.attachments.length ? (
      <PicturesComponent
        data-test="lab-pictures"
        isReadOnly // only lab can add new files
        content={labInput}
        uploadAttachment={advisedGoodLabStore.uploadAttachment}
        uploadAttachments={advisedGoodLabStore.uploadAttachments}
        downloadAttachments={advisedGoodLabStore.downloadAttachments}
        title={t.GLOBAL_LABEL_ATTACHMENTS}
      />
    ) : null;
  }

  private get _isAnyFromDE_IT_FR() {
    const {
      viewStore: { isDE, isIT, isFR, isDE_D365 },
    } = this.props;
    return isDE || isDE_D365 || isIT || isFR;
  }
}

export default inject(
  'translateService',
  'viewStore',
  'advisedGoodLabStore',
  'commonStore',
  'dateFormattingService'
)(observer(AdvisedGoodLabAnalysisComponent));
