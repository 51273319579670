import * as React from 'react';
import { Card, CardTitle } from 'reactstrap';

interface IDashboardCardProps {
  title: string;
}

const DashboardCard: React.FunctionComponent<IDashboardCardProps> = ({ title, children }) => (
  <Card body className="dashboard-card">
    <CardTitle className="font-light">{title}</CardTitle>
    <div className="dashboard-card-children">{children}</div>
  </Card>
);

export default DashboardCard;
