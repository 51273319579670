import * as React from 'react';
import * as classnames from 'classnames';
import { observer } from 'mobx-react';

interface IAdvisedGoodWeightDetailsCellComponentProps {
  title: string;
  dataTest: string;
  children: string | React.ReactNode;
  condition?: boolean;
  customClass?: string;
}

const AdvisedGoodWeightDetailsCellComponent: React.FunctionComponent<IAdvisedGoodWeightDetailsCellComponentProps> = ({
  title,
  dataTest,
  customClass,
  children,
  condition = true,
}) => {
  return condition ? (
    <tr>
      <td className="width-130 font-light">{title}</td>
      <td
        className={classnames([
          'font-16 text-right-for-desktop-left-for-mobile font-medium',
          { [customClass]: customClass },
        ])}
        data-test={dataTest}
      >
        {children}
      </td>
    </tr>
  ) : null;
};

export default observer(AdvisedGoodWeightDetailsCellComponent);
