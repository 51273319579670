import { AxiosError } from 'axios';
import bugsnag from '@bugsnag/js';

import env from 'env';

interface IBugsnagReport {
  originalError?: AxiosError;
}

const bugsnagClient = bugsnag({
  apiKey: env.bugsnagKey,
  appVersion: env.version,
  releaseStage: env.isProduction ? 'production' : 'development',
  beforeSend: (report: IBugsnagReport): boolean | undefined => {
    if (
      report.originalError &&
      report.originalError.response &&
      report.originalError.response.status &&
      [400, 403, 422].includes(report.originalError.response.status)
    ) {
      return false;
    }
  },
});

export default bugsnagClient;
