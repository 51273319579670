class UrlResolver {
  private getHostname = () => {
    return (window.location.hostname || window.location.host).toLowerCase();
  };

  public getApiUrl = () => {
    if (typeof __API_URL__ !== 'undefined') {
      return __API_URL__;
    }

    return `https://${this.getHostname()}`;
  };

  public getWsUrl = () => {
    if (typeof __SOCKET_URL__ !== 'undefined') {
      return __SOCKET_URL__;
    }

    return `wss://${this.getHostname()}`;
  };
}

const urlResolver = new UrlResolver();

const conf = {
  apiUrl: urlResolver.getApiUrl(),
  socketUrl: urlResolver.getWsUrl(),
  isProduction: typeof PRODUCTION !== 'undefined' ? PRODUCTION : false,
  version: typeof __VERSION__ !== 'undefined' ? __VERSION__ : 'unknown',
  bugsnagKey: typeof __BUGSNAG_KEY__ !== 'undefined' ? __BUGSNAG_KEY__ : '_invalid_bugsnag_key_',
};

export default conf;
