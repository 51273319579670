import * as React from 'react';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import TransferModel, { ITransferData } from 'models/TransferModel';
import ViewStore from 'stores/ViewStore';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import { AdvisedGoodStatus, DepartmentCode } from 'util/enums';
import { Button, Input, Row } from 'reactstrap';
import InputNumber from 'components/InputNumber';
import { inputNumberStep } from 'util/helpers';
import * as FontAwesome from 'react-fontawesome';
import TranslateService from 'services/TranslateService';
import CommonStore from 'stores/CommonStore';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import { instance as notification } from 'util/notification';
import { logger } from 'util/logger';

interface ITransferComponentProps {
  item: ReceivedGoodsModel;
  advisedGood: AdvisedGoodsModel;
  transferDeliveredGood: (deliveredGood: ReceivedGoodsModel, transfer: ITransferData) => Promise<void>;
  isReadOnly: boolean;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  commonStore?: CommonStore;
}

class TransferComponent extends React.Component<ITransferComponentProps> {
  public transfer: TransferModel = this._createTransfer();

  private _isTransferCollapsable: boolean = false;

  constructor(props: ITransferComponentProps) {
    super(props);

    makeObservable<
      TransferComponent,
      | '_isTransferCollapsable'
      | '_toggleTransferCollapsable'
      | '_changeLocation'
      | '_changeTransferWeight'
      | '_changeDepartmentCode'
      | '_createTransfer'
    >(this, {
      transfer: observable,
      _isTransferCollapsable: observable,
      _toggleTransferCollapsable: action,
      _changeLocation: action,
      _changeTransferWeight: action,
      _changeDepartmentCode: action,
      _createTransfer: action,
    });
  }

  public render() {
    const {
      translateService: { t },
      viewStore: { isDE, numberOfDecimals },
      isReadOnly,
      commonStore: { departmentCodes, yardLocations },
    } = this.props;

    return (
      <div data-test="transfer">
        <h5 className="mt-4 clearfix">
          {t.ADVISEDGOOD_SORTINGREPORT_TRANSFER}
          {this._isTransferCollapsable ? (
            <div className="pull-right">
              {isDE ? (
                <Input
                  data-test="transfer-department"
                  bsSize="sm"
                  className="float-start width-100 me-1"
                  disabled={isReadOnly}
                  onChange={this._changeDepartmentCode}
                  required
                  type="select"
                  value={this.transfer.departmentCode || ''}
                >
                  <option value="" disabled>
                    {t.GLOBAL_LABEL_DEPARTMENT}
                  </option>
                  {departmentCodes &&
                    departmentCodes.map((departmentCode: DepartmentCode) => (
                      <option key={departmentCode} value={departmentCode}>
                        {departmentCode}
                      </option>
                    ))}
                </Input>
              ) : (
                <React.Fragment>
                  <Input
                    data-test="transfer-location"
                    bsSize="sm"
                    className="float-start width-100"
                    disabled={isReadOnly}
                    onChange={this._changeLocation}
                    required
                    type="select"
                    value={(this.transfer.yardLocation && this.transfer.yardLocation.id) || ''}
                  >
                    <option value="" disabled>
                      {t.GLOBAL_LABEL_LOCATION}
                    </option>
                    {yardLocations &&
                      yardLocations.map((yardLocation: IdNameModel) => (
                        <option key={yardLocation.id} value={yardLocation.id}>
                          {yardLocation.name}
                        </option>
                      ))}
                  </Input>
                  <InputNumber
                    data-test="transfer-input"
                    bsSize="sm"
                    className="float-start width-100"
                    disabled={isReadOnly}
                    decimalPlaces={numberOfDecimals}
                    onChangeValue={this._changeTransferWeight}
                    placeholder={t.GLOBAL_LABEL_WEIGHT}
                    step={inputNumberStep(numberOfDecimals)}
                    value={this.transfer.weight}
                  />
                </React.Fragment>
              )}
              <Button
                data-test="create-transfer-btn"
                className="float-start"
                color="success"
                disabled={this._isTransferButtonDisabled}
                onClick={this._transferDeliveredGood}
                size="sm"
              >
                <FontAwesome name="share" /> {t.ADVISEDGOOD_SORTINGREPORT_TRANSFER}
              </Button>
            </div>
          ) : (
            <div className="pull-right">
              <Button
                data-test="toggle-transfer-collapsable"
                className="float-start"
                color="secondary"
                size="sm"
                disabled={!this._isTransferToggleEnabled}
                onClick={this._toggleTransferCollapsable}
              >
                <FontAwesome name="share" />
              </Button>
            </div>
          )}
        </h5>
        {/* Empty element to make space between components */}
        <Row className="bg-white border-top border-bottom pb-2" />
      </div>
    );
  }

  private get _isTransferButtonDisabled(): boolean {
    const {
      viewStore: { isDE },
    } = this.props;
    const { yardLocation, weight, departmentCode } = this.transfer;

    return !(isDE ? departmentCode : yardLocation && yardLocation.id && weight);
  }

  private get _isTransferToggleEnabled(): boolean {
    const {
      advisedGood: { status },
      viewStore: { isDE, isTier2FromDE, isTier2FromDE_D365 },
    } = this.props;
    return !isDE || ((isTier2FromDE || isTier2FromDE_D365) && status === AdvisedGoodStatus.UNLOADED);
  }

  private _transferDeliveredGood = () => {
    const {
      transferDeliveredGood,
      translateService: { t },
      viewStore: { isDE },
    } = this.props;

    transferDeliveredGood(this.props.item, this.transfer.getTransferData(isDE))
      .then(() => {
        notification.success(t.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG);
        this._toggleTransferCollapsable();
      })
      .catch((message: string) => logger.error(message));
  };

  private _toggleTransferCollapsable = () => {
    this._isTransferCollapsable = !this._isTransferCollapsable;
    if (!this._isTransferCollapsable) {
      this.transfer = this._createTransfer();
    }
  };

  private _changeLocation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const yardLocation = new IdNameModel();
    yardLocation.id = e.target.value;
    this.transfer.setYardLocation(yardLocation);
  };

  private _changeTransferWeight = (newValue: number) => {
    this.transfer.setWeight(newValue);
  };

  private _changeDepartmentCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.transfer.setDepartmentCode(e.target.value as DepartmentCode);
  };

  private _createTransfer() {
    const newTransfer = new TransferModel();
    newTransfer.description = this.props.item ? this.props.item.description : '';
    return newTransfer;
  }
}

export default inject('translateService', 'viewStore', 'commonStore')(observer(TransferComponent));
