import FileModel from 'models/FileModel';
import SupplierModel from 'models/SupplierModel';
import UpdateModel from 'models/UpdateModel';
import { action, observable, makeObservable } from 'mobx';
import UserModel from 'models/UserModel';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class AdvisedGoodDeliveryModel extends UpdateModel<AdvisedGoodDeliveryModel> {
  public attachments?: FileModel[] = [];
  public containerNumber?: string = '';
  public deliveryGrossWeight?: number = null;
  public deliveryTareWeight?: number = null;
  public driverName?: string = '';
  public grn?: string = '';
  public id?: string = '';
  public supplier?: SupplierModel = null;
  public supplierCity?: string = '';
  public trader?: UserModel<IUserModelConstructObj> = null;
  public deliveryNote?: string = '';
  public deliveryType?: string = '';
  public weatherCondition?: string = '';
  public advisedGoodsCount?: number = null;
  public transactionType?: string = '';

  public update = (obj: AdvisedGoodDeliveryModel) => {
    const newAdvisedGoodDeliveryModel = cloneObj(obj);

    if (newAdvisedGoodDeliveryModel) {
      newAdvisedGoodDeliveryModel.attachments = newAdvisedGoodDeliveryModel.attachments.map((r: FileModel) =>
        new FileModel().update(r)
      );

      if (newAdvisedGoodDeliveryModel.supplier) {
        newAdvisedGoodDeliveryModel.supplier = new SupplierModel().update(newAdvisedGoodDeliveryModel.supplier);
      }
      if (newAdvisedGoodDeliveryModel.trader) {
        newAdvisedGoodDeliveryModel.trader = new UserModel<IUserModelConstructObj>().update(
          newAdvisedGoodDeliveryModel.trader
        );
      }
    }

    this.updater.update(this, newAdvisedGoodDeliveryModel, AdvisedGoodDeliveryModel);
    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      attachments: observable,
      containerNumber: observable,
      deliveryGrossWeight: observable,
      deliveryTareWeight: observable,
      driverName: observable,
      grn: observable,
      id: observable,
      supplier: observable,
      supplierCity: observable,
      trader: observable,
      deliveryNote: observable,
      deliveryType: observable,
      weatherCondition: observable,
      advisedGoodsCount: observable,
      transactionType: observable,
      update: action,
    });
  }
}
