import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import RoadHaulierModel from 'models/RoadHaulierModel';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import RoadHaulierDetailComponent from 'pod/admin/road-hauliers/RoadHaulierDetailComponent';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class RoadHaulierDetailRoute extends React.Component<IProps> {
  public content: RoadHaulierModel = null;

  public updateContent = (newRoadHaulier: RoadHaulierModel) => {
    this.content = new RoadHaulierModel().update(newRoadHaulier);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      updateContent: action,
    });
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { roadHaulierList, roadHaulierApi },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.ROAD_HAULIER}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_ROAD_HAULIER}
        content={this.content}
        itemsList={roadHaulierList}
        api={roadHaulierApi}
        updateItem={this.updateContent}
      >
        <RoadHaulierDetailComponent content={this.content} />
      </CommonAdminRoute>
    );
  }
}

export default inject('adminStore', 'translateService')(observer(RoadHaulierDetailRoute));
