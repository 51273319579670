import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'はい';
lang.GLOBAL_LABEL_YES_SMALL = 'ﾊｲ';
lang.GLOBAL_LABEL_NO = 'いいえ';
lang.GLOBAL_LABEL_NO_SMALL = 'ｲｲｴ';
lang.GLOBAL_LABEL_SUBMIT = '送信';
lang.GLOBAL_LABEL_RESULT = '結果';
lang.GLOBAL_LABEL_ERROR = 'エラー';
lang.GLOBAL_LABEL_REFRESH = '更新';
lang.GLOBAL_LABEL_ADD_NEW = '新規追加';
lang.GLOBAL_LABEL_NAME = '名前';
lang.GLOBAL_LABEL_CODE = 'コード';
lang.GLOBAL_LABEL_UNIQUE_ID = '固有のID';
lang.GLOBAL_LABEL_LOCATION = '拠点';
lang.GLOBAL_LABEL_ONLINE = 'オンライン';
lang.GLOBAL_LABEL_IS_DEFAULT = 'はデフォルトです';
lang.GLOBAL_LABEL_MODEL = 'モデル';
lang.GLOBAL_LABEL_IP = 'IP';
lang.GLOBAL_LABEL_SAVE = '保存';
lang.GLOBAL_LABEL_DELETE = '削除';
lang.GLOBAL_LABEL_CANCEL = '取消';
lang.GLOBAL_LABEL_UPLOAD = 'アップロード';
lang.GLOBAL_LABEL_FILE = 'ファイル';
lang.GLOBAL_LABEL_PORT = 'ポート';
lang.GLOBAL_LABEL_FIRST_NAME = 'ファーストネーム';
lang.GLOBAL_LABEL_LAST_NAME = 'ラストネーム';
lang.GLOBAL_LABEL_USERNAME = 'ユーザー名';
lang.GLOBAL_LABEL_PASSWORD = 'パスワード';
lang.GLOBAL_LABEL_EMAIL = 'メール';
lang.GLOBAL_LABEL_ROLE = 'ロール';
lang.GLOBAL_LABEL_STATUS = '状態';
lang.GLOBAL_LABEL_REMARKS = '備考';
lang.GLOBAL_LABEL_SELECT = '選択';
lang.GLOBAL_LABEL_GRN = 'GRN';
lang.GLOBAL_LABEL_IN_PROGRESS = '進行中';
lang.GLOBAL_LABEL_COMPLETED = '完了済み';
lang.GLOBAL_LABEL_REGISTER = '登録';
lang.GLOBAL_LABEL_OPERATION = '操作';
lang.GLOBAL_LABEL_SUPPLIER = 'サプライヤー';
lang.GLOBAL_LABEL_SUPPLIER_CITY = 'サプライヤーの都市';
lang.GLOBAL_LABEL_DRIVER = 'ドライバー';
lang.GLOBAL_LABEL_LICENCE_PLACE = 'ナンバープレート';
lang.GLOBAL_LABEL_TO_CLAIM = '請求するため';
lang.GLOBAL_LABEL_CREATED = '作成済み';
lang.GLOBAL_LABEL_ACTIONS = 'アクション';
lang.GLOBAL_LABEL_CLAIM = 'クレーム';
lang.GLOBAL_LABEL_DESCRIPTION = '内容';
lang.GLOBAL_LABEL_ADD = '追加';
lang.GLOBAL_LABEL_NOT_CLAIMED = 'クレームされていない';
lang.GLOBAL_LABEL_DELIVERIES = '納入';
lang.GLOBAL_LABEL_SAVE_ATTACHMENTS = '添付ファイルをうぃ保存';
lang.GLOBAL_LABEL_UPLOAD_ATTACHMENTS = '添付ファイルをアップロード';
lang.GLOBAL_LABEL_ATTACHMENTS = '添付ファイル';
lang.GLOBAL_LABEL_ATTACHMENT = 'アタッチメント';
lang.GLOBAL_LABEL_ADVISED_GOODS = 'お勧め商品';
lang.GLOBAL_LABEL_SYNC = '同期';
lang.GLOBAL_LABEL_CLOSE = '終了';
lang.GLOBAL_LABEL_VERIFY = '検証';
lang.GLOBAL_LABEL_ALERT = '!!! お勧め商品にはフラグが付いています !!!';
lang.GLOBAL_LABEL_ALERT_RECEIVED_GOOD = '!!! 受け取った商品にフラグが立てられています!!!';
lang.GLOBAL_LABEL_CLAIM_BY = '請求者';
lang.GLOBAL_LABEL_CLASSIFIED_BY = '分類者';
lang.GLOBAL_LABEL_VERIFIED_BY = '確認者';
lang.GLOBAL_LABEL_ROLLEDBACK_BY = '撤回者';
lang.GLOBAL_LABEL_FLAGGED_BY = 'フラグを立てた人';
lang.GLOBAL_LABEL_UNFLAGGED_BY = 'フラグなし';
lang.GLOBAL_LABEL_TRANSFERS = '転送件数';
lang.GLOBAL_LABEL_TRANSFERED = '転送済み';
lang.GLOBAL_LABEL_WEIGHT = '重量';
lang.GLOBAL_LABEL_CREATE = '作成';
lang.GLOBAL_LABEL_CHARACTERS_REMAINING = (number) => `残り文字数： ${number}`;
lang.GLOBAL_LABEL_HIDE = '隠す';
lang.GLOBAL_LABEL_UNHIDE = '再表示';
lang.GLOBAL_LABEL_SHOW = '表示';
lang.GLOBAL_LABEL_LANGUAGE = (language) => `言語：${language}`;
lang.GLOBAL_LABEL_FILE_DOWNLOADED_MSG = 'ダウンロードしたファイル';
lang.GLOBAL_LABEL_DECIMALS = '少数';
lang.GLOBAL_LABEL_GO_BACK = '戻る';
lang.GLOBAL_LABEL_VERSION = (version) => `バージョン - ${version}`;
lang.GLOBAL_LABEL_CONFIRM = '確認';
lang.GLOBAL_LABEL_FIELD = 'ﾌｨｰﾙﾄﾞ';
lang.GLOBAL_LABEL_FIELDS = 'ﾌｨｰﾙﾄﾞ';
lang.GLOBAL_LABEL_NONE = '無し';
lang.GLOBAL_LABEL_ALL_TENANTS = '全テナント';
lang.GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR = '入力欄の表題が未定義です';
lang.GLOBAL_LABEL_UNCLAIM = '請求の撤回';
lang.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE = '証明書333/EU';
lang.GLOBAL_LABEL_CERT_AUT_RIF_DATE = '証明書 aut. rif.';
lang.GLOBAL_LABEL_CERT_715_13 = '証明書。 715/13';
lang.GLOBAL_LABEL_TYPE = 'タイプ';
lang.GLOBAL_LABEL_REVISION_REQUEST = '改訂依頼';
lang.GLOBAL_LABEL_EXPIRED = '期限切れ';
lang.GLOBAL_LABEL_NO_CERTIFICATE = '証明書無し';
lang.GLOBAL_LABEL_RESPONSIBLE = '責任';
lang.GLOBAL_LABEL_DEPARTMENT = '部門';
lang.GLOBAL_LABEL_EXPORT = '輸出';
lang.GLOBAL_LABEL_REEXPORT = '再輸出';
lang.GLOBAL_LABEL_FLAGGED = 'フラグ付き';
lang.GLOBAL_LABEL_EXPORTED = '輸出済み';
lang.GLOBAL_LABEL_SYNCED = '同期済み';
lang.GLOBAL_LABEL_PRINT = '印刷';
lang.GLOBAL_LABEL_FLAG = 'フラグ';
lang.GLOBAL_LABEL_UN_FLAG = 'フラグ解除';
lang.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER = '許可番号なし';
lang.GLOBAL_LABEL_AUTHORIZATION_EXPIRED = '許可期限切れ';
lang.GLOBAL_LABEL_NO_EXPIRATION_DATE = '有効期限なし';
lang.GLOBAL_LABEL_AUTH_NUMBER = '許可番号';
lang.GLOBAL_LABEL_EXP_DATE = '有効期限';
lang.GLOBAL_LABEL_IS_NOT_VALID = (name) => `${name} 有効ではない`;
lang.GLOBAL_LABEL_INVALID_FIELDS = (name) => `無効なフィールド：${name}`;
lang.GLOBAL_LABEL_USER_ROLES = 'ユーザーロール';
lang.GLOBAL_LABEL_DEFAULT_ROLE = 'デフォルトロール';
lang.GLOBAL_LABEL_DEFAULT_TENANT = 'デフォルトのテナント';
lang.GLOBAL_LABEL_NEW_VERSION_RELEASED = '新しいバージョンが出ました。';
lang.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION = '変更を適用するには - ページを再読み込みしてください。未保存の変更は全て失われます!';
lang.GLOBAL_LABEL_TENANTS_AVAILABLE = '利用可能なテナント';
lang.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE = (roleName) => `.${roleName}で利用可能なテナント`;
lang.GLOBAL_LABEL_IMPORT = 'インポート';
lang.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE = (date) => `.パスワードの有効期限は${date}です。 アカウントのブロックを回避するために、できるだけ早くパスワードを変更してください`;
lang.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE = 'パスワードの有効期限が切れています。新しいものを設定してください';
lang.GLOBAL_LABEL_PROCESSING = '処理';
lang.GLOBAL_LABEL_PRICE = '価格';
lang.GLOBAL_LABEL_UNIT = '単位';
lang.GLOBAL_LABEL_BREAKDOWN = '壊す';
lang.GLOBAL_LABEL_PASSWORD_EXP_DATE = 'パスワードexp。 日付';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA = 'イタリア';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO = 'エステロ';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_DITTA = 'ディッタ';
lang.GLOBAL_LABEL_SUPPLIERS_DETAILS = 'サプライヤーの詳細';
lang.GLOBAL_LABEL_FORM = '形';
lang.GLOBAL_LABEL_QUALITY = '品質';
lang.GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS = '製品バリアントの更新';
lang.GLOBAL_LABEL_WEIGHT_UNITS = '重量単位';
lang.GLOBAL_LABEL_EMPLOYEE_ID = '従業員ID';
lang.GLOBAL_LABEL_LEGAL_ENTITY = '法人';
lang.GLOBAL_LABEL_PRODUCT_VARIANTS = '製品バリエーション';
lang.GLOBAL_LABEL_REFRESH_COMMON = (name) => `更新 ${name}`;
lang.GLOBAL_LABEL_TOTAL = '合計';
lang.GLOBAL_LABEL_SHAREPOINT = '共有ポイント';
lang.GLOBAL_LABEL_PARTIAL_SIGN_OFF = '部分的なサインオフ';
lang.GLOBAL_LABEL_TITLE = 'タイトル';
lang.GLOBAL_LABEL_CLIENT_ID = 'クライアントID';
lang.GLOBAL_LABEL_CLIENT_SECRET = 'クライアントサーセト';
lang.GLOBAL_LABEL_CONNECTION_ADDRESS = '接続アドレス';
lang.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT = 'デフォルトで残高控除を有効にする';
lang.GLOBAL_LABEL_DETAILED_WEIGHING = '詳細計量';
lang.GLOBAL_LABEL_UPDATE = 'アップデート';
lang.GLOBAL_FILTERKEY_DATE_ASC = '昇順日付';
lang.GLOBAL_FILTERKEY_DATE_DESC = '降順日付';
lang.GLOBAL_FILTERKEY_STATUS_ASC = '昇順ステータス';
lang.GLOBAL_FILTERKEY_STATUS_DESC = '降順ステータス';
lang.GLOBAL_FILTERKEY_ALL = '全て';
lang.GLOBAL_FILTERKEY_ONLY_MY = '自分のもののみ';
lang.GLOBAL_FILTERKEY_ONLY_UNCLAIMED = '未請求分のみ';
lang.GLOBAL_FILTERKEY_ONLY_UNSYNCED = '非同期分のみ';
lang.GLOBAL_FILTERKEY_IN_PROGRESS = '進行中';
lang.GLOBAL_FILTERKEY_IN_LAB = '在ラボ';
lang.GLOBAL_FILTERKEY_READY_FOR_TIER_2 = 'ティア2準備完了';
lang.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF = 'サインオフ準備完了';
lang.GLOBAL_FILTERKEY_SIGNED_OFF = 'サインオフ済み';
lang.GLOBAL_FILTERKEY_COMPLETE = '完了';
lang.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF = '部分的に承認されました';
lang.GLOBAL_RADIOBUTTON_DRY = '乾燥';
lang.GLOBAL_RADIOBUTTON_RAIN = '雨';
lang.GLOBAL_RADIOBUTTON_SNOW = '雪';
lang.GLOBAL_RADIOBUTTON_DUMP = 'ダンプ';
lang.GLOBAL_RADIOBUTTON_VAN = 'バン';
lang.GLOBAL_RADIOBUTTON_FLATBED = '平台';
lang.GLOBAL_RADIOBUTTON_ROLL_OFF_BOX = 'ロールコンテナ';
lang.GLOBAL_RADIOBUTTON_RAILCAR = 'レールカー';
lang.GLOBAL_RADIOBUTTON_OTHER = 'その他';
lang.GLOBAL_USERROLE_SUPERADMIN = 'スーパー管理者';
lang.GLOBAL_USERROLE_ADMIN = '管理者';
lang.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR = '計量台';
lang.GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE = '外部計量台';
lang.GLOBAL_USERROLE_TIER3 = 'ティア3';
lang.GLOBAL_USERROLE_TIER2 = 'ティア2';
lang.GLOBAL_USERROLE_TIER1 = 'ティア1';
lang.GLOBAL_USERROLE_MANAGER = 'マネージャー';
lang.GLOBAL_USERROLE_LAB = 'ラボ';
lang.GLOBAL_USERROLE_TRADER = 'トレーダー';
lang.GLOBAL_USERROLE_TRADER_MOBILE = 'トレーダー';
lang.GLOBAL_USERROLE_BACKOFFICE = 'バックオフィス';
lang.GLOBAL_USERROLE_DATAANALYZER = 'データ アナライザー';
lang.GLOBAL_LOGIN_HEADER = 'ログイン';
lang.GLOBAL_LOGIN_TITLE = 'あなたのアカウントにサインインしてください';
lang.GLOBAL_LOGOUT_SUCCESS_MSG = 'あなたはすでにログアウトしました';
lang.GLOBAL_DATERANGEPICKER_START_DATE_PLH = '開始日';
lang.GLOBAL_DATERANGEPICKER_END_DATE_PLH = '終了日';
lang.GLOBAL_RECOVERPASSWORD_HEADER = 'パスワード<br />設定';
lang.GLOBAL_RECOVERPASSWORD_UPDATE_MSG = 'パスワードは更新されました';
lang.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN = 'パスワードを設定してください';
lang.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD = '現在のパスワード';
lang.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD = '新しいパスワード';
lang.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD = '新しいパスワードをの再入力してください';
lang.GLOBAL_FILE_SELECT_ERROR = 'ファイル選択中にエラーが発生しました';
lang.GLOBAL_SERVERERROR_DEFAULT = '不具合が発生しました…';
lang.GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR = '外部サービスに障害が発生しました...';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD = 'T2が指定指図指図貨物のフラグを解除できません。';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD = 'T2は受信した良好なフラグを解除できません。';
lang.GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK = 'T2が指図指定指図貨物ステータスを撤回できません。';
lang.GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD = '.Traderが指定指図指図貨物のフラグを解除できません。';
lang.GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS = '管理者は、現在のステータスからアドバイスをロールバックすることはできません。';
lang.GLOBAL_SERVERERROR_T1_CANNOT_CLAIM = 'T1が指定指図指図貨物を請求できません';
lang.GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD = 'T1が指定指図指図貨物を降ろせません';
lang.GLOBAL_SERVERERROR_T1_CANNOT_VERIFY = 'T1が指定指図指図貨物を確認できません';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED = '納入がまだ同期されておらず指定指図指図貨物を確認できません';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_ADVISED_GOOD_NOT_UNLOADED = '配信がまだダウンロードされていないため、推奨を完了することはできません';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF = 'フラグ付き指図貨物はサインオフできません';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF = 'アドバイスされた商品はサインオフの準備ができていません';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED = 'アドバイスされた商品をサインオフする前に、すべてのアイテムを在庫コード化する必要があります';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT = 'すでに確認済みの場合は、アドバイスを承認することはできません';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT = '推奨品には風袋重量が記入されていません';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT = 'アドバイスされた適切なレベルのサインオフはテナントに対して無効になっています';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF = '納入がサインオフ済みの場合指定指図指図貨物を更新できません';
lang.GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED = 'AG がアンロードされている場合、コメントを更新できません';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT = '指図貨物を確認できません、指図指定指図貨物の自重が記入されていません';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT = '指図貨物を確認できません、納入自重が記載されていません';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING = '全ての在庫コードを記入してから指定指図指図貨物を確認できます';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB = 'ラボにはまだいくつかのアイテムが残っています';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY = (fullname) => `指定指図指図貨物はすでに${fullname}が請求しました`;
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF = '納入のサインオフ後に撤回はできません';
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF = 'アドバイスされた商品が承認されると、ロールバックを行うことはできません';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST = 'ラボサンプルを含む配信は削除できません';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_LAB_SAMPLES_EXIST = '実験室サンプルで推奨される製品を排除することはできません';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_LAST_ADVISED_GOOD_IN_DELIVERY = '配送には推奨品が 1 つだけ含まれます。 代わりに配信を削除してください';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE = 'テンプレートは指図貨物一つで受領した11個を超える以上の商品には対応していません。';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG = '一つの指定指図指図貨物に最大10個の受け入れ貨物が認許められます。';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG = '一つの指定指図指図貨物に最大19個の受け入れ貨物が認許められます。';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE = 'カメラから画像取得中にタイムアウトしました';
lang.GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED = '使用する前にカメラを調整較正較正してください';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT = 'OCRの結果取得中にタイムアウトしました';
lang.GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND = '管理者ロールが存在しません';
lang.GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND = 'AppRegistrationの役割は存在しません';
lang.GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED = 'AppRegistrationタイプのアカウントの編集のみが許可されます';
lang.GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR = 'クライアントシークレットには、少なくとも20文字、大文字1文字、数字1文字、特殊文字1文字が必要です。';
lang.GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS = 'クライアントIDはすでに存在します';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE = 'アプリ登録タイトルが無効です';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION = 'アプリ登録の説明が無効です';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20 = 'クライアントシークレットには少なくとも20文字が必要です';
lang.GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS = '数人のユーザーが属しているためテナントを削除できません';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES = 'テナントにはすでにいくつかの配信が作成されているため、テナントの順序を設定できません';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES = 'すでにいくつかの配信が作成されているため、テナントの法人を設定できません';
lang.GLOBAL_SERVERERROR_CANNOT_CHANGE_TENANT_WORKFLOW = 'ワークフローは変えられない';
lang.GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT = 'このサイトはすでに他のテナントが使用しているため使用できません';
lang.GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY = 'サイトを提供してください';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY = '添付ファイルのアップロード用にSharePointフォルダーを提供してください';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST = '選択したSharePointフォルダーが存在しません';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT = '添付ファイルアップロード用のSharePointフォルダーがテナント用に選択されていません。 SuperAdminに連絡してください';
lang.GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE = 'dOpsアプリケーションは、選択したSharePointサイトにファイルをアップロードすることを許可されていません。 SuperAdminに連絡してください';
lang.GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT = '外部サービスエラー：SharePointOnlineの添付ファイルメタデータの更新に失敗しました';
lang.GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT = '外部サービスエラー：SharePointOnlineにログインできません';
lang.GLOBAL_SERVERERROR_SHAREPOINT_FOLDER_NOT_FOUND = '外部サービス エラー: 添付ファイルをアップロードするための SharePoint フォルダーが見つかりません。 スーパー管理者にお問い合わせください';
lang.GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED = '同期後の納入は更新できません';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER = '納入通知番号は納入同期のために必須です';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED = '請求済み指定指図指図貨物を削除することはできません';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT = '自重は納入同期のために必須です';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD = '副指定指図指図貨物には少なくとも一つの受領貨物項目がなければなりません';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS = '指図貨物は指定指図指図貨物明細に少なくとも一項目を含まなければなりません';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD = '納入は少なくとも一つの指定指図指図貨物項目を含まなければなりません';
lang.GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED = '指定指図指図貨物が請求されるまで自重は保存できません';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED = '全項目を在庫コード化してからでないと納入をサインオフすることはできません。';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED = '全ラボ項目を完了してからでないと納入をサインオフすることはできません。';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '自重が未記入の指定指図指図貨物が数個あります。';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT = '総重量が記入されていない商品があります';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE = 'EWCコードが記入されていないおすすめ商品があります';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'サインオフ前に納入を同期してください';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'サインオフできません。配信を同期する必要があります。';
lang.CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF = '配達がサインオフされる前に、すべてのアドバイスされた商品はサインオフの準備ができている必要があります';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = '全自重が入力されるまでは納入を完了できません';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF = 'サインオフしていない納入を完了できません。';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = '納入は計量台で同期されていないので再同期不可';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF = 'サインオフしていないので分別の再同期不可';
lang.GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS = '同じコードの物資がすでにあります';
lang.GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY = (grn) => `物資は納入${grn}が使用中`;
lang.GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED = '指定指図指図貨物を最初に請求せずに編集不可';
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD = 'ラボ解析を完了した分別レポート項目については追加不可';
lang.GLOBAL_SERVERERROR_STOCK_CODE_INVALID = (stockCode) => `入力された在庫コード ${stockCode}は無効`;
lang.GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND = (userRole) => `指定されたユーザーロールID ${userRole}が見つかりません`;
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD = 'ラボ解析をクレームされていない分別レポート項目には追加不可';
lang.GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR = 'QRコード画像の生成中に例外発生';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT = '着荷貨物の重量が移転時にゼロは不可';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED = '移転最大重量は着荷総重量';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT = '指定指図指図貨物の重量を設定してからでないと移転できません';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED = '移転最大重量が指定指図指図貨物の重量より大きいことは不可';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT = '重量が負の着荷貨物は移転不可';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD = '全着荷貨物の移転不可';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD = '全指定指図指図貨物の移転不可';
lang.GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD = '指定指図指図貨物に一個しかない着荷貨物の移転不可';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN = 'スーパー管理者ロールをそのユーザーには割り当て不可';
lang.GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR = 'パスワードは少なくとも10文字で1つ以上の大文字、1つ以上の数字、1つ以上の特殊記号からなること';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN = 'その他の管理者を削除することはできません';
lang.GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR = '未文書化エラー';
lang.GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL = 'メール送信中にエラー発生';
lang.GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST = '要請の処理中にエラー発生';
lang.GLOBAL_SERVERERROR_FILE_NOT_FOUND = 'ファイルが見つかりません';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND = '指定指図指図貨物が見つかりません';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND = '指定指図指図貨物添付ファイルが見つかりません';
lang.GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND = '汚染が見つかりません';
lang.GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND = '副指定指図指図貨物が見つかりません';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND = '着荷貨物が見つかりません';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79 = '着荷貨物の説明は79文字まで';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_FOUND = 'カメラが見つかりません';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE = 'カメラが作動していません';
lang.GLOBAL_SERVERERROR_TENANT_NOT_FOUND = 'テナントが見つかりません';
lang.GLOBAL_SERVERERROR_ADMIN_NOT_FOUND = '管理者が見つかりません';
lang.GLOBAL_SERVERERROR_WEIGHBRIDGE_USER_NOT_FOUND = '称量ユーザーが見つかりません';
lang.GLOBAL_SERVERERROR_TRADER_USER_NOT_FOUND = 'トレーダーユーザーが見つかりません';
lang.GLOBAL_SERVERERROR_USER_NOT_FOUND = 'ユーザーが見つかりません';
lang.GLOBAL_SERVERERROR_LOCATION_NOT_FOUND = '拠点が見つかりません';
lang.GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS = '拠点はすでに存在します';
lang.GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND = '納入が見つかりません';
lang.GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND = '納入添付ファイルが見つかりません';
lang.GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND = 'サプライヤーが見つかりません';
lang.GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND = 'サプライヤータイプが見つかりません';
lang.GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND = '道路路上運送道路貨物輸送会社が見つかりません';
lang.GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND = '物資が見つかりません';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND = 'ラボ入力タイプが見つかりません';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW = 'ワークフローのラボ入力タイプが見つかりません';
lang.GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND = 'ラボ入力が見つかりません';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED = 'ラボステータスに対応していません';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW = 'ワークフローのラボステータスが見つかりません';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND = '在庫項目が見つかりません';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS = '在庫項目はすでにあります';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5 = '在庫項目コードの長さは5桁です';
lang.GLOBAL_SERVERERROR_PRINTER_NOT_FOUND = 'プリンターが見つかりません';
lang.GLOBAL_SERVERERROR_SCALE_NOT_FOUND = '秤が見つかりません';
lang.GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND = '印刷テンプレートが見つかりません';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND = '製品フォームが見つかりません';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM = '提供された製品フォームは、提供された在庫アイテムとは関係ありません';
lang.GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND = '製品の品質が見つかりません';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND = '製品バリアントが見つかりません';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF = 'サインオフには製品バリアントが必要です';
lang.GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS = 'そのユーザー名はすでにあります';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF = '自分を削除することはできません';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD = '無効なユーザー名かパスワード';
lang.GLOBAL_SERVERERROR_CANNOT_LOGIN_TENANT_IS_DELETED = '削除されているため、テナントにログインできません';
lang.GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE = '別のパスワードを選択してください。 これは以前にすでに使用されています';
lang.GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED = '現在のクライアントシークレットは2年間使用されており、有効期限が切れています。 スーパー管理者に連絡してください';
lang.GLOBAL_SERVERERROR_INVALID_EMAIL = '有効なメールを入力してください';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME = 'ユーザー名を入力してください';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD = 'パスワードを入力してください';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10 = 'パスワードは10文字以上です';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_ID = '無効なクライアントID';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET = '無効なクライアントシークレット';
lang.GLOBAL_SERVERERROR_INVALID_FIRSTNAME = 'ファーストネームを入力してください';
lang.GLOBAL_SERVERERROR_INVALID_LASTNAME = 'ファミリーネームを入力してください';
lang.GLOBAL_SERVERERROR_INVALID_USER_ROLES = 'ユーザーロールを入力してください';
lang.GLOBAL_SERVERERROR_INVALID_USER_TENANTS = 'ユーザーテナントを入力してください';
lang.GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID = '外部従業員IDは6つ以上の記号である必要があります';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED = '外部の従業員IDフィールドは必須です';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND = '提供されたIDを持つ外部従業員が見つかりません';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT = (tenantName) => `選択した従業員IDは、テナントで作業する資格がありません：${tenantName}`;
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE = 'ユーザーにデフォルトの役割を1つだけ指定してください';
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT = 'ユーザーにはデフォルトテナントを1つだけ指定してください';
lang.GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES = 'ユーザーの現ロールは既存のユーザーロールの一つに一致しなければなりません';
lang.GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT = '選択したテナントにアクセスする権限がありません';
lang.GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN = '特権管理者のみが「管理者」の役割を持つユーザーを作成または編集できます';
lang.GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY = 'ユーザーはTraderとBackOfficeに同時になることはできません';
lang.GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES = 'ロール DataAnalyzer を持つユーザーは、複数のロールを持つことはできません';
lang.GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN = 'ユーザーに役割Trader、BackOffice、またはAdminが割り当てられている場合にのみ、複数のテナントを割り当てることができます';
lang.GLOBAL_SERVERERROR_INVALID_NAME = '名前を入力してください';
lang.GLOBAL_SERVERERROR_ACCESS_DENIED = 'アクセスが拒否されました';
lang.GLOBAL_SERVERERROR_SESSION_NOT_FOUND = 'セッションが見つかりません';
lang.GLOBAL_SERVERERROR_SESSION_EXPIRED = 'セッション期限切れ';
lang.GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE = '1分後にもう一度お試しください';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING = '在庫項目名を入力してください';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD = '指定指図指図貨物の一つを確認した場合納入はサインオフできなくなります';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS = 'そのコードのサプライヤーはすでにいます';
lang.GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING = 'サプライヤー名を入力してください';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING = 'サプライヤーコードを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS = 'フラグ付きステータスを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION = '内容を入力してください';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255 = '着荷貨物の説明は255文字まで';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION = '物資情報を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID = '物資 IDを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT = '物資重量を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID = '副指定指図指図貨物IDを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1 = 'p1の値を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2 = 'p2の値を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3 = 'p3の値を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4 = 'p4の値を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_CONTRAST = 'コントラストを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE = '表示タイプを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME = 'カメラ名を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE = 'カメラの場所を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID = 'カメラ固有IDを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_SUPPLIER = 'サプライヤーを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER = '道路路上運送道路貨物輸送会社を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT = '部門を入力してください';
lang.GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30 = '備考は30文字まで認められます';
lang.GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER = '車両登録番号を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM = '在庫項目を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION = '指定指図指図説明を入力してください';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30 = '指図指定指図貨物の説明は30文字まで認められます';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50 = '指図指定指図貨物の説明は50文字まで認められます';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100 = '指図指定指図貨物の説明は100文字まで認められます';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMMENT_MAX_LENGTH_100 = 'コメントは100文字を超えることはできません';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNLOADED_ATTACHMENTS_MISSING_ERROR = '添付ファイルを追加してください';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMPLETED_ATTACHMENTS_MISSING_ERROR = '添付ファイルを追加してください';
lang.GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER = '計量台チケット番号を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_LOCATION = '拠点を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE = '物資コードを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME = '物資名を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME = 'プリンター名を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP = 'プリンターのIPアドレスを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL = 'プリンター機種を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS = 'プリンタ接続アドレスを入力してください';
lang.GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255 = '説明の長さは最大255文字です';
lang.GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255 = 'アドレスは最大255文字の長さにすることができます';
lang.GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50 = '名前の長さは最大255文字です';
lang.GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND = 'ドキュメントタイプが見つかりません';
lang.GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME = 'Lasernetレポート名を入力してください';
lang.GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255 = 'Lasernetレポート名は最大255文字の長さにすることができます';
lang.GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES = 'デフォルトの部数を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID = 'プリンタIDを入力してください';
lang.GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS = '指定された名前のプリンタはすでに存在します';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND = 'プリンター構成が見つかりません';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS = '提供されたパラメーターを使用したプリンター構成はすでに存在します';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES = '部数を教えてください';
lang.GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER = '移送重量を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_STATUS = '新しいステータスを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME = '秤名を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_IP = '秤のIPアドレスを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT = '秤のポートを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE = '納入タイプを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION = '気象条件を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE = '取引タイプを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE = '処理価格を入力してください';
lang.GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND = '処理価格が見つかりません';
lang.GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH = '画像解像度が高すぎます';
lang.GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED = 'PDFファイルと画像のみ添付できます';
lang.GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY = 'GRNを空欄のままにできません';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION = (transfers) => `移動項目関連の貨物は削除不可: ${transfers}`;
lang.GLOBAL_SERVERERROR_UNSUPPORTED_ACTION = '対応していないアクション';
lang.GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE = 'このサーバーで利用可能なD365テナントはありません';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS = '同じタイプのその他ラボ入力がすでにあるのでりラボ入力を作成できません';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT = '指定指図指図貨物の控除重量を空欄のままにできません';
lang.GLOBAL_SERVERERROR_MACHINE_NOT_FOUND = '機械が見つかりません';
lang.GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND = 'ターゲットバッチが見つかりません';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING = (rgIdentifier) => `次の重量を入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING = (rgIdentifier) => `に機械を入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING = (rgIdentifier) => `に完了WIを入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING = (rgIdentifier) => `に嵩密度を入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING = (rgIdentifier) => `に物資の説明を入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING = (rgIdentifier) => `に検出同定した品質を入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING = (rgIdentifier) => `に主なタイプを入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING = (rgIdentifier) => `にターゲットバッチを入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING = 'テナント名を入力してください';
lang.GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING = 'テナントコードを入力してください';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING = 'テナントの拠点コードを入力してください';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS = 'テナントの拠点コードは10文字まで認められます';
lang.GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING = 'テナントのGRNプレフィックスを入力してください';
lang.GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING = '現在のテナント用GRNの値テナントを入力してください';
lang.GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING = 'テナントワークフローを入力してください';
lang.GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING = 'テナントの時間帯を入力してください';
lang.GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING = 'テナントの地域コードを入力してください';
lang.GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS = 'テナント地域コードは10文字まで認められます';
lang.GLOBAL_SERVERERROR_TENANT_COMMENT_MAX_CHARS = 'サイトのコメントは 100 文字を超えることはできません';
lang.GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING = 'テナントの法人を提供してください';
lang.GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT = '添付ファイルの所有者か管理者しか添付ファイルは削除できません';
lang.GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING = '統合終点の設定がありません。管理者に連絡してください。';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED = '指定指図指図貨物は請求済みステータスでないと請求解除不可';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN = '指定指図指図貨物は請求者本人か管理者のみ請求解除できます';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS = '指定指図指図貨物はラボ入力が未作成の場合のみ請求解除可能です';
lang.GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS = '梱包はすでにあります';
lang.GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND = '梱包が見つかりません';
lang.GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT = '汚染領域の合計「サイズ」は必ず100%になります';
lang.GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND = '処理時間が見つかりません';
lang.GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING = (rgIdentifier) => `に汚染を入力してください: ${rgIdentifier}`;
lang.GLOBAL_SERVERERROR_INVALID_CODE = 'コードを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_ORIGIN = '出荷元を入力してください';
lang.GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND = '出荷元が見つかりません';
lang.GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35 = '出荷元は35文字です';
lang.GLOBAL_SERVERERROR_EWC_NOT_FOUND = 'EWCが見つかりません';
lang.GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS = 'EWCはすでに存在します';
lang.GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS = 'GRNはすでに存在します';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF = '納入がまだサインオフされていないのでエクスポートできません';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED = 'フィルタを使用して、エクスポートされる配信の数を減らしてください。 選択した配信リストが長すぎます。 ';
lang.GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND = '測定値が見つかりません';
lang.GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND = '分光解析が見つかりません';
lang.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS = (joinedlabelslist) => `入力してください: ${joinedlabelslist}リスト`;
lang.GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH = (allowedLength) => `ユーザーコード長は${allowedLength}文字です`;
lang.GLOBAL_SERVERERROR_USER_WITH_THIS_CODE_ALREADY_EXISTS = 'このコードを持つユーザーはテナントにすでに存在します';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE = '配信作成日を入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER = 'アドバイスされた適切な行番号を提供してください';
lang.GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE = 'トレーダーコードを入力してください';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT = 'アドバイスされた正味重量を提供してください';
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE = (grn) => `配信${grn}はすでに完了しており、更新できません`;
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE = (grn) => `配信${grn}はすでにサインオフされており、更新できません`;
lang.GLOBAL_SERVERERROR_FILE_IS_EMPTY = 'ファイルが空です';
lang.GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED = '契約のインポートに失敗しました ';
lang.GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE = '列数がテンプレートに対応していません';
lang.GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED = '列の順序がテンプレートに対応していません';
lang.GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED = 'ヤードの場所のアップロードに失敗しました。間違った列名が指定されました';
lang.GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED = 'サプライヤーのアップロードに失敗しました。間違った列名が指定されました';
lang.GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED = '道路運送業者のアップロードに失敗しました。間違った列名が指定されました';
lang.GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED = '在庫アイテムのアップロードに失敗しました。間違った列名が指定されました';
lang.GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY  = '重量単位を入力してください';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY = '法人を提供してください';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_NOT_FOUND = '提供された法人が見つかりません';
lang.GLOBAL_SERVERERROR_EXTERNAL_API_NAME_NOT_FOUND = '指定された外部 API 名が見つかりません';
lang.GLOBAL_COPY_TITLE = (itemname) => `.クリックして${itemname}をクリップボードにコピーしてください`;
lang.GLOBAL_COPY_SUCCESS_MESSAGE = (name) => `.${name}はクリップボードにコピーされました`;
lang.HEADER_QRCODE_HEADER = 'QRコードをスキャン';
lang.HEADER_QRCODE_ERROR_MSG = 'QRコードリーダーの起動中に障害発生';
lang.HEADER_LABEL_LOGOUT = 'ログアウト';
lang.HEADER_LABEL_CHANGE_PASSWORD = 'パスワードを変更してください';
lang.HEADER_LABEL_SWITCH_ROLE = 'ロールを入れ替える';
lang.HEADER_LABEL_SWITCH_TENANT = 'テナントの切り替え';
lang.HEADER_MODAL_LOGOUT = 'ログアウトしますか? 保存していない変更は失われます。';
lang.HEADER_MODAL_SWITCH_ROLE = 'ロールを切り替えますか? 保存していない変更は失われます。';
lang.HEADER_MODAL_SWITCH_TENANT = 'テナントを切り替えますか？ 保存されていない変更はすべて失われます。';
lang.ADMIN_SECTIONENTITY_USER = 'ユーザー';
lang.ADMIN_SECTIONENTITY_STOCK_ITEM = '在庫項目';
lang.ADMIN_SECTIONENTITY_SUPPLIER = 'サプライヤー';
lang.ADMIN_SECTIONENTITY_ROAD_HAULIER = '道路路上運送道路貨物輸送会社';
lang.ADMIN_SECTIONENTITY_YARD_LOCATION = 'ヤードの拠点';
lang.ADMIN_SECTIONENTITY_PRINTER = 'プリンター';
lang.ADMIN_SECTIONENTITY_SCALE = '秤';
lang.ADMIN_SECTIONENTITY_MATERIAL = '物資';
lang.ADMIN_SECTIONENTITY_MACHINE = '機械';
lang.ADMIN_SECTIONENTITY_TARGET_BATCH = 'ターゲットバッチ';
lang.ADMIN_SECTIONENTITY_PACKAGE = '梱包';
lang.ADMIN_SECTIONENTITY_EWC = 'EWC番号';
lang.ADMIN_SECTIONENTITY_ORIGIN = '出荷元';
lang.ADMIN_ACTIVITYLOG_TITLE = '活動ログ';
lang.ADMIN_ACTIVITYLOG_ACTION = 'アクション';
lang.ADMIN_ACTIVITYLOG_CREATEDAT = '作成日';
lang.ADMIN_ACTIVITYLOG_USER = 'ユーザー';
lang.ADMIN_ACTIVITYLOG_MESSAGE = 'メッセージ';
lang.ADMIN_MATERIALS_CREATE_SUCCESS_MSG = '新しい物資が作成されました';
lang.ADMIN_MATERIALS_TITLE = '物資';
lang.ADMIN_MATERIALS_CREATE_NEW = '新しい物資を作成';
lang.ADMIN_MATERIALDETAIL_UPDATE_MSG = '物資が更新されました';
lang.ADMIN_MATERIALDETAIL_DELETE_MSG = '物資が削除されました';
lang.ADMIN_MATERIALDETAIL_HEADER = '物資の明細';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER = '物資の確認書を削除';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT = (name) => `物資<strong>${name}</strong>を削除しますか?`;
lang.ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG = '新しいプリンターが作成されました';
lang.ADMIN_PRINTERLIST_HEADER = 'プリンター';
lang.ADMIN_PRINTERLIST_ADD_BTN = 'プリンターを作成';
lang.ADMIN_PRINTERLIST_NAME = 'プリンター名';
lang.ADMIN_PRINTERLIST_CREATE_NEW = '新しいプリンターを作成';
lang.ADMIN_PRINTERDETAIL_UPDATE_MSG = 'プリンターが更新されました';
lang.ADMIN_PRINTERDETAIL_DELETE_MSG = 'プリンターが削除されました';
lang.ADMIN_PRINTERDETAIL_HEADER = 'プリンター拠点の詳細';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER = 'プリンターの確認書を削除';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT = (name) => `プリンター<strong>${name}</strong>を削除しますか?`;
lang.ADMIN_ROADHAULIERLIST_HEADER = '道路路上運送道路貨物輸送会社';
lang.ADMIN_ROADHAULIERLIST_UPLOAD_BTN = '道路路上運送道路貨物輸送会社をアップロード';
lang.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER = '許可番号';
lang.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE = '有効期限日';
lang.ADMIN_COMMONLIST_CREATE_HEADER = (name) => `新しい${name}を作成`;
lang.ADMIN_COMMONLIST_UPLOAD_HEADER = (name) => `${name}をアップロード`;
lang.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG = (name) => `新しい${name}が作成されました`;
lang.ADMIN_COMMONDETAIL_UPDATE_MSG = (name) => `${name}が更新されました`;
lang.ADMIN_COMMONDETAIL_HIDE_MSG = (name) => `${name}が非表示にされました`;
lang.ADMIN_COMMONDETAIL_UNHIDE_MSG = (name) => `${name}の非表示が解除表示に戻されました`;
lang.ADMIN_COMMONDETAIL_HEADER = (name) => `${name}の詳細`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER = (name) => `${name}確認書を隠す`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `<strong>${name}</strong>を隠しますか?`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER = (name) => `${name}確認書を再表示`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `<strong>${name}</strong>を再表示しますか?`;
lang.ADMIN_COMMONDETAIL_ERROR_MSG = (name) => `${name}が見つかりません`;
lang.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG = 'リストのアップロードに成功しました!';
lang.ADMIN_ADMINLISTUPLOAD_ERROR_MSG = 'リストのアップロード中に不具合が発生しました。';
lang.ADMIN_ADMINLISTUPLOAD_EXAMPLE = 'エクセルファイル例をよく調べてください：';
lang.ADMIN_SCALELIST_CREATE_SUCCESS_MSG = '新しい秤が作成されました';
lang.ADMIN_SCALELIST_HEADER = '秤';
lang.ADMIN_SCALELIST_ADD_BTN = '秤を作成';
lang.ADMIN_SCALELIST_NAME = '秤名';
lang.ADMIN_SCALELIST_CREATE_NEW = '新しい秤を作成';
lang.ADMIN_SCALEDETAIL_UPDATE_MSG = '秤が更新されました';
lang.ADMIN_SCALEDETAIL_DELETE_MSG = '秤が削除されました';
lang.ADMIN_SCALEDETAIL_HEADER = '秤の詳細';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_HEADER = '秤確認書を削除';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_TEXT = (name) => `秤<strong>${name}</strong>を削除しますか?`;
lang.ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR = '入力欄の表題が未定義です';
lang.ADMIN_STOCKEITEMLIST_HEADER = '在庫項目';
lang.ADMIN_STOCKEITEMLIST_UPLOAD_BTN = '在庫項目をアップロード';
lang.ADMIN_SUPPLIERLIST_HEADER = 'サプライヤー';
lang.ADMIN_SUPPLIERLIST_UPLOAD_BTN = 'サプライヤーをアップロード';
lang.ADMIN_SUPPLIERDETAIL_UPDATE_MSG = 'サプライヤーが更新されました';
lang.ADMIN_SUPPLIERDETAIL_HIDE_MSG = 'サプライヤーが非表示にされました';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_MSG = 'サプライヤーが再表示されました表示に戻されました';
lang.ADMIN_SUPPLIERDETAIL_HEADER = 'サプライヤーの詳細';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER = 'サプライヤー確認書を隠す';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `サプライヤー<strong>${name}</strong>を隠しますか?`;
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER = 'サプライヤー確認書を再表示';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `サプライヤー<strong>${name}</strong>を再表示しますか?`;
lang.ADMIN_SUPPLIERDETAIL_ERROR_MSG = 'サプライヤーが見つかりません';
lang.ADMIN_MACHINEDETAIL_UPDATE_MSG = '機械が更新されました';
lang.ADMIN_MACHINEDETAIL_HIDE_MSG = '機械が隠されました';
lang.ADMIN_MACHINEDETAIL_UNHIDE_MSG = '機械が再表示されました表示に戻されました';
lang.ADMIN_MACHINEDETAIL_HEADER = '機械の詳細';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER = '機械確認書を隠す';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `機械<strong>${name}</strong>を隠しますか?`;
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER = '機械確認書を再表示';
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `機械<strong>${name}</strong>を再表示しますか?`;
lang.ADMIN_MACHINEDETAIL_ERROR_MSG = '機械が見つかりません';
lang.ADMIN_PACKAGING_DETAIL_UPDATE_MSG = '梱包が更新されました';
lang.ADMIN_PACKAGING_DETAIL_HIDE_MSG = '梱包が隠されました';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_MSG = '梱包が表示に戻されました再表示されました';
lang.ADMIN_PACKAGING_DETAIL_HEADER = '梱包の詳細';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER = '梱包確認書を隠す';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT = (name) => `梱包<strong>${name}</strong>を隠しますか?`;
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER = '梱包確認書を再表示';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `梱包<strong>${name}</strong>を再表示しますか?`;
lang.ADMIN_PACKAGING_DETAIL_ERROR_MSG = '梱包が見つかりません';
lang.ADMIN_TARGETBATCHDETAIL_UPDATE_MSG = 'ターゲットバッチが更新されました';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_MSG = 'ターゲットバッチが隠されました';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG = 'ターゲットバッチが再表示されました表示に戻されました';
lang.ADMIN_TARGETBATCHDETAIL_HEADER = 'ターゲットバッチの詳細';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER = 'ターゲットバッチ確認書を隠す';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `ターゲットバッチ<strong>${name}</strong>を隠しますか?`;
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER = 'ターゲットバッチ確認書を再表示';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `ターゲットバッチ<strong>${name}</strong>を再表示しますか?`;
lang.ADMIN_TARGETBATCHDETAIL_ERROR_MSG = 'ターゲットバッチが見つかりません';
lang.ADMIN_USERLIST_CREATE_SUCCESS_MSG = '新しいユーザーが作成されました';
lang.ADMIN_USERLIST_HEADER = 'ユーザー管理';
lang.ADMIN_USERLIST_ADD_BTN = '新しいユーザーを作成';
lang.ADMIN_USERDETAIL_UPDATE_MSG = 'ユーザーが更新されました';
lang.ADMIN_USERDETAIL_DELETE_MSG = 'ユーザーが削除されました';
lang.ADMIN_USERDETAIL_DELETE_BTN = 'ユーザー削除';
lang.ADMIN_USERDETAIL_HEADER = 'ユーザーの詳細';
lang.ADMIN_USERDETAIL_CONFIRMATION_HEADER = 'ユーザー確認書を削除';
lang.ADMIN_USERDETAIL_CONFIRMATION_TEXT = (name) => `ユーザー<strong>${name}</strong>を削除しますか?`;
lang.ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG = '新しいヤード拠点が作成されました';
lang.ADMIN_YARDLOCATIONLIST_HEADER = 'ヤード拠点';
lang.ADMIN_YARDLOCATIONLIST_ADD_BTN = 'ヤード拠点を作成';
lang.ADMIN_YARDLOCATIONLIST_UPLOAD_BTN = 'ヤード拠点をアップロード';
lang.ADMIN_YARDLOCATIONLIST_NAME = 'ヤード拠点名';
lang.ADMIN_YARDLOCATIONLIST_CREATE_NEW = '新しいヤード拠点を作成';
lang.ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG = 'ヤード拠点が更新されました';
lang.ADMIN_YARDLOCATIONDETAIL_DELETE_MSG = 'ヤード拠点が削除されました';
lang.ADMIN_YARDLOCATIONDETAIL_HEADER = 'ヤード拠点の詳細';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER = '拠点を削除';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT = (name) => `拠点<strong> ${name} </strong>を削除しますか?`;
lang.ADMIN_YARDLOCATIONDETAIL_NAME = 'ヤードの拠点';
lang.ADMIN_MACHINE_LIST_HEADER = '機械';
lang.ADMIN_MACHINE_LIST_UPLOAD_BTN = '機械をアップロード';
lang.ADMIN_TARGET_BATCH_LIST_HEADER = 'ターゲットバッチ';
lang.ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN = 'ターゲットバッチをアップロード';
lang.ADMIN_PACKAGING_ADD_BTN = '梱包を追加';
lang.ADMIN_PACKAGING_UPLOAD_BTN = '梱包をアップロード';
lang.ADMIN_PACKAGING_CREATE_NEW_PACKAGING = '新しい梱包を作成';
lang.ADMIN_PACKAGING_CREATE_SUCCESS_MSG = '新しい梱包が作成されました';
lang.ADMIN_EWCLIST_HEADER = 'EWC';
lang.ADMIN_EWCLIST_ADD_BTN = 'EWCを作成';
lang.ADMIN_EWCLIST_UPLOAD_BTN = 'EWCをアップロード';
lang.ADMIN_EWCLIST_CODE = 'コード';
lang.ADMIN_EWCLIST_CREATE_NEW = '新しいEWCを作成';
lang.ADMIN_EWCLIST_CREATE_SUCCESS_MSG = '新しいEWCコードが追加されました';
lang.ADMIN_EWCDETAIL_UPDATE_MSG = 'EWCコードが更新されました';
lang.ADMIN_EWCDETAIL_HEADER = 'EWCの詳細';
lang.ADMIN_EWCDETAIL_NAME = 'コード';
lang.ADMIN_EWCDETAIL_HIDE_MSG = 'EWCが非表示にされました';
lang.ADMIN_EWCDETAIL_UNHIDE_MSG = 'EWCが再表示されました表示に戻されました';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER = 'EWC確認書を隠す';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `EWCコード<strong> ${name} </strong>を隠しますか?`;
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER = 'EWC確認書を再表示';
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `EWCコード<strong> ${name} </strong>を再表示しますか?`;
lang.ADMIN_EWCDETAIL_ERROR_MSG = 'EWCが見つかりません';
lang.ADMIN_ORIGINLIST_HEADER = '出荷元';
lang.ADMIN_ORIGINLIST_ADD_BTN = '出荷元コードを作成';
lang.ADMIN_ORIGINLIST_UPLOAD_BTN = '出荷元をアップロード';
lang.ADMIN_ORIGINLIST_CODE = 'コード';
lang.ADMIN_ORIGINLIST_CREATE_NEW = '新しい出荷元を作成';
lang.ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG = '新しい出荷元コードが追加されました';
lang.ADMIN_ORIGINDETAIL_UPDATE_MSG = '出荷元コードが更新されました';
lang.ADMIN_ORIGINDETAIL_HEADER = '出荷元の詳細';
lang.ADMIN_ORIGINDETAIL_NAME = 'コード';
lang.ADMIN_ORIGINDETAIL_HIDE_MSG = '出荷元が非表示にされました';
lang.ADMIN_ORIGINDETAIL_UNHIDE_MSG = '出荷元が再表示されました表示に戻されました';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER = '出荷元確認書を隠す';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `出荷元コード<strong> ${name} </strong>を隠しますか?`;
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER = '出荷元確認書を再表示';
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `出荷元コード<strong> ${name} </strong>を再表示しますか?`;
lang.ADMIN_ORIGINDETAIL_ERROR_MSG = '出荷元が見つかりません';
lang.ADMIN_CONTRACTS_TITLE = '契約';
lang.ADMIN_CONTRACTS_FILE_IMPORT = 'ファイルのインポート';
lang.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC = 'SFTP同期のトリガー';
lang.ADMIN_CONTRACTS_FILE_IMPORTED_MSG = 'インポートされたファイル';
lang.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG = 'SFTPインポートがトリガーされました';
lang.ADMIN_PROCESSING_UNIT_MINUTES = '£/時間';
lang.ADMIN_PROCESSING_UNIT_TONS = '£/トン';
lang.ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE = '製品バリアントの更新の確認';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT = '更新プロセスには最大数分かかる場合があります。 続行しますか？';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE = '製品バリアントが正常に更新されました';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE = 'まだ進行中の製品バリアントの更新。 2分後にページを更新します';
lang.ADMIN_REFRESH_CONFIRMATION_TITLE = (name) => `${name}の確認を更新`;
lang.ADMIN_REFRESH_SUCCESS_MESSAGE = (name) => `${name}が正常に更新されました`;
lang.ADMIN_REFRESH_CONFIRMATION_TEXT = '更新プロセスには最大数分かかる場合があります。 続行しますか？';
lang.ADMIN_REFRESH_IN_PROGRESS_MESSAGE = (name) => `${name}の更新はまだ進行中です。 2分後にページを更新します`;
lang.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE = '従業員は正常に更新されました';
lang.ADMIN_LASERNET_REPORT_NAME = 'Lasernerレポート名';
lang.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES = 'デフォルトのコピー数';
lang.ADMIN_LASERNET_DOCUMENT_TYPE = 'ドキュメントタイプ';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL = 'ドキュメントタイプの詳細';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_ID = 'ドキュメントタイプID';
lang.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG = 'Lasernetレポート名を入力してください';
lang.ADMIN_LASERNET_CONFIGURATIONS_HEADER = 'プリンターの構成';
lang.ADMIN_LASERNET_CONFIGURATION_DETAILS = '構成の詳細';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER = '削除の確認';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT = '設定を削除しますか?';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE = '確認は正常に削除されました';
lang.ADMIN_LASERNET_PRINTER_ID = 'プリンターID';
lang.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG = '新しいテナントが作成されました';
lang.SUPERADMIN_TENANTLIST_HEADER = 'テナント';
lang.SUPERADMIN_TENANTLIST_ADD_BTN = '新規追加';
lang.SUPERADMIN_TENANTLIST_GRN = 'GRNプレフィックス';
lang.SUPERADMIN_TENANTLIST_CREATE_NEW = '新しいテナントを作成';
lang.SUPERADMIN_TENANTDETAIL_UPDATE_MSG = 'テナントが更新されました';
lang.SUPERADMIN_TENANTDETAIL_DELETE_MSG = 'テナントが削除されました';
lang.SUPERADMIN_TENANTDETAIL_HEADER = 'テナントの詳細';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER = 'テナント確認書を削除';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT = (name) => `テナント<strong>${name}</strong>を削除しますか?`;
lang.SUPERADMIN_TENANTDETAIL_SITE = '地点';
lang.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS = '不足している添付ファイルを確認します';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL = 'アドバイスされた良いレベルで';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL = '配信レベルで';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION = '分類が必要';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION = '汚染が必要';
lang.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT = 'ソートレポートで上書き記述を確認';
lang.SUPERADMIN_TENANTDETAIL_DISPLAY_TARE_WEIGHT_FOR_RECEIVED_GOOD = '入荷した商品の風袋重量を表示します';
lang.SUPERADMIN_TENANTDETAIL_IS_STOCK_CODE_REQUIRED_FOR_VERIFY = '検証には銘柄コードが必要ですか';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_HEADER = 'テナント確認を非表示にする';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_HEADER = 'テナントの確認を再表示する';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `テナント <strong>${name}</strong> を非表示にしますか?`;
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `テナント <strong>${name}</strong> を再表示しますか?`;
lang.SUPERADMIN_TENANTDETAIL_HIDE_SUCCESS_MESSAGE = 'テナントは正常に非表示になりました';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_SUCCESS_MESSAGE = 'テナントの非表示が正常に解除されました';
lang.SUPERADMIN_TENANTSECTIONS_GENERAL_SECTION = 'ジェネラル';
lang.SUPERADMIN_TENANTSECTIONS_CONFIGURATION_SECTION = '設定';
lang.SUPERADMIN_TENANTSECTIONS_AG_CONFIGURATION_SECTION = 'アコンセハド ブエノ ';
lang.SUPERADMIN_TENANTSECTIONS_DELIVERY_CONFIGURATION_SECTION = 'エントレガ';
lang.SUPERADMIN_TENANTSECTIONS_REQUIRED_OPTIONS_SECTION = 'オプツィオーニ・リチェステ';
lang.SUPERADMIN_TENANTSECTIONS_INTEGRATION_SECTION = 'インテグラツィオーネ';
lang.SUPERADMIN_TENANTADMIN_UPDATE_MSG = 'テナントを更新済み';
lang.SUPERADMIN_TENANTADMIN_ADD_MSG = '管理者を追加済み';
lang.SUPERADMIN_TENANTADMIN_DELETE_MSG = '管理者を削除済み';
lang.SUPERADMIN_TENANTADMIN_TENANT = 'テナント';
lang.SUPERADMIN_TENANTADMIN_LOCATION_CODE = '拠点コード';
lang.SUPERADMIN_TENANTADMIN_REGION_CODE = '地域コード';
lang.SUPERADMIN_TENANTADMIN_GRN_VALUE = '現GRN値';
lang.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE = '現ラボシーケンス値';
lang.SUPERADMIN_TENANTADMIN_WORKFLOW = 'ワークフロー';
lang.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW = 'ワークフローを選ぶ';
lang.SUPERADMIN_TENANTADMIN_TIMEZONE = '時間帯';
lang.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE = '統合APIベース';
lang.SUPERADMIN_TENANTADMIN_ADMINS = '管理者';
lang.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN = '管理者を追加';
lang.SUPERADMIN_TENANTADMIN_NO_ADMINS = '管理者無し';
lang.SUPERADMIN_TENANTADMIN_HEADER = '管理者ユーザーを追加';
lang.SUPERADMIN_TENANTADMINLINE_HEADER = '管理者ユーザー';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE = '管理者を隠す';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE = (name) => `<strong>${name}</strong>を隠しますか?`;
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE = '管理者を再表示';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE = (name) => `<strong>${name}</strong>を再表示しますか?`;
lang.SUPERADMIN_SHAREPOINT_CHOOSE_SITE = 'Sharepointサイトを選択します';
lang.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH = '画像フォルダパス';
lang.SUPERADMIN_SHAREPOINT_SITES = 'Sharepointサイト';
lang.SUPERADMIN_APP_USERS_TITLE = 'アプリの登録';
lang.SUPERADMIN_APP_USERS_ADD_MSG = 'アプリ登録を追加';
lang.SUPERADMIN_APP_USERS_ADD_TITLE = 'アプリ登録を追加';
lang.SUPERADMIN_APP_USERS_UPDATE_MSG = 'アプリ登録を更新';
lang.SUPERADMIN_APP_USERS_RANDOM_SECRET = 'ランダムなシークレットを生成する';
lang.SUPERADMIN_APP_USERS_COPY_TEXT = 'このアプリ ユーザーを保存して閉じた後はキーを取得できなくなるため、この値をコピーします。';
lang.SUPERADMIN_CONNECTIONS_TITLE = '接続';
lang.SUPERADMIN_CONNECTIONS_UPDATE_SUCCESS_MSG = 'クライアント ID とシークレットが正常に更新されました';
lang.SUPERADMIN_CONNECTIONS_D365_DESCRIPTION = 'D365 マスターデータと YardLink API 接続';
lang.SUPERADMIN_CONNECTIONS_SHAREPOINT_DESCRIPTION = 'SharePoint Online API接続';
lang.MENU_ADMIN_TITLE = '管理';
lang.MENU_ADMIN_USER_MANAGEMENT = 'ユーザー管理';
lang.MENU_ADMIN_ACTIVITY_LOG = '活動ログ';
lang.MENU_ADMIN_STOCK_ITEMS = '在庫項目';
lang.MENU_ADMIN_SUPPLIERS = 'サプライヤー';
lang.MENU_ADMIN_ROAD_HAULIERS = '道路路上運送道路貨物輸送会社';
lang.MENU_ADMIN_YARD_LOCATION = 'ヤードの拠点';
lang.MENU_ADMIN_PRINTERS = 'プリンター';
lang.MENU_ADMIN_LASERNET_PRINTERS = 'レーザーネットプリンター';
lang.MENU_ADMIN_DOCUMENT_TYPES = '文書の種類';
lang.MENU_ADMIN_CONFIGURATIONS = '構成';
lang.MENU_ADMIN_SCALES = '秤';
lang.MENU_ADMIN_MATERIALS = '物資';
lang.MENU_ADMIN_APPLICATION = '適用';
lang.MENU_ADMIN_DELIVERY_LIST = '納入リスト';
lang.MENU_ADMIN_MACHINES = '機械';
lang.MENU_ADMIN_TARGET_BATCHES = 'ターゲットバッチ';
lang.MENU_ADMIN_PACKAGING = '梱包';
lang.MENU_ADMIN_ORIGINS = '出荷元';
lang.MENU_ADMIN_EWCS = 'EWC';
lang.MENU_SUPERADMIN_TENANTS = 'テナント';
lang.LABORATORY_ADVISEGOODS_UPDATE_MSG = 'ラボ解析 - 更新済み';
lang.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG = 'ラボ解析ステータス - 更新済み';
lang.LABORATORY_ADVISEGOODS_DELIVERIES = '納入';
lang.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION = '指定指図指図内容';
lang.LABORATORY_ADVISEGOODS_TENANT = 'テナント';
lang.LABORATORY_ADVISEGOODS_SORTING_REPORT = '分別レポート';
lang.LABORATORY_ADVISEGOODS_LAB_STATUSES = 'ラボステータス';
lang.LABORATORY_ADVISEGOODS_LAB_LIST = 'ラボリスト';
lang.LABORATORY_ANALISYS_HEADER = 'ラボ解析';
lang.LABORATORY_ANALISYS_MEASUREMENTS = '測定値';
lang.LABORATORY_ANALISYS_UPDATE_STATUS_MSG = 'ラボ解析ステータス - 更新済み';
lang.LABORATORY_ANALISYS_WET_WEIGHT = '湿重量';
lang.LABORATORY_ANALISYS_DRY_WEIGHT = '乾燥重量';
lang.LABORATORY_ANALISYS_INGOTE_WEIGHT = 'インゴット重量';
lang.LABORATORY_ANALISYS_YIELD = '歩留まり';
lang.LABORATORY_ANALISYS_SPECTRO_ANALYSIS = '分光解析';
lang.LABORATORY_PRINTER_SUCCESS_MSG = 'ページがプリンターへ送られました';
lang.LABORATORY_PRINTER_WARN_MSG = '未対応のプリンターです';
lang.LABORATORY_PRINTER_ERROR_MSG = '印刷エラーが発生しました';
lang.LABORATORY_PRINTER_LABEL = 'ラベル';
lang.LABORATORY_PRINTER_NO_PRINTERS = 'プリンター無し';
lang.LABORATORY_SCALE_CONNECTION_ERROR_MSG = '秤と接続できません';
lang.LABORATORY_SCALE_SCALE_ERROR_MSG = '秤 - エラーが発生しました';
lang.LABORATORY_SCALE_FAKE_RESULT = 'フェース結果';
lang.DELIVERIES_LABORATORY_FILTER_BY = 'フィルター基準：';
lang.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG = '新しい納入が着荷しました';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG = (name) => `納入${name}は更新されました`;
lang.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG = '指定指図指図貨物が請求されました';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED = '納入は再同期されました';
lang.DELIVERIES_FILTER_SORT_BY = (name) => `${name}で並べ替え`;
lang.DELIVERIES_FILTER_GRN_SEARCH = 'GRN検索';
lang.DELIVERIES_FILTER_ADVISED_GOOD_MSG = '指定指図指図貨物が請求されました';
lang.DELIVERIES_FILTER_DELIVERY_RESYNCED = '納入は再同期されました';
lang.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG = '納入はサインオフ済み';
lang.DELIVERIES_DELIVERYLIST_COMPLETED_MSG = '納入完了';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF = 'サインオフ';
lang.DELIVERIES_DELIVERYLIST_RESYNC_WB = '再同期 Wb';
lang.DELIVERIES_DELIVERYLIST_RESYNC_SR = '再同期 Sr';
lang.DELIVERIES_DELIVERYLIST_RESP = '対応する';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER = '納入をサインオフしますか?';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT = (grn) => `サインオフ<strong>${grn}</strong>しますか?`;
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER = '完了としてマークしますか?';
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT = (grn) => `完了<strong>${grn}</strong>しますか?`;
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER = '納入をエクスポートしますか?';
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT = (grn) => `<strong>${grn}</strong>をエクスポートしますか?`;
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER = '体重の不一致の確認';
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT = '並べ替え総重量が受信重量と一致しません。 サインオフしますか？';
lang.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG = 'エラーが発生したので、納入リストへ戻ります';
lang.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG = '納入が作成されました';
lang.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG = '添付ファイルのアップロードに失敗しました、添付ファイルを再度アップロードしてください';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER = '契約番号';
lang.DELIVERIES_NEWDELIVERYDETAILS_GRN = 'GRN番号';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW = '新しい納入を作成';
lang.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER = '納入通知番号';
lang.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER = '車両登録番号';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER = 'コンテナ番号';
lang.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER = '道路路上運送道路貨物輸送会社';
lang.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS = '添付ファイル無し';
lang.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE = '責任者';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY = '作成者';
lang.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY = 'サインオフ者';
lang.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY = '同期者';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY = '完了者';
lang.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM = '在庫項目';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION = '指図指定指図内容';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT = '指図指定指図重量';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT = 'アドバンス 重量（総）';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT = 'アドバンス 重量（ネット）';
lang.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION = 'ヤードの拠点';
lang.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT = '総重量';
lang.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT = '自重';
lang.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET = 'WBチケット番号';
lang.DELIVERIES_NEWDELIVERYDETAILS_NET = '正味';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER = '変更を保存しますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT = '<strong>変更を保存</strong>しますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER = '完了としてマークしますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT = '納入を<strong>完了</strong>としてマークしますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = '添付ファイル無しで保存しますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = '<strong>添付ファイル無しで</strong>納入を保存しますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER = '証明書期限切れのままで保存しますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT = 'サプライヤー証明書の有効期限が切れているか、紛失しています。 とにかく配達を節約したいですか？';
lang.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN = '出荷元';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER = '期限切れ許可状態で保存しますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT = '道路路上運送道路貨物輸送会社の許可がないか期限切れです。納入をともかく保存しますか?';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE = '取引タイプ';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE = '買掛金';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER = '転送';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS = '拒否の返却';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID = 'ボイド';
lang.DELIVERIES_DELIVERYDETAILSLINE_HEADER = 'アドバイス商品詳細';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION = '指定指図指図内容';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT = '指定指図指図重量';
lang.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE = '注文ラインシーケンス';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX = '行番号';
lang.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG = '納入は更新されました';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG = '添付ファイルは更新されました!';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG = '添付ファイルのアップロードに失敗しました、添付ファイルを再度アップロードしてください';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG = '添付ファイルが削除されました';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION = '添付ファイルの確認を削除する';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION = 'この添付ファイルを削除しますか？';
lang.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG = (deliveryGrn) => `納入<strong>${deliveryGrn}<strong/>の指定指図指図貨物は請求済みです`;
lang.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG = (codes) => `在庫コード[${codes}]がdOpsのどの在庫コードとも一致しません。管理者に連絡してください`;
lang.DELIVERIES_DELIVERYEDIT_DELETE = '配信を削除しますか？';
lang.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION = '配信を削除しますか？ 将来的に復元することはできません。';
lang.DELIVERIES_DELIVERYEDIT_DELETED = (name) => `配信${name}が削除されました`;
lang.ADVISEDGOOD_ROUTE_EWC_NUMBER = 'EWC番号';
lang.ADVISEDGOOD_ROUTE_DRIVER = 'ドライバー';
lang.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG = '指定指図指図貨物は更新済み';
lang.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED = (entity) => `で重量は更新済み: ${entity}`;
lang.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG = '指定指図指図貨物はフラグ付き';
lang.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG = '指定指図指図貨物はフラグ解除されました';
lang.ADVISEDGOOD_ROUTE_COMPLETED_MSG = '指定指図指図貨物は完了しています';
lang.ADVISEDGOOD_ROUTE_VERIFIED_MSG = '指定指図指図貨物は確認済み';
lang.ADVISEDGOOD_ROUTE_LAB_MSG = 'ラボ解析は正常に作成されました';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG = '指定指図指図貨物を保存してからでないと移転を作成できません';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG = '指定指図指図貨物を保存してからでないと新しいラボ解析を作成できません';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG = '指定指図指図貨物を保存してからでないと印刷できません';
lang.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK = 'ステータス撤回';
lang.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION = '車両登録';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT = '総重量';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT = '自重';
lang.ADVISEDGOOD_ROUTE_NET_WEIGHT = '正味重量';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED = '総重量が必要です';
lang.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT = '着荷重量';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED = '自重が必要';
lang.ADVISEDGOOD_ROUTE_SORTING_REPORT = '分別レポート';
lang.ADVISEDGOOD_ROUTE_BALANCED = '均衡状態';
lang.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG = 'Zipファイルをダウンロードしました';
lang.ADVISEDGOOD_ROUTE_PICTURES = '写真';
lang.ADVISEDGOOD_ROUTE_NO_PICTURE = '写真無し';
lang.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES = '写真のアップロード中です…';
lang.ADVISEDGOOD_ROUTE_COMMENT = 'コメント';
lang.ADVISEDGOOD_ROUTE_NO_COMMENT = 'ノーコメント';
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER = (hasFlag) => `${hasFlag}の確認`;
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `この指定指図指図貨物を<strong class=${className}>${hasFlag}</strong>しますか?`;
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER = '変更を保存しますか?';
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT = '<strong>変更を保存</strong>しますか?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER = '完了としてマークしますか?';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT = 'この指定指図指図貨物を<strong>完了</strong>としてマークしますか?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER = '確認済みとしてマーク済み';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT = 'この指定指図指図貨物を<strong>確認済み</strong>としてマークしますか?';
lang.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT = '.分別合計重量がトラックの秤から得た着荷重量より大きいです。確認するには、分別重量をプラットフォームの秤で確認したかチェックしてください。';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER = '撤回して請求済み状態に戻しますか?';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT = 'ステータスを撤回して<strong>請求済み</strong>に戻しますか?';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER = 'アンロード状態にロールバックしますか？';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT = 'ステータスを<strong>アンロード</strong>にロールバックしますか？';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_ERROR = '添付ファイルを追加してください';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_WARNING = 'アドバイスされた添付ファイルなしでアンロードしてもよろしいですか?';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS = '分別合計重量(総)';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET = '分別合計重量(正味)';
lang.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG = '総重量が更新されました';
lang.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG = '着荷重量が更新されました';
lang.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG = 'EWC番号が更新されました';
lang.ADVISEDGOOD_ROUTE_DELIVERY_TYPE = '納入タイプ';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER = '荷下ろし拠点を確認';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT = '荷下ろしのヤード拠点を選択';
lang.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION = '荷下ろし拠点';
lang.ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR = '指定指図指図貨物は分別合計重量が着荷重量より大きいので確認不可';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER = '指定指図指図貨物の請求解除';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT = '指定指図指図貨物を請求解除しますか?';
lang.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG = '指定指図指図貨物は請求解除されました';
lang.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE = '着荷貨物個数は10個まで';
lang.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT = 'トラック着荷重量 （総）';
lang.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL = 'トラック分別合計（総）';
lang.ADVISEDGOOD_ROUTE_PACKAGING = '包装';
lang.ADVISEDGOOD_ROUTE_NO_PACKAGING = 'パッケージなし';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG = 'AG をサインオフする';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER = 'AG を承認しますか?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT = 'AG をサインオフしますか?';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE = 'AG は正常に署名されました';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = '添付ファイルなしで保存しますか？';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'アドバイスされた良い<strong>添付ファイルなし</strong>を保存しますか？';
lang.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT = 'アイテムの説明を上書きしますか?';
lang.ADVISEDGOOD_ROUTE_DELETE = '削除することをお勧めします。';
lang.ADVISEDGOOD_ROUTE_DELETE_CONFIRMATION = 'Advised Good を削除しますか?将来的には復元できなくなります。';
lang.ADVISEDGOOD_ROUTE_DELETED = (name) => `アドバイスグッドは削除されました${name} `;
lang.ADVISEDGOOD_SORTINGREPORT_HEADER = '分別レポートの詳細';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG = '着荷貨物は移転重量が現重量より重いので移転不可';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG = '着荷貨物は移転済み';
lang.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE = '在庫コード';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER = '移転';
lang.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS = 'ラボ解析';
lang.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS = 'ラボ解析は要請されていない';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL = '物資';
lang.ADVISEDGOOD_SORTINGREPORT_WI_DONE = 'WI完了';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER = '新しいラボ解析を作成';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT = '新しいラボ解析を作成しますか?';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER = 'ラボ解析を削除';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT = (labAnalysisName) => `<strong>${labAnalysisName}</strong>要請を削除しますか?`;
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE = '機械';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH = 'ターゲットバッチ';
lang.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY = '嵩密度';
lang.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY = '特定された品質';
lang.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE = '主なタイプ';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION = '物資の内容';
lang.ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG = (emptyRequiredField) => `保存する前に<strong>${emptyRequiredField}</strong>を記入してください`;
lang.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG = '写真を追加する前に指定指図指図貨物を保存してください';
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE = '拠点変更';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION = '目的地拠点';
lang.ADVISEDGOOD_LABANALYSIS_LAB_REMARKS = 'ラボ所見';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER = '写真の確認書を削除';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT = 'この写真を削除しますか?';
lang.ADVISEDGOOD_DEDUCTION_FREE_WATER = '遊離水';
lang.ADVISEDGOOD_DEDUCTION_FREE_OIL = '遊離オイル';
lang.ADVISEDGOOD_DEDUCTION_FREE_EMULSION = '遊離エマルジョン';
lang.ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE = '遊離雪/氷';
lang.ADVISEDGOOD_DEDUCTION_NO_DEDUCTION = '控除無し';
lang.ADVISEDGOOD_DEDUCTION_DEDUCTION = '控除';
lang.ADVISEDGOOD_DEDUCTION_MODAL_HEADER = '指定指図指図貨物の荷下ろし済み確認';
lang.ADVISEDGOOD_UNLOAD_TITLE = '荷下ろし済み';
lang.ADVISEDGOOD_UNLOAD_UPDATE_MSG = '指定指図指図貨物の荷下ろし完了';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE = '負の重量の確認';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT = '材料の重量がマイナスです。 確認してください';
lang.RECEIVED_GOOD_FLAG_ERROR_MSG = '受け取った良いものはフラグが立てられました';
lang.RECEIVED_GOOD_FLAG_SUCCESS_MSG = '受け取った商品はフラグが立てられていませんでした';
lang.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `<strong class=${className}> ${hasFlag} </ strong>これは好評でしたか？`;
lang.RECEIVED_GOOD_CONTAMINATION_TITLE = '汚染';
lang.RECEIVED_GOOD_CONTAMINATION_OTHER = 'その他';
lang.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION = '汚染無し';
lang.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH = '非金属属性';
lang.RECEIVED_GOOD_CONTAMINATION_WATER = '水(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FREE_OIL = '遊離油(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIRT = '泥(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_WOOD = '木材(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PLASTIC = 'プラスチック(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL = 'ガラスウール(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG = '燃焼スラグ(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BITUMEN = 'タール(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_OIL_CREASE = '属性オイル/グリース(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CARDBOARD = 'ボール紙(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH = '金属性属性';
lang.RECEIVED_GOOD_CONTAMINATION_IRON = '鉄(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MN_STEEL = 'マンガン鋼(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL = 'CrFe 10 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL = 'CrFe 13 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_301 = '301 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL = 'CrFe 17 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_SHREDDER = 'シュレッダー(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT = '焼却炉物資(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS = '各種金属';
lang.RECEIVED_GOOD_CONTAMINATION_CU = 'Cu (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_AL = 'Al (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MS = 'Ms (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_PB = 'Pb (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TI = 'Ti (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER = 'StSt/Cuクーラー (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING = 'シュレッド向け物資(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS = '固形物内の研削屑';
lang.RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR = 'CrFe 研削屑 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR = '低合金研削屑(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS = 'StSt 研削屑 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_304_TURNINGS = '304 研削屑 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_316_TURNINGS = '316 研削屑 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE = '炉のサイズに不適合';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR = '移動型剪断用 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR = '定置型剪断用 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING = 'バーナー切断用 (kgs)';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_PRESS = 'プレス処理用 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY = '集計不要 (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES = '中空部分を含む (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS = '物資の形態';
lang.RECEIVED_GOOD_CONTAMINATION_PACKETS = '小束(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_BRIQUETTES = 'ブリケット (kg)';
lang.RECEIVED_GOOD_CONTAMINATION_INGOTS = 'インゴット(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL = '粉体(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_RUNNINGS = '流出物(kg)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND = '自重（入荷）';
lang.RECEIVED_GOOD_CONTAMINATION_TOTAL = '合計(pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_DRUMS = 'バレル(pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_BOXES = '箱(pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_GRID_BOXES = 'グリッドボックス(pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_BIGBAGS = '大袋(pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_PALLETS = 'パレット(pcs)';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND = '自重（出荷）';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION = '研削屑組成';
lang.RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT = 'マグネット成分';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING = '金属研削屑';
lang.RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING = '研磨研削屑';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES = 'サイズ';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_0_15 = 'サイズ 0-15 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_16_35 = 'サイズ 16-35 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_36_60 = 'サイズ 36-60 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_61_80 = 'サイズ 61-80 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_81_120 = 'サイズ 81-120 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_121_150 = 'サイズ 121-150 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_151_999 = 'サイズ 150-999 cm';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING = '合計が100%でない';
lang.RECEIVED_GOOD_CONTAMINATION_PACKAGING = '梱包';
lang.SORTINGREPORT_CLASSIFICATION_TITLE = '分類';
lang.SORTINGREPORT_CLASSIFICATION_VERY_LIGHT = '超軽量';
lang.SORTINGREPORT_CLASSIFICATION_LIGHT = '軽量';
lang.SORTINGREPORT_CLASSIFICATION_MIDDLE = '中重量';
lang.SORTINGREPORT_CLASSIFICATION_HEAVY = '重い';
lang.SORTINGREPORT_CLASSIFICATION_VERY_HEAVY = '非常に重い';
lang.SORTINGREPORT_CLASSIFICATION_FE_SDS = 'Fe-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CR_SDS = 'Cr-SDS混合';
lang.SORTINGREPORT_CLASSIFICATION_V13F_SDS = 'V13F-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS = '1.4510-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS = '1.4512-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS = '17er Cr-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS = '1.4016-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS = '1.4509-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_304_SDS = '304-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_SDS = 'CrNi-SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS = 'CrNiMo-SDS';
lang.SORTINGREPORT_CLASSIFICATION_FE_TUR = 'Fe-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR = 'Cr-/CrNi-TUR混合';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_TUR = 'CrNi-TUR';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR = 'CrNiMo-TUR';
lang.SORTINGREPORT_CLASSIFICATION_OTHER = 'その他';
lang.SORTINGREPORT_CLASSIFICATION_SHREDDER = 'シュレッダー';
lang.SORTINGREPORT_CLASSIFICATION_SOLIDS = '固形物';
lang.SORTINGREPORT_CLASSIFICATION_TURNINGS = '研削屑';
lang.SORTINGREPORT_CLASSIFICATION_PACKAGE = '梱包';
lang.SORTINGREPORT_CLASSIFICATION_BRIKETTS = 'ブリケット';
lang.SORTINGREPORT_CLASSIFICATION_INGOTS = 'インゴット';
lang.SORTINGREPORT_CLASSIFICATION_FINES = '粉体';
lang.SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION = '廃棄物焼却';
lang.SORTINGREPORT_CLASSIFICATION_RUNNINGS = '流出物';
lang.SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP = '解体残骸';
lang.SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP = '生産残骸';
lang.SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP = '回収残骸';
lang.SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP = '剪断残骸';
lang.SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS = '固形物を含む研削屑';
lang.SORTINGREPORT_PROCESSING_TIME_TITLE = '処理時間';
lang.SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS = '切断時間曲げ/破砕';
lang.SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS = 'スパイダー時間';
lang.SORTINGREPORT_PROCESSING_TIME_CART_HOURS = 'カート時間';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS = '操業時間';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES = '議事録';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TONS = 'トン';
lang.SORTINGREPORT_PROCESSING_TITLE = '処理';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR = 'オペレーター';
lang.SORTINGREPORT_PROCESSING_TIME_FORKLIFT = 'フォークリフト';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE = 'クレーン';
lang.SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING = 'トーチカッティング';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA = 'プラズマ';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR = 'クレーン剪断機';
lang.SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR = 'ギロチンせん断';
lang.SORTINGREPORT_PROCESSING_TIME_DENSIFIER = 'デンシファイア';
lang.SORTINGREPORT_PROCESSING_TIME_BALER = 'ベーラー';
lang.SORTINGREPORT_MEASUREMENT_TITLE = '測定値';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_WET = '湿サンプル(g)';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY = '乾燥サンプル(g)';
lang.SORTINGREPORT_MEASUREMENT_HUMIDITY = '湿度(%)';
lang.SORTINGREPORT_MEASUREMENT_FOR_MELTING = '溶融向け(g)';
lang.SORTINGREPORT_MEASUREMENT_INGOT = 'インゴット(g)';
lang.SORTINGREPORT_MEASUREMENT_YIELD = '歩留まり(%)';
lang.SORTINGREPORT_MEASUREMENT_CONTAINER = '容器';
lang.SORTINGREPORT_LASERNET_COPIES = 'コピー';
lang.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE = (numberOfCopies) => `${numberOfCopies} 枚のラベルがキューに入れられており、1 分間で印刷されます`;
lang.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE = 'プリンターのセットアップ エラーです。 管理者に連絡してください';
lang.STATUS_LABINPUT_PRE_SAMPLE = '事前サンプル';
lang.STATUS_LABINPUT_SAMPLE = 'サンプル';
lang.STATUS_LABINPUT_RE_SAMPLE = '再サンプル';
lang.STATUS_LABINPUT_SORTATION = '分別';
lang.STATUS_LABINPUT_NITON_ANALYSIS = '二トン解析';
lang.STATUS_LABSTATUSES_IN_LAB = '在ラボ';
lang.STATUS_LABSTATUSES_DRYING = '乾燥中';
lang.STATUS_LABSTATUSES_MELTING = '溶融中';
lang.STATUS_LABSTATUSES_MELTED = '溶融済み';
lang.STATUS_LABSTATUSES_COMPLETE = '完了';
lang.STATUS_LABSTATUSES_REQUESTED = '要請済み';
lang.STATUS_LABSTATUSES_SAMPLED = 'サンプル済み';
lang.STATUS_LABSTATUSES_IN_PROGRESS = '進行中';
lang.STATUS_DELIVERYSTATUSES_IN_WORK = '進行中';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2 = 'ティア2準備完了';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF = 'サインオフ準備完了';
lang.STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF = '部分的に承認されました';
lang.STATUS_DELIVERYSTATUSES_SIGNED_OFF = 'サインオフ済み';
lang.STATUS_DELIVERYSTATUSES_COMPLETE = '完了';
lang.STATUS_ADVISEDGOOD_WAITING = '待機中';
lang.STATUS_ADVISEDGOOD_CLAIMED = 'ソート';
lang.STATUS_ADVISEDGOOD_UNLOADED = '荷下ろし済み';
lang.STATUS_ADVISEDGOOD_IN_LAB = '在ラボ';
lang.STATUS_ADVISEDGOOD_READY_FOR_TIER_2 = 'ティア2準備完了';
lang.STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF = 'サインオフ準備完了';
lang.STATUS_ADVISEDGOOD_SIGNED_OFF = 'サインオフ済み';
lang.STATUS_ADVISEDGOOD_COMPLETE = '完了';
lang.DASHBOARD_TITLES_DASHBOARD = 'ダッシュボード';
lang.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS = '進行中の配信';
lang.DASHBOARD_TITLES_DELIVERY_BY_STATUS = 'ステータス別納入';
lang.DASHBOARD_TITLES_AVERAGE_SORTING_TIME = '平均ソート時間';
lang.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME = '平均所要時間';
lang.DASHBOARD_TITLES_SORTING_TIME_TREND = '最終配達のソート時間';
lang.DASHBOARD_TITLES_FLAGGED_ITEMS = 'フラグ付き項目';
lang.DASHBOARD_TITLES_TREES_SAVED = '節約樹木数';
lang.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL = 'ソート時間（時間）';
lang.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL = '所要時間（時間）';
lang.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP = 'ソート時間';
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTHS = (condition) => `進行中の ${condition} か月`;
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTH = (condition) => `進行中の${condition}月`;
lang.DASHBOARD_TITLES_IN_PROGRESS_WEEK = (condition) => `進行中の${condition}週`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS = (condition) => `フラグが立てられたアイテム${condition}か月`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH = (condition) => `フラグ付きアイテム${condition}月`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK = (condition) => `フラグが立てられたアイテム${condition}週`;
lang.USA_CONFIGURATION_CONFIGURATION = '構成';
lang.USA_CONFIGURATION_SHOW_CONTRACT_NUMBER = '契約番号を表示';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN = '指定指図指図貨物明細を表示';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE = '指定指図指図貨物の自重を表示';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION = '指定指図指図貨物の拠点を表示';
lang.USA_CONFIGURATION_SHOW_DELIVERY_GROSS = '納入総重量/自重/正味重量を表示';
lang.USA_CONFIGURATION_SHOW_DELIVERY_TYPE = '納入タイプを表示';
lang.USA_CONFIGURATION_SHOW_RADIATION_FILE = '放射線ファイルアップロード';
lang.USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS = '気象条件を表示';
lang.USA_CONFIGURATION_STOCK_CODE = '在庫コードの形式';
lang.USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE = '計量台の公差';
lang.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR = '放射線ファイルのアップロードに失敗しました、放射線ファイルを再度アップロードしてください';
lang.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG = '放射線ファイルは必須';
lang.USA_CONFIGURATION_WEATHER_CONDITIONS = '気象条件';
lang.USA_CONFIGURATION_DELIVERY_TYPE = '納入タイプ';
lang.USA_CONFIGURATION_GROSS = '総重量';
lang.USA_CONFIGURATION_TARE = '自重';
lang.USA_CONFIGURATION_NET = '正味';
lang.USA_CONFIGURATION_RADIATION_FILE = '放射線ファイル';
lang.USA_CONFIGURATION_NO_RADIATION_FILE = '放射線ファイル無し';
lang.USA_CONFIGURATION_ADV_TARE = '指定指図指図自重';
lang.PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED = '新しい配達が作成されました';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF = 'サインオフの準備ができた配信';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF = '配送は承認されました';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER = 'トレーダーが配達を完了';

export default lang;
