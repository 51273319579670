import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { Button, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';

import UserModel from 'models/UserModel';
import ModalConfirmation from 'domain/ModalConfirmation';
import TranslateService from 'services/TranslateService';
import CommonAdminDetailComponent from 'pod/admin/commonComponents/CommonAdminDetailComponent';
import TenantModel from 'models/TenantModel';
import AdminDetailComponent from 'pod/superAdmin/admins/AdminDetailComponent';
import UserTenantModel from 'models/UserTenantModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IProps {
  saveAction: (tenantAdmin: UserModel<IUserModelConstructObj>) => Promise<void>;
  hideAdmin: (user: UserModel<IUserModelConstructObj>) => Promise<void>;
  unhideAdmin: (user: UserModel<IUserModelConstructObj>) => Promise<void>;
  tenantAdmin: UserModel<IUserModelConstructObj>;
  translateService?: TranslateService;
  workflowTenants?: UserTenantModel[];
  selectedTenant?: TenantModel;
}

class TenantAdminLineComponent extends React.Component<IProps> {
  private _isModalOpen: boolean = false;
  private _hideConfirmationModal: boolean = false;
  private _unhideConfirmationModal: boolean = false;

  public saveAndToggleModal = async (tenantAdmin: UserModel<IUserModelConstructObj>) => {
    this.props
      .saveAction(tenantAdmin)
      .then(() => {
        this._toggleModal();
      })
      .catch(() => null);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable<
      TenantAdminLineComponent,
      | '_isModalOpen'
      | '_hideConfirmationModal'
      | '_unhideConfirmationModal'
      | '_toggleModal'
      | '_toggleHideConfirmationModal'
      | '_toggleUnhideConfirmationModal'
    >(this, {
      _isModalOpen: observable,
      _hideConfirmationModal: observable,
      _unhideConfirmationModal: observable,
      _toggleModal: action,
      _toggleHideConfirmationModal: action,
      _toggleUnhideConfirmationModal: action,
    });
  }

  public render() {
    const {
      tenantAdmin,
      translateService: { t },
    } = this.props;

    return (
      <tbody key={tenantAdmin.id}>
        <tr className={classnames(['pointer', { 'bg-secondary': !tenantAdmin.active }])}>
          <td>{tenantAdmin.fullName}</td>
          <td className="text-end">
            {this._renderHideUnhideButton}
            <Button
              data-test="info-button"
              type="button"
              color="success"
              className="width-38"
              onClick={this._toggleModal}
            >
              <FontAwesome name="info" />
            </Button>

            {/* MODAL - SORTING REPORT DETAIL MODAL */}
            {this._isModalOpen && (
              <Modal data-test="details-modal" isOpen={this._isModalOpen} toggle={this._toggleModal} backdrop="static">
                <ModalHeader toggle={this._toggleModal}>{t.SUPERADMIN_TENANTADMINLINE_HEADER}</ModalHeader>
                <ModalBody className="ps-0 pe-0 pt-0">
                  <Container className="pt-3">
                    <CommonAdminDetailComponent content={tenantAdmin} saveAction={this.saveAndToggleModal}>
                      <AdminDetailComponent
                        content={tenantAdmin}
                        tenants={this.props.workflowTenants}
                        selectedTenant={this.props.selectedTenant}
                      />
                    </CommonAdminDetailComponent>
                  </Container>
                </ModalBody>
              </Modal>
            )}

            <ModalConfirmation
              data-test="hide-confirm-modal"
              buttonYesColor="success"
              callbackYes={() => this.props.hideAdmin(this.props.tenantAdmin)}
              heading={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE}
              ico="eye-slash"
              isOpen={this._hideConfirmationModal}
              text={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE(tenantAdmin.fullName)}
              toggleModal={this._toggleHideConfirmationModal}
            />

            <ModalConfirmation
              data-test="unhide-confirm-modal"
              buttonYesColor="success"
              callbackYes={() => this.props.unhideAdmin(this.props.tenantAdmin)}
              heading={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE}
              ico="eye"
              isOpen={this._unhideConfirmationModal}
              text={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE(tenantAdmin.fullName)}
              toggleModal={this._toggleUnhideConfirmationModal}
            />
          </td>
        </tr>
      </tbody>
    );
  }

  private get _renderHideUnhideButton() {
    return this.props.tenantAdmin.active ? (
      <Button
        data-test="hide-button"
        type="button"
        color="danger"
        onClick={this._toggleHideConfirmationModal}
        className="width-38 me-3"
      >
        <FontAwesome name="eye-slash" />
      </Button>
    ) : (
      <Button
        data-test="unhide-button"
        type="button"
        color="success"
        onClick={this._toggleUnhideConfirmationModal}
        className="width-38 me-3"
      >
        <FontAwesome name="eye" />
      </Button>
    );
  }

  private _toggleModal = () => {
    this._isModalOpen = !this._isModalOpen;
  };

  private _toggleHideConfirmationModal = () => {
    this._hideConfirmationModal = !this._hideConfirmationModal;
  };

  private _toggleUnhideConfirmationModal = () => {
    this._unhideConfirmationModal = !this._unhideConfirmationModal;
  };
}

export default inject('translateService')(observer(TenantAdminLineComponent));
