/* global document */

require('es6-shim');
import 'babel-polyfill';

import 'react-image-lightbox/style.css';
import '../assets/sass/stylesheet.scss';

import * as React from 'react';
import * as mobx from 'mobx';
import { createInjectables } from './injectables';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { default as Routes } from 'routes';
import { render } from 'react-dom';
import { Route } from 'react-router';
import bugsnagClient from 'util/bugsnag';
import bugsnagReact from '@bugsnag/plugin-react';
import ErrorFallback from 'domain/ErrorFallback';

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

mobx.configure({ enforceActions: 'observed' });
const reactRoot = document.getElementById('reactroot');

// CHECK, IF IS ANDROID
window.isAndroid = /Android/gi.test(navigator.userAgent);

// ADD CLASS isAndroid TO BODY, FOR CSS
if (window.isAndroid) {
  document.getElementsByTagName('body')[0].className = 'isAndroid';
}

render(
  <Provider {...createInjectables()}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router>
        <Route component={Routes} />
      </Router>
    </ErrorBoundary>
  </Provider>,
  reactRoot
);
