import { action, observable, computed, makeObservable } from 'mobx';
import RootService from 'services/RootService';
import IdNameModel from 'models/IdNameModel';
import FilterAbstractStore from 'stores/FilterAbstractStore';

export interface ITenantFilterStore {
  onTenantChange: (tenantId: string) => void;
}

export interface ITenantFilters {
  tenantId: boolean;
}

export interface ITenantQueryParams {
  tenantId: string;
}

export default class TenantFilterStore extends FilterAbstractStore<ITenantFilters, ITenantQueryParams> {
  public tenants: IdNameModel[] = [];

  public tenantId: string = '';

  constructor(private readonly rootService: RootService, private readonly instance: ITenantFilterStore) {
    super();

    makeObservable(this, {
      tenants: observable,
      tenantId: observable,
      getFiltersApplied: computed,
      getQueryParams: computed,
      changeTenant: action,
      setTenants: action,
    });
  }

  public get getFiltersApplied(): ITenantFilters {
    return {
      tenantId: this.tenantId !== '',
    };
  }

  public get getQueryParams(): ITenantQueryParams {
    return {
      tenantId: this.tenantId,
    };
  }

  public async getTenantsByRegion(): Promise<IdNameModel[]> {
    const tenantsInRegion = await this._getTenantsByRegionRequest().then((res: IdNameModel[]) =>
      res.map((t: IdNameModel) => new IdNameModel().update(t))
    );

    this.setTenants(tenantsInRegion);

    return this.tenants;
  }

  public changeTenant = (tenantId: string) => {
    this.tenantId = tenantId;
    this.instance.onTenantChange(tenantId);
  };

  public setTenants(tenants: IdNameModel[]) {
    this.tenants = tenants;
  }

  private _getTenantsByRegionRequest = (): Promise<IdNameModel[]> => this.rootService.ajaxService.get('tenants/user');
}
