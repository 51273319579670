import * as React from 'react';
import { throttle } from 'lodash';

interface IUseWindowWidthState {
  windowWidth?: number;
}

const useWindowWidth = <P extends object>(WrappedComponent: React.ComponentType<P>): React.ComponentClass<P> => {
  return class BadgedComponent extends React.Component<P, IUseWindowWidthState> {
    public readonly state: IUseWindowWidthState = { windowWidth: window.innerWidth };

    public componentDidMount(): void {
      window.addEventListener('resize', this._changeWindowWidth);
    }

    public componentWillUnmount(): void {
      window.removeEventListener('resize', this._changeWindowWidth);
    }

    public render() {
      return <WrappedComponent {...this.props} windowWidth={this.state.windowWidth} />;
    }

    private _changeWindowWidth = throttle(() => this.setState({ windowWidth: window.innerWidth }), 16);
  };
};

export default useWindowWidth;
