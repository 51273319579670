import { action, computed, makeObservable, observable, observe, reaction } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';
import ValidateModel from 'models/ValidateModel';
import LabAnalysisModel from 'models/LabAnalysisModel';
import MaterialsModel from 'models/MaterialsModel';
import { autoAddNewLine, IShouldAddNewLine, IShouldRemoveLine } from 'util/mobx-utils';
import MaterialsListModel from 'models/MaterialsListModel';
import MaterialModel from 'models/MaterialModel';
import KeyValueModel, { IKeyValueModelConstructObject } from 'models/KeyValueModel';
import { CONTAMINATION, HAS_CHANGED, PROCESSING_TIME } from 'util/constants';
import FileModel from 'models/FileModel';
import IdNameModel from 'models/IdNameModel';
import IdNameActiveModel from 'models/IdNameActiveModel';
import { ContaminationSectionType, CountryCode } from 'util/enums';
import MeasurementModel, { IMeasurementModelSaveObj } from 'models/MeasurementModel';
import { ISetHasChangedAndClearIsDirty } from 'util/objectUpdater';
import StockItemModel from 'models/StockItemModel';

export interface IReceivedGoodsModelConstructSaveObj {
  description: string;
  grossWeight: number;
  tareWeight: number;
  id: string;
  materials: MaterialsModel[];
  remarks: string;
  stockCode: string;
  contamination?: {
    nonMetallicAttach: IKeyValueModelConstructObject[];
    metallicAttach: IKeyValueModelConstructObject[];
    differentMetals: IKeyValueModelConstructObject[];
    turningsInSolids: IKeyValueModelConstructObject[];
    notInFurnaceSize: IKeyValueModelConstructObject[];
    materialForms: IKeyValueModelConstructObject[];
    tareInbound: IKeyValueModelConstructObject[];
    tareOutbound: IKeyValueModelConstructObject[];
    turningsComposition: IKeyValueModelConstructObject[];
    packaging: IKeyValueModelConstructObject[];
    sizes: IKeyValueModelConstructObject[];
  };
  noContamination: boolean;
  files?: Array<{ id: string }>;
  targetLocationId: string;
  machineId: string;
  targetBatchId: string;
  bulkDensity: string;
  foundQuality: string;
  mainType: string;
  materialDescription: string;
  wiDone: boolean;
  processingTimes?: IKeyValueModelConstructObject[];
  measurement?: IMeasurementModelSaveObj;
  productFormId?: string;
  productQualityId?: string;
}

export interface IContamination {
  nonMetallicAttach: KeyValueModel[];
  metallicAttach: KeyValueModel[];
  differentMetals: KeyValueModel[];
  turningsInSolids: KeyValueModel[];
  notInFurnaceSize: KeyValueModel[];
  materialForms: KeyValueModel[];
  tareInbound: KeyValueModel[];
  tareOutbound: KeyValueModel[];
  turningsComposition: KeyValueModel[];
  packaging?: KeyValueModel[];
  sizes?: KeyValueModel[];
}

interface IReceivedGoodsModelValidationKeys {
  description: boolean;
  grossWeight: boolean;
  contamination: boolean;
  wiDone: boolean;
  bulkDensity: boolean;
  foundQuality: boolean;
  mainType: boolean;
  materialDescription: boolean;
  stockItemCode: boolean;
  productForm: boolean;
  productQuality: boolean;
}

export type ReceivedGoodsValidatorKey = keyof Partial<IReceivedGoodsModelValidationKeys>;

export interface IReceivedGoodsParams {
  rgClassificationsSectionRequired?: boolean;
  rgContaminationsSectionRequired?: boolean;
  countryCode: CountryCode;
}

export default class ReceivedGoodsModel
  extends ValidateModel<IReceivedGoodsModelValidationKeys, MaterialModel[], IReceivedGoodsParams>
  implements IShouldAddNewLine, IShouldRemoveLine, ISetHasChangedAndClearIsDirty {
  constructor(private readonly _receivedGoodsParams: IReceivedGoodsParams) {
    super();
    makeObservable<ReceivedGoodsModel, '_createContaminationPackagingSectionItem'>(this, {
      advisedGoodId: observable,
      description: observable,
      grossWeight: observable,
      tareWeight: observable,
      contamination: observable,
      attachments: observable,
      labInputs: observable,
      materials: observable,
      remarks: observable,
      stockCode: observable,
      stockItem: observable,
      createdAt: observable,
      id: observable,
      index: observable,
      rgTitle: observable,
      targetLocation: observable,
      wiDone: observable,
      machine: observable,
      targetBatch: observable,
      bulkDensity: observable,
      foundQuality: observable,
      mainType: observable,
      materialDescription: observable,
      noContamination: observable,
      processingTimes: observable,
      measurement: observable,
      flagged: observable,
      wasFlagged: observable,
      productForm: observable,
      productQuality: observable,
      createContamination: action,
      createAllSectionOfContamination: action,
      createAllProcessingTimeItems: action,
      contaminationIsDirty: computed,
      combineContamination: computed,
      processingTimeIsDirty: computed,
      measurementIsDirty: computed,
      isDirty: computed,
      netWeight: computed,
      shouldAddNewLine: computed,
      shouldRemoveLine: computed,
      hasItems: computed,
      isFilledOut: computed,
      validators: computed,
      update: action,
      pushNewAttachment: action,
      removeAttachment: action,
      createDefaultMaterials: action,
      replaceLabInputs: action,
      setDescription: action,
      setStockCode: action,
      setStockItem: action,
      setGrossWeight: action,
      setTareWeight: action,
      setRemarks: action,
      clearIsDirty: action,
      deleteLabInputById: action,
      hasLabInputWithName: action,
      setTargetLocation: action,
      setMachine: action,
      setTargetBatch: action,
      setBulkDensity: action,
      setFoundQuality: action,
      setMainType: action,
      setMaterialDescription: action,
      setWiDone: action,
      setFlagged: action,
      setWasFlagged: action,
      setProductForm: action,
      setProductQuality: action,
      setNoContamination: action,
      constructSaveObj: action,
      constructKeyValueModelArray: action,
      addContaminationPackagingSectionItems: action,
      _createContaminationPackagingSectionItem: action,
    });
    observe(this, this.onChange);
    reaction(
      () => this.stockItem?.id,
      () => {
        this.setProductForm(null);
      }
    );
    reaction(
      () => this.productForm,
      () => {
        this.setProductQuality(null);
      }
    );
  }

  public generalValidatorKeys: Array<keyof Partial<IReceivedGoodsModelValidationKeys>> = ['description', 'grossWeight'];

  public validationKeysByCountryCode: Map<
    CountryCode,
    Array<keyof Partial<IReceivedGoodsModelValidationKeys>>
  > = new Map<CountryCode, Array<keyof Partial<IReceivedGoodsModelValidationKeys>>>([
    [CountryCode.US, this.generalValidatorKeys],
    [CountryCode.IT, this.generalValidatorKeys],
    [
      CountryCode.DE,
      this.generalValidatorKeys.concat([
        'wiDone',
        ...this._getContaminationValidationFields(CountryCode.DE),
        ...this._getClassificationValidationFields(CountryCode.DE),
      ] as Array<keyof Partial<IReceivedGoodsModelValidationKeys>>),
    ],
    [
      CountryCode.DE_D365,
      this.generalValidatorKeys.concat([
        'stockItemCode',
        'productForm',
        'productQuality',
        ...this._getContaminationValidationFields(CountryCode.DE_D365),
        ...this._getClassificationValidationFields(CountryCode.DE_D365),
      ]),
    ],
    [CountryCode.UK, this.generalValidatorKeys],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  public get validators(): IReceivedGoodsModelValidationKeys {
    return {
      description: !(this.grossWeight || this.tareWeight || this.stockCode) || !!this.description,
      grossWeight: !(this.description || this.tareWeight || this.stockCode) || !!this.grossWeight,
      contamination:
        !!this.noContamination ||
        Object.values(this.contamination).some((items: KeyValueModel[]) =>
          items.some((item: KeyValueModel) => item.value !== null)
        ), // DE
      wiDone: this.wiDone, // DE
      bulkDensity: !!this.bulkDensity, // DE
      foundQuality: !!this.foundQuality, // DE
      mainType: !!this.mainType, // DE
      materialDescription: !!this.materialDescription, // DE
      stockItemCode: !!this.stockCode, // DE_D365
      productForm: this.productForm !== null || !this.stockItem?.requiresFormAndQuality, // DE_D365
      productQuality: this.productQuality !== null || !this.stockItem?.requiresFormAndQuality, // DE_D365
    };
  }

  private _getContaminationValidationFields(
    countryCode: CountryCode
  ): Array<Partial<keyof IReceivedGoodsModelValidationKeys>> {
    return this._receivedGoodsParams.countryCode === countryCode &&
      this._receivedGoodsParams.rgContaminationsSectionRequired
      ? ['contamination']
      : [];
  }

  private _getClassificationValidationFields(
    countryCode: CountryCode
  ): Array<Partial<keyof IReceivedGoodsModelValidationKeys>> {
    return this._receivedGoodsParams.countryCode === countryCode &&
      this._receivedGoodsParams.rgClassificationsSectionRequired
      ? ['bulkDensity', 'foundQuality', 'mainType', 'materialDescription']
      : [];
  }

  public createContamination = (keyValueModelArray: KeyValueModel[]): KeyValueModel[] => {
    return keyValueModelArray.map((keyValueModel) => new KeyValueModel().update(keyValueModel));
  };

  public createAllSectionOfContamination = (): IContamination => {
    return {
      nonMetallicAttach: this.createContamination(CONTAMINATION.nonMetallicAttach),
      metallicAttach: this.createContamination(CONTAMINATION.metallicAttach),
      differentMetals: this.createContamination(CONTAMINATION.differentMetals),
      turningsInSolids: this.createContamination(CONTAMINATION.turningsInSolids),
      notInFurnaceSize: this.createContamination(CONTAMINATION.notInFurnaceSize),
      materialForms: this.createContamination(CONTAMINATION.materialForms),
      tareInbound: this.createContamination(CONTAMINATION.tareInbound),
      tareOutbound: this.createContamination(CONTAMINATION.tareOutbound),
      turningsComposition: this.createContamination(CONTAMINATION.turningsComposition),
      packaging: this.createContamination(CONTAMINATION.packaging),
      sizes: this.createContamination(CONTAMINATION.sizes),
    };
  };

  public createAllProcessingTimeItems = (): KeyValueModel[] => {
    return PROCESSING_TIME.map((keyValueModel) => new KeyValueModel().update(keyValueModel));
  };

  public advisedGoodId?: string = '';
  public description?: string = null;
  public grossWeight?: number = null;
  public tareWeight?: number = null;
  public contamination?: IContamination = this.createAllSectionOfContamination();
  public attachments?: FileModel[] = [];
  public labInputs?: LabAnalysisModel[] = [];
  public materials?: MaterialsListModel = null;
  public remarks?: string = '';
  public stockCode?: string = '';
  public stockItem?: StockItemModel = null;
  public createdAt?: string = null;
  public id?: string = null;
  public index?: number = null;
  public rgTitle?: string = null;
  public targetLocation?: IdNameModel = null;
  public wiDone?: boolean = false;
  public machine?: IdNameActiveModel = null;
  public targetBatch?: IdNameActiveModel = null;
  public bulkDensity?: string = '';
  public foundQuality?: string = '';
  public mainType?: string = '';
  public materialDescription?: string = '';
  public noContamination?: boolean = false;
  public processingTimes?: KeyValueModel[] = this.createAllProcessingTimeItems();
  public measurement?: MeasurementModel = new MeasurementModel();
  public flagged?: boolean = false;
  public wasFlagged?: boolean = false;
  public productForm?: IdNameModel = null;
  public productQuality?: IdNameModel = null;

  public get contaminationIsDirty(): boolean {
    return this.combineContamination
      ? this.combineContamination.some((keyValueModel) => keyValueModel.hasChanged)
      : false;
  }

  public get combineContamination(): KeyValueModel[] {
    return this.contamination
      ? Object.keys(this.contamination).reduce((res: KeyValueModel[], section: keyof IContamination) => {
          res.push(...this.contamination[section]);
          return res;
        }, [])
      : null;
  }

  public get processingTimeIsDirty(): boolean {
    return this.processingTimes ? this.processingTimes.some((keyValueModel) => keyValueModel.hasChanged) : false;
  }

  public get measurementIsDirty(): boolean {
    return this.measurement && this.measurement.hasChanged;
  }

  public get isDirty() {
    return (
      this.hasChanged ||
      (this.materials && this.materials.isDirty) ||
      this.contaminationIsDirty ||
      this.processingTimeIsDirty ||
      this.measurementIsDirty
    );
  }

  public get netWeight() {
    return this.grossWeight ? this.grossWeight - this.tareWeight : null;
  }

  public get shouldAddNewLine() {
    return this.isFilledOut;
  }

  public get shouldRemoveLine() {
    return !this.isFilledOut;
  }

  public onChange = (change: IObjectDidChange) => {
    const isHasChanged = change.name === HAS_CHANGED;
    const isFlagged = change.name === 'flagged';
    const isAttachments = change.name === 'attachments';
    if (!isHasChanged && !isAttachments && !isFlagged) {
      this.setHasChanged(true);
    }
  };

  public get hasItems() {
    const { materials, labInputs, remarks, targetLocation } = this;

    // PROCESS MATERIAL and REMARKS
    if ((materials && materials.hasItems) || remarks || targetLocation) {
      return true;
    }

    // PROCESS LAB ANALYSIS
    return !!labInputs && labInputs.length > 0;
  }

  public get isFilledOut(): boolean {
    return Boolean(this.grossWeight || this.tareWeight || this.description || this.stockCode);
  }

  public get shouldTrigger() {
    return autoAddNewLine(this.materials.filledMaterials, () => this.materials.pushNewMaterial());
  }

  public isValidStockCodeFormat(stockCodeFormat: string): boolean {
    if (!this.description) {
      return true;
    }

    return new RegExp(stockCodeFormat).test(this.stockCode);
  }

  public update = (
    obj: ReceivedGoodsModel,
    defaultMaterials: MaterialModel[],
    nonDefaultMaterials: MaterialModel[]
  ) => {
    // NOSONAR
    const newReceivedGoodsModel = obj ? Object.assign({}, obj) : null;

    if (newReceivedGoodsModel) {
      if (newReceivedGoodsModel.labInputs && newReceivedGoodsModel.labInputs.length !== 0) {
        newReceivedGoodsModel.labInputs = newReceivedGoodsModel.labInputs.map((r) =>
          new LabAnalysisModel().update(r, defaultMaterials, nonDefaultMaterials)
        );
      }

      if (newReceivedGoodsModel.contamination) {
        newReceivedGoodsModel.contamination = this._mergeCurrentContaminationWithNew(
          newReceivedGoodsModel.contamination
        );
      }

      if (newReceivedGoodsModel.processingTimes) {
        newReceivedGoodsModel.processingTimes = this._mergeCurrentProcessingTimesWithNew(
          newReceivedGoodsModel.processingTimes
        );
      }

      newReceivedGoodsModel.measurement = new MeasurementModel();
      if (obj.measurement) {
        newReceivedGoodsModel.measurement.update(obj.measurement);
      }

      if (newReceivedGoodsModel.attachments && newReceivedGoodsModel.attachments.length !== 0) {
        newReceivedGoodsModel.attachments = newReceivedGoodsModel.attachments.map((r: FileModel) =>
          new FileModel().update(r)
        );
      }

      newReceivedGoodsModel.materials = new MaterialsListModel().update(
        newReceivedGoodsModel.materials,
        defaultMaterials,
        nonDefaultMaterials
      );

      if (obj.machine) {
        newReceivedGoodsModel.machine = new IdNameActiveModel().update(obj.machine);
      }

      if (obj.targetBatch) {
        newReceivedGoodsModel.targetBatch = new IdNameActiveModel().update(obj.targetBatch);
      }

      if (obj.productForm) {
        newReceivedGoodsModel.productForm = new IdNameModel().update(obj.productForm);
      }

      if (obj.productQuality) {
        newReceivedGoodsModel.productQuality = new IdNameModel().update(obj.productQuality);
      }

      if (obj.stockItem) {
        newReceivedGoodsModel.stockItem = new StockItemModel().update(obj.stockItem);
      }
    }

    this.updater.update(this, newReceivedGoodsModel, ReceivedGoodsModel);

    return this;
  };

  public pushNewAttachment(obj: FileModel) {
    this.attachments.push(new FileModel().update(obj));
  }

  public removeAttachment(attachmentId: string) {
    this.attachments = this.attachments.filter((att) => att.id !== attachmentId);
  }

  public createDefaultMaterials = (defaultMaterials: MaterialModel[]) => {
    // IF CREATING NEW RECEIVED GOOD, PUSH DEFAULT MATERIALS
    this.materials = new MaterialsListModel().createDefaultMaterials(defaultMaterials);
  };

  public replaceLabInputs(
    arr: LabAnalysisModel[],
    defaultMaterials: MaterialModel[],
    nonDefaultMaterials: MaterialModel[]
  ) {
    this.labInputs = arr.map((r: LabAnalysisModel) =>
      new LabAnalysisModel().update(r, defaultMaterials, nonDefaultMaterials)
    );
  }

  public setDescription(val: string) {
    this.description = val;
  }

  public setStockCode(val: string) {
    this.stockCode = val;
  }

  public setStockItem(val: StockItemModel | null) {
    this.stockItem = !val ? null : val;
  }

  public setGrossWeight(val: number) {
    this.grossWeight = val;
  }

  public setTareWeight(val: number) {
    this.tareWeight = val;
  }

  public setRemarks(val: string) {
    this.remarks = val;
  }

  public clearIsDirty = () => {
    this.setHasChanged(false);
    this.materials.clearIsDirty();

    if (this.contaminationIsDirty) {
      this.combineContamination.forEach((contamination) => contamination.setHasChanged(false));
    }
  };

  public deleteLabInputById = (labAnalysisId: string) => {
    this.labInputs = this.labInputs.filter((labInput) => labInput.id !== labAnalysisId);
  };

  public hasLabInputWithName = (labInputName: string): boolean =>
    this.labInputs.some((labInput) => labInput.labInputType.name === labInputName);

  public setTargetLocation = (newTargetLocation: IdNameModel) => {
    this.targetLocation = newTargetLocation;
  };

  public setMachine = (newMachine: IdNameActiveModel) => {
    this.machine = newMachine;
  };

  public setTargetBatch = (newTargetBatch: IdNameActiveModel) => {
    this.targetBatch = newTargetBatch;
  };

  public setBulkDensity = (newBulkDensity: string) => {
    this.bulkDensity = newBulkDensity;
  };

  public setFoundQuality = (newFoundQuality: string) => {
    this.foundQuality = newFoundQuality;
  };

  public setMainType = (newMainType: string) => {
    this.mainType = newMainType;
  };

  public setMaterialDescription = (newMaterialDescription: string) => {
    this.materialDescription = newMaterialDescription;
  };

  public setWiDone = (newValue: boolean) => {
    this.wiDone = newValue;
  };

  public setFlagged(val: boolean) {
    this.flagged = val;
  }

  public setWasFlagged(val: boolean) {
    this.wasFlagged = val;
  }

  public setProductForm = (val: IdNameModel) => {
    this.productForm = val;
  };

  public setProductQuality = (val: IdNameModel) => {
    this.productQuality = val;
  };

  public setNoContamination = (newValue: boolean) => {
    this.noContamination = newValue;
    if (this.noContamination) {
      Object.values(this.contamination).forEach((section: KeyValueModel[]) => {
        section.forEach((item: KeyValueModel) => {
          if (item.value !== null) {
            item.changeValue(null);
          }
        });
      });
    }
  };

  // CONSTRUT OBJ FOR PUT ADVISED GOOD
  public constructSaveObj(): IReceivedGoodsModelConstructSaveObj {
    const res: IReceivedGoodsModelConstructSaveObj = {
      description: this.description,
      grossWeight: this.grossWeight,
      tareWeight: this.tareWeight,
      id: this.id,
      materials: this.materials.filledMaterials,
      remarks: this.remarks,
      stockCode: this.stockCode || null,
      targetLocationId: this.targetLocation && this.targetLocation.id,
      machineId: this.machine && this.machine.id ? this.machine.id : null,
      targetBatchId: this.targetBatch && this.targetBatch.id ? this.targetBatch.id : null,
      bulkDensity: this.bulkDensity ? this.bulkDensity : null,
      foundQuality: this.foundQuality ? this.foundQuality : null,
      mainType: this.mainType ? this.mainType : null,
      materialDescription: this.materialDescription ? this.materialDescription : null,
      wiDone: this.wiDone,
      noContamination: this.noContamination,
    };

    if (this.contamination) {
      res.contamination = {
        nonMetallicAttach: this.constructKeyValueModelArray(this.contamination.nonMetallicAttach),
        metallicAttach: this.constructKeyValueModelArray(this.contamination.metallicAttach),
        differentMetals: this.constructKeyValueModelArray(this.contamination.differentMetals),
        turningsInSolids: this.constructKeyValueModelArray(this.contamination.turningsInSolids),
        notInFurnaceSize: this.constructKeyValueModelArray(this.contamination.notInFurnaceSize),
        materialForms: this.constructKeyValueModelArray(this.contamination.materialForms),
        tareInbound: this.constructKeyValueModelArray(this.contamination.tareInbound),
        tareOutbound: this.constructKeyValueModelArray(this.contamination.tareOutbound),
        turningsComposition: this.constructKeyValueModelArray(this.contamination.turningsComposition),
        packaging: this.constructKeyValueModelArray(this.contamination.packaging),
        sizes: this.constructKeyValueModelArray(this.contamination.sizes),
      };
    }

    if (this.attachments && this.attachments.length !== 0) {
      res.files = this.attachments.map((file: FileModel) => ({ id: file.id }));
    }

    if (this.processingTimes) {
      res.processingTimes = this.constructKeyValueModelArray(this.processingTimes);
    }

    if (this.measurement) {
      res.measurement = this.measurement.constructSaveObj();
    }

    if (this.productForm) {
      res.productFormId = this.productForm.id;
    }

    if (this.productQuality) {
      res.productQualityId = this.productQuality.id;
    }

    return res;
  }

  public constructKeyValueModelArray = (keyValueModelArray: KeyValueModel[]): IKeyValueModelConstructObject[] => {
    return keyValueModelArray
      .filter((keyValueModel) => keyValueModel.value !== null)
      .map((keyValueModel) => keyValueModel.constructObject);
  };

  public addContaminationPackagingSectionItems = (items: KeyValueModel[]) => {
    if (!this.contamination) {
      this.contamination = this.createAllSectionOfContamination();
    }
    this.contamination.packaging.push(...items.map((item) => this._createContaminationPackagingSectionItem(item)));
  };

  private _createContaminationPackagingSectionItem = (item: KeyValueModel) => {
    return new KeyValueModel().update({
      ...item,
      inputType: new Map([
        [CountryCode.DE, 'number'],
        [CountryCode.DE_D365, 'number'],
      ]),
      type: ContaminationSectionType.PACKAGING,
    } as KeyValueModel);
  };

  private _mergeCurrentContaminationWithNew(data: IContamination): IContamination {
    let receivedData: KeyValueModel[] = [];

    if (!this.contamination) {
      this.contamination = this.createAllSectionOfContamination();
    }

    if (data) {
      receivedData = Object.keys(data).reduce((res: KeyValueModel[], section: keyof IContamination) => {
        res.push(...data[section]);
        return res;
      }, []);
    }

    receivedData.forEach((keyValueModelWithNewValue: KeyValueModel) => {
      const item = this.combineContamination.find((keyValueModel) => {
        return (
          keyValueModel.type === keyValueModelWithNewValue.type && keyValueModel.name === keyValueModelWithNewValue.name
        );
      });

      if (keyValueModelWithNewValue.type === ContaminationSectionType.PACKAGING) {
        this.contamination.packaging.push(this._createContaminationPackagingSectionItem(keyValueModelWithNewValue));
      }

      if (item) {
        item.update(keyValueModelWithNewValue);
      }
    });

    return this.contamination;
  }

  private _mergeCurrentProcessingTimesWithNew(data: KeyValueModel[]): KeyValueModel[] {
    if (!this.processingTimes) {
      this.processingTimes = this.createAllProcessingTimeItems();
    }

    data.forEach((keyValueModelWithNewValue: KeyValueModel) => {
      const item = this.processingTimes.find((keyValueModel) => {
        return keyValueModel.name === keyValueModelWithNewValue.name;
      });

      if (item) {
        item.update(keyValueModelWithNewValue);
      }
    });

    return this.processingTimes;
  }
}
