import * as React from 'react';
import { inject, observer } from 'mobx-react';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import TranslateService from 'services/TranslateService';
import ViewStore from 'stores/ViewStore';
import { AdvisedGoodStatus, DeductionsList } from 'util/enums';
import { I18N } from '../../../assets/i18n/i18n';
import { getTranslation } from 'util/helpers';
import Autocomplete from 'domain/Autocomplete';
import IdNameModel from 'models/IdNameModel';
import InputNumber from 'components/InputNumber';

interface IUnloadInputGroupProps {
    isReadOnly: boolean;
    advisedGoodsStore?: AdvisedGoodsStore;
    translateService?: TranslateService;
    viewStore?: ViewStore;
}

const UnloadInputGroup: React.FunctionComponent<IUnloadInputGroupProps> = (props) => {
    const {
        translateService: { t },
        viewStore: { numberOfDecimals },
        advisedGoodsStore: { content },
        isReadOnly
    } = props;
    const deductionsList = Object.keys(DeductionsList).map((deduction) => ({
        id: deduction,
        name: getTranslation(`ADVISEDGOOD_DEDUCTION_${deduction}` as keyof I18N, t) as string,
    }));
    const isDisabled = isReadOnly || ![AdvisedGoodStatus.CLAIMED, AdvisedGoodStatus.UNLOADED].includes(content.status);

    return (
        <div className="unload-input-group" data-test="unload-input-group">
            <Autocomplete
                data-test="unload-select"
                disabled={isDisabled}
                placeholder={t.ADVISEDGOOD_DEDUCTION_DEDUCTION}
                items={deductionsList}
                onSelect={(selectedItem: IdNameModel) => content.setNameOfDeduction(selectedItem)}
                selectedValue={content.deduction.name}
                inputClassName="deduction-autocomplete-width"
            />
            <InputNumber
                data-test="unload-input"
                placeholder={t.GLOBAL_LABEL_WEIGHT}
                className="width-100 margin-left-1rem"
                disabled={content.isUserChoseNoDeductionOption || isDisabled}
                value={content.deduction.value}
                onChangeValue={content.setValueOfDeduction}
                decimalPlaces={numberOfDecimals}
            />
        </div>
    );
}

export default inject('translateService', 'advisedGoodsStore', 'viewStore')(observer(UnloadInputGroup));

