import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, InputGroup, Label } from 'reactstrap';
import InputWithNullCheck from 'components/InputWithNullCheck';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';

interface IAGConfigurationSectionProps {
  tenant: TenantModel;
  translateService?: TranslateService;
}

class AGConfigurationSection extends React.Component<IAGConfigurationSectionProps> {
  public render() {
    const { translateService: {t}, tenant} = this.props;
    return (
      <div>
        <FormGroup row className="remaining-padding">
          <Label for="unit" sm={4}>
            {t.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS}
          </Label>
          <Col sm={8}>
            <InputGroup size="md" className="full-width align-items-center full-height">
              <Label className="ms-1" key="agAttachmentsRequired-label">
                <InputWithNullCheck
                  data-test="ag-attachments-required"
                  key="agAttachmentsRequired"
                  type="checkbox"
                  checked={tenant.agAttachmentsRequired}
                  onChange={this._changeAgAttachmentsRequired}
                  className="me-1"
                />
                {t.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL}
              </Label>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="ag-sign-off-enable" sm={4}>
            {t.GLOBAL_LABEL_PARTIAL_SIGN_OFF}
          </Label>
          <Col sm={8}>
            <Label className="ms-1">
              <InputWithNullCheck
                data-test="ag-sign-off-enable"
                type="checkbox"
                checked={tenant.agSignOffEnabled}
                onChange={this._changAagSignOffEnabled}
                className="pointer"
              />
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="balance-deduction-enable" sm={4}>
            {t.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT}
          </Label>
          <Col sm={8}>
            <Label className="ms-1">
              <InputWithNullCheck
                data-test="balance-deduction-enable"
                type="checkbox"
                checked={tenant.balanceDeductionEnabled}
                onChange={this._changeBalanceDeductionEnabled}
                className="pointer"
                id="balance-deduction-enable"
              />
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="overwrite-rg-description-dialog-enable" sm={4}>
            {t.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT}
          </Label>
          <Col sm={8}>
            <Label className="ms-1">
              <InputWithNullCheck
                data-test="overwrite-rg-description-dialog-enable"
                type="checkbox"
                checked={tenant.overwriteRgDescriptionDialogEnabled}
                onChange={this._changeOverwriteRgDescriptionDialogEnabled}
                className="pointer"
                id="overwrite-rg-description-dialog-enable"
              />
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="display-tare-weight-for-received-good" sm={4}>
            {t.SUPERADMIN_TENANTDETAIL_DISPLAY_TARE_WEIGHT_FOR_RECEIVED_GOOD}
          </Label>
          <Col sm={8}>
            <Label className="ms-1">
              <InputWithNullCheck
                data-test="display-tare-weight-for-received-good"
                type="checkbox"
                checked={tenant.displayTareWeightForReceivedGood}
                onChange={this._changeDisplayTareWeightForReceivedGood}
                className="pointer"
                id="display-tare-weight-for-received-good"
              />
            </Label>
          </Col>
        </FormGroup>
      </div>
    );
  }

  private _changeAgAttachmentsRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setAgAttachmentsRequired(e.target.checked);
  };

  private _changAagSignOffEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setAgSignOffEnabled(checked);
  };

  private _changeBalanceDeductionEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setBalanceDeductionEnabled(checked);
  };

  private _changeOverwriteRgDescriptionDialogEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setOverwriteRgDescriptionDialogEnabled(checked);
  };

  private _changeDisplayTareWeightForReceivedGood = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    const { checked } = e.target;
    tenant.setDisplayTareWeightForReceivedGood(checked);
  };
};

export default inject('translateService')(observer(AGConfigurationSection));
