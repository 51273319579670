import * as React from 'react';
import { InputProps } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { action, computed, observable, makeObservable } from 'mobx';
import TranslateService from 'services/TranslateService';
import InputWithNullCheck from 'components/InputWithNullCheck';
import { Position } from 'util/enums';

interface IInputWithLimitOfCharactersProps extends InputProps, IInputWithLimitOfCharactersDefaultProps {
  maxLength: number;
  translateService?: TranslateService;
}

interface IInputWithLimitOfCharactersDefaultProps {
  position?: Position;
  maxLength?: number;
  invalidChars?: string[];
}

class InputWithLimitOfCharacters extends React.Component<IInputWithLimitOfCharactersProps> {
  private readonly _maxLength: number;

  public static defaultProps: IInputWithLimitOfCharactersDefaultProps = {
    position: Position.bottom,
    maxLength: Number.MAX_SAFE_INTEGER,
    invalidChars: [],
  };

  private _charactersRemaining: number = undefined;

  private get _isDisplayRemainingText() {
    return this._charactersRemaining <= 5;
  }

  public constructor(props: IInputWithLimitOfCharactersProps) {
    super(props);

    makeObservable<
      InputWithLimitOfCharacters,
      '_charactersRemaining' | '_isDisplayRemainingText' | '_setCharactersRemaining'
    >(this, {
      _charactersRemaining: observable,
      _isDisplayRemainingText: computed,
      _setCharactersRemaining: action,
    });

    this._maxLength = props.maxLength;
    this._setCharactersRemaining(props.value as string);
  }

  public componentDidUpdate(prevProps: Readonly<IInputWithLimitOfCharactersProps>): void {
    if (prevProps.value !== this.props.value) {
      this._setCharactersRemaining(this.props.value as string);
    }
  }

  public render() {
    const { translateService, invalidChars, ...rest } = this.props;

    return (
      <div className="remaining-container">
        {this._isDisplayRemainingText && this._isPositionTop && (
          <span className="remaining-message remaining-message-top" data-test="characters-remaining">
            {translateService.t.GLOBAL_LABEL_CHARACTERS_REMAINING(String(this._charactersRemaining))}
          </span>
        )}
        <InputWithNullCheck
          {...rest}
          onChange={this._onChange}
          onKeyPress={this._onKeyPress}
          data-test="input-with-limit"
        />
        {this._isDisplayRemainingText && this._isPositionBottom && (
          <span className="remaining-message remaining-message-bottom" data-test="characters-remaining">
            {translateService.t.GLOBAL_LABEL_CHARACTERS_REMAINING(String(this._charactersRemaining))}
          </span>
        )}
      </div>
    );
  }

  private get _isPositionTop() {
    return this.props.position === Position.top;
  }

  private get _isPositionBottom() {
    return this.props.position === Position.bottom;
  }

  private _setCharactersRemaining: (value: string) => void = (value) => {
    const inputValueLength: number = value ? value.toString().length : 0;
    this._charactersRemaining = this._maxLength ? this._maxLength - inputValueLength : undefined;
  };

  private _isValidInputValueLength: (value: string) => boolean = (value) => {
    return value.length <= this._maxLength;
  };

  private _onChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    const value: string = e.currentTarget.value;
    if (this._isValidInputValueLength(value)) {
      this._setCharactersRemaining(value);
      this.props.onChange(e);
    }
  };

  private _onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void = (e) => {
    const { invalidChars } = this.props;
    if (invalidChars.length > 0 && invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
}

export default inject('translateService')(observer(InputWithLimitOfCharacters));
