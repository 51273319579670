import { computed, makeObservable } from 'mobx';
import TenantFilterStore, { ITenantFilters, ITenantFilterStore, ITenantQueryParams } from 'stores/TenantFilterStore';
import RootService from 'services/RootService';
import RootStore from 'stores/RootStore';
import FilterAbstractStore from 'stores/FilterAbstractStore';

export default class DashboardFiltersStore extends FilterAbstractStore<ITenantFilters, ITenantQueryParams>
  implements ITenantFilterStore {
  public tenantFilterStore: TenantFilterStore;

  public constructor(private readonly rootService: RootService, private readonly rootStore: RootStore) {
    super();
    this.tenantFilterStore = new TenantFilterStore(this.rootService, this);

    makeObservable(this, {
      getFiltersApplied: computed,
      getQueryParams: computed,
    });
  }

  public get getFiltersApplied(): ITenantFilters {
    return {
      ...this.tenantFilterStore.getFiltersApplied,
    };
  }

  public get getQueryParams(): ITenantQueryParams {
    return {
      ...this.tenantFilterStore.getQueryParams,
    };
  }

  public onTenantChange = (tenantId: string) => {
    this.rootStore.dashboardStore.getDashboardData(tenantId);
  };
}
