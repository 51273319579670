import { logger } from 'util/logger';
import { ReactNativeActionType } from 'util/enums';

interface IWebViewAction {
  command: ReactNativeActionType;
  payload?: string;
}

class ReactNative {
  public send(action: IWebViewAction) {
    logger.info(`sending to bridge: ${action.command}`);
    if (!window.ReactNativeWebView) {
      logger.info('ReactNativeWebView is not fined');
      return;
    }
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'action',
          ...action,
        })
      );
    } catch (e) {
      logger.error('error while sending action over native bridge', e);
    }
  }

  public get isReactNativeWebViewExist() {
    return Boolean(window.ReactNativeWebView);
  }
}

export default new ReactNative();
