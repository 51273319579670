import key from './key';
import en from './en';
import en_us from './en_us';
import de from './de';
import es from './es';
import fr from './fr';
import it from './it';
import zh from './zh';
import ja from './ja';
import { I18N } from './i18n';

export interface IDefaultExports {
  key: I18N;
  en: I18N;
  en_us: I18N;
  de: I18N;
  es: I18N;
  fr: I18N;
  it: I18N;
  zh: I18N;
  ja: I18N;
}

const defaultExports: IDefaultExports = {
  key,
  en,
  en_us,
  de,
  es,
  fr,
  it,
  zh,
  ja,
};

export default defaultExports;
