import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import { instance as notification } from 'util/notification';
import SessionStore from 'stores/SessionStore';
import { inject, observer } from 'mobx-react';

import FileModel from 'models/FileModel';
import TranslateService from 'services/TranslateService';
import { isMobile } from 'util/helpers';
import ModalConfirmation from 'domain/ModalConfirmation';

interface IProps {
  attachment: FileModel;
  index: number;
  setIndexForLightbox?: (imageToShow: number) => void;
  removeAttachment?: (attachmentId: string) => void;
  openPDF?: (url: string) => void;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
}

interface IState {
  deleteConfirmationModal: boolean;
}

interface IDocument {
  documentMode?: boolean;
}

class AttachmentListItem extends React.Component<IProps, IState> {
  private _target: string;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      deleteConfirmationModal: false,
    };
  }

  public componentDidMount() {
    if ((document as IDocument).documentMode) {
      this._target = '_blank';
    }
  }

  public render() {
    const {
      attachment,
      index,
      sessionStore: { isTier3 },
      translateService: { t },
      removeAttachment,
    } = this.props;

    const attachmentOwner = attachment.createdBy?.fullNameWithShortName;

    return (
      <tr data-test="attachment-item">
        <td onClick={this._handleAttachmentClick} className="pointer" data-test="attachment-item-cell">
          <FontAwesome name={attachment.isAttachmentPDF ? 'file-pdf-o' : 'file-image-o'} className="me-2" />
          <span data-test="attachment-name">
            {`${attachment.isAttachmentPDF ? 'PDF' : t.GLOBAL_LABEL_ATTACHMENT} ${index + 1}`}
            {attachmentOwner && ` (${attachmentOwner})`}
          </span>
        </td>
        {!isMobile() && !isTier3 && (
          <td className="text-center width-30 border-start">
            <a
              href={attachment.path}
              target={this._target}
              download
              onClick={() => notification.success(t.GLOBAL_LABEL_FILE_DOWNLOADED_MSG)}
            >
              <FontAwesome name="download" />
            </a>
          </td>
        )}
        {this._canDeleteAttachment && (
          <td
            data-test="attachment-delete-btn"
            className="text-center width-30 pointer border-start border-end"
            onClick={this._toggleDeleteConfirmationModal}
          >
            <FontAwesome name="trash-o" />
          </td>
        )}
        <ModalConfirmation
          data-test="delete-attachment-confirmation"
          buttonYesColor="success"
          callbackYes={() => removeAttachment(attachment.id)}
          heading={t.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION}
          ico="trash-o"
          isOpen={this.state.deleteConfirmationModal}
          text={`<div class="text-center">${t.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION} <br />
                <img class="mt-2 max-width-200" src=${attachment.thumbnailPath} /></div>`}
          toggleModal={this._toggleDeleteConfirmationModal}
        />
      </tr>
    );
  }
  private get _canDeleteAttachment(): boolean {
    const {
      attachment,
      removeAttachment,
      sessionStore: { user, isAdmin },
    } = this.props;
    return removeAttachment && (!attachment.createdBy.id || attachment.createdBy.id === user.id || isAdmin);
  }

  private _toggleDeleteConfirmationModal = () => {
    this.setState({
      deleteConfirmationModal: !this.state.deleteConfirmationModal,
    });
  };

  private _handleAttachmentClick = () => {
    const { setIndexForLightbox, index, openPDF, attachment } = this.props;
    if (attachment.isAttachmentPDF) {
      openPDF && openPDF(attachment.path);
    } else {
      setIndexForLightbox && setIndexForLightbox(index);
    }
  };
}

export default inject('sessionStore', 'translateService')(observer(AttachmentListItem));
