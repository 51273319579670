import { IContamination } from 'models/ReceivedGoodsModel';
import KeyValueModel from 'models/KeyValueModel';
import {
  ContaminationSectionType,
  CountryCode,
  DepartmentCode,
  DepartmentGroupCode,
  TDepartmentCodes,
  UserRoles,
} from 'util/enums';

export const IMAGE_ERROR = 'Image error';
export const QRCODE_ERROR = 'QRCODE - Error';
export const CREATED_DESC = 'createdAt,desc';
export const GRN_DESC = 'grn,desc';
export const SORT_BY_QP_KEY = 'sortByQp';
export const CREATED_ASC = 'createdAt,asc';
export const ALL = 'All';
export const IN_PROGRESS = 'In progress';
export const READY_FOR_TIER_2 = 'Ready for Tier 2';
export const READY_FOR_SIGNOFF = 'Ready for signoff';
export const IN_LAB = 'In lab';
export const SIGNED_OFF = 'Signed off';
export const IN_WORK = 'IN_WORK';
export const CLAIMED = 'CLAIMED';
export const ALL_VALUE = 'ALL';
export const ONLY_MY_VALUE = 'ONLY_MY';
export const ONLY_UNCLAIMED_VALUE = 'ONLY_UNCLAIMED';
export const ONLY_UNSYNCED_VALUE = 'ONLY_UNSYNCED';
export const IN_PROGRESS_VALUE = 'IN_PROGRESS';
export const READY_FOR_SIGNOFF_VALUE = 'READY_FOR_SIGNOFF';
export const COMPLETE_VALUE = 'COMPLETE';
export const READY_FOR_TIER_2_VALUE = 'READY_FOR_TIER_2';
export const READY_FOR_SIGN_OFF_VALUE = 'READY_FOR_SIGN_OFF';
export const IN_LAB_VALUE = 'IN_LAB';
export const SIGNED_OFF_VALUE = 'SIGNED_OFF';
export const PARTIALLY_SIGNED_OFF_VALUE = 'PARTIALLY_SIGNED_OFF';
export const DATE_DESC = 'Date DESC';
export const DATE_ASC = 'Date ASC';
export const STATUS_ASC = 'Status ASC';
export const STATUS_DESC = 'Status DESC';
export const ASC = 'asc';
export const DESC = 'desc';
export const CREATED_AT = 'createdAt';
export const DATE_FORMAT_WITH_DOT = 'DD.MM.YYYY';
export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const PASSWORD_EXPIRATION_DATE_FORMAT = 'DD MMM, YYYY';
export const PASSWORD_EXPIRATION_DATE_DAYS_LIMIT = 10;
export const EMPTY = 'empty';
export const MAX_MOBILE_PORTRAIT_WIDTH = 768;
export const BORDER_DANGER = 'border-danger';
export const TEXT_DANGER = 'text-danger';
export const TOTAL = 'TOTAL';
export const ALL_ROLES_DELIVERY_FILTER = [
  UserRoles.Admin,
  UserRoles.Trader,
  UserRoles.WeightBridgeOperator,
  UserRoles.Tier3,
  UserRoles.Tier2,
  UserRoles.Tier1,
  UserRoles.Manager,
  UserRoles.BackOffice,
];
export const VEHICLE_REGISTRATION_LIMIT_ALL = 10;
export const VEHICLE_REGISTRATION_LIMIT_IT = 30;
export const TRANSFER_ID_NULL_ERROR_MSG = 'Can not transfer received good because ID is null';
export const TRANSFER_WEIGHT_ERROR_MSG =
  'Can not transfer received good because transfer weight more than gross weight';
export const ADVISED_DESCRIPTION_MAX_LENGTH_DEFAULT = 30;
export const ADVISED_DESCRIPTION_MAX_LENGTH_DE = 50;
export const ADVISED_DESCRIPTION_MAX_LENGTH_IT = 100;
export const ADVISED_PACKAGING_MAX_LENGTH_FR = 30;
export const ADVISED_COMMENT_MAX_LENGTH_DEFAULT = 100;
export const ADVISED_COMMENT_MAX_LENGTH_FR = 65;
export const MAX_DELIVERY_GROSS_WEIGHT = 46200;
export const MAX_RG_AMOUNT = 10;
export const HAS_CHANGED = 'hasChanged';
export const SORTING_REPORT_DESCRIPTION_MAX_LENGTH_UK = 79;
export const SORTING_REPORT_DESCRIPTION_MAX_LENGTH_DEFAULT = 255;
export const NUMBER_OF_DECIMALS_MATERIAL = 4;
export const NUMBER_OF_DECIMALS_MATERIAL_UK = 2;
export const NUMBER_OF_DECIMALS_MATERIAL_FR = 3;
export const NUMBER_OF_DECIMALS_MEASUREMENT = 1;
export const NUMBER_OF_DECIMALS_PROCESSING_TIME = 1;
export const NUMBER_OF_DECIMALS_PROCESSING_PRICE = 2;
export const ORIGIN_CODE_MAX_LENGTH_DEFAULT = 2;
export const ORIGIN_CODE_MAX_LENGTH_FR = 35;
export const DELIVERY_REMARKS_MAX_LENGTH_DEFAULT = 30;
export const DELIVERY_REMARKS_MAX_LENGTH_FR = 60;
export const DEVICE_TOKEN = 'deviceToken';
export const LASERNET_PRINTER_CONFIGURATION_ALL_VALUE = 'All';

const DEPARTMENT_FILTER_CODES = { ...DepartmentGroupCode, ...DepartmentCode };
export const DepartmentsMap: Map<TDepartmentCodes, string> = new Map([
  [DEPARTMENT_FILTER_CODES.EUASDSNI as TDepartmentCodes, 'EUA solids Ni'],
  [DEPARTMENT_FILTER_CODES.STST, 'Stainless Steel'],
  [DEPARTMENT_FILTER_CODES.HSS, 'HSS'],
  [DEPARTMENT_FILTER_CODES.EUASDSTI, 'EUA solids Ti'],
  [DEPARTMENT_FILTER_CODES.EUATUR, 'EUA turnings'],
  [DEPARTMENT_FILTER_CODES.EUA_SOLIDS, 'EUA solids'],
  [DEPARTMENT_FILTER_CODES.EUA_TOTAL, 'EUA total'],
]);

const getContaminationInputType = (DEType: string = 'number') => {
  return new Map([
    [CountryCode.DE, DEType],
    [CountryCode.DE_D365, DEType],
    [CountryCode.IT, 'checkbox'],
    [CountryCode.FR, 'checkbox'],
  ]);
};

const getContaminationDEInputType = () => {
  return new Map([
    [CountryCode.DE, 'number'],
    [CountryCode.DE_D365, 'number'],
  ]);
};

const contaminationSizeNames = [
  'SIZE_0_15',
  'SIZE_16_35',
  'SIZE_36_60',
  'SIZE_61_80',
  'SIZE_81_120',
  'SIZE_121_150',
  'SIZE_151_999',
];
const contaminationTareBoundNames = ['TOTAL', 'DRUMS', 'BOXES', 'GRID_BOXES', 'BIGBAGS', 'PALLETS', 'OTHER'];

export const CONTAMINATION: IContamination = {
  nonMetallicAttach: [
    {
      name: 'WATER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'FREE_OIL',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'DIRT',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'WOOD',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'PLASTIC',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'GLASS_WOOL',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'BURNING_SLAG',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'BITUMEN',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'OIL_CREASE',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'CARDBOARD',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
    {
      name: 'OTHER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NON_METALLIC_ATTACH,
    },
  ] as KeyValueModel[],
  metallicAttach: [
    {
      name: 'IRON',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
    {
      name: 'MN_STEEL',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
    {
      name: '10ER_CR_STEEL',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
    {
      name: '13ER_CR_STEEL',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
    {
      name: '17ER_CR_STEEL',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
    {
      name: '301',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
    {
      name: 'SHREDDER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
    {
      name: 'INCINERATOR_MAT',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
    {
      name: 'OTHER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.METALLIC_ATTACH,
    },
  ] as KeyValueModel[],
  differentMetals: [
    {
      name: 'CU',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.DIFFERENT_METALS,
    },
    {
      name: 'AL',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.DIFFERENT_METALS,
    },
    {
      name: 'MS',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.DIFFERENT_METALS,
    },
    {
      name: 'PB',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.DIFFERENT_METALS,
    },
    {
      name: 'TI',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.DIFFERENT_METALS,
    },
    {
      name: 'STST_CU_COOLER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.DIFFERENT_METALS,
    },
    {
      name: 'MAT_FOR_SHREDDING',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.DIFFERENT_METALS,
    },
    {
      name: 'OTHER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.DIFFERENT_METALS,
    },
  ] as KeyValueModel[],
  turningsInSolids: [
    {
      name: 'CR_STEEL_TUR',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.TURNINGS_IN_SOLIDS,
    },
    {
      name: 'LOW_ALLOYED_TUR',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.TURNINGS_IN_SOLIDS,
    },
    {
      name: 'STST_TURNINGS',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.TURNINGS_IN_SOLIDS,
    },
    {
      name: '304_TURNINGS',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.TURNINGS_IN_SOLIDS,
    },
    {
      name: '316_TURNINGS',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.TURNINGS_IN_SOLIDS,
    },
    {
      name: 'OTHER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.TURNINGS_IN_SOLIDS,
    },
  ] as KeyValueModel[],
  notInFurnaceSize: [
    {
      name: 'FOR_MOBILE_SHEAR',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NOT_IN_FURNACE_SIZE,
    },
    {
      name: 'FOR_STATIONARY_SHEAR',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NOT_IN_FURNACE_SIZE,
    },
    {
      name: 'FOR_PRESS',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NOT_IN_FURNACE_SIZE,
    },
    {
      name: 'NO_AGGREGATE_NECESSARY',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NOT_IN_FURNACE_SIZE,
    },
    {
      name: 'CONSISTS_HOLLOW_BODIES',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NOT_IN_FURNACE_SIZE,
    },
    {
      name: 'FOR_TORCH_CUTTING',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NOT_IN_FURNACE_SIZE,
    },
    {
      name: 'OTHER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.NOT_IN_FURNACE_SIZE,
    },
  ] as KeyValueModel[],
  materialForms: [
    {
      name: 'PACKETS',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.MATERIAL_FORMS,
    },
    {
      name: 'BRIQUETTES',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.MATERIAL_FORMS,
    },
    {
      name: 'INGOTS',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.MATERIAL_FORMS,
    },
    {
      name: 'FINE_MATERIAL',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.MATERIAL_FORMS,
    },
    {
      name: 'RUNNINGS',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.MATERIAL_FORMS,
    },
    {
      name: 'OTHER',
      inputType: getContaminationInputType(),
      type: ContaminationSectionType.MATERIAL_FORMS,
    },
  ] as KeyValueModel[],
  tareInbound: contaminationTareBoundNames.map((name: string) => {
    return {
      name,
      inputType: getContaminationDEInputType(),
      type: ContaminationSectionType.TARE_INBOUND,
    };
  }) as KeyValueModel[],
  tareOutbound: contaminationTareBoundNames.map((name: string) => {
    return {
      name,
      inputType: getContaminationDEInputType(),
      type: ContaminationSectionType.TARE_OUTBOUND,
    };
  }) as KeyValueModel[],
  turningsComposition: [
    {
      name: 'MAGNETIC_CONTENT',
      inputType: getContaminationInputType('checkbox'),
      type: ContaminationSectionType.TURNINGS_COMPOSITION,
    },
    {
      name: 'METALLIC_TURNING',
      inputType: getContaminationInputType('checkbox'),
      type: ContaminationSectionType.TURNINGS_COMPOSITION,
    },
    {
      name: 'GRINDING_TURNING',
      inputType: getContaminationInputType('checkbox'),
      type: ContaminationSectionType.TURNINGS_COMPOSITION,
    },
    {
      name: 'OTHER',
      inputType: getContaminationInputType('checkbox'),
      type: ContaminationSectionType.TURNINGS_COMPOSITION,
    },
  ] as KeyValueModel[],
  packaging: [],
  sizes: contaminationSizeNames.map((name: string) => {
    return {
      name,
      inputType: getContaminationDEInputType(),
      type: ContaminationSectionType.SIZES,
    };
  }) as KeyValueModel[],
};

export const PROCESSING_TIME: KeyValueModel[] = [
  { name: 'CUTTING_HOURS' },
  { name: 'SPIDER_HOURS' },
  { name: 'CART_HOURS' },
  { name: 'OPERATOR_HOURS' },
] as KeyValueModel[];

export const ACTIVE_USER_ROLE_NAME_STORAGE_KEY = 'activeUserRoleName';
export const ACTIVE_TENANT_ID_STORAGE_KEY = 'activeTenantId';

export const EXPECTATION_FAILED_STATUS_CODE = 417;
