import RootStore from 'stores/RootStore';
import * as moment from 'moment-timezone';

moment.tz.setDefault('UTC');

export default class DateFormattingService {
  public constructor(private readonly rootStore: RootStore) {}

  public formatDate = (date: string | Date, timezone?: string) => {
    const utcDate = moment.utc(date);
    if (!utcDate.isValid()) {
      return null;
    }
    return (timezone ? utcDate.tz(timezone) : utcDate).format(this.rootStore.viewStore.dateFormat);
  };

  public formatTime = (date: string | Date, timezone?: string) => {
    const utcDate = moment.utc(date);
    if (!utcDate.isValid()) {
      return null;
    }
    return (timezone ? utcDate.tz(timezone) : utcDate).format('HH:mm');
  };

  public formatDateTime = (date: string | Date, timezone?: string) => {
    const utcDate = moment.utc(date);
    if (!utcDate.isValid()) {
      return null;
    }
    return (timezone ? utcDate.tz(timezone) : utcDate).format(`${this.rootStore.viewStore.dateFormat} - HH:mm`);
  };
}
