import * as React from 'react';
import { Label } from 'reactstrap';
import { observer } from 'mobx-react';

import InputWithNullCheck from 'components/InputWithNullCheck';
import UserModel from 'models/UserModel';
import UserTenantModel from 'models/UserTenantModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IProps {
  content: UserModel<IUserModelConstructObj>;
  tenants: UserTenantModel[];
  tenant: UserTenantModel;
  disabled?: boolean;
}

class UserTenantComponent extends React.Component<IProps> {
  public render() {
    const { content, tenant, disabled } = this.props;

    return !!content ? (
      <Label className="ms-3" key={`${tenant.id}-label`}>
        <InputWithNullCheck
          data-test={`tenant-${tenant.name}`}
          key={tenant.id}
          type="checkbox"
          value={tenant.id}
          checked={!!content.tenants.find((t: UserTenantModel) => t.id === tenant.id)}
          onChange={this._onTenantChange}
          disabled={disabled}
          className="me-1"
        />
        {tenant.name}
      </Label>
    ) : null;
  }

  private _onTenantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { content, tenants } = this.props;
    const { value, checked } = e.target;

    if (checked) {
      const newTenant = tenants.find((item: UserTenantModel) => item.id === value);
      if (!!newTenant) {
        content.addTenant(newTenant);
      }
    } else {
      content.removeTenant(value);
    }
    // during creating new admin or removing if it is default tenant
    if (!content.hasDefaultTenant && content.tenants[0]?.id) {
      content.setIsDefaultTenant(content.tenants[0]?.id);
    }
  };
}

export default observer(UserTenantComponent);
