import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as H from 'history';
import { Button, Col, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';

import TranslateService from 'services/TranslateService';
import AdminStore from 'pod/admin/AdminStore';
import IdNameModel from 'models/IdNameModel';
import UploadFileButton from 'components/UploadFileButton';

interface IProps {
  history?: H.History;
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class ContractListRoute extends React.Component<IProps> {
  public componentDidMount() {
    this.props.adminStore.getContractsUS();
  }

  public render() {
    const {
      adminStore: { contractList },
      translateService: { t },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {t.ADMIN_CONTRACTS_TITLE}
              <Button data-test="refresh-button" className="float-end" color="success" onClick={this._refresh}>
                <FontAwesome name="refresh" className="me-2" />
                {t.GLOBAL_LABEL_REFRESH}
              </Button>
              <Button
                data-test="sync-ftp-button"
                className="float-end margin-right-1rem"
                color="success"
                onClick={this._triggerSMTPSyncUS}
              >
                <FontAwesome name="server" className="me-2" />
                {t.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC}
              </Button>
              <UploadFileButton
                data-test="upload-file-button"
                onChangeFunc={this._importContractFileUS}
                label={t.ADMIN_CONTRACTS_FILE_IMPORT}
                buttonClassName="float-float-end margin-right-1rem"
              />
            </h2>
            <table className="custom-table mb-3">
              <thead>
                <tr>
                  <th className="ps-3">{t.GLOBAL_LABEL_NAME}</th>
                </tr>
              </thead>
              {contractList &&
                contractList.length !== 0 &&
                contractList.map((contract: IdNameModel) => (
                  <tbody key={contract.id}>
                    <tr data-test="contract-item">
                      <td className="ps-3">{contract.name}</td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </Col>
        </Row>
      </Container>
    );
  }

  private _refresh = () => {
    this.props.adminStore.refreshContractsUS();
  };

  private _triggerSMTPSyncUS = async () => {
    const {
      adminStore,
      translateService: { t },
    } = this.props;
    await adminStore.triggerSMTPSyncUS();
    notification.success(t.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG);
  };

  private _importContractFileUS = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      adminStore,
      translateService: { t },
    } = this.props;
    const file = e.target.files[0];
    if (!!file) {
      await adminStore.importContractFileUS(file);
      notification.success(t.ADMIN_CONTRACTS_FILE_IMPORTED_MSG);
    }
  };
}

export default inject('adminStore', 'translateService')(observer(ContractListRoute));
