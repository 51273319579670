import { computed, observable, makeObservable } from 'mobx';
import SupplierModel from 'models/SupplierModel';

export default abstract class SupplierInfo {
  public supplier?: SupplierModel = new SupplierModel();
  public supplierCity?: string = '';

  constructor() {
    makeObservable(this, {
      supplier: observable,
      supplierCity: observable,
      supplierInfo: computed,
    });
  }

  public get supplierInfo(): string {
    const city = this.supplierCity ? `, ${this.supplierCity}` : '';
    return `${this.supplier.name}${city}`;
  }
}
