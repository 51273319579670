import * as React from 'react';
import * as H from 'history';
import { Col, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import DeliveryLabModel from 'models/DeliveryLabModel';
import DeliveryListLabComponent from 'pod/deliveries/DeliveryListLabComponent';
import CommonStore from 'stores/CommonStore';
import TranslateService from 'services/TranslateService';
import { I18N } from '../../../assets/i18n/i18n';
import { getTranslation, isIosVersionLess13 } from 'util/helpers';
import InfiniteScroll from 'domain/InfiniteScroll';
import LabStore from 'pod/deliveries/LabStore';
import { action, observable, reaction, makeObservable } from 'mobx';
import checkLatestAppVersion from 'util/checkLatestAppVersion';
import SessionStore from 'stores/SessionStore';
import SocketService from 'services/SocketService';
import { SocketServiceTopic } from 'util/enums';
import DeliveryLabFiltersStore from 'pod/deliveries/DeliveryLabFiltersStore';
import DeliveryListLabFilterComponent from 'pod/deliveries/DeliveryListLabFilterComponent';
import * as classname from 'classnames';

interface IProps {
  commonStore: CommonStore;
  history?: H.History;
  translateService?: TranslateService;
  labStore?: LabStore;
  sessionStore: SessionStore;
  socketService: SocketService;
  deliveryLabFiltersStore?: DeliveryLabFiltersStore;
}

class DeliveryListLabRoute extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);

    makeObservable<DeliveryListLabRoute, '_onSocketLabAnalysisRequested'>(this, {
      hasMore: observable,
      changeHasMore: action,
      _onSocketLabAnalysisRequested: action,
    });

    reaction(
      () => this.props.deliveryLabFiltersStore.hasFiltersApplied,
      () => {
        this.changeHasMore(true);
      }
    );
  }

  public hasMore: boolean = null;

  public componentDidMount() {
    const { commonStore, socketService } = this.props;
    checkLatestAppVersion();
    commonStore.getCommonLab();
    socketService.subscribeTopic(SocketServiceTopic.LAB_ANALYSIS_REQUESTED, this._onSocketLabAnalysisRequested);
  }

  public componentWillUnmount() {
    const { deliveryLabFiltersStore, socketService } = this.props;

    deliveryLabFiltersStore.deliveryCommonFilterStore.changeOffset(0);
    socketService.unsubscribeTopic(SocketServiceTopic.LAB_ANALYSIS_REQUESTED, this._onSocketLabAnalysisRequested);
  }

  public changeHasMore = (newValue: boolean) => {
    this.hasMore = newValue;
  };

  public get nodeForScrolling() {
    return document.getElementById('nodeForScrolling');
  }

  public getDeliveryLabList = (quertParams: string) => {
    return this.props.labStore.getDeliveriesLabList(quertParams).then((hasMore) => this.changeHasMore(hasMore));
  };

  public downloadList = (queryParams: string) => {
    const { labStore } = this.props;

    labStore.downloadLabList(queryParams);
  };

  public getDeliveryLabListWithFilter = () => {
    const { deliveryLabFiltersStore } = this.props;
    return this.getDeliveryLabList(deliveryLabFiltersStore.buildQueryString);
  };

  public increasePageAmount = () => {
    const {
      deliveryLabFiltersStore: { deliveryCommonFilterStore },
    } = this.props;

    deliveryCommonFilterStore.changeOffset(deliveryCommonFilterStore.offset + deliveryCommonFilterStore.limit);
  };

  public render() {
    const {
      commonStore: { labStatuses },
      translateService: { t },
      labStore: { deliveryLabListSortedByNotification, removeNotificationFlagFromReceivedGood },
      history,
    } = this.props;

    return (
      <div className="full-height">
        <Row>
          <Col>
            <div className="padding-left-right-20 clearfix margin-bottom-1rem">
              <DeliveryListLabFilterComponent
                getListData={this.getDeliveryLabList}
                changeCompleteTrue={this._makeIsCompletedTrue}
                changeCompleteFalse={this._makeIsCompletedFalse}
                isFlaggedChange={this._isFlaggedChange}
                downloadList={this.downloadList}
                history={history}
              />
            </div>
          </Col>
        </Row>
        {/* DISPLAY COLLAPSABLE LIST OF DELIVERIES */}
        <table className="delivery-lab-list-table">
          <thead className={classname([{ 'position-sticky--20': isIosVersionLess13() }])}>
            <tr className="delivery-lab-list-table-header">
              <th className="min-width-10"></th>
              <th className="min-width-50">{t.GLOBAL_LABEL_GRN}</th>
              <th className="min-width-80">{t.GLOBAL_LABEL_SUPPLIER}</th>
              <th>{t.GLOBAL_LABEL_OPERATION}</th>
              {labStatuses.map((labStatus) => (
                <th className="text-center min-width-30 vertical-height" key={`sd-${labStatus.id}`}>
                  <div className="vertical-text-mode ">
                    {getTranslation(`STATUS_LABSTATUSES_${labStatus.name}` as keyof I18N, t)}
                  </div>
                </th>
              ))}
              <th className="min-width-10" />
            </tr>
          </thead>
          <tbody>
            {deliveryLabListSortedByNotification && (
              <InfiniteScroll
                loadMore={this.getDeliveryLabListWithFilter}
                hasMore={this.hasMore}
                increasePageAmount={this.increasePageAmount}
                nodeForScrolling={this.nodeForScrolling}
                initialLoading
              >
                {deliveryLabListSortedByNotification.map((deliveryItem: DeliveryLabModel) => (
                  <DeliveryListLabComponent
                    delivery={deliveryItem}
                    removeNotificationFlagFromReceivedGood={removeNotificationFlagFromReceivedGood}
                    history={this.props.history}
                    key={deliveryItem.id}
                    labStatuses={labStatuses}
                  />
                ))}
              </InfiniteScroll>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  private _makeIsCompletedFalse = (): void => {
    this.props.deliveryLabFiltersStore.changeFilterShowCompleted(false);
    this.getDeliveryLabListWithFilter();
  };

  private _makeIsCompletedTrue = (): void => {
    this.props.deliveryLabFiltersStore.changeFilterShowCompleted(true);
    this.getDeliveryLabListWithFilter();
  };

  private _isFlaggedChange = (): void => {
    this.props.deliveryLabFiltersStore.deliveryCommonFilterStore.toggleIsFlagged();
    this.getDeliveryLabListWithFilter();
  };

  private _onSocketLabAnalysisRequested = (data: DeliveryLabModel) => {
    const { labStore } = this.props;
    labStore.updateLabList(data);
  };
}

export default inject(
  'commonStore',
  'translateService',
  'labStore',
  'sessionStore',
  'socketService',
  'deliveryLabFiltersStore'
)(observer(DeliveryListLabRoute));
