import * as React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';

import NavlinkCustom from 'domain/NavlinkCustom';
import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import { ExampleFileNames, AdminRootPath } from 'util/enums';
import EwcDetailRoute from 'pod/admin/ewcs/EwcDetailRoute';
import IdCodeActiveModel from 'models/IdCodeActiveModel';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class EwcListRoute extends React.Component<IProps> {
  public content: IdCodeActiveModel = new IdCodeActiveModel();

  public clearModel = () => {
    this.content = new IdCodeActiveModel();
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      clearModel: action,
    });
  }

  public get ewcList() {
    const {
      translateService: { t },
      adminStore: { ewcListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="ps-3 pe-3">{t.ADMIN_EWCLIST_CODE}</th>
          </tr>
        </thead>
        {ewcListActiveFirst.map((ewc: IdCodeActiveModel) => this._renderItem(ewc))}
      </table>
    );
  }

  public render() {
    const {
      adminStore: { ewcApi },
      translateService: { t },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={ewcApi}
        rootPath={AdminRootPath.EWCS}
        itemsTable={this.ewcList}
        itemCreateForm={null}
        componentRoute={EwcDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_EWC}
        exampleFileNames={ExampleFileNames.EWCS}
        header={t.MENU_ADMIN_EWCS}
      />
    );
  }

  private _renderItem = (ewc: IdCodeActiveModel) => (
    <tbody key={ewc.id}>
      <NavlinkCustom
        data-test="ewc-item"
        to={`/admin/ewcs/${ewc.id}`}
        tagName="tr"
        className={classnames(['pointer', { 'bg-secondary': !ewc.active }])}
      >
        <td className="ps-3 pe-3">{ewc.code}</td>
      </NavlinkCustom>
    </tbody>
  );
}

export default inject('adminStore', 'translateService')(observer(EwcListRoute));
