import * as React from 'react';
import Portal from 'components/Portal';
import * as FontAwesome from 'react-fontawesome';

interface IProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const BackButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { onClick, children } = props;
  return (
    <Portal nodeId="header">
      <button className="header-button header-back-button" onClick={onClick}>
        <FontAwesome name="chevron-left" /> {children}
      </button>
    </Portal>
  );
};

export default BackButton;
