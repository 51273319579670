import * as React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import NavlinkCustom from 'domain/NavlinkCustom';
import IdNameActiveModel from 'models/IdNameActiveModel';
import MachineDetailRoute from 'pod/admin/machines/MachineDetailRoute';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class MachineListRoute extends React.Component<IProps> {
  public content: IdNameActiveModel = new IdNameActiveModel();

  public clearModel = () => {
    this.content = new IdNameActiveModel();
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      clearModel: action,
    });
  }

  public get machineList() {
    const {
      translateService: { t },
      adminStore: { machineListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="ps-3 pe-3">{t.GLOBAL_LABEL_NAME}</th>
          </tr>
        </thead>
        {machineListActiveFirst.map((machine: IdNameActiveModel) => this._renderItem(machine))}
      </table>
    );
  }

  public render() {
    const {
      adminStore: { machineApi },
      translateService: { t },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={machineApi}
        rootPath={AdminRootPath.MACHINES}
        itemsTable={this.machineList}
        itemCreateForm={null}
        componentRoute={MachineDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_MACHINE}
        exampleFileNames={ExampleFileNames.MACHINES}
        header={t.MENU_ADMIN_MACHINES}
      />
    );
  }

  private _renderItem = (machine: IdNameActiveModel) => {
    return (
      <tbody key={machine.id}>
        <NavlinkCustom
          to={`/admin/machines/${machine.id}`}
          tagName="tr"
          key={machine.id}
          className={classnames(['pointer', { 'bg-secondary': !machine.active }])}
        >
          <td className="ps-3">{machine.name}</td>
        </NavlinkCustom>
      </tbody>
    );
  };
}

export default inject('adminStore', 'translateService')(observer(MachineListRoute));
