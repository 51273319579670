import { action, observable, observe, computed, makeObservable } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';
import UpdateModel from 'models/UpdateModel';
import LabAnalysisModel from 'models/LabAnalysisModel';
import LabStatsModel from 'models/LabStatsModel';
import { cloneObj } from 'util/helpers';
import { HAS_CHANGED } from 'util/constants';
import { ISetHasChanged } from 'util/objectUpdater';
import MaterialModel from 'models/MaterialModel';

export default class ReceivedGoodsLabModel extends UpdateModel<ReceivedGoodsLabModel> implements ISetHasChanged {
  constructor() {
    super();

    makeObservable(this, {
      description: observable,
      id: observable,
      labAnalysis: observable,
      supplier: observable,
      agTitle: observable,
      supplierCode: observable,
      stats: observable,
      isDirty: computed,
      update: action,
    });

    observe(this, this.onChange);
  }

  public description: string = '';
  public id: string = null;
  public labAnalysis: LabAnalysisModel[] = [];
  public supplier: string = null;
  public agTitle: string = null;
  public supplierCode: string = null;
  public stats: LabStatsModel[] = [];

  public get isDirty() {
    return this.hasChanged || (this.labAnalysis && this.labAnalysis.some((x) => x.hasChanged));
  }

  public onChange = (change: IObjectDidChange) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  public update = (
    obj: ReceivedGoodsLabModel,
    defaultMaterials: MaterialModel[],
    nonDefaultMaterials: MaterialModel[]
  ) => {
    const newReceivedGoodsLabModel = cloneObj(obj);
    if (newReceivedGoodsLabModel) {
      newReceivedGoodsLabModel.labAnalysis = newReceivedGoodsLabModel.labAnalysis.map((la) =>
        new LabAnalysisModel().update(la, defaultMaterials, nonDefaultMaterials)
      );
    }

    this.updater.update(this, newReceivedGoodsLabModel, ReceivedGoodsLabModel);

    return this;
  };
}
