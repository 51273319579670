import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

import LasernetPrinterModel from 'models/LasernetPrinterModel';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import InputWithNullCheck from 'components/InputWithNullCheck';

interface IPrinterDetailComponentProps {
  content: LasernetPrinterModel;
  translateService?: TranslateService;
}

const LasernetPrinterDetailComponent: React.FunctionComponent<IPrinterDetailComponentProps> = ({
  content,
  translateService: { t },
}) => {
  const changeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    content.changeDescription(e.target.value);
  };
  const changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    content.changeAddress(e.target.value);
  };

  return (
    content && (
      <React.Fragment>
        <FormGroup row>
          <Label for="address" sm={4}>
            {t.GLOBAL_LABEL_CONNECTION_ADDRESS}
          </Label>
          <Col sm={8}>
            <InputWithNullCheck
              data-test="address"
              value={content.address}
              name="address"
              id="address"
              maxLength={255}
              required
              onChange={changeAddress}
              type="text"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="description" sm={4}>
            {t.GLOBAL_LABEL_DESCRIPTION}
          </Label>
          <Col sm={8}>
            <InputWithNullCheck
              data-test="description"
              value={content.description}
              name="description"
              id="description"
              maxLength={255}
              onChange={changeDescription}
              type="text"
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    )
  );
};

export default inject('translateService')(observer(LasernetPrinterDetailComponent));
