import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, InputGroup, Label } from 'reactstrap';
import InputWithNullCheck from 'components/InputWithNullCheck';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';

interface IDeliveryConfigurationSectionProps {
  tenant: TenantModel;
  translateService?: TranslateService;
}

const DeliveryConfigurationSection: React.FC<IDeliveryConfigurationSectionProps> = (props) => {
  const { translateService: {t}, tenant} = props;
  const changeDeliveryAttachmentsRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = props;
    tenant.setDeliveryAttachmentsRequired(e.target.checked);
  };
  return (
    <div>
      <FormGroup row className="remaining-padding">
        <Label for="unit" sm={4}>
          {t.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS}
        </Label>
        <Col sm={8}>
          <InputGroup size="md" className="full-width align-items-center full-height">
            <Label className="ms-1" key="deliveryAttachmentsRequired-label">
              <InputWithNullCheck
                data-test="delivery-attachments-required"
                key="deliveryAttachmentsRequired"
                type="checkbox"
                checked={tenant.deliveryAttachmentsRequired}
                onChange={changeDeliveryAttachmentsRequired}
                className="me-1"
              />
              {t.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL}
            </Label>
          </InputGroup>
        </Col>
      </FormGroup>
    </div>
  );
};

export default inject('translateService')(observer(DeliveryConfigurationSection));
