import * as moment from 'moment';
import { action, computed, observable, makeObservable } from 'mobx';

import { cloneObj, isMobile } from 'util/helpers';
import { IActive, ICode, IConstructSaveObj, UserModelConstructObj } from 'models/ModelInterfaces';
import UserRoleModel from 'models/UserRoleModel';
import IdActiveModel from 'models/IdActiveModel';
import UserTenantModel from 'models/UserTenantModel';
import {
  PASSWORD_EXPIRATION_DATE_DAYS_LIMIT,
  PASSWORD_EXPIRATION_DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from 'util/constants';
import TenantModel from 'models/TenantModel';

export default class UserModel<T extends UserModelConstructObj> extends IdActiveModel
  implements IActive, ICode, IConstructSaveObj<T> {
  public email: string = '';
  public firstname: string = '';
  public lastname: string = '';
  public password: string = null;
  public roles: UserRoleModel[] = [];
  public activeRole: UserRoleModel = null;
  public username: string = '';
  public requiresPasswordChange: boolean = false;
  public code: string = '';
  public tenants: UserTenantModel[] = [];
  public activeTenant: UserTenantModel = null;
  public passwordExpirationDate: string = '';
  public externalEmployeeId: string = '';

  constructor() {
    super();

    makeObservable(this, {
      email: observable,
      firstname: observable,
      lastname: observable,
      password: observable,
      roles: observable,
      activeRole: observable,
      username: observable,
      requiresPasswordChange: observable,
      code: observable,
      tenants: observable,
      activeTenant: observable,
      passwordExpirationDate: observable,
      externalEmployeeId: observable,
      fullName: computed,
      fullNameWithShortName: computed,
      isFirstNameAndLastNameExist: computed,
      hasDefaultRole: computed,
      hasDefaultTenant: computed,
      passwordExpirationDateMoment: computed,
      passwordExpirationDateMomentWithFormat: computed,
      shouldShowPasswordExpirationMessage: computed,
      isPasswordExpired: computed,
      constructSaveObj: computed,
      isNew: computed,
      setFirstname: action,
      setLastname: action,
      setUsername: action,
      setEmail: action,
      setPassword: action,
      addRole: action,
      removeRole: action,
      clearRoles: action,
      changeCode: action,
      setActiveRole: action,
      setIsDefaultRole: action,
      addTenant: action,
      setActiveTenant: action,
      setIsDefaultTenant: action,
      setExternalEmployeeId: action,
      removeTenant: action,
      clearTenants: action,
    });
  }

  get fullName(): string {
    if (!this.isFirstNameAndLastNameExist) {
      return '';
    }
    return isMobile() ? this.fullNameWithShortName : `${this.firstname} ${this.lastname}`;
  }

  get fullNameWithShortName(): string {
    return this.isFirstNameAndLastNameExist ? `${this.firstname[0]}. ${this.lastname}` : '';
  }

  get isFirstNameAndLastNameExist(): boolean {
    return !!(this.firstname && this.lastname);
  }

  get hasDefaultRole(): boolean {
    return !!this.roles.find((role: UserRoleModel) => role.isDefault);
  }

  get hasDefaultTenant(): boolean {
    return !!this.tenants.find((tenant: UserTenantModel) => tenant.isDefault);
  }

  get passwordExpirationDateMoment(): moment.Moment {
    return moment(this.passwordExpirationDate, SERVER_DATE_FORMAT);
  }

  get passwordExpirationDateMomentWithFormat(): string {
    return this.passwordExpirationDateMoment?.format(PASSWORD_EXPIRATION_DATE_FORMAT);
  }

  get shouldShowPasswordExpirationMessage(): boolean {
    const notificationDate = moment(this.passwordExpirationDate).subtract(PASSWORD_EXPIRATION_DATE_DAYS_LIMIT, 'days');

    return this.passwordExpirationDateMoment && moment().isAfter(notificationDate, 'day');
  }

  get isPasswordExpired(): boolean {
    return moment(this.passwordExpirationDate).isBefore();
  }

  public get constructSaveObj(): T {
    return {
      email: this.email,
      firstname: this.firstname,
      id: this.id,
      lastname: this.lastname,
      password: this.password,
      roles: this.roles.map((item: UserRoleModel) => ({ id: item.id, isDefault: item.isDefault })),
      username: this.username,
      active: this.active,
      code: this.code,
      tenants: this.tenants.map((item: UserTenantModel) => ({ id: item.id, isDefault: item.isDefault })),
      externalEmployeeId: this.externalEmployeeId,
    } as T;
  }
  public get isNew(): boolean {
    return !this.id;
  }

  public getRoleByName = (name: string): UserRoleModel => {
    return this.roles.find((role: UserRoleModel) => role.name === name) || null;
  };

  public getTenantById = (id: string): UserTenantModel => {
    return this.tenants.find((tenant: UserTenantModel) => tenant.id === id) || null;
  };

  public update(obj: UserModel<T>) {
    const newUserModel = cloneObj(obj);

    if (newUserModel && newUserModel.roles && newUserModel.roles.length !== 0) {
      newUserModel.roles = newUserModel.roles.map((r: UserRoleModel) => new UserRoleModel().update(r));

      if (!newUserModel.activeRole) {
        const defaultRole = newUserModel.roles.find((role: UserRoleModel) => role.isDefault);
        newUserModel.activeRole = defaultRole ? new UserRoleModel().update(defaultRole) : null;
      }
    }

    if (newUserModel && newUserModel.tenants && newUserModel.tenants.length !== 0) {
      newUserModel.tenants = newUserModel.tenants.map((t: UserTenantModel) => new UserTenantModel().update(t));

      if (!newUserModel.activeTenant) {
        const defaultTenant = newUserModel.tenants.find((tenant: UserTenantModel) => tenant.isDefault);
        newUserModel.activeTenant = !!defaultTenant ? new UserTenantModel().update(defaultTenant) : null;
      }
    }

    this.updater.update(this, super.update(newUserModel), UserModel);
    return this;
  }

  public setFirstname(val: string) {
    this.firstname = val;
  }
  public setLastname(val: string) {
    this.lastname = val;
  }
  public setUsername(val: string) {
    this.username = val;
  }
  public setEmail(val: string) {
    this.email = val;
  }
  public setPassword(val: string) {
    this.password = val;
  }
  public addRole(userRole: UserRoleModel) {
    const newRole = new UserRoleModel().update(userRole);
    this.roles.push(newRole);
  }
  public removeRole(id: string) {
    this.roles = this.roles.filter((r) => r.id !== id);
  }
  public clearRoles() {
    this.roles = [];
  }
  public changeCode(newCode: string) {
    this.code = newCode;
  }
  public setActiveRole(role: UserRoleModel) {
    this.activeRole = role;
  }

  public setIsDefaultRole(id: string) {
    this.roles.forEach((role: UserRoleModel) => {
      role.changeIsDefault(role.id === id);
    });
  }

  public addTenant(tenant: UserTenantModel | TenantModel, isDefault?: boolean) {
    const newTenant = new UserTenantModel();
    if (!!tenant) {
      newTenant.id = tenant.id;
      newTenant.code = tenant.code;
      newTenant.regionCode = tenant.regionCode;
      newTenant.changeName(tenant.name);
      if (isDefault) {
        newTenant.changeIsDefault(true);
      }
    }
    this.tenants.push(newTenant);
  }

  public setActiveTenant(tenant: UserTenantModel) {
    this.activeTenant = tenant;
  }

  public setIsDefaultTenant(id: string) {
    this.tenants.forEach((tenant: UserTenantModel) => {
      tenant.changeIsDefault(tenant.id === id);
    });
  }

  public setExternalEmployeeId(id: string) {
    this.externalEmployeeId = id;
  }

  public removeTenant(id: string) {
    this.tenants = this.tenants.filter((item: UserTenantModel) => item.id !== id);
  }

  public clearTenants() {
    this.tenants = [];
  }
}
