import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

import IdNameModel from 'models/IdNameModel';
import { DepartmentCode } from 'util/enums';
import { cloneObj } from 'util/helpers';

export interface ITransferData {
  description: string;
  weight?: number;
  yardLocationId?: string;
  departmentCode?: DepartmentCode;
}

export default class TransferModel extends UpdateModel<TransferModel> {
  public description?: string = '';
  public weight?: number = null;
  public yardLocation?: IdNameModel = null;
  public departmentCode?: DepartmentCode = null;
  public id?: string = null;

  public update = (obj: TransferModel) => {
    const newTransferModel = cloneObj(obj);

    if (newTransferModel) {
      newTransferModel.yardLocation = new IdNameModel().update(newTransferModel.yardLocation);
    }

    this.updater.update(this, newTransferModel, TransferModel);
    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      description: observable,
      weight: observable,
      yardLocation: observable,
      departmentCode: observable,
      id: observable,
      update: action,
      setWeight: action,
      setYardLocation: action,
      setDepartmentCode: action,
    });
  }

  public setWeight(newWeight: number) {
    this.weight = newWeight;
  }

  public setYardLocation(newYardLocation: IdNameModel) {
    this.yardLocation = newYardLocation;
  }

  public setDepartmentCode(newDepartmentCode: DepartmentCode) {
    this.departmentCode = newDepartmentCode;
  }

  public getTransferData(isDE: boolean): ITransferData {
    const res: ITransferData = { description: this.description };
    if (isDE) {
      res.departmentCode = this.departmentCode;
    } else {
      res.yardLocationId = this.yardLocation ? this.yardLocation.id : null;
      res.weight = this.weight;
    }

    return res;
  }
}
