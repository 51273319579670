import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdvisedGoodRoute from 'pod/advisedGoods/AdvisedGoodRoute';

export default class AdvisedGoodsIndexRoute extends React.PureComponent {
  public render() {
    return (
      <Switch>
        <Route path="/advised-good/:id/:deliveredGoodId?" component={AdvisedGoodRoute} />
      </Switch>
    );
  }
}
