import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import ActivityLogRoute from 'pod/admin/activity-log/ActivityLogRoute';
import MaterialListRoute from 'pod/admin/materials/MaterialListRoute';
import PrinterListRoute from 'pod/admin/printers/PrinterListRoute';
import RoadHaulierListRoute from 'pod/admin/road-hauliers/RoadHaulierListRoute';
import ScaleListRoute from 'pod/admin/scales/ScaleListRoute';
import StockItemListRoute from 'pod/admin/stock-items/StockItemListRoute';
import SupplierListRoute from 'pod/admin/suppliers/SupplierListRoute';
import UserListRoute from 'pod/admin/users/UserListRoute';
import AdminYardLocationListRoute from 'pod/admin/yard-locations/YardLocationListRoute';
import MachineListRoute from 'pod/admin/machines/MachineListRoute';
import TargetBatchListRoute from 'pod/admin/target-batches/TargetBatchListRoute';
import PackagingListRoute from 'pod/admin/packaging/PackagingListRoute';
import EwcListRoute from 'pod/admin/ewcs/EwcListRoute';
import OriginListRoute from 'pod/admin/origins/OriginListRoute';
import ContractListRoute from 'pod/admin/contracts/ContractListRoute';
import ProcessingListRoute from 'pod/admin/processing/ProcessingListRoute';
import FormListRoute from 'pod/admin/form/FormListRoute';
import QualityListRoute from 'pod/admin/quality/QualityListRoute';
import LasernetPrinterListRoute from 'pod/admin/lasernetPrinters/printers/LasernetPrinterListRoute';
import LasernetDocumentTypeListRoute from 'pod/admin/lasernetPrinters/documentTypes/LasernetDocumentTypeListRoute';
import LasernetPrinterConfigurationsListRoute from 'pod/admin/lasernetPrinters/printerConfigurations/LasernetPrinterConfigurationsListRoute';

export default class AdminIndexRoute extends React.PureComponent {
  public render() {
    return (
      <Switch>
        <Route path="/admin/activity-log" component={ActivityLogRoute} />
        <Route path="/admin/materials" component={MaterialListRoute} />
        <Route path="/admin/printers" component={PrinterListRoute} />
        <Route path="/admin/road-hauliers" component={RoadHaulierListRoute} />
        <Route path="/admin/scales" component={ScaleListRoute} />
        <Route path="/admin/stock-items" component={StockItemListRoute} />
        <Route path="/admin/suppliers" component={SupplierListRoute} />
        <Route path="/admin/user-management" component={UserListRoute} />
        <Route path="/admin/yard-locations" component={AdminYardLocationListRoute} />
        <Route path="/admin/machines" component={MachineListRoute} />
        <Route path="/admin/target-batches" component={TargetBatchListRoute} />
        <Route path="/admin/packaging" component={PackagingListRoute} />
        <Route path="/admin/ewcs" component={EwcListRoute} />
        <Route path="/admin/origins" component={OriginListRoute} />
        <Route path="/admin/contracts" component={ContractListRoute} />
        <Route path="/admin/processing" component={ProcessingListRoute} />
        <Route path="/admin/form" component={FormListRoute} />
        <Route path="/admin/quality" component={QualityListRoute} />
        <Route path="/admin/lasernet-printers" component={LasernetPrinterListRoute} />
        <Route path="/admin/lasernet-document-types" component={LasernetDocumentTypeListRoute} />
        <Route path="/admin/lasernet-configurations" component={LasernetPrinterConfigurationsListRoute} />
      </Switch>
    );
  }
}
