import * as React from 'react';
import TenantModel from '../../../models/TenantModel';
import NavlinkCustom from '../../../domain/NavlinkCustom';
import { inject, observer } from 'mobx-react';
import TranslateService from '../../../services/TranslateService';
import SuperAdminStore from '../SuperAdminStore';
import env from '../../../env';
import { UncontrolledTooltip } from 'reactstrap';
import * as classnames from 'classnames';

interface ITenantListComponentProps {
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}

class TenantListComponent extends React.Component<ITenantListComponentProps> {
  render () {
    const { superAdminStore: { tenants }, translateService: { t} } = this.props;
    return (
      <table className="custom-table mb-3">
        <thead>
        <tr>
          <th className="pe-3">{ t.GLOBAL_LABEL_NAME }</th>
          <th className="width-150">{ t.ADVISEDGOOD_ROUTE_COMMENT }</th>
          <th className="pe-3">{ t.SUPERADMIN_TENANTLIST_GRN }</th>
          <th>{ t.GLOBAL_LABEL_CODE }</th>
          { this._isSiteColumnShown && <th data-test="site-column">{ t.SUPERADMIN_TENANTDETAIL_SITE }</th> }
        </tr>
        </thead>
        { tenants &&
          tenants.length !== 0 &&
          tenants.map((tenant: TenantModel) => (
            <tbody key={ tenant.id } data-test="tenant-item">
            <NavlinkCustom
              data-test="tenant-item-link"
              to={ `/super-admin/tenants/${ tenant.id }` }
              tagName="tr"
              className={classnames(['pointer', { 'bg-secondary': !tenant.active }])}
            >
              <td className="pe-3-3">{ tenant.name }</td>
              <td className="max-width-120">
                {
                  tenant.comment && <>
                    <div id={`comment-${tenant.id}`} className="ellipsis">{ tenant.comment }</div>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`comment-${tenant.id}`}
                      modifiers={ [
                        {
                          name: 'preventOverflow',
                          options: { boundary: 'window' },
                          enabled: true,
                          phase: 'main',
                          fn: () => null
                        },
                      ] }
                    >
                      <span>{ tenant.comment }</span>
                    </UncontrolledTooltip>
                  </>
                }

              </td>
              <td className="pe-3">{ tenant.grnPrefix }</td>
              <td data-test="code">{ tenant.code }</td>
              { this._isSiteColumnShown && <td data-test="site">{ tenant.site }</td> }
            </NavlinkCustom>
            </tbody>
          )) }
      </table>
    );
  }

  private get _isSiteColumnShown() {
    const {
      superAdminStore: { tenants },
    } = this.props;
    return !!tenants && tenants.some((tenant: TenantModel) => tenant.workflow.isDE_D365);
  }
};

export default inject('superAdminStore', 'translateService')(observer(TenantListComponent));

