import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import SupplierModel from 'models/SupplierModel';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import SupplierDetailComponent from 'pod/admin/suppliers/SupplierDetailComponent';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class SupplierDetailRoute extends React.Component<IProps> {
  public content: SupplierModel = null;

  public updateContent = (newSupplier: SupplierModel) => {
    this.content = new SupplierModel().update(newSupplier);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      updateContent: action,
    });
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { supplierList, supplierApi },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.SUPPLIER}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_SUPPLIER}
        itemsList={supplierList}
        api={supplierApi}
        updateItem={this.updateContent}
      >
        <SupplierDetailComponent content={this.content} />
      </CommonAdminRoute>
    );
  }
}

export default inject('adminStore', 'translateService')(observer(SupplierDetailRoute));
