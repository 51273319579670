import { action, computed, observable, makeObservable } from 'mobx';
import CertificateModel from 'models/CertificateModel';
import * as moment from 'moment-timezone';
import { cloneObj } from 'util/helpers';
import { Moment } from 'moment';
import { IActive, ICode, IConstructSaveObj, IName } from 'models/ModelInterfaces';

export enum SupplierTypes {
  ITALIA = 'Italia',
  ESTERO = 'Estero',
  DITTA = 'Ditta',
}

export default class SupplierModel extends CertificateModel
  implements IName, ICode, IActive, IConstructSaveObj<SupplierModel> {
  public code: string = '';
  public name: string = '';
  public active: boolean = true;
  public id: string = null;
  public type?: string = SupplierTypes.ITALIA;

  public certAutRifDate?: moment.Moment = null;
  public cert71513Date?: moment.Moment = null;
  public revisionRequest?: moment.Moment = null;

  constructor() {
    super();

    makeObservable<SupplierModel, '_isTypeItalia'>(this, {
      code: observable,
      name: observable,
      active: observable,
      id: observable,
      type: observable,
      certAutRifDate: observable,
      cert71513Date: observable,
      revisionRequest: observable,
      constructSaveObj: computed,
      isExpirationDateExpired: computed,
      isCertAutRifDateExpired: computed,
      isCert71513DateExpired: computed,
      isRevisionRequestExpired: computed,
      hasNotExpirationDateCertificate: computed,
      hasNotAutRifDateCertificate: computed,
      hasNot71513DateCertificate: computed,
      formattedCert71513Date: computed,
      formattedRevisionRequest: computed,
      formattedCertAutRifDate: computed,
      _isTypeItalia: computed,
      changeName: action,
      changeCode: action,
      changeType: action,
      changeActiveStatus: action,
      changeCertAutRifDate: action,
      changeCert71513Date: action,
      changeRevisionRequest: action,
    });
  }

  public get constructSaveObj(): SupplierModel {
    return {
      name: this.name,
      code: this.code,
      active: this.active,
      expirationDate: this.expirationDate,
      certAutRifDate: this.certAutRifDate,
      cert71513Date: this.cert71513Date,
      revisionRequest: this.revisionRequest,
      type: this.type,
    } as SupplierModel;
  }

  public get isExpirationDateExpired(): boolean {
    return this._isTypeItalia && this.expirationDate?.isBefore() && !moment().isSame(this.expirationDate, 'day');
  }

  public get isCertAutRifDateExpired(): boolean {
    return this._isTypeItalia && this.certAutRifDate?.isBefore() && !moment().isSame(this.certAutRifDate, 'day');
  }

  public get isCert71513DateExpired(): boolean {
    return this._isTypeItalia && this.cert71513Date?.isBefore() && !moment().isSame(this.cert71513Date, 'day');
  }

  public get isRevisionRequestExpired(): boolean {
    return this.revisionRequest?.isBefore() && !moment().isSame(this.revisionRequest, 'day');
  }

  public get isCertificateExpired(): boolean {
    return (
      this.isRevisionRequestExpired ||
      this.isExpirationDateExpired ||
      this.isCertAutRifDateExpired ||
      this.isCert71513DateExpired
    );
  }

  public get hasNotExpirationDateCertificate(): boolean {
    return this._isTypeItalia && !this.expirationDate;
  }

  public get hasNotAutRifDateCertificate(): boolean {
    return this._isTypeItalia && !this.certAutRifDate;
  }

  public get hasNot71513DateCertificate(): boolean {
    return this._isTypeItalia && !this.cert71513Date;
  }

  public get hasNotCertificate(): boolean {
    return (
      !this.revisionRequest ||
      this.hasNotExpirationDateCertificate ||
      this.hasNotAutRifDateCertificate ||
      this.hasNot71513DateCertificate
    );
  }

  public get formattedCert71513Date() {
    return this.cert71513Date && this.cert71513Date.toISOString();
  }

  public get formattedRevisionRequest() {
    return this.revisionRequest && this.revisionRequest.toISOString();
  }

  public get formattedCertAutRifDate() {
    return this.certAutRifDate && this.certAutRifDate.toISOString();
  }

  private get _isTypeItalia() {
    return !!this.type && this.type === SupplierTypes.ITALIA;
  }

  public update = (obj: SupplierModel) => {
    const newSupplierModel = cloneObj(obj);

    if (newSupplierModel.certAutRifDate) {
      newSupplierModel.certAutRifDate = moment(newSupplierModel.certAutRifDate);
    }

    if (newSupplierModel.cert71513Date) {
      newSupplierModel.cert71513Date = moment(newSupplierModel.cert71513Date);
    }

    if (newSupplierModel.revisionRequest) {
      newSupplierModel.revisionRequest = moment(newSupplierModel.revisionRequest);
    }

    this.updater.update(this, super.update(newSupplierModel), SupplierModel);

    return this;
  };

  public changeName = (newName: string): void => {
    this.name = newName;
  };

  public changeCode = (newCode: string): void => {
    this.code = newCode;
  };

  public changeType = (newType: string): void => {
    this.type = newType;
  };

  public changeActiveStatus = (newStatus: boolean): void => {
    this.active = newStatus;
  };

  public changeCertAutRifDate = (newDate: moment.Moment): void => {
    this.certAutRifDate = newDate;
  };

  public changeCert71513Date = (newDate: moment.Moment): void => {
    this.cert71513Date = newDate;
  };

  public changeRevisionRequest = (newDate: moment.Moment): void => {
    this.revisionRequest = newDate;
  };
}
