import * as React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { instance as notification } from 'util/notification';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import { isMobile } from 'util/helpers';
import { AdminRootPath, ExampleFileNames, PublicPaths } from 'util/enums';

interface IProps {
  heading: string;
  name: AdminRootPath;
  uploadFile: (file: File) => Promise<void>;
  showUpload: boolean;
  toggleUpload: () => void;
  translateService?: TranslateService;
  exampleFileName: ExampleFileNames;
}

interface IState {
  file?: File;
}

class AdminListUploadComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      file: null,
    };
  }

  public componentDidMount() {
    this.setState({
      file: null,
    });
  }

  public render() {
    const { file } = this.state;
    const {
      showUpload,
      toggleUpload,
      heading,
      name,
      translateService: { t },
      exampleFileName,
    } = this.props;

    const examplePath = `${PublicPaths.ADMIN_EXAMPLE_FILES}${exampleFileName}`;

    return (
      <Modal isOpen={showUpload} toggle={toggleUpload} backdrop="static">
        <ModalHeader toggle={toggleUpload}>{heading}</ModalHeader>
        <ModalBody>
          {!isMobile() && (
            <p>
              {t.ADMIN_ADMINLISTUPLOAD_EXAMPLE}
              <a href={examplePath} download data-test="example">
                {` ${exampleFileName}`}
              </a>
            </p>
          )}

          <Form onSubmit={this._uploadFile}>
            <FormGroup row>
              <Label for={`${name}-file`} sm={3}>
                {t.GLOBAL_LABEL_FILE}
              </Label>
              <Col sm={9}>
                <Input
                  name={`${name}-file`}
                  id={`${name}-file`}
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={this._setFile}
                />
              </Col>
            </FormGroup>

            <ModalFooter>
              <Button color="secondary" onClick={toggleUpload}>
                {t.GLOBAL_LABEL_CANCEL}
              </Button>
              <Button data-test="upload-btn" type="submit" color="success" disabled={!file}>
                {t.GLOBAL_LABEL_UPLOAD}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    );
  }

  private _uploadFile = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      translateService: { t },
    } = this.props;

    this.props
      .uploadFile(this.state.file)
      .then(() => {
        this.props.toggleUpload();

        notification.success(t.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG);
      })
      .catch(() => {
        notification.error(t.ADMIN_ADMINLISTUPLOAD_ERROR_MSG);
      });
  };

  private _setFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = e;

    this.setState({
      file: files[0],
    });
  };
}

export default inject('translateService')(observer(AdminListUploadComponent));
