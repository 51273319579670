import * as React from 'react';
import * as H from 'history';
import * as FontAwesome from 'react-fontawesome';
import { inject, observer } from 'mobx-react';
import { action, computed, observable, makeObservable } from 'mobx';
import { elgLogo } from 'util/images';

import { Button, Card, CardBody, Col, Container, InputGroup, Row } from 'reactstrap';
import SessionStore from 'stores/SessionStore';
import { parse } from 'query-string';
import TranslateService from 'services/TranslateService';
import ChangeLanguage from 'components/ChangeLanguage';
import { instance as notification } from 'util/notification';
import InputWithNullCheck from 'components/InputWithNullCheck';

interface IProps {
  sessionStore?: SessionStore;
  history: H.History;
  translateService?: TranslateService;
}

class RecoverPasswordRoute extends React.Component<IProps> {
  private username: string = '';
  private oldPassword: string = '';
  private newPassword: string = '';
  private newSecondPassword: string = '';

  constructor(props: IProps) {
    super(props);

    makeObservable<
      RecoverPasswordRoute,
      | 'username'
      | 'oldPassword'
      | 'newPassword'
      | 'newSecondPassword'
      | '_isRecoverButtonDisabled'
      | '_setUserName'
      | '_setOldPassword'
      | '_setNewPassword'
      | '_setNewSecondPassword'
    >(this, {
      username: observable,
      oldPassword: observable,
      newPassword: observable,
      newSecondPassword: observable,
      _isRecoverButtonDisabled: computed,
      _setUserName: action,
      _setOldPassword: action,
      _setNewPassword: action,
      _setNewSecondPassword: action,
    });
  }

  public componentDidMount() {
    const {
      history: {
        location: { search = '' },
      },
    } = this.props;
    const { username } = parse(search);
    if (!username) {
      this.props.history.push('/auth/login');
    }

    this._setUserName(username as string);
  }

  public render() {
    const {
      translateService: { t },
      sessionStore: { user },
    } = this.props;
    const isShowBackButton = !!user && !user.requiresPasswordChange;

    return (
      <Container>
        {isShowBackButton && (
          <Button data-test="go-back" className="width-140 btn-sm" onClick={this._goBack}>
            {t.GLOBAL_LABEL_GO_BACK}
          </Button>
        )}
        <ChangeLanguage width="width-140 float-end" />
        <Row className="margin-0-auto full-width">
          <Col xs="12" sm="10" md="7" lg="4" className="mx-auto ps-0 pe-0 pl-sm-3 pr-sm-3">
            <Card className="mt-0 mt-sm-4">
              <CardBody>
                <div className="clearfix">
                  <img src={elgLogo} alt="ELG" className="login-logo float-end" />
                  <h3 dangerouslySetInnerHTML={{ __html: t.GLOBAL_RECOVERPASSWORD_HEADER }} />
                </div>
                {user?.isPasswordExpired && (
                  <div className="color-grey" data-test="expired-password-message">
                    {t.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE}
                  </div>
                )}

                <div className="margin-top-1rem">
                  <InputGroup className="margin-bottom-1rem">
                    <span className="input-group-text">
                      <FontAwesome name="user" />
                    </span>
                    <InputWithNullCheck
                      autoFocus
                      placeholder={t.GLOBAL_LABEL_USERNAME}
                      type="text"
                      value={this.username}
                      disabled
                    />
                  </InputGroup>
                  <InputGroup className="margin-bottom-1_5rem">
                    <span className="input-group-text">
                      <FontAwesome name="lock" />
                    </span>
                    <InputWithNullCheck
                      data-test="old-password"
                      onChange={(e) => this._setOldPassword(e.target.value)}
                      placeholder={t.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD}
                      type="password"
                      value={this.oldPassword}
                    />
                  </InputGroup>
                  <InputGroup className="margin-bottom-1_5rem">
                    <span className="input-group-text">
                      <FontAwesome name="lock" />
                    </span>
                    <InputWithNullCheck
                      data-test="new-password"
                      onChange={(e) => this._setNewPassword(e.target.value)}
                      placeholder={t.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD}
                      type="password"
                      value={this.newPassword}
                    />
                  </InputGroup>
                  <InputGroup className="margin-bottom-1_5rem">
                    <span className="input-group-text">
                      <FontAwesome name="lock" />
                    </span>
                    <InputWithNullCheck
                      data-test="new-second-password"
                      onChange={(e) => this._setNewSecondPassword(e.target.value)}
                      placeholder={t.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD}
                      type="password"
                      value={this.newSecondPassword}
                    />
                  </InputGroup>
                  <Button
                    data-test="recover-button"
                    block
                    disabled={this._isRecoverButtonDisabled}
                    onClick={this.recoverPassword}
                    color="success"
                    type="submit"
                  >
                    {t.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  private recoverPassword = () => {
    const {
      sessionStore,
      history,
      translateService: { t },
    } = this.props;

    sessionStore.recoverPassword(this.username, this.oldPassword, this.newPassword).then(() => {
      notification.success(t.GLOBAL_RECOVERPASSWORD_UPDATE_MSG);

      sessionStore.login(this.username, this.newSecondPassword).then((res) => {
        const {
          sessionStore: { isAdmin, isLab, isSuperAdmin },
        } = this.props;
        if (res.requiresPasswordChange) {
          history.push(`/auth/recover-password?username=${this.username}`);
        } else if (isAdmin) {
          history.push('/');
        } else if (isSuperAdmin) {
          history.push('/tenants');
        } else if (isLab) {
          history.push('/deliveries/lab');
        } else {
          history.push('/deliveries');
        }
      });
    });
  };

  private _goBack = () => {
    this.props.history.goBack();
  };

  private get _isRecoverButtonDisabled(): boolean {
    return !(
      this.username &&
      this.newPassword &&
      this.oldPassword &&
      this.newSecondPassword &&
      this.newSecondPassword === this.newPassword
    );
  }

  private _setUserName = (val: string) => {
    this.username = val;
  };
  private _setOldPassword = (val: string) => {
    this.oldPassword = val;
  };
  private _setNewPassword = (val: string) => {
    this.newPassword = val;
  };
  private _setNewSecondPassword = (val: string) => {
    this.newSecondPassword = val;
  };
}

export default inject('sessionStore', 'translateService')(observer(RecoverPasswordRoute));
