import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import UserModel from 'models/UserModel';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import TranslateService from 'services/TranslateService';
import InputWithNullCheck from 'components/InputWithNullCheck';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IProps {
  content: UserModel<IUserModelConstructObj>;
  translateService?: TranslateService;
}

class CommonUserFields extends React.Component<IProps> {
  public render() {
    const {
      content,
      translateService: { t },
    } = this.props;
    return (
      <React.Fragment>
        <FormGroup row className="remaining-padding">
          <Label for="firstname" sm={4}>
            {t.GLOBAL_LABEL_FIRST_NAME}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="name"
              value={content.firstname}
              maxLength={255}
              id="firstname"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setFirstname(e.target.value)}
              type="text"
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="lastname" sm={4}>
            {t.GLOBAL_LABEL_LAST_NAME}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="lastName"
              id="lastname"
              maxLength={255}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setLastname(e.target.value)}
              required
              type="text"
              value={content.lastname}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="username" sm={4}>
            {t.GLOBAL_LABEL_USERNAME}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="userName"
              id="username"
              maxLength={50}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setUsername(e.target.value)}
              required
              type="text"
              value={content.username}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="remaining-padding">
          <Label for="password" sm={4}>
            {t.GLOBAL_LABEL_PASSWORD}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="password"
              id="password"
              maxLength={255}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setPassword(e.target.value)}
              required={!content.id}
              type="password"
              value={content.password}
            />
          </Col>
        </FormGroup>
        {!!content.passwordExpirationDate && (
          <FormGroup row className="remaining-padding">
            <Label for="password-expiration-date" sm={4}>
              {t.GLOBAL_LABEL_PASSWORD_EXP_DATE}
            </Label>
            <Col sm={8}>
              <InputWithNullCheck
                data-test="password-expiration-date"
                id="password-expiration-date"
                type="text"
                value={content.passwordExpirationDateMomentWithFormat}
                disabled
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup row className="remaining-padding">
          <Label for="email" sm={4}>
            {t.GLOBAL_LABEL_EMAIL}
          </Label>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="email"
              id="email"
              maxLength={255}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setEmail(e.target.value)}
              type="text"
              value={content.email}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

export default inject('translateService')(observer(CommonUserFields));
