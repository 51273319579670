import TranslateService from 'services/TranslateService';
import SocketService from 'services/SocketService';
import AjaxService from 'services/AjaxService';
import DateFormattingService from 'services/DateFormattingService';

export default class RootService {
  public ajaxService: AjaxService;
  public socketService: SocketService;
  public translateService: TranslateService;
  public dateFormattingService: DateFormattingService;
}
