import * as React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import StockItemModel from 'models/StockItemModel';
import { AdminRootUrl } from 'util/enums';
import ViewStore from 'stores/ViewStore';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

class StockItemDetailRoute extends React.Component<IProps> {
  public content: StockItemModel = null;

  public updateContent = (newStockItem: StockItemModel) => {
    this.content = new StockItemModel({ code: this.props.viewStore.stockCodeFormat }).update(newStockItem);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      updateContent: action,
    });
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { stockItemList, stockItemApi },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.STOCK_ITEM}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_STOCK_ITEM}
        itemsList={stockItemList}
        api={stockItemApi}
        updateItem={this.updateContent}
      />
    );
  }
}

export default inject('adminStore', 'viewStore', 'translateService')(observer(StockItemDetailRoute));
