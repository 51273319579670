import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { I18N } from '../../../../assets/i18n/i18n';

import TranslateService from 'services/TranslateService';
import InputWithNullCheck from 'components/InputWithNullCheck';
import { getTranslation } from 'util/helpers';
import UserModel from 'models/UserModel';
import UserRoleModel from 'models/UserRoleModel';
import SessionStore from 'stores/SessionStore';
import { UserRoles } from 'util/enums';
import UserTenantModel from 'models/UserTenantModel';
import CommonUserFields from 'pod/admin/commonComponents/CommonUserFields';
import UserTenantComponent from 'pod/admin/commonComponents/UserTenantComponent';
import ViewStore from 'stores/ViewStore';
import Employees from 'pod/admin/commonComponents/Employees';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IUserDetailComponentProps {
  content: UserModel<IUserModelConstructObj>;
  userRoles: UserRoleModel[];
  translateService?: TranslateService;
  sessionStore?: SessionStore;
  tenants?: UserTenantModel[];
  viewStore?: ViewStore;
}

class UserDetailComponent extends React.Component<IUserDetailComponentProps> {
  public componentDidMount(): void {
    const {
      content,
      sessionStore: { user },
    } = this.props;
    // for new user
    if (!content.tenants.length && !!user.activeTenant) {
      content.addTenant(user.activeTenant, true);
    }
  }

  public render() {
    const {
      content,
      userRoles,
      sessionStore: { user },
      translateService: { t },
      tenants,
      viewStore: { isDE_D365 },
    } = this.props;

    return !!content ? (
      <React.Fragment>
        {isDE_D365 && <Employees data-test="employees" content={content} />}
        <CommonUserFields content={content} />
        {!!(userRoles && userRoles.length) && (
          <React.Fragment>
            <FormGroup row className="remaining-padding">
              <Label sm={4}>{t.GLOBAL_LABEL_USER_ROLES}</Label>
              <Col sm={8} className="ps-0">
                {userRoles.map((item: UserRoleModel) => (
                  <Label className="ms-3" key={`${item.id}-label`}>
                    <InputWithNullCheck
                      data-test={`user-role-${item.name}`}
                      key={item.id}
                      type="checkbox"
                      value={item.id}
                      checked={!!content.roles.find((r: UserRoleModel) => r.id === item.id)}
                      onChange={this._onUserRoleChange}
                      disabled={this._isDisabledRole(item)}
                      className="me-1"
                    />
                    {getTranslation(`GLOBAL_USERROLE_${item.name.toUpperCase()}` as keyof I18N, t)}
                  </Label>
                ))}
              </Col>
            </FormGroup>
            {!!(content.roles && content.roles.length) && (
              <FormGroup row>
                <Label sm={4} for="user-default-role">
                  {t.GLOBAL_LABEL_DEFAULT_ROLE}
                </Label>
                <Col sm={8}>
                  <InputWithNullCheck
                    type="select"
                    id="user-default-role"
                    disabled={content.roles.length < 2}
                    value={content.roles.find((role: UserRoleModel) => role.isDefault)?.id}
                    onChange={this._onDefaultRoleChange}
                  >
                    {content.roles.map((role: UserRoleModel) => (
                      <option key={role.id} value={role.id}>
                        {getTranslation(`GLOBAL_USERROLE_${role.name.toUpperCase()}` as keyof I18N, t)}
                      </option>
                    ))}
                  </InputWithNullCheck>
                </Col>
              </FormGroup>
            )}
          </React.Fragment>
        )}
        {this._isDisplayTenants ? (
          <FormGroup row className="remaining-padding" data-test="tenant-group">
            <Label sm={4}>
              {t.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE(
                `${getTranslation(`GLOBAL_USERROLE_${UserRoles.Trader.toUpperCase()}` as keyof I18N, t) as string}/${
                  getTranslation(`GLOBAL_USERROLE_${UserRoles.BackOffice.toUpperCase()}` as keyof I18N, t) as string
                }`
              )}
            </Label>
            <Col sm={8}>
              {tenants.map((item: UserTenantModel) => (
                <UserTenantComponent
                  data-test={`tenant-${item.name}`}
                  key={item.id}
                  tenants={tenants}
                  tenant={item}
                  content={content}
                  disabled={user.activeTenant && user.activeTenant.id === item.id}
                />
              ))}
            </Col>
          </FormGroup>
        ) : null}
      </React.Fragment>
    ) : null;
  }

  private get _isDisplayTenants(): boolean {
    return (
      !!this.props.content.getRoleByName(UserRoles.Trader) || !!this.props.content.getRoleByName(UserRoles.BackOffice)
    );
  }

  private _isDisabledRole = (role: UserRoleModel): boolean => {
    const isTrader = role.name === UserRoles.Trader;
    const isBackOffice = role.name === UserRoles.BackOffice;
    const isDataAnalyzer = role.name === UserRoles.DataAnalyzer;

    return (
      (!isDataAnalyzer && !!this.props.content.getRoleByName(UserRoles.DataAnalyzer)) ||
      (isTrader && !!this.props.content.getRoleByName(UserRoles.BackOffice)) ||
      (isBackOffice && !!this.props.content.getRoleByName(UserRoles.Trader))
    );
  };

  private _onUserRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { content, userRoles } = this.props;
    const { value, checked } = e.target;
    if (checked) {
      const role = userRoles.find((u: UserRoleModel) => u.id === value);
      if (role.name === UserRoles.DataAnalyzer) {
        content.clearRoles();
        this._clearTenants();
      }
      content.addRole(role);
    } else {
      const isRemovedRoleTraderOrBackOffice = !!content.roles.find(
        (role: UserRoleModel) =>
          role.id === value && (role.name === UserRoles.Trader || role.name === UserRoles.BackOffice)
      );

      content.removeRole(value);

      if (isRemovedRoleTraderOrBackOffice) {
        // if Trader or BackOffice role is removed need to reset tenants
        this._clearTenants();
      }
    }
    // during creating new user or removing if it is default role
    if (!content.hasDefaultRole && content.roles[0]?.id) {
      content.setIsDefaultRole(content.roles[0]?.id);
    }
  };

  private _onDefaultRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.content.setIsDefaultRole(e.target.value);
  };

  private _clearTenants = () => {
    const {
      content,
      sessionStore: { user },
    } = this.props;
    content.clearTenants();
    content.addTenant(user.activeTenant, true);
  };
}

export default inject('translateService', 'sessionStore', 'viewStore')(observer(UserDetailComponent));
