import IdNameModel from 'models/IdNameModel';
import UserModel from 'models/UserModel';
import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class LabTrackingModel extends UpdateModel<LabTrackingModel> {
  public active?: boolean = false;
  public createdAt?: Date = null;
  public createdBy?: UserModel<IUserModelConstructObj> = null;
  public id?: string = null;
  public location?: IdNameModel = null;
  public status?: IdNameModel = null;

  public update = (obj: LabTrackingModel) => {
    const newLabTrackingModel = cloneObj(obj);

    if (newLabTrackingModel) {
      newLabTrackingModel.createdBy = newLabTrackingModel.createdBy
        ? new UserModel<IUserModelConstructObj>().update(newLabTrackingModel.createdBy)
        : new UserModel<IUserModelConstructObj>();
    }

    this.updater.update(this, newLabTrackingModel, LabTrackingModel);
    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      active: observable,
      createdAt: observable,
      createdBy: observable,
      id: observable,
      location: observable,
      status: observable,
      update: action,
    });
  }
}
