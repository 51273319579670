import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

import PrinterModel from 'models/PrinterModel';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import InputWithNullCheck from 'components/InputWithNullCheck';

interface IPrinterDetailComponentProps {
  content: PrinterModel;
  translateService?: TranslateService;
}

const PrinterDetailComponent: React.FunctionComponent<IPrinterDetailComponentProps> = ({
  content,
  translateService: { t },
}) => {
  const changeIp = (e: React.ChangeEvent<HTMLInputElement>) => {
    content.changeIp(e.target.value);
  };
  const changeModel = (e: React.ChangeEvent<HTMLInputElement>) => {
    content.changeModel(e.target.value);
  };

  return (
    content && (
      <React.Fragment>
        <FormGroup row>
          <Label for="ip" sm={4}>
            {t.GLOBAL_LABEL_IP}
          </Label>
          <Col sm={8}>
            <InputWithNullCheck
              data-test="ip"
              value={content.ip}
              name="ip"
              id="ip"
              maxLength={50}
              required
              onChange={changeIp}
              type="text"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="model" sm={4}>
            {t.GLOBAL_LABEL_MODEL}
          </Label>
          <Col sm={8}>
            <InputWithNullCheck
              data-test="model"
              value={content.model}
              name="model"
              id="model"
              maxLength={50}
              required
              onChange={changeModel}
              type="text"
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    )
  );
};

export default inject('translateService')(observer(PrinterDetailComponent));
