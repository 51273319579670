import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import ViewStore from 'stores/ViewStore';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import TranslateService from 'services/TranslateService';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import { ADVISED_COMMENT_MAX_LENGTH_DEFAULT, ADVISED_COMMENT_MAX_LENGTH_FR } from 'util/constants';
import { AdvisedGoodStatus } from 'util/enums';

interface IAdvisedGoodCommentProps {
    isReadOnly: boolean;
    viewStore?: ViewStore;
    advisedGoodsStore?: AdvisedGoodsStore;
    translateService?: TranslateService;
}

class AdvisedGoodComment extends React.Component<IAdvisedGoodCommentProps> {
    public render() {
        const {
            translateService: { t },
            advisedGoodsStore: { content },
            viewStore: { isFR },
        } = this.props;
        const isCommentReadOnly = this._isCommentReadOnly;

        return (
            <Row className="bg-white border-top border-bottom" data-test="comment-container">
                <Col xs={12} className="pt-1 pb-1">
                    {isCommentReadOnly &&
                        (content.comment ? (
                            <div data-test="comment-div">{content.comment}</div>
                        ) : (
                            <div data-test="no-comment" className="text-center font-light">{t.ADVISEDGOOD_ROUTE_NO_COMMENT}</div>
                        ))}
                    {!isCommentReadOnly && (
                        <InputWithLimitOfCharacters
                            data-test="comment-input"
                            disabled={isCommentReadOnly}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setComment(e.target.value)}
                            type="textarea"
                            value={content.comment}
                            maxLength={isFR ? ADVISED_COMMENT_MAX_LENGTH_FR : ADVISED_COMMENT_MAX_LENGTH_DEFAULT}
                        />
                    )}
                </Col>
            </Row>
        );
    }



    private get _isCommentReadOnly(): boolean {
        const {
            viewStore: {
                isTier2FromIT,
                isTier1FromIT,
                isTier3FromIT,
                isTier3FromFR,
                isTier1FromFR,
                isTier2FromFR,
                isDE_D365,
            },
            advisedGoodsStore: { content },
            isReadOnly
        } = this.props;

        return (
            (isDE_D365 && content.status !== AdvisedGoodStatus.CLAIMED) ||
            ((isReadOnly ||
                    ((isTier2FromIT || isTier3FromIT || isTier2FromFR || isTier3FromFR) &&
                        !content.secondWeight &&
                        [AdvisedGoodStatus.UNLOADED, AdvisedGoodStatus.READY_FOR_TIER_2].includes(content.status))) &&
                !((isTier1FromIT || isTier1FromFR) && content.status === AdvisedGoodStatus.READY_FOR_SIGN_OFF))
        );
    }
}

export default inject(
    'advisedGoodsStore',
    'viewStore',
    'translateService')
(observer(AdvisedGoodComment));
