import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import SessionStore from 'stores/SessionStore';
import LayoutStore from 'stores/LayoutStore';
import TranslateService from 'services/TranslateService';
import { isMobile } from 'util/helpers';
import ViewStore from 'stores/ViewStore';
import LasernetPrinterMenu from 'domain/layout/LasernetPrintersMenu';

interface IMenuProps extends IMenuDefaultProps {
  pathName: string;
  sessionStore?: SessionStore;
  layoutStore?: LayoutStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
}

interface IMenuDefaultProps {
  isMenuOpen?: boolean;
}

class Menu extends React.Component<IMenuProps> {
  public static defaultProps: IMenuDefaultProps = {
    isMenuOpen: !isMobile(),
  };

  public componentDidMount() {
    const {
      sessionStore: { isAdmin, isSuperAdmin },
      layoutStore,
    } = this.props;

    if (isAdmin || isSuperAdmin) {
      layoutStore.toggleMenu(this.props.isMenuOpen);
    }
  }

  public componentWillUnmount(): void {
    const { layoutStore } = this.props;
    if (layoutStore.menuOpen) {
      layoutStore.toggleMenu(false);
    }
  }

  public render() {
    const {
      layoutStore,
      sessionStore: { isAdmin, isSuperAdmin },
      viewStore: { isDE, isIT, isUK, isUS, isFR, isDE_D365 },
      translateService: { t },
    } = this.props;

    return isAdmin || isSuperAdmin ? (
      <div data-test="menu" className={classNames(['menu', 'float-start', { 'menu-open': layoutStore.menuOpen }])}>
        <div className="menu-wrapper">
          {isAdmin && (
            <ul data-test="admin-list">
              <li className="li-title clearfix">
                <span>{t.MENU_ADMIN_TITLE}</span>
              </li>
              <li>
                <NavLink to="/admin/user-management">
                  <FontAwesome name="users" />
                  {t.MENU_ADMIN_USER_MANAGEMENT}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/activity-log">
                  <FontAwesome name="server" />
                  {t.MENU_ADMIN_ACTIVITY_LOG}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/stock-items">
                  <FontAwesome name="recycle" />
                  {t.MENU_ADMIN_STOCK_ITEMS}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/suppliers">
                  <FontAwesome name="handshake-o" />
                  {t.MENU_ADMIN_SUPPLIERS}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/road-hauliers">
                  <FontAwesome name="truck" />
                  {t.MENU_ADMIN_ROAD_HAULIERS}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/yard-locations">
                  <FontAwesome name="location-arrow" />
                  {t.MENU_ADMIN_YARD_LOCATION}
                </NavLink>
              </li>
              {isUK && (
                <li data-test="printers">
                  <NavLink to="/admin/printers">
                    <FontAwesome name="print" />
                    {t.MENU_ADMIN_PRINTERS}
                  </NavLink>
                </li>
              )}
              <li>
                <LasernetPrinterMenu isExisted={!isUK} />
              </li>
              <li>
                <NavLink to="/admin/scales">
                  <FontAwesome name="balance-scale" />
                  {t.MENU_ADMIN_SCALES}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/materials">
                  <FontAwesome name="wrench" />
                  {t.MENU_ADMIN_MATERIALS}
                </NavLink>
              </li>
              {isDE_D365 && (
                <React.Fragment>
                  <li data-test="form-link">
                    <NavLink to="/admin/form">
                      <FontAwesome name="th-large" />
                      {t.GLOBAL_LABEL_FORM}
                    </NavLink>
                  </li>
                  <li data-test="quality-link">
                    <NavLink to="/admin/quality">
                      <FontAwesome name="th-large" />
                      {t.GLOBAL_LABEL_QUALITY}
                    </NavLink>
                  </li>
                </React.Fragment>
              )}
              {(isDE || isDE_D365) && (
                <React.Fragment>
                  <li data-test="machines-link">
                    <NavLink to="/admin/machines">
                      <FontAwesome name="th-large" />
                      {t.MENU_ADMIN_MACHINES}
                    </NavLink>
                  </li>
                  <li data-test="target-batches-link">
                    <NavLink to="/admin/target-batches">
                      <FontAwesome name="square" />
                      {t.MENU_ADMIN_TARGET_BATCHES}
                    </NavLink>
                  </li>
                  <li data-test="packaging-link">
                    <NavLink to="/admin/packaging">
                      <FontAwesome name="cube" />
                      {t.MENU_ADMIN_PACKAGING}
                    </NavLink>
                  </li>
                </React.Fragment>
              )}
              {isIT || isUK || isFR ? (
                <li data-test="ewcs-link">
                  <NavLink to="/admin/ewcs">
                    <FontAwesome name="th-large" />
                    {t.MENU_ADMIN_EWCS}
                  </NavLink>
                </li>
              ) : null}
              {isUK && (
                <li data-test="processing-link">
                  <NavLink to="/admin/processing">
                    <FontAwesome name="cog" />
                    {t.GLOBAL_LABEL_PROCESSING}
                  </NavLink>
                </li>
              )}
              {(isIT || isFR) && (
                <li data-test="origins-link">
                  <NavLink to="/admin/origins">
                    <FontAwesome name="square" />
                    {t.MENU_ADMIN_ORIGINS}
                  </NavLink>
                </li>
              )}
              {isUS && (
                <li data-test="contracts-link">
                  <NavLink to="/admin/contracts">
                    <FontAwesome name="file" />
                    {t.ADMIN_CONTRACTS_TITLE}
                  </NavLink>
                </li>
              )}
              <li className="li-title clearfix">
                <span>{t.MENU_ADMIN_APPLICATION}</span>
              </li>
              <li>
                <NavLink to="/dashboard">
                  <FontAwesome name="line-chart" />
                  {t.DASHBOARD_TITLES_DASHBOARD}
                </NavLink>
              </li>
              <li>
                <NavLink to="/deliveries">
                  <FontAwesome name="truck" />
                  {t.MENU_ADMIN_DELIVERY_LIST}
                </NavLink>
              </li>
            </ul>
          )}
          {isSuperAdmin && (
            <ul data-test="superAdmin-list">
              <li className="li-title clearfix">
                <span>{t.MENU_ADMIN_TITLE}</span>
              </li>
              <li data-test="superAdmin-list-tenants">
                <NavLink to="/super-admin/tenants">
                  <FontAwesome name="industry" />
                  {t.MENU_SUPERADMIN_TENANTS}
                </NavLink>
              </li>
              <li data-test="superAdmin-list-admins">
                <NavLink to="/super-admin/admins">
                  <FontAwesome name="users" />
                  {t.SUPERADMIN_TENANTADMIN_ADMINS}
                </NavLink>
              </li>
              <li data-test="superAdmin-list-app-users">
                <NavLink to="/super-admin/app-users">
                  <FontAwesome name="users" />
                  {t.SUPERADMIN_APP_USERS_TITLE}
                </NavLink>
              </li>
              <li data-test="superAdmin-list-connections">
                <NavLink to="/super-admin/connections">
                  <FontAwesome name="link" />
                  {t.SUPERADMIN_CONNECTIONS_TITLE}
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </div>
    ) : null;
  }
}

export default inject('sessionStore', 'layoutStore', 'viewStore', 'translateService')(observer(Menu));
