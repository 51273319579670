import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { ICode } from 'models/ModelInterfaces';

export default class IdCodeModel extends UpdateModel<IdCodeModel> implements ICode {
  public id?: string = '';

  public code: string = '';

  constructor() {
    super();

    makeObservable(this, {
      id: observable,
      code: observable,
      update: action,
      changeCode: action,
    });
  }

  public update(obj: IdCodeModel) {
    this.updater.update(this, obj, IdCodeModel);
    return this;
  }

  public changeCode = (newCode: string) => (this.code = newCode);
}
