import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { computed, makeObservable } from 'mobx';

import TranslateService from 'services/TranslateService';
import InputWithNullCheck from 'components/InputWithNullCheck';
import UserModel from 'models/UserModel';
import SessionStore from 'stores/SessionStore';
import TenantModel from 'models/TenantModel';
import UserTenantModel from 'models/UserTenantModel';
import CommonUserFields from 'pod/admin/commonComponents/CommonUserFields';
import UserTenantComponent from 'pod/admin/commonComponents/UserTenantComponent';
import Employees from 'pod/admin/commonComponents/Employees';
import { CountryCode } from 'util/enums';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IAdminDetailComponentProps {
  content: UserModel<IUserModelConstructObj>;
  translateService?: TranslateService;
  sessionStore?: SessionStore;
  tenants?: UserTenantModel[];
  selectedTenant?: TenantModel;
}

class AdminDetailComponent extends React.Component<IAdminDetailComponentProps> {
  constructor(props: IAdminDetailComponentProps) {
    super(props);

    makeObservable<AdminDetailComponent, '_isDE_D365Selected'>(this, {
      _isDE_D365Selected: computed,
    });
  }

  public componentDidMount(): void {
    const { content, selectedTenant } = this.props;
    // for new admin if tenant exists
    if (!content.tenants.length && !!selectedTenant) {
      content.addTenant(selectedTenant, true);
    }
  }

  public render() {
    const {
      content,
      translateService: { t },
      tenants,
    } = this.props;

    return !!content ? (
      <React.Fragment>
        {this._isDE_D365Selected && <Employees data-test="employees" content={content} />}
        <CommonUserFields content={content} />
        <FormGroup row className="remaining-padding" data-test="tenant-group">
          <Label sm={4}>{t.GLOBAL_LABEL_TENANTS_AVAILABLE}</Label>
          <Col sm={8} className="ps-0">
            {tenants.map((item: UserTenantModel) => (
              <UserTenantComponent
                key={item.id}
                tenants={tenants}
                tenant={item}
                content={content}
                disabled={this._isTenantDisabled(item.id)}
              />
            ))}
          </Col>
        </FormGroup>
        {this._isDisplayDefaultTenant && (
          <FormGroup row>
            <Label sm={4}>{t.GLOBAL_LABEL_DEFAULT_TENANT}</Label>
            <Col sm={8}>
              <InputWithNullCheck
                type="select"
                data-test="admin-default-tenant"
                disabled={content.tenants.length < 2}
                value={content.tenants.find((tenant: UserTenantModel) => tenant.isDefault)?.id}
                onChange={this._onDefaultTenantChange}
              >
                {content.tenants.map((tenant: UserTenantModel, index) => (
                  <option key={tenant.id || index} value={tenant.id}>
                    {tenant.name}
                  </option>
                ))}
              </InputWithNullCheck>
            </Col>
          </FormGroup>
        )}
      </React.Fragment>
    ) : null;
  }

  private get _isDisplayDefaultTenant(): boolean {
    return this.props.content.tenants.length > 0;
  }

  private _onDefaultTenantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.content.setIsDefaultTenant(e.target.value);
  };

  private _isTenantDisabled = (id: string) => {
    const { selectedTenant } = this.props;
    return !!selectedTenant && selectedTenant.id === id;
  };

  private get _isDE_D365Selected() {
    const { tenants, content } = this.props;
    return content.tenants.some(
      (tenant: UserTenantModel) => tenants.find((t) => t.id === tenant.id)?.workflowCode === CountryCode.DE_D365
    );
  }
}

export default inject('translateService', 'sessionStore')(observer(AdminDetailComponent));
