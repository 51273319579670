import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { IName } from 'models/ModelInterfaces';

export default class IdNameModel extends UpdateModel<IdNameModel> implements IName {
  public id?: string = null;
  public name: string = '';

  constructor() {
    super();

    makeObservable(this, {
      id: observable,
      name: observable,
      update: action,
      changeName: action,
    });
  }

  public update(obj: IdNameModel) {
    this.updater.update(this, obj, IdNameModel);
    return this;
  }

  public changeName = (newName: string) => (this.name = newName);
}
