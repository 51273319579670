import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import * as classnames from 'classnames';

import NavlinkCustom from 'domain/NavlinkCustom';
import YardLocationDetailRoute from 'pod/admin/yard-locations/YardLocationDetailRoute';
import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import IdNameActiveModel from 'models/IdNameActiveModel';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import { instance as notification } from 'util/notification';
import RefreshConfirmation from 'pod/admin/commonComponents/RefreshConfirmation';
import { Button } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import ViewStore from 'stores/ViewStore';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

class YardLocationListRoute extends React.Component<IProps> {
  public content: IdNameActiveModel = new IdNameActiveModel();
  public showConfirmation: boolean = false;

  public clearModel = () => {
    this.content = new IdNameActiveModel();
  };

  public toggleConfirmation = () => {
    this.showConfirmation = !this.showConfirmation;
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      showConfirmation: observable,
      clearModel: action,
      toggleConfirmation: action,
    });
  }

  public get yardLocationList() {
    const {
      translateService: { t },
      adminStore: { yardLocationListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="ps-3 pe-3">{t.GLOBAL_LABEL_NAME}</th>
          </tr>
        </thead>
        {yardLocationListActiveFirst.map((yardLocation: IdNameActiveModel) => this._renderItem(yardLocation))}
      </table>
    );
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { yardLocationApi },
      viewStore: { isDE_D365 },
    } = this.props;

    return (
      <React.Fragment>
        <CommonAdminListRoute
          api={yardLocationApi}
          rootPath={AdminRootPath.YARD_LOCATIONS}
          itemsTable={this.yardLocationList}
          itemCreateForm={null}
          componentRoute={YardLocationDetailRoute}
          clearModel={this.clearModel}
          content={this.content}
          sectionNameTranslation={t.ADMIN_SECTIONENTITY_YARD_LOCATION}
          exampleFileNames={ExampleFileNames.YARD_LOCATIONS}
          header={t.MENU_ADMIN_YARD_LOCATION}
          hasUploadBtn={!isDE_D365}
          hasCreateBtn={!isDE_D365}
          buttons={
            isDE_D365 ? (
              <Button
                data-test="trigger-button"
                className="float-end"
                color="success"
                onClick={this.toggleConfirmation}
              >
                <FontAwesome name="refresh" className="me-2" />
                {t.GLOBAL_LABEL_REFRESH_COMMON(t.MENU_ADMIN_YARD_LOCATION)}
              </Button>
            ) : null
          }
        />
        {isDE_D365 && (
          <RefreshConfirmation
            callbackYes={this._onRefresh}
            itemName={t.MENU_ADMIN_YARD_LOCATION}
            isOpen={this.showConfirmation}
            toggleConfirmation={this.toggleConfirmation}
          />
        )}
      </React.Fragment>
    );
  }

  private _renderItem = (yardLocation: IdNameActiveModel) => (
    <tbody key={yardLocation.id}>
      {this.props.viewStore.isDE_D365 ? (
        <tr key={yardLocation.id} className={classnames([{ 'bg-secondary': !yardLocation.active }])}>
          <td className="ps-3 pe-3">{yardLocation.name}</td>
        </tr>
      ) : (
        <NavlinkCustom
          to={`/admin/yard-locations/${yardLocation.id}`}
          tagName="tr"
          className={classnames(['pointer', { 'bg-secondary': !yardLocation.active }])}
        >
          <td className="ps-3 pe-3">{yardLocation.name}</td>
        </NavlinkCustom>
      )}
    </tbody>
  );

  private _onRefresh = async () => {
    const {
      translateService: { t },
      adminStore: { yardLocationApi },
    } = this.props;
    try {
      await this.props.adminStore.refreshYardLocations();
      notification.success(t.ADMIN_REFRESH_SUCCESS_MESSAGE(t.MENU_ADMIN_YARD_LOCATION));
    } catch (e) {
      notification.success(t.ADMIN_REFRESH_IN_PROGRESS_MESSAGE(t.MENU_ADMIN_YARD_LOCATION));
    } finally {
      yardLocationApi.getAllItems();
    }
  };
}

export default inject('adminStore', 'translateService', 'viewStore')(observer(YardLocationListRoute));
