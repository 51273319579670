import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { DateRangePicker, FocusedInputShape, isInclusivelyAfterDay } from 'react-dates';
/** Needed for DateRangePicker */
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/zh-cn';
import 'moment/locale/ja';
import '../../assets/sass/domain/DateRangePicker.scss';
import * as moment from 'moment-timezone';
import TranslateService from 'services/TranslateService';
import { MAX_MOBILE_PORTRAIT_WIDTH, DATE_FORMAT_DEFAULT } from 'util/constants';
import useWindowWidth from 'components/UseWindowWidth';
import DeliveryCommonFilterStore from 'pod/deliveries/DeliveryCommonFilterStore';
import ViewStore from 'stores/ViewStore';

moment.defineLocale('zh', { parentLocale: 'zh-cn' });

interface IProps {
  deliveryCommonFilterStore: DeliveryCommonFilterStore;
  getListData: (queryParams: string) => void;
  windowWidth?: number;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

export class DateRangePickerComponent extends React.Component<IProps> {
  private _focusedDate: FocusedInputShape = undefined;

  public changeDateRange = ({ startDate, endDate }: { startDate: moment.Moment; endDate: moment.Moment }): void => {
    const { deliveryCommonFilterStore, getListData } = this.props;

    deliveryCommonFilterStore.changeDateRange(startDate, endDate);
    getListData(deliveryCommonFilterStore.buildQueryString);
  };

  public changeDateInputFocus = (focusedInput: FocusedInputShape): void => {
    this._focusedDate = focusedInput;
  };

  constructor(props: IProps) {
    super(props);

    makeObservable<DateRangePickerComponent, '_focusedDate'>(this, {
      _focusedDate: observable,
      changeDateRange: action,
      changeDateInputFocus: action,
    });
  }

  public render(): React.ReactNode {
    const {
      deliveryCommonFilterStore: { startDate, endDate },
      translateService: { t },
      viewStore,
    } = this.props;

    return (
      <React.Fragment>
        <DateRangePicker
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="start_date_id" // PropTypes.string.isRequired,
          startDatePlaceholderText={t.GLOBAL_DATERANGEPICKER_START_DATE_PLH}
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="end_date_id" // PropTypes.string.isRequired,
          endDatePlaceholderText={t.GLOBAL_DATERANGEPICKER_END_DATE_PLH}
          onDatesChange={this.changeDateRange} // PropTypes.func.isRequired,
          focusedInput={this._focusedDate} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={this.changeDateInputFocus} // PropTypes.func.isRequired,
          isDayBlocked={this._isDayBlocked}
          small={true}
          showClearDates={true}
          isOutsideRange={() => false} // allow all days
          numberOfMonths={this._getNumberOfMonth}
          minimumNights={0}
          displayFormat={viewStore && viewStore.dateFormat ? viewStore.dateFormat : DATE_FORMAT_DEFAULT}
        />
      </React.Fragment>
    );
  }

  private get _getNumberOfMonth() {
    return this.props.windowWidth >= MAX_MOBILE_PORTRAIT_WIDTH ? 2 : 1;
  }

  private _isDayBlocked = (day: moment.Moment): boolean => {
    return !isInclusivelyAfterDay(moment.utc(), day);
  };
}

export default useWindowWidth(inject('translateService', 'viewStore')(observer(DateRangePickerComponent)));
