import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import SupplierModel, { SupplierTypes } from 'models/SupplierModel';
import TranslateService from 'services/TranslateService';
import ViewStore from 'stores/ViewStore';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import InputWithNullCheck from 'components/InputWithNullCheck';
import { getTranslation } from 'util/helpers';
import AdminStore from 'pod/admin/AdminStore';
import { I18N } from '../../../../assets/i18n/i18n';

interface ISuppliersDetailComponentProps {
  content: SupplierModel;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  adminStore?: AdminStore;
}

const focused = observable({
  expirationDate: false,
  certAutRifDate: false,
  revisionRequest: false,
  cert71513Date: false,
});
const changeExpirationDateInputFocus = action((args: { focused: boolean }): void => {
  focused.expirationDate = args.focused;
});
const changeCertAutRifDateInputFocus = action((args: { focused: boolean }): void => {
  focused.certAutRifDate = args.focused;
});
const changeRevisionRequestDateInputFocus = action((args: { focused: boolean }): void => {
  focused.revisionRequest = args.focused;
});
const changeCert71513DateDateInputFocus = action((args: { focused: boolean }): void => {
  focused.cert71513Date = args.focused;
});

const SupplierDetailComponent: React.FunctionComponent<ISuppliersDetailComponentProps> = ({
  content,
  viewStore: { isIT },
  translateService: { t },
  adminStore: { supplierTypes },
}) =>
  content &&
  isIT && (
    <React.Fragment>
      <FormGroup row data-test="supplier-type-input" key="supplierType">
        <Label for="supplierType" sm={4}>
          {t.GLOBAL_LABEL_TYPE}
        </Label>
        <Col sm={8}>
          <InputWithNullCheck
            data-test="supplier-type-input-value"
            type="select"
            id="supplierType"
            value={content.type}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.changeType(e.target.value)}
          >
            {supplierTypes.map((item: SupplierTypes) => (
              <option key={item} value={item}>
                {getTranslation(`GLOBAL_LABEL_SUPPLIER_TYPE_${item.toUpperCase()}` as keyof I18N, t)}
              </option>
            ))}
          </InputWithNullCheck>
        </Col>
      </FormGroup>
      <FormGroup row data-test="revision-request-input" key="revisionRequestDate">
        <Label for="revisionRequestDate" sm={4}>
          {t.GLOBAL_LABEL_REVISION_REQUEST}
        </Label>
        <Col sm={8}>
          <SingleDatePicker
            id="revisionRequestDate"
            date={content.revisionRequest}
            onDateChange={content.changeRevisionRequest}
            focused={focused.revisionRequest}
            onFocusChange={changeRevisionRequestDateInputFocus}
            showClearDate={true}
            numberOfMonths={1}
            isOutsideRange={() => false}
            small
          />
        </Col>
      </FormGroup>
      <FormGroup row data-test="cert-aut-rif-date-input" key="certAutRifDate">
        <Label for="certAutRifDate" sm={4}>
          {t.GLOBAL_LABEL_CERT_AUT_RIF_DATE}
        </Label>
        <Col sm={8}>
          <SingleDatePicker
            id="certAutRifDate"
            date={content.certAutRifDate}
            onDateChange={content.changeCertAutRifDate}
            focused={focused.certAutRifDate}
            onFocusChange={changeCertAutRifDateInputFocus}
            showClearDate={true}
            numberOfMonths={1}
            isOutsideRange={() => false}
            small
          />
        </Col>
      </FormGroup>
      <FormGroup row data-test="cert-71513-date-input" key="cert71513Date">
        <Label for="cert71513Date" sm={4}>
          {t.GLOBAL_LABEL_CERT_715_13}
        </Label>
        <Col sm={8}>
          <SingleDatePicker
            id="cert71513Date"
            date={content.cert71513Date}
            onDateChange={content.changeCert71513Date}
            focused={focused.cert71513Date}
            onFocusChange={changeCert71513DateDateInputFocus}
            showClearDate={true}
            numberOfMonths={1}
            isOutsideRange={() => false}
            small
          />
        </Col>
      </FormGroup>
      <FormGroup row data-test="expiration-date-input" key="expirationDate">
        <Label for="expirationDate" sm={4}>
          {t.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE}
        </Label>
        <Col sm={8}>
          <SingleDatePicker
            id="expirationDate"
            date={content.expirationDate}
            onDateChange={content.changeExpirationDate}
            focused={focused.expirationDate}
            onFocusChange={changeExpirationDateInputFocus}
            showClearDate={true}
            numberOfMonths={1}
            isOutsideRange={() => false}
            small
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  );

export default inject('viewStore', 'translateService', 'adminStore')(observer(SupplierDetailComponent));
