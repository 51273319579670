import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import DeliveryListRoute from 'pod/deliveries/DeliveryListRoute';
import DeliveryListLabRoute from 'pod/deliveries/DeliveryListLabRoute';
import DeliveryNewRoute from 'pod/deliveries/DeliveryNewRoute';
import DeliveryEditRoute from 'pod/deliveries/DeliveryEditRoute';

export default class DeliveryIndexRoute extends React.PureComponent {
  public render() {
    return (
      <Switch>
        <Route path="/deliveries" exact component={DeliveryListRoute} />
        <Route path="/deliveries/lab" exact component={DeliveryListLabRoute} />
        <Route path="/deliveries/new" component={DeliveryNewRoute} />
        <Route path="/deliveries/:id" component={DeliveryEditRoute} />
      </Switch>
    );
  }
}
