import RootService from 'services/RootService';
import { TData } from 'services/AjaxService';

interface ICommonContentProps {
  constructSaveObj: TData;
  changeActiveStatus?: (status: boolean) => void;
  id?: string;
}

export default class CommonApiCalls<T extends ICommonContentProps> {
  public constructor(
    private readonly rootService: RootService,
    private path: string,
    private setContentCallBack: (content: T[]) => void
  ) {}

  public async getAllItems() {
    const items = await this.getAllItemsRequest();
    this.setContentCallBack(items);
  }

  public getAllItemsRequest() {
    return this.rootService.ajaxService.get(`${this.path}?onlyActive=false`);
  }

  public uploadItemList(file: FormData) {
    return this.rootService.ajaxService.post(`${this.path}/import`, file).then(() => this.getAllItems());
  }

  public getItemById(itemId: string) {
    return this.rootService.ajaxService.get(`${this.path}/${itemId}`);
  }

  public async postItem(item: T) {
    await this.postItemRequest(item);
    return this.getAllItems();
  }

  public postItemRequest(item: T) {
    return this.rootService.ajaxService.post(this.path, item.constructSaveObj);
  }

  public async putItemById(item: T) {
    await this.putItemByIdRequest(item);
    return this.getAllItems();
  }

  public putItemByIdRequest(item: T) {
    return this.rootService.ajaxService.put(`${this.path}/${item.id}`, item.constructSaveObj);
  }

  public hideItemById(contentList: T[], itemId: string, errorMessage: string) {
    const hidedItem = contentList.find((item: T) => item.id === itemId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(false);
      return this.putItemById(hidedItem);
    } else {
      throw new Error(errorMessage);
    }
  }

  public unhideItemById(contentList: T[], itemId: string, errorMessage: string) {
    const hidedItem = contentList.find((item: T) => item.id === itemId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(true);
      return this.putItemById(hidedItem);
    } else {
      throw new Error(errorMessage);
    }
  }
}
