import '../../node_modules/noty/lib/noty.css';
import '../../node_modules/noty/lib/themes/bootstrap-v4.css';
import * as Noty from 'noty';

const timeout = 3500;
const errorTimeout = 10000;

class Notification {
  public success = (msg: string) => {
    this._createNotification('success', msg);
  };

  public info = (msg: string) => {
    this._createNotification('info', msg);
  };

  public warn = (msg: string) => {
    this._createNotification('warning', msg);
  };

  public error = (msg: string) => {
    this._createNotification('error', msg, { closeWith: ['click', 'button'], timeout: errorTimeout });
  };

  private _createNotification(type: Noty.Type, text: string, config?: Partial<Noty.Options>) {
    new Noty({
      theme: 'bootstrap-v4',
      type,
      text,
      timeout,
      ...config,
    }).show();
  }
}

export const instance = new Notification();
