import * as React from 'react';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import TenantFilterStore from 'stores/TenantFilterStore';
import { InputGroup } from 'reactstrap';
import InputWithNullCheck from 'components/InputWithNullCheck';
import IdNameModel from 'models/IdNameModel';

interface IProps {
  changeTenant: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tenantFilterStore: TenantFilterStore;
  translateService?: TranslateService;
}

class TenantPickerComponent extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public componentDidMount() {
    this.props.tenantFilterStore.getTenantsByRegion();
  }

  public render(): React.ReactNode {
    const {
      changeTenant,
      tenantFilterStore: { tenants, tenantId },
      translateService: { t },
    } = this.props;

    return (
      <React.Fragment>
        <InputGroup size="sm" className="max-width-170">
          <InputWithNullCheck data-test="tenant-filter" onChange={changeTenant} type="select" value={tenantId}>
            <option value="">{t.GLOBAL_LABEL_ALL_TENANTS}</option>
            {tenants &&
              tenants.map((tenant: IdNameModel) => (
                <option key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </option>
              ))}
          </InputWithNullCheck>
        </InputGroup>
      </React.Fragment>
    );
  }
}

export default inject('translateService')(observer(TenantPickerComponent));
