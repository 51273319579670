import UserModel from 'models/UserModel';
import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class OperatorModel extends UpdateModel<OperatorModel> {
  public date: string = '';
  public user: UserModel<IUserModelConstructObj> = new UserModel<IUserModelConstructObj>();

  public update = (obj: OperatorModel) => {
    const newOperatorModel = cloneObj(obj);

    if (newOperatorModel) {
      newOperatorModel.user = new UserModel<IUserModelConstructObj>().update(newOperatorModel.user);
    }

    this.updater.update(this, newOperatorModel, OperatorModel);
    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      date: observable,
      user: observable,
      update: action,
    });
  }
}
