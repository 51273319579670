import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { TenantSectionModel } from 'models/TenantSectionModel';
import TenantModel from 'models/TenantModel';

interface ISectionNavigationProps {
  sections: TenantSectionModel[];
  setActiveSection: (index: number) => void;
  tenant: TenantModel;
}

const SectionNavigation: React.FC<ISectionNavigationProps> = (props) => {
  const { sections, setActiveSection, tenant } = props;
  return <div className="d-flex section-nav">
    { sections.map((section: TenantSectionModel) =>
      <Button key={section.index} active={section.active} color={section.isInvalidSection(tenant) ? "danger" : "success"} className="section-nav-btn" onClick={() => setActiveSection(section.index)}>
        {section.name}
      </Button>
    ) }
  </div>
}

export default observer(SectionNavigation);
