import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, Label } from 'reactstrap';
import IdNameModel from 'models/IdNameModel';
import { AsyncAutocomplete } from 'components/AsyncAutocomplete';
import TranslateService from 'services/TranslateService';
import CommonStore from 'stores/CommonStore';
import Loading from 'domain/layout/Loading';
import Autocomplete from 'domain/Autocomplete';

interface IProductFormAndQualityProps {
  isExisted: boolean;
  stockItemId: string;
  shouldDownloadForm: boolean;
  onSelectForm: (item: IdNameModel) => void;
  shouldDownloadQuality: boolean;
  onSelectQuality: (item: IdNameModel) => void;
  productFormId: string;
  productQualityId: string;
  productFormClassName: string;
  productQualityClassName: string;
  isReadOnly: boolean;
  translateService?: TranslateService;
  commonStore?: CommonStore;
}

const ProductFormAndQuality: React.FC<IProductFormAndQualityProps> = ({
  isExisted,
  stockItemId,
  shouldDownloadForm,
  onSelectForm,
  productFormId,
  shouldDownloadQuality,
  onSelectQuality,
  productQualityId,
  productFormClassName,
  productQualityClassName,
  isReadOnly,
  translateService: { t },
  commonStore,
}) =>
  isExisted ? (
    <React.Fragment>
      <FormGroup data-test="product-form" row>
        <Label xs={3}>{t.GLOBAL_LABEL_FORM}</Label>
        <Col xs={9}>
          <React.Suspense fallback={<Loading />}>
            {shouldDownloadForm ? (
              <AsyncAutocomplete
                data-test="product-form-autocomplete"
                items={commonStore.getProductForm(stockItemId)}
                onSelect={onSelectForm}
                selectedValue={productFormId}
                inputClassName={productFormClassName}
                disabled={isReadOnly}
              />
            ) : (
              <Autocomplete data-test="product-form-dummy" inputClassName={productFormClassName} disabled />
            )}
          </React.Suspense>
        </Col>
      </FormGroup>
      <FormGroup data-test="product-quality" row>
        <Label xs={3}>{t.GLOBAL_LABEL_QUALITY}</Label>
        <Col xs={9}>
          <React.Suspense fallback={<Loading />}>
            {shouldDownloadQuality ? (
              <AsyncAutocomplete
                data-test="product-quality-autocomplete"
                items={commonStore.getProductQuality(stockItemId, productFormId)}
                onSelect={onSelectQuality}
                selectedValue={productQualityId}
                inputClassName={productQualityClassName}
                disabled={isReadOnly}
              />
            ) : (
              <Autocomplete data-test="product-quality-dummy" inputClassName={productQualityClassName} disabled />
            )}
          </React.Suspense>
        </Col>
      </FormGroup>
    </React.Fragment>
  ) : null;

export default inject('translateService', 'commonStore')(observer(ProductFormAndQuality));
