import * as React from 'react';
import * as classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

interface IProps extends RouteComponentProps {
  children: React.ReactNode;
  className?: string;
  disableBubble?: boolean;
  innerClick?: () => void;
  key?: string;
  tagName: string;
  to: string;
}

class NavlinkCustom extends React.Component<IProps> {
  public render() {
    // HACK: DO NOT REMOVE ANY PROPS
    const { tagName, to, className } = this.props;
    const isActive = location.pathname === to;

    return React.createElement(
      tagName,
      {
        onClick: this._navigate,
        className: classnames([className, { active: isActive }]),
      },
      this.props.children
    );
  }

  private _navigate = (event: React.MouseEvent) => {
    if (this.props.innerClick) {
      this.props.innerClick();
    }

    this.props.history.push(this.props.to);
    if (this.props.disableBubble) {
      event.stopPropagation();
    }
  };
}

export default withRouter(NavlinkCustom);
