import * as React from 'react';
import { Col, Row } from 'reactstrap';
import AttachmentList from 'domain/AttachmentList';
import FileModel from 'models/FileModel';

interface IProps {
  headerText: string;
  emptyListText: string;
  attachments: FileModel[];
  remove: (attachmentId: string) => void;
}

export default class DeliveryAttachments extends React.Component<IProps> {
  public render() {
    const { headerText, emptyListText, attachments, remove } = this.props;
    const hasAttachments = attachments.length > 0;
    return (
      <div>
        <h5 className="mt-4">{headerText}</h5>
        <Row className="bg-white border-top border-bottom">
          <Col data-test="attachments-list" xs={12} md={hasAttachments ? 6 : 12} className="pt-1 pb-1">
            {hasAttachments ? (
              <AttachmentList attachments={attachments} removeAttachment={remove} />
            ) : (
              <div className="text-center">{emptyListText}</div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
