import { action, computed, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import UserModel from 'models/UserModel';
import { AttachmentType } from 'util/enums';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class FileModel extends UpdateModel<FileModel> {
  public id: string = null;
  public name: string = '';
  public path: string = '';
  public type: AttachmentType = null;
  public thumbnailPath?: string = '';
  public createdBy?: UserModel<IUserModelConstructObj> = null;

  constructor() {
    super();

    makeObservable(this, {
      id: observable,
      name: observable,
      path: observable,
      type: observable,
      thumbnailPath: observable,
      createdBy: observable,
      isAttachmentPDF: computed,
      update: action,
    });
  }

  public get isAttachmentPDF(): boolean {
    return this.name.includes('.pdf');
  }

  public update = (obj: FileModel) => {
    const newFileModel = cloneObj(obj);

    if (newFileModel) {
      newFileModel.createdBy = newFileModel.createdBy
        ? new UserModel<IUserModelConstructObj>().update(newFileModel.createdBy)
        : new UserModel<IUserModelConstructObj>();
    }

    this.updater.update(this, newFileModel, FileModel);
    return this;
  };
}
