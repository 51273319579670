import { action, computed, observable, makeObservable } from 'mobx';

import RootService from 'services/RootService';
import IdCodeNameModel from 'models/IdCodeNameModel';
import EmployeeModel from 'models/EmployeeModel';

export default class EmployeeStore {
  public constructor(public readonly rootService: RootService) {
    makeObservable<EmployeeStore, '_setEmployees'>(this, {
      employees: observable,
      employeeList: computed,
      _setEmployees: action,
    });
  }

  public employees: EmployeeModel[] = [];

  public get employeeList(): IdCodeNameModel[] {
    return this.employees.map((item: EmployeeModel) => item.convertedIdCodeNameModel);
  }

  public getEmployeeByCode = (code: string): EmployeeModel => {
    return this.employees.find((e) => e.externalId === code);
  };

  public getEmployeeById = (id: string): EmployeeModel => {
    return this.employees.find((e) => e.id === id);
  };

  public triggerImportEmployee = async (): Promise<EmployeeModel[]> => {
    return this.rootService.ajaxService.post('employees/import/trigger', null, { timeout: 30000 });
  };

  public getEmployeeItems = async () => {
    const items: EmployeeModel[] = await this._getEmployeeRequest();
    this._setEmployees(items);
    return this.employees;
  };

  private _setEmployees = (res: EmployeeModel[]) => {
    this.employees = res.map((e: EmployeeModel) => new EmployeeModel().update(e));
  };

  private _getEmployeeRequest = async (): Promise<EmployeeModel[]> => {
    return this.rootService.ajaxService.get('employees');
  };
}
