import * as React from 'react';

interface IProps {
  error: Error;
}

export default class ErrorFallback extends React.Component<IProps> {
  public render() {
    const { error } = this.props;

    if (error) {
      return (
        <div style={{ padding: '50px' }}>
          <h5>{error.message}. Please try to restart the app.</h5>
        </div>
      );
    }

    return (
      <div style={{ padding: '50px' }}>
        <h5>Something went wrong.. Please try to restart the app.</h5>
      </div>
    );
  }
}
