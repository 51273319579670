import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, UncontrolledTooltip, TooltipProps } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import { action, observable, makeObservable } from 'mobx';
import { randomPasswordGenerator } from 'util/randomPasswordGenerator';

interface IRandomPasswordGeneratorDefaultButtonProps extends Partial<TooltipProps> {
  tooltipText?: string;
}

interface IRandomPasswordGeneratorButtonProps extends IRandomPasswordGeneratorDefaultButtonProps {
  passwordLength: number;
  callback: (password: string) => void;
  buttonClassName?: string;
}

class RandomPasswordGeneratorButton extends React.Component<IRandomPasswordGeneratorButtonProps> {
  public static defaultProps: IRandomPasswordGeneratorDefaultButtonProps = {
    tooltipText: '',
    placement: 'top',
  };

  private _btnRef: HTMLElement = null;

  constructor(props: IRandomPasswordGeneratorButtonProps) {
    super(props);

    makeObservable<RandomPasswordGeneratorButton, '_btnRef' | '_setRef'>(this, {
      _btnRef: observable,
      _setRef: action,
    });
  }

  public render() {
    const { tooltipText, placement } = this.props;
    return (
      <React.Fragment>
        <Button
          data-test="random-password-generator"
          innerRef={this._setRef}
          size="sm"
          className={classnames('position-center', this.props.buttonClassName)}
          onClick={this._generateRandomPassword}
        >
          <FontAwesome name="key" />
        </Button>
        {this._btnRef && tooltipText && (
          <UncontrolledTooltip
            data-test="tooltip"
            placement={placement}
            target={this._btnRef}
            modifiers={[
              {
                name: 'preventOverflow',
                options: { boundary: 'window' },
                enabled: true,
                phase: 'main',
                fn: () => null,
              },
            ]}
          >
            {tooltipText}
          </UncontrolledTooltip>
        )}
      </React.Fragment>
    );
  }

  private _generateRandomPassword = () => {
    const password = randomPasswordGenerator(this.props.passwordLength);
    this.props.callback(password);
  };

  private _setRef = (btn: HTMLElement) => {
    this._btnRef = btn;
  };
}

export default observer(RandomPasswordGeneratorButton);
