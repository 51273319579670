import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import { getTranslation } from 'util/helpers';
import { I18N } from '../../assets/i18n/i18n';
import InputWithNullCheck from 'components/InputWithNullCheck';

interface IProps {
  list: (Record<number, string> & { [key: string]: string }) | string[];
  selected: string;
  onChange: (item: string) => void;
  translateService?: TranslateService;
  tabIndexFrom?: number;
  disabled?: boolean;
}

class RadioComponent extends React.Component<IProps> {
  public render() {
    const { list, selected, tabIndexFrom, disabled } = this.props;

    const processList = Array.isArray(list)
      ? list
      : Object.keys(list).map((type) => {
          return list[type];
        });

    return (
      <FormGroup tag="fieldset">
        {processList.map((item: string, index: number) => (
          <FormGroup check key={`radio-${item}-${index}`} className="float-start me-3">
            <Label check disabled={disabled}>
              <InputWithNullCheck
                data-test="radio-input"
                type="radio"
                onChange={() => this.props.onChange(item)}
                checked={selected === item}
                style={{ marginTop: '2px' }}
                tabIndex={tabIndexFrom ? tabIndexFrom + index : null}
                disabled={disabled}
              />
              {getTranslation(`GLOBAL_RADIOBUTTON_${item}` as keyof I18N, this.props.translateService.t)}
            </Label>
          </FormGroup>
        ))}
      </FormGroup>
    );
  }
}

export default inject('translateService')(observer(RadioComponent));
