import { action, computed, observable, observe, makeObservable } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';

import UpdateModel from 'models/UpdateModel';
import MaterialModel from 'models/MaterialModel';
import { cloneObj } from 'util/helpers';
import { HAS_CHANGED } from 'util/constants';
import { ISetHasChanged } from 'util/objectUpdater';

export default class MaterialsModel extends UpdateModel<MaterialsModel> implements ISetHasChanged {
  constructor() {
    super();

    makeObservable(this, {
      material: observable,
      weight: observable,
      id: observable,
      shouldAddNewLine: computed,
      update: action,
      createDefaultMaterial: action,
      setMaterial: action,
      setWeight: action,
    });

    observe(this, this.onChange);
  }

  public material?: MaterialModel = null;
  public weight?: number = null;
  public id: string = null;

  public get shouldAddNewLine() {
    return !!this.material;
  }

  public onChange = (change: IObjectDidChange) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  public update = (obj: MaterialsModel) => {
    const newMaterialsModel = cloneObj(obj);

    if (newMaterialsModel) {
      newMaterialsModel.material = new MaterialModel().update(newMaterialsModel.material);
    }

    this.updater.update(this, newMaterialsModel, MaterialsModel);

    return this;
  };

  public createDefaultMaterial?(material: MaterialModel) {
    this.material = new MaterialModel().update(material);

    this.setHasChanged(false);
    return this;
  }

  public setMaterial(nonDefaultMaterials: MaterialModel[], newMaterialId: string) {
    this.material = nonDefaultMaterials.find((m: MaterialModel) => m.id === newMaterialId);
  }

  public setWeight(val: number) {
    this.weight = val;
  }
}
