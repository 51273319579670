import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { Button, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import TenantModel from 'models/TenantModel';
import TenantAdminLineComponent from 'pod/superAdmin/tenants/TenantAdminLineComponent';
import UserModel from 'models/UserModel';
import TranslateService from 'services/TranslateService';
import CommonAdminDetailComponent from 'pod/admin/commonComponents/CommonAdminDetailComponent';
import { sortActiveFirst } from 'util/helpers';
import AdminDetailComponent from 'pod/superAdmin/admins/AdminDetailComponent';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import UserTenantModel from 'models/UserTenantModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';
import { instance as notification } from 'util/notification';

interface IProps {
  tenant: TenantModel;
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}

class AdminSection extends React.Component<IProps> {
  public admin: UserModel<IUserModelConstructObj> = new UserModel<IUserModelConstructObj>();

  public isModalOpen: boolean = false;
  public workflowTenants: UserTenantModel[] = [];

  constructor(props: IProps) {
    super(props);

    makeObservable<
      AdminSection,
      | '_toggleIsModalOpen'
      | '_toggleIsModalOpenAndClearAdmin'
      | '_setWorkflowTenants'
    >(this, {
      admin: observable,
      isModalOpen: observable,
      workflowTenants: observable,
      clearAdmin: action,
      _setWorkflowTenants: action,
      _toggleIsModalOpen: action,
      _toggleIsModalOpenAndClearAdmin: action,
    });
  }
  componentDidMount() {
    const { superAdminStore, tenant} = this.props;

    superAdminStore.getTenantsForTheSameWorkflow(tenant).then((tenants) => {
      this._setWorkflowTenants(tenants);
    });
  }

  public clearAdmin = () => (this.admin = new UserModel<IUserModelConstructObj>());

  public saveAdminAndCloseModal = (user: UserModel<IUserModelConstructObj>) => {
    this._postAdmin(user).then(() => {
      this.clearAdmin();
      this._toggleIsModalOpen();
    });
  };

  public render() {
    const {
      tenant,
      translateService: { t },
    } = this.props;

    return (
      <div>
        {tenant.id && (
          <div>
            <h5 className="clearfix">
              <Button
                data-test="add-admin-btn"
                color="primary"
                onClick={this._toggleIsModalOpen}
                className="pull-right"
              >
                {t.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN}
              </Button>
            </h5>
            {tenant.admins && tenant.admins.length !== 0 ? (
              <table className="custom-table">
                <thead>
                <tr>
                  <th>{t.GLOBAL_LABEL_NAME}</th>
                  <th />
                </tr>
                </thead>
                {sortActiveFirst(tenant.admins).map((tenantAdmin) => (
                  <TenantAdminLineComponent
                    key={tenantAdmin.id}
                    tenantAdmin={tenantAdmin}
                    saveAction={this._saveAdmin}
                    hideAdmin={this._hideAdmin}
                    unhideAdmin={this._unhideAdmin}
                    workflowTenants={this.workflowTenants}
                    selectedTenant={tenant}
                  />
                ))}
              </table>
            ) : (
              <p className="text-muted text-center">{t.SUPERADMIN_TENANTADMIN_NO_ADMINS}</p>
            )}

            {this.isModalOpen && (
              <Modal
                data-test="create-admin-modal"
                isOpen={this.isModalOpen}
                toggle={this._toggleIsModalOpenAndClearAdmin}
                backdrop="static"
              >
                <ModalHeader toggle={this._toggleIsModalOpenAndClearAdmin}>
                  {t.SUPERADMIN_TENANTADMIN_HEADER}
                </ModalHeader>
                <ModalBody className="ps-0 pe-0 pt-0">
                  <Container className="pt-3">
                    <CommonAdminDetailComponent content={this.admin} saveAction={this.saveAdminAndCloseModal}>
                      <AdminDetailComponent content={this.admin} tenants={this.workflowTenants} selectedTenant={tenant} />
                    </CommonAdminDetailComponent>
                  </Container>
                </ModalBody>
              </Modal>
            )}
            <hr />
          </div>
        )}
      </div>
    );
  }
  private _saveAdmin = async (user: UserModel<IUserModelConstructObj>) => {
    return this.props.superAdminStore.putAdmin(user).then(() => {
      notification.success(this.props.translateService.t.SUPERADMIN_TENANTADMIN_UPDATE_MSG);
    });
  };

  private _postAdmin = async (user: UserModel<IUserModelConstructObj>) => {
    const { superAdminStore, tenant, translateService: { t } } = this.props;
    return superAdminStore.postAdmin(user).then((res) => {
      notification.success(t.SUPERADMIN_TENANTADMIN_ADD_MSG);
      tenant.pushAdmin(res);
    });
  };

  private _hideAdmin = async (user: UserModel<IUserModelConstructObj>) => {
    const { superAdminStore, tenant, translateService: { t } } = this.props;

    return superAdminStore.hideAdminById(tenant.admins, user.id).then(() => {
      notification.success(t.ADMIN_COMMONDETAIL_HIDE_MSG(user.fullName));
    });
  };

  private _unhideAdmin = async (user: UserModel<IUserModelConstructObj>) => {
    const { superAdminStore, tenant, translateService: { t } } = this.props;

    return superAdminStore.unhideAdminById(tenant.admins, user.id).then(() => {
      notification.success(t.ADMIN_COMMONDETAIL_UNHIDE_MSG(user.fullName));
    });
  };

  private _toggleIsModalOpen = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  private _toggleIsModalOpenAndClearAdmin = () => {
    this._toggleIsModalOpen();
    this.clearAdmin();
  };

  private _setWorkflowTenants = (tenants: UserTenantModel[]) => {
    this.workflowTenants = tenants;
  }
}

export default inject('translateService', 'superAdminStore')(observer(AdminSection));
