import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, InputGroup, Label } from 'reactstrap';
import * as classnames from 'classnames';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import InputWithNullCheck from 'components/InputWithNullCheck';
import SharepointModel from 'models/SharepointModel';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import { BORDER_DANGER } from 'util/constants';
import RequiredLabel from 'components/RequiredLabel';

interface IntegrationSectionProps {
  tenant: TenantModel;
  translateService?: TranslateService;
  superAdminStore?: SuperAdminStore;
}

class IntegrationSection  extends React.Component<IntegrationSectionProps> {
  componentDidMount() {
    const { tenant, superAdminStore } = this.props;
    superAdminStore.getSharePointSites();
  }

  public render() {
    const {
      tenant,
      translateService: {t},
      superAdminStore: { sharePointSiteList },
    } = this.props;
    return <>
      {this._hasIntegrationApiBase && (
        <FormGroup row className="remaining-padding">
          <RequiredLabel for="integrationApiBase" sm={4} required>
            {t.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE}
          </RequiredLabel>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="integration-api-base"
              id="integrationApiBase"
              maxLength={100}
              onChange={this._changeIntegrationApiBase}
              type="text"
              value={tenant.integrationApiBase}
            />
          </Col>
        </FormGroup>
      )}
      { this._hasSharePoint && <div>
        <h5 className="clearfix">{ t.GLOBAL_LABEL_SHAREPOINT }</h5>
        <FormGroup row className="remaining-padding">
          <RequiredLabel for="sharepoint-site" sm={ 4 } required>
            { t.SUPERADMIN_TENANTDETAIL_SITE }
          </RequiredLabel>
          <Col sm={ 8 }>
            <InputGroup size="md" className="full-width">
              <InputWithNullCheck
                data-test="sharepoint-site"
                type="select"
                id="sharepoint-site"
                value={ tenant.sharepointUploadFolder.id }
                className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('sharepointUploadFolderId') })}
                onChange={ this._changeSharePointSite }
              >
                <option value="" disabled>
                  { t.SUPERADMIN_SHAREPOINT_CHOOSE_SITE }
                </option>
                { sharePointSiteList &&
                  sharePointSiteList.map((site: SharepointModel) => (
                    <option key={ site.id } value={ site.id }>
                      { site.site }
                    </option>
                  )) }
              </InputWithNullCheck>
            </InputGroup>
          </Col>
        </FormGroup>
        { !!tenant.sharepointUploadFolder.path && (
          <FormGroup row className="remaining-padding">
            <Label for="image-path" sm={ 4 }>
              { t.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH }
            </Label>
            <Col sm={ 8 }>
              <div data-test="sharepoint-path" className="flex flex-nowrap-space-between word-break-all">
                <div>
                  <a data-test="sharepoint-path-link" href={ tenant.sharepointUploadFolder.path } target="_blank">
                    { tenant.sharepointUploadFolder.path }
                  </a>
                </div>
                <div className="margin-left-1rem">
                  <CopyToClipboardButton
                    text={ tenant.sharepointUploadFolder.path }
                    tooltipText={ t.GLOBAL_COPY_TITLE(t.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH.toLowerCase()) }
                    messageOnCopied={ t.GLOBAL_COPY_SUCCESS_MESSAGE(t.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH) }
                  />
                </div>
              </div>
            </Col>
          </FormGroup>
        ) }
      </div> }
    </>;
  }

  public get _hasIntegrationApiBase() {
    const { tenant, superAdminStore } = this.props;
    return superAdminStore.getSelectedWorkflow(tenant)?.isDE;
  }

  public get _hasSharePoint() {
    const { tenant, superAdminStore } = this.props;
    return superAdminStore.getSelectedWorkflow(tenant)?.isDE_D365;
  }

  private _changeIntegrationApiBase = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setIntegrationApiBase(e.target.value);
  };

  private _changeSharePointSite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      tenant,
      superAdminStore: { sharePointSiteList },
    } = this.props;
    const site = sharePointSiteList.find((s) => s.id === e.target.value);
    if (site) {
      tenant.setSharepointUploadFolder(site);
    }
  };
}

export default inject('translateService', 'superAdminStore')(observer(IntegrationSection));
