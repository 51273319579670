import SocketService from 'services/SocketService';
import AdminStore from 'pod/admin/AdminStore';
import AdvisedGoodLabStore from 'pod/advisedGoodLab/AdvisedGoodLabStore';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import DeliveryFiltersStore from 'pod/deliveries/DeliveryFiltersStore';
import LabStore from 'pod/deliveries/LabStore';
import CommonStore from 'stores/CommonStore';
import DeliveryStore from 'pod/deliveries/DeliveryStore';
import LayoutStore from 'stores/LayoutStore';
import SessionStore from 'stores/SessionStore';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import DashboardStore from 'pod/dashboard/DashboardStore';
import AjaxService from 'services/AjaxService';
import TranslateService from 'services/TranslateService';
import RootStore from 'stores/RootStore';
import ContractStore from 'stores/ContractStore';
import RootService from 'services/RootService';
import ReceivedGoodsStore from 'pod/receivedGoods/ReceivedGoodsStore';
import DeliveryLabFiltersStore from 'pod/deliveries/DeliveryLabFiltersStore';
import ViewStore from 'stores/ViewStore';
import DashboardFiltersStore from 'stores/DashboardFilterStore';
import DeviceTokenStore from 'stores/DeviceTokenStore';
import DateFormattingService from 'services/DateFormattingService';
import VersionStore from 'stores/VersionStore';
import EmployeeStore from 'stores/EmployeeStore';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';

export function createInjectables() {
  const rootService = new RootService();
  const rootStore = new RootStore();
  rootService.translateService = new TranslateService();
  rootService.ajaxService = new AjaxService(rootService, rootStore);
  rootService.dateFormattingService = new DateFormattingService(rootStore);

  rootService.socketService = new SocketService(rootService);
  rootStore.sessionStore = new SessionStore(rootService, rootStore);
  rootStore.viewStore = new ViewStore(rootStore);
  rootStore.commonStore = new CommonStore(rootService);
  rootStore.layoutStore = new LayoutStore();
  rootStore.adminStore = new AdminStore(rootService);
  rootStore.advisedGoodLabStore = new AdvisedGoodLabStore(rootService);
  rootStore.advisedGoodsStore = new AdvisedGoodsStore(rootService, rootStore);
  rootStore.deliveryFiltersStore = new DeliveryFiltersStore(rootService, rootStore);
  rootStore.deliveryLabFiltersStore = new DeliveryLabFiltersStore(rootService);
  rootStore.labStore = new LabStore(rootService);
  rootStore.deliveryStore = new DeliveryStore(rootService);
  rootStore.superAdminStore = new SuperAdminStore(rootService);
  rootStore.dashboardStore = new DashboardStore(rootService);
  rootStore.dashboardFilterStore = new DashboardFiltersStore(rootService, rootStore);
  rootStore.contractStore = new ContractStore(rootService);
  rootStore.receivedGoodsStore = new ReceivedGoodsStore(rootService, rootStore);
  rootStore.versionStore = new VersionStore();
  rootStore.deviceTokenStore = new DeviceTokenStore(rootService);
  rootStore.employeeStore = new EmployeeStore(rootService);
  rootStore.lasernetPrinterStore = new LasernetPrinterStore(rootService, rootStore);

  return {
    ...rootService,
    ...rootStore,
  };
}
