import { action, observable, makeObservable } from 'mobx';
import storage from 'util/storage';
import translation, { IDefaultExports } from '../../assets/i18n/languages';

export const defaultLanguage = 'en' as Language;
export type Language = keyof IDefaultExports;

export default class TranslateService {
  constructor() {
    makeObservable(this, {
      language: observable,
      t: observable,
      setLanguage: action,
    });

    const currentLanguage = storage.get('dops-language') as Language;
    this.setLanguage(currentLanguage ? currentLanguage : defaultLanguage);
  }

  public language = defaultLanguage;

  public t = translation[defaultLanguage];

  public setLanguage = (lang: Language) => {
    if (!translation.hasOwnProperty(lang)) {
      throw new Error(
        `TranslateService.setLanguage(): cannot set language to "${lang}", cannot find translation files`
      );
    }

    if (this.language === lang) {
      // nothing to do here
      return;
    }

    storage.set('dops-language', lang);

    this.language = lang;
    this.t = translation[lang];
  };

  public todo = (str: string): string => {
    return str;
  };
}
