import { action, autorun, computed, observable, observe, reaction, makeObservable } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';
import { autoAddNewLine } from 'util/mobx-utils';

import DeliverySubAdvisedGoodModel from 'models/DeliverySubAdvisedGoodModel';
import IdNameModel from 'models/IdNameModel';
import StockItemModel from 'models/StockItemModel';
import UserModel from 'models/UserModel';
import { AdvisedGoodStatus, AdvisedGoodWeighingTypes, CountryCode, DepartmentCode } from 'util/enums';
import IdCodeModel from 'models/IdCodeModel';
import { cloneObj } from 'util/helpers';
import { ISetHasChanged } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';
import ValidateModel from 'models/ValidateModel';
import FileModel from 'models/FileModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export interface IDeliveryAdvisedGoodConstructSaveObj {
  advisedDescription: string;
  firstWeight: number;
  advisedNetWeight: number;
  id: string;
  stockItemCode: string;
  subAdvisedGoods: DeliverySubAdvisedGoodModel[];
  advisedTareWeight: number;
  secondWeight: number;
  ticketNumber: string;
  ewcCode: string;
  yardLocationName: string;
  responsibleId: string;
  advisedGoodExternalIndex: string;
  productFormId?: string;
  productQualityId?: string;
  departmentCode?: DepartmentCode;
  advisedGrossWeight?: number;
  packaging?: string;
  comment?: string;
}

interface IDeliveryAdvisedGoodModelValidationKeys {
  advisedDescription: boolean;
  yardLocation: boolean;
  stockItem: boolean;
  ticketNumber: boolean;
  departmentCode: boolean;
  firstWeight: boolean;
  productForm: boolean;
  productQuality: boolean;
}

export type DeliveryAGValidatorKey = Partial<keyof IDeliveryAdvisedGoodModelValidationKeys>;

export default class DeliveryAdvisedGoodModel extends ValidateModel<IDeliveryAdvisedGoodModelValidationKeys>
  implements ISetHasChanged {
  constructor() {
    super();
    makeObservable(this, {
      advisedDescription: observable,
      advisedNetWeight: observable,
      advisedGrossWeight: observable,
      claimedBy: observable,
      firstWeight: observable,
      isFlagged: observable,
      stockItem: observable,
      subAdvisedGoods: observable,
      advisedTareWeight: observable,
      secondWeight: observable,
      ticketNumber: observable,
      ewcCode: observable,
      yardLocation: observable,
      createdAt: observable,
      id: observable,
      nonAdvisedGood: observable,
      responsible: observable,
      departmentCode: observable,
      status: observable,
      advisedGoodExternalIndex: observable,
      packaging: observable,
      comment: observable,
      attachments: observable,
      productForm: observable,
      productQuality: observable,
      weighingType: observable,
      netWeight: computed,
      netWeightWithZeroSecond: computed,
      isDirty: computed,
      validators: computed,
      isScaleWeights: computed,
      isInsideWeightTolerance: action,
      update: action,
      pushSubAdvisedGood: action,
      setFirstWeight: action,
      setSecondWeight: action,
      setAdvisedDescription: action,
      setAdvisedGrossWeight: action,
      setAdvisedNetWeight: action,
      setAdvisedTareWeight: action,
      setStockItem: action,
      setTicketNumber: action,
      setEwcCode: action,
      setYardLocation: action,
      setResponsible: action,
      setClaimBy: action,
      setDepartmentCode: action,
      setAdvisedGoodExternalIndex: action,
      setProductForm: action,
      setProductQuality: action,
      setPackaging: action,
      setComment: action,
      pushNewAttachment: action,
      removeAttachment: action,
      cleanSubAdvisedGoods: action,
      constructSaveObj: action,
    });
    observe(this, this.onChange);
    autorun(() => this.shouldTrigger);
    reaction(
      () => this.stockItem,
      () => {
        this.setProductForm(null);
      }
    );
    reaction(
      () => this.productForm,
      () => {
        this.setProductQuality(null);
      }
    );
  }

  public advisedDescription?: string = '';
  public advisedNetWeight?: number = null;
  public advisedGrossWeight?: number = null;
  public claimedBy?: UserModel<IUserModelConstructObj> = null;
  public firstWeight?: number = null;
  public isFlagged?: boolean = false;
  public stockItem?: StockItemModel = null;
  public subAdvisedGoods?: DeliverySubAdvisedGoodModel[] = [];
  public advisedTareWeight?: number = null;
  public secondWeight?: number = null;
  public ticketNumber?: string = '';
  public ewcCode?: string = null;
  public yardLocation?: IdNameModel = null;
  public createdAt?: string = '';
  public id?: string = null;
  public nonAdvisedGood?: boolean = false;
  public responsible?: IdNameModel = null;
  public departmentCode?: DepartmentCode = null;
  public status?: AdvisedGoodStatus = null;
  public advisedGoodExternalIndex?: string = null;
  public packaging?: string = null;
  public comment?: string = null;
  public attachments?: FileModel[] = [];
  public productForm?: IdNameModel = null;
  public productQuality?: IdNameModel = null;
  public weighingType?: string = AdvisedGoodWeighingTypes.WEIGHBRIDGE;

  public generalValidatorKeys: Array<keyof Partial<IDeliveryAdvisedGoodModelValidationKeys>> = [
    'advisedDescription',
    'yardLocation',
    'stockItem',
  ];
  public validationKeysByCountryCode: Map<
    CountryCode,
    Array<keyof Partial<IDeliveryAdvisedGoodModelValidationKeys>>
  > = new Map<CountryCode, Array<keyof Partial<IDeliveryAdvisedGoodModelValidationKeys>>>([
    [CountryCode.US, this.generalValidatorKeys],
    [CountryCode.IT, this.generalValidatorKeys.concat(['firstWeight', 'ticketNumber'])],
    [CountryCode.DE, this.generalValidatorKeys.concat(['departmentCode', 'ticketNumber'])],
    [CountryCode.DE_D365, this.generalValidatorKeys.concat(['ticketNumber', 'productForm', 'productQuality'])],
    [CountryCode.UK, this.generalValidatorKeys.concat(['ticketNumber'])],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  public get netWeight(): number {
    if (this.nonAdvisedGood) {
      // IF ADVISED GOOD WAS CREATED BY TRANSFERING, RETURN GROSS WEIGHT
      return this.firstWeight;
    } else {
      return this.firstWeight && this.secondWeight ? this.firstWeight - this.secondWeight : null;
    }
  }

  public get netWeightWithZeroSecond(): number {
    return this.firstWeight && typeof this.secondWeight === 'number' ? this.firstWeight - this.secondWeight : null;
  }

  public get isDirty() {
    return this.hasChanged || this.subAdvisedGoods.some((x) => x.hasChanged);
  }

  public get validators(): IDeliveryAdvisedGoodModelValidationKeys {
    return {
      advisedDescription: this.advisedDescription !== '',
      yardLocation: this.yardLocation !== null,
      stockItem: this.stockItem !== null,
      departmentCode: this.departmentCode !== null,
      ticketNumber: this.ticketNumber !== '',
      firstWeight: this.firstWeight !== null,
      productForm: this.productForm !== null || !this.stockItem?.requiresFormAndQuality,
      productQuality: this.productQuality !== null || !this.stockItem?.requiresFormAndQuality,
    };
  }

  public get isScaleWeights(): boolean {
    return this.weighingType === AdvisedGoodWeighingTypes.SCALE;
  }

  public get shouldTrigger() {
    return autoAddNewLine(this.subAdvisedGoods, () => this.pushSubAdvisedGood());
  }

  public onChange = (change: IObjectDidChange) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  public isInsideWeightTolerance(weighbridgeTolerance: number) {
    if (!this.advisedNetWeight || !this.netWeight) {
      return true;
    }

    const diff = this.advisedNetWeight - this.netWeight;

    return diff > -weighbridgeTolerance && diff < weighbridgeTolerance;
  }

  public update = (obj: DeliveryAdvisedGoodModel) => {
    const newDeliveryAdvisedGoodModel = cloneObj(obj);

    if (newDeliveryAdvisedGoodModel) {
      newDeliveryAdvisedGoodModel.subAdvisedGoods = newDeliveryAdvisedGoodModel.subAdvisedGoods
        ? newDeliveryAdvisedGoodModel.subAdvisedGoods.map((r: DeliverySubAdvisedGoodModel) =>
            new DeliverySubAdvisedGoodModel().update(r)
          )
        : [];
      if (newDeliveryAdvisedGoodModel.attachments && newDeliveryAdvisedGoodModel.attachments.length !== 0) {
        newDeliveryAdvisedGoodModel.attachments = newDeliveryAdvisedGoodModel.attachments.map((r: FileModel) =>
          new FileModel().update(r)
        );
      }
    }

    this.updater.update(this, newDeliveryAdvisedGoodModel, DeliveryAdvisedGoodModel);

    return this;
  };

  public pushSubAdvisedGood() {
    const newSubAdvisedGood = new DeliverySubAdvisedGoodModel();
    this.subAdvisedGoods.push(newSubAdvisedGood);

    return newSubAdvisedGood;
  }

  public setFirstWeight(val: number) {
    this.firstWeight = val;
  }

  public setSecondWeight(val: number) {
    this.secondWeight = val;
  }

  public setAdvisedDescription(val: string) {
    this.advisedDescription = val;
  }

  public setAdvisedGrossWeight(val: number) {
    this.advisedGrossWeight = val;
  }

  public setAdvisedNetWeight(val: number) {
    this.advisedNetWeight = val;
  }

  public setAdvisedTareWeight(val: number) {
    this.advisedTareWeight = val;
  }

  public setStockItem(val: StockItemModel) {
    this.stockItem = val;
  }

  public setTicketNumber(val: string) {
    this.ticketNumber = val;
  }

  public setEwcCode(val: IdCodeModel) {
    this.ewcCode = val ? val.code : '';
  }

  public setYardLocation(val: IdNameModel) {
    this.yardLocation = val;
  }

  public setResponsible(val: IdNameModel) {
    this.responsible = new IdNameModel().update(val);
  }

  public setClaimBy(val: UserModel<IUserModelConstructObj>) {
    this.claimedBy = new UserModel<IUserModelConstructObj>().update(val);
  }

  public setDepartmentCode(code: DepartmentCode) {
    this.departmentCode = code;
  }

  public setAdvisedGoodExternalIndex(val: string) {
    this.advisedGoodExternalIndex = val;
  }

  public setProductForm = (val: IdNameModel) => {
    this.productForm = val;
  };

  public setProductQuality = (val: IdNameModel) => {
    this.productQuality = val;
  };

  public setPackaging(val: string) {
    this.packaging = val;
  }

  public setComment(val: string) {
    this.comment = val;
  }

  public pushNewAttachment(obj: FileModel) {
    this.attachments.push(new FileModel().update(obj));
  }

  public removeAttachment(attachmentId: string) {
    this.attachments = this.attachments.filter((att) => att.id !== attachmentId);
  }

  public cleanSubAdvisedGoods() {
    this.subAdvisedGoods = this.subAdvisedGoods.filter(
      (subAdvisedGood) => subAdvisedGood.shouldAddNewLine && subAdvisedGood.constructSaveObj()
    );
  }

  // CONSTRUCT OBJ FOR POST/PUT DELIVERY
  public constructSaveObj(): IDeliveryAdvisedGoodConstructSaveObj {
    this.cleanSubAdvisedGoods();
    const res: IDeliveryAdvisedGoodConstructSaveObj = {
      advisedDescription: this.advisedDescription,
      advisedNetWeight: this.advisedNetWeight,
      firstWeight: this.firstWeight,
      id: this.id,
      stockItemCode: this.stockItem ? this.stockItem.code : '',
      subAdvisedGoods: this.subAdvisedGoods.filter((sag) => !!sag),
      advisedTareWeight: this.advisedTareWeight,
      secondWeight: this.secondWeight,
      ticketNumber: this.ticketNumber,
      ewcCode: this.ewcCode ? this.ewcCode : null,
      yardLocationName: this.yardLocation ? this.yardLocation.name : '',
      responsibleId: this.responsible?.id,
      departmentCode: this.departmentCode,
      advisedGoodExternalIndex: this.advisedGoodExternalIndex,
    };

    if (this.departmentCode) {
      res.departmentCode = this.departmentCode;
    }
    if (this.advisedGrossWeight) {
      res.advisedGrossWeight = this.advisedGrossWeight;
    }
    if (this.packaging) {
      res.packaging = this.packaging;
    }
    if (this.comment) {
      res.comment = this.comment;
    }
    if (this.productForm) {
      res.productFormId = this.productForm.id;
    }
    if (this.productQuality) {
      res.productQualityId = this.productQuality.id;
    }
    return res;
  }

  public isFirstWeightMoreThanMaxWeight(maxWeight: number): boolean {
    return this.firstWeight > maxWeight;
  }
}
