import { action, computed, observable, observe, makeObservable } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';

import UpdateModel from 'models/UpdateModel';
import { HAS_CHANGED } from 'util/constants';
import { ISetHasChanged } from 'util/objectUpdater';

export interface IMeasurementModelSaveObj {
  id: string;
  sampleWet: number;
  sampleDry: number;
  container: number;
  forMelting: number;
  ingot: number;
}

export default class MeasurementModel extends UpdateModel<MeasurementModel> implements ISetHasChanged {
  constructor() {
    super();

    makeObservable(this, {
      id: observable,
      sampleWet: observable,
      container: observable,
      sampleDry: observable,
      forMelting: observable,
      ingot: observable,
      humidity: computed,
      humidityWithTare: computed,
      yield: computed,
      setSampleWet: action,
      setContainer: action,
      setSampleDry: action,
      setForMelting: action,
      setIngot: action,
      update: action,
      constructSaveObj: action,
    });

    observe(this, this.onChange);
  }

  public id?: string = null;
  public sampleWet?: number = null;
  public container?: number = null;
  public sampleDry?: number = null;
  public forMelting?: number = null;
  public ingot?: number = null;

  public get humidity() {
    return this.sampleWet && this.sampleDry && (1 - this.sampleDry / this.sampleWet) * 100;
  }

  public get humidityWithTare() {
    return (
      this.sampleWet && this.sampleDry && ((this.sampleWet - this.sampleDry) / (this.sampleWet - this.container)) * 100
    );
  }

  public get yield() {
    return this.forMelting && this.ingot && (this.ingot / this.forMelting) * 100;
  }

  public onChange = (change: IObjectDidChange) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  public setSampleWet = (val: number) => {
    this.sampleWet = val;
  };

  public setContainer = (val: number) => {
    this.container = val;
  };

  public setSampleDry = (val: number) => {
    this.sampleDry = val;
  };

  public setForMelting = (val: number) => {
    this.forMelting = val;
  };

  public setIngot = (val: number) => {
    this.ingot = val;
  };

  public update = (obj: MeasurementModel) => {
    this.updater.update(this, obj, MeasurementModel);

    return this;
  };

  public constructSaveObj?(): IMeasurementModelSaveObj {
    return this._noValues
      ? null
      : {
          id: this.id,
          sampleWet: this.sampleWet,
          sampleDry: this.sampleDry,
          container: this.container,
          forMelting: this.forMelting,
          ingot: this.ingot,
        };
  }

  private get _noValues(): boolean {
    return !(this.sampleWet || this.sampleDry || this.forMelting || this.ingot);
  }
}
