import * as React from 'react';
import * as H from 'history';
import * as FontAwesome from 'react-fontawesome';
import { inject, observer } from 'mobx-react';
import { elgLogo } from 'util/images';

import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';
import * as classnames from 'classnames';

import SessionStore from 'stores/SessionStore';
import env from 'env';
import storage from 'util/storage';
import TranslateService from 'services/TranslateService';
import ChangeLanguage from 'components/ChangeLanguage';
import UIVersion from 'components/UIVersion';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import TenantModel from 'models/TenantModel';
import { logger } from 'util/logger';
import QuickLoginButtons from 'components/QuickLoginButtons';

interface IState {
  username: string;
  password: string;
  isTenantDropdownOpen: boolean;
  selectedTenant: TenantModel;
}

interface IProps {
  sessionStore?: SessionStore;
  history: H.History;
  translateService?: TranslateService;
  superAdminStore: SuperAdminStore;
}

class LoginRoute extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isTenantDropdownOpen: false,
      selectedTenant: null,
    };
  }

  public componentDidMount() {
    if (storage.get('sid')) {
      logger.warn('calling logout because sid already exist');
      this.props.sessionStore.logout(true);
    }

    if (!env.isProduction) {
      this._prefetchTenantsForNonProdEnv();
    }
  }

  public render() {
    const { username, password, isTenantDropdownOpen, selectedTenant } = this.state;
    const {
      superAdminStore: { activeTenants },
      translateService: { t },
    } = this.props;

    return (
      <Container className="overflow-x-hidden container-for-mobile">
        <Row>
          <Col xs="12" sm="10" md="7" lg="4" className="mx-auto ps-0 pe-0 pl-sm-3 pr-sm-3">
            <Card className="mt-0 mt-sm-4 min-width-385">
              <header className="login-header">
                <ChangeLanguage width="full-width" />
                {this._hasTenants && (
                  <Dropdown
                    data-test="login-tenant-picker"
                    isOpen={isTenantDropdownOpen}
                    toggle={this._tenantDropdownToggle}
                    className="max-width-margin-left-half-rem"
                  >
                    <DropdownToggle
                      outline={false}
                      size="sm"
                      caret
                      className={classnames(['btn btn-secondary btn-block margin-bottom text-truncate'])}
                    >
                      {selectedTenant
                        ? `${t.SUPERADMIN_TENANTADMIN_TENANT}: ${selectedTenant.name}`
                        : t.SUPERADMIN_TENANTADMIN_TENANT}
                    </DropdownToggle>
                    <DropdownMenu>
                      {activeTenants.map((tenant) => (
                        <DropdownItem
                          data-test="login-tenant-item"
                          key={tenant.code}
                          className={classnames({ active: selectedTenant === tenant })}
                          onClick={() => this._tenantDropdownSelect(tenant)}
                        >
                          {tenant.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                )}
              </header>
              <header className="login-header">
                <UIVersion />
              </header>

              <CardBody>
                <div className="clearfix">
                  <img src={elgLogo} alt="ELG" className="login-logo float-end" />
                  <h2>{t.GLOBAL_LOGIN_HEADER}</h2>
                  <p className="text-muted margin-bottom-1_5rem">{t.GLOBAL_LOGIN_TITLE}</p>
                </div>

                <Form className="margin-top-1rem">
                  <InputGroup className="margin-bottom-1rem">
                    <span className="input-group-text">
                      <FontAwesome name="user" />
                    </span>
                    <Input
                      autoFocus
                      data-test="user-name"
                      onChange={(v) => this.setState({ username: v.target.value })}
                      onKeyDown={this._onHitEnterKey}
                      placeholder={t.GLOBAL_LABEL_USERNAME}
                      type="text"
                      value={username}
                    />
                  </InputGroup>
                  <InputGroup className="margin-bottom-1_5rem">
                    <span className="input-group-text">
                      <FontAwesome name="lock" />
                    </span>
                    <Input
                      onChange={(v) => this.setState({ password: v.target.value })}
                      onKeyDown={this._onHitEnterKey}
                      placeholder={t.GLOBAL_LABEL_PASSWORD}
                      type="password"
                      value={password}
                    />
                  </InputGroup>

                  <Button
                    block
                    disabled={this._isLoginButtonDisabled}
                    color="success"
                    onClick={this._executeLogin}
                    type="button"
                    className="margin-bottom-1rem"
                  >
                    {t.GLOBAL_LOGIN_HEADER}
                  </Button>
                </Form>

                {this._hasQuickLoginButtons && (
                  <QuickLoginButtons devLogin={this._devLogin} selectedTenant={selectedTenant} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  private get _hasTenants() {
    const {
      superAdminStore: { activeTenants },
    } = this.props;
    return !env.isProduction && typeof activeTenants !== 'undefined' && activeTenants !== null && activeTenants.length > 0;
  }

  private get _isLoginButtonDisabled() {
    return !(this.state.username && this.state.password && this.state.password.length >= 6);
  }

  private get _hasQuickLoginButtons() {
    return !env.isProduction;
  }

  private async _prefetchTenantsForNonProdEnv() {
    const { sessionStore, superAdminStore } = this.props;

    const loggedUser = await sessionStore.login(`super-admin`, 'asdfasdf');

    if (!loggedUser.requiresPasswordChange) {
      await superAdminStore.getTenantsForDevLogin();

      if (superAdminStore.activeTenants && superAdminStore.activeTenants.length) {
        const currentTenantCode = storage.get('dops-tenant');
        const currentTenant = superAdminStore.activeTenants.find((tenant) => tenant.code === currentTenantCode);
        this.setState({ selectedTenant: currentTenant || superAdminStore.activeTenants[0] });
      }
    }

    sessionStore.logout(true);
  }

  private _executeLogin = () => {
    const { sessionStore } = this.props;

    sessionStore.login(this.state.username, this.state.password).then((res) => {
      const {
        history,
        sessionStore: { isSuperAdmin, isAdmin, isLab },
      } = this.props;

      if (res.requiresPasswordChange) {
        history.push(`/auth/recover-password?username=${this.state.username}`);
      } else if (isSuperAdmin) {
        history.push('/super-admin/tenants');
      } else if (isAdmin) {
        history.push('/admin/user-management');
      } else if (isLab) {
        history.push('/deliveries/lab');
      } else {
        history.push('/deliveries');
      }
    });
  };

  private _devLogin = (username: string, password: string) => {
    this.setState(
      {
        username,
        password,
      },
      this._executeLogin
    );
  };

  private _onHitEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this._executeLogin();
    }
  };

  private _tenantDropdownToggle = () => {
    this.setState({
      isTenantDropdownOpen: !this.state.isTenantDropdownOpen,
    });
  };

  private _tenantDropdownSelect(tenant: TenantModel) {
    storage.set('dops-tenant', tenant.code);
    this.setState({ selectedTenant: tenant });
  }
}

export default inject('sessionStore', 'translateService', 'superAdminStore')(observer(LoginRoute));
