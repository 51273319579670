import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';

const Loading = () => (
  <div className="loading">
    <div className="centerer">
      <FontAwesome name="spinner" size="2x" spin />
    </div>
  </div>
);

export default Loading;
