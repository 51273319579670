import { computed, makeObservable } from 'mobx';
import { stringifyNonNullableObj } from 'util/helpers';

export default abstract class FilterAbstractStore<T, K extends {}> {
  protected abstract get getFiltersApplied(): T;
  protected abstract get getQueryParams(): K;

  constructor() {
    makeObservable(this, {
      buildQueryString: computed,
      hasFiltersApplied: computed,
    });
  }

  public get buildQueryString(): string {
    return `?${stringifyNonNullableObj(this.getQueryParams, { arrayFormat: 'none' })}`;
  }

  public get hasFiltersApplied(): T {
    return this.getFiltersApplied;
  }
}
