import * as React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, UncontrolledTooltip, TooltipProps } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import { instance as notification } from 'util/notification';
import * as classnames from 'classnames';

interface IProps extends Partial<TooltipProps> {
  messageOnCopied?: string;
  text?: string;
  tooltipText?: string;
  buttonClassName?: string;
}

interface IDefaultProps extends Partial<TooltipProps> {
  text?: string;
}

class CopyToClipboardButton extends React.Component<IProps> {
  public static defaultProps: IDefaultProps = {
    text: '',
    placement: 'top',
  };

  private _btnRef: HTMLElement = null;

  public copyToClipboard = () => {
    const { text, messageOnCopied } = this.props;
    if (!!text) {
      const textField = document.createElement('textarea');
      textField.value = text;
      textField.style.position = 'absolute';
      textField.style.left = '-9999px';
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      if (messageOnCopied) {
        notification.success(messageOnCopied);
      }
    }
  };

  public setRef = (btn: HTMLElement) => {
    this._btnRef = btn;
  };

  constructor(props: IProps) {
    super(props);

    makeObservable<CopyToClipboardButton, '_btnRef'>(this, {
      _btnRef: observable,
      setRef: action,
    });
  }

  public render() {
    const { tooltipText, placement } = this.props;
    return (
      <React.Fragment>
        <Button
          innerRef={this.setRef}
          size="sm"
          className={classnames('position-center', this.props.buttonClassName)}
          onClick={this.copyToClipboard}
        >
          <FontAwesome name="copy" />
        </Button>
        {this._btnRef && tooltipText && (
          <UncontrolledTooltip
            placement={placement}
            target={this._btnRef}
            modifiers={[
              {
                name: 'preventOverflow',
                options: { boundary: 'window' },
                enabled: true,
                phase: 'main',
                fn: () => null,
              },
            ]}
          >
            {tooltipText}
          </UncontrolledTooltip>
        )}
      </React.Fragment>
    );
  }
}

export default observer(CopyToClipboardButton);
