import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { action, computed, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';

import { instance as notification } from 'util/notification';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import TranslateService from 'services/TranslateService';
import AppUserModel from 'models/AppUserModel';
import ModalConfirmation from 'domain/ModalConfirmation';
import AppUserForm from 'pod/superAdmin/appUsers/AppUserForm';

interface IProps {
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}

class AppUsersListRoute extends React.Component<IProps> {
  public addAppUserModal: boolean = false;
  public appUser: AppUserModel = new AppUserModel();
  private _hideConfirmationModal: boolean = false;
  private _unhideConfirmationModal: boolean = false;

  constructor(props: IProps) {
    super(props);

    makeObservable<
      AppUsersListRoute,
      | '_hideConfirmationModal'
      | '_unhideConfirmationModal'
      | '_setAddAppUserModal'
      | '_setNewAppUserModel'
      | '_toggleHideConfirmationModal'
      | '_toggleUnhideConfirmationModal'
    >(this, {
      addAppUserModal: observable,
      appUser: observable,
      _hideConfirmationModal: observable,
      _unhideConfirmationModal: observable,
      toggleHideUserCallback: computed,
      toggleUnhideUserCallback: computed,
      _setAddAppUserModal: action,
      _setNewAppUserModel: action,
      _toggleHideConfirmationModal: action,
      _toggleUnhideConfirmationModal: action,
    });
  }

  public get toggleHideUserCallback(): () => void | undefined {
    return !this.appUser.isNew ? this._toggleHideConfirmationModal : undefined;
  }

  public get toggleUnhideUserCallback(): () => void | undefined {
    return !this.appUser.isNew ? this._toggleUnhideConfirmationModal : undefined;
  }

  public componentDidMount() {
    const { superAdminStore } = this.props;
    superAdminStore.getAppUsers();
  }

  // SHOW / HIDE MODAL WINDOW
  public toggleAddAppUserModal = () => {
    this._setAddAppUserModal(!this.addAppUserModal);
    if (!this.addAppUserModal) {
      this._setNewAppUserModel(new AppUserModel());
    }
  };

  public saveNewAppUser = (appUser: AppUserModel) => {
    const {
      superAdminStore,
      translateService: { t },
    } = this.props;
    return superAdminStore.postAppUser(appUser).then(() => {
      this._updateOnSave();
      notification.success(t.SUPERADMIN_APP_USERS_ADD_MSG);
    });
  };

  public putAppUser = (user: AppUserModel) => {
    const {
      superAdminStore,
      translateService: { t },
    } = this.props;
    return superAdminStore.putAppUser(user).then(() => {
      this._updateOnSave();
      notification.success(t.SUPERADMIN_APP_USERS_UPDATE_MSG);
    });
  };

  public hideAppUser = () => {
    return this.props.superAdminStore.hideAppUserById(this.props.superAdminStore.appUsers, this.appUser.id).then(() => {
      this.appUser.changeActiveStatus(false);
      notification.success(this.props.translateService.t.ADMIN_COMMONDETAIL_HIDE_MSG(this.appUser.fullName));
    });
  };

  public unhideAppUser = () => {
    return this.props.superAdminStore
      .unhideAppUserById(this.props.superAdminStore.appUsers, this.appUser.id)
      .then(() => {
        this.appUser.changeActiveStatus(true);
        notification.success(this.props.translateService.t.ADMIN_COMMONDETAIL_UNHIDE_MSG(this.appUser.fullName));
      });
  };

  public saveAction = (user: AppUserModel) => {
    user.isNew ? this.saveNewAppUser(user) : this.putAppUser(user);
  };

  public render() {
    const {
      superAdminStore: { appUsersListActiveFirst },
      translateService: { t },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {t.SUPERADMIN_APP_USERS_TITLE}
              <Button
                data-test="add-new-app-user-button"
                className="float-end"
                color="success"
                onClick={this.toggleAddAppUserModal}
              >
                <FontAwesome name="plus" className="me-2" />
                {t.GLOBAL_LABEL_ADD_NEW}
              </Button>
            </h2>
            <table className="custom-table mb-3">
              <thead>
                <tr>
                  <th className="ps-3">{t.GLOBAL_LABEL_NAME}</th>
                  <th className="pe-3">{t.GLOBAL_LABEL_USERNAME}</th>
                </tr>
              </thead>
              {appUsersListActiveFirst.map((appUser: AppUserModel) => (
                <tbody key={appUser.id} data-test="app-user-table-item">
                  <tr
                    data-test={`app-user-table-item-${appUser.username}`}
                    onClick={this._onAppUserClick(appUser.id)}
                    className={classnames(['pointer', { 'bg-secondary': !appUser.active }])}
                  >
                    <td className="ps-3">{appUser.fullName}</td>
                    <td className="pe-3">{appUser.username}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Col>
        </Row>

        {/* MODAL - CREATE NEW APP USER */}
        <Modal
          isOpen={this.addAppUserModal}
          toggle={this.toggleAddAppUserModal}
          backdrop="static"
          data-test="create-app-user-modal"
        >
          <ModalHeader toggle={this.toggleAddAppUserModal}>{t.SUPERADMIN_APP_USERS_ADD_TITLE}</ModalHeader>
          <ModalBody>
            <AppUserForm
              content={this.appUser}
              saveAction={this.saveAction}
              toggleHideConfirmationModal={this.toggleHideUserCallback}
              toggleUnhideConfirmationModal={this.toggleUnhideUserCallback}
            />
          </ModalBody>
        </Modal>

        <ModalConfirmation
          data-test="hide-confirm-modal"
          buttonYesColor="success"
          callbackYes={() => this.hideAppUser()}
          heading={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE}
          ico="eye-slash"
          isOpen={this._hideConfirmationModal}
          text={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE(this.appUser.fullName)}
          toggleModal={this._toggleHideConfirmationModal}
        />

        <ModalConfirmation
          data-test="unhide-confirm-modal"
          buttonYesColor="success"
          callbackYes={() => this.unhideAppUser()}
          heading={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE}
          ico="eye"
          isOpen={this._unhideConfirmationModal}
          text={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE(this.appUser.fullName)}
          toggleModal={this._toggleUnhideConfirmationModal}
        />
      </Container>
    );
  }

  private _updateOnSave = () => {
    this.props.superAdminStore.getAppUsers();
    this.toggleAddAppUserModal();
    this._setNewAppUserModel(new AppUserModel());
  };

  private _onAppUserClick = (id: string) => () => {
    const { superAdminStore } = this.props;
    const appUser = superAdminStore.getAppUserById(id);
    if (!!appUser) {
      const appUserModel = new AppUserModel().update(appUser);
      this._setNewAppUserModel(appUserModel);
      this.toggleAddAppUserModal();
    }
  };

  private _setAddAppUserModal = (val: boolean) => {
    this.addAppUserModal = val;
  };

  private _setNewAppUserModel = (val: AppUserModel) => {
    this.appUser = val;
  };

  private _toggleHideConfirmationModal = () => {
    this._hideConfirmationModal = !this._hideConfirmationModal;
  };

  private _toggleUnhideConfirmationModal = () => {
    this._unhideConfirmationModal = !this._unhideConfirmationModal;
  };
}

export default inject('superAdminStore', 'translateService')(observer(AppUsersListRoute));
