import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as H from 'history';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';
import TenantDetail from 'pod/superAdmin/tenants/TenantDetail';
import { Col, Container, Row } from 'reactstrap';

interface IProps {
  history: H.History;
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}

class AddNewTenantRoute extends React.Component<IProps> {
  public tenant = new TenantModel();

  public componentDidMount() {
    const {
      superAdminStore,
    } = this.props;

    superAdminStore.getWorkflows();
  }

  public render() {
    const {
      translateService: { t },
      history
    } = this.props;
    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2>{t.SUPERADMIN_TENANTLIST_CREATE_NEW}</h2>
            <TenantDetail
              tenant={this.tenant}
              history={history}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default inject('superAdminStore', 'translateService')(observer(AddNewTenantRoute));
