import { action, computed, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

import IdNameModel from 'models/IdNameModel';
import OperatorModel from 'models/OperatorModel';
import UserModel from 'models/UserModel';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';
import { AdvisedGoodWeighingTypes } from 'util/enums';

export default class DeliveryListAdvisedGoodModel extends UpdateModel<DeliveryListAdvisedGoodModel> {
  public claimedBy?: OperatorModel = new OperatorModel();
  public status?: string = '';
  public advisedDescription?: string = '';
  public createdAt?: string = '';
  public deliveryId?: string = '';
  public isFlagged?: boolean = false;
  public wasFlagged?: boolean = false;
  public agTitle?: string = '';
  public id?: string = null;
  public index?: number = null;
  public nonAdvisedGood?: boolean = false;
  public responsible?: UserModel<IUserModelConstructObj> = null;
  public yardLocation?: IdNameModel = null;
  public weightDiscrepancy?: boolean = false;
  public weighingType?: string = AdvisedGoodWeighingTypes.WEIGHBRIDGE;

  public update = (obj: DeliveryListAdvisedGoodModel) => {
    const newDeliveryListAdvisedGoodModel = cloneObj(obj);

    if (newDeliveryListAdvisedGoodModel) {
      newDeliveryListAdvisedGoodModel.yardLocation = new IdNameModel().update(
        newDeliveryListAdvisedGoodModel.yardLocation
      );
      if (newDeliveryListAdvisedGoodModel.claimedBy && newDeliveryListAdvisedGoodModel.claimedBy.user !== null) {
        newDeliveryListAdvisedGoodModel.claimedBy = new OperatorModel().update(
          newDeliveryListAdvisedGoodModel.claimedBy
        );
      }

      if (newDeliveryListAdvisedGoodModel.responsible) {
        newDeliveryListAdvisedGoodModel.responsible = new UserModel<IUserModelConstructObj>().update(
          newDeliveryListAdvisedGoodModel.responsible
        );
      }
    }

    this.updater.update(this, newDeliveryListAdvisedGoodModel, DeliveryListAdvisedGoodModel);
    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      claimedBy: observable,
      status: observable,
      advisedDescription: observable,
      createdAt: observable,
      deliveryId: observable,
      isFlagged: observable,
      wasFlagged: observable,
      agTitle: observable,
      id: observable,
      index: observable,
      nonAdvisedGood: observable,
      responsible: observable,
      yardLocation: observable,
      weightDiscrepancy: observable,
      weighingType: observable,
      update: action,
      setClaimedBy: action,
      setStatus: action,
      setResponsible: action,
      isScaleWeights: computed,
    });
  }

  public setClaimedBy(user: UserModel<IUserModelConstructObj>) {
    this.claimedBy = new OperatorModel().update({ date: '', user } as OperatorModel);
  }

  public setStatus(val: string) {
    this.status = val;
  }

  public setResponsible(val: UserModel<IUserModelConstructObj>) {
    this.responsible = val;
  }

  public get isScaleWeights(): boolean {
    return this.weighingType === AdvisedGoodWeighingTypes.SCALE;
  }
}
