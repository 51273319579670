import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as H from 'history';
import { Route } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import TranslateService from 'services/TranslateService';
import TenantListComponent from 'pod/superAdmin/tenants/TenantListComponent';
import TenantDetailRoute from 'pod/superAdmin/tenants/TenantDetailRoute';

interface IProps {
  history: H.History;
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}

class TenantListRoute extends React.Component<IProps> {
  public componentDidMount() {
    const { superAdminStore } = this.props;
    superAdminStore.getTenants();
    superAdminStore.getWorkflows();
    superAdminStore.getTenantCommon();
    superAdminStore.getSharePointSites();
  }

  public toggleAddTenantModal = () => {
    this.props.history.push('/super-admin/add-new-tenant');
  };

  public render() {
    const {
      superAdminStore: { workflowList },
      translateService: { t },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {t.SUPERADMIN_TENANTLIST_HEADER}
              <Button
                data-test="add-new-button"
                className="float-end"
                color="success"
                onClick={this.toggleAddTenantModal}
              >
                <FontAwesome name="plus" className="me-2" />
                {t.SUPERADMIN_TENANTLIST_ADD_BTN}
              </Button>
            </h2>
            <Route exact path="/super-admin/tenants/" component={TenantListComponent} />
          </Col>
        </Row>

        <Route path="/super-admin/tenants/:id" component={TenantDetailRoute} />
      </Container>
    );
  }
}

export default inject('superAdminStore', 'translateService')(observer(TenantListRoute));
