class PromiseResolver<T> {
  public constructor() {
    this.createNewPromise();
  }
  public rejectPromise: (reason?: T) => void = null;
  public resolvePromise: (reason?: T) => void = null;
  public promise: Promise<T> = null;

  public createNewPromise = () => {
    this.promise = new Promise((resolve, reject) => {
      this.rejectPromise = reject;
      this.resolvePromise = resolve;
    });
  };
}

export default PromiseResolver;
