import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import { Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import * as H from 'history';

import SessionStore from 'stores/SessionStore';
import TranslateService from 'services/TranslateService';
import ChangeLanguage from 'components/ChangeLanguage';
import UIVersion from 'components/UIVersion';
import { getTranslation } from 'util/helpers';
import { I18N } from '../../../assets/i18n/i18n';
import ChangeUserRole from 'components/ChangeUserRole';
import UserRoleModel from 'models/UserRoleModel';
import ChangeTenant from 'components/ChangeTenant';

interface IUserContentMenuProps {
  show: boolean;
  toggleLogoutModal: () => void;
  onUserRoleChange: (newRole: UserRoleModel) => void;
  onTenantChange: (id: string) => void;
  changeUserMenuIsOpen: (value: boolean) => void;
  translateService?: TranslateService;
  sessionStore?: SessionStore;
  history?: H.History;
}

class UserContentMenu extends React.Component<IUserContentMenuProps> {
  private _div = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    document.addEventListener('click', this.handleOnclick);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleOnclick);
  }

  public handleOnclick = (e: Event) => {
    const domNode = this._div.current;
    if (!domNode || !domNode.contains(e.target as Node)) {
      this.props.changeUserMenuIsOpen(false);
    }
  };

  public render() {
    const {
      show,
      toggleLogoutModal,
      changeUserMenuIsOpen,
      translateService: { t },
      sessionStore: { user, isAdmin },
      onUserRoleChange,
      onTenantChange,
    } = this.props;

    return (
      <div ref={this._div}>
        <div onClick={() => changeUserMenuIsOpen(!show)}>
          <div className="user-menu">
            <FontAwesome name={show ? 'caret-up' : 'caret-down'} />
          </div>
          <div data-test="user-full-name" className="user-profile float-end">
            {user.fullName}
            <div data-test="user-role-info" className="user-role">
              {getTranslation(`GLOBAL_USERROLE_${user.activeRole.name.toUpperCase()}` as keyof I18N, t)}
            </div>
          </div>
        </div>

        <div id="user-menu-content" className={classnames(['user-menu-content', { show }])}>
          <ChangeLanguage />
          <Button data-test="change-password" color="secondary" block onClick={this._moveToRecoverPassword}>
            <FontAwesome name="key" className="pe-2 margin-bottom" />
            {t.HEADER_LABEL_CHANGE_PASSWORD}
          </Button>
          <ChangeUserRole onUserRoleChange={onUserRoleChange} />
          {isAdmin && <ChangeTenant onTenantChange={onTenantChange} />}
          <Button data-test="logout" color="secondary" block onClick={toggleLogoutModal}>
            <FontAwesome name="power-off" className="pe-2 margin-bottom" />
            {t.HEADER_LABEL_LOGOUT}
          </Button>
          <UIVersion />
        </div>
      </div>
    );
  }

  private _moveToRecoverPassword = () => {
    this.props.history.push(`/auth/recover-password?username=${this.props.sessionStore.user.username}`);
  };
}

export default inject('translateService', 'sessionStore')(observer(UserContentMenu));
