import { action, computed, observable, makeObservable } from 'mobx';

import IdNameActiveModel from 'models/IdNameActiveModel';
import { IConstructSaveObj } from 'models/ModelInterfaces';

export default class PrinterModel extends IdNameActiveModel implements IConstructSaveObj<PrinterModel> {
  public ip: string = '';
  public model: string = '';

  constructor() {
    super();

    makeObservable(this, {
      ip: observable,
      model: observable,
      changeIp: action,
      changeModel: action,
    });
  }

  public get constructSaveObj(): PrinterModel {
    return {
      name: this.name,
      ip: this.ip,
      model: this.model,
      active: this.active,
    } as PrinterModel;
  }

  public update = (obj: PrinterModel) => {
    this.updater.update(this, super.update(obj), PrinterModel);
    return this;
  };

  public changeIp = (newIp: string) => (this.ip = newIp);
  public changeModel = (newModel: string) => (this.model = newModel);
}
