import { action, computed, makeObservable, observable } from 'mobx';
import UpdateModel from 'models/UpdateModel';
import { IConstructSaveObj } from 'models/ModelInterfaces';

export interface IConnectionConstructObj {
  clientId: string;
  clientSecret: string;
}

export default class ConnectionModel extends UpdateModel<ConnectionModel> implements IConstructSaveObj<IConnectionConstructObj>{
  constructor() {
    super();

    makeObservable(this, {
      clientId: observable,
      clientSecret: observable,
      constructSaveObj: computed,
      hasClientIdAndSecret: computed,
      update: action,
      setClientId: action,
      setClientSecret: action,
    });
  }

  public clientId: string = '';
  public apiName: string = '';
  public apiDescription: string = '';
  public clientSecret: string = '';

  public get constructSaveObj(): IConnectionConstructObj {
    return {
      clientId: this.clientId,
      clientSecret: this.clientSecret
    }
  }

  public get hasClientIdAndSecret(): boolean {
    return Boolean(this.clientSecret && this.clientId);
  }

  public update(obj: ConnectionModel): ConnectionModel {
    this.updater.update(this, obj, ConnectionModel);
    return this;
  }

  public setClientId(newClientId: string): void {
    this.clientId = newClientId;
  }

  public setClientSecret(newClientSecret: string): void {
    this.clientSecret = newClientSecret;
  }
}
