import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import MaterialDetailComponent from 'pod/admin/materials/MaterialDetailComponent';
import AdminStore from 'pod/admin/AdminStore';
import MaterialModel from 'models/MaterialModel';
import TranslateService from 'services/TranslateService';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import { AdminRootUrl } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class MaterialDetailRoute extends React.Component<IProps> {
  public content: MaterialModel = null;

  public updateContent = (newMaterial: MaterialModel) => {
    this.content = new MaterialModel().update(newMaterial);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      updateContent: action,
    });
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { materialList, materialApi },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.MATERIAL}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_MATERIAL}
        itemsList={materialList}
        api={materialApi}
        updateItem={this.updateContent}
      >
        <MaterialDetailComponent content={this.content} />
      </CommonAdminRoute>
    );
  }
}

export default inject('adminStore', 'translateService')(observer(MaterialDetailRoute));
