import * as React from 'react';
import * as moment from 'moment-timezone';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, InputGroup, Label } from 'reactstrap';
import * as classnames from 'classnames';
import { InputType } from 'reactstrap/types/lib/Input';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import InputNumber from 'components/InputNumber';
import Autocomplete from 'domain/Autocomplete';
import InputWithNullCheck from 'components/InputWithNullCheck';
import WorkflowModel from 'models/WorkflowModel';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';
import { CountryCode } from 'util/enums';
import IdNameModel from 'models/IdNameModel';
import SharepointModel from 'models/SharepointModel';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import { BORDER_DANGER } from 'util/constants';
import RequiredLabel from 'components/RequiredLabel';

interface IGeneralSectionProps {
  tenant: TenantModel;
  translateService?: TranslateService;
  superAdminStore?: SuperAdminStore;
}

interface IDocument {
  documentMode?: boolean;
}

class GeneralSection extends React.Component<IGeneralSectionProps>{
  private _typeCurrentGRNInput = 'number' as InputType;

  private _typeCurrentLabSequenceInput = 'number' as InputType;

  public componentDidMount() {
    const { superAdminStore } = this.props;
    superAdminStore.getTenantCommon();
    if ((document as IDocument).documentMode) {
      this._typeCurrentGRNInput = 'text' as InputType;
      this._typeCurrentLabSequenceInput = 'text' as InputType;
    }
  }

  public render() {
    const {
      tenant,
      translateService: { t },
      superAdminStore: { tenantUnits, legalEntities, workflowList },
    } = this.props;

    if (!tenant) {
      return null;
    }

    return <>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="workflow" sm={4} required>
          {t.SUPERADMIN_TENANTADMIN_WORKFLOW}
        </RequiredLabel>
        <Col sm={8}>
          <InputGroup size="md" className="full-width">
            <InputWithNullCheck
              data-test="workflow"
              type="select"
              id="workflow"
              value={tenant.workflowId}
              className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('workflow') })}
              onChange={this._changeWorkflow}
              disabled={Boolean(tenant.id)}
            >
              <option value="" disabled>
                {t.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW}
              </option>
              {workflowList &&
                workflowList.map((workflow: WorkflowModel) => (
                  <option key={workflow.id} value={workflow.id}>
                    {workflow.code}
                  </option>
                ))}
            </InputWithNullCheck>
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="name" sm={4} required>
          {t.SUPERADMIN_TENANTADMIN_TENANT}
        </RequiredLabel>
        <Col sm={8}>
          <InputWithLimitOfCharacters
            data-test="name"
            id="name"
            maxLength={50}
            onChange={this._changeName}
            className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('name') })}
            required
            type="text"
            value={tenant.name}
          />
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="code" sm={4} required>
          {t.GLOBAL_LABEL_CODE}
        </RequiredLabel>
        <Col sm={8}>
          <InputWithLimitOfCharacters
            data-test="code"
            id="code"
            maxLength={50}
            onChange={this._changeCode}
            className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('code') })}
            required
            type="text"
            value={tenant.code}
          />
        </Col>
      </FormGroup>
      {this._isDE_D365Selected && (
        <FormGroup row className="remaining-padding">
          <RequiredLabel for="site" sm={4} required>
            {t.SUPERADMIN_TENANTDETAIL_SITE}
          </RequiredLabel>
          <Col sm={8}>
            <InputWithLimitOfCharacters
              data-test="site"
              id="site"
              maxLength={50}
              onChange={this._changeSite}
              className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('site') })}
              required
              type="text"
              value={tenant.site}
            />
          </Col>
        </FormGroup>
      )}
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="locationCode" sm={4} required>
          {t.SUPERADMIN_TENANTADMIN_LOCATION_CODE}
        </RequiredLabel>
        <Col sm={8}>
          <InputWithLimitOfCharacters
            data-test="location-code"
            id="locationCode"
            maxLength={50}
            onChange={this._changeLocationCode}
            className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('locationCode') })}
            required
            type="text"
            value={tenant.locationCode}
          />
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="regionCode" sm={4} required>
          {t.SUPERADMIN_TENANTADMIN_REGION_CODE}
        </RequiredLabel>
        <Col sm={8}>
          <InputWithLimitOfCharacters
            data-test="region-code"
            id="regionCode"
            maxLength={10}
            onChange={this._changeRegionCode}
            className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('regionCode') })}
            required
            type="text"
            value={tenant.regionCode}
          />
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <RequiredLabel for="grnprefix" sm={4} required>
          {t.SUPERADMIN_TENANTLIST_GRN}
        </RequiredLabel>
        <Col sm={8}>
          <InputWithLimitOfCharacters
            data-test="grn-prefix"
            id="grnprefix"
            maxLength={50}
            onChange={this._changeGrnPrefix}
            className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('grnPrefix') })}
            required
            type="text"
            value={tenant.grnPrefix}
          />
        </Col>
      </FormGroup>
      { !this._isDE_D365Selected && <FormGroup row className="remaining-padding">
        <RequiredLabel for="current-seq-value" sm={4} required>
          {t.SUPERADMIN_TENANTADMIN_GRN_VALUE}
        </RequiredLabel>
        <Col sm={8}>
          <InputNumber
            data-test="grn"
            id="current-seq-value"
            disabled={tenant.isDeliveryCreated}
            decimalPlaces={0}
            onChangeValue={this._changeCurrentGRN}
            className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('currentSequenceValue') })}
            required={tenant.id == null}
            type={this._typeCurrentGRNInput}
            value={tenant.currentSequenceValue}
          />
        </Col>
      </FormGroup>}
      <FormGroup row className="remaining-padding">
        <RequiredLabel sm={4} required>{t.SUPERADMIN_TENANTADMIN_TIMEZONE}</RequiredLabel>
        <Col sm={8}>
          <Autocomplete
            data-test="timezone"
            disabled={false}
            placeholder={t.GLOBAL_LABEL_SELECT}
            inputClassName={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('timezone') })}
            items={this._getTimezones}
            onSelect={this._changeTimezone}
            selectedValue={tenant.timezone}
          />
        </Col>
      </FormGroup>
      <FormGroup row className="remaining-padding">
        <Label sm={4} for="comment">{t.ADVISEDGOOD_ROUTE_COMMENT}</Label>
        <Col sm={8}>
          <InputWithLimitOfCharacters
            data-test="comment"
            id="comment"
            maxLength={100}
            onChange={this._changeComment}
            type="text"
            value={tenant.comment}
          />
        </Col>
      </FormGroup>

      {this._isWeightUnitsLegalEntitiesShown && (
        <React.Fragment>
          <FormGroup row className="remaining-padding">
            <Label for="legal-entity" sm={4}>
              {t.GLOBAL_LABEL_LEGAL_ENTITY}
            </Label>
            <Col sm={8}>
              <InputGroup size="md" className="full-width">
                <InputWithNullCheck
                  data-test="legal-entity"
                  type="select"
                  id="legal-entity"
                  value={tenant.legalEntity?.id || ''}
                  disabled={tenant.isDeliveryCreated}
                  onChange={this._changeLegalEntity}
                >
                  <option value="" disabled />
                  {legalEntities &&
                    legalEntities.map((entity: IdNameModel) => (
                      <option key={entity.id} value={entity.id}>
                        {entity.name}
                      </option>
                    ))}
                </InputWithNullCheck>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row className="remaining-padding">
            <Label for="unit" sm={4}>
              {t.GLOBAL_LABEL_WEIGHT_UNITS}
            </Label>
            <Col sm={8}>
              <InputGroup size="md" className="full-width">
                <InputWithNullCheck
                  data-test="units"
                  type="select"
                  id="unit"
                  value={tenant.units}
                  onChange={this._changeUnit}
                >
                  <option value="" disabled />
                  {tenantUnits &&
                    tenantUnits.map((unit: string) => (
                      <option key={unit} value={unit}>
                        {unit}
                      </option>
                    ))}
                </InputWithNullCheck>
              </InputGroup>
            </Col>
          </FormGroup>
        </React.Fragment>
      )}

      {this._isITSelected && (
        <FormGroup row className="remaining-padding">
          <RequiredLabel for="current-seq-value" sm={4} required>
            {t.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE}
          </RequiredLabel>
          <Col sm={8}>
            <InputNumber
              data-test="lab-sequence"
              id="current-lab-sequence-value"
              disabled={tenant.id != null}
              decimalPlaces={0}
              onChangeValue={this._changeCurrentLabSequence}
              className={classnames({ [BORDER_DANGER]: tenant.isInvalidFieldByKey('currentLabSequenceValue') })}
              required={tenant.id == null}
              type={this._typeCurrentLabSequenceInput}
              value={tenant.currentLabSequenceValue}
            />
          </Col>
        </FormGroup>
      )}
    </>
  }
  private _changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setName(e.target.value);
  };

  private _changeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setCode(e.target.value);
  };

  private _changeSite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setSite(e.target.value);
  };

  private _changeLocationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setLocationCode(e.target.value);
  };

  private _changeRegionCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setRegionCode(e.target.value);
  };

  private _changeGrnPrefix = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setGrnPrefix(e.target.value);
  };

  private _changeComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setComment(e.target.value);
  };

  private _changeCurrentGRN = (value: number) => {
    const { tenant } = this.props;
    tenant.setCurrentSequenceValue(value);
  };

  private get _isWeightUnitsLegalEntitiesShown(): boolean {
    const { tenant, superAdminStore } = this.props;
    return superAdminStore.getSelectedWorkflow(tenant)?.isDE_D365;
  }

  private get _isDE_D365Selected(): boolean {
    const { tenant, superAdminStore } = this.props;
    return superAdminStore.getSelectedWorkflow(tenant)?.isDE_D365;
  }

  private get _isITSelected(): boolean {
    const { tenant, superAdminStore } = this.props;
    return superAdminStore.getSelectedWorkflow(tenant)?.isIT;
  }

  private get _getTimezones(): IdNameModel[] {
    return moment.tz.names().map((timezone: string) => {
      return {
        id: timezone,
        name: `(UTC${moment.tz(timezone).format('Z')}) ${timezone}`,
      };
    }) as IdNameModel[];
  }

  private _changeTimezone = (selectedTimezone: IdNameModel) => {
    const { tenant } = this.props;
    tenant.setTimezone(selectedTimezone.id);
  };

  private _changeWorkflow = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant, superAdminStore: { workflowList } } = this.props;

    const code = workflowList.find((w) => w.id === e.target.value)?.code;
    if (
      Boolean(tenant.units || tenant.legalEntity || tenant.sharepointUploadFolder.id) &&
      code !== CountryCode.DE_D365
    ) {
      tenant.setUnits('');
      tenant.setLegalEntity(null);
      tenant.setSharepointUploadFolder(new SharepointModel());
    }
    tenant.setWorkflowId(e.target.value);
  };

  private _changeLegalEntity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant, superAdminStore } = this.props;
    const entity = (!!superAdminStore && superAdminStore.legalEntities.find((l) => l.id === e.target.value)) || null;
    tenant.setLegalEntity(entity);
  };

  private _changeCurrentLabSequence = (value: number) => {
    const { tenant } = this.props;
    tenant.setCurrentLabSequenceValue(value);
  };

  private _changeUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tenant } = this.props;
    tenant.setUnits(e.target.value);
  };
}

export default inject('translateService', 'superAdminStore')(observer(GeneralSection));
