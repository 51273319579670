import * as React from 'react';
import { inject, observer } from 'mobx-react';

import AdminStore from 'pod/admin/AdminStore';
import UserModel from 'models/UserModel';
import SessionStore from 'stores/SessionStore';
import TranslateService from 'services/TranslateService';
import { action, observable, makeObservable } from 'mobx';
import CommonAdminRoute from 'pod/admin/commonComponents/CommonAdminRoute';
import UserDetailComponent from 'pod/admin/users/UserDetailComponent';
import { AdminRootUrl } from 'util/enums';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IProps {
  adminStore?: AdminStore;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
}

class UserDetailRoute extends React.Component<IProps> {
  public content: UserModel<IUserModelConstructObj> = null;

  public updateContent = (newUser: UserModel<IUserModelConstructObj>) => {
    this.content = new UserModel<IUserModelConstructObj>().update(newUser);
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      updateContent: action,
    });
  }

  public componentDidMount(): void {
    this.props.adminStore.getUserRoles();
    this.props.adminStore.getTenants();
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { userList, userApi, userRoleList, tenantList },
    } = this.props;

    return (
      <CommonAdminRoute
        rootUrl={AdminRootUrl.USER}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_USER}
        itemsList={userList}
        api={userApi}
        updateItem={this.updateContent}
        isCodeRequired={false}
      >
        <UserDetailComponent content={this.content} userRoles={userRoleList} tenants={tenantList} />
      </CommonAdminRoute>
    );
  }
}

export default inject('adminStore', 'sessionStore', 'translateService')(observer(UserDetailRoute));
