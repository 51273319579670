type TInfoInput = string[] | [string, Event | MessageEvent | CloseEvent];
type TError = Error[] | string[] | [string, Event] | [string, Error] | [string, EventTarget & HTMLInputElement];

class Logger {
  public debug = (...args: string[]) => {
    console.log(...args);
  };

  public info = (...args: TInfoInput) => {
    console.log(...args);
  };

  public error = (...args: TError) => {
    console.error(...args);
  };

  public warn = (...args: string[]) => {
    console.warn(...args);
  };
}

export const logger = new Logger();
