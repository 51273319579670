import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import { Button, Col, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import AdminStore from 'pod/admin/AdminStore';
import ActivityLogModel from 'models/ActivityLogModel';
import TranslateService from 'services/TranslateService';
import ViewStore from 'stores/ViewStore';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import DateFormattingService from 'services/DateFormattingService';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  dateFormattingService?: DateFormattingService;
}

interface IState {
  content: ActivityLogModel[];
}

class ActivityLogRoute extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      content: [],
    };
  }

  public componentDidMount() {
    this.loadData();
  }

  // LOAD DATA
  public loadData() {
    const { adminStore } = this.props;
    adminStore.getAdminActivityLog().then((res: ActivityLogModel[]) => {
      this.setState({
        content: res.map((item: ActivityLogModel) => new ActivityLogModel().update(item)),
      });
    });
  }

  public render() {
    const { content } = this.state;
    const {
      translateService: { t },
      viewStore: { timezone },
      dateFormattingService: { formatDateTime },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {t.ADMIN_ACTIVITYLOG_TITLE}
              <Button className="float-end" color="success" onClick={() => this.loadData()}>
                <FontAwesome name="refresh" className="me-2" />
                {t.GLOBAL_LABEL_REFRESH}
              </Button>
            </h2>
            <table className="custom-table mb-3">
              <thead>
                <tr>
                  <th className="ps-3">{t.GLOBAL_LABEL_GRN}</th>
                  <th className="ps-3">{t.ADMIN_ACTIVITYLOG_ACTION}</th>
                  <th className="width-170">{t.ADMIN_ACTIVITYLOG_CREATEDAT}</th>
                  <th>{t.ADMIN_ACTIVITYLOG_USER}</th>
                  <th className="pe-3">{t.ADMIN_ACTIVITYLOG_MESSAGE}</th>
                </tr>
              </thead>
              {content &&
                content.map((log: ActivityLogModel) => (
                  <tbody key={log.id}>
                    <tr className="pointer">
                      <td className="ps-3">{log.grn}</td>
                      <td className="ps-3">{log.action}</td>
                      <td>{formatDateTime(log.createdAt, timezone)}</td>
                      <td>{log.user.fullName}</td>
                      <td className="pe-3">
                        {!!log.message && (
                          <CopyToClipboardButton
                            text={log.message}
                            tooltipText={t.GLOBAL_COPY_TITLE(t.ADMIN_ACTIVITYLOG_MESSAGE.toLowerCase())}
                            messageOnCopied={t.GLOBAL_COPY_SUCCESS_MESSAGE(t.ADMIN_ACTIVITYLOG_MESSAGE)}
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default inject(
  'adminStore',
  'translateService',
  'viewStore',
  'dateFormattingService'
)(observer(ActivityLogRoute));
