import ObjectUpdater from 'util/objectUpdater';
import { action, observable, makeObservable } from 'mobx';

// to skip UpdateMethodParamsType just pass undefined instead: UpdateModel<ModelType, undefined, ConstructorParamsType>

export default abstract class UpdateModel<
  ModelType,
  UpdateMethodParamsType = undefined,
  ConstructorParamsType = undefined
> {
  public abstract update(obj: ModelType, ...rest: UpdateMethodParamsType[]): ModelType;
  protected readonly updater: ObjectUpdater<ModelType, ConstructorParamsType> = new ObjectUpdater<
    ModelType,
    ConstructorParamsType
  >();

  public hasChanged?: boolean = false;

  public setHasChanged = (value: boolean) => {
    this.hasChanged = value;
  };

  constructor() {
    makeObservable(this, {
      hasChanged: observable,
      setHasChanged: action,
    });
  }
}

export interface IUpdateModel<ModelType, UpdateMethodParamsType = undefined, ConstructorParamsType = undefined> {
  update: (obj: ModelType, ...rest: UpdateMethodParamsType[]) => ModelType;
  readonly updater: ObjectUpdater<ModelType, ConstructorParamsType>;
}
