import * as React from 'react';
import TransferModel from 'models/TransferModel';
import ViewStore from 'stores/ViewStore';
import { processDecimals } from 'util/helpers';
import { inject, observer } from 'mobx-react';

interface ITransferHistoryProps {
  transfers: TransferModel[];
  viewStore?: ViewStore;
}

const TransfersHistory: React.FunctionComponent<ITransferHistoryProps> = ({
  transfers,
  viewStore: { numberOfDecimals, isDE },
}) => (
  <React.Fragment>
    {transfers.map((tr: TransferModel) => (
      <div key={tr.id}>
        {tr.description}
        <span className="margin-left-5 font-13 font-light display-inline-block">
          {processDecimals(tr.weight, numberOfDecimals)} &rarr; {isDE ? tr.departmentCode : tr.yardLocation.name}
        </span>
      </div>
    ))}
  </React.Fragment>
);

export default inject('viewStore')(observer(TransfersHistory));
