// AUTO ADD NEW LINE IN MOBX
export interface IShouldAddNewLine {
  shouldAddNewLine: boolean;
}

// AUTO REMOVE LINE IN MOBX
export interface IShouldRemoveLine {
  shouldRemoveLine: boolean;
}

export function autoAddNewLine<T extends IShouldAddNewLine>(
  array: T[],
  newLineFn: () => T,
  condition?: boolean
): boolean | undefined {
  // array -> ARRAY TO BE OBSERVED
  // newLineFn -> FUNC FOR ADDING NEW LINE, MUST RETURN NEW LINE

  // EXAMPLE USE CASE (MOBX MODEL)
  /*

  constructor() {
    autorun(() => this.shouldTrigger);
  }

  public get shouldTrigger() {
    return this.run(this.receivedGoods, () => this.pushNewReceivedGood());
  }
  */

  if (array && array.length) {
    const lastItem = array[array.length - 1];
    return lastItem.shouldAddNewLine && newLineFn().shouldAddNewLine;
  }
}

export function autoRemoveLine<T extends IShouldRemoveLine>(array: T[], updateArray: (newArray: T[]) => void): void {
  // array -> ARRAY TO BE OBSERVED
  // updateArray -> FUNC FOR UPDATING ARRAY AND REMOVING UNNECESSARY LINES

  // EXAMPLE USE CASE (MOBX MODEL)
  /*

  constructor() {
    autorun(() => this.shouldTriggerRemoving);
  }

  public get shouldTriggerRemoving() {
    return this.run(this.array, (newArray) => this.updateArray(newArray));
  }
  */

  if (array && array.length) {
    const necessaryItems = array.filter((item: T) => !item.shouldRemoveLine);
    if (necessaryItems.length !== array.length) {
      updateArray(necessaryItems);
    }
  }
}
