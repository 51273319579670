import { action, computed, observable, observe, makeObservable } from 'mobx';
import { IObjectDidChange } from 'mobx/src/types/observableobject';

import DeliveryAdvisedGoodModel, { IDeliveryAdvisedGoodConstructSaveObj } from 'models/DeliveryAdvisedGoodModel';
import FileModel from 'models/FileModel';
import ValidateModel from 'models/ValidateModel';
import OperatorModel from 'models/OperatorModel';
import RoadHaulierModel from 'models/RoadHaulierModel';
import SupplierModel from 'models/SupplierModel';
import { AdvisedGoodStatus, AttachmentType, CountryCode, DeliveryStatus } from 'util/enums';
import IdCodeModel from 'models/IdCodeModel';
import { cloneObj } from 'util/helpers';
import { ISetHasChanged } from 'util/objectUpdater';
import { HAS_CHANGED } from 'util/constants';
import IdCodeNameModel from 'models/IdCodeNameModel';

export interface IDeliveryConstructSaveObj {
  advisedGoods: IDeliveryAdvisedGoodConstructSaveObj[];
  containerNumber: string;
  contractId: string;
  contractNumber: string;
  deliveryNote: string;
  deliveryType: string;
  files: Array<{ id: string }>;
  grossWeight: number;
  id: string;
  grn: string;
  tareWeight: number;
  remarks: string;
  roadHaulierCode: string;
  supplierCode: string;
  traderId: string;
  vehicleRegistrationNumber: string;
  weatherCondition: string;
  originId: string;
  transactionType: string;
}

interface IDeliveryModelValidationKeys {
  advisedGoods: boolean;
  roadHaulier: boolean;
  supplier: boolean;
  vehicleRegistrationNumber: boolean;
  grn: boolean;
  origin: boolean;
  deliveryType: boolean;
  weatherCondition: boolean;
  AGDescription: boolean;
  AGYardLocation: boolean;
  AGStockItem: boolean;
  AGTicketNumber: boolean;
  AGDepartmentCode: boolean;
  AGFirstWeight: boolean;
  AGProductForm: boolean;
  AGProductQuality: boolean;
}

export interface IDeliveryAttachmentsConstructSaveObj {
  attachments: Array<{ id: string }>;
  radiationFiles: Array<{ id: string }>;
}

export type DeliveryValidatorKey = Partial<keyof IDeliveryModelValidationKeys>;

export default class DeliveryModel extends ValidateModel<IDeliveryModelValidationKeys> implements ISetHasChanged {
  constructor() {
    super();

    makeObservable(this, {
      advisedGoods: observable,
      attachments: observable,
      containerNumber: observable,
      contractId: observable,
      contractNumber: observable,
      deliveryNote: observable,
      deliveryType: observable,
      isFlagged: observable,
      remarks: observable,
      roadHaulier: observable,
      status: observable,
      supplier: observable,
      trader: observable,
      vehicleRegistrationNumber: observable,
      weatherCondition: observable,
      grossWeight: observable,
      tareWeight: observable,
      completedBy: observable,
      createdBy: observable,
      syncedBy: observable,
      grn: observable,
      id: observable,
      signedOffBy: observable,
      isSynced: observable,
      origin: observable,
      transactionType: observable,
      printed: observable,
      deliveryNetWeight: computed,
      canBeSynced: computed,
      canBeSyncedWhenSaved: computed,
      canBePrinted: computed,
      canBeExported: computed,
      isEveryAGUnloaded: computed,
      hasEveryAGSecondWeight: computed,
      hasEveryAGSecondWeightIncludeZero: computed,
      isDirty: computed,
      validators: computed,
      getAttachmentsIds: computed,
      getRadiationFilesIds: computed,
      update: action,
      changeAttachments: action,
      pushNewAdvisedGood: action,
      removeAdvisedGood: action,
      removeAttachment: action,
      setContainerNumber: action,
      setDeliveryNote: action,
      setRemarks: action,
      setRoadHaulier: action,
      setTrader: action,
      setOrigin: action,
      setSupplier: action,
      setVehicleRegistrationNumber: action,
      setWeatherCondition: action,
      setDeliveryType: action,
      setGrossWeight: action,
      setTareWeight: action,
      setGRN: action,
      setContractNumber: action,
      setContractId: action,
      setTransactionType: action,
      clearContract: action,
      constructSaveAttachmentsObj: action,
      constructSaveObj: action,
    });

    observe(this, this.onChange);
  }

  public hasChanged?: boolean = false;
  public advisedGoods?: DeliveryAdvisedGoodModel[] = [new DeliveryAdvisedGoodModel()];
  public attachments?: FileModel[] = [];
  public containerNumber?: string = '';
  public contractId?: string = null;
  public contractNumber?: string = null;
  public deliveryNote?: string = '';
  public deliveryType?: string = '';
  public isFlagged?: boolean = false;
  public remarks?: string = '';
  public roadHaulier?: RoadHaulierModel = null;
  public status?: DeliveryStatus = null;
  public supplier?: SupplierModel = null;
  public trader?: IdCodeNameModel = null;
  public vehicleRegistrationNumber?: string = '';
  public weatherCondition?: string = '';
  public grossWeight?: number = null; // DELIVERY GROSS WEIGHT
  public tareWeight?: number = null; // DELIVERY TARE WEIGHT
  public completedBy?: OperatorModel = null;
  public createdBy?: OperatorModel = null;
  public syncedBy?: OperatorModel = null;
  public grn?: string = '';
  public id?: string = null;
  public signedOffBy?: OperatorModel = null;
  public isSynced?: boolean = false;
  public origin?: IdCodeModel = null;
  public transactionType?: string = '';
  public printed?: boolean = false;

  public generalValidatorKeys: Array<keyof Partial<IDeliveryModelValidationKeys>> = [
    'advisedGoods',
    'supplier',
    'vehicleRegistrationNumber',
    'AGDescription',
    'AGYardLocation',
    'AGStockItem',
  ];
  public validationKeysByCountryCode: Map<CountryCode, Array<keyof Partial<IDeliveryModelValidationKeys>>> = new Map<
    CountryCode,
    Array<keyof Partial<IDeliveryModelValidationKeys>>
  >([
    [CountryCode.US, this.generalValidatorKeys.concat(['roadHaulier', 'deliveryType', 'weatherCondition'])],
    [CountryCode.IT, this.generalValidatorKeys.concat(['roadHaulier', 'origin', 'AGFirstWeight', 'AGTicketNumber'])],
    [CountryCode.DE, this.generalValidatorKeys.concat(['grn', 'AGDepartmentCode', 'AGTicketNumber'])],
    [
      CountryCode.DE_D365,
      this.generalValidatorKeys.concat(['grn', 'AGTicketNumber', 'AGProductForm', 'AGProductQuality']),
    ],
    [CountryCode.UK, this.generalValidatorKeys.concat(['roadHaulier', 'AGTicketNumber'])],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  public get deliveryNetWeight(): string {
    return this.grossWeight && this.tareWeight ? String(Number(this.grossWeight) - Number(this.tareWeight)) : '-';
  }

  public get canBeSynced(): boolean {
    return (
      !!this.deliveryNote &&
      this.advisedGoods.find((ag: DeliveryAdvisedGoodModel) => {
        if (ag.nonAdvisedGood) {
          return !ag.firstWeight;
        }
        return !ag.firstWeight || !ag.secondWeight || !ag.ewcCode;
      }) === undefined
    );
  }

  public get canBeSyncedWhenSaved(): boolean {
    return !!this.id && !!this.grossWeight && !!this.tareWeight;
  }

  public get canBePrinted(): boolean {
    return this.isEveryAGUnloaded && this.hasEveryAGSecondWeight && !this.isSynced;
  }

  public get canBeExported(): boolean {
    return this.isEveryAGUnloaded && this.hasEveryAGSecondWeightIncludeZero;
  }

  public get isEveryAGUnloaded(): boolean {
    const preUnloadStatuses = [AdvisedGoodStatus.WAITING, AdvisedGoodStatus.CLAIMED];
    return !this.advisedGoods.some((ag: DeliveryAdvisedGoodModel) => preUnloadStatuses.includes(ag.status));
  }

  public get hasEveryAGSecondWeight(): boolean {
    return this.advisedGoods.every((ag: DeliveryAdvisedGoodModel) => ag.secondWeight || ag.nonAdvisedGood);
  }

  public get hasEveryAGSecondWeightIncludeZero(): boolean {
    return this.advisedGoods.every(
      (ag: DeliveryAdvisedGoodModel) => typeof ag.secondWeight === 'number' || ag.nonAdvisedGood
    );
  }

  public get isDirty() {
    return this.hasChanged || this.advisedGoods.some((x) => x.isDirty);
  }

  public get validators(): IDeliveryModelValidationKeys {
    return {
      advisedGoods: this.advisedGoods.length !== 0,
      roadHaulier: this.roadHaulier !== null,
      supplier: this.supplier !== null,
      vehicleRegistrationNumber: !!this.vehicleRegistrationNumber && this.vehicleRegistrationNumber !== '',
      grn: !!this.grn && this.grn !== '',
      origin: this.origin !== null, // for IT
      deliveryType: !!this.deliveryType && this.deliveryType !== '', // for US
      weatherCondition: !!this.weatherCondition && this.weatherCondition !== '', // for US
      AGDescription: this.advisedGoods.every((ag) => ag.validators.advisedDescription),
      AGYardLocation: this.advisedGoods.every((ag) => ag.validators.yardLocation),
      AGStockItem: this.advisedGoods.every((ag) => ag.validators.stockItem),
      AGTicketNumber: this.advisedGoods.every((ag) => ag.validators.ticketNumber),
      AGDepartmentCode: this.advisedGoods.every((ag) => ag.validators.departmentCode), // for DE
      AGFirstWeight: this.advisedGoods.every((ag) => ag.validators.firstWeight), // for IT
      AGProductForm: this.advisedGoods.every((ag) => ag.validators.productForm), // for DE_D365
      AGProductQuality: this.advisedGoods.every((ag) => ag.validators.productQuality), // for DE_D365
    };
  }

  public get getAttachmentsIds() {
    return this.attachments
      .filter((item: FileModel) => item.type === AttachmentType.DELIVERY_ATTACHMENT)
      .map((file: FileModel) => ({ id: file.id }));
  }

  public get getRadiationFilesIds() {
    return this.attachments
      .filter((item: FileModel) => item.type === AttachmentType.DELIVERY_RADIATION)
      .map((file: FileModel) => ({ id: file.id }));
  }

  public onChange = (change: IObjectDidChange) => {
    if (change.name !== HAS_CHANGED) {
      this.setHasChanged(true);
    }
  };

  public update = (obj: DeliveryModel) => {
    const newDeliveryModel = cloneObj(obj);

    if (newDeliveryModel) {
      newDeliveryModel.advisedGoods = newDeliveryModel.advisedGoods.map((r: DeliveryAdvisedGoodModel) =>
        new DeliveryAdvisedGoodModel().update(r)
      );
      if (newDeliveryModel.attachments && newDeliveryModel.attachments.length !== 0) {
        newDeliveryModel.attachments = newDeliveryModel.attachments.map((r: FileModel) => new FileModel().update(r));
      }
      if (newDeliveryModel.supplier) {
        newDeliveryModel.supplier = new SupplierModel().update(newDeliveryModel.supplier);
      }
      if (newDeliveryModel.createdBy) {
        newDeliveryModel.createdBy = new OperatorModel().update(newDeliveryModel.createdBy);
      }
      if (newDeliveryModel.signedOffBy) {
        newDeliveryModel.signedOffBy = new OperatorModel().update(newDeliveryModel.signedOffBy);
      }
      if (newDeliveryModel.syncedBy) {
        newDeliveryModel.syncedBy = new OperatorModel().update(newDeliveryModel.syncedBy);
      }
      if (newDeliveryModel.completedBy) {
        newDeliveryModel.completedBy = new OperatorModel().update(newDeliveryModel.completedBy);
      }
    }

    this.updater.update(this, newDeliveryModel, DeliveryModel);

    return this;
  };

  public changeAttachments = (newAttachments: FileModel[] | void) => {
    if (newAttachments) {
      this.attachments.push(...newAttachments);
    }
  };

  public pushNewAdvisedGood() {
    this.advisedGoods.push(new DeliveryAdvisedGoodModel());
  }

  public removeAdvisedGood(ag: DeliveryAdvisedGoodModel) {
    this.advisedGoods = this.advisedGoods.filter((item) => item !== ag);
  }

  public removeAttachment(attachmentId: string) {
    this.attachments = this.attachments.filter((att) => att.id !== attachmentId);
  }

  public setContainerNumber(val: string) {
    this.containerNumber = val;
  }

  public setDeliveryNote(val: string) {
    this.deliveryNote = val;
  }

  public setRemarks(val: string) {
    this.remarks = val;
  }

  public setRoadHaulier(item: RoadHaulierModel) {
    this.roadHaulier = new RoadHaulierModel().update(item);
  }

  public setTrader(item: IdCodeNameModel) {
    this.trader = new IdCodeNameModel().update(item);
  }

  public setOrigin(item: IdCodeModel) {
    this.origin = new IdCodeModel().update(item);
  }

  public setSupplier(item: SupplierModel) {
    this.supplier = new SupplierModel().update(item);
  }

  public setVehicleRegistrationNumber(val: string) {
    this.vehicleRegistrationNumber = val;
  }

  public setWeatherCondition(val: string) {
    this.weatherCondition = val;
  }

  public setDeliveryType(val: string) {
    this.deliveryType = val;
  }

  public setGrossWeight(val: number) {
    this.grossWeight = val;
  }

  public setTareWeight(val: number) {
    this.tareWeight = val;
  }

  // this method uses only for test purposes Germany workflow.
  public setGRN(newGRN: string) {
    this.grn = newGRN;
  }

  public setContractNumber(val: string) {
    this.contractNumber = val;
  }

  public setContractId(val: string) {
    this.contractId = val;
  }

  public setTransactionType(val: string) {
    this.transactionType = val;
  }

  public clearContract() {
    this.contractId = null;
    this.contractNumber = null;
  }

  public constructSaveAttachmentsObj(): IDeliveryAttachmentsConstructSaveObj {
    return {
      attachments: this.getAttachmentsIds,
      radiationFiles: this.getRadiationFilesIds,
    };
  }

  // CONSTRUCT OBJ FOR POST/PUT DELIVERY
  public constructSaveObj(): IDeliveryConstructSaveObj {
    return {
      advisedGoods: this.advisedGoods.map((ag) => ag.constructSaveObj()),
      containerNumber: this.containerNumber,
      contractId: this.contractId,
      contractNumber: this.contractNumber,
      deliveryNote: this.deliveryNote,
      deliveryType: this.deliveryType ? this.deliveryType : null,
      files: this.attachments.map((file: FileModel) => ({ id: file.id })),
      grossWeight: this.grossWeight,
      id: this.id,
      grn: this.grn ? this.grn : null,
      tareWeight: this.tareWeight,
      remarks: this.remarks,
      roadHaulierCode: this.roadHaulier ? this.roadHaulier.code : '',
      supplierCode: this.supplier ? this.supplier.code : '',
      traderId: this.trader ? this.trader.id : '',
      vehicleRegistrationNumber: this.vehicleRegistrationNumber,
      weatherCondition: this.weatherCondition ? this.weatherCondition : null,
      originId: this.origin ? this.origin.id : '',
      transactionType: this.transactionType ? this.transactionType : null,
    };
  }
}
