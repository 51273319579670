import * as React from 'react';
import { Col, Row } from 'reactstrap';

interface IPutInOrderChildrenProps {
  children: React.ReactNodeArray;
  className?: string;
}

const PutInOrderChildren: (props: IPutInOrderChildrenProps) => JSX.Element = ({ children, className }) => {
  const filteredChildren = children.filter((item) => item);
  const middle = Math.ceil(filteredChildren.length / 2);

  return children.length ? (
    <Row className={className}>
      <Col md="6">{filteredChildren.slice(0, middle)}</Col>
      <Col md="6">{filteredChildren.slice(middle)}</Col>
    </Row>
  ) : null;
};

export default PutInOrderChildren;
