import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, Label } from 'reactstrap';
import InputWithNullCheck from 'components/InputWithNullCheck';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';

interface IRequiredOptionsSectionProps {
  tenant: TenantModel;
  translateService?: TranslateService;
}

const RequiredOptionsSection: React.FC<IRequiredOptionsSectionProps> = (props) => {
  const { translateService: {t}, tenant} = props;

  const changeClassificationRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    tenant.setClassificationSectionRequired(checked);
  };

  const changeContaminationRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    tenant.setContaminationSectionRequired(checked);
  };

  const changeRequireStockCodeOnVerificationForTier2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    tenant.setRequireStockCodeOnVerificationForTier2(checked);
  };

  return (
    <div>
      <FormGroup row className="remaining-padding">
        <Label for="is-stock-code-required-for-verify" sm={4}>
          {t.SUPERADMIN_TENANTDETAIL_IS_STOCK_CODE_REQUIRED_FOR_VERIFY}
        </Label>
        <Col sm={8}>
          <Label className="ms-1">
            <InputWithNullCheck
              data-test="is-stock-code-required-for-verify"
              type="checkbox"
              checked={tenant.requireStockCodeOnVerificationForTier2}
              onChange={changeRequireStockCodeOnVerificationForTier2}
              className="pointer"
              id="is-stock-code-required-for-verify"
            />
          </Label>
        </Col>
      </FormGroup>
      {tenant.workflow.isDE_D365 && (
        <React.Fragment>
          <FormGroup row className="remaining-padding">
            <Label for="require-classification" sm={4}>
              {t.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION}
            </Label>
            <Col sm={8}>
              <Label className="ms-1">
                <InputWithNullCheck
                  data-test="require-classification"
                  type="checkbox"
                  checked={tenant.rgClassificationsSectionRequired}
                  onChange={changeClassificationRequired}
                  className="pointer"
                  id="require-classification"
                />
              </Label>
            </Col>
          </FormGroup>
          <FormGroup row className="remaining-padding">
            <Label for="require-contamination" sm={4}>
              {t.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION}
            </Label>
            <Col sm={8}>
              <Label className="ms-1">
                <InputWithNullCheck
                  data-test="require-contamination"
                  type="checkbox"
                  checked={tenant.rgContaminationsSectionRequired}
                  onChange={changeContaminationRequired}
                  className="pointer"
                  id="require-contamination"
                />
              </Label>
            </Col>
          </FormGroup>
        </React.Fragment>
      )}
    </div>
  );
};

export default inject('translateService')(observer(RequiredOptionsSection));
