import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import * as classnames from 'classnames';

import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import NavlinkCustom from 'domain/NavlinkCustom';
import IdNameActiveModel from 'models/IdNameActiveModel';
import PackagingDetailRoute from 'pod/admin/packaging/PackagingDetailRoute';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class PackagingListRoute extends React.Component<IProps> {
  public content: IdNameActiveModel = new IdNameActiveModel();

  public clearModel = () => {
    this.content = new IdNameActiveModel();
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      clearModel: action,
    });
  }

  public get packagingList() {
    const {
      translateService: { t },
      adminStore: { packageListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="ps-3">{t.GLOBAL_LABEL_NAME}</th>
          </tr>
        </thead>
        {packageListActiveFirst.map((item) => this._renderItem(item))}
      </table>
    );
  }

  public render() {
    const {
      adminStore: { packagingApi },
      translateService: { t },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={packagingApi}
        rootPath={AdminRootPath.PACKAGING}
        itemsTable={this.packagingList}
        itemCreateForm={null}
        componentRoute={PackagingDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_PACKAGE}
        exampleFileNames={ExampleFileNames.PACKAGING}
        header={t.MENU_ADMIN_PACKAGING}
      />
    );
  }

  private _renderItem = (item: IdNameActiveModel) => {
    return (
      <tbody key={item.id}>
        <NavlinkCustom
          to={`/admin/packaging/${item.id}`}
          tagName="tr"
          key={item.id}
          className={classnames(['pointer', { 'bg-secondary': !item.active }])}
        >
          <td className="ps-3">{item.name}</td>
        </NavlinkCustom>
      </tbody>
    );
  };
}

export default inject('adminStore', 'translateService')(observer(PackagingListRoute));
