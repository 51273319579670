import { action, computed, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

import LabStatsModel from 'models/LabStatsModel';
import { LabAnalysisStatus } from 'util/enums';
import { cloneObj } from 'util/helpers';

export default class DeliveryLabReceivedGoodModel extends UpdateModel<DeliveryLabReceivedGoodModel> {
  public advisedGoodId: string = null;
  public description: string = '';
  public agTitle: string = null;
  public oldestInLabDate: Date = null;
  public receivedGoodId: string = null;
  public stats?: LabStatsModel[] = [];

  constructor() {
    super();

    makeObservable(this, {
      advisedGoodId: observable,
      description: observable,
      agTitle: observable,
      oldestInLabDate: observable,
      receivedGoodId: observable,
      stats: observable,
      hasNotification: computed,
      amountOfRequestedLabAnalysis: computed,
      addNotificationFlagToRequestedLabAnalysis: action,
      update: action,
    });
  }

  public get hasNotification(): boolean {
    return this.stats.some((st: LabStatsModel) => st.hasNotification);
  }

  public get amountOfRequestedLabAnalysis(): number {
    if (!this.stats || !this.stats.length) {
      return 0;
    }

    const requestedLabAnalysis = this.stats.find((st: LabStatsModel) => st.status.name === LabAnalysisStatus.REQUESTED);
    return requestedLabAnalysis ? Number(requestedLabAnalysis.count) : 0;
  }

  public addNotificationFlagToRequestedLabAnalysis = () => {
    this.stats.forEach((st: LabStatsModel) => {
      if (st.status.name === LabAnalysisStatus.REQUESTED) {
        st.updateHasNotification(true);
      }
    });
  };

  public update = (obj: DeliveryLabReceivedGoodModel) => {
    const newDeliveryLabReceivedGoodModel = cloneObj(obj);

    if (newDeliveryLabReceivedGoodModel) {
      newDeliveryLabReceivedGoodModel.stats = newDeliveryLabReceivedGoodModel.stats.map((st: LabStatsModel) =>
        new LabStatsModel().update(st)
      );
    }

    this.updater.update(this, newDeliveryLabReceivedGoodModel, DeliveryLabReceivedGoodModel);
    return this;
  };
}
