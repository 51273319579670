import { action, computed, observable, makeObservable } from 'mobx';
import UserModel from 'models/UserModel';
import WorkflowModel from 'models/WorkflowModel';
import { cloneObj } from 'util/helpers';
import IdNameModel from 'models/IdNameModel';
import SharepointModel from 'models/SharepointModel';
import { IActive, IUserModelConstructObj } from 'models/ModelInterfaces';
import ValidateModel from 'models/ValidateModel';
import { CountryCode } from 'util/enums';

export interface ITenantSaveObj {
  code: string;
  currentSequenceValue: number | string;
  currentLabSequenceValue: number | string;
  grnPrefix: string;
  locationCode: string;
  name: string;
  workflowId: string;
  timezone: string;
  regionCode: string;
  agSignOffEnabled: boolean;
  balanceDeductionEnabled: boolean;
  active?: boolean;
  integrationApiBase?: string;
  units?: string;
  comment?: string;
  legalEntityId?: string;
  site?: string;
  sharepointUploadFolderId?: string;
  deliveryAttachmentsRequired?: boolean;
  agAttachmentsRequired?: boolean;
  rgClassificationsSectionRequired?: boolean;
  rgContaminationsSectionRequired?: boolean;
  overwriteRgDescriptionDialogEnabled?: boolean;
  displayTareWeightForReceivedGood?: boolean;
  requireStockCodeOnVerificationForTier2?: boolean;
}

export interface ITenantModelValidationKeys {
  name: boolean;
  code: boolean;
  locationCode: boolean;
  regionCode: boolean;
  grnPrefix: boolean;
  currentSequenceValue: boolean;
  currentLabSequenceValue: boolean;
  timezone: boolean;
  workflow: boolean;
  site: boolean;
  sharepointUploadFolderId: boolean;
}

export default class TenantModel extends ValidateModel<ITenantModelValidationKeys> implements IActive {
  public admins?: UserModel<IUserModelConstructObj>[] = [];
  public code?: string = '';
  public site?: string = '';
  public grnPrefix?: string = '';
  public workflowId?: string = '';
  public locationCode?: string = '';
  public name?: string = '';
  public currentSequenceValue?: number = null;
  public currentLabSequenceValue?: number = null;
  public workflow?: WorkflowModel = new WorkflowModel();
  public id?: string = '';
  public createdAt?: string = '';
  public timezone?: string = '';
  public regionCode?: string = '';
  public integrationApiBase?: string = '';
  public units?: string = '';
  public comment?: string = '';
  public legalEntity?: IdNameModel = null;
  public isDeliveryCreated?: boolean = false;
  public sharepointUploadFolder?: SharepointModel = new SharepointModel();
  public agSignOffEnabled?: boolean = false;
  public balanceDeductionEnabled?: boolean = true;
  public deliveryAttachmentsRequired?: boolean = false;
  public agAttachmentsRequired?: boolean = false;
  public rgClassificationsSectionRequired?: boolean = true;
  public rgContaminationsSectionRequired?: boolean = true;
  public overwriteRgDescriptionDialogEnabled?: boolean = false;
  public displayTareWeightForReceivedGood?: boolean = false;
  public requireStockCodeOnVerificationForTier2?: boolean = false;
  public active: boolean = true;

  constructor() {
    super();

    makeObservable(this, {
      admins: observable,
      code: observable,
      site: observable,
      grnPrefix: observable,
      workflowId: observable,
      locationCode: observable,
      name: observable,
      currentSequenceValue: observable,
      currentLabSequenceValue: observable,
      workflow: observable,
      id: observable,
      createdAt: observable,
      timezone: observable,
      regionCode: observable,
      integrationApiBase: observable,
      units: observable,
      comment: observable,
      legalEntity: observable,
      isDeliveryCreated: observable,
      sharepointUploadFolder: observable,
      agSignOffEnabled: observable,
      balanceDeductionEnabled: observable,
      deliveryAttachmentsRequired: observable,
      agAttachmentsRequired: observable,
      rgClassificationsSectionRequired: observable,
      active: observable,
      rgContaminationsSectionRequired: observable,
      overwriteRgDescriptionDialogEnabled: observable,
      displayTareWeightForReceivedGood: observable,
      requireStockCodeOnVerificationForTier2: observable,
      constructSaveObj: computed,
      isNeedCheckDeliveryAttachments: computed,
      isNeedCheckAGAttachments: computed,
      update: action,
      pushAdmin: action,
      removeAdmin: action,
      setCode: action,
      setSite: action,
      setLocationCode: action,
      setGrnPrefix: action,
      setCurrentSequenceValue: action,
      setCurrentLabSequenceValue: action,
      setName: action,
      setWorkflowId: action,
      setTimezone: action,
      setRegionCode: action,
      setIntegrationApiBase: action,
      setUnits: action,
      setComment: action,
      setLegalEntity: action,
      setIsDeliveryCreated: action,
      setSharepointUploadFolder: action,
      setAgSignOffEnabled: action,
      setBalanceDeductionEnabled: action,
      setDeliveryAttachmentsRequired: action,
      setAgAttachmentsRequired: action,
      setClassificationSectionRequired: action,
      setContaminationSectionRequired: action,
      setOverwriteRgDescriptionDialogEnabled: action,
      setDisplayTareWeightForReceivedGood: action,
      setRequireStockCodeOnVerificationForTier2: action,
      changeActiveStatus: action,
    });
  }

  public generalValidatorKeys: Array<keyof Partial<ITenantModelValidationKeys>> = [
    'name', 'code', 'locationCode', 'regionCode','grnPrefix', 'timezone', 'workflow'
  ];

  public validationKeysByCountryCode: Map<
    CountryCode,
    Array<keyof Partial<ITenantModelValidationKeys>>
  > = new Map<CountryCode, Array<keyof Partial<ITenantModelValidationKeys>>>([
    [CountryCode.US, this.generalValidatorKeys.concat('currentSequenceValue')],
    [CountryCode.IT, this.generalValidatorKeys.concat('currentSequenceValue', 'currentLabSequenceValue')],
    [CountryCode.DE, this.generalValidatorKeys.concat('currentSequenceValue')],
    [CountryCode.DE_D365, this.generalValidatorKeys.concat(['site', 'sharepointUploadFolderId'])],
    [CountryCode.UK, this.generalValidatorKeys.concat('currentSequenceValue')],
    [CountryCode.FR, this.generalValidatorKeys.concat('currentSequenceValue')],
  ]);

  public get validators(): ITenantModelValidationKeys {
    return {
      name: Boolean(this.name),
      code: Boolean(this.code),
      locationCode: Boolean(this.locationCode),
      regionCode: Boolean(this.regionCode),
      grnPrefix: Boolean(this.grnPrefix),
      currentSequenceValue: Boolean(this.currentSequenceValue || this.currentSequenceValue === 0),
      currentLabSequenceValue: Boolean(this.currentLabSequenceValue || this.currentLabSequenceValue === 0),
      site: Boolean(this.site),
      sharepointUploadFolderId: Boolean(this.sharepointUploadFolder?.id),
      timezone: Boolean(this.timezone),
      workflow: Boolean(this.workflowId),
    };
  }

  public get constructSaveObj(): ITenantSaveObj {
    const result: ITenantSaveObj = {
      code: this.code,
      currentSequenceValue: this.currentSequenceValue,
      currentLabSequenceValue: this.currentLabSequenceValue,
      grnPrefix: this.grnPrefix,
      locationCode: this.locationCode,
      name: this.name,
      workflowId: this.workflowId,
      timezone: this.timezone,
      regionCode: this.regionCode,
      comment: this.comment,
      integrationApiBase: this.integrationApiBase,
      agSignOffEnabled: this.agSignOffEnabled,
      balanceDeductionEnabled: this.balanceDeductionEnabled,
      deliveryAttachmentsRequired: this.deliveryAttachmentsRequired,
      agAttachmentsRequired: this.agAttachmentsRequired,
      rgClassificationsSectionRequired: this.rgClassificationsSectionRequired,
      rgContaminationsSectionRequired: this.rgContaminationsSectionRequired,
      overwriteRgDescriptionDialogEnabled: this.overwriteRgDescriptionDialogEnabled,
      displayTareWeightForReceivedGood: this.displayTareWeightForReceivedGood,
      requireStockCodeOnVerificationForTier2: this.requireStockCodeOnVerificationForTier2,
      active: this.active,
    };
    if (this.units) {
      result.units = this.units;
    }
    if (this.legalEntity) {
      result.legalEntityId = this.legalEntity.id;
    }
    if (this.site) {
      result.site = this.site;
    }
    if (this.sharepointUploadFolder.id) {
      result.sharepointUploadFolderId = this.sharepointUploadFolder.id;
    }
    return result;
  }

  public get isNeedCheckDeliveryAttachments(): boolean {
    return this.deliveryAttachmentsRequired;
  }

  public get isNeedCheckAGAttachments(): boolean {
    return this.agAttachmentsRequired;
  }

  public update = (obj: TenantModel) => {
    const newTenantModel = cloneObj(obj);

    if (newTenantModel) {
      if (newTenantModel.admins && newTenantModel.admins.length !== 0) {
        newTenantModel.admins = newTenantModel.admins.map((admin) =>
          new UserModel<IUserModelConstructObj>().update(admin)
        );
      }
      newTenantModel.workflow = newTenantModel.workflow
        ? new WorkflowModel().update(newTenantModel.workflow)
        : new WorkflowModel();
      newTenantModel.workflowId = newTenantModel.workflow.id || '';
      newTenantModel.sharepointUploadFolder = !!newTenantModel.sharepointUploadFolder
        ? new SharepointModel().update(newTenantModel.sharepointUploadFolder)
        : new SharepointModel();
    }
    this.updater.update(this, newTenantModel, TenantModel);
    return this;
  };

  public pushAdmin(newAdmin: UserModel<IUserModelConstructObj>) {
    this.admins.push(new UserModel<IUserModelConstructObj>().update(newAdmin));
  }

  public removeAdmin(user: UserModel<IUserModelConstructObj>) {
    this.admins = this.admins.filter((a) => a.id !== user.id);
  }

  public setCode(val: string) {
    this.code = val;
  }

  public setSite(val: string) {
    this.site = val;
  }

  public setLocationCode(val: string) {
    this.locationCode = val;
  }

  public setGrnPrefix(val: string) {
    this.grnPrefix = val;
  }

  public setCurrentSequenceValue(val: number) {
    this.currentSequenceValue = val;
  }

  public setCurrentLabSequenceValue(val: number) {
    this.currentLabSequenceValue = val;
  }

  public setName(val: string) {
    this.name = val;
  }

  public setWorkflowId(val: string) {
    this.workflowId = val;
  }

  public setTimezone(val: string) {
    this.timezone = val;
  }

  public setRegionCode(val: string) {
    this.regionCode = val;
  }

  public setIntegrationApiBase(val: string) {
    this.integrationApiBase = val;
  }

  public setUnits(val: string) {
    this.units = val;
  }

  public setComment(val: string) {
    this.comment = val;
  }

  public setLegalEntity(val: IdNameModel) {
    this.legalEntity = val;
  }

  public setIsDeliveryCreated(val: boolean) {
    this.isDeliveryCreated = val;
  }

  public setSharepointUploadFolder(item: SharepointModel) {
    this.sharepointUploadFolder = new SharepointModel().update(item);
  }

  public setAgSignOffEnabled(val: boolean) {
    this.agSignOffEnabled = val;
  }

  public setBalanceDeductionEnabled(val: boolean) {
    this.balanceDeductionEnabled = val;
  }

  public setDeliveryAttachmentsRequired(val: boolean) {
    this.deliveryAttachmentsRequired = val;
  }

  public setAgAttachmentsRequired(val: boolean) {
    this.agAttachmentsRequired = val;
  }

  public setClassificationSectionRequired(val: boolean) {
    this.rgClassificationsSectionRequired = val;
  }

  public setContaminationSectionRequired(val: boolean) {
    this.rgContaminationsSectionRequired = val;
  }

  public setOverwriteRgDescriptionDialogEnabled(val: boolean) {
    this.overwriteRgDescriptionDialogEnabled = val;
  }

  public setDisplayTareWeightForReceivedGood(val: boolean) {
    this.displayTareWeightForReceivedGood = val;
  }

  public setRequireStockCodeOnVerificationForTier2(val: boolean) {
    this.requireStockCodeOnVerificationForTier2 = val;
  }

  public changeActiveStatus(val: boolean) {
    this.active = val;
  }
}
