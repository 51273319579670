import * as React from 'react';
import SessionStore from 'stores/SessionStore';
import { Alert } from 'reactstrap';
import TranslateService from 'services/TranslateService';
import { inject, observer } from 'mobx-react';

interface IPasswordExpirationWarningMessage {
  sessionStore?: SessionStore;
  translateService?: TranslateService;
}

const PasswordExpirationWarningMessage: React.FC<IPasswordExpirationWarningMessage> = ({
  sessionStore: { user },
  translateService: { t },
}) =>
  user.shouldShowPasswordExpirationMessage ? (
    <Alert color="danger" className="text-center" data-test="password-expiration-message">
      <strong>{t.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE(user.passwordExpirationDateMomentWithFormat)}</strong>
    </Alert>
  ) : null;

export default inject('sessionStore', 'translateService')(observer(PasswordExpirationWarningMessage));
