import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import ContentWrapper from 'domain/layout/ContentWrapper';
import Header from 'domain/layout/Header';
import Menu from 'domain/layout/Menu';

import AdminIndexRoute from 'pod/admin/AdminIndexRoute';
import SuperAdminIndexRoute from 'pod/superAdmin/SuperAdminIndexRoute';
import AdvisedGoodLabIndexRoute from 'pod/advisedGoodLab/AdvisedGoodLabIndexRoute';
import AdvisedGoodsIndexRoute from 'pod/advisedGoods/AdvisedGoodsIndexRoute';
import DeliveryIndexRoute from 'pod/deliveries/DeliveryIndexRoute';
import IndexRoute from 'pod/authenticated/IndexRoute';
import DashboardRoute from 'pod/dashboard/DashboardRoute';
import PasswordExpirationWarningMessage from 'components/PasswordExpirationWarningMessage';

class AuthenticatedIndexRoute extends React.Component<RouteComponentProps> {
  public render() {
    return (
      <div className="full-height position-relative">
        <Header pathName={this.props.location.pathname} history={this.props.history} />
        <Menu pathName={this.props.location.pathname} />
        <ContentWrapper>
          <PasswordExpirationWarningMessage />
          <Switch>
            <Route path="/" exact component={IndexRoute} />
            <Route path="/admin" component={AdminIndexRoute} />
            <Route path="/dashboard" component={DashboardRoute} />
            <Route path="/super-admin" component={SuperAdminIndexRoute} />
            <Route path="/advised-good-lab" component={AdvisedGoodLabIndexRoute} />
            <Route path="/advised-good" component={AdvisedGoodsIndexRoute} />
            <Route path="/advised-goods" component={AdvisedGoodsIndexRoute} />
            <Route path="/deliveries" component={DeliveryIndexRoute} />
          </Switch>
        </ContentWrapper>
      </div>
    );
  }
}

export default observer(AuthenticatedIndexRoute);
