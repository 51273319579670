import * as React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { instance as notification } from 'util/notification';
import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import TranslateService from 'services/TranslateService';
import ConnectionModel from 'models/ConnectionModel';
import ConnectionForm from './ConnectionForm';


interface IProps {
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
}
class ConnectionListRoute extends React.Component<IProps> {
  public isConnectionModalOpen: boolean = false;
  public connection: ConnectionModel = new ConnectionModel();

  constructor(props: IProps) {
    super(props);

    makeObservable<
      ConnectionListRoute,
      | '_setConnection'
    >(this, {
      isConnectionModalOpen: observable,
      connection: observable,
      toggleConnectionModal: action,
      _setConnection: action,
    });
  }
  public componentDidMount() {
    const { superAdminStore } = this.props;
    superAdminStore.getConnections();
  }
  public render() {
    const {
      superAdminStore: { connections },
      translateService: { t },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {t.SUPERADMIN_CONNECTIONS_TITLE}
            </h2>
            <table className="custom-table mb-3">
              <thead>
              <tr>
                <th className="ps-3">{t.GLOBAL_LABEL_CLIENT_ID}</th>
                <th className="pe-3">{t.GLOBAL_LABEL_DESCRIPTION}</th>
              </tr>
              </thead>
              {connections.map((connection: ConnectionModel) => (
                <tbody key={connection.clientId} data-test="connections-table-item">
                  <tr
                    data-test={`connections-table-item-${connection.apiName}`}
                    onClick={this._openConnectionModel(connection)}
                    className='pointer'
                  >
                    <td className="ps-3">{connection.clientId}</td>
                    <td className="pe-3">{t[connection.apiDescription]}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Col>
        </Row>

        <Modal
          isOpen={this.isConnectionModalOpen}
          toggle={this.toggleConnectionModal}
          backdrop="static"
          data-test="update-connection-modal"
        >
          <ModalHeader toggle={this.toggleConnectionModal}>{t[this.connection.apiDescription]}</ModalHeader>
          <ModalBody>
            <ConnectionForm
              content={this.connection}
              saveAction={this.updateConnection}
            />
          </ModalBody>
        </Modal>
      </Container>
    );
  }

  public updateConnection = async (connection: ConnectionModel) => {
    const {
      superAdminStore: { putConnection },
      translateService: { t },
    } = this.props;
    await putConnection(connection);
    this.toggleConnectionModal();
    notification.success(t.SUPERADMIN_CONNECTIONS_UPDATE_SUCCESS_MSG);
  }

  public toggleConnectionModal = (): void => {
    this.isConnectionModalOpen = !this.isConnectionModalOpen;
  }

  private _setConnection = (newConnection: ConnectionModel): void => {
    this.connection = new ConnectionModel().update(newConnection);
  }

  private _openConnectionModel = (connection: ConnectionModel) => () => {
    this._setConnection(connection);
    this.toggleConnectionModal();
  }
}

export default inject('superAdminStore', 'translateService')(observer(ConnectionListRoute));
