import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, computed, makeObservable, observable } from 'mobx';
import { Alert, Button, Col, Row } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import { I18N } from '../../../assets/i18n/i18n';
import TranslateService from 'services/TranslateService';
import ViewStore from 'stores/ViewStore';
import SessionStore from 'stores/SessionStore';
import OperatorModel from 'models/OperatorModel';
import CommonStore from 'stores/CommonStore';
import Autocomplete from 'domain/Autocomplete';
import ModalConfirmation from 'domain/ModalConfirmation';
import IdNameModel from 'models/IdNameModel';
import { AdvisedGoodsValidatorKey } from 'models/AdvisedGoodsModel';
import { instance as notification } from 'util/notification';
import { AdvisedGoodStatus } from 'util/enums';
import { getTranslation, joinWithCommaSeparator } from 'util/helpers';
import { TEXT_DANGER } from 'util/constants';
import AdvisedGoodSignOffButton from 'pod/advisedGoods/AdvisedGoodSignOffButton';
import AdvisedGoodsStore, { IFlaggedResponse, IUnloadedResponse } from 'pod/advisedGoods/AdvisedGoodsStore';
import AdvisedGoodComment from 'pod/advisedGoods/AdvisedGoodComment';
import UnloadInputGroup from 'pod/advisedGoods/UnloadInputGroup';

interface IAdvisedGoodButtonsProps {
  initialYardLocation: IdNameModel;
  toggleSaveConfirmationModalForLab: () => void;
  redirectToDeliveries: () => void;
  saveAdvisedGoodIfDirty: () => Promise<boolean>;
  isReadOnly: boolean;
  advisedGoodsStore?: AdvisedGoodsStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
  sessionStore?: SessionStore;
  commonStore?: CommonStore;
}

class AdvisedGoodButtons extends React.Component<IAdvisedGoodButtonsProps> {
  public constructor(props: IAdvisedGoodButtonsProps) {
    super(props);

    makeObservable<
      AdvisedGoodButtons,
      | 'flagConfirmationModal'
      | 'statusRollbackConfirmationModal'
      | 'verifyConfirmationModal'
      | 'claimConfirmationModal'
      | 'unclaimConfirmationModal'
      | 'unloadConfirmationModal'
      | 'isTareNetWeightFieldsShown'
      | 'completeConfirmationModal'
      | 'deleteConfirmationModal'
      | '_hasClaimButton'
      | '_hasUnclaimButton'
      | '_hasUnloadedButton'
      | '_hasStatusRollbackButton'
      | '_hasCompleteButton'
      | '_hasVerifyButton'
      | '_isVerifyButtonDisabled'
      | '_toggleClaimConfirmationModal'
      | '_toggleUnclaimConfirmationModal'
      | '_toggleUnloadConfirmationModal'
      | '_toggleFlagConfirmationModal'
      | '_toggleCompleteConfirmationModal'
    >(this, {
      flagConfirmationModal: observable,
      statusRollbackConfirmationModal: observable,
      verifyConfirmationModal: observable,
      claimConfirmationModal: observable,
      unclaimConfirmationModal: observable,
      unloadConfirmationModal: observable,
      isTareNetWeightFieldsShown: observable,
      completeConfirmationModal: observable,
        deleteConfirmationModal: observable,
      _hasClaimButton: computed,
      _hasUnclaimButton: computed,
      _hasUnloadedButton: computed,
      _hasStatusRollbackButton: computed,
      _hasCompleteButton: computed,
      _hasVerifyButton: computed,
      _isVerifyButtonDisabled: computed,
      _toggleClaimConfirmationModal: action,
      _toggleUnclaimConfirmationModal: action,
      _toggleUnloadConfirmationModal: action,
      _toggleFlagConfirmationModal: action,
      _toggleCompleteConfirmationModal: action,
    });
  }
  public flagConfirmationModal: boolean = false;
  public statusRollbackConfirmationModal: boolean = false;
  public verifyConfirmationModal: boolean = false;
  public claimConfirmationModal: boolean = false;
  public unclaimConfirmationModal: boolean = false;
  public unloadConfirmationModal: boolean = false;
  public isTareNetWeightFieldsShown: boolean = false;
  public completeConfirmationModal: boolean = false;
  public deleteConfirmationModal: boolean = false;

  public get deliveryReceivedWeight(): number {
      return this.props.advisedGoodsStore.content.deliveryReceivedWeight;
  }

  public get isSortingWeightMoreThanReceived(): boolean {
      const {
          advisedGoodsStore: {
              content: { totalAdvisedGoodWeightWithDeductions },
          },
      } = this.props;
      return this.deliveryReceivedWeight && totalAdvisedGoodWeightWithDeductions > this.deliveryReceivedWeight;
  }

  public validate = (): boolean => {
      const {
          advisedGoodsStore: { content },
          viewStore: { countryCode },
          translateService: { t },
      } = this.props;
      if (content.isFlagged) {
          notification.error(t.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF);
          return false;
      }
      if (!content.validators.subAdvisedGoods) {
          notification.error(t.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD);
          return false;
      }
      if (!content.isValid(countryCode)) {
          const invalidKeyLabels: string[] = content.invalidValidatorKeys
              .get(countryCode)
              .map((key: AdvisedGoodsValidatorKey) => this._getValidationMessageMap.get(key))
              .filter((f) => f);
          notification.error(
              t.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS(joinWithCommaSeparator(invalidKeyLabels).toLowerCase())
          );
          return false;
      }
      return true;
  };

  public render() {
    const {
      translateService: { t },
      viewStore: { isLabFromDE, isLabFromDE_D365, isTier3FromDE_D365 },
      advisedGoodsStore: { content, hasAgSignOff },
      toggleSaveConfirmationModalForLab,
    } = this.props;
    return (
      <Row>
        <Col sm="12" className="mb-3">
        <div className="flex-wrap-space-between">
          <h2 className="mb-0 clearfix">{content.agTitle}</h2>

          <div className="btn-container" data-test="btn-container">
            {/* CLAIM BUTTON */}
            {this._hasClaimButton && (
              <Button data-test="claim-btn" color="success" onClick={this._claimAdvisedGood}>
                {t.GLOBAL_LABEL_CLAIM}
              </Button>
            )}

            {/* SIGN OFF BUTTON */}
            {hasAgSignOff(content) && <AdvisedGoodSignOffButton ag={content} validate={this.validate} />}

            {/* UNCLAIM BUTTON */}
            {this._hasUnclaimButton && (
              <Button data-test="unclaim-btn" color="success" onClick={this._toggleUnclaimConfirmationModal}>
                {t.GLOBAL_LABEL_UNCLAIM}
              </Button>
            )}

            {/* UNLOAD BUTTON */}
            {this._hasUnloadedButton && (
              <Button
                data-test="button-unloaded"
                type="button"
                size="sm"
                color="success"
                onClick={this._unloadAdvisedGood}
              >
                {t.ADVISEDGOOD_UNLOAD_TITLE}
              </Button>
            )}

            {/* FLAG ADVISED GOOD BUTTON */}
            {this._showFlagButton && (
              <Button
                data-test="flag-btn"
                color={content.isFlagged ? 'danger' : 'success'}
                onClick={this._toggleFlagConfirmationModal}
              >
                <FontAwesome name="flag" className="me-0" />
              </Button>
            )}

            {/* TIER 3 COMPLETE BUTTON */}
            {this._hasCompleteButton && (
              <Button
                color="success"
                data-test="complete-btn"
                disabled={!content.subAdvisedGoods}
                onClick={this._toggleCompleteConfirmationModal}
              >
                {t.GLOBAL_FILTERKEY_COMPLETE}
              </Button>
            )}

            {/* TIER 2 VERIFY BUTTON */}
            {this._hasVerifyButton && (
              <Button
                data-test="verify-btn"
                color="success"
                disabled={this._isVerifyButtonDisabled}
                onClick={this._toggleVerifyConfirmationModal}
              >
                {t.GLOBAL_LABEL_VERIFY}
              </Button>
            )}

            {/* TIER 2 ROLLBACK BUTTON */}
            {this._hasStatusRollbackButton && (
              <Button data-test="rollback-btn" color="secondary" onClick={this._toggleStatusRollbackConfirmationModal}>
                {t.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK}
              </Button>
            )}

            {/* ADMIN DELETE BUTTON */}
            {this._hasDeleteButton && (
                <Button
                    data-test="delete-ag-button"
                    className="float-end"
                    size="md"
                    type="button"
                    color="secondary"
                    onClick={this._toggleDeleteConfirmationModal}
                >
                    {t.GLOBAL_LABEL_DELETE}
                </Button>
            )}

            {/* LAB FROM DE LABLIST BUTTON */}
            {(isLabFromDE || isLabFromDE_D365) && (
              <Button color="success" onClick={toggleSaveConfirmationModalForLab}>
                {t.LABORATORY_ADVISEGOODS_LAB_LIST}
              </Button>
            )}
          </div>
        </div>

        {content.isFlagged && (
          <Alert color="danger" className="text-center mt-4">
            <strong>{t.GLOBAL_LABEL_ALERT}</strong>
          </Alert>
        )}
          {/* MODAL CONFIRMATION FOR FLAGGING */}
          <ModalConfirmation
            data-test="flag-modal"
            buttonYesColor={content.isFlagged ? 'success' : 'danger'}
            callbackYes={this._flagAdvisedGood}
            heading={t.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER(this._hasFlag)}
            ico="flag"
            isOpen={this.flagConfirmationModal}
            text={t.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT(
              content.isFlagged ? 'text-success' : TEXT_DANGER,
              this._hasFlag
            )}
            toggleModal={this._toggleFlagConfirmationModal}
          />

          {/* MODAL CONFIRMATION FOR MARKING AS COMPLETE - TIER 1 */}
          <ModalConfirmation
              buttonYesColor="success"
              callbackYes={this._tier3Complete}
              heading={t.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER}
              ico="check"
              isOpen={this.completeConfirmationModal}
              text={t.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT}
              toggleModal={this._toggleCompleteConfirmationModal}
          />


            {/* MODAL CONFIRMATION FOR MARKING AS VERIFIED - TIER 2 */}
            <ModalConfirmation
                buttonYesColor="success"
                callbackYes={this._tier2Verify}
                heading={t.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER}
                ico="check"
                isOpen={this.verifyConfirmationModal}
                body={this._getVerifyConfirmationModalBody()}
                toggleModal={this._toggleVerifyConfirmationModal}
                data-test="verify-confirmation"
            />

            {/* MODAL CONFIRMATION FOR STATUS ROLLBACK TO CLAIMED - TIER 2*/}
            <ModalConfirmation
                data-test="rollback-modal"
                buttonYesColor="success"
                callbackYes={this._statusRollback}
                heading={
                    this._isRollbackToUnloaded
                        ? t.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER
                        : t.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER
                }
                ico="backward"
                isOpen={this.statusRollbackConfirmationModal}
                text={
                    this._isRollbackToUnloaded
                        ? t.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT
                        : t.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT
                }
                toggleModal={this._toggleStatusRollbackConfirmationModal}
            />
            {/* MODAL CONFIRMATION FOR CLAIM AG */}
            <ModalConfirmation
                data-test="claim-confirmation"
                buttonYesColor="success"
                buttonNo={t.GLOBAL_LABEL_CANCEL}
                buttonYes={t.GLOBAL_LABEL_CONFIRM}
                callbackNo={this._cancelClaimAG}
                callbackYes={this.claimAdvisedGood}
                heading={t.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER}
                isOpen={this.claimConfirmationModal}
                body={this._claimModalBody}
                toggleModal={this._toggleClaimConfirmationModal}
            />

            {/* MODAL CONFIRMATION FOR UNCLAIM AG */}
            <ModalConfirmation
                data-test="unclaim-modal"
                buttonYesColor="success"
                callbackYes={this._unclaimAdvisedGood}
                heading={t.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER}
                ico="check"
                isOpen={this.unclaimConfirmationModal}
                text={t.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT}
                toggleModal={this._toggleUnclaimConfirmationModal}
            />

            {/* MODAL CONFIRMATION FOR UNLOAD AG */}
            <ModalConfirmation
                buttonYesColor="success"
                buttonNo={t.GLOBAL_LABEL_CANCEL}
                buttonYes={t.GLOBAL_LABEL_CONFIRM}
                buttonYesDisabled={isTier3FromDE_D365 && !content.attachments.length}
                callbackYes={this._confirmAdvisedGoodUnloaded}
                heading={t.ADVISEDGOOD_DEDUCTION_MODAL_HEADER}
                isOpen={this.unloadConfirmationModal}
                body={this._unloadModalBody}
                toggleModal={this._toggleUnloadConfirmationModal}
            />
            {/* MODAL CONFIRMATION - DELETE AG */}
            <ModalConfirmation
                data-test="delete-ag-confirmation"
                buttonNo={t.GLOBAL_LABEL_CANCEL}
                buttonYes={t.GLOBAL_LABEL_CONFIRM}
                callbackNo={this._toggleDeleteConfirmationModal}
                callbackYes={this._deleteAG}
                heading={t.ADVISEDGOOD_ROUTE_DELETE}
                isOpen={this.deleteConfirmationModal}
                text={t.ADVISEDGOOD_ROUTE_DELETE_CONFIRMATION}
                toggleModal={this._toggleDeleteConfirmationModal}
            />
      </Col>
      </Row>
    );
  }

    private get _getValidationMessageMap(): Map<AdvisedGoodsValidatorKey, string> {
        const {
            translateService: { t },
        } = this.props;
        return new Map<AdvisedGoodsValidatorKey, string>([
            ['RGsDescription', t.GLOBAL_LABEL_DESCRIPTION],
            ['RGsGrossWeight', t.GLOBAL_LABEL_WEIGHT],
            ['RGsBulkDensity', t.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY],
            ['RGsFoundQuality', t.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY],
            ['RGsMainType', t.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE],
            ['RGsMaterialDescription', t.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION],
            ['RGsWiDone', t.ADVISEDGOOD_SORTINGREPORT_WI_DONE],
            ['RGsContamination', t.RECEIVED_GOOD_CONTAMINATION_TITLE],
            ['RGsProductForm', t.GLOBAL_LABEL_FORM],
            ['RGsProductQuality', t.GLOBAL_LABEL_QUALITY],
            ['processingData', t.SORTINGREPORT_PROCESSING_TITLE],
        ]);
    }

  // TOGGLE COMPLETE - TIER 3
  private _toggleCompleteConfirmationModal = () => {
    this.completeConfirmationModal = !this.completeConfirmationModal;
  };

  // TOGGLE VERIFY - TIER 2
  private _toggleVerifyConfirmationModal = () => {
    this.verifyConfirmationModal = !this.verifyConfirmationModal;
  };

  // TOGGLE STATUS ROLLBACK - TIER 2
  private _toggleStatusRollbackConfirmationModal = () => {
    this.statusRollbackConfirmationModal = !this.statusRollbackConfirmationModal;
  };

  private _toggleFlagConfirmationModal = () => {
    this.flagConfirmationModal = !this.flagConfirmationModal;
  };

  private _toggleClaimConfirmationModal = () => {
    this.claimConfirmationModal = !this.claimConfirmationModal;
  };

  private _toggleUnclaimConfirmationModal = () => {
    this.unclaimConfirmationModal = !this.unclaimConfirmationModal;
  };

  private _toggleUnloadConfirmationModal = () => {
    this.unloadConfirmationModal = !this.unloadConfirmationModal;
  };

  private _toggleDeleteConfirmationModal = () => {
    this.deleteConfirmationModal = !this.deleteConfirmationModal;
  };

  private get _hasClaimButton(): boolean {
    const {
      advisedGoodsStore: {
        content: { claimedBy, status },
      },
    } = this.props;

    return (
      !(claimedBy && claimedBy.user && claimedBy.user.id) &&
      status === AdvisedGoodStatus.WAITING &&
      this._isUserRoleThatCanClaim
    );
  }

  private get _hasDeleteButton(): boolean {
    const {
      sessionStore: { isAdmin }
    } = this.props;

    return isAdmin && false;
  }

  private get _hasStatusRollbackButton() {
    const {
      sessionStore: { isTier2, isTier1, isAdmin },
      viewStore: { isUK, isUS, isAdminFromDE_D365 },
      advisedGoodsStore: {
        content: { status },
      },
    } = this.props;

    return (
      (isTier1 && (status === AdvisedGoodStatus.READY_FOR_TIER_2 || status === AdvisedGoodStatus.READY_FOR_SIGN_OFF)) ||
      (isTier2 && (isUK || isUS) && status === AdvisedGoodStatus.READY_FOR_TIER_2) ||
      (isAdmin &&
        !isAdminFromDE_D365 &&
        [AdvisedGoodStatus.IN_LAB, AdvisedGoodStatus.UNLOADED, AdvisedGoodStatus.READY_FOR_TIER_2].includes(status))
    );
  }

  private get _isUserRoleThatCanClaim(): boolean {
    const {
      sessionStore: { isAdmin },
    } = this.props;

    return this._isRoleForClaimOrUnclaim && !isAdmin;
  }

  private get _isRoleForClaimOrUnclaim(): boolean {
    const {
      sessionStore: { isLab, isWeightBridgeOperator, isTraderOrBackOffice, isManager },
      viewStore: { isTier1FromDE, isTier1FromIT, isTier1FromFR, isTier1FromDE_D365 },
    } = this.props;

    return (
      !isLab &&
      !isWeightBridgeOperator &&
      !isTraderOrBackOffice &&
      !isTier1FromDE &&
      !isTier1FromDE_D365 &&
      !isTier1FromIT &&
      !isTier1FromFR &&
      !isManager
    );
  }

  private _claimAdvisedGood = () => {
    const {
      viewStore: { isDE, isDE_D365 },
    } = this.props;

    if (isDE || isDE_D365) {
      this._toggleClaimConfirmationModal();
    } else {
      this.claimAdvisedGood();
    }
  };

  public claimAdvisedGood = () => {
    const {
      advisedGoodsStore: { postClaimAdvisedGood, content },
      viewStore: { isDE, isDE_D365 },
    } = this.props;
    postClaimAdvisedGood(content.id, isDE || isDE_D365 ? content.yardLocation.id : null).then(
      (res: { newStatus: AdvisedGoodStatus; claimedBy: OperatorModel }) => {
        content.setStatus(res.newStatus);
        content.setClaimedBy(res.claimedBy);
        // this needed to clear hasChanged status after claim AG for Germany(user can change location and hasChanged will be true)
        content.clearIsDirty();
        notification.success(this.props.translateService.t.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG);
      }
    );
  };

  private get _hasUnclaimButton(): boolean {
    const {
      sessionStore: { user, isAdmin },
      advisedGoodsStore: {
        content: { claimedBy, status },
      },
    } = this.props;
    return (
      ((claimedBy && claimedBy.user && claimedBy.user.id && claimedBy.user.id === user.id) || isAdmin) &&
      status === AdvisedGoodStatus.CLAIMED &&
      this._isRoleForClaimOrUnclaim
    );
  }

  private get _hasUnloadedButton(): boolean {
    const {
      viewStore: { isTier3FromIT, isTier2FromIT, isTier2FromDE, isTier3FromFR, isTier2FromFR, isTier2FromDE_D365, isTier3FromDE_D365 },
      advisedGoodsStore: {
        content: { status },
      },
    } = this.props;

    return (
      (isTier2FromDE || isTier2FromDE_D365 || isTier3FromDE_D365 || isTier2FromIT || isTier2FromFR || isTier3FromIT || isTier3FromFR) &&
      status === AdvisedGoodStatus.CLAIMED
    );
  }

  private _unloadAdvisedGood = () => {
    const {
      viewStore: { isDE, isDE_D365 },
      advisedGoodsStore: { content },
    } = this.props;

    if ((isDE || isDE_D365) && content.isRequiredGrossAndTareWeights) {
      this._toggleUnloadConfirmationModal();
    } else {
      this._confirmAdvisedGoodUnloaded();
    }
  };

  private get _showFlagButton() {
    const {
      sessionStore: { isTier3 },
      advisedGoodsStore: {
        content: { isFlagged },
      },
      viewStore: {
        isTier2FromDE,
        isTier2FromIT,
        isTier1FromDE,
        isTier1FromIT,
        isTier2FromFR,
        isTier1FromFR,
        isTier1FromDE_D365,
        isTier2FromDE_D365,
        isTraderFromFR,
      },
      isReadOnly
    } = this.props;
    // Tier1 from Germany, Italy, France or DE_D365 and Traders have readOnly mode always, because of this we have to exclude them from this._isReadOnly
    return !(
      ((isTier3 || isTier2FromDE || isTier2FromIT || isTier2FromFR || isTier2FromDE_D365) && isFlagged) ||
      (isReadOnly && !(isTier1FromDE || isTier1FromIT || isTier1FromFR || isTier1FromDE_D365 || isTraderFromFR))
    );
  }

  private get _hasCompleteButton() {
    const {
      sessionStore: { isTier3 },
      advisedGoodsStore: {
        content: { status },
      },
      viewStore: { isIT, isFR, isDE_D365 },
      isReadOnly
    } = this.props;
    const hasRightStatus = (isDE_D365 || isFR || isIT)
      ? status === AdvisedGoodStatus.UNLOADED
      : status === AdvisedGoodStatus.CLAIMED;
    return (
      !isReadOnly &&
      isTier3 &&
      hasRightStatus
    );
  }

  private get _hasVerifyButton() {
    const {
      sessionStore: { isTier2, isTier1 },
      viewStore: { isDE, isIT, isFR, isTier1FromDE, isTier1FromIT, isTier1FromFR, isDE_D365, isTier1FromDE_D365 },
      advisedGoodsStore: {
        content: { status },
      },
      isReadOnly
    } = this.props;

    return (
      !isReadOnly &&
      (isTier2 || isTier1) &&
      !(isTier1FromDE || isTier1FromIT || isTier1FromFR || isTier1FromDE_D365) &&
      (status === AdvisedGoodStatus.READY_FOR_TIER_2 ||
        (!(isDE || isIT || isFR || isDE_D365) && status === AdvisedGoodStatus.CLAIMED) ||
        ((isDE || isIT || isFR || isDE_D365) && status === AdvisedGoodStatus.UNLOADED))
    );
  }

  private get _isVerifyButtonDisabled() {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.isRequiredGrossAndTareWeights && !this.deliveryReceivedWeight;
  }

  // MARK ADVISED GOOD AS FLAGGED
  private _flagAdvisedGood = () => {
    const {
      translateService: { t },
      advisedGoodsStore: { content, postFlagAdvisedGood },
    } = this.props;
    return postFlagAdvisedGood(content.id, !content.isFlagged).then((res: IFlaggedResponse) => {
      content.setFlagged(res.isFlagged);
      if (content.isFlagged) {
        notification.error(t.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG);
        content.setFlaggedBy(new OperatorModel().update(res.flaggedBy));
      } else {
        notification.success(t.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG);
        content.setUnflaggedBy(new OperatorModel().update(res.unflaggedBy));
      }
    });
  };

  private get _hasFlag(): string {
      const {
          translateService: { t },
      } = this.props;
      const {
          advisedGoodsStore: { content },
      } = this.props;

      return getTranslation(`GLOBAL_LABEL_${content.isFlagged ? 'UN_FLAG' : 'FLAG'}` as keyof I18N, t) as string;
  }

    // TIER 3 - MARK AS COMPLETE
  private _tier3Complete = async () => {
      const {
          translateService: { t },
          advisedGoodsStore: { content, postTier3Complete },
          saveAdvisedGoodIfDirty,
          redirectToDeliveries
      } = this.props;

      const result = await saveAdvisedGoodIfDirty();
      if (result) {
          return postTier3Complete(content.id).then(() => {
              this.props.advisedGoodsStore.content.setStatus(AdvisedGoodStatus.READY_FOR_TIER_2);
              notification.success(t.ADVISEDGOOD_ROUTE_COMPLETED_MSG);
              redirectToDeliveries(); // REDIRECT TO LIST
          });
      }
  };

    // TIER 2 - MARK AS VERIFIED
  private _tier2Verify = async () => {
      const {
          translateService: { t },
          advisedGoodsStore: { content, postTier2Verify },
          saveAdvisedGoodIfDirty,
          redirectToDeliveries
      } = this.props;

      if (!this.validate()) {
          return false;
      }

      const result = await saveAdvisedGoodIfDirty();
      if (result) {
          return postTier2Verify(content.id).then(() => {
              this.props.advisedGoodsStore.content.setStatus(AdvisedGoodStatus.READY_FOR_SIGN_OFF);
              notification.success(t.ADVISEDGOOD_ROUTE_VERIFIED_MSG);
              redirectToDeliveries(); // REDIRECT TO LIST
          });
      }
  };

  private _getVerifyConfirmationModalBody(): React.ReactNode {
      const {
          viewStore: { isDE, isDE_D365 },
          translateService: { t },
      } = this.props;

      return (isDE || isDE_D365) && this.isSortingWeightMoreThanReceived ? (
          <div className={classnames(`${TEXT_DANGER} flex`)}>
              <FontAwesome
                  name="exclamation-triangle"
                  className={classnames('font-17 me-3')}
                  data-test="verify-danger-triangle"
              />
              <span data-test="danger-verify-text">{t.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT}</span>
          </div>
      ) : (
          <div
              data-test="success-verify-text"
              dangerouslySetInnerHTML={{ __html: t.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT }}
          />
      );
  }



    // TIER 2 OR TIER 1 - STATUS ROLLBACK
    private _statusRollback = async () => {
        const {
            advisedGoodsStore: { content, postStatusRollback },
            saveAdvisedGoodIfDirty
        } = this.props;
        const result = await saveAdvisedGoodIfDirty();
        if (result) {
            const res: { newStatus: AdvisedGoodStatus; rolledbackBy: OperatorModel } = await postStatusRollback(content.id);
            this.props.advisedGoodsStore.content.setStatus(res.newStatus);
            this.props.advisedGoodsStore.content.setRollbackBy(res.rolledbackBy);
            this.props.advisedGoodsStore.content.clearIsDirty();

            return res;
        }
    };

    private get _isRollbackToUnloaded(): boolean {
        const {
            viewStore: { isDE_D365, isDE, isFR, isIT },
        } = this.props;
        return isDE_D365 || isDE || isFR || isIT;
    }

    private _cancelClaimAG = () => {
        const {
            advisedGoodsStore: {
                content: { setYardLocation, clearIsDirty },
            },
            initialYardLocation
        } = this.props;
        setYardLocation(initialYardLocation);
        clearIsDirty();
        this._toggleClaimConfirmationModal();
    };

    private get _claimModalBody(): React.ReactNode {
        const {
            translateService: { t },
            commonStore: { yardLocations },
            advisedGoodsStore: { content },
        } = this.props;

        return (
            <div className="flex">
                <span className="margin-right-1rem">{t.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT}</span>
                <Autocomplete
                    placeholder={t.GLOBAL_LABEL_SELECT}
                    items={yardLocations}
                    onSelect={(selectedItem: IdNameModel) => content.setYardLocation(selectedItem)}
                    selectedValue={content.yardLocation && content.yardLocation.id}
                />
            </div>
        );
    }


    private _unclaimAdvisedGood = async () => {
      const { saveAdvisedGoodIfDirty } = this.props;
        const result = await saveAdvisedGoodIfDirty();
        if (result) {
            const res: {
                newStatus: AdvisedGoodStatus;
                claimedBy: OperatorModel;
            } = await this.props.advisedGoodsStore.postUnclaimAdvisedGood(this.props.advisedGoodsStore.content.id);
            const {
                advisedGoodsStore: { content },
                translateService: { t },
            } = this.props;

            content.setStatus(res.newStatus);
            content.setClaimedBy(res.claimedBy);
            content.clearIsDirty();
            notification.success(t.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG);
        }
    };

    // TIER 2 OR TIER 1 - CONFIRM DELIVERY UNLOAD COMPLETION
    private _confirmAdvisedGoodUnloaded = async () => {
        const {
            viewStore: { isIT },
            translateService: { t },
            saveAdvisedGoodIfDirty
        } = this.props;
        if ((isIT) && !this.props.advisedGoodsStore.content.validators.RGsContamination) {
            notification.error(
                t.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS(t.RECEIVED_GOOD_CONTAMINATION_TITLE.toLowerCase())
            );
            return false;
        }
        const result = await saveAdvisedGoodIfDirty();
        if (result) {
            const {
                advisedGoodsStore: { content, postAdvisedGoodUnload },
            } = this.props;
            postAdvisedGoodUnload(content).then((res: IUnloadedResponse) => {
                content.setStatus(res.status);
                if (res.deduction) {
                    content.deduction.update(res.deduction);
                }
                content.clearIsDirty();
                notification.success(t.ADVISEDGOOD_UNLOAD_UPDATE_MSG);
            });
        }
    };

    private get _unloadModalBody() {
        const {
            viewStore: { isDE_D365, isTier3FromDE_D365, isTier2FromDE_D365 },
            translateService: { t },
            advisedGoodsStore: { content: { attachments } },
            isReadOnly
        } = this.props;
        const attachmentError = isTier3FromDE_D365 && !attachments.length
          ? <Alert data-test="attachments-error-alert" color="danger" className="text-center font-16">{t.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_ERROR}</Alert>
          : null;
        const attachmentWarning = isTier2FromDE_D365 && !attachments.length
          ? <Alert data-test="attachments-warning-alert" color="warning" className="text-center font-16">{t.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_WARNING}</Alert>
          : null;

        return (
            <>
                {attachmentError}
                {attachmentWarning}
                <UnloadInputGroup isReadOnly={isReadOnly}/>
                {isDE_D365 && (
                    <div data-test="unload-comment">
                        <h5 className="mt-4 clearfix">{t.ADVISEDGOOD_ROUTE_COMMENT}</h5>
                        <AdvisedGoodComment isReadOnly={ isReadOnly }/>
                    </div>
                )}
            </>
        );
    }

    private _deleteAG = async () => {
        const {
            advisedGoodsStore: { deleteAG, content: { id } },
            redirectToDeliveries,
            translateService: { t }
        } = this.props;

        await deleteAG(id);
        notification.success(t.ADVISEDGOOD_ROUTE_DELETED(id));
        redirectToDeliveries();
    }
}

export default inject('advisedGoodsStore',
    'commonStore',
    'sessionStore',
    'viewStore',
    'translateService')(observer(AdvisedGoodButtons));
