import UserModel from 'models/UserModel';
import UpdateModel from 'models/UpdateModel';
import { action, makeObservable } from 'mobx';
import { cloneObj } from 'util/helpers';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class ActivityLogModel extends UpdateModel<ActivityLogModel> {
  public id: string = null;
  public action: string = null;
  public createdAt: string = null;
  public message: string = null;
  public grn: string = null;

  public user: UserModel<IUserModelConstructObj>;

  public update = (obj: ActivityLogModel) => {
    const newActivityLogModel = cloneObj(obj);

    if (newActivityLogModel) {
      newActivityLogModel.user = newActivityLogModel.user
        ? new UserModel<IUserModelConstructObj>().update(newActivityLogModel.user)
        : new UserModel<IUserModelConstructObj>();
    }

    this.updater.update(this, newActivityLogModel, ActivityLogModel);

    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      update: action,
    });
  }
}
