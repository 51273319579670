import * as moment from 'moment-timezone';
import { I18N } from '../../assets/i18n/i18n';
import { stringify, StringifyOptions } from 'query-string';
import { logger } from 'util/logger';
import FileModel from '../models/FileModel';

export function transformKey(key: string): string {
  return key.replace(/[\s\-]+/g, '_').toUpperCase();
}

export function transformKeyToText(key: string): string {
  return key.replace(/[\s\_]+/g, ' ').toLowerCase();
}

export function transformEveryFirstLetterToUpperCase(str: string, separator: string = ' '): string {
  const strArray = str.split(separator);
  return strArray.map((s) => transformFirstLetterToUpperCase(s)).join(separator);
}

export function transformFirstLetterToUpperCase(str: string): string {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function isMobile(): boolean {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
}

export function nullCheck(val: string) {
  return val ? val : '';
}

export function getTranslation(key: keyof I18N, t: I18N): string | ((...rest: string[]) => string) {
  if (!t[key]) {
    logger.info(`Key ${key} not found`);
  }

  return t[key];
}

export const debounce = (() => {
  let _timer: number = null;
  return (fn: TimerHandler, time: number = 500) => {
    if (_timer) {
      clearTimeout(_timer);
    }

    _timer = window.setTimeout(fn, time);
  };
})();

export function excludeNullAndEmptyStringValue<T>(obj: T): NonNullable<T> {
  return Object.keys(obj).reduce((res: Partial<T>, item: string) => {
    if (obj[item as keyof T]) {
      res[item as keyof Partial<T>] = obj[item as keyof T];
    }

    return res;
  }, {} as NonNullable<T>) as NonNullable<T>;
}

export function stringifyNonNullableObj(obj: object, options?: StringifyOptions) {
  return stringify(excludeNullAndEmptyStringValue(obj), options);
}

export const processDecimals = (val: number, decimals: number): string => {
  if (val === 0) {
    return String(val);
  }

  if (val && !isNaN(val)) {
    return val.toFixed(decimals);
  } else {
    return '';
  }
};

export const toFixed = (value: number, numberOfDecimals: number): number => {
  const arr = value.toString().split('.');
  if (arr.length === 1) {
    return Number(value);
  }

  const floatingPart = arr[1];
  const fixedFloatingPart =
    floatingPart.length <= numberOfDecimals ? floatingPart : floatingPart.slice(0, numberOfDecimals);
  return Number(`${arr[0]}.${fixedFloatingPart}`);
};

export const inputNumberStep = (decimals: number): string => {
  if (!decimals) {
    return '1';
  }
  const zeroes = new Array(decimals - 1).fill(0).reduce((acc) => `${acc}0`, '');
  return `0.${zeroes}1`;
};

export const isIosVersionLess13 = (): boolean => {
  const match = navigator && navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);

  return match !== undefined && match !== null && parseInt(match[1], 10) < 13;
};

export const getPreviousDay = (): moment.Moment => {
  return moment().subtract(1, 'days');
};

export const getNextDay = (): moment.Moment => {
  return moment().add(1, 'day');
};

export function cloneObj<T>(obj: T): T {
  return obj ? Object.assign({}, obj) : null;
}

export const addBracketsWithContentToString = (
  str: string,
  squareBracketsContent: string,
  roundBracketsContent: string
): string => {
  const formattedSquareBracketsContent = squareBracketsContent ? `[${squareBracketsContent}]` : '';
  const formattedRoundBracketsContent = roundBracketsContent ? `(${roundBracketsContent})` : '';
  return `${str} ${formattedSquareBracketsContent} ${formattedRoundBracketsContent}`;
};

export function sortActiveFirst<T extends { active?: boolean }>(array: T[]): T[] {
  return array.slice().sort((first: T, second: T) => (first.active === second.active ? 0 : first.active ? -1 : 1));
}

export function joinWithCommaSeparator(arr: string[]): string {
  return arr.join(', ');
}

export const splitFileModelsArrayToImagesAndPdfs = (arr: FileModel[]): [FileModel[], FileModel[]] => {
  const images = arr.filter((f) => !f.isAttachmentPDF);
  const pdfs = arr.filter((f) => f.isAttachmentPDF);
  return [images, pdfs];
};

export const colors = {
  black: '#292B2C',
  white: '#FFFFFF',
  red: '#e00f20',
  green: '#008000',
  greyLigth: '#f9f9f9',
  grey: '#e9ecef',
};

export const colorArray = ['#e00f20', '#008000', '#36A2EB', '#FFCE56', '#00FF00'];

export const reloadPage = () => {
  if (typeof JSInterface !== 'undefined' && JSInterface && JSInterface.reloadApp) {
    JSInterface.reloadApp();
  } else {
    window.location.reload();
  }
};

export interface PromiseWrapper<T> {
  read(): T;
}

export function promiseWrapper<T>(promise: Promise<T>): PromiseWrapper<T> {
  let status = 'pending';
  let result: T;
  const suspender = promise.then(
    (r) => {
      status = 'success';
      result = r;
    },
    (e) => {
      status = 'error';
      result = e;
    }
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    },
  };
}
