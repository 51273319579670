import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import FileModel from 'models/FileModel';
import ModalConfirmation from 'domain/ModalConfirmation';
import { inject, observer } from 'mobx-react';
import TranslateService from 'services/TranslateService';
import SessionStore from 'stores/SessionStore';
import ViewStore from 'stores/ViewStore';
import { isMobile } from 'util/helpers';
import { instance as notification } from 'util/notification';

interface IProps {
  attachment: FileModel;
  index: number;
  onDelete: (attachment: FileModel) => void;
  setIndexForLightbox?: (imageToShow: number) => void;
  openPDF?: (url: string) => void;
  key?: string;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

interface IState {
  deleteConfirmationModal: boolean;
}

class AttachmentGalleryPicture extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      deleteConfirmationModal: false,
    };
  }

  private toggleDeleteConfirmationModal = () => {
    this.setState({
      deleteConfirmationModal: !this.state.deleteConfirmationModal,
    });
  };

  public render() {
    const {
      attachment,
      onDelete,
      translateService: { t },
    } = this.props;
    const { deleteConfirmationModal } = this.state;

    const attachmentOwner = attachment.createdBy.fullNameWithShortName;

    return (
      <div className="pull-left img-wrapper">
        <img src={attachment.thumbnailPath} onClick={this._handleImageClick} data-test="image" />
        {this._canDeleteAttachment && (
          <button
            data-test="attachment-delete-btn"
            type="button"
            className="img-delete-btn"
            onClick={this.toggleDeleteConfirmationModal}
          >
            <FontAwesome name="trash-o" />
          </button>
        )}
        {this._hasDownloadButton && (
          <a
            data-test="attachment-download-btn"
            href={attachment.path}
            onClick={() => notification.success(t.GLOBAL_LABEL_FILE_DOWNLOADED_MSG)}
            download
            className="img-download-btn"
          >
            <FontAwesome name="download" />
          </a>
        )}
        <ModalConfirmation
          buttonYesColor="success"
          callbackYes={() => onDelete(attachment)}
          heading={t.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER}
          ico="trash-o"
          isOpen={deleteConfirmationModal}
          text={`<div class="text-center">${t.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT} <br /> <img class="mt-2 max-width-200" src=${attachment.thumbnailPath} /></div>`}
          toggleModal={this.toggleDeleteConfirmationModal}
        />
        {attachmentOwner && <div data-test="attachment-owner" className="word-break-all">{`(${attachmentOwner})`}</div>}
      </div>
    );
  }

  private get _canDeleteAttachment(): boolean {
    const {
      attachment,
      sessionStore: { user, isAdmin },
    } = this.props;

    return !attachment.createdBy.id || attachment.createdBy.id === user.id || isAdmin;
  }

  private get _hasDownloadButton(): boolean {
    const {
      viewStore: { isUS },
    } = this.props;
    return !isMobile() && isUS;
  }

  private _handleImageClick = () => {
    const { setIndexForLightbox, index, openPDF, attachment } = this.props;
    if (attachment.isAttachmentPDF) {
      openPDF && openPDF(attachment.path);
    } else {
      setIndexForLightbox && setIndexForLightbox(index);
    }
  };
}

export default inject('sessionStore', 'translateService', 'viewStore')(observer(AttachmentGalleryPicture));
