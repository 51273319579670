import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import SuperAdminTenantListRoute from 'pod/superAdmin/tenants/TenantListRoute';
import SuperAdminAdminsListRoute from 'pod/superAdmin/admins/AdminListRoute';
import SuperAdminAppUsersListRoute from 'pod/superAdmin/appUsers/AppUsersListRoute';
import ConnectionListRoute from 'pod/superAdmin/connections/ConnectionListRoute';
import AddNewTenantRoute from './AddNewTenantRoute';

export default class SuperAdminIndexRoute extends React.PureComponent {
  public render() {
    return (
      <Switch>
        <Route path="/super-admin/tenants" component={SuperAdminTenantListRoute} />
        <Route path="/super-admin/admins" component={SuperAdminAdminsListRoute} />
        <Route path="/super-admin/app-users" component={SuperAdminAppUsersListRoute} />
        <Route path="/super-admin/connections" component={ConnectionListRoute} />
        <Route path="/super-admin/add-new-tenant" component={AddNewTenantRoute} />
      </Switch>
    );
  }
}
