import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

export default class SharepointModel extends UpdateModel<SharepointModel> {
  public id?: string = null;
  public path: string = '';
  public site: string = '';

  constructor() {
    super();

    makeObservable(this, {
      id: observable,
      path: observable,
      site: observable,
      update: action,
      changeId: action,
    });
  }

  public update(obj: SharepointModel) {
    this.updater.update(this, obj, SharepointModel);
    return this;
  }

  public changeId = (id: string) => (this.id = id);
}
