import * as React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as FontAwesome from 'react-fontawesome';
import { Collapse, Row } from 'reactstrap';
import * as classnames from 'classnames';

type Position = 'left' | 'right';

interface ISortingReportDetailSectionDefaultProps {
  isOpen?: boolean;
  hasFieldWithError?: boolean;
  errorTrianglePosition?: Position;
}

interface ISortingReportDetailSectionProps extends ISortingReportDetailSectionDefaultProps {
  children: React.ReactNode;
  title: string;
  getHeaderToolsComponent?: () => React.ReactNode;
}

class SortingReportDetailSection extends React.Component<ISortingReportDetailSectionProps> {
  public static defaultProps: ISortingReportDetailSectionDefaultProps = {
    isOpen: false,
    hasFieldWithError: false,
    errorTrianglePosition: 'right',
  };

  private _isOpen: boolean = this.props.isOpen;
  private headerRef = React.createRef<HTMLHeadingElement>();
  private toolsRef = React.createRef<HTMLDivElement>();

  constructor(props: ISortingReportDetailSectionProps) {
    super(props);

    makeObservable<SortingReportDetailSection, '_isOpen' | '_toggleIsOpen'>(this, {
      _isOpen: observable,
      _toggleIsOpen: action,
    });
  }

  public render() {
    const { children, title, hasFieldWithError, getHeaderToolsComponent, errorTrianglePosition } = this.props;

    return (
      <React.Fragment>
        <h5 className="section-header pointer" onClick={this._toggleIsOpen} ref={this.headerRef}>
          <span data-test="section-header">{title}</span>
          <FontAwesome className="margin-left-1rem" name={this._isOpen ? 'angle-up' : 'angle-down'} />
          {hasFieldWithError && (
            <span
              data-test="error-triangle"
              className={classnames([
                'color-red',
                errorTrianglePosition === 'right' ? 'float-end margin-left-0-5rem' : 'float-start margin-right-075rem',
              ])}
            >
              <FontAwesome name="exclamation-triangle" />
            </span>
          )}
          <div className="float-end" ref={this.toolsRef}>
            {!!getHeaderToolsComponent ? getHeaderToolsComponent() : null}
          </div>
        </h5>
        <Row className="bg-white pb-1 pt-1 border-top border-bottom">
          <Collapse isOpen={this._isOpen} className="full-width">
            {children}
          </Collapse>
        </Row>
      </React.Fragment>
    );
  }

  private _toggleIsOpen = (e: React.MouseEvent<HTMLHeadingElement>) => {
    // prevent toggling during click on tools element
    const toolsNode = this.toolsRef.current;
    if (!toolsNode || !toolsNode.contains(e.target as Node)) {
      this._isOpen = !this._isOpen;
    }
  };
}

export default observer(SortingReportDetailSection);
