import * as React from 'react';
import TranslateService from 'services/TranslateService';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import { logger } from 'util/logger';
import { instance as notification } from 'util/notification';

interface IProps {
  onChangeFunc: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  label?: string;
  buttonClassName?: string;
  translateService?: TranslateService;
}

class UploadFileButton extends React.Component<IProps> {
  private _fileInput: HTMLInputElement = null;

  public render() {
    const {
      buttonClassName,
      label,
      translateService: { t },
    } = this.props;

    const className = buttonClassName ? buttonClassName : 'btn btn-success btn-sm';

    return (
      <Button className={className} color="success">
        <label className="btn padding-none margin-none border-none line-height-1-4-rem">
          <FontAwesome name="upload" className="me-2" />
          <input
            type="file"
            multiple={false}
            ref={(r) => (this._fileInput = r)}
            onChange={this._onChange}
            accept=".csv"
            capture
            style={{ display: 'none' }}
          />
          {label || t.GLOBAL_LABEL_UPLOAD}
        </label>
      </Button>
    );
  }

  private _onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      onChangeFunc,
      translateService: { t },
    } = this.props;
    try {
      await onChangeFunc(e);
      this._fileInput.value = null;
    } catch (e) {
      this._fileInput.value = null;
      logger.error(e);
      notification.error(t.GLOBAL_FILE_SELECT_ERROR);
    }
  };
}

export default inject('translateService')(observer(UploadFileButton));
