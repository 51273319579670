import * as React from 'react';
import { observer } from 'mobx-react';
import { Label } from 'reactstrap';
import { LabelProps } from 'reactstrap/types/lib/Label';

interface IRequiredLabelProps extends LabelProps {
  required?: boolean;
}

const RequiredLabel: React.FC<IRequiredLabelProps> = ({ children, ...props }) => {
  const { required } = props;
  return (
    <Label {...props}>
      {children}
      { required && <span className="asterisk" data-test="asterisk">*</span> }
    </Label>
  );
};

export default observer(RequiredLabel);
