export enum UserRoles {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  WeightBridgeOperator = 'WeightBridgeOperator',
  Tier3 = 'Tier3',
  Tier2 = 'Tier2',
  Tier1 = 'Tier1',
  Manager = 'Manager',
  Lab = 'Lab',
  Trader = 'Trader',
  BackOffice = 'BackOffice',
  DataAnalyzer = 'DataAnalyzer',
}

export enum DeliveryStatus {
  IN_WORK = 'IN_WORK',
  READY_FOR_TIER_2 = 'READY_FOR_TIER_2',
  READY_FOR_SIGN_OFF = 'READY_FOR_SIGN_OFF',
  PARTIALLY_SIGNED_OFF = 'PARTIALLY_SIGNED_OFF',
  SIGNED_OFF = 'SIGNED_OFF',
  COMPLETE = 'COMPLETE',
}

export enum SocketServiceTopic {
  NEW_DELIVERY = 'NEW_DELIVERY',
  DELIVERY_UPDATED = 'DELIVERY_UPDATED',
  DELIVERY_WEIGHTS_UPDATED = 'DELIVERY_WEIGHTS_UPDATED',
  DELIVERY_IN_WORK = 'DELIVERY_IN_WORK',
  DELIVERY_READY_FOR_TIER_2 = 'DELIVERY_READY_FOR_TIER_2',
  DELIVERY_READY_FOR_TIER_1 = 'DELIVERY_READY_FOR_TIER_1',
  DELIVERY_SIGNED_OFF = 'DELIVERY_SIGNED_OFF',
  DELIVERY_COMPLETE = 'DELIVERY_COMPLETE',
  DELIVERY_ROLLBACK = 'DELIVERY_ROLLBACK',
  ADVISED_GOOD_CLAIMED = 'ADVISED_GOOD_CLAIMED',
  ADVISED_GOOD_READY_FOR_TIER_2 = 'ADVISED_GOOD_READY_FOR_TIER_2',
  ADVISED_GOOD_READY_FOR_TIER_1 = 'ADVISED_GOOD_READY_FOR_TIER_1',
  ADVISED_GOOD_HAS_LAB_ANALYSIS = 'ADVISED_GOOD_HAS_LAB_ANALYSIS',
  RECEIVED_GOOD_TRANSFER = 'RECEIVED_GOOD_TRANSFER',
  LAB_ANALYSIS_REQUESTED = 'LAB_ANALYSIS_REQUESTED',
  ADVISED_GOOD_UPDATED = 'ADVISED_GOOD_UPDATED',
  ADVISED_GOOD_WEIGHTS_UPDATED = 'ADVISED_GOOD_WEIGHTS_UPDATED',
  ADVISED_GOOD_GROSS_WEIGHT_UPDATED = 'ADVISED_GOOD_GROSS_WEIGHT_UPDATED',
  ADVISED_GOOD_TARE_WEIGHT_UPDATED = 'ADVISED_GOOD_TARE_WEIGHT_UPDATED',
  ADVISED_GOOD_EWC_CODE = 'ADVISED_GOOD_EWC_CODE',
  RECEIVED_GOOD_WEIGHTS_UPDATED = 'RECEIVED_GOOD_WEIGHTS_UPDATED',
}

export enum LabAnalysisStatus {
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SAMPLED = 'SAMPLED',
  IN_LAB = 'IN_LAB',
  DRYING = 'DRYING',
  MELTING = 'MELTING',
  MELTED = 'MELTED',
  COMPLETE = 'COMPLETE',
}

export enum ContaminationSection {
  nonMetallicAttach = 'nonMetallicAttach',
  metallicAttach = 'metallicAttach',
  differentMetals = 'differentMetals',
  turningsInSolids = 'turningsInSolids',
  notInFurnaceSize = 'notInFurnaceSize',
  materialForms = 'materialForms',
  tareInbound = 'tareInbound',
  tareOutbound = 'tareOutbound',
  turningsComposition = 'turningsComposition',
  packaging = 'packaging',
  sizes = 'sizes',
}

export enum AdvisedGoodStatus {
  WAITING = 'WAITING',
  CLAIMED = 'CLAIMED',
  UNLOADED = 'UNLOADED',
  IN_LAB = 'IN_LAB',
  READY_FOR_TIER_2 = 'READY_FOR_TIER_2',
  READY_FOR_SIGN_OFF = 'READY_FOR_SIGN_OFF',
  SIGNED_OFF = 'SIGNED_OFF',
  COMPLETE = 'COMPLETE',
}

export enum DeductionsList {
  FREE_WATER = 'FREE_WATER',
  FREE_OIL = 'FREE_OIL',
  FREE_EMULSION = 'FREE_EMULSION',
  FREE_SNOW_ICE = 'FREE_SNOW_ICE',
  NO_DEDUCTION = 'NO_DEDUCTION',
}

export enum SortingReportRequiredFields {
  machine = 'MACHINE',
  targetBatch = 'TARGET_BATCH',
  bulkDensity = 'BULK_DENSITY',
  foundQuality = 'FOUND_QUALITY',
  mainType = 'MAIN_TYPE',
  materialDescription = 'MATERIAL_DESCRIPTION',
}

export enum ContaminationSectionType {
  DIFFERENT_METALS = 'DIFFERENT_METALS',
  NON_METALLIC_ATTACH = 'NON_METALLIC_ATTACH',
  METALLIC_ATTACH = 'METALLIC_ATTACH',
  TURNINGS_IN_SOLIDS = 'TURNINGS_IN_SOLIDS',
  NOT_IN_FURNACE_SIZE = 'NOT_IN_FURNACE_SIZE',
  MATERIAL_FORMS = 'MATERIAL_FORMS',
  TARE_INBOUND = 'TARE_INBOUND',
  TARE_OUTBOUND = 'TARE_OUTBOUND',
  TURNINGS_COMPOSITION = 'TURNINGS_COMPOSITION',
  PACKAGING = 'PACKAGING',
  SIZES = 'SIZES',
}

export enum AttachmentType {
  DELIVERY_ATTACHMENT = 'DELIVERY_ATTACHMENT',
  DELIVERY_RADIATION = 'DELIVERY_RADIATION',
}

export enum DepartmentCode {
  STST = 'STST',
  HSS = 'HSS',
  EUASDSTI = 'EUASDSTI',
  EUASDSNI = 'EUASDSNI',
  EUATUR = 'EUATUR',
}

export enum DepartmentGroupCode {
  EUA_SOLIDS = 'EUASDSTI,EUASDSNI',
  EUA_TOTAL = 'EUASDSTI,EUASDSNI,EUATUR',
}

export enum Position {
  top = 'top',
  bottom = 'bottom',
}

// assets/examples
export enum ExampleFileNames {
  ORIGINS = 'origins-example.xlsx',
  ROAD_HAULIERS = 'road-hauliers-example.xlsx',
  STOCK_ITEMS = 'stock-items-example.xlsx',
  SUPPLIERS = 'suppliers-example.xlsx',
  YARD_LOCATIONS = 'yard-locations-example.xlsx',
  MACHINES = 'machines-example.xlsx',
  PACKAGING = 'packaging-example.xlsx',
  TARGET_BATCHES = 'target-batches-example.xlsx',
  EWCS = 'ewcs-example.xlsx',
}

export enum PublicPaths {
  ADMIN_EXAMPLE_FILES = '/assets/examples/',
}

export enum AdminRootPath {
  EWCS = 'ewcs',
  MACHINES = 'machines',
  MATERIALS = 'materials',
  ORIGINS = 'origins',
  PACKAGING = 'packaging',
  PRINTERS = 'printers',
  ROAD_HAULIERS = 'road-hauliers',
  SCALES = 'scales',
  STOCK_ITEMS = 'stock-items',
  SUPPLIERS = 'suppliers',
  TARGET_BATCHES = 'target-batches',
  USERS = 'user-management',
  YARD_LOCATIONS = 'yard-locations',
  LASERNET_PRINTERS = 'lasernet-printers',
  LASERNET_PRINTER_CONFIGURATIONS = 'lasernet-configurations',
}

export enum AdminRootUrl {
  EWC = '/admin/ewcs',
  MACHINE = '/admin/machines',
  MATERIAL = '/admin/materials',
  ORIGIN = '/admin/origins',
  PACKAGE = '/admin/packaging',
  PRINTER = '/admin/printers',
  ROAD_HAULIER = '/admin/road-hauliers',
  SCALE = '/admin/scales',
  STOCK_ITEM = '/admin/stock-items',
  SUPPLIER = '/admin/suppliers',
  TARGET_BATCH = '/admin/target-batches',
  USER = '/admin/user-management',
  YARD_LOCATION = '/admin/yard-locations',
  LASERNET_PRINTERS = '/admin/lasernet-printers',
  LASERNET_DOCUMENT_TYPES = '/admin/lasernet-document-types',
  LASERNET_PRINTER_CONFIGURATIONS = '/admin/lasernet-configurations',
}

export enum Urls {
  DELIVERIES = '/deliveries',
}

export type TDepartmentCodes = DepartmentCode | DepartmentGroupCode;

export enum CountryCode {
  INIT = '',
  UK = 'UK',
  US = 'US',
  DE = 'DE',
  DE_D365 = 'DE_D365',
  IT = 'IT',
  FR = 'FR',
}

export enum ReactNativeActionType {
  SHOW_CAMERA = 'showCamera',
  SHOW_QR = 'showQr',
  IS_LOGGED_IN = 'isLoggedIn',
  LOGOUT = 'logout',
  SHOW_PDF_READER = 'showPDFReader',
}

export enum LasernetDocumentTypes {
  YARD_QR_CODE_LABEL = 'Yard QR Code Label',
  LAB_QR_CODE_LABEL = 'Lab QR Code Label',
}

export enum AdvisedGoodWeighingTypes {
  WEIGHBRIDGE = 'WEIGHBRIDGE',
  SCALE = 'SCALE',
}
