import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import * as classnames from 'classnames';

import NavlinkCustom from 'domain/NavlinkCustom';
import ScaleModel from 'models/ScaleModel';
import TranslateService from 'services/TranslateService';
import AdminStore from 'pod/admin/AdminStore';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import ScaleDetailComponent from 'pod/admin/scales/ScaleDetailComponent';
import ScaleDetailRoute from 'pod/admin/scales/ScaleDetailRoute';
import { AdminRootPath } from 'util/enums';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class ScaleListRoute extends React.Component<IProps> {
  public content: ScaleModel = new ScaleModel();

  public clearModel = () => {
    this.content = new ScaleModel();
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      clearModel: action,
    });
  }

  public get scaleList() {
    const {
      translateService: { t },
      adminStore: { scaleListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="ps-2">{t.ADMIN_SCALELIST_NAME}</th>
            <th>{t.GLOBAL_LABEL_IP}</th>
            <th className="pe-2">{t.GLOBAL_LABEL_MODEL}</th>
          </tr>
        </thead>
        {scaleListActiveFirst.map((scale: ScaleModel) => this._renderItem(scale))}
      </table>
    );
  }

  public get scaleForm() {
    return <ScaleDetailComponent content={this.content} />;
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { scaleApi },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={scaleApi}
        rootPath={AdminRootPath.SCALES}
        itemsTable={this.scaleList}
        itemCreateForm={this.scaleForm}
        componentRoute={ScaleDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_SCALE}
        hasUploadBtn={false}
        header={t.MENU_ADMIN_SCALES}
      />
    );
  }

  private _renderItem = (scale: ScaleModel) => (
    <tbody key={scale.id}>
      <NavlinkCustom
        to={`/admin/scales/${scale.id}`}
        tagName="tr"
        className={classnames(['pointer', { 'bg-secondary': !scale.active }])}
      >
        <td className="ps-3">{scale.name}</td>
        <td>{scale.ip}</td>
        <td className="pe-3">{scale.port}</td>
      </NavlinkCustom>
    </tbody>
  );
}

export default inject('adminStore', 'translateService')(observer(ScaleListRoute));
