import * as React from 'react';
import * as classnames from 'classnames';

interface IProps {
  show: boolean;
}

export default class StickyFooter extends React.PureComponent<IProps> {
  public render() {
    const { show } = this.props;

    return (
      <div className={classnames(['fixed-bottom', 'sticky-footer-component', { isVisible: show }])}>
        {this.props.children}
      </div>
    );
  }
}
