import * as React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import TranslateService from 'services/TranslateService';
import ViewStore from 'stores/ViewStore';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import RoadHaulierModel from 'models/RoadHaulierModel';

interface IRoadHaulierDetailComponentProps {
  content: RoadHaulierModel;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

const focused = observable({
  expirationDate: false,
});
const changeExpirationDateInputFocus = action((args: { focused: boolean }): void => {
  focused.expirationDate = args.focused;
});

const RoadHaulierDetailComponent: React.FunctionComponent<IRoadHaulierDetailComponentProps> = ({
  content,
  viewStore: { isIT },
  translateService: { t },
}) => {
  const changeAuthorizationNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    content.changeAuthorizationNumber(e.target.value);
  };

  return (
    content &&
    isIT && (
      <React.Fragment>
        <FormGroup row data-test="expiration-date-input" key="authorizationNumber">
          <Label for="authorizationNumber" sm={4}>
            {t.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER}
          </Label>
          <Col sm={8}>
            <Input
              id="authorizationNumber"
              value={content.authorizationNumber}
              name="authorizationNumber"
              type="text"
              onChange={changeAuthorizationNumber}
              data-test="authorization-number-input"
            />
          </Col>
        </FormGroup>
        <FormGroup row data-test="expiration-date-input" key="expirationDate">
          <Label for="expirationDate" sm={4}>
            {t.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE}
          </Label>
          <Col sm={8}>
            <SingleDatePicker
              id="expirationDate"
              date={content.expirationDate}
              onDateChange={content.changeExpirationDate}
              focused={focused.expirationDate}
              onFocusChange={changeExpirationDateInputFocus}
              showClearDate={true}
              numberOfMonths={1}
              isOutsideRange={() => false}
              small
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    )
  );
};

export default inject('viewStore', 'translateService')(observer(RoadHaulierDetailComponent));
