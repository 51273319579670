import { inject, observer } from 'mobx-react';
import * as React from 'react';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';
import DeliveryConfigurationSection from 'pod/superAdmin/tenants/DeliveryConfigurationSection';
import AGConfigurationSection from 'pod/superAdmin/tenants/AGConfigurationSection';

interface IConfigurationSectionProps {
  tenant: TenantModel;
  translateService?: TranslateService;
}

const ConfigurationSection: React.FC<IConfigurationSectionProps> = (props) => {
  const { tenant, translateService: { t } } = props;
  return <div>
    <h5>{t.SUPERADMIN_TENANTSECTIONS_DELIVERY_CONFIGURATION_SECTION}</h5>
    <DeliveryConfigurationSection tenant={tenant}/>
    <br/>
    <h5>{ t.SUPERADMIN_TENANTSECTIONS_AG_CONFIGURATION_SECTION }</h5>
    <AGConfigurationSection tenant={tenant}/>
    <br/>
  </div>
}

export default inject('translateService')(observer(ConfigurationSection));
