import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import * as classnames from 'classnames';

import NavlinkCustom from 'domain/NavlinkCustom';
import UserDetailRoute from 'pod/admin/users/UserDetailRoute';
import AdminStore from 'pod/admin/AdminStore';
import UserModel from 'models/UserModel';
import UserDetailComponent from 'pod/admin/users/UserDetailComponent';
import TranslateService from 'services/TranslateService';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import { AdminRootPath } from 'util/enums';
import UserRoleModel from 'models/UserRoleModel';
import { getTranslation, joinWithCommaSeparator } from 'util/helpers';
import { I18N } from '../../../../assets/i18n/i18n';
import EmployeeStore from 'stores/EmployeeStore';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
  employeeStore?: EmployeeStore;
}

interface IState {
  showAddUserModal: boolean;
}

class UserListRoute extends React.Component<IProps, IState> {
  public content: UserModel<IUserModelConstructObj> = new UserModel<IUserModelConstructObj>();

  public clearModel = () => {
    this.content = new UserModel<IUserModelConstructObj>();
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      clearModel: action,
    });
  }

  public componentDidMount(): void {
    this.props.adminStore.getUserRoles();
    this.props.adminStore.getTenants();
    this.props.employeeStore.getEmployeeItems();
  }

  public get userList() {
    const {
      translateService: { t },
      adminStore: { userListActiveFirst },
    } = this.props;

    return (
      <table className="custom-table mb-5">
        <thead>
          <tr className="table-heading">
            <th className="width-33p ps-3 font-medium">{t.GLOBAL_LABEL_NAME}</th>
            <th className="width-33p ps-3">{t.GLOBAL_LABEL_ROLE}</th>
            <th className="width-33p">{t.GLOBAL_LABEL_USERNAME}</th>
            <th className="width-33p pe-3">{t.GLOBAL_LABEL_EMAIL}</th>
          </tr>
        </thead>
        {userListActiveFirst.map((user: UserModel<IUserModelConstructObj>) => this._renderItem(user))}
      </table>
    );
  }

  public get userForm() {
    const { adminStore } = this.props;
    return (
      <UserDetailComponent content={this.content} userRoles={adminStore.userRoleList} tenants={adminStore.tenantList} />
    );
  }

  public render() {
    const {
      translateService: { t },
      adminStore: { userApi },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={userApi}
        rootPath={AdminRootPath.USERS}
        itemsTable={this.userList}
        itemCreateForm={this.userForm}
        componentRoute={UserDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_USER}
        hasUploadBtn={false}
        header={t.MENU_ADMIN_USER_MANAGEMENT}
        isCodeRequired={false}
      />
    );
  }

  private _renderItem = (user: UserModel<IUserModelConstructObj>) => {
    const {
      translateService: { t },
    } = this.props;

    return (
      <tbody key={user.id}>
        <NavlinkCustom
          to={`/admin/user-management/${user.id}`}
          tagName="tr"
          className={classnames(['pointer', { 'bg-secondary': !user.active }])}
        >
          <td className="ps-3">{user.fullName}</td>
          <td className="ps-3 pe-3" data-test="role-names">
            {joinWithCommaSeparator(
              user.roles.map(
                (role: UserRoleModel) =>
                  getTranslation(`GLOBAL_USERROLE_${role.name.toUpperCase()}` as keyof I18N, t) as string
              )
            )}
          </td>
          <td>{user.username}</td>
          <td className="pe-3">{user.email}</td>
        </NavlinkCustom>
      </tbody>
    );
  };
}

export default inject('adminStore', 'translateService', 'employeeStore')(observer(UserListRoute));
