import { I18N } from './i18n';

// ignore warning as we are defining it lazily
const lang: I18N = {} as I18N;

lang.GLOBAL_LABEL_YES = 'GLOBAL_LABEL_YES';
lang.GLOBAL_LABEL_YES_SMALL = 'GLOBAL_LABEL_YES_SMALL';
lang.GLOBAL_LABEL_NO = 'GLOBAL_LABEL_NO';
lang.GLOBAL_LABEL_NO_SMALL = 'GLOBAL_LABEL_NO_SMALL';
lang.GLOBAL_LABEL_SUBMIT = 'GLOBAL_LABEL_SUBMIT';
lang.GLOBAL_LABEL_RESULT = 'GLOBAL_LABEL_RESULT';
lang.GLOBAL_LABEL_ERROR = 'GLOBAL_LABEL_ERROR';
lang.GLOBAL_LABEL_REFRESH = 'GLOBAL_LABEL_REFRESH';
lang.GLOBAL_LABEL_ADD_NEW = 'GLOBAL_LABEL_ADD_NEW';
lang.GLOBAL_LABEL_NAME = 'GLOBAL_LABEL_NAME';
lang.GLOBAL_LABEL_CODE = 'GLOBAL_LABEL_CODE';
lang.GLOBAL_LABEL_UNIQUE_ID = 'GLOBAL_LABEL_UNIQUE_ID';
lang.GLOBAL_LABEL_LOCATION = 'GLOBAL_LABEL_LOCATION';
lang.GLOBAL_LABEL_ONLINE = 'GLOBAL_LABEL_ONLINE';
lang.GLOBAL_LABEL_IS_DEFAULT = 'GLOBAL_LABEL_IS_DEFAULT';
lang.GLOBAL_LABEL_MODEL = 'GLOBAL_LABEL_MODEL';
lang.GLOBAL_LABEL_IP = 'GLOBAL_LABEL_IP';
lang.GLOBAL_LABEL_SAVE = 'GLOBAL_LABEL_SAVE';
lang.GLOBAL_LABEL_DELETE = 'GLOBAL_LABEL_DELETE';
lang.GLOBAL_LABEL_CANCEL = 'GLOBAL_LABEL_CANCEL';
lang.GLOBAL_LABEL_UPLOAD = 'GLOBAL_LABEL_UPLOAD';
lang.GLOBAL_LABEL_FILE = 'GLOBAL_LABEL_FILE';
lang.GLOBAL_LABEL_PORT = 'GLOBAL_LABEL_PORT';
lang.GLOBAL_LABEL_FIRST_NAME = 'GLOBAL_LABEL_FIRST_NAME';
lang.GLOBAL_LABEL_LAST_NAME = 'GLOBAL_LABEL_LAST_NAME';
lang.GLOBAL_LABEL_USERNAME = 'GLOBAL_LABEL_USERNAME';
lang.GLOBAL_LABEL_PASSWORD = 'GLOBAL_LABEL_PASSWORD';
lang.GLOBAL_LABEL_EMAIL = 'GLOBAL_LABEL_EMAIL';
lang.GLOBAL_LABEL_ROLE = 'GLOBAL_LABEL_ROLE';
lang.GLOBAL_LABEL_STATUS = 'GLOBAL_LABEL_STATUS';
lang.GLOBAL_LABEL_REMARKS = 'GLOBAL_LABEL_REMARKS';
lang.GLOBAL_LABEL_SELECT = 'GLOBAL_LABEL_SELECT';
lang.GLOBAL_LABEL_GRN = 'GLOBAL_LABEL_GRN';
lang.GLOBAL_LABEL_IN_PROGRESS = 'GLOBAL_LABEL_IN_PROGRESS';
lang.GLOBAL_LABEL_COMPLETED = 'GLOBAL_LABEL_COMPLETED';
lang.GLOBAL_LABEL_REGISTER = 'GLOBAL_LABEL_REGISTER';
lang.GLOBAL_LABEL_OPERATION = 'GLOBAL_LABEL_OPERATION';
lang.GLOBAL_LABEL_SUPPLIER = 'GLOBAL_LABEL_SUPPLIER';
lang.GLOBAL_LABEL_SUPPLIER_CITY = 'GLOBAL_LABEL_SUPPLIER_CITY';
lang.GLOBAL_LABEL_DRIVER = 'GLOBAL_LABEL_DRIVER';
lang.GLOBAL_LABEL_LICENCE_PLACE = 'GLOBAL_LABEL_LICENCE_PLACE';
lang.GLOBAL_LABEL_TO_CLAIM = 'GLOBAL_LABEL_TO_CLAIM';
lang.GLOBAL_LABEL_CREATED = 'GLOBAL_LABEL_CREATED';
lang.GLOBAL_LABEL_ACTIONS = 'GLOBAL_LABEL_ACTIONS';
lang.GLOBAL_LABEL_CLAIM = 'GLOBAL_LABEL_CLAIM';
lang.GLOBAL_LABEL_DESCRIPTION = 'GLOBAL_LABEL_DESCRIPTION';
lang.GLOBAL_LABEL_ADD = 'GLOBAL_LABEL_ADD';
lang.GLOBAL_LABEL_NOT_CLAIMED = 'GLOBAL_LABEL_NOT_CLAIMED';
lang.GLOBAL_LABEL_DELIVERIES = 'GLOBAL_LABEL_DELIVERIES';
lang.GLOBAL_LABEL_SAVE_ATTACHMENTS = 'GLOBAL_LABEL_SAVE_ATTACHMENTS';
lang.GLOBAL_LABEL_UPLOAD_ATTACHMENTS = 'GLOBAL_LABEL_UPLOAD_ATTACHMENTS';
lang.GLOBAL_LABEL_ATTACHMENTS = 'GLOBAL_LABEL_ATTACHMENTS';
lang.GLOBAL_LABEL_ATTACHMENT = 'GLOBAL_LABEL_ATTACHMENT';
lang.GLOBAL_LABEL_ADVISED_GOODS = 'GLOBAL_LABEL_ADVISED_GOODS';
lang.GLOBAL_LABEL_SYNC = 'GLOBAL_LABEL_SYNC';
lang.GLOBAL_LABEL_CLOSE = 'GLOBAL_LABEL_CLOSE';
lang.GLOBAL_LABEL_VERIFY = 'GLOBAL_LABEL_VERIFY';
lang.GLOBAL_LABEL_ALERT = 'GLOBAL_LABEL_ALERT';
lang.GLOBAL_LABEL_ALERT_RECEIVED_GOOD = 'GLOBAL_LABEL_ALERT_RECEIVED_GOOD';
lang.GLOBAL_LABEL_CLAIM_BY = 'GLOBAL_LABEL_CLAIM_BY';
lang.GLOBAL_LABEL_CLASSIFIED_BY = 'GLOBAL_LABEL_CLASSIFIED_BY';
lang.GLOBAL_LABEL_VERIFIED_BY = 'GLOBAL_LABEL_VERIFIED_BY';
lang.GLOBAL_LABEL_ROLLEDBACK_BY = 'GLOBAL_LABEL_ROLLEDBACK_BY';
lang.GLOBAL_LABEL_FLAGGED_BY = 'GLOBAL_LABEL_FLAGGED_BY';
lang.GLOBAL_LABEL_UNFLAGGED_BY = 'GLOBAL_LABEL_UNFLAGGED_BY';
lang.GLOBAL_LABEL_TRANSFERS = 'GLOBAL_LABEL_TRANSFERS';
lang.GLOBAL_LABEL_TRANSFERED = 'GLOBAL_LABEL_TRANSFERED';
lang.GLOBAL_LABEL_WEIGHT = 'GLOBAL_LABEL_WEIGHT';
lang.GLOBAL_LABEL_CREATE = 'GLOBAL_LABEL_CREATE';
lang.GLOBAL_LABEL_CHARACTERS_REMAINING = (number) => `GLOBAL_LABEL_CHARACTERS_REMAINING`;
lang.GLOBAL_LABEL_HIDE = 'GLOBAL_LABEL_HIDE';
lang.GLOBAL_LABEL_UNHIDE = 'GLOBAL_LABEL_UNHIDE';
lang.GLOBAL_LABEL_SHOW = 'GLOBAL_LABEL_SHOW';
lang.GLOBAL_LABEL_LANGUAGE = (language) => `GLOBAL_LABEL_LANGUAGE`;
lang.GLOBAL_LABEL_FILE_DOWNLOADED_MSG = 'GLOBAL_LABEL_FILE_DOWNLOADED_MSG';
lang.GLOBAL_LABEL_DECIMALS = 'GLOBAL_LABEL_DECIMALS';
lang.GLOBAL_LABEL_GO_BACK = 'GLOBAL_LABEL_GO_BACK';
lang.GLOBAL_LABEL_VERSION = (version) => `GLOBAL_LABEL_VERSION`;
lang.GLOBAL_LABEL_CONFIRM = 'GLOBAL_LABEL_CONFIRM';
lang.GLOBAL_LABEL_FIELD = 'GLOBAL_LABEL_FIELD';
lang.GLOBAL_LABEL_FIELDS = 'GLOBAL_LABEL_FIELDS';
lang.GLOBAL_LABEL_NONE = 'GLOBAL_LABEL_NONE';
lang.GLOBAL_LABEL_ALL_TENANTS = 'GLOBAL_LABEL_ALL_TENANTS';
lang.GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR = 'GLOBAL_LABEL_FIELD_NAME_NOT_FIND_ERROR';
lang.GLOBAL_LABEL_UNCLAIM = 'GLOBAL_LABEL_UNCLAIM';
lang.GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE = 'GLOBAL_LABEL_CERTIFICATE_EXPIRATION_DATE';
lang.GLOBAL_LABEL_CERT_AUT_RIF_DATE = 'GLOBAL_LABEL_CERT_AUT_RIF_DATE';
lang.GLOBAL_LABEL_CERT_715_13 = 'GLOBAL_LABEL_CERT_715_13';
lang.GLOBAL_LABEL_TYPE = 'GLOBAL_LABEL_TYPE';
lang.GLOBAL_LABEL_REVISION_REQUEST = 'GLOBAL_LABEL_REVISION_REQUEST';
lang.GLOBAL_LABEL_EXPIRED = 'GLOBAL_LABEL_EXPIRED';
lang.GLOBAL_LABEL_NO_CERTIFICATE = 'GLOBAL_LABEL_NO_CERTIFICATE';
lang.GLOBAL_LABEL_RESPONSIBLE = 'GLOBAL_LABEL_RESPONSIBLE';
lang.GLOBAL_LABEL_DEPARTMENT = 'GLOBAL_LABEL_DEPARTMENT';
lang.GLOBAL_LABEL_EXPORT = 'GLOBAL_LABEL_EXPORT';
lang.GLOBAL_LABEL_REEXPORT = 'GLOBAL_LABEL_REEXPORT';
lang.GLOBAL_LABEL_FLAGGED = 'GLOBAL_LABEL_FLAGGED';
lang.GLOBAL_LABEL_EXPORTED = 'GLOBAL_LABEL_EXPORTED';
lang.GLOBAL_LABEL_SYNCED = 'GLOBAL_LABEL_SYNCED';
lang.GLOBAL_LABEL_PRINT = 'GLOBAL_LABEL_PRINT';
lang.GLOBAL_LABEL_FLAG = 'GLOBAL_LABEL_FLAG';
lang.GLOBAL_LABEL_UN_FLAG = 'GLOBAL_LABEL_UN_FLAG';
lang.GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER = 'GLOBAL_LABEL_NO_AUTHORIZATION_NUMBER';
lang.GLOBAL_LABEL_AUTHORIZATION_EXPIRED = 'GLOBAL_LABEL_AUTHORIZATION_EXPIRED';
lang.GLOBAL_LABEL_NO_EXPIRATION_DATE = 'GLOBAL_LABEL_NO_EXPIRATION_DATE';
lang.GLOBAL_LABEL_AUTH_NUMBER = 'GLOBAL_LABEL_AUTH_NUMBER';
lang.GLOBAL_LABEL_EXP_DATE = 'GLOBAL_LABEL_EXP_DATE';
lang.GLOBAL_LABEL_IS_NOT_VALID = (name) => `GLOBAL_LABEL_IS_NOT_VALID`;
lang.GLOBAL_LABEL_INVALID_FIELDS = (name) => `GLOBAL_LABEL_INVALID_FIELDS`;
lang.GLOBAL_LABEL_USER_ROLES = 'GLOBAL_LABEL_USER_ROLES';
lang.GLOBAL_LABEL_DEFAULT_ROLE = 'GLOBAL_LABEL_DEFAULT_ROLE';
lang.GLOBAL_LABEL_DEFAULT_TENANT = 'GLOBAL_LABEL_DEFAULT_TENANT';
lang.GLOBAL_LABEL_NEW_VERSION_RELEASED = 'GLOBAL_LABEL_NEW_VERSION_RELEASED';
lang.GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION = 'GLOBAL_LABEL_REFRESH_PAGE_CONFIRMATION';
lang.GLOBAL_LABEL_TENANTS_AVAILABLE = 'GLOBAL_LABEL_TENANTS_AVAILABLE';
lang.GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE = (roleName) => `GLOBAL_LABEL_AVAILABLE_TENANTS_FOR_ROLE`;
lang.GLOBAL_LABEL_IMPORT = 'GLOBAL_LABEL_IMPORT';
lang.GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE = (date) => `GLOBAL_LABEL_PASSWORD_EXPIRATION_MESSAGE`;
lang.GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE = 'GLOBAL_LABEL_PASSWORD_EXPIRED_MESSAGE';
lang.GLOBAL_LABEL_PROCESSING = 'GLOBAL_LABEL_PROCESSING';
lang.GLOBAL_LABEL_PRICE = 'GLOBAL_LABEL_PRICE';
lang.GLOBAL_LABEL_UNIT = 'GLOBAL_LABEL_UNIT';
lang.GLOBAL_LABEL_BREAKDOWN = 'GLOBAL_LABEL_BREAKDOWN';
lang.GLOBAL_LABEL_PASSWORD_EXP_DATE = 'GLOBAL_LABEL_PASSWORD_EXP_DATE';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA = 'GLOBAL_LABEL_SUPPLIER_TYPE_ITALIA';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO = 'GLOBAL_LABEL_SUPPLIER_TYPE_ESTERO';
lang.GLOBAL_LABEL_SUPPLIER_TYPE_DITTA = 'GLOBAL_LABEL_SUPPLIER_TYPE_DITTA';
lang.GLOBAL_LABEL_SUPPLIERS_DETAILS = 'GLOBAL_LABEL_SUPPLIERS_DETAILS';
lang.GLOBAL_LABEL_FORM = 'GLOBAL_LABEL_FORM';
lang.GLOBAL_LABEL_QUALITY = 'GLOBAL_LABEL_QUALITY';
lang.GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS = 'GLOBAL_LABEL_REFRESH_PRODUCT_VARIANTS';
lang.GLOBAL_LABEL_WEIGHT_UNITS = 'GLOBAL_LABEL_WEIGHT_UNITS';
lang.GLOBAL_LABEL_EMPLOYEE_ID = 'GLOBAL_LABEL_EMPLOYEE_ID';
lang.GLOBAL_LABEL_LEGAL_ENTITY = 'GLOBAL_LABEL_LEGAL_ENTITY';
lang.GLOBAL_LABEL_PRODUCT_VARIANTS = 'GLOBAL_LABEL_PRODUCT_VARIANTS';
lang.GLOBAL_LABEL_REFRESH_COMMON = (name) => `GLOBAL_LABEL_REFRESH_COMMON`;
lang.GLOBAL_LABEL_TOTAL = 'GLOBAL_LABEL_TOTAL';
lang.GLOBAL_LABEL_SHAREPOINT = 'GLOBAL_LABEL_SHAREPOINT';
lang.GLOBAL_LABEL_PARTIAL_SIGN_OFF = 'GLOBAL_LABEL_PARTIAL_SIGN_OFF';
lang.GLOBAL_LABEL_TITLE = 'GLOBAL_LABEL_TITLE';
lang.GLOBAL_LABEL_CLIENT_ID = 'GLOBAL_LABEL_CLIENT_ID';
lang.GLOBAL_LABEL_CLIENT_SECRET = 'GLOBAL_LABEL_CLIENT_SECRET';
lang.GLOBAL_LABEL_CONNECTION_ADDRESS = 'GLOBAL_LABEL_CONNECTION_ADDRESS';
lang.GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT = 'GLOBAL_LABEL_ENABLE_BALANCE_DEDUCTION_BY_DEFAULT';
lang.GLOBAL_LABEL_DETAILED_WEIGHING = 'GLOBAL_LABEL_DETAILED_WEIGHING';
lang.GLOBAL_LABEL_UPDATE = 'GLOBAL_LABEL_UPDATE';
lang.GLOBAL_FILTERKEY_DATE_ASC = 'GLOBAL_FILTERKEY_DATE_ASC';
lang.GLOBAL_FILTERKEY_DATE_DESC = 'GLOBAL_FILTERKEY_DATE_DESC';
lang.GLOBAL_FILTERKEY_STATUS_ASC = 'GLOBAL_FILTERKEY_STATUS_ASC';
lang.GLOBAL_FILTERKEY_STATUS_DESC = 'GLOBAL_FILTERKEY_STATUS_DESC';
lang.GLOBAL_FILTERKEY_ALL = 'GLOBAL_FILTERKEY_ALL';
lang.GLOBAL_FILTERKEY_ONLY_MY = 'GLOBAL_FILTERKEY_ONLY_MY';
lang.GLOBAL_FILTERKEY_ONLY_UNCLAIMED = 'GLOBAL_FILTERKEY_ONLY_UNCLAIMED';
lang.GLOBAL_FILTERKEY_ONLY_UNSYNCED = 'GLOBAL_FILTERKEY_ONLY_UNSYNCED';
lang.GLOBAL_FILTERKEY_IN_PROGRESS = 'GLOBAL_FILTERKEY_IN_PROGRESS';
lang.GLOBAL_FILTERKEY_IN_LAB = 'GLOBAL_FILTERKEY_IN_LAB';
lang.GLOBAL_FILTERKEY_READY_FOR_TIER_2 = 'GLOBAL_FILTERKEY_READY_FOR_TIER_2';
lang.GLOBAL_FILTERKEY_READY_FOR_SIGNOFF = 'GLOBAL_FILTERKEY_READY_FOR_SIGNOFF';
lang.GLOBAL_FILTERKEY_SIGNED_OFF = 'GLOBAL_FILTERKEY_SIGNED_OFF';
lang.GLOBAL_FILTERKEY_COMPLETE = 'GLOBAL_FILTERKEY_COMPLETE';
lang.GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF = 'GLOBAL_FILTERKEY_PARTIALLY_SIGNED_OFF';
lang.GLOBAL_RADIOBUTTON_DRY = 'GLOBAL_RADIOBUTTON_DRY';
lang.GLOBAL_RADIOBUTTON_RAIN = 'GLOBAL_RADIOBUTTON_RAIN';
lang.GLOBAL_RADIOBUTTON_SNOW = 'GLOBAL_RADIOBUTTON_SNOW';
lang.GLOBAL_RADIOBUTTON_DUMP = 'GLOBAL_RADIOBUTTON_DUMP';
lang.GLOBAL_RADIOBUTTON_VAN = 'GLOBAL_RADIOBUTTON_VAN';
lang.GLOBAL_RADIOBUTTON_FLATBED = 'GLOBAL_RADIOBUTTON_FLATBED';
lang.GLOBAL_RADIOBUTTON_ROLL_OFF_BOX = 'GLOBAL_RADIOBUTTON_ROLL_OFF_BOX';
lang.GLOBAL_RADIOBUTTON_RAILCAR = 'GLOBAL_RADIOBUTTON_RAILCAR';
lang.GLOBAL_RADIOBUTTON_OTHER = 'GLOBAL_RADIOBUTTON_OTHER';
lang.GLOBAL_USERROLE_SUPERADMIN = 'GLOBAL_USERROLE_SUPERADMIN';
lang.GLOBAL_USERROLE_ADMIN = 'GLOBAL_USERROLE_ADMIN';
lang.GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR = 'GLOBAL_USERROLE_WEIGHTBRIDGEOPERATOR';
lang.GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE = 'GLOBAL_USERROLE_EXTERNALWEIGHTBRIDGE';
lang.GLOBAL_USERROLE_TIER3 = 'GLOBAL_USERROLE_TIER3';
lang.GLOBAL_USERROLE_TIER2 = 'GLOBAL_USERROLE_TIER2';
lang.GLOBAL_USERROLE_TIER1 = 'GLOBAL_USERROLE_TIER1';
lang.GLOBAL_USERROLE_MANAGER = 'GLOBAL_USERROLE_MANAGER';
lang.GLOBAL_USERROLE_LAB = 'GLOBAL_USERROLE_LAB';
lang.GLOBAL_USERROLE_TRADER = 'GLOBAL_USERROLE_TRADER';
lang.GLOBAL_USERROLE_TRADER_MOBILE = 'GLOBAL_USERROLE_TRADER_MOBILE';
lang.GLOBAL_USERROLE_BACKOFFICE = 'GLOBAL_USERROLE_BACKOFFICE';
lang.GLOBAL_USERROLE_DATAANALYZER = 'GLOBAL_USERROLE_DATAANALYZER';
lang.GLOBAL_LOGIN_HEADER = 'GLOBAL_LOGIN_HEADER';
lang.GLOBAL_LOGIN_TITLE = 'GLOBAL_LOGIN_TITLE';
lang.GLOBAL_LOGOUT_SUCCESS_MSG = 'GLOBAL_LOGOUT_SUCCESS_MSG';
lang.GLOBAL_DATERANGEPICKER_START_DATE_PLH = 'GLOBAL_DATERANGEPICKER_START_DATE_PLH';
lang.GLOBAL_DATERANGEPICKER_END_DATE_PLH = 'GLOBAL_DATERANGEPICKER_END_DATE_PLH';
lang.GLOBAL_RECOVERPASSWORD_HEADER = 'GLOBAL_RECOVERPASSWORD_HEADER';
lang.GLOBAL_RECOVERPASSWORD_UPDATE_MSG = 'GLOBAL_RECOVERPASSWORD_UPDATE_MSG';
lang.GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN = 'GLOBAL_RECOVERPASSWORD_SET_PASSWORD_BTN';
lang.GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD = 'GLOBAL_RECOVERPASSWORD_CURRENT_PASSWORD';
lang.GLOBAL_RECOVERPASSWORD_NEW_PASSWORD = 'GLOBAL_RECOVERPASSWORD_NEW_PASSWORD';
lang.GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD = 'GLOBAL_RECOVERPASSWORD_REENTER_PASSWORD';
lang.GLOBAL_FILE_SELECT_ERROR = 'GLOBAL_FILE_SELECT_ERROR';
lang.GLOBAL_SERVERERROR_DEFAULT = 'GLOBAL_SERVERERROR_DEFAULT';
lang.GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR = 'GLOBAL_SERVERERROR_EXTERNAL_SERVICE_ERROR';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD = 'GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_ADVISED_GOOD';
lang.GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD = 'GLOBAL_SERVERERROR_T2_CANNOT_UNFLAG_RECEIVED_GOOD';
lang.GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK = 'GLOBAL_SERVERERROR_T2_CANNOT_ROLLBACK';
lang.GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD = 'GLOBAL_SERVERERROR_TRADER_CANNOT_UNFLAG_ADVISED_GOOD';
lang.GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS = 'GLOBAL_SERVERERROR_ADMIN_CANNOT_ROLLBACK_FROM_CURRENT_AG_STATUS';
lang.GLOBAL_SERVERERROR_T1_CANNOT_CLAIM = 'GLOBAL_SERVERERROR_T1_CANNOT_CLAIM';
lang.GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD = 'GLOBAL_SERVERERROR_T1_CANNOT_UNLOAD';
lang.GLOBAL_SERVERERROR_T1_CANNOT_VERIFY = 'GLOBAL_SERVERERROR_T1_CANNOT_VERIFY';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_DELIVERY_NOT_SYNCED';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_ADVISED_GOOD_NOT_UNLOADED = 'GLOBAL_SERVERERROR_CANNOT_COMPLETE_ADVISED_GOOD_NOT_UNLOADED';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_FLAGGED_WHEN_SIGN_OFF';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_READY_FOR_SIGN_OFF';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NOT_ALL_ITEMS_STOCK_CODED';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_IF_VERIFIED_IT';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_NO_TARE_WEIGHT';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT = 'GLOBAL_SERVERERROR_ADVISED_GOOD_LEVEL_SIGN_OFF_DISABLED_ON_THE_TENANT';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF = 'GLOBAL_SERVERERROR_CANNOT_UPDATE_ADVISED_GOOD_WHEN_DELIVERY_SIGNED_OFF';
lang.GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED = 'GLOBAL_SERVERERROR_COMMENT_CANNOT_BE_UPDATED_IF_AG_IS_UNLOADED';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_AG_TARE_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_DELIVERY_TARE_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_STOCK_CODE_MISSING';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_ITEMS_IN_LAB';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY = (fullname) => `GLOBAL_SERVERERROR_ADVISED_GOOD_ALREADY_CLAIMED_BY`;
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF = 'GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_DELIVERY_SIGNED_OFF';
lang.GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF = 'GLOBAL_SERVERERROR_CANNOT_ROLLBACK_ADVISED_GOOD_SIGNED_OFF';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST = 'GLOBAL_SERVERERROR_CANNOT_DELETE_DELIVERY_LAB_SAMPLES_EXIST';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_LAB_SAMPLES_EXIST = 'GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_LAB_SAMPLES_EXIST';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_LAST_ADVISED_GOOD_IN_DELIVERY = 'GLOBAL_SERVERERROR_CANNOT_DELETE_LAST_ADVISED_GOOD_IN_DELIVERY';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE = 'GLOBAL_SERVERERROR_MAX_ALLOWED_11_RG_WITHIN_ONE_AG_IN_TEMPLATE';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG = 'GLOBAL_SERVERERROR_MAX_ALLOWED_10_RG_WITHIN_ONE_AG';
lang.GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG = 'GLOBAL_SERVERERROR_MAX_ALLOWED_19_RG_WITHIN_ONE_AG';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE = 'GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_IMAGE';
lang.GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED = 'GLOBAL_SERVERERROR_CAMERA_CALIBRATION_REQUIRED';
lang.GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT = 'GLOBAL_SERVERERROR_CAMERA_TIMEOUT_WHEN_GETTING_OCR_RESULT';
lang.GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND = 'GLOBAL_SERVERERROR_ROLE_ADMIN_NOT_FOUND';
lang.GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND = 'GLOBAL_SERVERERROR_ROLE_APP_REGISTRATION_NOT_FOUND';
lang.GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED = 'GLOBAL_SERVERERROR_ONLY_APP_REGISTRATION_EDIT_ALLOWED';
lang.GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR = 'GLOBAL_SERVERERROR_WEAK_CLIENT_SECRET_ERROR';
lang.GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_CLIENT_ID_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE = 'GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_TITLE';
lang.GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION = 'GLOBAL_SERVERERROR_INVALID_APP_REGISTRATION_DESCRIPTION';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20 = 'GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET_MIN_LENGTH_20';
lang.GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS = 'GLOBAL_SERVERERROR_CANNOT_REMOVE_TENANT_HAS_USERS';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES = 'GLOBAL_SERVERERROR_CANNOT_UPDATE_SEQUENCE_TENANT_HAS_DELIVERIES';
lang.GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES = 'GLOBAL_SERVERERROR_CANNOT_UPDATE_LEGAL_ENTITY_HAS_DELIVERIES';
lang.GLOBAL_SERVERERROR_CANNOT_CHANGE_TENANT_WORKFLOW = 'GLOBAL_SERVERERROR_CANNOT_CHANGE_TENANT_WORKFLOW';
lang.GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT = 'GLOBAL_SERVERERROR_SITE_IS_ALREADY_USED_BY_ANOTHER_TENANT';
lang.GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY = 'GLOBAL_SERVERERROR_SITE_FIELD_IS_MANDATORY';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY = 'GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_FIELD_IS_MANDATORY';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST = 'GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_DOES_NOT_EXIST';
lang.GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT = 'GLOBAL_SERVERERROR_SHAREPOINT_UPLOAD_FOLDER_NOT_SELECTED_FOR_TENANT';
lang.GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE = 'GLOBAL_SERVERERROR_DOPS_NOT_ALLOWED_TO_UPLOAD_TO_THE_SELECTED_SITE';
lang.GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT = 'GLOBAL_SERVERERROR_FAILED_TO_UPDATE_METADATA_ON_SHAREPOINT';
lang.GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT = 'GLOBAL_SERVERERROR_CANNOT_AUTHORIZE_TO_SHAREPOINT';
lang.GLOBAL_SERVERERROR_SHAREPOINT_FOLDER_NOT_FOUND = 'GLOBAL_SERVERERROR_SHAREPOINT_FOLDER_NOT_FOUND';
lang.GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED = 'GLOBAL_SERVERERROR_DELIVERY_ALREADY_SYNCED';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER = 'GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_DELIVERY_NOTE_NUMBER';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED = 'GLOBAL_SERVERERROR_CANNOT_DELETE_ADVISED_GOOD_ALREADY_CLAIMED';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NO_TARE_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_SUB_ADVISED_GOOD_NO_RECEIVED_GOOD';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_ADVISED_GOOD_NO_SUBS';
lang.GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD = 'GLOBAL_SERVERERROR_CANNOT_VERIFY_DELIVERY_NO_ADVISED_GOOD';
lang.GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED = 'GLOBAL_SERVERERROR_CANNOT_SAVE_TARE_WEIGHT_ADVISED_GOOD_IS_CLAIMED';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_STOCK_CODED';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_LAB_ITEMS_COMPLETED';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_NOT_ALL_ITEMS_HAVE_GROSS_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE = 'GLOBAL_SERVERERROR_CANNOT_SYNC_DELIVERY_NOT_ALL_ITEMS_HAVE_EWC_CODE';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_ADVISED_GOOD_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE';
lang.CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF = 'CANNOT_SIGN_OFF_DELIVERY_AG_NOT_READY_FOR_SIGN_OFF';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_ALL_ITEMS_HAVE_TARE_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF = 'GLOBAL_SERVERERROR_CANNOT_COMPLETE_DELIVERY_NOT_SIGNED_OFF';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE = 'GLOBAL_SERVERERROR_CANNOT_RESYNC_DELIVERY_NOT_YET_SYNCED_AT_WEIGHTBRIDGE';
lang.GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF = 'GLOBAL_SERVERERROR_CANNOT_RESYNC_SORTING_NOT_YET_SIGNED_OFF';
lang.GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_MATERIAL_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY = (grn) => `GLOBAL_SERVERERROR_MATERIAL_IN_USE_BY_DELIVERY`;
lang.GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED = 'GLOBAL_SERVERERROR_CANNOT_EDIT_ADVISED_GOOD_UNTIL_CLAIMED';
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD = 'GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_COMPLETED_ADVISED_GOOD';
lang.GLOBAL_SERVERERROR_STOCK_CODE_INVALID = (stockCode) => `GLOBAL_SERVERERROR_STOCK_CODE_INVALID`;
lang.GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND = (userRole) => `GLOBAL_SERVERERROR_USER_ROLE_NOT_FOUND`;
lang.GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD = 'GLOBAL_SERVERERROR_CANNOT_ADD_LAB_ANALYSIS_FOR_NOT_CLAIMED_ADVISED_GOOD';
lang.GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR = 'GLOBAL_SERVERERROR_QR_CODE_IMAGE_GENERATION_ERROR';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_NULL_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED = 'GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_MAX_WEIGHT_EXCEEDED';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_NULL_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED = 'GLOBAL_SERVERERROR_CANNOT_TRANSFER_ADVISED_GOOD_MAX_WEIGHT_EXCEEDED';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT = 'GLOBAL_SERVERERROR_CANNOT_TRANSFER_RECEIVED_GOOD_WITH_NEGATIVE_WEIGHT';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD = 'GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_RECEIVED_GOOD';
lang.GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD = 'GLOBAL_SERVERERROR_CANNOT_TRANSFER_FULL_ADVISED_GOOD';
lang.GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD = 'GLOBAL_SERVERERROR_CAN_NOT_TRANSFER_RECEIVED_GOOD_IF_ONLY_ONE_IN_ADVISED_GOOD';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN = 'GLOBAL_SERVERERROR_CANNOT_CREATE_SUPER_ADMIN';
lang.GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR = 'GLOBAL_SERVERERROR_WEAK_PASSWORD_ERROR';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN = 'GLOBAL_SERVERERROR_CANNOT_DELETE_ADMIN_IF_ADMIN';
lang.GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR = 'GLOBAL_SERVERERROR_UNDOCUMENTED_ERROR';
lang.GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL = 'GLOBAL_SERVERERROR_ERROR_SENDING_EMAIL';
lang.GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST = 'GLOBAL_SERVERERROR_ERROR_WHILE_PROCESSING_REQUEST';
lang.GLOBAL_SERVERERROR_FILE_NOT_FOUND = 'GLOBAL_SERVERERROR_FILE_NOT_FOUND';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND = 'GLOBAL_SERVERERROR_ADVISED_GOOD_NOT_FOUND';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND = 'GLOBAL_SERVERERROR_ADVISED_GOOD_ATTACHMENT_NOT_FOUND';
lang.GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND = 'GLOBAL_SERVERERROR_CONTAMINATION_NOT_FOUND';
lang.GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND = 'GLOBAL_SERVERERROR_SUB_ADVISED_GOOD_NOT_FOUND';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND = 'GLOBAL_SERVERERROR_RECEIVED_GOOD_NOT_FOUND';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79 = 'GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_79';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_FOUND = 'GLOBAL_SERVERERROR_CAMERA_NOT_FOUND';
lang.GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE = 'GLOBAL_SERVERERROR_CAMERA_NOT_ACTIVE';
lang.GLOBAL_SERVERERROR_TENANT_NOT_FOUND = 'GLOBAL_SERVERERROR_TENANT_NOT_FOUND';
lang.GLOBAL_SERVERERROR_ADMIN_NOT_FOUND = 'GLOBAL_SERVERERROR_ADMIN_NOT_FOUND';
lang.GLOBAL_SERVERERROR_WEIGHBRIDGE_USER_NOT_FOUND = 'GLOBAL_SERVERERROR_WEIGHBRIDGE_USER_NOT_FOUND';
lang.GLOBAL_SERVERERROR_TRADER_USER_NOT_FOUND = 'GLOBAL_SERVERERROR_TRADER_USER_NOT_FOUND';
lang.GLOBAL_SERVERERROR_USER_NOT_FOUND = 'GLOBAL_SERVERERROR_USER_NOT_FOUND';
lang.GLOBAL_SERVERERROR_LOCATION_NOT_FOUND = 'GLOBAL_SERVERERROR_LOCATION_NOT_FOUND';
lang.GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_LOCATION_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND = 'GLOBAL_SERVERERROR_DELIVERY_NOT_FOUND';
lang.GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND = 'GLOBAL_SERVERERROR_DELIVERY_ATTACHMENT_NOT_FOUND';
lang.GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND = 'GLOBAL_SERVERERROR_SUPPLIER_NOT_FOUND';
lang.GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND = 'GLOBAL_SERVERERROR_SUPPLIER_TYPE_NOT_FOUND';
lang.GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND = 'GLOBAL_SERVERERROR_ROAD_HAULIER_NOT_FOUND';
lang.GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND = 'GLOBAL_SERVERERROR_MATERIAL_NOT_FOUND';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND = 'GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND';
lang.GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW = 'GLOBAL_SERVERERROR_LAB_INPUT_TYPE_NOT_FOUND_FOR_WORKFLOW';
lang.GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND = 'GLOBAL_SERVERERROR_LAB_INPUT_NOT_FOUND';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED = 'GLOBAL_SERVERERROR_LAB_STATUS_NOT_SUPPORTED';
lang.GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW = 'GLOBAL_SERVERERROR_LAB_STATUS_NOT_FOUND_FOR_WORKFLOW';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND = 'GLOBAL_SERVERERROR_STOCK_ITEM_NOT_FOUND';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_STOCK_ITEM_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5 = 'GLOBAL_SERVERERROR_STOCK_ITEM_CODE_ALLOWED_LENGTH_5';
lang.GLOBAL_SERVERERROR_PRINTER_NOT_FOUND = 'GLOBAL_SERVERERROR_PRINTER_NOT_FOUND';
lang.GLOBAL_SERVERERROR_SCALE_NOT_FOUND = 'GLOBAL_SERVERERROR_SCALE_NOT_FOUND';
lang.GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND = 'GLOBAL_SERVERERROR_PRINT_TEMPLATE_NOT_FOUND';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND = 'GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_FOUND';
lang.GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM = 'GLOBAL_SERVERERROR_PRODUCT_FORM_NOT_RELATED_TO_STOCK_ITEM';
lang.GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND = 'GLOBAL_SERVERERROR_PRODUCT_QUALITY_NOT_FOUND';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND = 'GLOBAL_SERVERERROR_PRODUCT_VARIANT_NOT_FOUND';
lang.GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF = 'GLOBAL_SERVERERROR_PRODUCT_VARIANTS_ARE_REQUIRED_FOR_SIGN_OFF';
lang.GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_USERNAME_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF = 'GLOBAL_SERVERERROR_CANNOT_DELETE_YOURSELF';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD = 'GLOBAL_SERVERERROR_INVALID_USERNAME_OR_PASSWORD';
lang.GLOBAL_SERVERERROR_CANNOT_LOGIN_TENANT_IS_DELETED = 'GLOBAL_SERVERERROR_CANNOT_LOGIN_TENANT_IS_DELETED';
lang.GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE = 'GLOBAL_SERVERERROR_PASSWORD_HAS_ALREADY_BEEN_USED_BEFORE';
lang.GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED = 'GLOBAL_SERVERERROR_CLIENT_SECRET_EXPIRED';
lang.GLOBAL_SERVERERROR_INVALID_EMAIL = 'GLOBAL_SERVERERROR_INVALID_EMAIL';
lang.GLOBAL_SERVERERROR_INVALID_USERNAME = 'GLOBAL_SERVERERROR_INVALID_USERNAME';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD = 'GLOBAL_SERVERERROR_INVALID_PASSWORD';
lang.GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10 = 'GLOBAL_SERVERERROR_INVALID_PASSWORD_MIN_LENGTH_10';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_ID = 'GLOBAL_SERVERERROR_INVALID_CLIENT_ID';
lang.GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET = 'GLOBAL_SERVERERROR_INVALID_CLIENT_SECRET';
lang.GLOBAL_SERVERERROR_INVALID_FIRSTNAME = 'GLOBAL_SERVERERROR_INVALID_FIRSTNAME';
lang.GLOBAL_SERVERERROR_INVALID_LASTNAME = 'GLOBAL_SERVERERROR_INVALID_LASTNAME';
lang.GLOBAL_SERVERERROR_INVALID_USER_ROLES = 'GLOBAL_SERVERERROR_INVALID_USER_ROLES';
lang.GLOBAL_SERVERERROR_INVALID_USER_TENANTS = 'GLOBAL_SERVERERROR_INVALID_USER_TENANTS';
lang.GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID = 'GLOBAL_SERVERERROR_INVALID_EXTERNAL_EMPLOYEE_ID';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED = 'GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_REQUIRED';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND = 'GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_ID_NOT_FOUND';
lang.GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT = (tenantName) => `GLOBAL_SERVERERROR_EXTERNAL_EMPLOYEE_NOT_AVAILABLE_FOR_TENANT`;
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE = 'GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_ROLE';
lang.GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT = 'GLOBAL_SERVERERROR_USER_MUST_HAVE_ONLY_ONE_DEFAULT_TENANT';
lang.GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES = 'GLOBAL_SERVERERROR_USER_ACTIVE_ROLE_MUST_MATCH_ONE_OF_USER_ROLES';
lang.GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT = 'GLOBAL_SERVERERROR_USER_HAS_NO_PERMISSION_TO_ACCESS_THE_TENANT';
lang.GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN = 'GLOBAL_SERVERERROR_ONLY_SUPER_ADMIN_CAN_CREATE_OR_EDIT_ADMIN';
lang.GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY = 'GLOBAL_SERVERERROR_USER_CANNOT_BE_TRADER_AND_BACKOFFICE_SIMULTANEOUSLY';
lang.GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES = 'GLOBAL_SERVERERROR_USER_WITH_ROLE_DATAANALYZER_CANNOT_HAVE_MULTIPLE_ROLES';
lang.GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN = 'GLOBAL_SERVERERROR_MULTIPLE_TENANTS_CAN_BE_ASSIGNED_FOR_TRADER_BACKOFFICE_OR_ADMIN';
lang.GLOBAL_SERVERERROR_INVALID_NAME = 'GLOBAL_SERVERERROR_INVALID_NAME';
lang.GLOBAL_SERVERERROR_ACCESS_DENIED = 'GLOBAL_SERVERERROR_ACCESS_DENIED';
lang.GLOBAL_SERVERERROR_SESSION_NOT_FOUND = 'GLOBAL_SERVERERROR_SESSION_NOT_FOUND';
lang.GLOBAL_SERVERERROR_SESSION_EXPIRED = 'GLOBAL_SERVERERROR_SESSION_EXPIRED';
lang.GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE = 'GLOBAL_SERVERERROR_TRY_AGAIN_IN_ONE_MINUTE';
lang.GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING = 'GLOBAL_SERVERERROR_STOCK_ITEM_NAME_MISSING';
lang.GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD = 'GLOBAL_SERVERERROR_CANNOT_SIGN_OFF_DELIVERY_IF_VERIFIED_ADVISED_GOOD';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_SUPPLIER_CODE_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING = 'GLOBAL_SERVERERROR_SUPPLIER_NAME_MISSING';
lang.GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING = 'GLOBAL_SERVERERROR_SUPPLIER_CODE_MISSING';
lang.GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS = 'GLOBAL_SERVERERROR_PROVIDE_FLAGGED_STATUS';
lang.GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION = 'GLOBAL_SERVERERROR_PROVIDE_DESCRIPTION';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255 = 'GLOBAL_SERVERERROR_RECEIVED_GOOD_DESCRIPTION_MAX_LENGTH_255';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION = 'GLOBAL_SERVERERROR_PROVIDE_MATERIAL_INFORMATION';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID = 'GLOBAL_SERVERERROR_PROVIDE_MATERIAL_ID';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT = 'GLOBAL_SERVERERROR_PROVIDE_MATERIAL_WEIGHT';
lang.GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID = 'GLOBAL_SERVERERROR_PROVIDE_SUB_ADVISED_GOOD_ID';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1 = 'GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P1';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2 = 'GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P2';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3 = 'GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P3';
lang.GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4 = 'GLOBAL_SERVERERROR_PROVIDE_VALUE_FOR_P4';
lang.GLOBAL_SERVERERROR_PROVIDE_CONTRAST = 'GLOBAL_SERVERERROR_PROVIDE_CONTRAST';
lang.GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE = 'GLOBAL_SERVERERROR_PROVIDE_DISPLAY_TYPE';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME = 'GLOBAL_SERVERERROR_PROVIDE_CAMERA_NAME';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE = 'GLOBAL_SERVERERROR_PROVIDE_CAMERA_PLACE';
lang.GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID = 'GLOBAL_SERVERERROR_PROVIDE_CAMERA_UNIQUE_ID';
lang.GLOBAL_SERVERERROR_PROVIDE_SUPPLIER = 'GLOBAL_SERVERERROR_PROVIDE_SUPPLIER';
lang.GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER = 'GLOBAL_SERVERERROR_PROVIDE_ROAD_HAULIER';
lang.GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT = 'GLOBAL_SERVERERROR_PROVIDE_DEPARTMENT';
lang.GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30 = 'GLOBAL_SERVERERROR_REMARKS_MAX_LENGTH_30';
lang.GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER = 'GLOBAL_SERVERERROR_PROVIDE_VEHICLE_REGISTRATION_NUMBER';
lang.GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM = 'GLOBAL_SERVERERROR_PROVIDE_STOCK_ITEM';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION = 'GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_DESCRIPTION';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30 = 'GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_30';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50 = 'GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_50';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100 = 'GLOBAL_SERVERERROR_ADVISED_GOOD_DESCRIPTION_MAX_LENGTH_100';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMMENT_MAX_LENGTH_100 = 'GLOBAL_SERVERERROR_ADVISED_GOOD_COMMENT_MAX_LENGTH_100';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNLOADED_ATTACHMENTS_MISSING_ERROR = 'GLOBAL_SERVERERROR_ADVISED_GOOD_UNLOADED_ATTACHMENTS_MISSING_ERROR';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_COMPLETED_ATTACHMENTS_MISSING_ERROR = 'GLOBAL_SERVERERROR_ADVISED_GOOD_COMPLETED_ATTACHMENTS_MISSING_ERROR';
lang.GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER = 'GLOBAL_SERVERERROR_PROVIDE_WB_TICKET_NUMBER';
lang.GLOBAL_SERVERERROR_PROVIDE_LOCATION = 'GLOBAL_SERVERERROR_PROVIDE_LOCATION';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE = 'GLOBAL_SERVERERROR_PROVIDE_MATERIAL_CODE';
lang.GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME = 'GLOBAL_SERVERERROR_PROVIDE_MATERIAL_NAME';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME = 'GLOBAL_SERVERERROR_PROVIDE_PRINTER_NAME';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP = 'GLOBAL_SERVERERROR_PROVIDE_PRINTER_IP';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL = 'GLOBAL_SERVERERROR_PROVIDE_PRINTER_MODEL';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS = 'GLOBAL_SERVERERROR_PROVIDE_PRINTER_ADDRESS';
lang.GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255 = 'GLOBAL_SERVERERROR_PRINTER_DESCRIPTION_MAX_LENGTH_255';
lang.GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255 = 'GLOBAL_SERVERERROR_PRINTER_ADDRESS_MAX_LENGTH_255';
lang.GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50 = 'GLOBAL_SERVERERROR_PRINTER_NAME_MAX_LENGTH_50';
lang.GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND = 'GLOBAL_SERVERERROR_DOCUMENT_TYPE_NOT_FOUND';
lang.GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME = 'GLOBAL_SERVERERROR_PROVIDE_LASERNET_REPORT_NAME';
lang.GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255 = 'GLOBAL_SERVERERROR_LASERNET_REPORT_NAME_MAX_LENGTH_255';
lang.GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES = 'GLOBAL_SERVERERROR_PROVIDE_DEFAULT_NUMBER_OF_COPIES';
lang.GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID = 'GLOBAL_SERVERERROR_PROVIDE_PRINTER_ID';
lang.GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_PRINTER_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND = 'GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_NOT_FOUND';
lang.GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_PRINTER_CONFIGURATION_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES = 'GLOBAL_SERVERERROR_PROVIDE_NUMBER_OF_COPIES';
lang.GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER = 'GLOBAL_SERVERERROR_PROVIDE_WEIGHT_TO_TRANSFER';
lang.GLOBAL_SERVERERROR_PROVIDE_STATUS = 'GLOBAL_SERVERERROR_PROVIDE_STATUS';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME = 'GLOBAL_SERVERERROR_PROVIDE_SCALE_NAME';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_IP = 'GLOBAL_SERVERERROR_PROVIDE_SCALE_IP';
lang.GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT = 'GLOBAL_SERVERERROR_PROVIDE_SCALE_PORT';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE = 'GLOBAL_SERVERERROR_PROVIDE_DELIVERY_TYPE';
lang.GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION = 'GLOBAL_SERVERERROR_PROVIDE_WEATHER_CONDITION';
lang.GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE = 'GLOBAL_SERVERERROR_PROVIDE_TRANSACTION_TYPE';
lang.GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE = 'GLOBAL_SERVERERROR_PROVIDE_PROCESSING_PRICE';
lang.GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND = 'GLOBAL_SERVERERROR_PROCESSING_PRICE_NOT_FOUND';
lang.GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH = 'GLOBAL_SERVERERROR_IMAGE_RESOLUTION_TOO_HIGH';
lang.GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED = 'GLOBAL_SERVERERROR_ONLY_PDF_FILES_AND_IMAGES_ARE_ALLOWED';
lang.GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY = 'GLOBAL_SERVERERROR_GRN_CAN_NOT_BE_EMPTY';
lang.GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION = (transfers) => `GLOBAL_SERVERERROR_CANNOT_DELETE_ITEM_WITH_TRANSFER_RELATION`;
lang.GLOBAL_SERVERERROR_UNSUPPORTED_ACTION = 'GLOBAL_SERVERERROR_UNSUPPORTED_ACTION';
lang.GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE = 'GLOBAL_SERVERERROR_THERE_ARE_NO_D365_TENANTS_AVAILABLE';
lang.GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_CANNOT_CREATE_LAB_INPUT_SAME_LAB_INPUT_TYPE_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT = 'GLOBAL_SERVERERROR_ADVISED_GOOD_DEDUCTION_NO_WEIGHT';
lang.GLOBAL_SERVERERROR_MACHINE_NOT_FOUND = 'GLOBAL_SERVERERROR_MACHINE_NOT_FOUND';
lang.GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND = 'GLOBAL_SERVERERROR_TARGET_BATCH_NOT_FOUND';
lang.GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_RECEIVED_GOOD_WEIGHT_MISSING`;
lang.GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_MACHINE_VALUE_MISSING`;
lang.GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_WI_DONE_VALUE_MISSING`;
lang.GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_BULK_DENSITY_VALUE_MISSING`;
lang.GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_MATERIAL_DESCRIPTION_VALUE_MISSING`;
lang.GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_FOUND_QUALITY_VALUE_MISSING`;
lang.GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_MAIN_TYPE_VALUE_MISSING`;
lang.GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_TARGET_BATCH_VALUE_MISSING`;
lang.GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_NAME_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_CODE_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_LOCATION_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS = 'GLOBAL_SERVERERROR_TENANT_LOCATION_MAX_CHARS';
lang.GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_GRN_PREFIX_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_CURRENT_GRN_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_WORKFLOW_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_TIMEZONE_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_REGION_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS = 'GLOBAL_SERVERERROR_TENANT_REGION_MAX_CHARS';
lang.GLOBAL_SERVERERROR_TENANT_COMMENT_MAX_CHARS = 'GLOBAL_SERVERERROR_TENANT_COMMENT_MAX_CHARS';
lang.GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING = 'GLOBAL_SERVERERROR_TENANT_LEGAL_ENTITY_VALUE_MISSING';
lang.GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT = 'GLOBAL_SERVERERROR_ONLY_ATTACHMENT_OWNER_OR_ADMIN_CAN_DELETE_ATTACHMENT';
lang.GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING = 'GLOBAL_SERVERERROR_INTEGRATION_ENDPOINT_CONFIGURATION_MISSING';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED = 'GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IN_STATUS_CLAIMED';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN = 'GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_BY_OWNER_OR_ADMIN';
lang.GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS = 'GLOBAL_SERVERERROR_ADVISED_GOOD_UNCLAIM_ONLY_IF_NO_LAB_INPUTS';
lang.GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_PACKAGING_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND = 'GLOBAL_SERVERERROR_PACKAGING_NOT_FOUND';
lang.GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT = 'GLOBAL_SERVERERROR_SIZES_SUM_IS_NOT_100_PERCENT';
lang.GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND = 'GLOBAL_SERVERERROR_PROCESSING_TIME_NOT_FOUND';
lang.GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING = (rgIdentifier) => `GLOBAL_SERVERERROR_CONTAMINATION_VALUE_MISSING`;
lang.GLOBAL_SERVERERROR_INVALID_CODE = 'GLOBAL_SERVERERROR_INVALID_CODE';
lang.GLOBAL_SERVERERROR_PROVIDE_ORIGIN = 'GLOBAL_SERVERERROR_PROVIDE_ORIGIN';
lang.GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND = 'GLOBAL_SERVERERROR_ORIGIN_NOT_FOUND';
lang.GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35 = 'GLOBAL_SERVERERROR_ORIGIN_ALLOWED_LENGTH_35';
lang.GLOBAL_SERVERERROR_EWC_NOT_FOUND = 'GLOBAL_SERVERERROR_EWC_NOT_FOUND';
lang.GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_EWC_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_GRN_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF = 'GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_NOT_YET_SIGNED_OFF';
lang.GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED = 'GLOBAL_SERVERERROR_CANNOT_EXPORT_DELIVERY_LIST_NEEDS_TO_BE_FILTERED';
lang.GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND = 'GLOBAL_SERVERERROR_MEASUREMENT_NOT_FOUND';
lang.GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND = 'GLOBAL_SERVERERROR_SPECTRO_ANALYSIS_NOT_FOUND';
lang.GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS = (joinedlabelslist) => `GLOBAL_SERVERERROR_PROVIDE_MANDATORY_FIELDS`;
lang.GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH = (allowedLength) => `GLOBAL_SERVERERROR_INVALID_USER_CODE_LENGTH`;
lang.GLOBAL_SERVERERROR_USER_WITH_THIS_CODE_ALREADY_EXISTS = 'GLOBAL_SERVERERROR_USER_WITH_THIS_CODE_ALREADY_EXISTS';
lang.GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE = 'GLOBAL_SERVERERROR_PROVIDE_DELIVERY_CREATION_DATE';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER = 'GLOBAL_SERVERERROR_PROVIDE_ADVISED_GOOD_LINE_NUMBER';
lang.GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE = 'GLOBAL_SERVERERROR_PROVIDE_TRADER_CODE';
lang.GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT = 'GLOBAL_SERVERERROR_PROVIDE_ADVISED_NET_WEIGHT';
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE = (grn) => `GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_COMPLETED_CANNOT_UPDATE`;
lang.GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE = (grn) => `GLOBAL_SERVERERROR_DELIVERY_GRN_ALREADY_SIGNED_OFF_CANNOT_UPDATE`;
lang.GLOBAL_SERVERERROR_FILE_IS_EMPTY = 'GLOBAL_SERVERERROR_FILE_IS_EMPTY';
lang.GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED = 'GLOBAL_SERVERERROR_CONTRACT_IMPORT_FAILED';
lang.GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE = 'GLOBAL_SERVERERROR_NUMBER_OF_COLUMNS_DOES_NOT_CORRESPOND_THE_TEMPLATE';
lang.GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED = 'GLOBAL_SERVERERROR_ANOTHER_COLUMN_EXPECTED_IN_PLACE_OF_PROVIDED';
lang.GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED = 'GLOBAL_SERVERERROR_YARD_LOCATIONS_IMPORT_FAILED';
lang.GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED = 'GLOBAL_SERVERERROR_SUPPLIERS_IMPORT_FAILED';
lang.GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED = 'GLOBAL_SERVERERROR_ROAD_HAULIERS_IMPORT_FAILED';
lang.GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED = 'GLOBAL_SERVERERROR_STOCK_ITEMS_IMPORT_FAILED';
lang.GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY  = 'GLOBAL_SERVERERROR_UNITS_FIELD_IS_MANDATORY ';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY = 'GLOBAL_SERVERERROR_LEGAL_ENTITY_FIELD_IS_MANDATORY';
lang.GLOBAL_SERVERERROR_LEGAL_ENTITY_NOT_FOUND = 'GLOBAL_SERVERERROR_LEGAL_ENTITY_NOT_FOUND';
lang.GLOBAL_SERVERERROR_EXTERNAL_API_NAME_NOT_FOUND = 'GLOBAL_SERVERERROR_EXTERNAL_API_NAME_NOT_FOUND';
lang.GLOBAL_COPY_TITLE = (itemname) => `GLOBAL_COPY_TITLE`;
lang.GLOBAL_COPY_SUCCESS_MESSAGE = (name) => `GLOBAL_COPY_SUCCESS_MESSAGE`;
lang.HEADER_QRCODE_HEADER = 'HEADER_QRCODE_HEADER';
lang.HEADER_QRCODE_ERROR_MSG = 'HEADER_QRCODE_ERROR_MSG';
lang.HEADER_LABEL_LOGOUT = 'HEADER_LABEL_LOGOUT';
lang.HEADER_LABEL_CHANGE_PASSWORD = 'HEADER_LABEL_CHANGE_PASSWORD';
lang.HEADER_LABEL_SWITCH_ROLE = 'HEADER_LABEL_SWITCH_ROLE';
lang.HEADER_LABEL_SWITCH_TENANT = 'HEADER_LABEL_SWITCH_TENANT';
lang.HEADER_MODAL_LOGOUT = 'HEADER_MODAL_LOGOUT';
lang.HEADER_MODAL_SWITCH_ROLE = 'HEADER_MODAL_SWITCH_ROLE';
lang.HEADER_MODAL_SWITCH_TENANT = 'HEADER_MODAL_SWITCH_TENANT';
lang.ADMIN_SECTIONENTITY_USER = 'ADMIN_SECTIONENTITY_USER';
lang.ADMIN_SECTIONENTITY_STOCK_ITEM = 'ADMIN_SECTIONENTITY_STOCK_ITEM';
lang.ADMIN_SECTIONENTITY_SUPPLIER = 'ADMIN_SECTIONENTITY_SUPPLIER';
lang.ADMIN_SECTIONENTITY_ROAD_HAULIER = 'ADMIN_SECTIONENTITY_ROAD_HAULIER';
lang.ADMIN_SECTIONENTITY_YARD_LOCATION = 'ADMIN_SECTIONENTITY_YARD_LOCATION';
lang.ADMIN_SECTIONENTITY_PRINTER = 'ADMIN_SECTIONENTITY_PRINTER';
lang.ADMIN_SECTIONENTITY_SCALE = 'ADMIN_SECTIONENTITY_SCALE';
lang.ADMIN_SECTIONENTITY_MATERIAL = 'ADMIN_SECTIONENTITY_MATERIAL';
lang.ADMIN_SECTIONENTITY_MACHINE = 'ADMIN_SECTIONENTITY_MACHINE';
lang.ADMIN_SECTIONENTITY_TARGET_BATCH = 'ADMIN_SECTIONENTITY_TARGET_BATCH';
lang.ADMIN_SECTIONENTITY_PACKAGE = 'ADMIN_SECTIONENTITY_PACKAGE';
lang.ADMIN_SECTIONENTITY_EWC = 'ADMIN_SECTIONENTITY_EWC';
lang.ADMIN_SECTIONENTITY_ORIGIN = 'ADMIN_SECTIONENTITY_ORIGIN';
lang.ADMIN_ACTIVITYLOG_TITLE = 'ADMIN_ACTIVITYLOG_TITLE';
lang.ADMIN_ACTIVITYLOG_ACTION = 'ADMIN_ACTIVITYLOG_ACTION';
lang.ADMIN_ACTIVITYLOG_CREATEDAT = 'ADMIN_ACTIVITYLOG_CREATEDAT';
lang.ADMIN_ACTIVITYLOG_USER = 'ADMIN_ACTIVITYLOG_USER';
lang.ADMIN_ACTIVITYLOG_MESSAGE = 'ADMIN_ACTIVITYLOG_MESSAGE';
lang.ADMIN_MATERIALS_CREATE_SUCCESS_MSG = 'ADMIN_MATERIALS_CREATE_SUCCESS_MSG';
lang.ADMIN_MATERIALS_TITLE = 'ADMIN_MATERIALS_TITLE';
lang.ADMIN_MATERIALS_CREATE_NEW = 'ADMIN_MATERIALS_CREATE_NEW';
lang.ADMIN_MATERIALDETAIL_UPDATE_MSG = 'ADMIN_MATERIALDETAIL_UPDATE_MSG';
lang.ADMIN_MATERIALDETAIL_DELETE_MSG = 'ADMIN_MATERIALDETAIL_DELETE_MSG';
lang.ADMIN_MATERIALDETAIL_HEADER = 'ADMIN_MATERIALDETAIL_HEADER';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER = 'ADMIN_MATERIALDETAIL_CONFIRMATION_HEADER';
lang.ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT = (name) => `ADMIN_MATERIALDETAIL_CONFIRMATION_TEXT`;
lang.ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG = 'ADMIN_PRINTERLIST_CREATE_SUCCESS_MSG';
lang.ADMIN_PRINTERLIST_HEADER = 'ADMIN_PRINTERLIST_HEADER';
lang.ADMIN_PRINTERLIST_ADD_BTN = 'ADMIN_PRINTERLIST_ADD_BTN';
lang.ADMIN_PRINTERLIST_NAME = 'ADMIN_PRINTERLIST_NAME';
lang.ADMIN_PRINTERLIST_CREATE_NEW = 'ADMIN_PRINTERLIST_CREATE_NEW';
lang.ADMIN_PRINTERDETAIL_UPDATE_MSG = 'ADMIN_PRINTERDETAIL_UPDATE_MSG';
lang.ADMIN_PRINTERDETAIL_DELETE_MSG = 'ADMIN_PRINTERDETAIL_DELETE_MSG';
lang.ADMIN_PRINTERDETAIL_HEADER = 'ADMIN_PRINTERDETAIL_HEADER';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER = 'ADMIN_PRINTERDETAIL_CONFIRMATION_HEADER';
lang.ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT = (name) => `ADMIN_PRINTERDETAIL_CONFIRMATION_TEXT`;
lang.ADMIN_ROADHAULIERLIST_HEADER = 'ADMIN_ROADHAULIERLIST_HEADER';
lang.ADMIN_ROADHAULIERLIST_UPLOAD_BTN = 'ADMIN_ROADHAULIERLIST_UPLOAD_BTN';
lang.ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER = 'ADMIN_ROADHAULIERLIST_AUTHORIZATION_NUMBER';
lang.ADMIN_ROADHAULIERLIST_EXPIRATION_DATE = 'ADMIN_ROADHAULIERLIST_EXPIRATION_DATE';
lang.ADMIN_COMMONLIST_CREATE_HEADER = (name) => `ADMIN_COMMONLIST_CREATE_HEADER`;
lang.ADMIN_COMMONLIST_UPLOAD_HEADER = (name) => `ADMIN_COMMONLIST_UPLOAD_HEADER`;
lang.ADMIN_COMMONLIST_CREATE_SUCCESS_MSG = (name) => `ADMIN_COMMONLIST_CREATE_SUCCESS_MSG`;
lang.ADMIN_COMMONDETAIL_UPDATE_MSG = (name) => `ADMIN_COMMONDETAIL_UPDATE_MSG`;
lang.ADMIN_COMMONDETAIL_HIDE_MSG = (name) => `ADMIN_COMMONDETAIL_HIDE_MSG`;
lang.ADMIN_COMMONDETAIL_UNHIDE_MSG = (name) => `ADMIN_COMMONDETAIL_UNHIDE_MSG`;
lang.ADMIN_COMMONDETAIL_HEADER = (name) => `ADMIN_COMMONDETAIL_HEADER`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER = (name) => `ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER`;
lang.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER = (name) => `ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER`;
lang.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT`;
lang.ADMIN_COMMONDETAIL_ERROR_MSG = (name) => `ADMIN_COMMONDETAIL_ERROR_MSG`;
lang.ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG = 'ADMIN_ADMINLISTUPLOAD_UPLOAD_SUCCESS_MSG';
lang.ADMIN_ADMINLISTUPLOAD_ERROR_MSG = 'ADMIN_ADMINLISTUPLOAD_ERROR_MSG';
lang.ADMIN_ADMINLISTUPLOAD_EXAMPLE = 'ADMIN_ADMINLISTUPLOAD_EXAMPLE';
lang.ADMIN_SCALELIST_CREATE_SUCCESS_MSG = 'ADMIN_SCALELIST_CREATE_SUCCESS_MSG';
lang.ADMIN_SCALELIST_HEADER = 'ADMIN_SCALELIST_HEADER';
lang.ADMIN_SCALELIST_ADD_BTN = 'ADMIN_SCALELIST_ADD_BTN';
lang.ADMIN_SCALELIST_NAME = 'ADMIN_SCALELIST_NAME';
lang.ADMIN_SCALELIST_CREATE_NEW = 'ADMIN_SCALELIST_CREATE_NEW';
lang.ADMIN_SCALEDETAIL_UPDATE_MSG = 'ADMIN_SCALEDETAIL_UPDATE_MSG';
lang.ADMIN_SCALEDETAIL_DELETE_MSG = 'ADMIN_SCALEDETAIL_DELETE_MSG';
lang.ADMIN_SCALEDETAIL_HEADER = 'ADMIN_SCALEDETAIL_HEADER';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_HEADER = 'ADMIN_SCALEDETAIL_CONFIRMATION_HEADER';
lang.ADMIN_SCALEDETAIL_CONFIRMATION_TEXT = (name) => `ADMIN_SCALEDETAIL_CONFIRMATION_TEXT`;
lang.ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR = 'ADMIN_SCALEDETAIL_FIELD_UPDATE_ERROR';
lang.ADMIN_STOCKEITEMLIST_HEADER = 'ADMIN_STOCKEITEMLIST_HEADER';
lang.ADMIN_STOCKEITEMLIST_UPLOAD_BTN = 'ADMIN_STOCKEITEMLIST_UPLOAD_BTN';
lang.ADMIN_SUPPLIERLIST_HEADER = 'ADMIN_SUPPLIERLIST_HEADER';
lang.ADMIN_SUPPLIERLIST_UPLOAD_BTN = 'ADMIN_SUPPLIERLIST_UPLOAD_BTN';
lang.ADMIN_SUPPLIERDETAIL_UPDATE_MSG = 'ADMIN_SUPPLIERDETAIL_UPDATE_MSG';
lang.ADMIN_SUPPLIERDETAIL_HIDE_MSG = 'ADMIN_SUPPLIERDETAIL_HIDE_MSG';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_MSG = 'ADMIN_SUPPLIERDETAIL_UNHIDE_MSG';
lang.ADMIN_SUPPLIERDETAIL_HEADER = 'ADMIN_SUPPLIERDETAIL_HEADER';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER = 'ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_HEADER';
lang.ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `ADMIN_SUPPLIERDETAIL_HIDE_CONFIRMATION_TEXT`;
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER = 'ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_HEADER';
lang.ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `ADMIN_SUPPLIERDETAIL_UNHIDE_CONFIRMATION_TEXT`;
lang.ADMIN_SUPPLIERDETAIL_ERROR_MSG = 'ADMIN_SUPPLIERDETAIL_ERROR_MSG';
lang.ADMIN_MACHINEDETAIL_UPDATE_MSG = 'ADMIN_MACHINEDETAIL_UPDATE_MSG';
lang.ADMIN_MACHINEDETAIL_HIDE_MSG = 'ADMIN_MACHINEDETAIL_HIDE_MSG';
lang.ADMIN_MACHINEDETAIL_UNHIDE_MSG = 'ADMIN_MACHINEDETAIL_UNHIDE_MSG';
lang.ADMIN_MACHINEDETAIL_HEADER = 'ADMIN_MACHINEDETAIL_HEADER';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER = 'ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_HEADER';
lang.ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `ADMIN_MACHINEDETAIL_HIDE_CONFIRMATION_TEXT`;
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER = 'ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_HEADER';
lang.ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `ADMIN_MACHINEDETAIL_UNHIDE_CONFIRMATION_TEXT`;
lang.ADMIN_MACHINEDETAIL_ERROR_MSG = 'ADMIN_MACHINEDETAIL_ERROR_MSG';
lang.ADMIN_PACKAGING_DETAIL_UPDATE_MSG = 'ADMIN_PACKAGING_DETAIL_UPDATE_MSG';
lang.ADMIN_PACKAGING_DETAIL_HIDE_MSG = 'ADMIN_PACKAGING_DETAIL_HIDE_MSG';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_MSG = 'ADMIN_PACKAGING_DETAIL_UNHIDE_MSG';
lang.ADMIN_PACKAGING_DETAIL_HEADER = 'ADMIN_PACKAGING_DETAIL_HEADER';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER = 'ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_HEADER';
lang.ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT = (name) => `ADMIN_PACKAGING_DETAIL_HIDE_CONFIRMATION_TEXT`;
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER = 'ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_HEADER';
lang.ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `ADMIN_PACKAGING_DETAIL_UNHIDE_CONFIRMATION_TEXT`;
lang.ADMIN_PACKAGING_DETAIL_ERROR_MSG = 'ADMIN_PACKAGING_DETAIL_ERROR_MSG';
lang.ADMIN_TARGETBATCHDETAIL_UPDATE_MSG = 'ADMIN_TARGETBATCHDETAIL_UPDATE_MSG';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_MSG = 'ADMIN_TARGETBATCHDETAIL_HIDE_MSG';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG = 'ADMIN_TARGETBATCHDETAIL_UNHIDE_MSG';
lang.ADMIN_TARGETBATCHDETAIL_HEADER = 'ADMIN_TARGETBATCHDETAIL_HEADER';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER = 'ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_HEADER';
lang.ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `ADMIN_TARGETBATCHDETAIL_HIDE_CONFIRMATION_TEXT`;
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER = 'ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_HEADER';
lang.ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `ADMIN_TARGETBATCHDETAIL_UNHIDE_CONFIRMATION_TEXT`;
lang.ADMIN_TARGETBATCHDETAIL_ERROR_MSG = 'ADMIN_TARGETBATCHDETAIL_ERROR_MSG';
lang.ADMIN_USERLIST_CREATE_SUCCESS_MSG = 'ADMIN_USERLIST_CREATE_SUCCESS_MSG';
lang.ADMIN_USERLIST_HEADER = 'ADMIN_USERLIST_HEADER';
lang.ADMIN_USERLIST_ADD_BTN = 'ADMIN_USERLIST_ADD_BTN';
lang.ADMIN_USERDETAIL_UPDATE_MSG = 'ADMIN_USERDETAIL_UPDATE_MSG';
lang.ADMIN_USERDETAIL_DELETE_MSG = 'ADMIN_USERDETAIL_DELETE_MSG';
lang.ADMIN_USERDETAIL_DELETE_BTN = 'ADMIN_USERDETAIL_DELETE_BTN';
lang.ADMIN_USERDETAIL_HEADER = 'ADMIN_USERDETAIL_HEADER';
lang.ADMIN_USERDETAIL_CONFIRMATION_HEADER = 'ADMIN_USERDETAIL_CONFIRMATION_HEADER';
lang.ADMIN_USERDETAIL_CONFIRMATION_TEXT = (name) => `ADMIN_USERDETAIL_CONFIRMATION_TEXT`;
lang.ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG = 'ADMIN_YARDLOCATIONLIST_CREATE_SUCCESS_MSG';
lang.ADMIN_YARDLOCATIONLIST_HEADER = 'ADMIN_YARDLOCATIONLIST_HEADER';
lang.ADMIN_YARDLOCATIONLIST_ADD_BTN = 'ADMIN_YARDLOCATIONLIST_ADD_BTN';
lang.ADMIN_YARDLOCATIONLIST_UPLOAD_BTN = 'ADMIN_YARDLOCATIONLIST_UPLOAD_BTN';
lang.ADMIN_YARDLOCATIONLIST_NAME = 'ADMIN_YARDLOCATIONLIST_NAME';
lang.ADMIN_YARDLOCATIONLIST_CREATE_NEW = 'ADMIN_YARDLOCATIONLIST_CREATE_NEW';
lang.ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG = 'ADMIN_YARDLOCATIONDETAIL_UPDATE_MSG';
lang.ADMIN_YARDLOCATIONDETAIL_DELETE_MSG = 'ADMIN_YARDLOCATIONDETAIL_DELETE_MSG';
lang.ADMIN_YARDLOCATIONDETAIL_HEADER = 'ADMIN_YARDLOCATIONDETAIL_HEADER';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER = 'ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_HEADER';
lang.ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT = (name) => `ADMIN_YARDLOCATIONDETAIL_CONFIRMATION_TEXT`;
lang.ADMIN_YARDLOCATIONDETAIL_NAME = 'ADMIN_YARDLOCATIONDETAIL_NAME';
lang.ADMIN_MACHINE_LIST_HEADER = 'ADMIN_MACHINE_LIST_HEADER';
lang.ADMIN_MACHINE_LIST_UPLOAD_BTN = 'ADMIN_MACHINE_LIST_UPLOAD_BTN';
lang.ADMIN_TARGET_BATCH_LIST_HEADER = 'ADMIN_TARGET_BATCH_LIST_HEADER';
lang.ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN = 'ADMIN_TARGET_BATCH_LIST_UPLOAD_BTN';
lang.ADMIN_PACKAGING_ADD_BTN = 'ADMIN_PACKAGING_ADD_BTN';
lang.ADMIN_PACKAGING_UPLOAD_BTN = 'ADMIN_PACKAGING_UPLOAD_BTN';
lang.ADMIN_PACKAGING_CREATE_NEW_PACKAGING = 'ADMIN_PACKAGING_CREATE_NEW_PACKAGING';
lang.ADMIN_PACKAGING_CREATE_SUCCESS_MSG = 'ADMIN_PACKAGING_CREATE_SUCCESS_MSG';
lang.ADMIN_EWCLIST_HEADER = 'ADMIN_EWCLIST_HEADER';
lang.ADMIN_EWCLIST_ADD_BTN = 'ADMIN_EWCLIST_ADD_BTN';
lang.ADMIN_EWCLIST_UPLOAD_BTN = 'ADMIN_EWCLIST_UPLOAD_BTN';
lang.ADMIN_EWCLIST_CODE = 'ADMIN_EWCLIST_CODE';
lang.ADMIN_EWCLIST_CREATE_NEW = 'ADMIN_EWCLIST_CREATE_NEW';
lang.ADMIN_EWCLIST_CREATE_SUCCESS_MSG = 'ADMIN_EWCLIST_CREATE_SUCCESS_MSG';
lang.ADMIN_EWCDETAIL_UPDATE_MSG = 'ADMIN_EWCDETAIL_UPDATE_MSG';
lang.ADMIN_EWCDETAIL_HEADER = 'ADMIN_EWCDETAIL_HEADER';
lang.ADMIN_EWCDETAIL_NAME = 'ADMIN_EWCDETAIL_NAME';
lang.ADMIN_EWCDETAIL_HIDE_MSG = 'ADMIN_EWCDETAIL_HIDE_MSG';
lang.ADMIN_EWCDETAIL_UNHIDE_MSG = 'ADMIN_EWCDETAIL_UNHIDE_MSG';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER = 'ADMIN_EWCDETAIL_HIDE_CONFIRMATION_HEADER';
lang.ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `ADMIN_EWCDETAIL_HIDE_CONFIRMATION_TEXT`;
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER = 'ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_HEADER';
lang.ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `ADMIN_EWCDETAIL_UNHIDE_CONFIRMATION_TEXT`;
lang.ADMIN_EWCDETAIL_ERROR_MSG = 'ADMIN_EWCDETAIL_ERROR_MSG';
lang.ADMIN_ORIGINLIST_HEADER = 'ADMIN_ORIGINLIST_HEADER';
lang.ADMIN_ORIGINLIST_ADD_BTN = 'ADMIN_ORIGINLIST_ADD_BTN';
lang.ADMIN_ORIGINLIST_UPLOAD_BTN = 'ADMIN_ORIGINLIST_UPLOAD_BTN';
lang.ADMIN_ORIGINLIST_CODE = 'ADMIN_ORIGINLIST_CODE';
lang.ADMIN_ORIGINLIST_CREATE_NEW = 'ADMIN_ORIGINLIST_CREATE_NEW';
lang.ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG = 'ADMIN_ORIGINLIST_CREATE_SUCCESS_MSG';
lang.ADMIN_ORIGINDETAIL_UPDATE_MSG = 'ADMIN_ORIGINDETAIL_UPDATE_MSG';
lang.ADMIN_ORIGINDETAIL_HEADER = 'ADMIN_ORIGINDETAIL_HEADER';
lang.ADMIN_ORIGINDETAIL_NAME = 'ADMIN_ORIGINDETAIL_NAME';
lang.ADMIN_ORIGINDETAIL_HIDE_MSG = 'ADMIN_ORIGINDETAIL_HIDE_MSG';
lang.ADMIN_ORIGINDETAIL_UNHIDE_MSG = 'ADMIN_ORIGINDETAIL_UNHIDE_MSG';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER = 'ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_HEADER';
lang.ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `ADMIN_ORIGINDETAIL_HIDE_CONFIRMATION_TEXT`;
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER = 'ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_HEADER';
lang.ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `ADMIN_ORIGINDETAIL_UNHIDE_CONFIRMATION_TEXT`;
lang.ADMIN_ORIGINDETAIL_ERROR_MSG = 'ADMIN_ORIGINDETAIL_ERROR_MSG';
lang.ADMIN_CONTRACTS_TITLE = 'ADMIN_CONTRACTS_TITLE';
lang.ADMIN_CONTRACTS_FILE_IMPORT = 'ADMIN_CONTRACTS_FILE_IMPORT';
lang.ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC = 'ADMIN_CONTRACTS_TRIGGER_SFTP_SYNC';
lang.ADMIN_CONTRACTS_FILE_IMPORTED_MSG = 'ADMIN_CONTRACTS_FILE_IMPORTED_MSG';
lang.ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG = 'ADMIN_CONTRACTS_SFTP_TRIGGERED_MSG';
lang.ADMIN_PROCESSING_UNIT_MINUTES = 'ADMIN_PROCESSING_UNIT_MINUTES';
lang.ADMIN_PROCESSING_UNIT_TONS = 'ADMIN_PROCESSING_UNIT_TONS';
lang.ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE = 'ADMIN_FORM_QUALITY_REFRESH_CONFIRMATION_TITLE';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT = 'ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_TEXT';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE = 'ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_SUCCESS_MESSAGE';
lang.ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE = 'ADMIN_FORM_QUALITY_REFRESH_PRODUCT_VARIANTS_IN_PROGRESS_MESSAGE';
lang.ADMIN_REFRESH_CONFIRMATION_TITLE = (name) => `ADMIN_REFRESH_CONFIRMATION_TITLE`;
lang.ADMIN_REFRESH_SUCCESS_MESSAGE = (name) => `ADMIN_REFRESH_SUCCESS_MESSAGE`;
lang.ADMIN_REFRESH_CONFIRMATION_TEXT = 'ADMIN_REFRESH_CONFIRMATION_TEXT';
lang.ADMIN_REFRESH_IN_PROGRESS_MESSAGE = (name) => `ADMIN_REFRESH_IN_PROGRESS_MESSAGE`;
lang.ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE = 'ADMIN_EMPLOYEE_REFRESH_EMPLOYEES_SUCCESS_MESSAGE';
lang.ADMIN_LASERNET_REPORT_NAME = 'ADMIN_LASERNET_REPORT_NAME';
lang.ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES = 'ADMIN_LASERNET_DEFAULT_NUMBER_OF_COPIES';
lang.ADMIN_LASERNET_DOCUMENT_TYPE = 'ADMIN_LASERNET_DOCUMENT_TYPE';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL = 'ADMIN_LASERNET_DOCUMENT_TYPE_DETAIL';
lang.ADMIN_LASERNET_DOCUMENT_TYPE_ID = 'ADMIN_LASERNET_DOCUMENT_TYPE_ID';
lang.ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG = 'ADMIN_LASERNET_PROVIDE_REPORT_NAME_MSG';
lang.ADMIN_LASERNET_CONFIGURATIONS_HEADER = 'ADMIN_LASERNET_CONFIGURATIONS_HEADER';
lang.ADMIN_LASERNET_CONFIGURATION_DETAILS = 'ADMIN_LASERNET_CONFIGURATION_DETAILS';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER = 'ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_HEADER';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT = 'ADMIN_LASERNET_CONFIGURATION_DELETE_CONFIRMATION_TEXT';
lang.ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE = 'ADMIN_LASERNET_CONFIGURATION_DELETE_SUCCESS_MESSAGE';
lang.ADMIN_LASERNET_PRINTER_ID = 'ADMIN_LASERNET_PRINTER_ID';
lang.SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG = 'SUPERADMIN_TENANTLIST_CREATE_SUCCESS_MSG';
lang.SUPERADMIN_TENANTLIST_HEADER = 'SUPERADMIN_TENANTLIST_HEADER';
lang.SUPERADMIN_TENANTLIST_ADD_BTN = 'SUPERADMIN_TENANTLIST_ADD_BTN';
lang.SUPERADMIN_TENANTLIST_GRN = 'SUPERADMIN_TENANTLIST_GRN';
lang.SUPERADMIN_TENANTLIST_CREATE_NEW = 'SUPERADMIN_TENANTLIST_CREATE_NEW';
lang.SUPERADMIN_TENANTDETAIL_UPDATE_MSG = 'SUPERADMIN_TENANTDETAIL_UPDATE_MSG';
lang.SUPERADMIN_TENANTDETAIL_DELETE_MSG = 'SUPERADMIN_TENANTDETAIL_DELETE_MSG';
lang.SUPERADMIN_TENANTDETAIL_HEADER = 'SUPERADMIN_TENANTDETAIL_HEADER';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER = 'SUPERADMIN_TENANTDETAIL_CONFIRMATION_HEADER';
lang.SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT = (name) => `SUPERADMIN_TENANTDETAIL_CONFIRMATION_TEXT`;
lang.SUPERADMIN_TENANTDETAIL_SITE = 'SUPERADMIN_TENANTDETAIL_SITE';
lang.SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS = 'SUPERADMIN_TENANTDETAIL_CHECK_FOR_MISSING_ATTACHMENTS';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL = 'SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_AG_LEVEL';
lang.SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL = 'SUPERADMIN_TENANTDETAIL_ATTACHMENTS_WARNING_DELIVERY_LEVEL';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION = 'SUPERADMIN_TENANTDETAIL_REQUIRE_CLASSIFICATION';
lang.SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION = 'SUPERADMIN_TENANTDETAIL_REQUIRE_CONTAMINATION';
lang.SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT = 'SUPERADMIN_TENANTDETAIL_CONFIRM_OVERWRITING_DESCRIPTION_IN_SORTING_REPORT';
lang.SUPERADMIN_TENANTDETAIL_DISPLAY_TARE_WEIGHT_FOR_RECEIVED_GOOD = 'SUPERADMIN_TENANTDETAIL_DISPLAY_TARE_WEIGHT_FOR_RECEIVED_GOOD';
lang.SUPERADMIN_TENANTDETAIL_IS_STOCK_CODE_REQUIRED_FOR_VERIFY = 'SUPERADMIN_TENANTDETAIL_IS_STOCK_CODE_REQUIRED_FOR_VERIFY';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_HEADER = 'SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_HEADER';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_HEADER = 'SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_HEADER';
lang.SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_TEXT = (name) => `SUPERADMIN_TENANTDETAIL_HIDE_CONFIRMATION_TEXT`;
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_TEXT = (name) => `SUPERADMIN_TENANTDETAIL_UNHIDE_CONFIRMATION_TEXT`;
lang.SUPERADMIN_TENANTDETAIL_HIDE_SUCCESS_MESSAGE = 'SUPERADMIN_TENANTDETAIL_HIDE_SUCCESS_MESSAGE';
lang.SUPERADMIN_TENANTDETAIL_UNHIDE_SUCCESS_MESSAGE = 'SUPERADMIN_TENANTDETAIL_UNHIDE_SUCCESS_MESSAGE';
lang.SUPERADMIN_TENANTSECTIONS_GENERAL_SECTION = 'SUPERADMIN_TENANTSECTIONS_GENERAL_SECTION';
lang.SUPERADMIN_TENANTSECTIONS_CONFIGURATION_SECTION = 'SUPERADMIN_TENANTSECTIONS_CONFIGURATION_SECTION';
lang.SUPERADMIN_TENANTSECTIONS_AG_CONFIGURATION_SECTION = 'SUPERADMIN_TENANTSECTIONS_AG_CONFIGURATION_SECTION';
lang.SUPERADMIN_TENANTSECTIONS_DELIVERY_CONFIGURATION_SECTION = 'SUPERADMIN_TENANTSECTIONS_DELIVERY_CONFIGURATION_SECTION';
lang.SUPERADMIN_TENANTSECTIONS_REQUIRED_OPTIONS_SECTION = 'SUPERADMIN_TENANTSECTIONS_REQUIRED_OPTIONS_SECTION';
lang.SUPERADMIN_TENANTSECTIONS_INTEGRATION_SECTION = 'SUPERADMIN_TENANTSECTIONS_INTEGRATION_SECTION';
lang.SUPERADMIN_TENANTADMIN_UPDATE_MSG = 'SUPERADMIN_TENANTADMIN_UPDATE_MSG';
lang.SUPERADMIN_TENANTADMIN_ADD_MSG = 'SUPERADMIN_TENANTADMIN_ADD_MSG';
lang.SUPERADMIN_TENANTADMIN_DELETE_MSG = 'SUPERADMIN_TENANTADMIN_DELETE_MSG';
lang.SUPERADMIN_TENANTADMIN_TENANT = 'SUPERADMIN_TENANTADMIN_TENANT';
lang.SUPERADMIN_TENANTADMIN_LOCATION_CODE = 'SUPERADMIN_TENANTADMIN_LOCATION_CODE';
lang.SUPERADMIN_TENANTADMIN_REGION_CODE = 'SUPERADMIN_TENANTADMIN_REGION_CODE';
lang.SUPERADMIN_TENANTADMIN_GRN_VALUE = 'SUPERADMIN_TENANTADMIN_GRN_VALUE';
lang.SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE = 'SUPERADMIN_TENANTADMIN_LAB_SEQUENCE_VALUE';
lang.SUPERADMIN_TENANTADMIN_WORKFLOW = 'SUPERADMIN_TENANTADMIN_WORKFLOW';
lang.SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW = 'SUPERADMIN_TENANTADMIN_CHOOSE_WORKFLOW';
lang.SUPERADMIN_TENANTADMIN_TIMEZONE = 'SUPERADMIN_TENANTADMIN_TIMEZONE';
lang.SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE = 'SUPERADMIN_TENANTADMIN_INTEGRATION_API_BASE';
lang.SUPERADMIN_TENANTADMIN_ADMINS = 'SUPERADMIN_TENANTADMIN_ADMINS';
lang.SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN = 'SUPERADMIN_TENANTADMIN_ADD_ADMIN_BTN';
lang.SUPERADMIN_TENANTADMIN_NO_ADMINS = 'SUPERADMIN_TENANTADMIN_NO_ADMINS';
lang.SUPERADMIN_TENANTADMIN_HEADER = 'SUPERADMIN_TENANTADMIN_HEADER';
lang.SUPERADMIN_TENANTADMINLINE_HEADER = 'SUPERADMIN_TENANTADMINLINE_HEADER';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE = 'SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE = (name) => `SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE`;
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE = 'SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE';
lang.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE = (name) => `SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE`;
lang.SUPERADMIN_SHAREPOINT_CHOOSE_SITE = 'SUPERADMIN_SHAREPOINT_CHOOSE_SITE';
lang.SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH = 'SUPERADMIN_SHAREPOINT_IMAGE_FOLDER_PATH';
lang.SUPERADMIN_SHAREPOINT_SITES = 'SUPERADMIN_SHAREPOINT_SITES';
lang.SUPERADMIN_APP_USERS_TITLE = 'SUPERADMIN_APP_USERS_TITLE';
lang.SUPERADMIN_APP_USERS_ADD_MSG = 'SUPERADMIN_APP_USERS_ADD_MSG';
lang.SUPERADMIN_APP_USERS_ADD_TITLE = 'SUPERADMIN_APP_USERS_ADD_TITLE';
lang.SUPERADMIN_APP_USERS_UPDATE_MSG = 'SUPERADMIN_APP_USERS_UPDATE_MSG';
lang.SUPERADMIN_APP_USERS_RANDOM_SECRET = 'SUPERADMIN_APP_USERS_RANDOM_SECRET';
lang.SUPERADMIN_APP_USERS_COPY_TEXT = 'SUPERADMIN_APP_USERS_COPY_TEXT';
lang.SUPERADMIN_CONNECTIONS_TITLE = 'SUPERADMIN_CONNECTIONS_TITLE';
lang.SUPERADMIN_CONNECTIONS_UPDATE_SUCCESS_MSG = 'SUPERADMIN_CONNECTIONS_UPDATE_SUCCESS_MSG';
lang.SUPERADMIN_CONNECTIONS_D365_DESCRIPTION = 'SUPERADMIN_CONNECTIONS_D365_DESCRIPTION';
lang.SUPERADMIN_CONNECTIONS_SHAREPOINT_DESCRIPTION = 'SUPERADMIN_CONNECTIONS_SHAREPOINT_DESCRIPTION';
lang.MENU_ADMIN_TITLE = 'MENU_ADMIN_TITLE';
lang.MENU_ADMIN_USER_MANAGEMENT = 'MENU_ADMIN_USER_MANAGEMENT';
lang.MENU_ADMIN_ACTIVITY_LOG = 'MENU_ADMIN_ACTIVITY_LOG';
lang.MENU_ADMIN_STOCK_ITEMS = 'MENU_ADMIN_STOCK_ITEMS';
lang.MENU_ADMIN_SUPPLIERS = 'MENU_ADMIN_SUPPLIERS';
lang.MENU_ADMIN_ROAD_HAULIERS = 'MENU_ADMIN_ROAD_HAULIERS';
lang.MENU_ADMIN_YARD_LOCATION = 'MENU_ADMIN_YARD_LOCATION';
lang.MENU_ADMIN_PRINTERS = 'MENU_ADMIN_PRINTERS';
lang.MENU_ADMIN_LASERNET_PRINTERS = 'MENU_ADMIN_LASERNET_PRINTERS';
lang.MENU_ADMIN_DOCUMENT_TYPES = 'MENU_ADMIN_DOCUMENT_TYPES';
lang.MENU_ADMIN_CONFIGURATIONS = 'MENU_ADMIN_CONFIGURATIONS';
lang.MENU_ADMIN_SCALES = 'MENU_ADMIN_SCALES';
lang.MENU_ADMIN_MATERIALS = 'MENU_ADMIN_MATERIALS';
lang.MENU_ADMIN_APPLICATION = 'MENU_ADMIN_APPLICATION';
lang.MENU_ADMIN_DELIVERY_LIST = 'MENU_ADMIN_DELIVERY_LIST';
lang.MENU_ADMIN_MACHINES = 'MENU_ADMIN_MACHINES';
lang.MENU_ADMIN_TARGET_BATCHES = 'MENU_ADMIN_TARGET_BATCHES';
lang.MENU_ADMIN_PACKAGING = 'MENU_ADMIN_PACKAGING';
lang.MENU_ADMIN_ORIGINS = 'MENU_ADMIN_ORIGINS';
lang.MENU_ADMIN_EWCS = 'MENU_ADMIN_EWCS';
lang.MENU_SUPERADMIN_TENANTS = 'MENU_SUPERADMIN_TENANTS';
lang.LABORATORY_ADVISEGOODS_UPDATE_MSG = 'LABORATORY_ADVISEGOODS_UPDATE_MSG';
lang.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG = 'LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG';
lang.LABORATORY_ADVISEGOODS_DELIVERIES = 'LABORATORY_ADVISEGOODS_DELIVERIES';
lang.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION = 'LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION';
lang.LABORATORY_ADVISEGOODS_TENANT = 'LABORATORY_ADVISEGOODS_TENANT';
lang.LABORATORY_ADVISEGOODS_SORTING_REPORT = 'LABORATORY_ADVISEGOODS_SORTING_REPORT';
lang.LABORATORY_ADVISEGOODS_LAB_STATUSES = 'LABORATORY_ADVISEGOODS_LAB_STATUSES';
lang.LABORATORY_ADVISEGOODS_LAB_LIST = 'LABORATORY_ADVISEGOODS_LAB_LIST';
lang.LABORATORY_ANALISYS_HEADER = 'LABORATORY_ANALISYS_HEADER';
lang.LABORATORY_ANALISYS_MEASUREMENTS = 'LABORATORY_ANALISYS_MEASUREMENTS';
lang.LABORATORY_ANALISYS_UPDATE_STATUS_MSG = 'LABORATORY_ANALISYS_UPDATE_STATUS_MSG';
lang.LABORATORY_ANALISYS_WET_WEIGHT = 'LABORATORY_ANALISYS_WET_WEIGHT';
lang.LABORATORY_ANALISYS_DRY_WEIGHT = 'LABORATORY_ANALISYS_DRY_WEIGHT';
lang.LABORATORY_ANALISYS_INGOTE_WEIGHT = 'LABORATORY_ANALISYS_INGOTE_WEIGHT';
lang.LABORATORY_ANALISYS_YIELD = 'LABORATORY_ANALISYS_YIELD';
lang.LABORATORY_ANALISYS_SPECTRO_ANALYSIS = 'LABORATORY_ANALISYS_SPECTRO_ANALYSIS';
lang.LABORATORY_PRINTER_SUCCESS_MSG = 'LABORATORY_PRINTER_SUCCESS_MSG';
lang.LABORATORY_PRINTER_WARN_MSG = 'LABORATORY_PRINTER_WARN_MSG';
lang.LABORATORY_PRINTER_ERROR_MSG = 'LABORATORY_PRINTER_ERROR_MSG';
lang.LABORATORY_PRINTER_LABEL = 'LABORATORY_PRINTER_LABEL';
lang.LABORATORY_PRINTER_NO_PRINTERS = 'LABORATORY_PRINTER_NO_PRINTERS';
lang.LABORATORY_SCALE_CONNECTION_ERROR_MSG = 'LABORATORY_SCALE_CONNECTION_ERROR_MSG';
lang.LABORATORY_SCALE_SCALE_ERROR_MSG = 'LABORATORY_SCALE_SCALE_ERROR_MSG';
lang.LABORATORY_SCALE_FAKE_RESULT = 'LABORATORY_SCALE_FAKE_RESULT';
lang.DELIVERIES_LABORATORY_FILTER_BY = 'DELIVERIES_LABORATORY_FILTER_BY';
lang.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG = 'DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG = (name) => `DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG`;
lang.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG = 'DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG';
lang.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED = 'DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED';
lang.DELIVERIES_FILTER_SORT_BY = (name) => `DELIVERIES_FILTER_SORT_BY`;
lang.DELIVERIES_FILTER_GRN_SEARCH = 'DELIVERIES_FILTER_GRN_SEARCH';
lang.DELIVERIES_FILTER_ADVISED_GOOD_MSG = 'DELIVERIES_FILTER_ADVISED_GOOD_MSG';
lang.DELIVERIES_FILTER_DELIVERY_RESYNCED = 'DELIVERIES_FILTER_DELIVERY_RESYNCED';
lang.DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG = 'DELIVERIES_DELIVERYLIST_SIGNED_OFF_MSG';
lang.DELIVERIES_DELIVERYLIST_COMPLETED_MSG = 'DELIVERIES_DELIVERYLIST_COMPLETED_MSG';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF = 'DELIVERIES_DELIVERYLIST_SIGN_OFF';
lang.DELIVERIES_DELIVERYLIST_RESYNC_WB = 'DELIVERIES_DELIVERYLIST_RESYNC_WB';
lang.DELIVERIES_DELIVERYLIST_RESYNC_SR = 'DELIVERIES_DELIVERYLIST_RESYNC_SR';
lang.DELIVERIES_DELIVERYLIST_RESP = 'DELIVERIES_DELIVERYLIST_RESP';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER = 'DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_HEADER';
lang.DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT = (grn) => `DELIVERIES_DELIVERYLIST_SIGN_OFF_CONFIRMATION_TEXT`;
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER = 'DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_HEADER';
lang.DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT = (grn) => `DELIVERIES_DELIVERYLIST_COMPLETE_CONFIRMATION_TEXT`;
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER = 'DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_HEADER';
lang.DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT = (grn) => `DELIVERIES_DELIVERYLIST_EXPORT_CONFIRMATION_TEXT`;
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER = 'DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_HEADER';
lang.DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT = 'DELIVERIES_DELIVERYLIST_WEIGHT_DISCREPANCY_CONFIRMATION_TEXT';
lang.DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG = 'DELIVERIES_NEWDELIVERY_CREATE_ERROR_MSG';
lang.DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG = 'DELIVERIES_NEWDELIVERY_CREATE_SUCCESS_MSG';
lang.DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG = 'DELIVERIES_NEWDELIVERY_ATTACHMENT_ERROR_MSG';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER = 'DELIVERIES_NEWDELIVERYDETAILS_CONTRACT_NUMBER';
lang.DELIVERIES_NEWDELIVERYDETAILS_GRN = 'DELIVERIES_NEWDELIVERYDETAILS_GRN';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW = 'DELIVERIES_NEWDELIVERYDETAILS_CREATE_NEW';
lang.DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER = 'DELIVERIES_NEWDELIVERYDETAILS_DELIVERY_NUMBER';
lang.DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER = 'DELIVERIES_NEWDELIVERYDETAILS_REGISTRATION_NUMBER';
lang.DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER = 'DELIVERIES_NEWDELIVERYDETAILS_CONTAINER_NUMBER';
lang.DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER = 'DELIVERIES_NEWDELIVERYDETAILS_ROAD_HAULIER';
lang.DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS = 'DELIVERIES_NEWDELIVERYDETAILS_NO_ATTACHMENTS';
lang.DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE = 'DELIVERIES_NEWDELIVERYDETAILS_RESPONSIBLE_PEOPLE';
lang.DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY = 'DELIVERIES_NEWDELIVERYDETAILS_CREATED_BY';
lang.DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY = 'DELIVERIES_NEWDELIVERYDETAILS_SIGNED_OFF_BY';
lang.DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY = 'DELIVERIES_NEWDELIVERYDETAILS_SYNCED_BY';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY = 'DELIVERIES_NEWDELIVERYDETAILS_COMPLETED_BY';
lang.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM = 'DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION = 'DELIVERIES_NEWDELIVERYDETAILS_ADV_DESCRIPTION';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT = 'DELIVERIES_NEWDELIVERYDETAILS_ADV_WEIGHT';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT = 'DELIVERIES_NEWDELIVERYDETAILS_ADV_GROSS_WEIGHT';
lang.DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT = 'DELIVERIES_NEWDELIVERYDETAILS_ADV_NET_WEIGHT';
lang.DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION = 'DELIVERIES_NEWDELIVERYDETAILS_YARD_LOCATION';
lang.DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT = 'DELIVERIES_NEWDELIVERYDETAILS_GROSS_WEIGHT';
lang.DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT = 'DELIVERIES_NEWDELIVERYDETAILS_TARE_WEIGHT';
lang.DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET = 'DELIVERIES_NEWDELIVERYDETAILS_WB_TICKET';
lang.DELIVERIES_NEWDELIVERYDETAILS_NET = 'DELIVERIES_NEWDELIVERYDETAILS_NET';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER = 'DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_HEADER';
lang.DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT = 'DELIVERIES_NEWDELIVERYDETAILS_SAVE_CHANGES_CONFIRMATION_TEXT';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER = 'DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_HEADER';
lang.DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT = 'DELIVERIES_NEWDELIVERYDETAILS_COMPLETE_CONFIRMATION_TEXT';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER';
lang.DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'DELIVERIES_NEWDELIVERYDETAILS_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER = 'DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_HEADER';
lang.DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT = 'DELIVERIES_NEWDELIVERYDETAILS_CERTIFICATE_CONFIRMATION_TEXT';
lang.DELIVERIES_NEWDELIVERYDETAILS_ORIGIN = 'DELIVERIES_NEWDELIVERYDETAILS_ORIGIN';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER = 'DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_HEADER';
lang.DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT = 'DELIVERIES_NEWDELIVERYDETAILS_AUTHORIZATION_NUMBER_CONFIRMATION_TEXT';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE = 'DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE = 'DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRADE_PAYABLE';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER = 'DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_TRANSFER';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS = 'DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_RETURN_OF_REJECTS';
lang.DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID = 'DELIVERIES_NEWDELIVERYDETAILS_TRANSACTION_TYPE_VOID';
lang.DELIVERIES_DELIVERYDETAILSLINE_HEADER = 'DELIVERIES_DELIVERYDETAILSLINE_HEADER';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION = 'DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT = 'DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT';
lang.DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE = 'DELIVERIES_DELIVERYDETAILSLINE_ORDER_LINE_SEQUENCE';
lang.DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX = 'DELIVERIES_DELIVERYDETAILSLINE_ADVISED_GOOD_EXTERNAL_INDEX';
lang.DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG = 'DELIVERIES_DELIVERYEDIT_UPDATE_SUCCES_MSG';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG = 'DELIVERIES_DELIVERYEDIT_ATTACHMENT_SUCCESS_MSG';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG = 'DELIVERIES_DELIVERYEDIT_ATTACHMENT_ERROR_MSG';
lang.DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG = 'DELIVERIES_DELIVERYEDIT_ATTACHMENT_REMOVED_MSG';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION = 'DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_HEADER_CONFIRMATION';
lang.DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION = 'DELIVERIES_DELIVERYEDIT_DELETE_ATTACHMENT_TEXT_CONFIRMATION';
lang.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG = (deliveryGrn) => `DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG`;
lang.DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG = (codes) => `DELIVERIES_DELIVERYEDIT_DO_NOT_MATCH_CONTRACT_STOCK_CODES_ERROR_MSG`;
lang.DELIVERIES_DELIVERYEDIT_DELETE = 'DELIVERIES_DELIVERYEDIT_DELETE';
lang.DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION = 'DELIVERIES_DELIVERYEDIT_DELETE_CONFIRMATION';
lang.DELIVERIES_DELIVERYEDIT_DELETED = (name) => `DELIVERIES_DELIVERYEDIT_DELETED`;
lang.ADVISEDGOOD_ROUTE_EWC_NUMBER = 'ADVISEDGOOD_ROUTE_EWC_NUMBER';
lang.ADVISEDGOOD_ROUTE_DRIVER = 'ADVISEDGOOD_ROUTE_DRIVER';
lang.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG = 'ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG';
lang.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED = (entity) => `ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED`;
lang.ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG = 'ADVISEDGOOD_ROUTE_FLAG_ERROR_MSG';
lang.ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG = 'ADVISEDGOOD_ROUTE_FLAG_SUCCESS_MSG';
lang.ADVISEDGOOD_ROUTE_COMPLETED_MSG = 'ADVISEDGOOD_ROUTE_COMPLETED_MSG';
lang.ADVISEDGOOD_ROUTE_VERIFIED_MSG = 'ADVISEDGOOD_ROUTE_VERIFIED_MSG';
lang.ADVISEDGOOD_ROUTE_LAB_MSG = 'ADVISEDGOOD_ROUTE_LAB_MSG';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG = 'ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG = 'ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG';
lang.ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG = 'ADVISEDGOOD_ROUTE_SAVE_BEFORE_PRINT_MSG';
lang.ADVISEDGOOD_ROUTE_STATUS_ROLLBACK = 'ADVISEDGOOD_ROUTE_STATUS_ROLLBACK';
lang.ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION = 'ADVISEDGOOD_ROUTE_VEHICLE_REGISTRATION';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT = 'ADVISEDGOOD_ROUTE_GROSS_WEIGHT';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT = 'ADVISEDGOOD_ROUTE_TARE_WEIGHT';
lang.ADVISEDGOOD_ROUTE_NET_WEIGHT = 'ADVISEDGOOD_ROUTE_NET_WEIGHT';
lang.ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED = 'ADVISEDGOOD_ROUTE_GROSS_WEIGHT_REQUIRED';
lang.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT = 'ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT';
lang.ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED = 'ADVISEDGOOD_ROUTE_TARE_WEIGHT_REQUIRED';
lang.ADVISEDGOOD_ROUTE_SORTING_REPORT = 'ADVISEDGOOD_ROUTE_SORTING_REPORT';
lang.ADVISEDGOOD_ROUTE_BALANCED = 'ADVISEDGOOD_ROUTE_BALANCED';
lang.ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG = 'ADVISEDGOOD_ROUTE_ZIP_DOWNLOADED_MSG';
lang.ADVISEDGOOD_ROUTE_PICTURES = 'ADVISEDGOOD_ROUTE_PICTURES';
lang.ADVISEDGOOD_ROUTE_NO_PICTURE = 'ADVISEDGOOD_ROUTE_NO_PICTURE';
lang.ADVISEDGOOD_ROUTE_UPLOADING_PICTURES = 'ADVISEDGOOD_ROUTE_UPLOADING_PICTURES';
lang.ADVISEDGOOD_ROUTE_COMMENT = 'ADVISEDGOOD_ROUTE_COMMENT';
lang.ADVISEDGOOD_ROUTE_NO_COMMENT = 'ADVISEDGOOD_ROUTE_NO_COMMENT';
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER = (hasFlag) => `ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_HEADER`;
lang.ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `ADVISEDGOOD_ROUTE_FLAG_CONFIRMATION_TEXT`;
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_COMPLETE_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_VERIFIED_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_VERIFIED_WARNING_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_CLAIMED_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_UNLOADED_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_ERROR = 'ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_ERROR';
lang.ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_WARNING = 'ADVISEDGOOD_ROUTE_UNLOADED_ATTACHMENTS_WARNING';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS = 'ADVISEDGOOD_ROUTE_SORTING_TOTAL_GROSS';
lang.ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET = 'ADVISEDGOOD_ROUTE_SORTING_TOTAL_NET';
lang.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG = 'ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG';
lang.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG = 'ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG';
lang.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG = 'ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG';
lang.ADVISEDGOOD_ROUTE_DELIVERY_TYPE = 'ADVISEDGOOD_ROUTE_DELIVERY_TYPE';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_LOCATION_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION = 'ADVISEDGOOD_ROUTE_UNLOAD_LOCATION';
lang.ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR = 'ADVISEDGOOD_ROUTE_VERIFY_WEIGHT_CHECK_ERROR';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_UNCLAIM_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_UNCLAIMED_MSG = 'ADVISEDGOOD_ROUTE_UNCLAIMED_MSG';
lang.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE = 'ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE';
lang.ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT = 'ADVISEDGOOD_ROUTE_TRUCK_RECEIVED_WEIGHT';
lang.ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL = 'ADVISEDGOOD_ROUTE_TRUCK_SORTING_TOTAL';
lang.ADVISEDGOOD_ROUTE_PACKAGING = 'ADVISEDGOOD_ROUTE_PACKAGING';
lang.ADVISEDGOOD_ROUTE_NO_PACKAGING = 'ADVISEDGOOD_ROUTE_NO_PACKAGING';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG = 'ADVISEDGOOD_ROUTE_SIGN_OFF_AG';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_SIGN_OFF_AG_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE = 'ADVISEDGOOD_ROUTE_SIGN_OFF_AG_SUCCESS_MESSAGE';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER = 'ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT = 'ADVISEDGOOD_ROUTE_OVERWRITE_RG_DESCRIPTION_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_ROUTE_DELETE = 'ADVISEDGOOD_ROUTE_DELETE';
lang.ADVISEDGOOD_ROUTE_DELETE_CONFIRMATION = 'ADVISEDGOOD_ROUTE_DELETE_CONFIRMATION';
lang.ADVISEDGOOD_ROUTE_DELETED = (name) => `ADVISEDGOOD_ROUTE_DELETED`;
lang.ADVISEDGOOD_SORTINGREPORT_HEADER = 'ADVISEDGOOD_SORTINGREPORT_HEADER';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG = 'ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG = 'ADVISEDGOOD_SORTINGREPORT_TRANSFER_SUCCESS_MSG';
lang.ADVISEDGOOD_SORTINGREPORT_STOCK_CODE = 'ADVISEDGOOD_SORTINGREPORT_STOCK_CODE';
lang.ADVISEDGOOD_SORTINGREPORT_TRANSFER = 'ADVISEDGOOD_SORTINGREPORT_TRANSFER';
lang.ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS = 'ADVISEDGOOD_SORTINGREPORT_LAB_ANALYSIS';
lang.ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS = 'ADVISEDGOOD_SORTINGREPORT_NO_LAB_ANALYSIS';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL = 'ADVISEDGOOD_SORTINGREPORT_MATERIAL';
lang.ADVISEDGOOD_SORTINGREPORT_WI_DONE = 'ADVISEDGOOD_SORTINGREPORT_WI_DONE';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER = 'ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_HEADER';
lang.ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT = 'ADVISEDGOOD_SORTINGREPORT_CREATE_LAB_ANALYSIS_TEXT';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER = 'ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_HEADER';
lang.ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT = (labAnalysisName) => `ADVISEDGOOD_SORTINGREPORT_DELETE_LAB_ANALYSIS_TEXT`;
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE = 'ADVISEDGOOD_SORTINGREPORT_MACHINE';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH = 'ADVISEDGOOD_SORTINGREPORT_TARGET_BATCH';
lang.ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY = 'ADVISEDGOOD_SORTINGREPORT_BULK_DENSITY';
lang.ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY = 'ADVISEDGOOD_SORTINGREPORT_FOUND_QUALITY';
lang.ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE = 'ADVISEDGOOD_SORTINGREPORT_MAIN_TYPE';
lang.ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION = 'ADVISEDGOOD_SORTINGREPORT_MATERIAL_DESCRIPTION';
lang.ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG = (emptyRequiredField) => `ADVISEDGOOD_SORTINGREPORT_EMPTY_REQUIRED_MSG`;
lang.ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG = 'ADVISEDGOOD_SORTINGREPORT_SAVE_BEFORE_ADD_PICTURE_MSG';
lang.ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE = 'ADVISEDGOOD_SORTINGREPORT_MACHINE_USAGE';
lang.ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION = 'ADVISEDGOOD_SORTINGREPORT_TARGET_LOCATION';
lang.ADVISEDGOOD_LABANALYSIS_LAB_REMARKS = 'ADVISEDGOOD_LABANALYSIS_LAB_REMARKS';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER = 'ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_HEADER';
lang.ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT = 'ADVISEDGOOD_GALERY_DELETE_CONFIRMATION_TEXT';
lang.ADVISEDGOOD_DEDUCTION_FREE_WATER = 'ADVISEDGOOD_DEDUCTION_FREE_WATER';
lang.ADVISEDGOOD_DEDUCTION_FREE_OIL = 'ADVISEDGOOD_DEDUCTION_FREE_OIL';
lang.ADVISEDGOOD_DEDUCTION_FREE_EMULSION = 'ADVISEDGOOD_DEDUCTION_FREE_EMULSION';
lang.ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE = 'ADVISEDGOOD_DEDUCTION_FREE_SNOW_ICE';
lang.ADVISEDGOOD_DEDUCTION_NO_DEDUCTION = 'ADVISEDGOOD_DEDUCTION_NO_DEDUCTION';
lang.ADVISEDGOOD_DEDUCTION_DEDUCTION = 'ADVISEDGOOD_DEDUCTION_DEDUCTION';
lang.ADVISEDGOOD_DEDUCTION_MODAL_HEADER = 'ADVISEDGOOD_DEDUCTION_MODAL_HEADER';
lang.ADVISEDGOOD_UNLOAD_TITLE = 'ADVISEDGOOD_UNLOAD_TITLE';
lang.ADVISEDGOOD_UNLOAD_UPDATE_MSG = 'ADVISEDGOOD_UNLOAD_UPDATE_MSG';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE = 'RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TITLE';
lang.RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT = 'RECEIVED_GOOD_WEIGHT_NEGATIVE_CONFIRMATION_TEXT';
lang.RECEIVED_GOOD_FLAG_ERROR_MSG = 'RECEIVED_GOOD_FLAG_ERROR_MSG';
lang.RECEIVED_GOOD_FLAG_SUCCESS_MSG = 'RECEIVED_GOOD_FLAG_SUCCESS_MSG';
lang.RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT = (className, hasFlag) => `RECEIVED_GOOD_FLAG_CONFIRMATION_TEXT`;
lang.RECEIVED_GOOD_CONTAMINATION_TITLE = 'RECEIVED_GOOD_CONTAMINATION_TITLE';
lang.RECEIVED_GOOD_CONTAMINATION_OTHER = 'RECEIVED_GOOD_CONTAMINATION_OTHER';
lang.RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION = 'RECEIVED_GOOD_CONTAMINATION_NO_CONTAMINATION';
lang.RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH = 'RECEIVED_GOOD_CONTAMINATION_NON_METALLIC_ATTACH';
lang.RECEIVED_GOOD_CONTAMINATION_WATER = 'RECEIVED_GOOD_CONTAMINATION_WATER';
lang.RECEIVED_GOOD_CONTAMINATION_FREE_OIL = 'RECEIVED_GOOD_CONTAMINATION_FREE_OIL';
lang.RECEIVED_GOOD_CONTAMINATION_DIRT = 'RECEIVED_GOOD_CONTAMINATION_DIRT';
lang.RECEIVED_GOOD_CONTAMINATION_WOOD = 'RECEIVED_GOOD_CONTAMINATION_WOOD';
lang.RECEIVED_GOOD_CONTAMINATION_PLASTIC = 'RECEIVED_GOOD_CONTAMINATION_PLASTIC';
lang.RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL = 'RECEIVED_GOOD_CONTAMINATION_GLASS_WOOL';
lang.RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG = 'RECEIVED_GOOD_CONTAMINATION_BURNING_SLAG';
lang.RECEIVED_GOOD_CONTAMINATION_BITUMEN = 'RECEIVED_GOOD_CONTAMINATION_BITUMEN';
lang.RECEIVED_GOOD_CONTAMINATION_OIL_CREASE = 'RECEIVED_GOOD_CONTAMINATION_OIL_CREASE';
lang.RECEIVED_GOOD_CONTAMINATION_CARDBOARD = 'RECEIVED_GOOD_CONTAMINATION_CARDBOARD';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH = 'RECEIVED_GOOD_CONTAMINATION_METALLIC_ATTACH';
lang.RECEIVED_GOOD_CONTAMINATION_IRON = 'RECEIVED_GOOD_CONTAMINATION_IRON';
lang.RECEIVED_GOOD_CONTAMINATION_MN_STEEL = 'RECEIVED_GOOD_CONTAMINATION_MN_STEEL';
lang.RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL = 'RECEIVED_GOOD_CONTAMINATION_10ER_CR_STEEL';
lang.RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL = 'RECEIVED_GOOD_CONTAMINATION_13ER_CR_STEEL';
lang.RECEIVED_GOOD_CONTAMINATION_301 = 'RECEIVED_GOOD_CONTAMINATION_301';
lang.RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL = 'RECEIVED_GOOD_CONTAMINATION_17ER_CR_STEEL';
lang.RECEIVED_GOOD_CONTAMINATION_SHREDDER = 'RECEIVED_GOOD_CONTAMINATION_SHREDDER';
lang.RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT = 'RECEIVED_GOOD_CONTAMINATION_INCINERATOR_MAT';
lang.RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS = 'RECEIVED_GOOD_CONTAMINATION_DIFFERENT_METALS';
lang.RECEIVED_GOOD_CONTAMINATION_CU = 'RECEIVED_GOOD_CONTAMINATION_CU';
lang.RECEIVED_GOOD_CONTAMINATION_AL = 'RECEIVED_GOOD_CONTAMINATION_AL';
lang.RECEIVED_GOOD_CONTAMINATION_MS = 'RECEIVED_GOOD_CONTAMINATION_MS';
lang.RECEIVED_GOOD_CONTAMINATION_PB = 'RECEIVED_GOOD_CONTAMINATION_PB';
lang.RECEIVED_GOOD_CONTAMINATION_TI = 'RECEIVED_GOOD_CONTAMINATION_TI';
lang.RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER = 'RECEIVED_GOOD_CONTAMINATION_STST_CU_COOLER';
lang.RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING = 'RECEIVED_GOOD_CONTAMINATION_MAT_FOR_SHREDDING';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS = 'RECEIVED_GOOD_CONTAMINATION_TURNINGS_IN_SOLIDS';
lang.RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR = 'RECEIVED_GOOD_CONTAMINATION_CR_STEEL_TUR';
lang.RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR = 'RECEIVED_GOOD_CONTAMINATION_LOW_ALLOYED_TUR';
lang.RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS = 'RECEIVED_GOOD_CONTAMINATION_STST_TURNINGS';
lang.RECEIVED_GOOD_CONTAMINATION_304_TURNINGS = 'RECEIVED_GOOD_CONTAMINATION_304_TURNINGS';
lang.RECEIVED_GOOD_CONTAMINATION_316_TURNINGS = 'RECEIVED_GOOD_CONTAMINATION_316_TURNINGS';
lang.RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE = 'RECEIVED_GOOD_CONTAMINATION_NOT_IN_FURNACE_SIZE';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR = 'RECEIVED_GOOD_CONTAMINATION_FOR_MOBILE_SHEAR';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR = 'RECEIVED_GOOD_CONTAMINATION_FOR_STATIONARY_SHEAR';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING = 'RECEIVED_GOOD_CONTAMINATION_FOR_TORCH_CUTTING';
lang.RECEIVED_GOOD_CONTAMINATION_FOR_PRESS = 'RECEIVED_GOOD_CONTAMINATION_FOR_PRESS';
lang.RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY = 'RECEIVED_GOOD_CONTAMINATION_NO_AGGREGATE_NECESSARY';
lang.RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES = 'RECEIVED_GOOD_CONTAMINATION_CONSISTS_HOLLOW_BODIES';
lang.RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS = 'RECEIVED_GOOD_CONTAMINATION_MATERIAL_FORMS';
lang.RECEIVED_GOOD_CONTAMINATION_PACKETS = 'RECEIVED_GOOD_CONTAMINATION_PACKETS';
lang.RECEIVED_GOOD_CONTAMINATION_BRIQUETTES = 'RECEIVED_GOOD_CONTAMINATION_BRIQUETTES';
lang.RECEIVED_GOOD_CONTAMINATION_INGOTS = 'RECEIVED_GOOD_CONTAMINATION_INGOTS';
lang.RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL = 'RECEIVED_GOOD_CONTAMINATION_FINE_MATERIAL';
lang.RECEIVED_GOOD_CONTAMINATION_RUNNINGS = 'RECEIVED_GOOD_CONTAMINATION_RUNNINGS';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND = 'RECEIVED_GOOD_CONTAMINATION_TARE_INBOUND';
lang.RECEIVED_GOOD_CONTAMINATION_TOTAL = 'RECEIVED_GOOD_CONTAMINATION_TOTAL';
lang.RECEIVED_GOOD_CONTAMINATION_DRUMS = 'RECEIVED_GOOD_CONTAMINATION_DRUMS';
lang.RECEIVED_GOOD_CONTAMINATION_BOXES = 'RECEIVED_GOOD_CONTAMINATION_BOXES';
lang.RECEIVED_GOOD_CONTAMINATION_GRID_BOXES = 'RECEIVED_GOOD_CONTAMINATION_GRID_BOXES';
lang.RECEIVED_GOOD_CONTAMINATION_BIGBAGS = 'RECEIVED_GOOD_CONTAMINATION_BIGBAGS';
lang.RECEIVED_GOOD_CONTAMINATION_PALLETS = 'RECEIVED_GOOD_CONTAMINATION_PALLETS';
lang.RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND = 'RECEIVED_GOOD_CONTAMINATION_TARE_OUTBOUND';
lang.RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION = 'RECEIVED_GOOD_CONTAMINATION_TURNINGS_COMPOSITION';
lang.RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT = 'RECEIVED_GOOD_CONTAMINATION_MAGNETIC_CONTENT';
lang.RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING = 'RECEIVED_GOOD_CONTAMINATION_METALLIC_TURNING';
lang.RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING = 'RECEIVED_GOOD_CONTAMINATION_GRINDING_TURNING';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES = 'RECEIVED_GOOD_CONTAMINATION_SIZES';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_0_15 = 'RECEIVED_GOOD_CONTAMINATION_SIZE_0_15';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_16_35 = 'RECEIVED_GOOD_CONTAMINATION_SIZE_16_35';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_36_60 = 'RECEIVED_GOOD_CONTAMINATION_SIZE_36_60';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_61_80 = 'RECEIVED_GOOD_CONTAMINATION_SIZE_61_80';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_81_120 = 'RECEIVED_GOOD_CONTAMINATION_SIZE_81_120';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_121_150 = 'RECEIVED_GOOD_CONTAMINATION_SIZE_121_150';
lang.RECEIVED_GOOD_CONTAMINATION_SIZE_151_999 = 'RECEIVED_GOOD_CONTAMINATION_SIZE_151_999';
lang.RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING = 'RECEIVED_GOOD_CONTAMINATION_SIZES_WARNING';
lang.RECEIVED_GOOD_CONTAMINATION_PACKAGING = 'RECEIVED_GOOD_CONTAMINATION_PACKAGING';
lang.SORTINGREPORT_CLASSIFICATION_TITLE = 'SORTINGREPORT_CLASSIFICATION_TITLE';
lang.SORTINGREPORT_CLASSIFICATION_VERY_LIGHT = 'SORTINGREPORT_CLASSIFICATION_VERY_LIGHT';
lang.SORTINGREPORT_CLASSIFICATION_LIGHT = 'SORTINGREPORT_CLASSIFICATION_LIGHT';
lang.SORTINGREPORT_CLASSIFICATION_MIDDLE = 'SORTINGREPORT_CLASSIFICATION_MIDDLE';
lang.SORTINGREPORT_CLASSIFICATION_HEAVY = 'SORTINGREPORT_CLASSIFICATION_HEAVY';
lang.SORTINGREPORT_CLASSIFICATION_VERY_HEAVY = 'SORTINGREPORT_CLASSIFICATION_VERY_HEAVY';
lang.SORTINGREPORT_CLASSIFICATION_FE_SDS = 'SORTINGREPORT_CLASSIFICATION_FE_SDS';
lang.SORTINGREPORT_CLASSIFICATION_CR_SDS = 'SORTINGREPORT_CLASSIFICATION_CR_SDS';
lang.SORTINGREPORT_CLASSIFICATION_V13F_SDS = 'SORTINGREPORT_CLASSIFICATION_V13F_SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS = 'SORTINGREPORT_CLASSIFICATION_FQ_1_4510_SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS = 'SORTINGREPORT_CLASSIFICATION_FQ_1_4512_SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS = 'SORTINGREPORT_CLASSIFICATION_FQ_17ER_CR_SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS = 'SORTINGREPORT_CLASSIFICATION_FQ_1_4016_SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS = 'SORTINGREPORT_CLASSIFICATION_FQ_1_4509_SDS';
lang.SORTINGREPORT_CLASSIFICATION_FQ_304_SDS = 'SORTINGREPORT_CLASSIFICATION_FQ_304_SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_SDS = 'SORTINGREPORT_CLASSIFICATION_CRNI_SDS';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS = 'SORTINGREPORT_CLASSIFICATION_CRNIMO_SDS';
lang.SORTINGREPORT_CLASSIFICATION_FE_TUR = 'SORTINGREPORT_CLASSIFICATION_FE_TUR';
lang.SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR = 'SORTINGREPORT_CLASSIFICATION_CR_CRNI_TUR';
lang.SORTINGREPORT_CLASSIFICATION_CRNI_TUR = 'SORTINGREPORT_CLASSIFICATION_CRNI_TUR';
lang.SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR = 'SORTINGREPORT_CLASSIFICATION_CRNIMO_TUR';
lang.SORTINGREPORT_CLASSIFICATION_OTHER = 'SORTINGREPORT_CLASSIFICATION_OTHER';
lang.SORTINGREPORT_CLASSIFICATION_SHREDDER = 'SORTINGREPORT_CLASSIFICATION_SHREDDER';
lang.SORTINGREPORT_CLASSIFICATION_SOLIDS = 'SORTINGREPORT_CLASSIFICATION_SOLIDS';
lang.SORTINGREPORT_CLASSIFICATION_TURNINGS = 'SORTINGREPORT_CLASSIFICATION_TURNINGS';
lang.SORTINGREPORT_CLASSIFICATION_PACKAGE = 'SORTINGREPORT_CLASSIFICATION_PACKAGE';
lang.SORTINGREPORT_CLASSIFICATION_BRIKETTS = 'SORTINGREPORT_CLASSIFICATION_BRIKETTS';
lang.SORTINGREPORT_CLASSIFICATION_INGOTS = 'SORTINGREPORT_CLASSIFICATION_INGOTS';
lang.SORTINGREPORT_CLASSIFICATION_FINES = 'SORTINGREPORT_CLASSIFICATION_FINES';
lang.SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION = 'SORTINGREPORT_CLASSIFICATION_WASTE_INCINERATION';
lang.SORTINGREPORT_CLASSIFICATION_RUNNINGS = 'SORTINGREPORT_CLASSIFICATION_RUNNINGS';
lang.SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP = 'SORTINGREPORT_CLASSIFICATION_DEMOLITION_SCRAP';
lang.SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP = 'SORTINGREPORT_CLASSIFICATION_PRODUCTION_SCRAP';
lang.SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP = 'SORTINGREPORT_CLASSIFICATION_COLLECTION_SCRAP';
lang.SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP = 'SORTINGREPORT_CLASSIFICATION_SHEARING_SCRAP';
lang.SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS = 'SORTINGREPORT_CLASSIFICATION_TURNING_WITH_SOLIDS';
lang.SORTINGREPORT_PROCESSING_TIME_TITLE = 'SORTINGREPORT_PROCESSING_TIME_TITLE';
lang.SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS = 'SORTINGREPORT_PROCESSING_TIME_CUTTING_HOURS';
lang.SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS = 'SORTINGREPORT_PROCESSING_TIME_SPIDER_HOURS';
lang.SORTINGREPORT_PROCESSING_TIME_CART_HOURS = 'SORTINGREPORT_PROCESSING_TIME_CART_HOURS';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS = 'SORTINGREPORT_PROCESSING_TIME_OPERATOR_HOURS';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES = 'SORTINGREPORT_PROCESSING_TIME_UNIT_MINUTES';
lang.SORTINGREPORT_PROCESSING_TIME_UNIT_TONS = 'SORTINGREPORT_PROCESSING_TIME_UNIT_TONS';
lang.SORTINGREPORT_PROCESSING_TITLE = 'SORTINGREPORT_PROCESSING_TITLE';
lang.SORTINGREPORT_PROCESSING_TIME_OPERATOR = 'SORTINGREPORT_PROCESSING_TIME_OPERATOR';
lang.SORTINGREPORT_PROCESSING_TIME_FORKLIFT = 'SORTINGREPORT_PROCESSING_TIME_FORKLIFT';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE = 'SORTINGREPORT_PROCESSING_TIME_CRANE';
lang.SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING = 'SORTINGREPORT_PROCESSING_TIME_TORCH_CUTTING';
lang.SORTINGREPORT_PROCESSING_TIME_PLASMA = 'SORTINGREPORT_PROCESSING_TIME_PLASMA';
lang.SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR = 'SORTINGREPORT_PROCESSING_TIME_CRANE_SHEAR';
lang.SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR = 'SORTINGREPORT_PROCESSING_TIME_GUILLOTINE_SHEAR';
lang.SORTINGREPORT_PROCESSING_TIME_DENSIFIER = 'SORTINGREPORT_PROCESSING_TIME_DENSIFIER';
lang.SORTINGREPORT_PROCESSING_TIME_BALER = 'SORTINGREPORT_PROCESSING_TIME_BALER';
lang.SORTINGREPORT_MEASUREMENT_TITLE = 'SORTINGREPORT_MEASUREMENT_TITLE';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_WET = 'SORTINGREPORT_MEASUREMENT_SAMPLE_WET';
lang.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY = 'SORTINGREPORT_MEASUREMENT_SAMPLE_DRY';
lang.SORTINGREPORT_MEASUREMENT_HUMIDITY = 'SORTINGREPORT_MEASUREMENT_HUMIDITY';
lang.SORTINGREPORT_MEASUREMENT_FOR_MELTING = 'SORTINGREPORT_MEASUREMENT_FOR_MELTING';
lang.SORTINGREPORT_MEASUREMENT_INGOT = 'SORTINGREPORT_MEASUREMENT_INGOT';
lang.SORTINGREPORT_MEASUREMENT_YIELD = 'SORTINGREPORT_MEASUREMENT_YIELD';
lang.SORTINGREPORT_MEASUREMENT_CONTAINER = 'SORTINGREPORT_MEASUREMENT_CONTAINER';
lang.SORTINGREPORT_LASERNET_COPIES = 'SORTINGREPORT_LASERNET_COPIES';
lang.SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE = (numberOfCopies) => `SORTINGREPORT_LASERNET_PRINTER_SUCCESS_MESSAGE`;
lang.SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE = 'SORTINGREPORT_LASERNET_PRINTER_ERROR_MESSAGE';
lang.STATUS_LABINPUT_PRE_SAMPLE = 'STATUS_LABINPUT_PRE_SAMPLE';
lang.STATUS_LABINPUT_SAMPLE = 'STATUS_LABINPUT_SAMPLE';
lang.STATUS_LABINPUT_RE_SAMPLE = 'STATUS_LABINPUT_RE_SAMPLE';
lang.STATUS_LABINPUT_SORTATION = 'STATUS_LABINPUT_SORTATION';
lang.STATUS_LABINPUT_NITON_ANALYSIS = 'STATUS_LABINPUT_NITON_ANALYSIS';
lang.STATUS_LABSTATUSES_IN_LAB = 'STATUS_LABSTATUSES_IN_LAB';
lang.STATUS_LABSTATUSES_DRYING = 'STATUS_LABSTATUSES_DRYING';
lang.STATUS_LABSTATUSES_MELTING = 'STATUS_LABSTATUSES_MELTING';
lang.STATUS_LABSTATUSES_MELTED = 'STATUS_LABSTATUSES_MELTED';
lang.STATUS_LABSTATUSES_COMPLETE = 'STATUS_LABSTATUSES_COMPLETE';
lang.STATUS_LABSTATUSES_REQUESTED = 'STATUS_LABSTATUSES_REQUESTED';
lang.STATUS_LABSTATUSES_SAMPLED = 'STATUS_LABSTATUSES_SAMPLED';
lang.STATUS_LABSTATUSES_IN_PROGRESS = 'STATUS_LABSTATUSES_IN_PROGRESS';
lang.STATUS_DELIVERYSTATUSES_IN_WORK = 'STATUS_DELIVERYSTATUSES_IN_WORK';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2 = 'STATUS_DELIVERYSTATUSES_READY_FOR_TIER_2';
lang.STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF = 'STATUS_DELIVERYSTATUSES_READY_FOR_SIGN_OFF';
lang.STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF = 'STATUS_DELIVERYSTATUSES_PARTIALLY_SIGNED_OFF';
lang.STATUS_DELIVERYSTATUSES_SIGNED_OFF = 'STATUS_DELIVERYSTATUSES_SIGNED_OFF';
lang.STATUS_DELIVERYSTATUSES_COMPLETE = 'STATUS_DELIVERYSTATUSES_COMPLETE';
lang.STATUS_ADVISEDGOOD_WAITING = 'STATUS_ADVISEDGOOD_WAITING';
lang.STATUS_ADVISEDGOOD_CLAIMED = 'STATUS_ADVISEDGOOD_CLAIMED';
lang.STATUS_ADVISEDGOOD_UNLOADED = 'STATUS_ADVISEDGOOD_UNLOADED';
lang.STATUS_ADVISEDGOOD_IN_LAB = 'STATUS_ADVISEDGOOD_IN_LAB';
lang.STATUS_ADVISEDGOOD_READY_FOR_TIER_2 = 'STATUS_ADVISEDGOOD_READY_FOR_TIER_2';
lang.STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF = 'STATUS_ADVISEDGOOD_READY_FOR_SIGN_OFF';
lang.STATUS_ADVISEDGOOD_SIGNED_OFF = 'STATUS_ADVISEDGOOD_SIGNED_OFF';
lang.STATUS_ADVISEDGOOD_COMPLETE = 'STATUS_ADVISEDGOOD_COMPLETE';
lang.DASHBOARD_TITLES_DASHBOARD = 'DASHBOARD_TITLES_DASHBOARD';
lang.DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS = 'DASHBOARD_TITLES_DELIVERIES_IN_PROGRESS';
lang.DASHBOARD_TITLES_DELIVERY_BY_STATUS = 'DASHBOARD_TITLES_DELIVERY_BY_STATUS';
lang.DASHBOARD_TITLES_AVERAGE_SORTING_TIME = 'DASHBOARD_TITLES_AVERAGE_SORTING_TIME';
lang.DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME = 'DASHBOARD_TITLES_TURN_AROUND_SORTING_TIME';
lang.DASHBOARD_TITLES_SORTING_TIME_TREND = 'DASHBOARD_TITLES_SORTING_TIME_TREND';
lang.DASHBOARD_TITLES_FLAGGED_ITEMS = 'DASHBOARD_TITLES_FLAGGED_ITEMS';
lang.DASHBOARD_TITLES_TREES_SAVED = 'DASHBOARD_TITLES_TREES_SAVED';
lang.DASHBOARD_TITLES_SORTING_TIME_Y_LABEL = 'DASHBOARD_TITLES_SORTING_TIME_Y_LABEL';
lang.DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL = 'DASHBOARD_TITLES_TURN_AROUND_TIME_Y_LABEL';
lang.DASHBOARD_TITLES_SORTING_TIME_TOOLTIP = 'DASHBOARD_TITLES_SORTING_TIME_TOOLTIP';
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTHS = (condition) => `DASHBOARD_TITLES_IN_PROGRESS_MONTHS`;
lang.DASHBOARD_TITLES_IN_PROGRESS_MONTH = (condition) => `DASHBOARD_TITLES_IN_PROGRESS_MONTH`;
lang.DASHBOARD_TITLES_IN_PROGRESS_WEEK = (condition) => `DASHBOARD_TITLES_IN_PROGRESS_WEEK`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS = (condition) => `DASHBOARD_TITLES_ITEMS_FLAGGED_MONTHS`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH = (condition) => `DASHBOARD_TITLES_ITEMS_FLAGGED_MONTH`;
lang.DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK = (condition) => `DASHBOARD_TITLES_ITEMS_FLAGGED_WEEK`;
lang.USA_CONFIGURATION_CONFIGURATION = 'USA_CONFIGURATION_CONFIGURATION';
lang.USA_CONFIGURATION_SHOW_CONTRACT_NUMBER = 'USA_CONFIGURATION_SHOW_CONTRACT_NUMBER';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN = 'USA_CONFIGURATION_SHOW_ADVISED_GOOD_BREAKDOWN';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE = 'USA_CONFIGURATION_SHOW_ADVISED_GOOD_TARE';
lang.USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION = 'USA_CONFIGURATION_SHOW_ADVISED_GOOD_LOCATION';
lang.USA_CONFIGURATION_SHOW_DELIVERY_GROSS = 'USA_CONFIGURATION_SHOW_DELIVERY_GROSS';
lang.USA_CONFIGURATION_SHOW_DELIVERY_TYPE = 'USA_CONFIGURATION_SHOW_DELIVERY_TYPE';
lang.USA_CONFIGURATION_SHOW_RADIATION_FILE = 'USA_CONFIGURATION_SHOW_RADIATION_FILE';
lang.USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS = 'USA_CONFIGURATION_SHOW_WEATHER_CONDITIONS';
lang.USA_CONFIGURATION_STOCK_CODE = 'USA_CONFIGURATION_STOCK_CODE';
lang.USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE = 'USA_CONFIGURATION_WEIGHBRIDGE_TOLERANCE';
lang.USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR = 'USA_CONFIGURATION_RADIATION_RILE_UPLOAD_MSG_ERROR';
lang.USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG = 'USA_CONFIGURATION_RADIATION_FILE_REQUIRED_MSG';
lang.USA_CONFIGURATION_WEATHER_CONDITIONS = 'USA_CONFIGURATION_WEATHER_CONDITIONS';
lang.USA_CONFIGURATION_DELIVERY_TYPE = 'USA_CONFIGURATION_DELIVERY_TYPE';
lang.USA_CONFIGURATION_GROSS = 'USA_CONFIGURATION_GROSS';
lang.USA_CONFIGURATION_TARE = 'USA_CONFIGURATION_TARE';
lang.USA_CONFIGURATION_NET = 'USA_CONFIGURATION_NET';
lang.USA_CONFIGURATION_RADIATION_FILE = 'USA_CONFIGURATION_RADIATION_FILE';
lang.USA_CONFIGURATION_NO_RADIATION_FILE = 'USA_CONFIGURATION_NO_RADIATION_FILE';
lang.USA_CONFIGURATION_ADV_TARE = 'USA_CONFIGURATION_ADV_TARE';
lang.PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED = 'PUSHNOTIFICATIONS_TITLES_NEW_DELIVERY_CREATED';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF = 'PUSHNOTIFICATIONS_TITLES_DELIVERY_READY_FOR_SIGN_OFF';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF = 'PUSHNOTIFICATIONS_TITLES_DELIVERY_SIGNED_OFF';
lang.PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER = 'PUSHNOTIFICATIONS_TITLES_DELIVERY_COMPLETED_BY_TRADER';

export default lang;
