import { action, computed, observable, reaction, makeObservable } from 'mobx';
import * as moment from 'moment-timezone';
import FilterAbstractStore from 'stores/FilterAbstractStore';
import RootService from 'services/RootService';
import { DATE_FORMAT_WITH_DOT } from 'util/constants';

export interface ICommonFiltersApplied {
  startDate: boolean;
  endDate: boolean;
  isFlagged: boolean;
}

export interface ICommonQueryParams {
  createdFrom: string;
  createdTo: string;
  isFlagged: boolean;
  offset: number;
  limit: number;
}

export default class DeliveryCommonFilterStore extends FilterAbstractStore<ICommonFiltersApplied, ICommonQueryParams> {
  public offset: number = 0;

  public limit: number = 30;

  public startDate: moment.Moment = null;

  public endDate: moment.Moment = null;

  public isFlagged: boolean = false;

  public shouldScrollToTop: boolean = false;

  constructor(private readonly rootService: RootService) {
    super();

    makeObservable(this, {
      offset: observable,
      limit: observable,
      startDate: observable,
      endDate: observable,
      isFlagged: observable,
      shouldScrollToTop: observable,
      getFiltersApplied: computed,
      getQueryParams: computed,
      changeOffset: action,
      changeStartDate: action,
      changeEndDate: action,
      changeDateRange: action,
      toggleIsFlagged: action,
      setShouldScrollToTop: action,
    });

    reaction(
      () => this.hasFiltersApplied,
      () => {
        this.changeOffset(0);
      }
    );
  }

  public get getFiltersApplied(): ICommonFiltersApplied {
    return {
      startDate: !!this.startDate,
      endDate: !!this.endDate,
      isFlagged: !!this.isFlagged,
    } as ICommonFiltersApplied;
  }

  public get getQueryParams(): ICommonQueryParams {
    return {
      createdFrom: this.startDate ? this.startDate.format(DATE_FORMAT_WITH_DOT) : '',
      createdTo: this.endDate ? this.endDate.format(DATE_FORMAT_WITH_DOT) : '',
      isFlagged: this.isFlagged,
      offset: this.offset,
      limit: this.limit,
    };
  }

  public changeOffset = (offset: number) => (this.offset = offset);

  public async changeStartDate(startDate: moment.Moment) {
    this.startDate = startDate;
  }

  public async changeEndDate(endDate: moment.Moment) {
    this.endDate = endDate;
  }

  public async changeDateRange(startDate: moment.Moment, endDate: moment.Moment) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public toggleIsFlagged = () => (this.isFlagged = !this.isFlagged);

  public setShouldScrollToTop = (value: boolean) => (this.shouldScrollToTop = value);
}
