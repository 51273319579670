import { action, computed, observable, makeObservable } from 'mobx';
import TenantModel, { ITenantSaveObj } from 'models/TenantModel';
import UserModel from 'models/UserModel';
import RootService from 'services/RootService';
import WorkflowModel from 'models/WorkflowModel';
import { logger } from 'util/logger';
import IdNameModel from 'models/IdNameModel';
import { sortActiveFirst } from 'util/helpers';
import UserTenantModel from 'models/UserTenantModel';
import SharepointModel from 'models/SharepointModel';
import AppUserModel from 'models/AppUserModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';
import ConnectionModel from 'models/ConnectionModel';

export default class SuperAdminStore {
  public tenants: TenantModel[] = [];
  public workflowList: WorkflowModel[] = [];
  public admins: UserModel<IUserModelConstructObj>[] = [];
  public tenantUnits: string[] = [];
  public legalEntities: IdNameModel[] = [];
  public sharePointSites: SharepointModel[] = [];
  public appUsers: AppUserModel[] = [];
  public connections: ConnectionModel[] = [];

  public constructor(private readonly rootService: RootService) {
    makeObservable<
      SuperAdminStore,
      | '_setTenants'
      | '_setSharePointSites'
      | '_setAdmins'
      | '_setAppUsers'
      | '_setWorkflowList'
      | '_setUnits'
      | '_setLegalEntities'
      | '_setConnections'
    >(this, {
      tenants: observable,
      workflowList: observable,
      admins: observable,
      tenantUnits: observable,
      legalEntities: observable,
      sharePointSites: observable,
      appUsers: observable,
      connections: observable,
      activeTenants: computed,
      _setTenants: action,
      adminListActiveFirst: computed,
      appUsersListActiveFirst: computed,
      sharePointSiteList: computed,
      _setSharePointSites: action,
      _setAdmins: action,
      _setAppUsers: action,
      _setWorkflowList: action,
      _setUnits: action,
      _setLegalEntities: action,
      _setConnections: action,
    });
  }

  public get activeTenants(): TenantModel[] {
    return this.tenants.filter(t => t.active);
  }

  // BOF TENANTS
  public async getTenants() {
    const tenants = await this._getTenantsRequest(false);
    this._setTenants(tenants);
    return this.tenants;
  }

  public getTenantsForTheSameWorkflow = async (currentTenant: TenantModel): Promise<UserTenantModel[]> => {
    if (!this.tenants.length) {
      await this.getTenants();
    }
    return this.activeTenants
      .filter(
        (item: TenantModel) =>
          item.regionCode === currentTenant.regionCode && item.workflow.code === currentTenant.workflow.code
      )
      .map((tenant: TenantModel) => {
        const userTenantModelTenant = new UserTenantModel();
        userTenantModelTenant.changeName(tenant.name);
        userTenantModelTenant.id = tenant.id;
        userTenantModelTenant.code = tenant.code;
        userTenantModelTenant.regionCode = tenant.regionCode;
        userTenantModelTenant.workflowCode = tenant.workflow.code;
        return userTenantModelTenant;
      });
  };

  public async getTenantsForDevLogin(): Promise<TenantModel[]> {
    const tenants = await this._getTenantsRequest(true);
    this._setTenants(tenants);
    return this.tenants;
  }

  public async getWorkflows() {
    const workflows = await this._getWorkflowsRequest();
    this._setWorkflowList(workflows);
    return this.workflowList;
  }

  public async getConnections(): Promise<ConnectionModel[]> {
    const connections = await this._getConnectionsRequest();
    this._setConnections(connections);
    return this.connections;
  }

  public putConnection = async (connection: ConnectionModel) => {
    await this._putConnectionRequest(connection);
    await this.getConnections();
  }
  private _setTenants = (res: TenantModel[]) => {
    this.tenants = res.map((t: TenantModel) => new TenantModel().update(t));
  };

  public async postTenant(tenant: ITenantSaveObj) {
    const tenantResponse = await this._postTenantRequest(tenant);
    await this.getTenants();
    return tenantResponse;
  }

  public async putTenant(tenant: ITenantSaveObj, id: string) {
    await this._putTenantRequest(tenant, id);
    await this.getTenants();
  }

  public async getTenantById(tenantId: string) {
    const tenant = new TenantModel().update(await this._getTenantByIdRequest(tenantId));
    const isDeliveryCreated = await this._isDeliveryCreatedInTenantRequest(tenantId);
    tenant.setIsDeliveryCreated(Boolean(isDeliveryCreated));
    return tenant;
  }

  public async deleteTenantById(tenantId: string) {
    await this._deleteTenantByIdRequest(tenantId);
    await this.getTenants();
  }

  public getSelectedWorkflow = (tenant: TenantModel) => {
    return tenant.workflowId && this.workflowList.find((workflow: WorkflowModel) => workflow.id === tenant.workflowId);
  }

  private _postTenantRequest(tenant: ITenantSaveObj) {
    return this.rootService.ajaxService.post(`tenants`, tenant);
  }

  private _putTenantRequest(tenant: ITenantSaveObj, id: string) {
    return this.rootService.ajaxService.put(`tenants/${id}`, tenant);
  }

  private _getTenantByIdRequest(tenantId: string): Promise<TenantModel> {
    return this.rootService.ajaxService.get(`tenants/${tenantId}`);
  }

  private _deleteTenantByIdRequest(tenantId: string) {
    return this.rootService.ajaxService.delete(`tenants/${tenantId}`);
  }

  private _isDeliveryCreatedInTenantRequest = (tenantId: string): Promise<number> => {
    return this.rootService.ajaxService.get(`tenants/${tenantId}/grns`);
  };

  // EOF TENANTS

  // BOF SUPER ADMIN USER CREATE
  public postAdmin(admin: UserModel<IUserModelConstructObj>) {
    return this.rootService.ajaxService.post('users/admin', admin);
  }

  public putAdmin(admin: UserModel<IUserModelConstructObj>) {
    return this.rootService.ajaxService.put(`users/${admin.id}`, admin.constructSaveObj);
  }

  public hideTenantById(tenantId: string) {
    const hidedItem = this.tenants.find((item: TenantModel) => item.id === tenantId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(false);
      return this.putTenant(hidedItem.constructSaveObj, hidedItem.id);
    } else {
      throw Error(`Tenant ${tenantId} can not be find`);
    }
  }

  public unhideTenantById(tenantId: string) {
    const hidedItem = this.tenants.find((item: TenantModel) => item.id === tenantId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(true);
      return this.putTenant(hidedItem.constructSaveObj, hidedItem.id);
    } else {
      throw Error(`Tenant ${tenantId} can not be find`);
    }
  }

  public hideAdminById(adminsList: UserModel<IUserModelConstructObj>[], adminId: string) {
    const hidedItem = adminsList.find((item: UserModel<IUserModelConstructObj>) => item.id === adminId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(false);
      return this.putAdmin(hidedItem);
    } else {
      logger.error(`Admin ${adminId} can not be find`);
    }
  }

  public unhideAdminById(adminsList: UserModel<IUserModelConstructObj>[], adminId: string) {
    const hidedItem = adminsList.find((item: UserModel<IUserModelConstructObj>) => item.id === adminId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(true);
      return this.putAdmin(hidedItem);
    } else {
      logger.error(`Admin ${adminId} can not be find`);
    }
  }

  public hideAppUserById(appUsersList: AppUserModel[], appUserId: string): Promise<AppUserModel> | undefined {
    const hidedItem = appUsersList.find((item: AppUserModel) => item.id === appUserId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(false);
      return this.putAppUser(hidedItem);
    } else {
      logger.error(`AppUser ${appUserId} can not be find`);
    }
  }

  public unhideAppUserById(appUsersList: AppUserModel[], appUserId: string): Promise<AppUserModel> | undefined {
    const hidedItem = appUsersList.find((item: AppUserModel) => item.id === appUserId);
    if (hidedItem) {
      hidedItem.changeActiveStatus(true);
      return this.putAppUser(hidedItem);
    } else {
      logger.error(`AppUser ${appUserId} can not be find`);
    }
  }

  public get adminListActiveFirst(): UserModel<IUserModelConstructObj>[] {
    return sortActiveFirst<UserModel<IUserModelConstructObj>>(this.admins);
  }
  // EOF SUPER ADMIN USER CREATE

  // BOF SUPER ADMIN APP USERS

  public async getAppUsers() {
    const users = await this._getAppUsersRequest();
    this._setAppUsers(users);
    return this.appUsers;
  }

  public postAppUser(user: AppUserModel) {
    return this.rootService.ajaxService.post('users/app-regs', user.constructSaveObj);
  }

  public putAppUser(user: AppUserModel) {
    return this.rootService.ajaxService.put(`users/app-regs/${user.id}`, user.constructSaveObj);
  }

  public getAppUserById(id: string): AppUserModel | undefined {
    return this.appUsers.find((user: AppUserModel) => user.id === id);
  }

  public get appUsersListActiveFirst(): AppUserModel[] {
    return sortActiveFirst<AppUserModel>(this.appUsers);
  }

  // EOF SUPER ADMIN APP USERS

  public getAdminById(id: string): UserModel<IUserModelConstructObj> | undefined {
    return this.admins.find((admin: UserModel<IUserModelConstructObj>) => admin.id === id);
  }

  public async getAdmins() {
    const admins = await this._getAdminsRequest();
    this._setAdmins(admins);
    return this.admins;
  }

  public async getTenantCommon() {
    const common = await this._getCommonTenantRequest();
    this._setUnits(common.tenantUnits);
    this._setLegalEntities(common.legalEntities);
    return common;
  }

  public async getSharePointSites() {
    const sites = await this._getSharePointSitesRequest();
    this._setSharePointSites(sites);
    return this.sharePointSites;
  }

  public get sharePointSiteList(): SharepointModel[] {
    return this.sharePointSites;
  }

  private _setSharePointSites = (sites: SharepointModel[]) => {
    this.sharePointSites = sites.map((s: SharepointModel) => new SharepointModel().update(s));
  };

  private _setAdmins = (res: UserModel<IUserModelConstructObj>[]) => {
    this.admins = res.map((u: UserModel<IUserModelConstructObj>) => new UserModel<IUserModelConstructObj>().update(u));
  };

  private _setAppUsers = (res: AppUserModel[]) => {
    this.appUsers = res.map((u: AppUserModel) => new AppUserModel().update(u));
  };

  private _setWorkflowList(workflows: WorkflowModel[]) {
    this.workflowList = workflows.map((workflow: WorkflowModel) => new WorkflowModel().update(workflow));
  }

  private _setUnits = (res: string[]) => {
    this.tenantUnits = res;
  };

  private _setLegalEntities = (res: IdNameModel[]) => {
    this.legalEntities = res.map((e: IdNameModel) => new IdNameModel().update(e));
  };

  private _setConnections = (res: ConnectionModel[]) => {
    this.connections = res.map((e: ConnectionModel) => new ConnectionModel().update(e));
  };

  private _getTenantsRequest(isDevLogin: boolean) {
    return this.rootService.ajaxService.get(`tenants${isDevLogin ? '/login' : ''}?onlyActive=false`);
  }

  private _getAdminsRequest() {
    return this.rootService.ajaxService.get('users/admins');
  }

  private _getWorkflowsRequest() {
    return this.rootService.ajaxService.get('workflow');
  }

  private _getCommonTenantRequest() {
    return this.rootService.ajaxService.get('common/tenant');
  }

  private _getSharePointSitesRequest() {
    return this.rootService.ajaxService.get('sharepoint-upload-folder');
  }

  private _getAppUsersRequest() {
    return this.rootService.ajaxService.get('users/app-regs');
  }

  private _getConnectionsRequest() {
    return this.rootService.ajaxService.get('external-api-connections');
  }

  private _putConnectionRequest(connection: ConnectionModel) {
    return this.rootService.ajaxService.put(`external-api-connections/${connection.apiName}`, connection.constructSaveObj);
  }
}
