import { action, computed, observable, makeObservable } from 'mobx';

import DeliveryLabReceivedGoodModel from 'models/DeliveryLabReceivedGoodModel';
import ObjectUpdater from 'util/objectUpdater';

import { IUpdateModel } from 'models/UpdateModel';
import LabStatsModel from 'models/LabStatsModel';
import TenantModel from 'models/TenantModel';
import SupplierInfo from 'models/SupplierInfoAbstractModel';
import { cloneObj } from 'util/helpers';

export default class DeliveryLabModel extends SupplierInfo implements IUpdateModel<DeliveryLabModel> {
  public readonly updater = new ObjectUpdater<DeliveryLabModel>();

  public createdAt?: string = '';
  public flagged?: boolean = false;
  public wasFlagged?: boolean = false;
  public grn?: string = '';
  public id: string = null;
  public receivedGoods?: DeliveryLabReceivedGoodModel[] = [];
  public stats?: LabStatsModel[] = [];
  public tenant?: TenantModel = null;

  constructor() {
    super();

    makeObservable(this, {
      createdAt: observable,
      flagged: observable,
      wasFlagged: observable,
      grn: observable,
      id: observable,
      receivedGoods: observable,
      stats: observable,
      tenant: observable,
      hasNotification: computed,
      update: action,
    });
  }

  public get hasNotification(): boolean {
    return this.receivedGoods.some((rg: DeliveryLabReceivedGoodModel) => {
      return rg.stats.some((st: LabStatsModel) => st.hasNotification);
    });
  }

  public update = (obj: DeliveryLabModel) => {
    const newDeliveryLabModel = cloneObj(obj);

    if (newDeliveryLabModel && newDeliveryLabModel.receivedGoods && newDeliveryLabModel.receivedGoods.length) {
      newDeliveryLabModel.receivedGoods = newDeliveryLabModel.receivedGoods.map((i: DeliveryLabReceivedGoodModel) =>
        new DeliveryLabReceivedGoodModel().update(i)
      );
    }

    this.updater.update(this, newDeliveryLabModel, DeliveryLabModel);
    return this;
  };
}
