import * as React from 'react';
import * as H from 'history';
import * as classname from 'classnames';
import { Col, Container, Row } from 'reactstrap';
import { action, computed, observable, reaction, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';
import DeliveryListComponent from 'pod/deliveries/DeliveryListComponent';
import DeliveryListFilterComponent from 'pod/deliveries/DeliveryListFilterComponent';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import InfiniteScroll from 'domain/InfiniteScroll';
import DeliveryListModel from 'models/DeliveryListModel';
import DeliveryStore, { IGetDeliveriesResponse } from 'pod/deliveries/DeliveryStore';
import SessionStore from 'stores/SessionStore';
import SocketService from 'services/SocketService';
import DeliveryListAdvisedGoodModel from 'models/DeliveryListAdvisedGoodModel';
import TranslateService from 'services/TranslateService';
import CommonStore from 'stores/CommonStore';
import { debounce, isIosVersionLess13 } from 'util/helpers';
import DeliveryFiltersStore, {
  FilterByStatus,
  FilterByStatusValue,
  SortByQp,
} from 'pod/deliveries/DeliveryFiltersStore';
import checkLatestAppVersion from 'util/checkLatestAppVersion';
import { SocketServiceTopic, TDepartmentCodes } from 'util/enums';
import NavlinkCustom from 'domain/NavlinkCustom';
import * as FontAwesome from 'react-fontawesome';
import Portal from 'components/Portal';
import { CREATED_DESC, GRN_DESC } from 'util/constants';
import ViewStore from 'stores/ViewStore';
import { getMobileTranslation } from '../../util/getMobileTranslation';

const URLSearchParams = require('url-search-params');

interface IProps {
  history: H.History;
  advisedGoodsStore?: AdvisedGoodsStore;
  deliveryStore?: DeliveryStore;
  commonStore?: CommonStore;
  sessionStore?: SessionStore;
  socketService?: SocketService;
  translateService?: TranslateService;
  deliveryFiltersStore?: DeliveryFiltersStore;
  viewStore?: ViewStore;
}

class DeliveryListRoute extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    makeObservable<
      DeliveryListRoute,
      | '_onSocketReceivedGoodTransfer'
      | '_onSocketAdvisedGoodUpdate'
      | '_onNewDelivery'
      | '_onDeliveryRollback'
      | '_onSocketDeliveryUpdateInternal'
      | '_onSocketDeliveryUpdateExternal'
      | '_isTraderInfoShown'
    >(this, {
      prevDeliveryId: observable,
      forceOpenList: observable,
      hasMore: observable,
      changePrevDeliveryId: action,
      toggleForceOpenList: action,
      changeHasMore: action,
      _onSocketReceivedGoodTransfer: action,
      _onSocketAdvisedGoodUpdate: action,
      _onNewDelivery: action,
      _onDeliveryRollback: action,
      _onSocketDeliveryUpdateInternal: action,
      _onSocketDeliveryUpdateExternal: action,
      _isTraderInfoShown: computed,
    });

    reaction(
      () => this.props.deliveryFiltersStore.hasFiltersApplied,
      () => {
        this.changeHasMore(true);
      }
    );
  }

  public prevDeliveryId: string = null;

  public forceOpenList: boolean = false;

  public hasMore: boolean = null;

  public changePrevDeliveryId = (newValue: string) => (this.prevDeliveryId = newValue);

  public toggleForceOpenList = () => (this.forceOpenList = !this.forceOpenList);

  public changeHasMore = (newValue: boolean) => {
    this.hasMore = newValue;
  };

  public componentDidMount() {
    const {
      commonStore,
      socketService,
      sessionStore: { canListenToNewDeliveries },
    } = this.props;

    const urlParams = new URLSearchParams(this.props.history.location.search);
    if (urlParams.has('prevDeliveryId')) {
      this.changePrevDeliveryId(urlParams.get('prevDeliveryId'));
    }

    if (!commonStore.isDataLoaded) {
      commonStore.getCommon();
    }

    if (canListenToNewDeliveries) {
      socketService.subscribeTopic(SocketServiceTopic.NEW_DELIVERY, this._onNewDelivery);
    }

    // deliveries
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_UPDATED, this._onSocketDeliveryUpdateExternal);
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_IN_WORK, this._onSocketDeliveryUpdateInternal);
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_READY_FOR_TIER_2, this._onSocketDeliveryUpdateInternal);
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_READY_FOR_TIER_1, this._onSocketDeliveryUpdateInternal);
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_SIGNED_OFF, this._onSocketDeliveryUpdateInternal);
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_COMPLETE, this._onSocketDeliveryUpdateInternal);
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_ROLLBACK, this._onDeliveryRollback);

    // advised goods
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_CLAIMED, this._onSocketAdvisedGoodUpdate);
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_READY_FOR_TIER_2, this._onSocketAdvisedGoodUpdate);
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_READY_FOR_TIER_1, this._onSocketAdvisedGoodUpdate);
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_HAS_LAB_ANALYSIS, this._onSocketAdvisedGoodUpdate);
    socketService.subscribeTopic(SocketServiceTopic.RECEIVED_GOOD_TRANSFER, this._onSocketReceivedGoodTransfer);

    checkLatestAppVersion();
  }

  public componentWillUnmount() {
    const { socketService, deliveryFiltersStore } = this.props;
    deliveryFiltersStore.deliveryCommonFilterStore.changeOffset(0);
    // deliveries
    socketService.unsubscribeTopic(SocketServiceTopic.NEW_DELIVERY, this._onNewDelivery);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_UPDATED, this._onSocketDeliveryUpdateExternal);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_IN_WORK, this._onSocketDeliveryUpdateInternal);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_READY_FOR_TIER_2, this._onSocketDeliveryUpdateInternal);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_READY_FOR_TIER_1, this._onSocketDeliveryUpdateInternal);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_SIGNED_OFF, this._onSocketDeliveryUpdateInternal);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_COMPLETE, this._onSocketDeliveryUpdateInternal);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_ROLLBACK, this._onDeliveryRollback);

    // advised goods
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_CLAIMED, this._onSocketAdvisedGoodUpdate);
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_READY_FOR_TIER_2, this._onSocketAdvisedGoodUpdate);
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_READY_FOR_TIER_1, this._onSocketAdvisedGoodUpdate);
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_HAS_LAB_ANALYSIS, this._onSocketAdvisedGoodUpdate);
    socketService.unsubscribeTopic(SocketServiceTopic.RECEIVED_GOOD_TRANSFER, this._onSocketReceivedGoodTransfer);
  }

  // CLAIM ADVISED GOOD
  public claimAdvisedGood = (advisedGood: DeliveryListAdvisedGoodModel, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { advisedGoodsStore } = this.props;
    advisedGoodsStore.postClaimAdvisedGood(advisedGood.id).then((res: { status: string }) => {
      advisedGood.setStatus(res.status);
      advisedGood.setClaimedBy(this.props.sessionStore.user);

      notification.success(this.props.translateService.t.DELIVERIES_DELIVERIESTABLE_ADVISED_GOOD_MSG);
    });
  };

  // BOF FILTER FUNCTIONS
  public selectSortBy = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const { deliveryStore, deliveryFiltersStore } = this.props;

    deliveryFiltersStore.changeFilterByStatusValue(target.dataset.value as FilterByStatusValue);
    deliveryFiltersStore.changeSortByQp(`${target.dataset.key},${target.dataset.direction}` as SortByQp);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  public selectFilterBy = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const { deliveryStore, deliveryFiltersStore } = this.props;

    deliveryFiltersStore.changeFilterByStatus(target.dataset.status as FilterByStatus);
    deliveryFiltersStore.changeFilterByStatusValue(target.dataset.value as FilterByStatusValue);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  public filterGrnOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const { deliveryStore, deliveryFiltersStore } = this.props;

    deliveryFiltersStore.changeFilterByGrnValue(val);

    // ADDED CALLBACK FOR PROMISE, TO CHECK IN CASE A FEW REQUEST ARE FIRED IN SAME TIME
    debounce(
      () =>
        deliveryStore
          .getDeliveriesWithCheck(deliveryFiltersStore.buildQueryString)
          .then((res: IGetDeliveriesResponse) => {
            if (val === deliveryFiltersStore.filterByGrnValue) {
              deliveryStore.setDeliveryList(res);
            }
          }),
      1000
    );
  };

  public isFlaggedChange = () => {
    const { deliveryStore, deliveryFiltersStore } = this.props;
    deliveryFiltersStore.deliveryCommonFilterStore.toggleIsFlagged();
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  public getDeliveryList = (queryParams: string) => {
    const { deliveryStore, socketService } = this.props;
    return deliveryStore.getDeliveries(queryParams).then((hasMore) => {
      this.changeHasMore(hasMore);
      socketService.setIsContentReadyToUpdate(true);
    });
  };

  public downloadList = (queryParams: string) => {
    const { deliveryStore } = this.props;

    deliveryStore.downloadList(queryParams);
  };

  public getDeliveryListWithFilter = () => {
    const { deliveryFiltersStore } = this.props;
    return this.getDeliveryList(deliveryFiltersStore.buildQueryString);
  };

  public filterSupplier = (supplier: string) => {
    const { deliveryStore, deliveryFiltersStore } = this.props;
    deliveryFiltersStore.changeSupplier(supplier);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  public filterLocation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { deliveryStore, deliveryFiltersStore } = this.props;
    deliveryFiltersStore.changeLocation(e.target.value);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  public filterTrader = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { deliveryStore, deliveryFiltersStore } = this.props;
    deliveryFiltersStore.changeTrader(e.target.value);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  public filterTenant = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { deliveryStore, deliveryFiltersStore } = this.props;
    deliveryFiltersStore.tenantFilterStore.changeTenant(e.target.value);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  public filterDepartment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { deliveryStore, deliveryFiltersStore } = this.props;
    let departmentCodes = [] as TDepartmentCodes[];
    const value = e.target.value;

    if (value) {
      departmentCodes = value.split(',') as TDepartmentCodes[];
    }

    deliveryFiltersStore.changeDepartment(departmentCodes);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  // ADMIN: CAN RE-SYNC DELIVERY TO NAVISION
  public adminResyncWb = (deliveryId: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    this.props.deliveryStore.postResyncWb(deliveryId).then(() => {
      notification.success(this.props.translateService.t.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED);
    });
  };

  public adminResyncSr = (deliveryId: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    this.props.deliveryStore.postResyncSr(deliveryId).then(() => {
      notification.success(this.props.translateService.t.DELIVERIES_DELIVERIESTABLE_DELIVERY_RESYNCED);
    });
  };

  public adminReexport = (deliveryId: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    this.props.deliveryStore.reexportDelivery(deliveryId);
  };

  public increasePageAmount = () => {
    const {
      deliveryFiltersStore: { deliveryCommonFilterStore },
    } = this.props;

    deliveryCommonFilterStore.changeOffset(deliveryCommonFilterStore.offset + deliveryCommonFilterStore.limit);
  };

  public get nodeForScrolling() {
    return document.getElementById('nodeForScrolling');
  }

  public render() {
    const {
      sessionStore: {
        canCreateDeliveries,
        isWeightBridgeOperator,
        isTier1,
        isTier3,
        isTraderOrBackOffice,
        isAdmin,
        isManager,
      },
      deliveryStore: { deliveryList },
      commonStore: { common, tradersForDeliveryFilterList },
      translateService: { t },
      deliveryFiltersStore: {
        isSortingGrnDesc,
        isSortingCreatedDesc,
        deliveryCommonFilterStore: { shouldScrollToTop, setShouldScrollToTop },
      },
      history,
    } = this.props;

    return (
      <div className="full-height">
        {(isTier1 || isManager) && (
          <Portal nodeId="header">
            <NavlinkCustom
              data-test="dashboard"
              to="/dashboard"
              tagName="button"
              className="dashboard-icon header-button btn btn-secondary pull-right"
            >
              <FontAwesome name="line-chart" />
            </NavlinkCustom>
          </Portal>
        )}
        <Container fluid>
          <Row>
            <Col xs="12">
              <DeliveryListFilterComponent
                canCreateDeliveries={canCreateDeliveries}
                filterGrnOnChange={this.filterGrnOnChange}
                forceOpenList={this.forceOpenList}
                isFlaggedChange={this.isFlaggedChange}
                downloadList={this.downloadList}
                refreshData={this._refreshData}
                selectFilterBy={this.selectFilterBy}
                selectSortBy={this.selectSortBy}
                showSortByFilter={false}
                toggleForceOpenList={this.toggleForceOpenList}
                getListData={this.getDeliveryList}
                suppliers={common.suppliers}
                changeSupplier={this.filterSupplier}
                locations={common.yardLocations}
                changeLocation={this.filterLocation}
                changeTenant={this.filterTenant}
                departmentCodes={common.departmentCodes}
                changeDepartment={this.filterDepartment}
                history={history}
                tradersForDeliveryFilterList={tradersForDeliveryFilterList}
                changeTrader={this.filterTrader}
              />
            </Col>
            <Col xs="12" className="ps-0 pe-0">
              {/* DISPLAY COLLAPSABLE LIST OF DELIVERIES */}
              <table className="custom-table delivery-list">
                <thead className={classname([{ 'position-sticky--20': isIosVersionLess13() }])}>
                  <tr>
                    {!isWeightBridgeOperator && <th className="width-30"></th>}
                    <th
                      className={classname([
                        { 'ps-2': isWeightBridgeOperator, 'bg-gray-300': isSortingGrnDesc },
                        'min-width-50 pointer bg-gray-300-hover',
                      ])}
                      onClick={this._changeSortingQp}
                      data-sorting={GRN_DESC}
                      data-test="grn-sorting"
                    >
                      {t.GLOBAL_LABEL_GRN}
                      {this._renderArrowDown(isSortingGrnDesc)}
                    </th>
                    <th>{t.GLOBAL_LABEL_SUPPLIER}</th>
                    {this._isTraderInfoShown && <th data-test="trader-info">{getMobileTranslation(t, 'GLOBAL_USERROLE_TRADER')}</th>}
                    {(isWeightBridgeOperator || isTier3) && <th>{t.GLOBAL_LABEL_LICENCE_PLACE}</th>}
                    <th className="min-width-55 word-break-all">{t.GLOBAL_LABEL_STATUS}</th>
                    <th className="text-center min-width-30 word-break-all">{t.GLOBAL_LABEL_TO_CLAIM}</th>
                    <th
                      className={classname([
                        { 'bg-gray-300': isSortingCreatedDesc },
                        'min-width-50 pointer bg-gray-300-hover',
                      ])}
                      onClick={this._changeSortingQp}
                      data-sorting={CREATED_DESC}
                      data-test="created-sorting"
                    >
                      {t.GLOBAL_LABEL_CREATED}
                      {this._renderArrowDown(isSortingCreatedDesc)}
                    </th>
                    {(isTier1 || isTraderOrBackOffice || isAdmin) && <th>{t.GLOBAL_LABEL_ACTIONS}</th>}
                    {isWeightBridgeOperator && <th className="width-30"></th>}
                  </tr>
                </thead>
                <tbody>
                  {deliveryList && (
                    <InfiniteScroll
                      loadMore={this.getDeliveryListWithFilter}
                      increasePageAmount={this.increasePageAmount}
                      initialLoading={true}
                      nodeForScrolling={this.nodeForScrolling}
                      hasMore={this.hasMore}
                      shouldScrollToTop={shouldScrollToTop}
                      setShouldScrollToTop={setShouldScrollToTop}
                    >
                      {deliveryList.map((deliveryItem: DeliveryListModel) => (
                        <DeliveryListComponent
                          adminResyncWb={this.adminResyncWb}
                          adminResyncSr={this.adminResyncSr}
                          adminReexport={this.adminReexport}
                          claimAdvisedGood={this.claimAdvisedGood}
                          delivery={deliveryItem}
                          deliveryStore={this.props.deliveryStore}
                          forceOpenList={this.forceOpenList}
                          history={this.props.history}
                          key={deliveryItem.id}
                          prevDeliveryId={this.prevDeliveryId}
                          refreshData={this._refreshData}
                          isTraderInfoShown={this._isTraderInfoShown}
                        />
                      ))}
                    </InfiniteScroll>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>

        {/*<Route path="/deliveries/:id" component="" />*/}
      </div>
    );
  }

  private _onSocketReceivedGoodTransfer = (data: DeliveryListAdvisedGoodModel) => {
    for (const delivery of this.props.deliveryStore.deliveryList) {
      if (delivery.id === data.deliveryId) {
        const existingAdvisedGood = delivery.advisedGoods.find((advisedGood) => advisedGood.id === data.id);

        if (existingAdvisedGood) {
          existingAdvisedGood.update(data);
        } else {
          delivery.advisedGoods.push(new DeliveryListAdvisedGoodModel().update(data));
        }
      }
    }
  };

  private _onSocketAdvisedGoodUpdate = (data: DeliveryListAdvisedGoodModel) => {
    for (const delivery of this.props.deliveryStore.deliveryList) {
      if (delivery.id === data.deliveryId) {
        for (const advisedGood of delivery.advisedGoods) {
          if (advisedGood.id === data.id) {
            advisedGood.update(data);
            notification.success(
              this.props.translateService.t.DELIVERIES_DELIVERYEDIT_AG_CLAIMED_SUCCESS_MSG(delivery.grn)
            );
          }
        }
      }
    }
  };

  private _

  private _onNewDelivery = (data: DeliveryListModel) => {
    notification.success(this.props.translateService.t.DELIVERIES_DELIVERIESTABLE_NEW_DELIVERY_SUCCESS_MSG);
    this.props.deliveryStore.deliveryList.unshift(new DeliveryListModel().update(data));
  };

  private _onDeliveryRollback = (data: DeliveryListModel) => {
    const deliveryToRollback = this.props.deliveryStore.deliveryList.find((d) => d.id === data.id);
    if (deliveryToRollback) {
      deliveryToRollback.update(data);
    } else {
      this.props.deliveryStore.deliveryList.unshift(data);
    }

    notification.success(this.props.translateService.t.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG(data.grn));
  };

  private _onSocketDeliveryUpdate = (data: DeliveryListModel, notfier: (message: string) => void) => {
    for (const delivery of this.props.deliveryStore.deliveryList) {
      if (delivery.id === data.id) {
        notfier(this.props.translateService.t.DELIVERIES_DELIVERIESTABLE_DELIVERY_UPDATE_MSG(data.grn));
        delivery.update(data);
      }
    }
  };

  private _onSocketDeliveryUpdateInternal = (data: DeliveryListModel) => {
    this._onSocketDeliveryUpdate(data, notification.success);
  };

  private _onSocketDeliveryUpdateExternal = (data: DeliveryListModel) => {
    this._onSocketDeliveryUpdate(data, notification.info);
  };

  private get _isTraderInfoShown() {
    const { viewStore: { isDE_D365, isTier1FromFR, isTier2FromFR} } = this.props;
    return isDE_D365 || isTier1FromFR || isTier2FromFR;
  }

  // TODO check
  private _refreshData = () => {
    const { deliveryStore, deliveryFiltersStore } = this.props;
    deliveryFiltersStore.deliveryCommonFilterStore.changeOffset(0);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString).then((hasMore) => {
      this.changeHasMore(hasMore);
    });
  };

  private _changeSortingQp = (e: React.MouseEvent<HTMLTableHeaderCellElement>) => {
    const { deliveryStore, deliveryFiltersStore } = this.props;
    const newSortValue = (e.target as HTMLTableHeaderCellElement).dataset.sorting as SortByQp;
    deliveryFiltersStore.changeSortByQp(newSortValue);
    deliveryStore.getDeliveries(deliveryFiltersStore.buildQueryString);
  };

  private _renderArrowDown = (shouldRender: boolean) => {
    return shouldRender && <FontAwesome name="arrow-down" data-test="arrow-down" />;
  };
}

export default inject(
  'deliveryStore',
  'commonStore',
  'socketService',
  'sessionStore',
  'advisedGoodsStore',
  'translateService',
  'deliveryFiltersStore',
  'viewStore'
)(observer(DeliveryListRoute));
