import LabStatsStatusModel from 'models/LabStatsStatusModel';
import { action, observable, makeObservable } from 'mobx';
import UpdateModel from 'models/UpdateModel';

export default class LabStatsModel extends UpdateModel<LabStatsModel> {
  public count: string = null;
  public status: LabStatsStatusModel = null;
  public hasNotification?: boolean = false;

  public update = (obj: LabStatsModel) => {
    this.updater.update(this, obj, LabStatsModel);
    return this;
  };

  public updateHasNotification = (newValue: boolean) => (this.hasNotification = newValue);

  constructor() {
    super();

    makeObservable(this, {
      count: observable,
      status: observable,
      hasNotification: observable,
      update: action,
      updateHasNotification: action,
    });
  }
}
