import { action, computed, makeObservable, observable } from 'mobx';
import { IConstructSaveObj, ILasernetPrinterConfigurationObj } from 'models/ModelInterfaces';
import ValidateModel from 'models/ValidateModel';
import { CountryCode } from 'util/enums';
import { cloneObj } from 'util/helpers';
import { LASERNET_PRINTER_CONFIGURATION_ALL_VALUE } from 'util/constants';

interface ILasernetPrinterConfigurationValidationKeys {
  printerId: boolean;
}

export type TLasernetPrinterConfigurationValidatorKey = keyof ILasernetPrinterConfigurationValidationKeys;

export default class LasernetPrinterConfigurationModel
  extends ValidateModel<ILasernetPrinterConfigurationValidationKeys>
  implements IConstructSaveObj<ILasernetPrinterConfigurationObj> {
  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      userId: observable,
      printerId: observable,
      documentTypeId: observable,
      constructSaveObj: computed,
      update: action,
      setUserId: action,
      setPrinterId: action,
      setDocumentTypeId: action,
    });
  }
  public id: string = '';
  public userId: string = LASERNET_PRINTER_CONFIGURATION_ALL_VALUE;
  public printerId: string = '';
  public documentTypeId = LASERNET_PRINTER_CONFIGURATION_ALL_VALUE;

  public generalValidatorKeys: Array<keyof ILasernetPrinterConfigurationValidationKeys> = ['printerId'];

  public validationKeysByCountryCode: Map<
    CountryCode,
    Array<keyof Partial<ILasernetPrinterConfigurationValidationKeys>>
  > = new Map<CountryCode, Array<keyof Partial<ILasernetPrinterConfigurationValidationKeys>>>([
    [CountryCode.US, this.generalValidatorKeys],
    [CountryCode.IT, this.generalValidatorKeys],
    [CountryCode.DE, this.generalValidatorKeys],
    [CountryCode.DE_D365, this.generalValidatorKeys],
    [CountryCode.UK, this.generalValidatorKeys],
    [CountryCode.FR, this.generalValidatorKeys],
  ]);

  public get validators() {
    return {
      printerId: Boolean(this.printerId),
    };
  }

  get constructSaveObj(): ILasernetPrinterConfigurationObj {
    return {
      userId: this.userId === LASERNET_PRINTER_CONFIGURATION_ALL_VALUE ? null : this.userId,
      printerId: this.printerId,
      documentTypeId: this.documentTypeId === LASERNET_PRINTER_CONFIGURATION_ALL_VALUE ? null : this.documentTypeId,
    };
  }

  public update = (obj: LasernetPrinterConfigurationModel) => {
    const newModel = cloneObj(obj);

    if (!newModel.userId) {
      newModel.userId = LASERNET_PRINTER_CONFIGURATION_ALL_VALUE;
    }

    if (!newModel.documentTypeId) {
      newModel.documentTypeId = LASERNET_PRINTER_CONFIGURATION_ALL_VALUE;
    }

    this.updater.update(this, newModel, LasernetPrinterConfigurationModel);
    return this;
  };

  public setUserId = (newUserId: string) => {
    this.userId = newUserId;
  };

  public setPrinterId = (newPrinterId: string) => {
    this.printerId = newPrinterId;
  };

  public setDocumentTypeId = (newDocumentTypeId: string) => {
    this.documentTypeId = newDocumentTypeId;
  };
}
