import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { action, computed, observable, makeObservable } from 'mobx';
import storage from 'util/storage';
import env from 'env';

import { instance as notification } from 'util/notification';
import { I18N } from '../../assets/i18n/i18n';
import { getTranslation } from 'util/helpers';
import { logger } from 'util/logger';
import RootService from 'services/RootService';
import { IDeliveryConstructSaveObj, IDeliveryAttachmentsConstructSaveObj } from 'models/DeliveryModel';
import { IAdvisedGoodsModelConstructPutAdvisedGood } from 'models/AdvisedGoodsModel';
import { ITenantSaveObj } from 'models/TenantModel';
import {
  ILasernetDocumentTypeConstructObj,
  ILasernetPrinterConfigurationObj,
  IUserModelConstructObj,
} from 'models/ModelInterfaces';
import { ITransferData } from 'models/TransferModel';
import { IKeyValueModelConstructObject } from 'models/KeyValueModel';
import MaterialsModel from 'models/MaterialsModel';
import { TAdminCommonItem } from 'pod/admin/AdminStore';
import { ILabAnalysisModelSaveObject } from 'models/LabAnalysisModel';
import { IConnectionConstructObj } from 'models/ConnectionModel';
import RootStore from 'stores/RootStore';
import {
  ACTIVE_USER_ROLE_NAME_STORAGE_KEY,
  EXPECTATION_FAILED_STATUS_CODE,
  ACTIVE_TENANT_ID_STORAGE_KEY,
} from 'util/constants';

export type TData =
  | FormData
  | IDeliveryConstructSaveObj
  | IAdvisedGoodsModelConstructPutAdvisedGood
  | ITenantSaveObj
  | IDeliveryAttachmentsConstructSaveObj
  | TAdminCommonItem
  | ITransferData
  | IUserModelConstructObj
  | IKeyValueModelConstructObject
  | ILabAnalysisModelSaveObject
  | ILasernetDocumentTypeConstructObj
  | ILasernetPrinterConfigurationObj
  | IConnectionConstructObj
  | { [key: string]: string | boolean | number | MaterialsModel[] }
  | { numberOfCopies: number };

interface IAjaxOptions {
  skipCounting: boolean;
  timeout: number;
}

interface IResponseErrorData {
  messageCode: string;
  params: string[];
}

export default class AjaxService {
  public constructor(private readonly rootService: RootService, private readonly rootStore: RootStore) {
    makeObservable<AjaxService, '_pendingUrlsArray' | '_addUrlToPendingArray' | '_removeUrlFromPendingArray'>(this, {
      _pendingUrlsArray: observable,
      hasPendingRequests: computed,
      _addUrlToPendingArray: action,
      _removeUrlFromPendingArray: action,
    });
  }
  private _pendingUrlsArray: string[] = [];

  public get hasPendingRequests(): boolean {
    return Boolean(this._pendingUrlsArray.length);
  }

  public apiUrl(url: string): string {
    return `${this._hostUrl}/api/${url}`;
  }

  public getSessionId(): string {
    return storage.get('sid') || null;
  }

  public getActiveRoleName(): string {
    return storage.get(ACTIVE_USER_ROLE_NAME_STORAGE_KEY) || null;
  }

  public getActiveTenantId(): string {
    return storage.get(ACTIVE_TENANT_ID_STORAGE_KEY) || null;
  }

  public get(url: string, options: Partial<IAjaxOptions> = {}) {
    return this._call('GET', url, null, options);
  }

  public post(url: string, data?: TData, options: Partial<IAjaxOptions> = {}) {
    return this._call('POST', url, data, options);
  }

  public put(url: string, data?: TData, options: Partial<IAjaxOptions> = {}) {
    return this._call('PUT', url, data);
  }

  public delete(url: string, data?: TData, options: Partial<IAjaxOptions> = {}) {
    return this._call('DELETE', url, data);
  }

  public download(url: string) {
    const options = `?x-auth-token=${this.getSessionId()}&x-client-version=${env.version}`;
    return `${this.apiUrl(url)}${options}`;
  }

  private async _call(method: string, url: string, data: TData = null, options: Partial<IAjaxOptions> = {}) {
    const skipCounting = options.skipCounting || false;
    url = this.apiUrl(url);
    this._addUrlToPendingArray(url, skipCounting);

    const headers: { [key: string]: string } = {
      'x-auth-token': String(this.getSessionId()),
      'x-client-version': env.version,
      'x-active-role': String(this.getActiveRoleName()),
      'x-active-tenant': String(this.getActiveTenantId()),
    };

    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    } else {
      headers['Content-Type'] = 'application/json';
    }

    const additionalOptions: Partial<IAjaxOptions> = {};
    if (options.timeout) {
      additionalOptions.timeout = options.timeout;
    }

    return axios({
      method,
      url,
      headers,
      data,
      ...additionalOptions,
    } as AxiosRequestConfig)
      .then((res: AxiosResponse) => {
        this._removeUrlFromPendingArray(res.config.url, skipCounting);
        return res.data;
      })
      .catch((e: AxiosError<IResponseErrorData>) => {
        this._removeUrlFromPendingArray(e.config.url, skipCounting);
        logger.error(e);

        if (e?.response?.status === EXPECTATION_FAILED_STATUS_CODE) {
          this.rootStore.versionStore.setIsNeedUpdateVersion(true);
        } else if (this._hasMessageCode(e)) {
          const key = getTranslation(
            `GLOBAL_SERVERERROR_${e.response.data.messageCode}` as keyof I18N,
            this.rootService.translateService.t
          );
          const params = e.response.data.params;
          const messsageCode = e.response.data.messageCode;
          let notificationMessage: string;

          if (this._isFunctionAndHasSomeParams(key, params)) {
            notificationMessage =
              (key as (...rest: string[]) => string)(...params) ||
              this.rootService.translateService.t.GLOBAL_SERVERERROR_DEFAULT;
          } else {
            notificationMessage =
              (key as string) || messsageCode || this.rootService.translateService.t.GLOBAL_SERVERERROR_DEFAULT;
          }

          if (e.response.data.messageCode !== 'SESSION_NOT_FOUND') {
            notification.error(notificationMessage);
          }
        } else {
          notification.error(this.rootService.translateService.t.GLOBAL_SERVERERROR_DEFAULT);
        }

        throw e;
      });
  }

  private _addUrlToPendingArray = (url: string, skipCounting: boolean) => {
    if (!skipCounting) {
      this._pendingUrlsArray.push(url);
    }
  };

  private _removeUrlFromPendingArray = (url: string, skipCounting: boolean) => {
    if (!skipCounting) {
      this._pendingUrlsArray = this._pendingUrlsArray.filter((item) => item !== url);
    }
  };

  private _hasMessageCode = (e: AxiosError<IResponseErrorData>): boolean => {
    return Boolean(e && e.response && e.response.data && e.response.data.messageCode);
  };

  private _isFunctionAndHasSomeParams = (fn: string | ((...rest: string[]) => string), params: string[]): boolean => {
    return !!(typeof fn === 'function' && Array.isArray(params) && params.length);
  };

  private get _hostUrl(): string {
    return env.apiUrl || '';
  }
}
