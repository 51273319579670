import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import AdminStore from 'pod/admin/AdminStore';
import LayoutStore from 'stores/LayoutStore';
import CommonStore from 'stores/CommonStore';
import SessionStore from 'stores/SessionStore';
import AdvisedGoodLabStore from 'pod/advisedGoodLab/AdvisedGoodLabStore';
import DeliveryFiltersStore from 'pod/deliveries/DeliveryFiltersStore';
import DeliveryStore from 'pod/deliveries/DeliveryStore';
import DashboardStore from 'pod/dashboard/DashboardStore';
import ContractStore from 'stores/ContractStore';
import LabStore from 'pod/deliveries/LabStore';
import ReceivedGoodsStore from 'pod/receivedGoods/ReceivedGoodsStore';
import DeliveryLabFiltersStore from 'pod/deliveries/DeliveryLabFiltersStore';
import ViewStore from 'stores/ViewStore';
import DashboardFiltersStore from 'stores/DashboardFilterStore';
import VersionStore from 'stores/VersionStore';
import DeviceTokenStore from 'stores/DeviceTokenStore';
import EmployeeStore from 'stores/EmployeeStore';
import LasernetPrinterStore from 'stores/LasernetPrinterStore';

export default class RootStore {
  public commonStore: CommonStore;
  public layoutStore: LayoutStore;
  public sessionStore: SessionStore;
  public viewStore: ViewStore;
  public adminStore: AdminStore;
  public advisedGoodsStore: AdvisedGoodsStore;
  public advisedGoodLabStore: AdvisedGoodLabStore;
  public deliveryFiltersStore: DeliveryFiltersStore;
  public deliveryLabFiltersStore: DeliveryLabFiltersStore;
  public labStore: LabStore;
  public deliveryStore: DeliveryStore;
  public superAdminStore: SuperAdminStore;
  public dashboardStore: DashboardStore;
  public dashboardFilterStore: DashboardFiltersStore;
  public contractStore: ContractStore;
  public receivedGoodsStore: ReceivedGoodsStore;
  public versionStore: VersionStore;
  public deviceTokenStore: DeviceTokenStore;
  public employeeStore: EmployeeStore;
  public lasernetPrinterStore: LasernetPrinterStore;
}
