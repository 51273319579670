import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FormGroup, Col, Label, Input, Row } from 'reactstrap';
import AdvisedGoodsModel from 'models/AdvisedGoodsModel';
import SessionStore from 'stores/SessionStore';
import ViewStore from 'stores/ViewStore';
import TranslateService from 'services/TranslateService';
import SortingReportDetailSection from 'pod/receivedGoods/SortingReportDetailSection';
import InputNumber from 'components/InputNumber';
import { inputNumberStep, processDecimals } from 'util/helpers';
import { AdvisedGoodStatus } from 'util/enums';
import MeasurementModel from 'models/MeasurementModel';
import { NUMBER_OF_DECIMALS_MEASUREMENT } from 'util/constants';

interface IMeasurementProps {
  measurement: MeasurementModel;
  advisedGood: AdvisedGoodsModel;
  isReadOnly: boolean;
  sessionStore?: SessionStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
}

class MeasurementComponent extends React.Component<IMeasurementProps> {
  public render() {
    const {
      measurement,
      translateService: { t },
      viewStore: { isFR },
    } = this.props;
    const humidity = isFR ? measurement.humidityWithTare : measurement.humidity;
    return (
      <SortingReportDetailSection title={t.SORTINGREPORT_MEASUREMENT_TITLE}>
        <FormGroup row className="padding-left-right-20">
          <Col md={2} xs={5} className="align-self-center padding-none">
            <Label>{t.SORTINGREPORT_MEASUREMENT_SAMPLE_WET}</Label>
          </Col>
          <Col md={2} xs={4} className="padding-only-right-15">
            <InputNumber
              data-test="sample-wet"
              disabled={!this._isMeasurementEnabled}
              value={measurement.sampleWet}
              decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
              step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
              onChangeValue={(number: number) => measurement.setSampleWet(number)}
            />
          </Col>
          {isFR && (
            <React.Fragment>
              <Col md={2} xs={5} className="align-self-center padding-none">
                <Label>{t.SORTINGREPORT_MEASUREMENT_CONTAINER}</Label>
              </Col>
              <Col md={2} xs={4} className="padding-only-right-15">
                <InputNumber
                  data-test="container"
                  disabled={!this._isMeasurementEnabled}
                  value={measurement.container}
                  decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
                  step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
                  onChangeValue={(number: number) => measurement.setContainer(number)}
                />
              </Col>
            </React.Fragment>
          )}
          <Col md={2} xs={5} className="align-self-center padding-none">
            <Label>{t.SORTINGREPORT_MEASUREMENT_SAMPLE_DRY}</Label>
          </Col>
          <Col md={2} xs={4} className="padding-only-right-15">
            <InputNumber
              data-test="sample-dry"
              disabled={!this._isMeasurementEnabled}
              value={measurement.sampleDry}
              decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
              step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
              onChangeValue={(number: number) => measurement.setSampleDry(number)}
            />
          </Col>
          <div className={isFR ? 'humidity-france' : 'humidity-default'}>
            <Col md={2} xs={5} className="align-self-center padding-none">
              <Label>{t.SORTINGREPORT_MEASUREMENT_HUMIDITY}</Label>
            </Col>
            <Col md={2} xs={4} className="padding-only-right-15">
              <Input
                data-test="humidity"
                className="text-end"
                disabled={true}
                value={humidity ? `${processDecimals(humidity, NUMBER_OF_DECIMALS_MEASUREMENT)} %` : ''}
              />
            </Col>
          </div>
        </FormGroup>
        <FormGroup row className="padding-left-right-20">
          <Col md={2} xs={5} className="align-self-center padding-none">
            <Label>{t.SORTINGREPORT_MEASUREMENT_FOR_MELTING}</Label>
          </Col>
          <Col md={2} xs={4} className="padding-only-right-15">
            <InputNumber
              data-test="for-melting"
              disabled={!this._isMeasurementEnabled}
              value={measurement.forMelting}
              decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
              step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
              onChangeValue={(number: number) => measurement.setForMelting(number)}
            />
          </Col>
          <Col md={2} xs={5} className="align-self-center padding-none">
            <Label>{t.SORTINGREPORT_MEASUREMENT_INGOT}</Label>
          </Col>
          <Col md={2} xs={4} className="padding-only-right-15">
            <InputNumber
              data-test="ingot"
              disabled={!this._isMeasurementEnabled}
              value={measurement.ingot}
              decimalPlaces={NUMBER_OF_DECIMALS_MEASUREMENT}
              step={inputNumberStep(NUMBER_OF_DECIMALS_MEASUREMENT)}
              onChangeValue={(number: number) => measurement.setIngot(number)}
            />
          </Col>
          <Col md={2} xs={5} className="align-self-center padding-none">
            <Label>{t.SORTINGREPORT_MEASUREMENT_YIELD}</Label>
          </Col>
          <Col md={2} xs={4} className="padding-only-right-15">
            <Input
              data-test="yield"
              className="text-end"
              disabled={true}
              value={measurement.yield ? `${processDecimals(measurement.yield, NUMBER_OF_DECIMALS_MEASUREMENT)} %` : ''}
            />
          </Col>
        </FormGroup>
      </SortingReportDetailSection>
    );
  }

  private get _isMeasurementEnabled() {
    const {
      isReadOnly,
      sessionStore: { isTier2, isTier3 },
      advisedGood: { status },
    } = this.props;
    return (
      !isReadOnly &&
      ((isTier2 && [AdvisedGoodStatus.UNLOADED, AdvisedGoodStatus.READY_FOR_TIER_2].includes(status)) ||
        (isTier3 && status === AdvisedGoodStatus.UNLOADED))
    );
  }
}

export default inject('viewStore', 'sessionStore', 'translateService')(observer(MeasurementComponent));
