import * as React from 'react';
import * as classnames from 'classnames';
import { InputGroup, ListGroup, ListGroupItem } from 'reactstrap';
import { reverse } from 'lodash';
import * as FontAwesome from 'react-fontawesome';
import { toJS } from 'mobx';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import { Position } from 'util/enums';
import TranslateService from 'services/TranslateService';

export interface IAutoCompleteItem {
  id?: number | string;
  name?: string;
  code?: string;
  active?: boolean;
}

export interface IAutocompleteProps {
  items: IAutoCompleteItem[]; // ITEMS HAVE TO CONTAIN OBJECT LIKE {id: XXX, name: YYY, ...restOfObjectDoesNotMatter}
  onSelect: (ret: object | string) => void; // return objec onClick on autocomplete suggestion
  disabled?: boolean;
  highlight?: boolean; // onBlur, select text in <input>
  onChange?: (value: string) => void; // RETURN onChange e.currectTarget.value and use autocomplete also as regular inputfield
  selectedValue?: string; // THIS HAVE TO BE ID
  useCodeAsValue?: boolean;
  showOnTop?: boolean;
  tabIndex?: number;
  placeholder?: string;
  size?: string;
  hasClearButton?: boolean;
  onClearCallback?: () => void;
  inputClassName?: string;
  maxLength?: number;
  charactersRemainingPosition?: Position;
  translateService?: TranslateService;
  id?: string;
  displayCodeAndName?: boolean;
  selectedName?: string; // it is needed for hidden items, to display name instead id
  changeValueOnSelectOnly?: boolean;
}

interface IState {
  firstFocus: boolean; // USED FOR SHOWING ALL ITEMS, OR FILTERED ITEMS
  isFocused: boolean; // USED FOR SHOW / HIDE ITEMS LIST
  searchValue: string;
}

// AUTOCOMPLETE COMPONENT
export default class Autocomplete extends React.Component<IAutocompleteProps, IState> {
  public static defaultProps: Partial<IAutocompleteProps> = {
    highlight: false,
    showOnTop: false,
    placeholder: 'Select',
  };

  // REFS
  private _searchInput: HTMLInputElement | HTMLTextAreaElement;
  private _timer: number;

  public constructor(props: IAutocompleteProps) {
    super(props);
    this.state = {
      firstFocus: false,
      isFocused: false,
      searchValue: '',
    };
  }

  public componentDidMount() {
    const { selectedValue, onChange } = this.props;

    if (onChange) {
      // IF ONCHANGE FUNC EXISTS, USE IT AS DEFAULT SEARCH
      this.setState({ searchValue: selectedValue });
    } else if (selectedValue) {
      // CHECK IF WE HAVE selectedValue; IF YES, PREFILL searchValue
      const { items } = this.props;
      const foundValue = items.find((item: IAutoCompleteItem) => item.id === selectedValue);

      this._setSearchValue(foundValue);
    }
  }

  public componentDidUpdate(prevProps: IAutocompleteProps) {
    if (prevProps.selectedValue !== this.props.selectedValue) {
      let newSearchValue: IAutoCompleteItem;
      if (this.props.selectedValue) {
        newSearchValue = this.props.items.find(
          (item) =>
            item.code === this.props.selectedValue ||
            item.name === this.props.selectedValue ||
            item.id === this.props.selectedValue
        );
      }

      this._setSearchValue(newSearchValue);
    }
  }

  public componentWillUnmount(): void {
    if (this._timer) {
      clearTimeout(this._timer);
    }
  }

  public render() {
    const { searchValue, isFocused } = this.state;
    const {
      disabled,
      placeholder,
      size,
      hasClearButton,
      selectedValue,
      inputClassName,
      tabIndex,
      maxLength,
      charactersRemainingPosition,
      id,
    } = this.props;

    const isDisabled = disabled ? disabled : false;

    return (
      <div className="autocomplete" onScroll={this._scrollRenderItemList}>
        {/* RENDER AUTOCOMPLETE INPUT */}
        <InputGroup size={size}>
          <InputWithLimitOfCharacters
            className={classnames([inputClassName, { 'padding-right-20': hasClearButton }])}
            data-test="autocomplete-input"
            tabIndex={tabIndex}
            disabled={isDisabled}
            innerRef={(r) => (this._searchInput = r)}
            onBlur={this._searchInputOnBlur}
            onChange={this._searchInputOnChange}
            onFocus={this._searchInputOnFocus}
            placeholder={placeholder}
            type="search"
            value={searchValue}
            maxLength={maxLength}
            position={charactersRemainingPosition}
            id={id}
          />
          {hasClearButton && selectedValue && (
            <button data-test="autocomplete-clear-button" className="clear-btn" onClick={this._clearInputValue}>
              <FontAwesome name="remove" />
            </button>
          )}
        </InputGroup>

        {/* RENDER AUTOCOMPLETE LIST */}
        {isFocused && this._renderItemList()}
      </div>
    );
  }

  private _processValue = (item: IAutoCompleteItem) => {
    const { useCodeAsValue } = this.props;
    return useCodeAsValue ? item.code : item.name;
  };

  private _processDisplayValue = (item: IAutoCompleteItem) => {
    const { displayCodeAndName } = this.props;
    if (displayCodeAndName && item.code && item.name) {
      return `${item.code} ${item.name}`;
    }
    return this._processValue(item);
  };

  // SET SELECTED ITEM AND SEND onSelect TO PARENT COMPONENT (id IS GOING TO BE SEND)
  private _onItemSelect = (selectedItem: IAutoCompleteItem) => {
    const { onSelect, changeValueOnSelectOnly } = this.props;
    this.setState(
      {
        ...(changeValueOnSelectOnly ? undefined : { searchValue: this._processValue(selectedItem) }),
        isFocused: false,
      },
      () => {
        onSelect(selectedItem);
      }
    );
  };

  // RENDER LIST OF AUTOCOMPLETE ITEMS; CONTAINS LOGIC TO SHOW WHOLE LIST OR FILTERED LIST
  private _renderItemList = () => {
    const { items, showOnTop } = this.props;
    const { searchValue, firstFocus } = this.state;
    const activeItems = items.filter((i) => typeof i.active === 'undefined' || i.active === true);
    let listToShow =
      searchValue && searchValue !== '' && !firstFocus
        ? activeItems.filter(
            (item: IAutoCompleteItem) =>
              this._processDisplayValue(item).toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          )
        : activeItems;

    if (showOnTop) {
      // this need to prevent mutation of observable array
      listToShow = reverse(toJS(listToShow));
    }

    return (
      <ListGroup className={classnames(['autocomplete-list', { showOnTop }])}>
        {listToShow &&
          listToShow.map((item: IAutoCompleteItem) => (
            <ListGroupItem
              data-test="autocomplete-option"
              onClick={() => this._onItemSelect(item)}
              active={this._processValue(item) === searchValue}
              key={item.id}
            >
              {this._processDisplayValue(item)}
            </ListGroupItem>
          ))}
        {listToShow.length === 0 && (
          <ListGroupItem color={this.props.onChange ? 'info' : 'danger'} className="no-hover">
            Not found
          </ListGroupItem>
        )}
      </ListGroup>
    );
  };

  // SET INPUT onChange
  private _searchInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    this.setState({
      firstFocus: false,
      searchValue: e.target.value,
    });

    if (onChange) {
      onChange(e.target.value);
    }
  };

  // SET INPUT onBlur AND FORCE CLOSE ITEMS LIST
  private _searchInputOnBlur = () => {
    const { items } = this.props;
    const { searchValue, firstFocus } = this.state;
    const listToShow =
      searchValue && searchValue !== '' && !firstFocus
        ? items.filter(
            (item: IAutoCompleteItem) =>
              this._processDisplayValue(item).toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          )
        : items;

    // WITHOUT setTimeout, onItemSelect FUNCTION IS NOT ABLE TO SAVE CLICK TARGET
    this._timer = window.setTimeout(
      () =>
        this.setState({ isFocused: false }, () => {
          if (!listToShow.length && !this.props.onChange) {
            this.props.onSelect({
              id: '',
              name: '',
            });
          }
        }),
      250
    );
  };

  private _searchInputOnFocus = () => {
    const { searchValue } = this.state;
    const { highlight } = this.props;

    this.setState({ isFocused: true, firstFocus: true });

    // HIGHLIGHT / MARK INPUT FIELD CONTENT
    if (highlight) {
      this._searchInput.setSelectionRange(0, searchValue.length);
    }
  };

  private _scrollRenderItemList: () => void = () => {
    clearTimeout(this._timer);
    this._searchInput.focus();
  };

  private _clearInputValue = () => {
    this.setState({ searchValue: '' }, () => {
      this.props.onSelect(null);
    });
    this.props.onClearCallback && this.props.onClearCallback();
  };

  private _setSearchValue = (newSearchValue: IAutoCompleteItem) => {
    const { selectedName, selectedValue } = this.props;
    if (newSearchValue) {
      this.setState({ searchValue: this._processValue(newSearchValue) });
    } else {
      this.setState({ searchValue: selectedName ? selectedName : selectedValue });
    }
  };
}
