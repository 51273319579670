import { action, computed, makeObservable, observable } from 'mobx';

import { IConstructSaveObj, ILasernetDocumentTypeConstructObj } from 'models/ModelInterfaces';
import UpdateModel from 'models/UpdateModel';

export default class LasernetDocumentTypeModel extends UpdateModel<LasernetDocumentTypeModel>
  implements IConstructSaveObj<ILasernetDocumentTypeConstructObj> {
  public defaultNumberOfCopies: number = null;
  public lasernetReportName: string = '';
  public documentTypeName: string = '';
  public id: string = '';

  public constructor() {
    super();
    makeObservable(this, {
      defaultNumberOfCopies: observable,
      lasernetReportName: observable,
      documentTypeName: observable,
      id: observable,
      constructSaveObj: computed,
      update: action,
      changeNumberOfCopies: action,
      changeReportName: action,
    });
  }

  public get constructSaveObj(): ILasernetDocumentTypeConstructObj {
    return {
      defaultNumberOfCopies: this.defaultNumberOfCopies,
      lasernetReportName: this.lasernetReportName,
    } as ILasernetDocumentTypeConstructObj;
  }

  public update = (obj: LasernetDocumentTypeModel) => {
    this.updater.update(this, obj, LasernetDocumentTypeModel);
    return this;
  };

  public changeNumberOfCopies = (newNumber: number) => (this.defaultNumberOfCopies = newNumber);
  public changeReportName = (newName: string) => (this.lasernetReportName = newName);
}
