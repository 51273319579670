import { action, computed, observable, makeObservable } from 'mobx';
import IdCodeModel from 'models/IdCodeModel';
import { IActive, IConstructSaveObj } from 'models/ModelInterfaces';

export default class IdCodeActiveModel extends IdCodeModel implements IActive, IConstructSaveObj<IdCodeActiveModel> {
  public active: boolean = false;

  public update = (obj: IdCodeActiveModel) => {
    this.updater.update(this, super.update(obj), IdCodeActiveModel);
    return this;
  };

  constructor() {
    super();

    makeObservable(this, {
      active: observable,
      constructSaveObj: computed,
      changeActiveStatus: action,
    });
  }

  public get constructSaveObj(): IdCodeActiveModel {
    return {
      code: this.code,
      active: this.active,
    } as IdCodeActiveModel;
  }

  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);
}
