import { action, computed, observable, reaction, makeObservable } from 'mobx';
import FilterAbstractStore from 'stores/FilterAbstractStore';
import DeliveryCommonFilterStore, {
  ICommonFiltersApplied,
  ICommonQueryParams,
} from 'pod/deliveries/DeliveryCommonFilterStore';
import RootService from 'services/RootService';

interface ILabFilters extends ICommonFiltersApplied {
  completed: boolean;
}

interface ILabQueryParams extends ICommonQueryParams {
  completed: boolean;
}

export default class DeliveryLabFiltersStore extends FilterAbstractStore<ILabFilters, ILabQueryParams> {
  public deliveryCommonFilterStore: DeliveryCommonFilterStore;

  public constructor(private readonly rootService: RootService) {
    super();

    makeObservable(this, {
      filterShowCompleted: observable,
      getFiltersApplied: computed,
      getQueryParams: computed,
      changeFilterShowCompleted: action,
    });

    this.deliveryCommonFilterStore = new DeliveryCommonFilterStore(this.rootService);

    reaction(
      () => this.hasFiltersApplied,
      () => {
        this.deliveryCommonFilterStore.changeOffset(0);
      }
    );
  }

  public filterShowCompleted: boolean = false;

  public get getFiltersApplied(): ILabFilters {
    return {
      completed: this.filterShowCompleted,
      ...this.deliveryCommonFilterStore.getFiltersApplied,
    };
  }

  public get getQueryParams(): ILabQueryParams {
    return {
      completed: this.filterShowCompleted,
      ...this.deliveryCommonFilterStore.getQueryParams,
    };
  }

  public changeFilterShowCompleted = (filterShowCompleted: boolean) => (this.filterShowCompleted = filterShowCompleted);
}
