import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';

import { TAdminCommonItem } from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import AdminDetailHideUnhideComponent, { IContent } from 'pod/admin/commonComponents/AdminDetailHideUnhideComponent';
import CommonAdminDetailComponent from 'pod/admin/commonComponents/CommonAdminDetailComponent';
import CommonApiCalls from 'pod/admin/commonComponents/CommonApiCalls';
import { AdminRootUrl } from 'util/enums';

interface IQuery {
  id: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  rootUrl: AdminRootUrl;
  sectionNameTranslation: string;
  content: TAdminCommonItem;
  itemsList: TAdminCommonItem[];
  api: CommonApiCalls<TAdminCommonItem>;
  updateItem: (newItem: TAdminCommonItem) => void;
  isCodeRequired?: boolean;
  limit?: number;
  children?: React.ReactNode;
  translateService?: TranslateService;
}

class CommonAdminRoute extends React.Component<IProps> {
  public componentDidMount() {
    const {
      match: {
        params: { id: supplierId },
      },
      api,
      itemsList,
    } = this.props;
    this._setContent(supplierId);
    if (!itemsList) {
      api.getAllItems();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      match: {
        params: { id: newItem },
      },
    } = this.props;
    const {
      match: {
        params: { id: prevItem },
      },
    } = prevProps;

    if (newItem !== prevItem) {
      this._setContent(newItem);
    }
  }

  public updateItemDetail = (item: TAdminCommonItem) => {
    const {
      api,
      translateService: { t },
      sectionNameTranslation,
      rootUrl,
    } = this.props;
    return api.putItemById(item).then(() => {
      notification.success(t.ADMIN_COMMONDETAIL_UPDATE_MSG(sectionNameTranslation));
      this.props.history.push(rootUrl);
    });
  };

  public hideItem = () => {
    const {
      api,
      translateService: { t },
      itemsList,
      rootUrl,
      content,
      sectionNameTranslation,
    } = this.props;
    api.hideItemById(itemsList, content.id, t.ADMIN_COMMONDETAIL_ERROR_MSG(sectionNameTranslation)).then(() => {
      notification.success(t.ADMIN_COMMONDETAIL_HIDE_MSG(sectionNameTranslation));
      this.props.history.push(rootUrl);
    });
  };

  public unhideItem = () => {
    const {
      api,
      translateService: { t },
      itemsList,
      rootUrl,
      content,
      sectionNameTranslation,
    } = this.props;
    api.unhideItemById(itemsList, content.id, t.ADMIN_COMMONDETAIL_ERROR_MSG(sectionNameTranslation)).then(() => {
      notification.success(t.ADMIN_COMMONDETAIL_UNHIDE_MSG(sectionNameTranslation));
      this.props.history.push(rootUrl);
    });
  };

  public render() {
    const {
      translateService: { t },
      rootUrl,
      content,
      children,
      sectionNameTranslation,
      limit,
      isCodeRequired,
    } = this.props;
    if (!content) {
      return null;
    }
    const header = t.ADMIN_COMMONDETAIL_HEADER(sectionNameTranslation);
    const hideConfirmationHeader = t.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_HEADER(sectionNameTranslation);
    const hideConfirmationTextFunction = t.ADMIN_COMMONDETAIL_HIDE_CONFIRMATION_TEXT;
    const unhideConfirmationHeader = t.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_HEADER(sectionNameTranslation);
    const unhideConfirmationTextFunction = t.ADMIN_COMMONDETAIL_UNHIDE_CONFIRMATION_TEXT;

    return (
      <AdminDetailHideUnhideComponent
        content={content as IContent}
        sidebarHeader={header}
        closeRoute={rootUrl}
        hideConfirmationHeader={hideConfirmationHeader}
        hideConfirmationTextFunction={hideConfirmationTextFunction}
        unhideConfirmationHeader={unhideConfirmationHeader}
        unhideConfirmationTextFunction={unhideConfirmationTextFunction}
        hideItem={this.hideItem}
        unhideItem={this.unhideItem}
      >
        <CommonAdminDetailComponent
          content={content}
          saveAction={this.updateItemDetail}
          limit={limit}
          isCodeRequired={isCodeRequired}
        >
          {children}
        </CommonAdminDetailComponent>
      </AdminDetailHideUnhideComponent>
    );
  }

  private _setContent = (itemId: string) => {
    const { api, updateItem } = this.props;
    api.getItemById(itemId).then((res) => {
      updateItem(res);
    });
  };
}

export default withRouter(inject('translateService')(observer(CommonAdminRoute)));
