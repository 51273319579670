import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as H from 'history';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { action, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';

import SuperAdminStore from 'pod/superAdmin/SuperAdminStore';
import TenantModel from 'models/TenantModel';
import TranslateService from 'services/TranslateService';
import UserModel from 'models/UserModel';
import CommonAdminDetailComponent from 'pod/admin/commonComponents/CommonAdminDetailComponent';
import { joinWithCommaSeparator } from 'util/helpers';
import UserTenantModel from 'models/UserTenantModel';
import AdminDetailComponent from 'pod/superAdmin/admins/AdminDetailComponent';
import * as classnames from 'classnames';
import ModalConfirmation from 'domain/ModalConfirmation';
import EmployeeStore from 'stores/EmployeeStore';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

interface IProps {
  history: H.History;
  superAdminStore?: SuperAdminStore;
  translateService?: TranslateService;
  employeeStore?: EmployeeStore;
}

class AdminListRoute extends React.Component<IProps> {
  public addAdminModal: boolean = false;
  public admin: UserModel<IUserModelConstructObj> = new UserModel<IUserModelConstructObj>();
  private _hideConfirmationModal: boolean = false;
  private _unhideConfirmationModal: boolean = false;

  constructor(props: IProps) {
    super(props);

    makeObservable<
      AdminListRoute,
      | '_hideConfirmationModal'
      | '_unhideConfirmationModal'
      | '_setAddAdminModal'
      | '_setNewAdminModel'
      | '_toggleHideConfirmationModal'
      | '_toggleUnhideConfirmationModal'
    >(this, {
      addAdminModal: observable,
      admin: observable,
      _hideConfirmationModal: observable,
      _unhideConfirmationModal: observable,
      _setAddAdminModal: action,
      _setNewAdminModel: action,
      _toggleHideConfirmationModal: action,
      _toggleUnhideConfirmationModal: action,
    });
  }

  public componentDidMount() {
    const { superAdminStore, employeeStore } = this.props;
    superAdminStore.getTenants();
    superAdminStore.getAdmins();
    employeeStore.getEmployeeItems();
  }

  // SHOW / HIDE MODAL WINDOW
  public toggleAddAdminModal = () => {
    this._setAddAdminModal(!this.addAdminModal);
    if (!this.addAdminModal) {
      this._setNewAdminModel(new UserModel<IUserModelConstructObj>());
    }
  };

  public saveNewAdmin = (admin: UserModel<IUserModelConstructObj>) => {
    const {
      superAdminStore,
      translateService: { t },
    } = this.props;
    return superAdminStore.postAdmin(admin).then(() => {
      this._updateOnSave();
      notification.success(t.SUPERADMIN_TENANTADMIN_ADD_MSG);
    });
  };

  public putAdmin = (admin: UserModel<IUserModelConstructObj>) => {
    const {
      superAdminStore,
      translateService: { t },
    } = this.props;
    return superAdminStore.putAdmin(admin).then(() => {
      this._updateOnSave();
      notification.success(t.SUPERADMIN_TENANTADMIN_UPDATE_MSG);
    });
  };

  public hideAdmin = () => {
    return this.props.superAdminStore.hideAdminById(this.props.superAdminStore.admins, this.admin.id).then(() => {
      this.admin.changeActiveStatus(false);
      notification.success(this.props.translateService.t.ADMIN_COMMONDETAIL_HIDE_MSG(this.admin.fullName));
    });
  };

  public unhideAdmin = () => {
    return this.props.superAdminStore.unhideAdminById(this.props.superAdminStore.admins, this.admin.id).then(() => {
      this.admin.changeActiveStatus(true);
      notification.success(this.props.translateService.t.ADMIN_COMMONDETAIL_UNHIDE_MSG(this.admin.fullName));
    });
  };

  public render() {
    const {
      superAdminStore: { adminListActiveFirst },
      translateService: { t },
    } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col xs="12">
            <h2 className="clearfix">
              {t.SUPERADMIN_TENANTADMIN_ADMINS}
              <Button
                data-test="add-new-admin-button"
                className="float-end"
                color="success"
                onClick={this.toggleAddAdminModal}
              >
                <FontAwesome name="plus" className="me-2" />
                {t.GLOBAL_LABEL_ADD_NEW}
              </Button>
            </h2>
            <table className="custom-table mb-3">
              <thead>
                <tr>
                  <th className="ps-3">{t.GLOBAL_LABEL_NAME}</th>
                  <th className="pe-3">{t.GLOBAL_LABEL_USERNAME}</th>
                  <th>{t.SUPERADMIN_TENANTLIST_HEADER}</th>
                </tr>
              </thead>
              {adminListActiveFirst.map((admin: UserModel<IUserModelConstructObj>) => (
                <tbody key={admin.id} data-test="admin-table-item">
                  <tr
                    data-test={`admin-table-item-${admin.username}`}
                    onClick={this._onAdminClick(admin.id)}
                    className={classnames(['pointer', { 'bg-secondary': !admin.active }])}
                  >
                    <td className="ps-3">{admin.fullName}</td>
                    <td className="pe-3">{admin.username}</td>
                    <td>{joinWithCommaSeparator(admin.tenants.map((t: UserTenantModel) => t.name))}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Col>
        </Row>

        {/* MODAL - CREATE NEW TENANT */}
        <Modal
          isOpen={this.addAdminModal}
          toggle={this.toggleAddAdminModal}
          backdrop="static"
          data-test="create-admin-modal"
        >
          <ModalHeader toggle={this.toggleAddAdminModal}>{t.SUPERADMIN_TENANTADMIN_HEADER}</ModalHeader>
          <ModalBody>
            <CommonAdminDetailComponent
              content={this.admin}
              saveAction={!!this.admin.id ? this.putAdmin : this.saveNewAdmin}
              toggleHideConfirmationModal={!!this.admin.id ? this._toggleHideConfirmationModal : undefined}
              toggleUnhideConfirmationModal={!!this.admin.id ? this._toggleUnhideConfirmationModal : undefined}
            >
              <AdminDetailComponent content={this.admin} tenants={this.tenants} />
            </CommonAdminDetailComponent>
          </ModalBody>
        </Modal>

        <ModalConfirmation
          data-test="hide-confirm-modal"
          buttonYesColor="success"
          callbackYes={() => this.hideAdmin()}
          heading={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_HIDE}
          ico="eye-slash"
          isOpen={this._hideConfirmationModal}
          text={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_HIDE(this.admin.fullName)}
          toggleModal={this._toggleHideConfirmationModal}
        />

        <ModalConfirmation
          data-test="unhide-confirm-modal"
          buttonYesColor="success"
          callbackYes={() => this.unhideAdmin()}
          heading={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_HEADER_UNHIDE}
          ico="eye"
          isOpen={this._unhideConfirmationModal}
          text={t.SUPERADMIN_TENANTADMINLINE_CONFIRMATION_TEXT_UNHIDE(this.admin.fullName)}
          toggleModal={this._toggleUnhideConfirmationModal}
        />
      </Container>
    );
  }

  private _updateOnSave = () => {
    this.props.superAdminStore.getAdmins();
    this.toggleAddAdminModal();
    this._setNewAdminModel(new UserModel<IUserModelConstructObj>());
  };

  private get tenants(): UserTenantModel[] {
    const {
      superAdminStore: { activeTenants },
    } = this.props;
    if (!!activeTenants && activeTenants.length > 0) {
      return activeTenants.map((t: TenantModel) => {
        const userTenantModel = new UserTenantModel();
        userTenantModel.changeName(t.name);
        userTenantModel.id = t.id;
        userTenantModel.code = t.code;
        userTenantModel.regionCode = t.regionCode;
        userTenantModel.workflowCode = t.workflow.code;
        return userTenantModel;
      });
    }
    return [];
  }

  private _onAdminClick = (id: string) => async () => {
    const { superAdminStore } = this.props;
    const admin = superAdminStore.getAdminById(id);
    if (!!admin) {
      const adminModel = new UserModel<IUserModelConstructObj>().update(admin);
      this._setNewAdminModel(adminModel);
      this.toggleAddAdminModal();
    }
  };

  private _setAddAdminModal = (val: boolean) => {
    this.addAdminModal = val;
  };

  private _setNewAdminModel = (val: UserModel<IUserModelConstructObj>) => {
    this.admin = val;
  };

  private _toggleHideConfirmationModal = () => {
    this._hideConfirmationModal = !this._hideConfirmationModal;
  };

  private _toggleUnhideConfirmationModal = () => {
    this._unhideConfirmationModal = !this._unhideConfirmationModal;
  };
}

export default inject('superAdminStore', 'translateService', 'employeeStore')(observer(AdminListRoute));
