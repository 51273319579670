import { action, computed, observable, makeObservable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import LabStatusModel from 'models/LabStatusModel';
import MaterialModel from 'models/MaterialModel';
import PrinterModel from 'models/PrinterModel';
import RoadHaulierModel from 'models/RoadHaulierModel';
import StockItemModel from 'models/StockItemModel';
import SupplierModel from 'models/SupplierModel';
import UpdateModel from 'models/UpdateModel';
import RootService from 'services/RootService';
import UserModel from 'models/UserModel';
import { DepartmentCode } from 'util/enums';
import { addBracketsWithContentToString, cloneObj, PromiseWrapper, promiseWrapper } from 'util/helpers';
import IdCodeModel from 'models/IdCodeModel';
import IdCodeActiveModel from 'models/IdCodeActiveModel';
import ScaleModel from 'models/ScaleModel';
import IdCodeNameModel from 'models/IdCodeNameModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';
import LasernetDocumentTypeModel from 'models/LasernetDocumentTypeModel';
import IdNameActiveModel from 'models/IdNameActiveModel';

export type ICommonData = ICommon & ICommonLab & ICommonSearch;

interface ICommon {
  materials?: MaterialModel[];
  printers?: PrinterModel[];
  roadHauliers?: RoadHaulierModel[];
  roadHauliersWithCertificateDates?: RoadHaulierModel[];
  stockItems?: StockItemModel[];
  suppliers?: SupplierModel[];
  suppliersWithCertificateDates?: SupplierModel[];
  yardLocations?: IdNameActiveModel[];
  traders?: UserModel<IUserModelConstructObj>[];
  tradersForDeliveryFilter?: UserModel<IUserModelConstructObj>[];
  responsibleUsers?: UserModel<IUserModelConstructObj>[];
  departmentCodes?: DepartmentCode[];
  ewcs?: IdCodeModel[];
  origins?: IdCodeActiveModel[];
  scales?: ScaleModel[];
  suppliersWithCodes?: SupplierModel[];
  documentTypes?: LasernetDocumentTypeModel[];
}

interface ICommonLab {
  labStatuses?: LabStatusModel[];
  labInputTypes?: IdNameModel[];
}

interface ICommonSearch {
  findSupplierByCode?: (supplierCode: string) => SupplierModel;
  findStockItemByCode?: (stockItemCode: string) => StockItemModel;
  findTraderByCode?: (traderCode: string) => IdCodeNameModel;
}

interface ITransactionTypes {
  values: string[];
  defaultTransactionType: string;
}

export interface IDeliveryCommonResponse {
  deliveryTypes: string[];
  weatherConditions: string[];
  transactionTypes: ITransactionTypes;
}

export default class CommonStore extends UpdateModel<CommonStore | ICommonData, undefined, RootService> {
  public isDataLoaded: boolean = false;
  public isLabDataLoaded: boolean = false;
  public materials: MaterialModel[] = [];
  public labStatuses: LabStatusModel[] = [];
  public labInputTypes: IdNameModel[] = [];
  public printers: PrinterModel[] = [];
  public roadHauliers: RoadHaulierModel[] = [];
  public stockItems: StockItemModel[] = [];
  public suppliers: SupplierModel[] = [];
  public yardLocations: IdNameActiveModel[] = [];
  public traders: UserModel<IUserModelConstructObj>[] = [];
  public tradersForDeliveryFilter: UserModel<IUserModelConstructObj>[] = [];
  public responsibleUsers: UserModel<IUserModelConstructObj>[] = [];
  public departmentCodes: DepartmentCode[] = [];
  public ewcs: IdCodeModel[] = [];
  public origins: IdCodeActiveModel[] = [];
  public scales: ScaleModel[] = [];
  public productFormList: Map<string, IdNameModel[]> = new Map();
  public qualityList: Map<string, IdNameModel[]> = new Map();
  public documentTypes: LasernetDocumentTypeModel[] = [];
  public deliveryWeatherConditionList: string[] = [];
  public deliveryTypeList: string[] = [];
  public deliveryTransactionTypeList: string[] = [];
  public defaultTransactionType: string = '';

  public constructor(private readonly rootService: RootService) {
    super();

    makeObservable<
      CommonStore,
      | '_setDeliveryWeatherConditionList'
      | '_setDeliveryTransactionTypeList'
      | '_setDefaultTransactionType'
      | '_setDeliveryTypeList'
      | '_getProductFormList'
      | '_getProductQualityList'
    >(this, {
      isDataLoaded: observable,
      isLabDataLoaded: observable,
      materials: observable,
      labStatuses: observable,
      labInputTypes: observable,
      printers: observable,
      roadHauliers: observable,
      stockItems: observable,
      suppliers: observable,
      yardLocations: observable,
      traders: observable,
      tradersForDeliveryFilter: observable,
      responsibleUsers: observable,
      departmentCodes: observable,
      ewcs: observable,
      origins: observable,
      scales: observable,
      productFormList: observable,
      qualityList: observable,
      documentTypes: observable,
      suppliersWithCertificateDates: computed,
      suppliersWithCodes: computed,
      roadHauliersWithCertificateDates: computed,
      nonDefaultMaterial: computed,
      defaultMaterials: computed,
      tradersList: computed,
      tradersForDeliveryFilterList: computed,
      responsibleUsersList: computed,
      common: computed,
      update: action,
      findSupplierByCode: action,
      findStockItemByCode: action,
      findTraderByCode: action,
      changeIsDataLoaded: action,
      changeIsLabDataLoaded: action,
      _setDeliveryWeatherConditionList: action,
      _setDeliveryTransactionTypeList: action,
      _setDefaultTransactionType: action,
      _setDeliveryTypeList: action,
      _getProductFormList: action,
      _getProductQualityList: action,
    });
  }

  public get suppliersWithCertificateDates(): SupplierModel[] {
    const { formatDate } = this.rootService.dateFormattingService;
    return this.suppliers.length
      ? this.suppliers.map((s: SupplierModel) => {
          const newSupplier = new SupplierModel().update(s);
          newSupplier.name = addBracketsWithContentToString(
            newSupplier.name.toUpperCase(),
            formatDate(newSupplier.formattedCertAutRifDate),
            formatDate(newSupplier.formattedExpirationDate)
          );
          return newSupplier;
        })
      : [];
  }

  public get suppliersWithCodes(): SupplierModel[] {
    return this.suppliers.length
      ? this.suppliers.map((s: SupplierModel) => {
          const newSupplier = new SupplierModel().update(s);
          newSupplier.name = `${newSupplier.code} ${newSupplier.name}`;
          return newSupplier;
        })
      : [];
  }

  public get roadHauliersWithCertificateDates(): RoadHaulierModel[] {
    const { formatDate } = this.rootService.dateFormattingService;
    return this.roadHauliers.length
      ? this.roadHauliers.map((rh: RoadHaulierModel) => {
          const newRoadHaulier = new RoadHaulierModel().update(rh);
          newRoadHaulier.name = addBracketsWithContentToString(
            newRoadHaulier.name.toUpperCase(),
            newRoadHaulier.authorizationNumber,
            formatDate(newRoadHaulier.formattedExpirationDate)
          );
          return newRoadHaulier;
        })
      : [];
  }

  public get nonDefaultMaterial(): MaterialModel[] | undefined {
    if (!this.materials) {
      return;
    }
    return this.materials.filter((m: MaterialModel) => !m.isDefault);
  }

  public get defaultMaterials(): MaterialModel[] | undefined {
    if (this.materials) {
      return this.materials.filter((material: MaterialModel) => material.isDefault);
    }
  }

  public get tradersList(): IdCodeNameModel[] {
    return this.traders
      ? this.traders.map((trader) => {
          return {
            name: trader.fullName,
            code: trader.code,
            id: trader.id,
          } as IdCodeNameModel;
        })
      : [];
  }

  public get tradersForDeliveryFilterList(): IdNameModel[] {
    return this.tradersForDeliveryFilter
      ? this.tradersForDeliveryFilter.map((trader) => {
          return {
            name: trader.fullName,
            id: trader.id,
          } as IdNameModel;
        })
      : [];
  }

  public get responsibleUsersList(): IdNameModel[] {
    return this.responsibleUsers
      ? this.responsibleUsers.map((user) => {
          return {
            name: user.fullNameWithShortName,
            id: user.id,
          } as IdNameModel;
        })
      : [];
  }

  public get common(): ICommonData {
    return {
      materials: this.materials,
      labStatuses: this.labStatuses,
      labInputTypes: this.labInputTypes,
      scales: this.scales,
      printers: this.printers,
      roadHauliers: this.roadHauliers,
      stockItems: this.stockItems,
      suppliers: this.suppliers,
      traders: this.traders,
      tradersForDeliveryFilter: this.tradersForDeliveryFilter,
      responsibleUsers: this.responsibleUsers,
      departmentCodes: this.departmentCodes,
      suppliersWithCertificateDates: this.suppliersWithCertificateDates,
      roadHauliersWithCertificateDates: this.roadHauliersWithCertificateDates,
      yardLocations: this.yardLocations,
      findSupplierByCode: this.findSupplierByCode.bind(this),
      findStockItemByCode: this.findStockItemByCode.bind(this),
      findTraderByCode: this.findTraderByCode.bind(this),
      ewcs: this.ewcs,
      origins: this.origins,
      suppliersWithCodes: this.suppliersWithCodes,
      documentTypes: this.documentTypes,
    };
  }

  public requiresStockItemProductFormAndQuality = (stockCode: string): boolean => {
    const stockItem = this.stockItems.find((item) => item.code === stockCode);
    return Boolean(stockItem?.requiresFormAndQuality);
  };

  public get hasDeliveryCommon() {
    return Boolean(
      this.deliveryTypeList.length &&
        this.deliveryWeatherConditionList.length &&
        this.deliveryTransactionTypeList.length
    );
  }

  public getProductForm(stockItemId: string): PromiseWrapper<IdNameModel[]> {
    if (this.productFormList.has(stockItemId)) {
      return promiseWrapper<IdNameModel[]>(Promise.resolve(this.productFormList.get(stockItemId)));
    }

    return promiseWrapper<IdNameModel[]>(this._getProductFormList(stockItemId));
  }

  public getProductQuality(stockItemId: string, productFormId: string): PromiseWrapper<IdNameModel[]> {
    const id = `${stockItemId}${productFormId}`;
    if (this.qualityList.has(id)) {
      return promiseWrapper<IdNameModel[]>(Promise.resolve(this.qualityList.get(id)));
    }

    return promiseWrapper<IdNameModel[]>(this._getProductQualityList(stockItemId, productFormId));
  }

  public async getCommon(tenantId?: string): Promise<ICommon> {
    const common: ICommon = await this._getCommonDataRequest(tenantId);
    this.update(common);
    this.changeIsDataLoaded(true);

    return this.common;
  }

  public getCommonLabRequest(): Promise<ICommonLab> {
    return this.rootService.ajaxService.get('common/lab');
  }

  // BOF LAB
  public getCommonLab = async (): Promise<ICommon> => {
    if (this.isLabDataLoaded) {
      return Promise.resolve(this.common);
    }

    const labStatusesAndInputTypes: ICommonLab = await this.getCommonLabRequest();
    this.update(labStatusesAndInputTypes);
    this.changeIsLabDataLoaded(true);

    return this.common;
  };

  public async getDeliveryCommon() {
    const commonDelivery = await this._getDeliveryCommonRequest();
    this._setDeliveryTypeList(commonDelivery.deliveryTypes);
    this._setDeliveryWeatherConditionList(commonDelivery.weatherConditions);
    this._setDeliveryTransactionTypeList(commonDelivery.transactionTypes.values);
    this._setDefaultTransactionType(commonDelivery.transactionTypes.defaultTransactionType);
  }

  public update = (obj: CommonStore | ICommonData) => {
    const newCommonStore = cloneObj(obj);

    if (newCommonStore) {
      if (newCommonStore.traders) {
        newCommonStore.traders = newCommonStore.traders.map((trader) =>
          new UserModel<IUserModelConstructObj>().update(trader)
        );
      }
      if (newCommonStore.tradersForDeliveryFilter) {
        newCommonStore.tradersForDeliveryFilter = newCommonStore.tradersForDeliveryFilter.map((trader) =>
          new UserModel<IUserModelConstructObj>().update(trader)
        );
      }
      if (newCommonStore.responsibleUsers) {
        newCommonStore.responsibleUsers = newCommonStore.responsibleUsers.map((user) =>
          new UserModel<IUserModelConstructObj>().update(user)
        );
      }
      if (newCommonStore.documentTypes) {
        newCommonStore.documentTypes = newCommonStore.documentTypes.map((docType) =>
          new LasernetDocumentTypeModel().update(docType)
        );
      }
    }

    this.updater.update(this, newCommonStore, CommonStore, this.rootService);
    return this as CommonStore | ICommonData;
  };

  public findSupplierByCode(supplierCode: string) {
    return this.suppliers.find((supplier) => supplier.code === supplierCode);
  }

  public findStockItemByCode(stockItemCode: string) {
    return this.stockItems.find((stockItem) => stockItem.code === stockItemCode);
  }

  public findTraderByCode(traderCode: string) {
    return this.tradersList.find((trader) => trader.code === traderCode);
  }

  public changeIsDataLoaded(newValue: boolean) {
    this.isDataLoaded = newValue;
  }

  public changeIsLabDataLoaded(newValue: boolean) {
    this.isLabDataLoaded = newValue;
  }

  private _setDeliveryWeatherConditionList = (newWeatherConditions: string[]) => {
    this.deliveryWeatherConditionList = newWeatherConditions;
  };

  private _setDeliveryTransactionTypeList = (newTransactionTypes: string[]) => {
    this.deliveryTransactionTypeList = newTransactionTypes;
  };

  private _setDefaultTransactionType = (type: string) => {
    this.defaultTransactionType = type;
  };

  private _setDeliveryTypeList = (newDeliveryTypes: string[]) => {
    this.deliveryTypeList = newDeliveryTypes;
  };

  private _getProductFormList = (stockItemId: string): Promise<IdNameModel[]> => {
    return this._getProductFormListRequest(stockItemId).then((res: IdNameModel[]) => {
      this.productFormList.set(stockItemId, res);
      return res;
    });
  };

  private _getProductQualityList = (stockItemId: string, productFormId: string): Promise<IdNameModel[]> => {
    return this._getQualityListRequest(stockItemId, productFormId).then((res: IdNameModel[]) => {
      this.qualityList.set(`${stockItemId}${productFormId}`, res);
      return res;
    });
  };

  private _getDeliveryCommonRequest = (): Promise<IDeliveryCommonResponse> =>
    this.rootService.ajaxService.get('common/delivery');

  private _getCommonDataRequest = (tenantId?: string): Promise<ICommon> =>
    this.rootService.ajaxService.get(`common${tenantId ? `/${tenantId}` : ''}`);

  private _getProductFormListRequest = (stockItemId: string): Promise<IdNameModel[]> =>
    this.rootService.ajaxService.get(`common/stock-items/${stockItemId}/product-forms`);

  private _getQualityListRequest = (stockItemId: string, productFormId: string): Promise<IdNameModel[]> =>
    this.rootService.ajaxService.get(`common/stock-items/${stockItemId}/product-forms/${productFormId}/qualities`);
}
