import * as React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as classnames from 'classnames';

import NavlinkCustom from 'domain/NavlinkCustom';
import AdminStore from 'pod/admin/AdminStore';
import TranslateService from 'services/TranslateService';
import { AdminRootPath, ExampleFileNames } from 'util/enums';
import IdCodeActiveModel from 'models/IdCodeActiveModel';
import OriginDetailRoute from 'pod/admin/origins/OriginDetailRoute';
import CommonAdminListRoute from 'pod/admin/commonComponents/CommonAdminListRoute';
import { ORIGIN_CODE_MAX_LENGTH_DEFAULT, ORIGIN_CODE_MAX_LENGTH_FR } from 'util/constants';
import ViewStore from 'stores/ViewStore';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
  viewStore?: ViewStore;
}

class OriginListRoute extends React.Component<IProps> {
  public content: IdCodeActiveModel = new IdCodeActiveModel();

  public clearModel = () => {
    this.content = new IdCodeActiveModel();
  };

  constructor(props: IProps) {
    super(props);

    makeObservable(this, {
      content: observable,
      clearModel: action,
    });
  }

  public get originList() {
    const {
      translateService: { t },
      adminStore: { originListActiveFirst },
    } = this.props;
    return (
      <table className="custom-table mb-3">
        <thead>
          <tr>
            <th className="ps-3 pe-3">{t.ADMIN_ORIGINLIST_CODE}</th>
          </tr>
        </thead>
        {originListActiveFirst.map((origin: IdCodeActiveModel) => this._renderItem(origin))}
      </table>
    );
  }

  public render() {
    const {
      adminStore: { originApi },
      translateService: { t },
      viewStore: { isFR },
    } = this.props;

    return (
      <CommonAdminListRoute
        api={originApi}
        rootPath={AdminRootPath.ORIGINS}
        itemsTable={this.originList}
        itemCreateForm={null}
        componentRoute={OriginDetailRoute}
        clearModel={this.clearModel}
        content={this.content}
        sectionNameTranslation={t.ADMIN_SECTIONENTITY_ORIGIN}
        exampleFileNames={ExampleFileNames.ORIGINS}
        header={t.MENU_ADMIN_ORIGINS}
        limit={isFR ? ORIGIN_CODE_MAX_LENGTH_FR : ORIGIN_CODE_MAX_LENGTH_DEFAULT}
      />
    );
  }

  private _renderItem = (origin: IdCodeActiveModel) => (
    <tbody key={origin.id}>
      <NavlinkCustom
        data-test="origin-item"
        to={`/admin/origins/${origin.id}`}
        tagName="tr"
        className={classnames(['pointer', { 'bg-secondary': !origin.active }])}
      >
        <td className="ps-3 pe-3">{origin.code}</td>
      </NavlinkCustom>
    </tbody>
  );
}

export default inject('adminStore', 'translateService', 'viewStore')(observer(OriginListRoute));
