import * as React from 'react';
import { inject, observer } from 'mobx-react';

import AdminStore from 'pod/admin/AdminStore';
import ProductVariants from 'pod/admin/commonComponents/ProductVariants';
import TranslateService from 'services/TranslateService';

interface IProps {
  adminStore?: AdminStore;
  translateService?: TranslateService;
}

class QualityListRoute extends React.Component<IProps> {
  public componentDidMount() {
    this.props.adminStore.getQualityItems();
  }

  public render() {
    const {
      adminStore: { qualityListActiveFirst, getQualityItems },
      translateService: { t },
    } = this.props;

    return (
      <ProductVariants
        items={qualityListActiveFirst}
        title={t.GLOBAL_LABEL_QUALITY}
        onRefreshCallback={getQualityItems}
      />
    );
  }
}

export default inject('adminStore', 'translateService')(observer(QualityListRoute));
