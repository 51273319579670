import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import FileModel from 'models/FileModel';
import AttachmentListItem from 'domain/AttachmentListItem';
import AjaxService from 'services/AjaxService';
import { inject } from 'mobx-react';

import Lightbox from 'react-image-lightbox';
import { splitFileModelsArrayToImagesAndPdfs } from 'util/helpers';
import reactNative from 'util/react-native';
import { ReactNativeActionType } from 'util/enums';

interface IProps {
  attachments: FileModel[];
  removeAttachment?: (attachmentId: string) => void;
  ajaxService?: AjaxService;
}

interface IState {
  imageToShow: number; // INDEX,
  isOpen: boolean;
}

const defaultState: IState = {
  imageToShow: null,
  isOpen: false,
};

class AttachmentList extends React.PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = defaultState;
  }

  public setIndexForLightbox = (imageToShow: number) => {
    this.setState({
      isOpen: true,
      imageToShow,
    });
  };

  public openPDF = (url: string) => {
    if (reactNative.isReactNativeWebViewExist) {
      reactNative.send({ command: ReactNativeActionType.SHOW_PDF_READER, payload: url });
    }
  };

  public render() {
    const { attachments, removeAttachment } = this.props;
    const { imageToShow, isOpen } = this.state;
    const images = attachments.filter((att) => att.thumbnailPath && !att.isAttachmentPDF).map((att) => att.path);
    const [imagesFileModels, pdfFileModels] = splitFileModelsArrayToImagesAndPdfs(attachments);
    return attachments && attachments.length ? (
      <table className="full-width attachment-list">
        <tbody>
          {imagesFileModels.map((attachment: FileModel, index) => (
            <AttachmentListItem
              key={`attachment-${attachment.id}`}
              attachment={attachment}
              index={index}
              removeAttachment={removeAttachment}
              setIndexForLightbox={this.setIndexForLightbox}
            />
          ))}
          {pdfFileModels.map((attachment: FileModel, index) => (
            <AttachmentListItem
              key={`pdf-${attachment.id}`}
              attachment={attachment}
              index={index}
              removeAttachment={removeAttachment}
              openPDF={this.openPDF}
            />
          ))}

          {isOpen && (
            <Lightbox
              toolbarButtons={[
                <span
                  className="lightbox-btn ril__builtinButton"
                  data-test="rotate-right"
                  onClick={() => this._rotateRight(attachments[imageToShow])}
                >
                  <FontAwesome name="rotate-right" />
                </span>,
                <span
                  className="lightbox-btn ril__builtinButton"
                  data-test="rotate-left"
                  onClick={() => this._rotateLeft(attachments[imageToShow])}
                >
                  <FontAwesome name="rotate-left" />
                </span>,
              ]}
              mainSrc={images[imageToShow]}
              nextSrc={images[(imageToShow + 1) % images.length]}
              prevSrc={images[(imageToShow + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  imageToShow: (imageToShow + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  imageToShow: (imageToShow + 1) % images.length,
                })
              }
            />
          )}
        </tbody>
      </table>
    ) : null;
  }

  private _rotateLeft = (image: FileModel) => {
    this.props.ajaxService.put(`delivery-attachments/${image.id}/rotate-left`, {}).then((res: FileModel) => {
      image.update(res);
      this.forceUpdate();
    });
  };

  private _rotateRight = (image: FileModel) => {
    this.props.ajaxService.put(`delivery-attachments/${image.id}/rotate-right`, {}).then((res: FileModel) => {
      image.update(res);
      this.forceUpdate();
    });
  };
}

export default inject('ajaxService')(AttachmentList);
