import { action, computed, observable, makeObservable } from 'mobx';
import ObjectUpdater from 'util/objectUpdater';

import DeliveryListAdvisedGoodModel from 'models/DeliveryListAdvisedGoodModel';
import { IUpdateModel } from 'models/UpdateModel';
import SupplierInfo from 'models/SupplierInfoAbstractModel';
import { cloneObj } from 'util/helpers';
import { DeliveryStatus } from 'util/enums';
import UserModel from 'models/UserModel';
import { IUserModelConstructObj } from 'models/ModelInterfaces';

export default class DeliveryListModel extends SupplierInfo implements IUpdateModel<DeliveryListModel> {
  public readonly updater = new ObjectUpdater<DeliveryListModel>();

  public advisedGoods?: DeliveryListAdvisedGoodModel[] = [new DeliveryListAdvisedGoodModel()];
  public createdAt?: string = '';
  public isFlagged?: boolean = false;
  public wasFlagged?: boolean = false;
  public grn?: string = '';
  public id?: string = null;
  public status?: DeliveryStatus = null;
  public isSynced?: boolean = false;
  public vehicleRegistrationNumber?: string = '';
  public tenantId?: string = null;
  public trader?: UserModel<IUserModelConstructObj> = null;

  constructor() {
    super();

    makeObservable(this, {
      advisedGoods: observable,
      createdAt: observable,
      isFlagged: observable,
      wasFlagged: observable,
      grn: observable,
      id: observable,
      status: observable,
      isSynced: observable,
      vehicleRegistrationNumber: observable,
      tenantId: observable,
      trader: observable,
      advisedGoodsToClam: computed,
      isAGWeightDiscrepancy: computed,
      traderFullName: computed,
      update: action,
      setStatus: action,
    });
  }

  get advisedGoodsToClam() {
    const toClamArray = this.advisedGoods.filter(
      (item: DeliveryListAdvisedGoodModel) => item.claimedBy && item.claimedBy.user === null
    );
    return toClamArray && toClamArray.length !== 0 ? toClamArray.length : null;
  }

  public get isAGWeightDiscrepancy() {
    return this.advisedGoods.some((ag: DeliveryListAdvisedGoodModel) => !!ag.weightDiscrepancy);
  }

  public get traderFullName() {
    return this.trader !== null ? `${this.trader.firstname} ${this.trader.lastname}` : '';
  }

  public get traderCode() {
    return this.trader !== null ? this.trader.code : '';
  }

  public update = (obj: DeliveryListModel) => {
    const newDeliveryListModel = cloneObj(obj);

    if (newDeliveryListModel) {
      newDeliveryListModel.advisedGoods = newDeliveryListModel.advisedGoods.map((r: DeliveryListAdvisedGoodModel) =>
        new DeliveryListAdvisedGoodModel().update(r)
      );
    }

    this.updater.update(this, newDeliveryListModel, DeliveryListModel);
    return this;
  };

  public setStatus = (newStatus: DeliveryStatus) => {
    this.status = newStatus;
  };
}
