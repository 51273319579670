import * as React from 'react';
import { Button } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import { logger } from 'util/logger';

interface IProps {
  onChangeFunc: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  buttonClassName?: string;
  fileAccept?: string;
  onPictureTaken?: (imageBase64: string) => void;
  onButtonClick?: () => void;
  isRenderInput?: boolean;
}

interface IDefaultProps {
  fileAccept: string;
}

export default class TakeFileCommon extends React.Component<IProps> {
  public static defaultProps: IDefaultProps = {
    fileAccept: 'image/*',
  };
  private _fileInput: HTMLInputElement = null;

  public render() {
    const { buttonClassName, fileAccept, onButtonClick, isRenderInput } = this.props;

    return (
      <Button className={buttonClassName} onClick={onButtonClick} color="success">
        <FontAwesome name="camera" />
        {isRenderInput ? (
          <input
            type="file"
            multiple
            ref={(r) => (this._fileInput = r)}
            onChange={this._inputOnChange}
            accept={fileAccept}
            capture
          />
        ) : undefined}
      </Button>
    );
  }

  private _inputOnChange = (stuff: React.ChangeEvent<HTMLInputElement>) => {
    this.props
      .onChangeFunc(stuff)
      .then(() => {
        this._fileInput.value = null;
      })
      .catch((err: Error) => {
        logger.error(err);
      });
  };
}
