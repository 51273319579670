import * as React from 'react';
import { inject, observer } from 'mobx-react';
import ViewStore from 'stores/ViewStore';
import DateFormattingService from 'services/DateFormattingService';
import OperatorModel from 'models/OperatorModel';

interface IAdvisedGoodActionProps {
  dataTest: string;
  title: string;
  model: OperatorModel;
  viewStore?: ViewStore;
  dateFormattingService?: DateFormattingService;
}

const AdvisedGoodActionComponent: React.FC<IAdvisedGoodActionProps> = ({
  title,
  dataTest,
  model,
  viewStore,
  dateFormattingService,
}) => {
  const { timezone } = viewStore;
  const { formatDateTime } = dateFormattingService;
  return (
    <tr data-test={dataTest}>
      <td className="font-light">{title}</td>
      <td className="font-16" colSpan={3} data-test={`${dataTest}-value`}>
        {model.user.fullName}
        <span className="margin-left-5 font-light font-13 display-inline-block">
          {formatDateTime(model.date, timezone)}
        </span>
      </td>
    </tr>
  );
};

export const AdvisedGoodAction = inject('viewStore', 'dateFormattingService')(observer(AdvisedGoodActionComponent));
