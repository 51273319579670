import * as React from 'react';
import { Alert, Col, Container, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';
import * as H from 'history';

import AdvisedGoodLabModel from 'models/AdvisedGoodLabModel';
import AdvisedGoodLabStore from 'pod/advisedGoodLab/AdvisedGoodLabStore';
import CommonStore from 'stores/CommonStore';
import LabAnalysisModel from 'models/LabAnalysisModel';
import LabTrackingModel from 'models/LabTrackingModel';
import ReceivedGoodsLabModel from 'models/ReceivedGoodsLabModel';
import SessionStore from 'stores/SessionStore';
import SortingReportLabComponent from 'pod/advisedGoodLab/SortingReportLabComponent';
import TranslateService from 'services/TranslateService';
import BackButton from 'components/BackButton';

interface IQuery {
  id: string;
  deliveredGoodId?: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  history: H.History;
  advisedGoodLabStore?: AdvisedGoodLabStore;
  commonStore?: CommonStore;
  sessionStore?: SessionStore;
  translateService?: TranslateService;
}

interface IState {
  content: AdvisedGoodLabModel;
  modalWasOpened: boolean;
}

class AdvisedGoodLabRoute extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      content: null,
      modalWasOpened: false,
    };
  }

  // LOAD DETAIL AND LOAD COMMON FOR SELECTS
  public componentDidMount() {
    this._loadData();
  }

  public preOpenModal = (itemId: string): boolean => {
    // this check is needed, because after update advise good children will render again.
    if (this.state.modalWasOpened) {
      return false;
    }

    if (itemId === this.props.match.params.deliveredGoodId) {
      this.setState({ modalWasOpened: true });
      return true;
    }

    return false;
  };

  public loadLabAdvisedGoodsById = () => {
    const {
      advisedGoodLabStore,
      match: { params },
      commonStore: { nonDefaultMaterial, defaultMaterials },
    } = this.props;

    return advisedGoodLabStore.getLabAdvisedGoodsById(params.id).then((content: AdvisedGoodLabModel) => {
      this.setState({ content: new AdvisedGoodLabModel().update(content, defaultMaterials, nonDefaultMaterial) });
    });
  };

  public render() {
    const { content } = this.state;
    const {
      commonStore: { printers },
      translateService: { t },
    } = this.props;

    if (!content) {
      return null;
    }

    return (
      <Container fluid className="padding-bottom-50">
        {/* BACK BUTTON, WITH DIRTY CHECK */}
        <BackButton onClick={() => this.props.history.push('/deliveries/lab')}>
          {t.LABORATORY_ADVISEGOODS_DELIVERIES}
        </BackButton>

        <Row>
          <Col sm="12" className="mb-3">
            <h2 className="mb-0 clearfix">{content.agTitle}</h2>
            {content.flagged && (
              <Alert color="danger" className="text-center">
                <strong>{t.GLOBAL_LABEL_ALERT}</strong>
              </Alert>
            )}
          </Col>
        </Row>

        <Row className="bg-white border-top border-bottom pt-3 pb-3">
          <Col xs={12}>
            {/* DELIVERY DETAIL TABLE */}
            <table className="full-width">
              <tbody>
                <tr>
                  <td className="width-130 font-light">{t.LABORATORY_ADVISEGOODS_ADVISED_DESCRIPTION}</td>
                  <td className="font-16">{content.advisedDescription}</td>
                </tr>
                <tr>
                  <td className="font-light">{t.GLOBAL_LABEL_SUPPLIER}</td>
                  <td className="font-16">{content.supplier}</td>
                </tr>
                <tr>
                  <td className="font-light">{t.LABORATORY_ADVISEGOODS_TENANT}</td>
                  <td className="font-16">{content.tenant.name}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>
          <Col size={12} className="pt-1 pb-1 ps-0 pe-0">
            {/* SORTING REPORT */}
            <h5 className="mt-4 ps-3 pe-3">{t.LABORATORY_ADVISEGOODS_SORTING_REPORT}</h5>
            <table className="custom-table">
              <thead>
                <tr>
                  <th className="ps-3">{t.GLOBAL_LABEL_DESCRIPTION}</th>
                  <th className="text-end">{t.LABORATORY_ADVISEGOODS_LAB_STATUSES}</th>
                  <th className="width-65 text-end pe-3" />
                </tr>
              </thead>
              {content.receivedGoods &&
                content.receivedGoods.length !== 0 &&
                content.receivedGoods.map((item: ReceivedGoodsLabModel, i: number) => (
                  <SortingReportLabComponent
                    updateAdvisedGoodAfterModalClose={this.loadLabAdvisedGoodsById}
                    advisedGoodDescription={item.description}
                    advisedGoodGrn={content.grn}
                    advisedGoodId={this.props.match.params.id}
                    advisedGoodSupplier={content.supplier}
                    advisedGoodTenant={content.tenant.name}
                    history={this.props.history}
                    item={item}
                    key={`${item.id}-${i}`}
                    labAnalysisPut={this._labAnalysisPut}
                    labAnalysisStatusPut={this._labAnalysisStatusPut}
                    labStatuses={this.props.commonStore.labStatuses}
                    preOpenModal={this.preOpenModal}
                    printers={printers}
                  />
                ))}
            </table>
          </Col>
        </Row>
      </Container>
    );
  }

  private _loadData = async () => {
    const { commonStore } = this.props;

    if (!commonStore.isDataLoaded) {
      await commonStore.getCommon();
    }

    if (!commonStore.isLabDataLoaded) {
      await commonStore.getCommonLab();
    }

    await this.loadLabAdvisedGoodsById();
  };

  private _labAnalysisPut = (labAnalysis: LabAnalysisModel) => {
    return this.props.advisedGoodLabStore.putLabAnalysis(labAnalysis).then(() => {
      notification.success(this.props.translateService.t.LABORATORY_ADVISEGOODS_UPDATE_MSG);
    });
  };

  private _labAnalysisStatusPut = (labAnalysis: LabAnalysisModel, newLocationId: string, newStatusId: string) => {
    return this.props.advisedGoodLabStore
      .putLabAnalysisStatus(labAnalysis.id, newLocationId, newStatusId)
      .then((newLabInputTracking: LabTrackingModel[]) => {
        notification.success(this.props.translateService.t.LABORATORY_ADVISEGOODS_UPDATE_STATUS_MSG);
        labAnalysis.setTracking(newLabInputTracking);
      });
  };
}

export default inject(
  'advisedGoodLabStore',
  'sessionStore',
  'commonStore',
  'translateService'
)(observer(AdvisedGoodLabRoute));
