import * as React from 'react';
import * as classnames from 'classnames';
import TranslateService from 'services/TranslateService';
import { observer } from 'mobx-react';
import TakeFileCommon from 'components/TakeFileCommon';

interface IProps {
  onChangeFunc: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  buttonClassName?: string;
  translateService?: TranslateService;
  fileAccept?: string;
}

class TakeFile extends React.Component<IProps> {
  public render() {
    const { onChangeFunc, buttonClassName, fileAccept } = this.props;
    return (
      <TakeFileCommon
        buttonClassName={classnames([buttonClassName, 'take-file'])}
        onChangeFunc={onChangeFunc}
        fileAccept={fileAccept}
        isRenderInput
      />
    );
  }
}

export default observer(TakeFile);
