import env from 'env';
import { action, autorun, computed, observable, makeObservable } from 'mobx';
import { excludeNullAndEmptyStringValue } from 'util/helpers';
import { logger } from 'util/logger';
import DeliveryListModel from 'models/DeliveryListModel';
import DeliveryListAdvisedGoodModel from 'models/DeliveryListAdvisedGoodModel';
import DeliveryLabModel from 'models/DeliveryLabModel';
import AdvisedGoodsModel, { IEwcCodeUpdateSocketResponse, IWeightUpdateSocketResponse } from 'models/AdvisedGoodsModel';
import RootService from 'services/RootService';
import AllWeightsModel from 'models/AllWeightsModel';

type TEventData =
  | DeliveryListModel
  | DeliveryListAdvisedGoodModel
  | { sfid: string }
  | DeliveryLabModel
  | IWeightUpdateSocketResponse
  | IEwcCodeUpdateSocketResponse
  | AdvisedGoodsModel[]
  | AllWeightsModel;

interface IWSMessage {
  type: string;
  payload: TEventData;
}

export default class SocketService {
  constructor(private readonly _rootService: RootService) {
    makeObservable<
      SocketService,
      '_wsMessageArray' | '_hasWSMessageArrayItems' | '_addItemToWSMessageArray' | '_clearWSMessageArray'
    >(this, {
      isContentReadyToUpdate: observable,
      _wsMessageArray: observable,
      _hasWSMessageArrayItems: computed,
      setIsContentReadyToUpdate: action,
      _addItemToWSMessageArray: action,
      _clearWSMessageArray: action,
    });

    autorun(() => {
      if (
        this._hasWSMessageArrayItems &&
        this._rootService.ajaxService &&
        !this._rootService.ajaxService.hasPendingRequests &&
        this.isContentReadyToUpdate
      ) {
        this._wsMessageArray.forEach((wsMessage: IWSMessage) => {
          const eventListeners = this._events.get(wsMessage.type) || [];
          eventListeners.forEach((eventListener) => eventListener(wsMessage.payload));
        });
        this._clearWSMessageArray();
        this.setIsContentReadyToUpdate(false);
      }
    });
  }
  public isContentReadyToUpdate: boolean = false;

  private _socket: WebSocket;
  private _keepAliveHandler?: number;
  private _sfid: string;

  private _wsMessageArray: IWSMessage[] = [];

  private get _hasWSMessageArrayItems(): boolean {
    return Boolean(this._wsMessageArray.length);
  }

  private _events: Map<string, Array<(data: TEventData) => void>> = new Map();

  public setIsContentReadyToUpdate = (value: boolean) => {
    this.isContentReadyToUpdate = value;
  };
  // during session-restore
  public open = (sfid: string) => {
    logger.info('SOCKET - GRACEFULLY OPENING SOCKET');
    this._sfid = sfid;

    this._establishConnection();
    this._keepAlive();
  };

  // on login/session-restore error
  public close = () => {
    logger.info('SOCKET - GRACEFULLY CLOSING WS CONNECTION');
    this._sfid = null;

    if (this._socket && this.isOpen) {
      this._socket.close();
    }
    this._socket = undefined;

    if (this._keepAliveHandler) {
      clearInterval(this._keepAliveHandler);
      this._keepAliveHandler = null;
    }
  };

  public subscribe(sfid: string) {
    if (sfid) {
      this._send('SUBSCRIBE', { sfid });
    }
  }

  public subscribeTopic(topic: string, fn: (data: TEventData) => void) {
    const eventListeners = this._events.get(topic) || [];

    eventListeners.push(fn);
    this._events.set(topic, eventListeners);
  }

  public unsubscribeTopic(topic: string, fn: (data: TEventData) => void) {
    const eventListeners = this._events.get(topic) || [];

    const index = eventListeners.indexOf(fn);

    if (index !== -1) {
      eventListeners.splice(index, 1);
    }
  }

  public get isOpen() {
    return !!this._socket && this._socket.readyState === this._socket.OPEN;
  }

  public get isConnecting() {
    return !!this._socket && this._socket.readyState === this._socket.CONNECTING;
  }

  public get isClosed() {
    return this._socket === undefined || this._socket.readyState === this._socket.CLOSED;
  }

  private _addItemToWSMessageArray(item: IWSMessage) {
    this._wsMessageArray.push(item);
  }

  private _clearWSMessageArray() {
    this._wsMessageArray = [];
  }

  private get _socketUrl(): string {
    return env.socketUrl || `wss://${window.location.hostname}`;
  }

  // initially on socket open, and periodically in case of ws errors
  private _establishConnection() {
    this._socket = new WebSocket(`${this._socketUrl}/api/socket`);
    this._socket.onopen = this._onOpen;
    this._socket.onmessage = this._onMessage;
    this._socket.onerror = this._onError;
    this._socket.onclose = this._onClose;
  }

  private _send(topic: string, data: TEventData) {
    this._socket.send(
      JSON.stringify(
        excludeNullAndEmptyStringValue({
          type: topic,
          ...data,
        })
      )
    );
  }

  private _onOpen = (event: Event) => {
    logger.info('SOCKET - CONNECTION OPEN', event);
    this.subscribe(this._sfid);
  };

  private _onError = (event: Event) => {
    logger.error('SOCKET - CONNECTION ERROR', event);
  };

  private _onMessage = (message: MessageEvent) => {
    logger.info('SOCKET - MESSAGE RECEIVED', message);

    try {
      const { type, payload } = JSON.parse(message.data);

      if (this._rootService.ajaxService && this._rootService.ajaxService.hasPendingRequests) {
        this._addItemToWSMessageArray({ type, payload });
        return;
      }

      const eventListeners = this._events.get(type) || [];
      eventListeners.forEach((eventListener) => eventListener(payload));
    } catch (e) {
      logger.error(`SOCKET - CANNOT PARSE INCOMING MESSAGE. ${e}`);
    }
  };

  private _onClose = (event: CloseEvent) => {
    logger.info(`SOCKET - CLOSED. Code: ${event.code}. Reason: ${event.reason}`, event);
    if (event.code === 1006 || 1008) {
      this.close();
    }
  };

  private _keepAlive = () => {
    // give it 5sec for initial connection, and then check if connection is established and try reconnecting otherwise
    window.setTimeout(() => {
      clearInterval(this._keepAliveHandler);

      this._keepAliveHandler = window.setInterval(() => {
        if (this.isClosed) {
          logger.info('SOCKET - RECONNECTING SOCKET');
          this._establishConnection();
        }
      }, 2000);
    }, 5000);
  };
}
