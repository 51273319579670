/* eslint-disable react/display-name */
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LoginRoute from 'pod/unathenticated/LoginRoute';
import RecoverPasswordRoute from 'pod/unathenticated/RecoverPasswordRoute';

export default class UnauthenticatedIndexRoute extends React.PureComponent {
  public render() {
    return (
      <div className="login full-height">
        <Switch>
          <Route path="/auth/login" component={LoginRoute} />
          <Route path="/auth/recover-password" component={RecoverPasswordRoute} />
          <Redirect from="/auth" to="/auth/login" />
        </Switch>
      </div>
    );
  }
}
