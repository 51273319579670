import * as React from 'react';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import { Button, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { instance as notification } from 'util/notification';
import { getTranslation, isMobile, processDecimals } from 'util/helpers';

import AdvisedGoodsModel, {
  IEwcCodeUpdateSocketResponse,
  IWeightUpdateSocketResponse,
} from 'models/AdvisedGoodsModel';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import CommonStore from 'stores/CommonStore';
import LabAnalysisModel from 'models/LabAnalysisModel';
import ModalConfirmation from 'domain/ModalConfirmation';
import ReceivedGoodsModel from 'models/ReceivedGoodsModel';
import SessionStore from 'stores/SessionStore';
import SortingReportLineComponent from 'pod/advisedGoods/SortingReportLineComponent';
import StickyFooter from 'domain/StickyFooter';
import SubAdvisedGoodsModel from 'models/SubAdvisedGoodsModel';
import TranslateService from 'services/TranslateService';
import { I18N } from '../../../assets/i18n/i18n';
import { action, observable, makeObservable } from 'mobx';
import SocketService from 'services/SocketService';
import { AdvisedGoodStatus, DeductionsList, SocketServiceTopic } from 'util/enums';
import PicturesComponent from 'components/PicturesComponent';
import IdNameModel from 'models/IdNameModel';
import Autocomplete from 'domain/Autocomplete';
import InputNumber from 'components/InputNumber';
import ViewStore from 'stores/ViewStore';
import {
  ADVISED_PACKAGING_MAX_LENGTH_FR,
  TRANSFER_ID_NULL_ERROR_MSG,
  TRANSFER_WEIGHT_ERROR_MSG,
} from 'util/constants';
import { ITransferData } from 'models/TransferModel';
import ReceivedGoodsStore from 'pod/receivedGoods/ReceivedGoodsStore';
import InputWithNullCheck from 'components/InputWithNullCheck';
import AdvisedGoodDeliveryDetailsComponent from 'pod/advisedGoods/AdvisedGoodDeliveryDetailsComponent';
import AdvisedGoodWeightDetailsComponent from 'pod/advisedGoods/AdvisedGoodWeightDetailsComponent';
import AllWeightsModel from 'models/AllWeightsModel';
import BackButton from 'components/BackButton';
import ProcessingTimeComponent from 'pod/receivedGoods/ProcessingTimeComponent';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';
import PromiseResolver from 'models/PromiseResolver';
import AdvisedGoodButtons from './AdvisedGoodButtons';
import AdvisedGoodComment from './AdvisedGoodComment';

interface IQuery {
  id: string;
  deliveredGoodId?: string;
}

interface IProps extends RouteComponentProps<IQuery> {
  advisedGoodsStore?: AdvisedGoodsStore;
  commonStore?: CommonStore;
  sessionStore?: SessionStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  socketService?: SocketService;
  receivedGoodsStore?: ReceivedGoodsStore;
}

interface IState {
  attachmentsToUpload: FileList;
  completeConfirmationModal: boolean;
  dirty: boolean;
  flagConfirmationModal: boolean;
  receivedWeight: number;
  saveConfirmationModal: boolean;
  saveConfirmationModalForLab: boolean;
  statusRollbackConfirmationModal: boolean;
  verifyConfirmationModal: boolean;
  claimConfirmationModal: boolean;
  unclaimConfirmationModal: boolean;
  unloadConfirmationModal: boolean;
  isTareNetWeightFieldsShown: boolean;
}

class AdvisedGoodRoute extends React.Component<IProps, IState> {
  public initialYardLocation: IdNameModel;
  private _hasAttachmentsPromise: PromiseResolver<boolean> = new PromiseResolver();

  private _hasAttachmentsConfirmationModal = false;

  public constructor(props: IProps) {
    super(props);

    makeObservable<
      AdvisedGoodRoute,
      | '_hasAttachmentsConfirmationModal'
      | '_toggleHasAttachmentsConfirmationModal'
    >(this, {
      _hasAttachmentsConfirmationModal: observable,
      _toggleHasAttachmentsConfirmationModal: action,
    });

    this.state = {
      attachmentsToUpload: null,
      completeConfirmationModal: false,
      dirty: false,
      flagConfirmationModal: false,
      receivedWeight: 0,
      saveConfirmationModal: false,
      saveConfirmationModalForLab: false,
      statusRollbackConfirmationModal: false,
      verifyConfirmationModal: false,
      claimConfirmationModal: false,
      unclaimConfirmationModal: false,
      unloadConfirmationModal: false,
      isTareNetWeightFieldsShown: false,
    };
  }

  // LOAD DETAIL AND LOAD COMMON FOR SELECTS
  public componentDidMount() {
    const { socketService } = this.props;
    this._loadData();
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_UPDATED, this._onSocketAdvisedGoodUpdate);
    socketService.subscribeTopic(
      SocketServiceTopic.ADVISED_GOOD_GROSS_WEIGHT_UPDATED,
      this._updateAdvisedGoodGrossWeight
    );
    socketService.subscribeTopic(
      SocketServiceTopic.ADVISED_GOOD_TARE_WEIGHT_UPDATED,
      this._updateAdvisedGoodTareWeight
    );
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_EWC_CODE, this._updateEwcCode);
    socketService.subscribeTopic(SocketServiceTopic.DELIVERY_WEIGHTS_UPDATED, this._updateWeights);
    socketService.subscribeTopic(SocketServiceTopic.ADVISED_GOOD_WEIGHTS_UPDATED, this._updateWeights);
    socketService.subscribeTopic(SocketServiceTopic.RECEIVED_GOOD_WEIGHTS_UPDATED, this._updateWeights);
  }

  public componentWillUnmount(): void {
    const { socketService, advisedGoodsStore } = this.props;

    advisedGoodsStore.setContent(null);
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_UPDATED, this._onSocketAdvisedGoodUpdate);
    socketService.unsubscribeTopic(
      SocketServiceTopic.ADVISED_GOOD_GROSS_WEIGHT_UPDATED,
      this._updateAdvisedGoodGrossWeight
    );
    socketService.unsubscribeTopic(
      SocketServiceTopic.ADVISED_GOOD_TARE_WEIGHT_UPDATED,
      this._updateAdvisedGoodTareWeight
    );
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_EWC_CODE, this._updateEwcCode);
    socketService.unsubscribeTopic(SocketServiceTopic.DELIVERY_WEIGHTS_UPDATED, this._updateWeights);
    socketService.unsubscribeTopic(SocketServiceTopic.ADVISED_GOOD_WEIGHTS_UPDATED, this._updateWeights);
    socketService.unsubscribeTopic(SocketServiceTopic.RECEIVED_GOOD_WEIGHTS_UPDATED, this._updateWeights);
  }

  public preOpenModal = (itemId: string) => {
    return itemId === this.props.match.params.deliveredGoodId;
  };


  public get isReadOnly() {
    const {
      sessionStore: { isTraderOrBackOffice, isTier3, isTier2, isTier1, isManager },
      viewStore: {
        isLabFromDE,
        isTier1FromDE,
        isTier1FromIT,
        isTier1FromFR,
        isIT,
        isFR,
        isDE_D365,
        isTier1FromDE_D365,
        isLabFromDE_D365,
      },
      advisedGoodsStore: { content },
    } = this.props;

    // Trader or Manager has read-only view
    if (isTraderOrBackOffice || isManager) {
      return true;
    }

    // TIER 3 CAN EDIT, ONLY IF ADVISED GOOD IS CLAIMED OR TIER 3 FROM IT AND STATUS IS UNLOADED
    if (
      isTier3 &&
      content.status !== AdvisedGoodStatus.CLAIMED &&
      !((isIT || isFR || isDE_D365) && content.status === AdvisedGoodStatus.UNLOADED)
    ) {
      return true;
    }

    if (
      isTier2 &&
      ![
        AdvisedGoodStatus.CLAIMED,
        AdvisedGoodStatus.UNLOADED,
        AdvisedGoodStatus.READY_FOR_TIER_2,
        AdvisedGoodStatus.IN_LAB,
      ].includes(content.status)
    ) {
      return true;
    }

    if (isTier1FromDE || isTier1FromIT || isTier1FromFR || isTier1FromDE_D365) {
      return true;
    }

    if (
      isTier1 &&
      ![
        AdvisedGoodStatus.CLAIMED,
        AdvisedGoodStatus.UNLOADED,
        AdvisedGoodStatus.READY_FOR_TIER_2,
        AdvisedGoodStatus.READY_FOR_SIGN_OFF,
        AdvisedGoodStatus.IN_LAB,
      ].includes(content.status)
    ) {
      return true;
    }

    return !!isLabFromDE || !!isLabFromDE_D365;
  }

  public redirectToDeliveries = () => {
      const {
          history,
          advisedGoodsStore: { content },
      } = this.props;
      const prevDelivery = content.delivery && content.delivery.id ? `?prevDeliveryId=${content.delivery.id}` : '';
      history.push(`/deliveries${prevDelivery}`);
  };

  public saveAdvisedGoodIfDirty = async (): Promise<boolean> => {
      const {
          advisedGoodsStore: { content, putAdvisedGoodsById },
          sessionStore: { tenant },
      } = this.props;
      if (content.isDirty) {
          const attachmentsCondition = tenant.isNeedCheckAGAttachments && !this._hasAttachments;
          if (
              !(await this._modalConfirmationCheck(
                  attachmentsCondition,
                  this._hasAttachmentsPromise,
                  this._toggleHasAttachmentsConfirmationModal
              ))
          ) {
              return false;
          }
          const newAG = await putAdvisedGoodsById(content.constructPutAdvisedGood());
          await this._setContent(newAG);
          notification.success(this.props.translateService.t.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG);
          return true;
      } else {
          return true;
      }
  };
  // TRANSFER DELIVERED GOOD
  public transferDeliveredGoodWithIdCheck = async (deliveredGood: ReceivedGoodsModel, transfer: ITransferData) => {
    const {
      translateService: { t },
      viewStore: { isDE, isDE_D365 },
    } = this.props;
    if (!deliveredGood.id) {
      notification.warn(this.props.translateService.t.ADVISEDGOOD_ROUTE_SAVE_BEFORE_TRANSFER_MSG);
      return Promise.reject(TRANSFER_ID_NULL_ERROR_MSG);
    }

    const result = await this.saveAdvisedGoodIfDirty();
    if (result) {
      if (!isDE && !isDE_D365 && transfer.weight > deliveredGood.grossWeight) {
        notification.error(t.ADVISEDGOOD_SORTINGREPORT_TRANSFER_ERROR_MSG);
        return Promise.reject(TRANSFER_WEIGHT_ERROR_MSG);
      }

      return this._transferDeliveredGood(deliveredGood, transfer);
    }
  };

  // UPDATE FUNCTION FOR RECEIVED GOOD - GROSS WEIGHT
  public updateReceivedGoodGrossWeight = (item: ReceivedGoodsModel, newVal: number) => {
    item.setGrossWeight(newVal);
  };

  public get deliveryReceivedWeight(): number {
    return this.props.advisedGoodsStore.content.deliveryReceivedWeight;
  }

  public toggleClaimConfirmationModal = () => {
    this.setState({ claimConfirmationModal: !this.state.claimConfirmationModal });
  };

  public render() {
    const { isTareNetWeightFieldsShown } = this.state;
    const {
      viewStore: { numberOfDecimals, isFR },
      commonStore: { stockItems },
      translateService: { t },
      advisedGoodsStore: { content, downloadAttachments, uploadAttachments, uploadAttachment },
    } = this.props;

    if (!content || !stockItems) {
      return null;
    }

    return (
      <Container fluid className="padding-bottom-50">
        {/* BACK BUTTON, WITH DIRTY CHECK */}
        <BackButton onClick={this._goBack}>{t.GLOBAL_LABEL_DELIVERIES}</BackButton>

        <AdvisedGoodButtons
          initialYardLocation={this.initialYardLocation}
          redirectToDeliveries={this.redirectToDeliveries}
          saveAdvisedGoodIfDirty={this.saveAdvisedGoodIfDirty}
          toggleSaveConfirmationModalForLab={this.toggleClaimConfirmationModal}
          isReadOnly={this.isReadOnly}
        />

        <Row className="bg-white border-top border-bottom pt-3 pb-3">
          <Col className="min-width-385">
            <AdvisedGoodDeliveryDetailsComponent />
          </Col>
          <Col className="min-width-385">
            <AdvisedGoodWeightDetailsComponent />
          </Col>
        </Row>

        {this._renderLocation}
        {this._renderDeduction}
        <Row>
          <Col size={12} className="pt-1 pb-1 ps-0 pe-0">
            {this._hasSubAdvisedGoods &&
              content.subAdvisedGoods.map((subAdvisedGood: SubAdvisedGoodsModel) => (
                <div key={subAdvisedGood.id}>
                  {/* SORTING REPORT */}
                  <h5 className="mt-4 clearfix ps-3 pe-3 flex">
                    {content.subAdvisedGoods.length === 1 ? (
                      t.LABORATORY_ADVISEGOODS_SORTING_REPORT
                    ) : (
                      <span>
                        {subAdvisedGood.advisedDescription}
                        <span className="ps-3 font-light">
                          {processDecimals(subAdvisedGood.advisedWeight, numberOfDecimals)}
                        </span>
                      </span>
                    )}
                    {this._hasShowHideTareNetWeightButton && (
                      <Button
                        data-test="show-tare-net-weight"
                        className="transparent-btn margin-left-1rem"
                        color="false"
                        onClick={this._toggleIsTareNetWeightFieldsShown}
                      >
                        {isTareNetWeightFieldsShown ? (
                          <React.Fragment>
                            <FontAwesome className="margin-right-10" name="angle-left" />
                            {t.GLOBAL_LABEL_HIDE}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <FontAwesome className="margin-right-10" name="angle-right" />
                            {t.GLOBAL_LABEL_SHOW}
                          </React.Fragment>
                        )}
                      </Button>
                    )}
                    {content.canNotCreateNewRG && (
                      <span data-test="rg-warning" className="color-warning margin-left-1rem">
                        <FontAwesome name="exclamation-triangle" />
                        {t.ADVISEDGOOD_ROUTE_RG_AMOUNT_WARNING_MESSAGE}
                      </span>
                    )}
                  </h5>

                  {this._hasBalanceCheckbox && (
                    <FormGroup check data-test="balance">
                      <Label check className="ps-3">
                        <InputWithNullCheck
                          checked={content.balance}
                          disabled={this.isReadOnly}
                          type="checkbox"
                          onChange={(e) => this._processBalance(e.currentTarget.checked)}
                        />{' '}
                        {t.ADVISEDGOOD_ROUTE_BALANCED}
                      </Label>
                    </FormGroup>
                  )}

                  <table className="custom-table srl-table">
                    <thead>
                      <tr>
                        {this._isStockItemShown && (
                          <th className="ps-3 srl-stock-code-width srl-stock-code-padding">
                            {t.DELIVERIES_NEWDELIVERYDETAILS_STOCK_ITEM}
                          </th>
                        )}
                        <th
                          className={classnames(
                            { 'ps-3': !this._isStockItemShown },
                            'srl-description-width',
                            'srl-description-padding'
                          )}
                        >
                          {t.GLOBAL_LABEL_DESCRIPTION}
                        </th>
                        <th className="srl-weight-width srl-weight-align-padding" data-test="gross-weight-title">
                          {this._grossWeightTitle}
                        </th>
                        {this._isTareNetFieldsShown && (
                          <React.Fragment>
                            <th className="srl-weight-tare-width srl-weight-align-padding">
                              {t.ADVISEDGOOD_ROUTE_TARE_WEIGHT}
                            </th>
                            <th className="srl-weight-width srl-weight-align-padding">
                              {t.ADVISEDGOOD_ROUTE_NET_WEIGHT}
                            </th>
                          </React.Fragment>
                        )}
                        <th className="srl-info-button text-end pe-3" />
                      </tr>
                    </thead>
                    {this._hasSubAdvisedGoods &&
                      subAdvisedGood.receivedGoods.map((item: ReceivedGoodsModel, index: number) => (
                        <SortingReportLineComponent
                          advisedGood={content}
                          index={index}
                          isReadOnly={this._isSortingReportLineReadOnly(item)}
                          isRemarksReadOnly={this._isRemarksReadOnly}
                          item={item}
                          key={`${item.id}-${index}`}
                          labAnalysisCreate={this._labAnalysisCreate}
                          preOpenModal={this.preOpenModal}
                          transferDeliveredGood={this.transferDeliveredGoodWithIdCheck}
                          updateReceivedGoodGrossWeight={this.updateReceivedGoodGrossWeight}
                          isTareNetFieldsShown={this._isTareNetFieldsShown}
                          isStockItemShown={this._isStockItemShown}
                          isNeedToValidateRGs={this._isNeedToValidateRGs}
                        />
                      ))}
                  </table>
                </div>
              ))}
          </Col>
        </Row>

        {/* PICTURES */}
        {
          <PicturesComponent
            data-test="pictures"
            isReadOnly={this.isReadOnly}
            content={content}
            uploadAttachment={uploadAttachment}
            uploadAttachments={uploadAttachments}
            downloadAttachments={downloadAttachments}
          />
        }

        {/* Processing component */}
        {this._renderProcessingComponent}

        {/* PACKAGING */}
        {isFR && (
          <React.Fragment>
            <h5 className="mt-4 clearfix">{t.ADVISEDGOOD_ROUTE_PACKAGING}</h5>
            {this._renderPackaging}
          </React.Fragment>
        )}

        {/* COMMENT */}
        <h5 className="mt-4 clearfix">{t.ADVISEDGOOD_ROUTE_COMMENT}</h5>
        <AdvisedGoodComment isReadOnly={ this.isReadOnly }/>

        {/* SAVE BUTTON - STICKY FOOTER */}
        <StickyFooter data-test="sticky-footer" show={this._isShowFooter}>
          <Button data-test="ag-save-btn" type="button" color="success" onClick={this.saveAdvisedGoodIfDirty}>
            {t.GLOBAL_LABEL_SAVE}
          </Button>
        </StickyFooter>

        {/* MODAL CONFIRMATION FOR LEAVING PAGE WHEN IS DIRTY */}
        <ModalConfirmation
          buttonYesColor="success"
          callbackNo={this.redirectToDeliveries}
          callbackYes={this._saveAdvisedGoodAndGoToDeliveriesList}
          heading={t.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER}
          ico="floppy-o"
          isOpen={this.state.saveConfirmationModal}
          text={t.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT}
          toggleModal={this._toggleSaveConfirmationModal}
        />

        {/* MODAL CONFIRMATION FOR LAB LIST - LAB FROM DE */}
        <ModalConfirmation
          buttonYesColor="success"
          callbackNo={this._goToLabList}
          callbackYes={this._saveAdvisedGoodAndGoToLabList}
          heading={t.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_HEADER}
          ico="floppy-o"
          isOpen={this.state.saveConfirmationModalForLab}
          text={t.ADVISEDGOOD_ROUTE_LIVING_PAGE_CONFIRMATION_TEXT}
          toggleModal={this._toggleSaveConfirmationModalForLab}
        />

        {/* MODAL CONFIRMATION - IF AG NOT HAS ATTACHMENTS */}
        <ModalConfirmation
          data-test="ag-attachments-confirmation"
          buttonYesColor="info"
          callbackNo={this._resolveHasAttachmentsPromiseWithFalse}
          callbackYes={this._resolveHasAttachmentsPromiseWithTrue}
          heading={t.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_HEADER}
          ico="check"
          isOpen={this._hasAttachmentsConfirmationModal}
          text={t.ADVISEDGOOD_ROUTE_WITHOUT_ATTACHMENTS_CONFIRMATION_TEXT}
          toggleModal={this._toggleHasAttachmentsConfirmationModal}
        />
      </Container>
    );
  }

  private get _renderPackaging() {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;
    const isPackagingReadOnly = this._isPackagingReadOnly;

    return (
      <Row data-test="packaging-container" className="bg-white border-top border-bottom">
        <Col xs={12} className="pt-1 pb-1">
          {isPackagingReadOnly &&
            (content.packaging ? (
              <div data-test="packaging-div">{content.packaging}</div>
            ) : (
              <div className="text-center font-light">{t.ADVISEDGOOD_ROUTE_NO_PACKAGING}</div>
            ))}
          {!isPackagingReadOnly && (
            <InputWithLimitOfCharacters
              data-test="packaging-input"
              disabled={isPackagingReadOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => content.setPackaging(e.target.value)}
              type="textarea"
              value={content.packaging}
              maxLength={ADVISED_PACKAGING_MAX_LENGTH_FR}
            />
          )}
        </Col>
      </Row>
    );
  }

  private get _renderLocation() {
    const {
      translateService: { t },
      commonStore: { yardLocations },
      viewStore: { isDE, isUS, isDE_D365 },
      advisedGoodsStore: { content },
    } = this.props;

    return isDE || isUS || isDE_D365 ? (
      <Row className="flex mt-4">
        <h5 className="ps-3 pe-3">{t.ADVISEDGOOD_ROUTE_UNLOAD_LOCATION}</h5>
        <Autocomplete
          data-test="unload-location"
          disabled={this._isLocationDisabled}
          placeholder={t.GLOBAL_LABEL_SELECT}
          items={yardLocations}
          onSelect={(selectedItem: IdNameModel) => content.setYardLocation(selectedItem)}
          selectedValue={content.yardLocation && content.yardLocation.id}
          selectedName={this._selectedUnloadLocationName}
        />
      </Row>
    ) : null;
  }

  private get _renderDeduction() {
    const {
      translateService: { t },
      viewStore: { isDE, isDE_D365 },
      advisedGoodsStore: { content },
    } = this.props;
    return (isDE || isDE_D365) && content.isRequiredGrossAndTareWeights ? (
      <Row className="flex mt-4" data-test="deduction-section">
        <h5 className="ps-3 pe-3">{t.ADVISEDGOOD_DEDUCTION_DEDUCTION}</h5>
        {this._unloadInputGroup}
      </Row>
    ) : null;
  }

  private get _renderProcessingComponent() {
    const {
      viewStore: { isUK },
      advisedGoodsStore: { content },
      translateService: { t },
    } = this.props;

    return isUK ? (
      <ProcessingTimeComponent
        data-test="processing-types"
        processingTimes={content.processingData}
        advisedGood={content}
        isReadOnly={this.isReadOnly}
        columns={2}
        title={t.SORTINGREPORT_PROCESSING_TITLE}
        isValid={this._isProcessingValid}
        decimalPlaces={0}
      />
    ) : null;
  }

  private get _isProcessingValid() {
    const {
      advisedGoodsStore: { content },
      sessionStore: { isTier2 },
    } = this.props;
    const needValidate =
      isTier2 && [AdvisedGoodStatus.READY_FOR_TIER_2, AdvisedGoodStatus.CLAIMED].includes(content.status);
    return needValidate ? content.validators.processingData : true;
  }

  private get _isLocationDisabled() {
    const {
      advisedGoodsStore: {
        content: { status },
      },
    } = this.props;
    return status !== AdvisedGoodStatus.CLAIMED || this.isReadOnly;
  }

  private get _selectedUnloadLocationName() {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.yardLocation && (content.yardLocation.name || content.yardLocation.id);
  }

  private _goToLabList = () => {
    this.props.history.push('/deliveries/lab');
  };

  private get _hasSubAdvisedGoods(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.subAdvisedGoods && content.subAdvisedGoods.length !== 0;
  }

  private _toggleHasAttachmentsConfirmationModal = () => {
    this._hasAttachmentsConfirmationModal = !this._hasAttachmentsConfirmationModal;
  };

  private get _unloadInputGroup(): React.ReactNode {
    const {
      translateService: { t },
      viewStore: { numberOfDecimals },
      advisedGoodsStore: { content },
    } = this.props;
    const deductionsList = Object.keys(DeductionsList).map((deduction) => ({
      id: deduction,
      name: getTranslation(`ADVISEDGOOD_DEDUCTION_${deduction}` as keyof I18N, t) as string,
    }));
    const isDisabled =
      this.isReadOnly || ![AdvisedGoodStatus.CLAIMED, AdvisedGoodStatus.UNLOADED].includes(content.status);

    return (
      <div className="unload-input-group">
        <Autocomplete
          data-test="unload-select"
          disabled={isDisabled}
          placeholder={t.ADVISEDGOOD_DEDUCTION_DEDUCTION}
          items={deductionsList}
          onSelect={(selectedItem: IdNameModel) => content.setNameOfDeduction(selectedItem)}
          selectedValue={content.deduction.name}
          inputClassName="deduction-autocomplete-width"
        />
        <InputNumber
          data-test="unload-input"
          placeholder={t.GLOBAL_LABEL_WEIGHT}
          className="width-100 margin-left-1rem"
          disabled={content.isUserChoseNoDeductionOption || isDisabled}
          value={content.deduction.value}
          onChangeValue={this._changeDeduction}
          decimalPlaces={numberOfDecimals}
        />
      </div>
    );
  }

  private get _grossWeightTitle() {
    const {
      viewStore: { shouldDisplayTareWeightForReceivedGood, isFR },
      translateService: {t}
    } = this.props;
    if (isFR) {
      return t.ADVISEDGOOD_ROUTE_RECEIVED_WEIGHT;
    }

    return shouldDisplayTareWeightForReceivedGood ? t.ADVISEDGOOD_ROUTE_GROSS_WEIGHT : t.ADVISEDGOOD_ROUTE_NET_WEIGHT;
  }

  private get _isTareNetFieldsShown() {
    const {
      viewStore: { shouldDisplayTareWeightForReceivedGood },
    } = this.props;
    return isMobile() ? shouldDisplayTareWeightForReceivedGood && this.state.isTareNetWeightFieldsShown : shouldDisplayTareWeightForReceivedGood;
  }

  private get _hasShowHideTareNetWeightButton() {
    const {
      viewStore: { isDE, isUS, isDE_D365 },
    } = this.props;
    return isMobile() && (isDE || isUS || isDE_D365);
  }

  private get _isStockItemShown(): boolean {
    const {
      sessionStore: { isTier3 },
      viewStore: { isTier3FromFR, isTier3FromDE_D365 },
    } = this.props;
    const { isTareNetWeightFieldsShown } = this.state;
    const permittedRole = !isTier3 || isTier3FromFR || isTier3FromDE_D365;
    return isMobile() ? permittedRole && !isTareNetWeightFieldsShown : permittedRole;
  }

  private _toggleIsTareNetWeightFieldsShown = () => {
    this.setState({ isTareNetWeightFieldsShown: !this.state.isTareNetWeightFieldsShown });
  };

  private async _loadData() {
    const {
      advisedGoodsStore,
      match: { params },
      commonStore,
      viewStore: { isUK },
      sessionStore: { isManager, isTraderOrBackOffice },
    } = this.props;

    if (!commonStore.isLabDataLoaded) {
      commonStore.getCommonLab();
    }

    if (isUK) {
      await advisedGoodsStore.getCommonData();
    }

    const content = await advisedGoodsStore.getAdvisedGoodsById(params.id);
    this._setContent(content);
    const allWeights = await advisedGoodsStore.getAllWeightsByAGId(advisedGoodsStore.content.id);
    advisedGoodsStore.setAllWeights(allWeights);

    const tenantId = isManager || isTraderOrBackOffice ? advisedGoodsStore.content?.tenant?.id : undefined;
    if (!commonStore.isDataLoaded || tenantId) {
      commonStore.getCommon(tenantId);
    }

    this.props.socketService.setIsContentReadyToUpdate(true);
  }

  private _changeDeduction = (newValue: number) => {
    this.props.advisedGoodsStore.content.setValueOfDeduction(newValue);
  };

  private _setContent = (res: AdvisedGoodsModel) => {
    const { advisedGoodsStore } = this.props;

    advisedGoodsStore.setContent(res);
    this._changeBalanceForOneLine();
    this.initialYardLocation = advisedGoodsStore.content.yardLocation;
  };

  private _changeBalanceForOneLine = () => {
    const {
      advisedGoodsStore: {
        content: { subAdvisedGoods, hasOnlyOneSubAdvisedGoodWithReceivedGoods },
      },
    } = this.props;
    if (
      hasOnlyOneSubAdvisedGoodWithReceivedGoods &&
      !subAdvisedGoods[0].receivedGoods[0].grossWeight &&
      this.deliveryReceivedWeight
    ) {
      this.props.advisedGoodsStore.content.setBalance(true);
    }
  };

  // EXECUTE CHECK BEFORE QUITING PAGE
  private _goBack = () => {
    if (this._isNeedToSave) {
      this.props.viewStore.isLabFromDE || this.props.viewStore.isLabFromDE_D365
        ? this._toggleSaveConfirmationModalForLab()
        : this._toggleSaveConfirmationModal();
    } else {
      this.redirectToDeliveries();
    }
  };

  private _saveAdvisedGoodAndGoToDeliveriesList = async () => {
    const result = await this.saveAdvisedGoodIfDirty();
    if (result) {
      this.props.history.push('/deliveries');
    }
  };

  private _saveAdvisedGoodAndGoToLabList = async () => {
    const result = await this.saveAdvisedGoodIfDirty();
    if (result) {
      notification.success(this.props.translateService.t.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG);
      this._goToLabList(); // REDIRECT TO LAB LIST
    }
  };

  // TOGGLE SAVE PROMPT MODAL
  private _toggleSaveConfirmationModal = () => {
    this.setState({
      saveConfirmationModal: !this.state.saveConfirmationModal,
    });
  };

  // TOGGLE SAVE PROMPT MODAL FOR LAB FROM DE
  private _toggleSaveConfirmationModalForLab = () => {
    this.setState({
      saveConfirmationModalForLab: !this.state.saveConfirmationModalForLab,
    });
  };

  private get _hasBalanceCheckbox() {
    const {
      advisedGoodsStore: { content },
      viewStore: { isUS },
    } = this.props;
    return Boolean(
      content.isRequiredGrossAndTareWeights &&
        ((!isUS && content.subAdvisedGoods.length === 1) || content.hasOnlyOneDeliveryAGForUS) &&
        this.deliveryReceivedWeight
    );
  }

  // CREATE NEW LAB ANALYSIS
  private _labAnalysisCreate = (receivedGood: ReceivedGoodsModel, labStatusSelected: string) => {
    if (!receivedGood.id) {
      notification.warn(this.props.translateService.t.ADVISEDGOOD_ROUTE_SAVE_BEFORE_LAB_ANALYSIS_MSG);
      return;
    }
    this.props.receivedGoodsStore
      .postLabAnalysis(receivedGood.id, labStatusSelected)
      .then((labInputs: LabAnalysisModel[]) => {
        receivedGood.replaceLabInputs(
          labInputs,
          this.props.commonStore.defaultMaterials,
          this.props.commonStore.nonDefaultMaterial
        );
        notification.success(this.props.translateService.t.ADVISEDGOOD_ROUTE_LAB_MSG);
      });
  };

  // ON CONTENT.BALANCE CHANGE
  private _processBalance = (isBalanceChecked: boolean) => {
    this.props.advisedGoodsStore.content.setBalance(isBalanceChecked);
  };

  private _onSocketAdvisedGoodUpdate = (data: AdvisedGoodsModel[]) => {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;

    for (const advisedGood of data) {
      if (content.id === advisedGood.id) {
        this._setContent(advisedGood);
        notification.info(t.ADVISEDGOOD_ROUTE_UPDATE_SUCCESS_MSG);
      }
    }
  };

  private _updateAdvisedGoodGrossWeight = (data: IWeightUpdateSocketResponse) => {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;

    if (content.id === data.advisedGoodId) {
      content.setFirstWeight(data.weight);
      this._setContent(content);
      notification.info(t.ADVISEDGOOD_ROUTE_UPDATE_GROSS_WEIGHT_SUCCESS_MSG);
    }
  };

  private _updateAdvisedGoodTareWeight = (data: IWeightUpdateSocketResponse) => {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;

    if (content.id === data.advisedGoodId) {
      content.setSecondWeight(data.weight);
      this._setContent(content);
      notification.info(t.ADVISEDGOOD_ROUTE_UPDATE_TARE_WEIGHT_SUCCESS_MSG);
    }
  };

  private _updateEwcCode = (data: IEwcCodeUpdateSocketResponse) => {
    const {
      translateService: { t },
      advisedGoodsStore: { content },
    } = this.props;

    content.setEwcCode(data.ewcCode);
    this._setContent(content);
    notification.info(t.ADVISEDGOOD_ROUTE_UPDATE_EWC_CODE_SUCCESS_MSG);
  };

  private _updateWeights = (data: AllWeightsModel) => {
    const {
      translateService: { t },
      advisedGoodsStore,
    } = this.props;

    advisedGoodsStore.setAllWeights(data);
    notification.info(t.ADVISEDGOOD_ROUTE_WEIGHTS_UPDATED(data.name));
  };

  private _transferDeliveredGood = async (deliveredGood: ReceivedGoodsModel, transfer: ITransferData) => {
    const updatedAG = await this.props.receivedGoodsStore.postTransferDeliveredGood(deliveredGood, transfer);
    await this._setContent(updatedAG);
  };

  private get _isRemarksReadOnly(): boolean {
    const {
      viewStore: { isTier2FromIT, isTier1FromIT, isTier3FromIT, isTier3FromFR, isTier1FromFR, isTier2FromFR, isIT },
      advisedGoodsStore: { content },
    } = this.props;

    return (
      (this.isReadOnly ||
        ((isTier2FromIT || isTier3FromIT || isTier2FromFR || isTier3FromFR) &&
          !content.secondWeight &&
          isIT &&
          [AdvisedGoodStatus.UNLOADED, AdvisedGoodStatus.READY_FOR_TIER_2].includes(content.status))) &&
      !((isTier1FromIT || isTier1FromFR) && content.status === AdvisedGoodStatus.READY_FOR_SIGN_OFF)
    );
  }

  private get _isPackagingReadOnly(): boolean {
    const {
      viewStore: { isTier3FromFR, isTier2FromFR },
      advisedGoodsStore: { content },
    } = this.props;

    return (
      this.isReadOnly ||
      [AdvisedGoodStatus.WAITING, AdvisedGoodStatus.READY_FOR_SIGN_OFF].includes(content.status) ||
      (!isTier2FromFR && !isTier3FromFR)
    );
  }

  private _isSortingReportLineReadOnly(item: ReceivedGoodsModel) {
    return this.isReadOnly || (!item.isFilledOut && this.props.advisedGoodsStore.content.canNotCreateNewRG);
  }

  private get _isNeedToValidateRGs(): boolean {
    const {
      advisedGoodsStore: { content },
      viewStore: { isIT, isTier2FromIT, isTier2FromFR, isFR },
    } = this.props;
    const { status } = content;
    return (
      // if status is READY_FOR_TIER_2 and it is Tier2 from IT, no need to validate, it was done by Tier 3
      (status === AdvisedGoodStatus.READY_FOR_TIER_2 && !isTier2FromIT && !isTier2FromFR) ||
      (status === AdvisedGoodStatus.UNLOADED && !isIT && !isFR) || // no need validate on verify for IT
      ((isIT || isFR) && status === AdvisedGoodStatus.CLAIMED && !content.validators.RGsContamination)
    ); // for IT validate on unload
  }

  private get _isNeedToSave(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.isDirty;
  }

  private get _isShowFooter(): boolean {
    return this._isNeedToSave;
  }

  private get _hasAttachments(): boolean {
    const {
      advisedGoodsStore: { content },
    } = this.props;
    return content.attachments?.length > 0;
  }

  private _modalConfirmationCheck = async (
    condition: boolean,
    promiseResolver: PromiseResolver<boolean>,
    toggleModal: () => void
  ): Promise<boolean> => {
    if (condition) {
      toggleModal();
      const shouldContinue = await promiseResolver.promise;
      promiseResolver.createNewPromise();

      if (!shouldContinue) {
        return false;
      }
    }
    return true;
  };

  private _resolveHasAttachmentsPromiseWithTrue = () => {
    this._hasAttachmentsPromise.resolvePromise(true);
  };

  private _resolveHasAttachmentsPromiseWithFalse = () => {
    this._hasAttachmentsPromise.resolvePromise(false);
  };
}

export default inject(
  'advisedGoodsStore',
  'receivedGoodsStore',
  'commonStore',
  'sessionStore',
  'viewStore',
  'translateService',
  'socketService'
)(observer(AdvisedGoodRoute));
