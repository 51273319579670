import { action, computed, observable, makeObservable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import ObjectUpdater from 'util/objectUpdater';
import { IActive } from 'models/ModelInterfaces';

export default class IdNameActiveModel extends IdNameModel implements IActive {
  public readonly updater = new ObjectUpdater<IdNameActiveModel>();

  public active: boolean = false;

  constructor() {
    super();

    makeObservable(this, {
      active: observable,
      constructSaveObj: computed,
      changeActiveStatus: action,
    });
  }

  public get constructSaveObj(): IdNameActiveModel {
    return {
      name: this.name,
      active: this.active,
    } as IdNameActiveModel;
  }

  public update(obj: IdNameActiveModel) {
    this.updater.update(this, super.update(obj), IdNameActiveModel);
    return this;
  }

  public changeActiveStatus = (newStatus: boolean) => (this.active = newStatus);
}
