import * as React from 'react';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import * as FontAwesome from 'react-fontawesome';
import * as classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import Autocomplete from 'domain/Autocomplete';
import InputWithLimitOfCharacters from 'domain/InputWithLimitOfCharacters';

import DeliveryAdvisedGoodModel, { DeliveryAGValidatorKey } from 'models/DeliveryAdvisedGoodModel';
import DeliveryModel from 'models/DeliveryModel';
import IdNameModel from 'models/IdNameModel';
import DeliverySubAdvisedGoodModel from 'models/DeliverySubAdvisedGoodModel';
import StockItemModel from 'models/StockItemModel';
import TranslateService from 'services/TranslateService';
import CommonStore from 'stores/CommonStore';
import ViewStore from 'stores/ViewStore';
import { inputNumberStep, isMobile, processDecimals } from 'util/helpers';
import InputNumber from 'components/InputNumber';
import { AdvisedGoodStatus, CountryCode, DepartmentCode } from 'util/enums';
import InputWithNullCheck from 'components/InputWithNullCheck';
import IdCodeModel from 'models/IdCodeModel';
import SessionStore from 'stores/SessionStore';
import {
  ADVISED_COMMENT_MAX_LENGTH_FR,
  ADVISED_DESCRIPTION_MAX_LENGTH_DE,
  ADVISED_DESCRIPTION_MAX_LENGTH_DEFAULT,
  ADVISED_DESCRIPTION_MAX_LENGTH_IT,
  ADVISED_PACKAGING_MAX_LENGTH_FR,
  BORDER_DANGER,
  MAX_DELIVERY_GROSS_WEIGHT,
} from 'util/constants';
import PicturesComponent from 'components/PicturesComponent';
import AdvisedGoodsStore from 'pod/advisedGoods/AdvisedGoodsStore';
import ProductFormAndQuality from 'components/ProductFormAndQuality';

interface IProps {
  delivery: DeliveryModel;
  index: number;
  isReadOnly: boolean;
  item: DeliveryAdvisedGoodModel;
  commonStore?: CommonStore;
  viewStore?: ViewStore;
  translateService?: TranslateService;
  sessionStore?: SessionStore;
  advisedGoodsStore?: AdvisedGoodsStore;
}

interface IState {
  showLineDetailModal: boolean;
}

class DeliveryDetailLineComponent extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      showLineDetailModal: false,
    };
  }

  public render() {
    const {
      item,
      commonStore: { common, responsibleUsersList },
      isReadOnly,
      translateService: { t },
      viewStore: { isUS, isDE, isIT, weighbridgeTolerance, numberOfDecimals, isFR, isAdminFromFR, isDE_D365 },
      advisedGoodsStore: { downloadAttachments, uploadAttachment, uploadAttachments },
    } = this.props;

    return (
      <tbody>
        <tr className={classnames('pointer', { scale: isMobile() && item.isScaleWeights })}>
          <td className={classnames([{ 'ps-3': !isMobile() }])}>
            <Autocomplete
              data-test={'stock-item'}
              disabled={isReadOnly || this._isStockItemDisabled}
              placeholder={t.GLOBAL_LABEL_SELECT}
              showOnTop={true}
              items={common.stockItems}
              onSelect={(selectedItem: StockItemModel) => {
                item.setStockItem(selectedItem);
                item.setAdvisedDescription(selectedItem.name);
              }}
              selectedValue={this._selectedStockItem}
              selectedName={this._selectedStockItemCode}
              inputClassName={classnames({ [BORDER_DANGER]: this._isFieldInvalid('stockItem') })}
              useCodeAsValue
              displayCodeAndName
            />
          </td>
          <td>
            <InputWithLimitOfCharacters
              data-test="advised-description"
              disabled={isReadOnly}
              onChange={(e: React.FormEvent<HTMLInputElement>) => item.setAdvisedDescription(e.currentTarget.value)}
              required
              type="text"
              maxLength={this._advisedDescriptionLimit}
              value={item.advisedDescription}
              className={classnames({ [BORDER_DANGER]: this._isFieldInvalid('advisedDescription') })}
            />
          </td>
          {isFR && (
            <React.Fragment>
              <td>
                <InputWithLimitOfCharacters
                  data-test="advised-comment"
                  disabled={isReadOnly}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => item.setComment(e.currentTarget.value)}
                  required
                  type="text"
                  maxLength={ADVISED_COMMENT_MAX_LENGTH_FR}
                  value={item.comment}
                />
              </td>
              <td>
                <InputWithLimitOfCharacters
                  data-test="advised-packaging"
                  disabled={isReadOnly}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => item.setPackaging(e.currentTarget.value)}
                  required
                  type="text"
                  maxLength={ADVISED_PACKAGING_MAX_LENGTH_FR}
                  value={item.packaging}
                />
              </td>
            </React.Fragment>
          )}
          {(isIT || isFR) && (
            <td data-test="responsible">
              <Autocomplete
                disabled={this._isResponsibleFieldDisable}
                placeholder={t.GLOBAL_LABEL_SELECT}
                showOnTop={true}
                items={responsibleUsersList}
                onSelect={(selectedItem: IdNameModel) => item.setResponsible(selectedItem)}
                selectedValue={item.responsible?.id}
              />
            </td>
          )}
          {(isFR || isUS) && (
            <td data-test="adv-gross-weight">
              <InputNumber
                disabled={isReadOnly}
                decimalPlaces={numberOfDecimals}
                onChangeValue={(value: number) => item.setAdvisedGrossWeight(value)}
                step={inputNumberStep(numberOfDecimals)}
                value={item.advisedGrossWeight}
              />
            </td>
          )}
          {!isUS && (
            <td data-test="adv-net-weight">
              <InputNumber
                disabled={isReadOnly}
                decimalPlaces={numberOfDecimals}
                onChangeValue={(value: number) => item.setAdvisedNetWeight(value)}
                step={inputNumberStep(numberOfDecimals)}
                value={item.advisedNetWeight}
              />
            </td>
          )}
          {isUS && (
            <td data-test="advised-tare-weight">
              <InputNumber
                disabled={isReadOnly}
                decimalPlaces={numberOfDecimals}
                onChangeValue={(value: number) => item.setAdvisedTareWeight(value)}
                step={inputNumberStep(numberOfDecimals)}
                value={item.advisedTareWeight}
              />
            </td>
          )}
          {this._hasTicketNumber && (
            <td>
              <InputWithNullCheck
                data-test="ticket-number"
                disabled={isReadOnly}
                onChange={(e: React.FormEvent<HTMLInputElement>) => item.setTicketNumber(e.currentTarget.value)}
                required
                type="text"
                value={item.ticketNumber}
                className={classnames({ [BORDER_DANGER]: this._isFieldInvalid('ticketNumber') })}
              />
            </td>
          )}

          {this._renderEwcCode}

          {isDE && (
            <td data-test="department">
              <Autocomplete
                data-test="department-input"
                disabled={isReadOnly}
                placeholder={t.GLOBAL_LABEL_SELECT}
                showOnTop={true}
                items={this._departmentCodeList}
                onSelect={(selectedItem: { name: DepartmentCode; id: DepartmentCode }) =>
                  item.setDepartmentCode(selectedItem.id)
                }
                selectedValue={item.departmentCode}
                inputClassName={classnames({ [BORDER_DANGER]: this._isFieldInvalid('departmentCode') })}
              />
            </td>
          )}
          <td data-test="yard-location">
            <Autocomplete
              data-test={'yard-location-value'}
              disabled={isReadOnly}
              placeholder={t.GLOBAL_LABEL_SELECT}
              showOnTop={true}
              items={common.yardLocations}
              onSelect={(selectedItem: IdNameModel) => item.setYardLocation(selectedItem)}
              selectedValue={this._selectedYardLocation}
              selectedName={this._selectedYardLocationName}
              inputClassName={classnames({ [BORDER_DANGER]: this._isFieldInvalid('yardLocation') })}
            />
          </td>
          {(isUS || isAdminFromFR) && (
            <td data-test="ag-external-index">
              <InputWithNullCheck disabled value={item.advisedGoodExternalIndex} />
            </td>
          )}
          {!isUS && (
            <td data-test="set1-weight">
              <InputNumber
                data-test="set1-weight-input"
                className={classnames({
                  [BORDER_DANGER]: this._isInvalidFirstWeight,
                })}
                disabled={this._is1WeightDisabled}
                decimalPlaces={numberOfDecimals}
                onChangeValue={(value: number) => item.setFirstWeight(value)}
                step={inputNumberStep(numberOfDecimals)}
                value={item.firstWeight}
              />
            </td>
          )}
          {!isUS && (
            <td data-test="set2-weight">
              <InputNumber
                data-test="2-weight"
                disabled={this._is2WeightDisabled}
                decimalPlaces={numberOfDecimals}
                onChangeValue={(value: number) => item.setSecondWeight(value)}
                step={inputNumberStep(numberOfDecimals)}
                value={item.secondWeight}
              />
            </td>
          )}
          {!isUS && (
            <td data-test="weight-tolerance">
              <InputWithNullCheck
                disabled
                value={this._weightTolerance}
                className={classnames({ 'bg-red text-white': !item.isInsideWeightTolerance(weighbridgeTolerance) })}
              />
            </td>
          )}
          {!isMobile() &&
            (item.isScaleWeights ? (
              <td className="width-30">
                <FontAwesome
                  title={t.GLOBAL_LABEL_DETAILED_WEIGHING}
                  data-test="scale-weight-icon"
                  name="balance-scale"
                  className="second-blue margin-left-5"
                />
              </td>
            ) : (
              <td className="width-0" />
            ))}
          <td data-test="action-buttons" className={classnames(['text-end', 'pe-3', { 'min-width-100': !isUS }])}>
            {/* ALLOW REMOVE, ONLY IF ITEM DON'T HAVE ID OR IF THIS ITEM CLAIMEDBY*/}
            {this._renderRemoveButton}

            {/* OPEN SUB-ADVISED GOOD MODAL */}
            {!isUS && (
              <Button
                data-test="sub-advised-good-modal"
                onClick={this._toggleLineDetailModal}
                size="sm"
                className={classnames(['min-width-32', { [BORDER_DANGER]: this._isButtonInfoInvalid }])}
                color={this._hasSubAdvisedGoods ? 'success' : 'secondary'}
              >
                <FontAwesome name="info" />
              </Button>
            )}

            <Modal
              data-test="ag-details-modal"
              isOpen={this.state.showLineDetailModal}
              toggle={this._toggleLineDetailModal}
              backdrop="static"
            >
              <ModalHeader toggle={() => this._toggleLineDetailModal()}>
                {t.DELIVERIES_DELIVERYDETAILSLINE_HEADER}
              </ModalHeader>
              <ModalBody className="ps-0 pe-0 pt-0">
                <Container>
                  {isDE_D365 && (
                    <Row className="bg-white border-top border-bottom">
                      <Col className="pt-2 pb-2">
                        <ProductFormAndQuality
                          isExisted={item?.stockItem?.requiresFormAndQuality}
                          shouldDownloadForm={Boolean(this._selectedStockItem)}
                          stockItemId={this._selectedStockItem}
                          onSelectForm={item.setProductForm}
                          productFormId={this._selectedProductForm}
                          productFormClassName={this._isInvalidProductForm ? BORDER_DANGER : ''}
                          productQualityId={this._selectedProductQuality}
                          shouldDownloadQuality={Boolean(this._selectedProductForm)}
                          onSelectQuality={item.setProductQuality}
                          productQualityClassName={this._isInvalidProductQuality ? BORDER_DANGER : ''}
                          isReadOnly={this._isProductFormAndQualityDisabled}
                        />
                      </Col>
                    </Row>
                  )}
                  <h5 className="mt-4">{t.GLOBAL_LABEL_BREAKDOWN}</h5>
                  <Row className="bg-white border-top border-bottom">
                    <Col className="pt-2 pb-2">
                      <table className="custom-table" data-test="break-down-table">
                        <thead>
                          <tr>
                            <th className="pt-3 ps-3">{t.DELIVERIES_DELIVERYDETAILSLINE_ADV_DESCRIPTION}</th>
                            <th className="pt-3">{t.DELIVERIES_DELIVERYDETAILSLINE_ADV_WEIGHT}</th>
                          </tr>
                        </thead>
                        {item.subAdvisedGoods &&
                          item.subAdvisedGoods.length !== 0 &&
                          item.subAdvisedGoods.map((subAdvGood: DeliverySubAdvisedGoodModel, ind: number) => (
                            <tbody key={`s-adv-g-${ind}`} data-test="s-adv-g-row">
                              <tr className="pointer">
                                <td className="pe-1">
                                  <Autocomplete
                                    disabled={isReadOnly}
                                    placeholder={t.GLOBAL_LABEL_SELECT}
                                    items={common.stockItems}
                                    onChange={(str: string) => subAdvGood.setAdvisedDescription(str)}
                                    onSelect={(selectedItem: StockItemModel) =>
                                      subAdvGood.setAdvisedDescription(selectedItem.name)
                                    }
                                    selectedValue={subAdvGood.advisedDescription}
                                  />
                                </td>
                                <td className="ps-1">
                                  <InputNumber
                                    disabled={isReadOnly}
                                    decimalPlaces={numberOfDecimals}
                                    onChangeValue={(value: number) => subAdvGood.setAdvisedWeight(value)}
                                    step={inputNumberStep(numberOfDecimals)}
                                    value={subAdvGood.advisedWeight}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </Col>
                  </Row>
                  {isFR && (
                    <React.Fragment>
                      <h5 className="mt-4">{t.ADVISEDGOOD_ROUTE_COMMENT}</h5>
                      <Row className="bg-white border-top border-bottom">
                        <Col className="pt-2 pb-2">
                          <InputWithNullCheck
                            data-test="comment-input"
                            className="min-height-80"
                            disabled={isReadOnly}
                            name="remarks"
                            onChange={(e: React.FormEvent<HTMLInputElement>) => item.setComment(e.currentTarget.value)}
                            type="textarea"
                            value={item.comment}
                            maxLength={ADVISED_COMMENT_MAX_LENGTH_FR}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                  <PicturesComponent
                    data-test="delivery-ag-pictures"
                    isReadOnly
                    content={item}
                    uploadAttachment={uploadAttachment}
                    uploadAttachments={uploadAttachments}
                    downloadAttachments={downloadAttachments}
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="success" onClick={this._toggleLineDetailModal}>
                  {t.GLOBAL_LABEL_CLOSE}
                </Button>
              </ModalFooter>
            </Modal>
          </td>
        </tr>
      </tbody>
    );
  }

  private get _weightTolerance() {
    const {
      viewStore: { isFR, numberOfDecimals },
      item,
    } = this.props;
    const weight = isFR ? item.netWeightWithZeroSecond : item.netWeight;
    return weight ? processDecimals(weight, numberOfDecimals) : '-';
  }

  private get _hasSubAdvisedGoods() {
    const { item } = this.props;
    return item.subAdvisedGoods && item.subAdvisedGoods.length > 1;
  }

  private get _selectedStockItem() {
    const { item } = this.props;
    return item.stockItem && item.stockItem.id;
  }

  private get _selectedStockItemCode() {
    const { item } = this.props;
    return item.stockItem && item.stockItem.code;
  }

  private get _selectedYardLocation() {
    const { item } = this.props;
    return item.yardLocation && item.yardLocation.id;
  }

  private get _selectedYardLocationName() {
    const { item } = this.props;
    return item.yardLocation && (item.yardLocation.name || item.yardLocation.id);
  }

  private get _selectedProductForm() {
    const { item } = this.props;
    return item.productForm && item.productForm.id;
  }

  private get _selectedProductQuality() {
    const { item } = this.props;
    return item.productQuality && item.productQuality.id;
  }

  private get _renderRemoveButton() {
    const { item, index } = this.props;
    return (
      ((!item.id && index !== 0) || !item.claimedBy) && (
        <Button
          size="sm"
          className="me-2 min-width-32"
          type="button"
          color="false"
          onClick={() => this.props.delivery.removeAdvisedGood(item)}
        >
          <FontAwesome name="trash-o" />
        </Button>
      )
    );
  }

  private get _renderEwcCode() {
    const {
      viewStore: { isUK, isIT },
      isReadOnly,
      item,
      translateService: { t },
    } = this.props;

    return isIT || isUK ? (
      <td data-test="ewc-code">
        <Autocomplete
          data-test="ewc-code-input"
          disabled={isReadOnly}
          placeholder={t.GLOBAL_LABEL_SELECT}
          showOnTop={true}
          items={this._ewcCodeList}
          onSelect={(selectedItem: IdCodeModel) => item.setEwcCode(selectedItem)}
          selectedValue={item.ewcCode}
          useCodeAsValue
          hasClearButton={!isReadOnly}
          highlight
        />
      </td>
    ) : null;
  }

  private get _isResponsibleFieldDisable() {
    const { isReadOnly, item } = this.props;
    return isReadOnly || !!item.claimedBy;
  }

  private get _ewcCodeList() {
    const {
      commonStore: { ewcs },
    } = this.props;
    return ewcs.map((ewc: IdCodeModel) => ({
      code: ewc.code,
      id: ewc.code,
    }));
  }

  private get _departmentCodeList() {
    const {
      commonStore: { departmentCodes },
    } = this.props;
    return departmentCodes.map((departmentCode: DepartmentCode) => ({
      name: departmentCode,
      id: departmentCode,
    }));
  }

  private get _is1WeightDisabled() {
    const {
      isReadOnly,
      viewStore: { isIT },
      sessionStore: { isAdmin },
      delivery,
    } = this.props;
    return isReadOnly || (isIT && delivery.printed && !isAdmin);
  }

  private get _is2WeightDisabled() {
    const {
      isReadOnly,
      item,
      viewStore: { isIT, isFR },
      sessionStore: { isAdmin },
      delivery,
    } = this.props;
    return (
      isReadOnly ||
      !item.claimedBy ||
      (isFR && (item.status === AdvisedGoodStatus.WAITING || item.status === AdvisedGoodStatus.CLAIMED)) ||
      (isIT && !isAdmin && (item.status === AdvisedGoodStatus.WAITING || item.status === AdvisedGoodStatus.CLAIMED)) ||
      (isIT && delivery.printed && !isAdmin)
    );
  }

  private get _hasTicketNumber(): boolean {
    const { isUS, isFR } = this.props.viewStore;
    return !(isFR || isUS);
  }

  private _toggleLineDetailModal = () => {
    const { item } = this.props;
    const { showLineDetailModal } = this.state;
    if (item.subAdvisedGoods.length === 0) {
      item.pushSubAdvisedGood();
    }

    if (showLineDetailModal) {
      // CLEAN SUB-ADVISED-GOODS FROM BLANK LINES
      this.props.item.cleanSubAdvisedGoods();
    }

    this.setState({
      showLineDetailModal: !showLineDetailModal,
    });
  };

  private get _advisedDescriptionLimit(): number {
    const {
      viewStore: {
        activeTenant: { workflow },
      },
    } = this.props;

    switch (workflow.code) {
      case CountryCode.DE:
        return ADVISED_DESCRIPTION_MAX_LENGTH_DE;
      case CountryCode.IT:
        return ADVISED_DESCRIPTION_MAX_LENGTH_IT;
      default:
        return ADVISED_DESCRIPTION_MAX_LENGTH_DEFAULT;
    }
  }

  private _isFieldInvalid(key: DeliveryAGValidatorKey): boolean {
    const { item, delivery } = this.props;
    return delivery.needToCheck && !item.isValidByKey(key);
  }

  private get _isInvalidFirstWeight(): boolean {
    const {
      item,
      viewStore: { isIT, isFR },
    } = this.props;
    return (
      (isIT || isFR) &&
      (item.isFirstWeightMoreThanMaxWeight(MAX_DELIVERY_GROSS_WEIGHT) || this._isFieldInvalid('firstWeight'))
    );
  }

  private get _isInvalidProductForm(): boolean {
    const { delivery, item } = this.props;
    return delivery.needToCheck && !item.isValidByKey('productForm');
  }

  private get _isInvalidProductQuality(): boolean {
    const { delivery, item } = this.props;
    return delivery.needToCheck && !item.isValidByKey('productQuality');
  }

  private get _isProductFormAndQualityDisabled(): boolean {
    const { item } = this.props;
    return !([AdvisedGoodStatus.WAITING, AdvisedGoodStatus.CLAIMED].includes(item.status) || item.status === null);
  }

  private get _isButtonInfoInvalid(): boolean {
    return this._isInvalidProductForm || this._isInvalidProductQuality;
  }

  private get _isStockItemDisabled(): boolean {
    const {
      viewStore: { isUS },
      item,
    } = this.props;
    return isUS && item.advisedGoodExternalIndex !== null;
  }
}

export default inject(
  'translateService',
  'commonStore',
  'viewStore',
  'sessionStore',
  'advisedGoodsStore'
)(observer(DeliveryDetailLineComponent));
