import { action, observable, makeObservable } from 'mobx';
import IdNameModel from 'models/IdNameModel';
import { CountryCode } from '../util/enums';

export default class UserTenantModel extends IdNameModel {
  public isDefault: boolean = false;
  public regionCode?: string = '';
  public code?: string = '';
  public workflowCode?: CountryCode = CountryCode.INIT;

  constructor() {
    super();

    makeObservable(this, {
      isDefault: observable,
      regionCode: observable,
      code: observable,
      workflowCode: observable,
      changeIsDefault: action,
    });
  }

  public update(obj: UserTenantModel) {
    this.updater.update(this, obj, UserTenantModel);
    return this;
  }

  public changeIsDefault = (val: boolean) => (this.isDefault = val);
}
