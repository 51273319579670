import { Component, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

interface IPortal {
  children: ReactNode;
  node?: Element;
  nodeId?: string;
}

class Portal extends Component<IPortal> {
  private _node: Element = this.props.node || null;

  constructor(props: IPortal) {
    super(props);

    makeObservable<Portal, '_node' | '_changeNode'>(this, {
      _node: observable,
      _changeNode: action,
    });
  }

  public componentDidMount(): void {
    const { nodeId } = this.props;

    if (nodeId) {
      this._changeNode(document.getElementById(nodeId));
    }
  }

  public render() {
    const { children } = this.props;

    return this._node ? createPortal(children, this._node) : null;
  }

  private _changeNode = (node: Element) => {
    this._node = node;
  };
}

export default observer(Portal);
